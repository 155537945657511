/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EpisodeClassificationMethodResultViewModel } from '../model';
// @ts-ignore
import { IctalGtPatientVector } from '../model';
/**
 * IctalGtSeizureClassificationControllerApi - axios parameter creator
 * @export
 */
export const IctalGtSeizureClassificationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {number} seizureTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifySeizure: async (patientId: number, seizureTypeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('classifySeizure', 'patientId', patientId)
            // verify required parameter 'seizureTypeId' is not null or undefined
            assertParamExists('classifySeizure', 'seizureTypeId', seizureTypeId)
            const localVarPath = `/api/ictal/gt/classification/episode/classify/patient/{patientId}/seizure/{seizureTypeId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"seizureTypeId"}}`, encodeURIComponent(String(seizureTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyVector: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ictal/gt/classification/episode/classify/vector`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientVectors: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientVectors', 'patientId', patientId)
            const localVarPath = `/api/ictal/gt/classification/episode/vector/patient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IctalGtSeizureClassificationControllerApi - functional programming interface
 * @export
 */
export const IctalGtSeizureClassificationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IctalGtSeizureClassificationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {number} seizureTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async classifySeizure(patientId: number, seizureTypeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<EpisodeClassificationMethodResultViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.classifySeizure(patientId, seizureTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async classifyVector(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<EpisodeClassificationMethodResultViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.classifyVector(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientVectors(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<IctalGtPatientVector>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientVectors(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IctalGtSeizureClassificationControllerApi - factory interface
 * @export
 */
export const IctalGtSeizureClassificationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IctalGtSeizureClassificationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {number} seizureTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifySeizure(patientId: number, seizureTypeId: number, options?: any): Promise<Array<EpisodeClassificationMethodResultViewModel>> {
            return localVarFp.classifySeizure(patientId, seizureTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyVector(requestBody?: Array<number>, options?: any): Promise<Array<EpisodeClassificationMethodResultViewModel>> {
            return localVarFp.classifyVector(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientVectors(patientId: number, options?: any): Promise<Array<IctalGtPatientVector>> {
            return localVarFp.getPatientVectors(patientId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IctalGtSeizureClassificationControllerApi - object-oriented interface
 * @export
 * @class IctalGtSeizureClassificationControllerApi
 * @extends {BaseAPI}
 */
export class IctalGtSeizureClassificationControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} patientId 
     * @param {number} seizureTypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IctalGtSeizureClassificationControllerApi
     */
    public classifySeizure(patientId: number, seizureTypeId: number, options?: AxiosRequestConfig) {
        return IctalGtSeizureClassificationControllerApiFp(this.configuration).classifySeizure(patientId, seizureTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IctalGtSeizureClassificationControllerApi
     */
    public classifyVector(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return IctalGtSeizureClassificationControllerApiFp(this.configuration).classifyVector(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IctalGtSeizureClassificationControllerApi
     */
    public getPatientVectors(patientId: number, options?: AxiosRequestConfig) {
        return IctalGtSeizureClassificationControllerApiFp(this.configuration).getPatientVectors(patientId, options).then((request) => request(this.axios, this.basePath));
    }
}
