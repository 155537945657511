/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DoctorBasicDto } from './doctor-basic-dto';
import { ICD10Dto } from './icd10-dto';

/**
 * 
 * @export
 * @interface SickLeaveDto
 */
export interface SickLeaveDto {
    /**
     * 
     * @type {string}
     * @memberof SickLeaveDto
     */
    'caregiverName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SickLeaveDto
     */
    'caregiverSurname'?: string;
    /**
     * 
     * @type {string}
     * @memberof SickLeaveDto
     */
    'dateFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof SickLeaveDto
     */
    'dateTo'?: string;
    /**
     * 
     * @type {DoctorBasicDto}
     * @memberof SickLeaveDto
     */
    'doctor'?: DoctorBasicDto;
    /**
     * 
     * @type {ICD10Dto}
     * @memberof SickLeaveDto
     */
    'icd10'?: ICD10Dto;
    /**
     * 
     * @type {number}
     * @memberof SickLeaveDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SickLeaveDto
     */
    'indication'?: SickLeaveDtoIndicationEnum;
    /**
     * 
     * @type {string}
     * @memberof SickLeaveDto
     */
    'issueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SickLeaveDto
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof SickLeaveDto
     */
    'status'?: SickLeaveDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SickLeaveDto
     */
    'type'?: SickLeaveDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SickLeaveDto
     */
    'visitId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum SickLeaveDtoIndicationEnum {
    ShouldLie = 'SHOULD_LIE',
    CanWalk = 'CAN_WALK'
}
/**
    * @export
    * @enum {string}
    */
export enum SickLeaveDtoStatusEnum {
    Issued = 'ISSUED',
    Canceled = 'CANCELED'
}
/**
    * @export
    * @enum {string}
    */
export enum SickLeaveDtoTypeEnum {
    Disease = 'DISEASE',
    Care = 'CARE'
}


