/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CertificateDto
 */
export interface CertificateDto {
    /**
     * 
     * @type {string}
     * @memberof CertificateDto
     */
    'certificateMetadata'?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateDto
     */
    'diagnosis'?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateDto
     */
    'documentType'?: CertificateDtoDocumentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CertificateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CertificateDto
     */
    'mongoId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CertificateDto
     */
    'patientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CertificateDto
     */
    'reason'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CertificateDtoDocumentTypeEnum {
    ReferralGeneralPurpose = 'REFERRAL_GENERAL_PURPOSE',
    ReferralClinic = 'REFERRAL_CLINIC',
    ReferralToHospital = 'REFERRAL_TO_HOSPITAL',
    ReferralToPsychiatricHospital = 'REFERRAL_TO_PSYCHIATRIC_HOSPITAL',
    ReferralToSpecialist = 'REFERRAL_TO_SPECIALIST',
    ReferralToLab = 'REFERRAL_TO_LAB',
    ReferralToDiagnosticLaboratory = 'REFERRAL_TO_DIAGNOSTIC_LABORATORY',
    Certificate = 'CERTIFICATE',
    CertificateForZus = 'CERTIFICATE_FOR_ZUS',
    CertificateOfDisability = 'CERTIFICATE_OF_DISABILITY',
    HealthCertificate = 'HEALTH_CERTIFICATE',
    InformationForPozDoctor = 'INFORMATION_FOR_POZ_DOCTOR',
    ApplicationForRefund = 'APPLICATION_FOR_REFUND',
    ApplicationForDrugRefund = 'APPLICATION_FOR_DRUG_REFUND',
    DemandForDrugFromAbroad = 'DEMAND_FOR_DRUG_FROM_ABROAD',
    DemandForFoodstuffFromAbroad = 'DEMAND_FOR_FOODSTUFF_FROM_ABROAD',
    Prescription = 'PRESCRIPTION',
    Other = 'OTHER'
}


