/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MigrateToS3Result } from '../model';
/**
 * AdminDocumentToS3MigrationControllerApi - axios parameter creator
 * @export
 */
export const AdminDocumentToS3MigrationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDocumentsToMigrate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/technical/s3-migration/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNexDocumentMigrated: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/technical/s3-migration/next`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateById: async (requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('migrateById', 'requestBody', requestBody)
            const localVarPath = `/api/admin/technical/s3-migration/ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} chunkSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateChunk: async (chunkSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chunkSize' is not null or undefined
            assertParamExists('migrateChunk', 'chunkSize', chunkSize)
            const localVarPath = `/api/admin/technical/s3-migration/chunk/{chunkSize}`
                .replace(`{${"chunkSize"}}`, encodeURIComponent(String(chunkSize)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminDocumentToS3MigrationControllerApi - functional programming interface
 * @export
 */
export const AdminDocumentToS3MigrationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminDocumentToS3MigrationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countDocumentsToMigrate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countDocumentsToMigrate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNexDocumentMigrated(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<MigrateToS3Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNexDocumentMigrated(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateById(requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<MigrateToS3Result>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrateById(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} chunkSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateChunk(chunkSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<MigrateToS3Result>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrateChunk(chunkSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminDocumentToS3MigrationControllerApi - factory interface
 * @export
 */
export const AdminDocumentToS3MigrationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminDocumentToS3MigrationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDocumentsToMigrate(options?: any): Promise<number> {
            return localVarFp.countDocumentsToMigrate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNexDocumentMigrated(options?: any): Promise<MigrateToS3Result> {
            return localVarFp.getNexDocumentMigrated(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateById(requestBody: Array<number>, options?: any): Promise<Array<MigrateToS3Result>> {
            return localVarFp.migrateById(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} chunkSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateChunk(chunkSize: number, options?: any): Promise<Array<MigrateToS3Result>> {
            return localVarFp.migrateChunk(chunkSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminDocumentToS3MigrationControllerApi - object-oriented interface
 * @export
 * @class AdminDocumentToS3MigrationControllerApi
 * @extends {BaseAPI}
 */
export class AdminDocumentToS3MigrationControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentToS3MigrationControllerApi
     */
    public countDocumentsToMigrate(options?: AxiosRequestConfig) {
        return AdminDocumentToS3MigrationControllerApiFp(this.configuration).countDocumentsToMigrate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentToS3MigrationControllerApi
     */
    public getNexDocumentMigrated(options?: AxiosRequestConfig) {
        return AdminDocumentToS3MigrationControllerApiFp(this.configuration).getNexDocumentMigrated(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentToS3MigrationControllerApi
     */
    public migrateById(requestBody: Array<number>, options?: AxiosRequestConfig) {
        return AdminDocumentToS3MigrationControllerApiFp(this.configuration).migrateById(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} chunkSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocumentToS3MigrationControllerApi
     */
    public migrateChunk(chunkSize: number, options?: AxiosRequestConfig) {
        return AdminDocumentToS3MigrationControllerApiFp(this.configuration).migrateChunk(chunkSize, options).then((request) => request(this.axios, this.basePath));
    }
}
