/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface NeuroscreenDiagnosisResultDto
 */
export interface NeuroscreenDiagnosisResultDto {
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenDiagnosisResultDto
     */
    'diagnosis': NeuroscreenDiagnosisResultDtoDiagnosisEnum;
    /**
     * 
     * @type {number}
     * @memberof NeuroscreenDiagnosisResultDto
     */
    'value': number;
}

/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenDiagnosisResultDtoDiagnosisEnum {
    Headaches = 'HEADACHES',
    MovementIssues = 'MOVEMENT_ISSUES',
    MemoryIssues = 'MEMORY_ISSUES',
    EatingIssues = 'EATING_ISSUES',
    ConsciousnessIssues = 'CONSCIOUSNESS_ISSUES',
    PsychomotorDevelopmentIssues = 'PSYCHOMOTOR_DEVELOPMENT_ISSUES',
    Epilepsy = 'EPILEPSY',
    SpineIssues = 'SPINE_ISSUES',
    MutipleSclerosis = 'MUTIPLE_SCLEROSIS',
    Dizziness = 'DIZZINESS',
    Autism = 'AUTISM',
    OtherSymptoms = 'OTHER_SYMPTOMS'
}


