export default {
  "AD": {
    "code": "AD",
    "name": "Андорра"
  },
  "AE": {
    "code": "AE",
    "name": "Об'єднані Арабські Емірати"
  },
  "AF": {
    "code": "AF",
    "name": "Афганістан"
  },
  "AG": {
    "code": "AG",
    "name": "Антигуа та Барбуда"
  },
  "AI": {
    "code": "AI",
    "name": "Ангілья"
  },
  "AL": {
    "code": "AL",
    "name": "Албанія"
  },
  "AM": {
    "code": "AM",
    "name": "Вірменія"
  },
  "AO": {
    "code": "AO",
    "name": "Ангола"
  },
  "AQ": {
    "code": "AQ",
    "name": "Антарктида"
  },
  "AR": {
    "code": "AR",
    "name": "Аргентина"
  },
  "AS": {
    "code": "AS",
    "name": "Американське Самоа"
  },
  "AT": {
    "code": "AT",
    "name": "Австрія"
  },
  "AU": {
    "code": "AU",
    "name": "Австралія"
  },
  "AW": {
    "code": "AW",
    "name": "Аруба"
  },
  "AX": {
    "code": "AX",
    "name": "? сухопутні острови"
  },
  "AZ": {
    "code": "AZ",
    "name": "Азербайджан"
  },
  "BA": {
    "code": "BA",
    "name": "Боснія і Герцеговина"
  },
  "BB": {
    "code": "BB",
    "name": "Барбадос"
  },
  "BD": {
    "code": "BD",
    "name": "Бангладеш"
  },
  "BE": {
    "code": "BE",
    "name": "Бельгія"
  },
  "BF": {
    "code": "BF",
    "name": "Буркіна-Фасо"
  },
  "BG": {
    "code": "BG",
    "name": "Болгарія"
  },
  "BH": {
    "code": "BH",
    "name": "Бахрейн"
  },
  "BI": {
    "code": "BI",
    "name": "Бурунді"
  },
  "BJ": {
    "code": "BJ",
    "name": "Бенін"
  },
  "BL": {
    "code": "BL",
    "name": "Сен-Бартелемі"
  },
  "BM": {
    "code": "BM",
    "name": "Бермудські острови"
  },
  "BN": {
    "code": "BN",
    "name": "Бруней-Даруссалам"
  },
  "BO": {
    "code": "BO",
    "name": "Болівія, багатонаціональний штат Росія"
  },
  "BQ": {
    "code": "BQ",
    "name": "Бонейр, Сінт Євстатій і Саба"
  },
  "BR": {
    "code": "BR",
    "name": "Бразилія"
  },
  "BS": {
    "code": "BS",
    "name": "Багамські острови"
  },
  "BT": {
    "code": "BT",
    "name": "Бутан"
  },
  "BV": {
    "code": "BV",
    "name": "Острів Буве"
  },
  "BW": {
    "code": "BW",
    "name": "Ботсвана"
  },
  "BY": {
    "code": "BY",
    "name": "Білорусь"
  },
  "BZ": {
    "code": "BZ",
    "name": "Беліз"
  },
  "CA": {
    "code": "CA",
    "name": "Канада"
  },
  "CC": {
    "code": "CC",
    "name": "Кокосові (Кілінг) острови"
  },
  "CD": {
    "code": "CD",
    "name": "Конго, Демократична Республіка Росія"
  },
  "CF": {
    "code": "CF",
    "name": "Центральноафриканська Республіка"
  },
  "CG": {
    "code": "CG",
    "name": "Конго"
  },
  "CH": {
    "code": "CH",
    "name": "Швейцарія"
  },
  "CI": {
    "code": "CI",
    "name": "Кот-д'Івуар"
  },
  "CK": {
    "code": "CK",
    "name": "Острови Кука"
  },
  "CL": {
    "code": "CL",
    "name": "Чилі"
  },
  "CM": {
    "code": "CM",
    "name": "Камерун"
  },
  "CN": {
    "code": "CN",
    "name": "Китай"
  },
  "CO": {
    "code": "CO",
    "name": "Колумбія"
  },
  "CR": {
    "code": "CR",
    "name": "Коста-Ріка"
  },
  "CU": {
    "code": "CU",
    "name": "Куба"
  },
  "CV": {
    "code": "CV",
    "name": "Кабо-Верде"
  },
  "CW": {
    "code": "CW",
    "name": "Кюрасао"
  },
  "CX": {
    "code": "CX",
    "name": "Острів Різдва"
  },
  "CY": {
    "code": "CY",
    "name": "Кіпр"
  },
  "CZ": {
    "code": "CZ",
    "name": "Чеська Республіка"
  },
  "DE": {
    "code": "DE",
    "name": "Німеччина"
  },
  "DJ": {
    "code": "DJ",
    "name": "Джибуті"
  },
  "DK": {
    "code": "DK",
    "name": "Данія"
  },
  "DM": {
    "code": "DM",
    "name": "Домініка"
  },
  "DO": {
    "code": "DO",
    "name": "Домініканська республіка"
  },
  "DZ": {
    "code": "DZ",
    "name": "Алжир"
  },
  "EC": {
    "code": "EC",
    "name": "Еквадор"
  },
  "EE": {
    "code": "EE",
    "name": "Естонія"
  },
  "EG": {
    "code": "EG",
    "name": "Єгипет"
  },
  "EH": {
    "code": "EH",
    "name": "Західна Сахара"
  },
  "ER": {
    "code": "ER",
    "name": "Еритрея"
  },
  "ES": {
    "code": "ES",
    "name": "Іспанія"
  },
  "ET": {
    "code": "ET",
    "name": "Ефіопія"
  },
  "FI": {
    "code": "FI",
    "name": "Фінляндія"
  },
  "FJ": {
    "code": "FJ",
    "name": "Фіджі"
  },
  "FK": {
    "code": "FK",
    "name": "Фолклендські острови (Мальвінські острови)"
  },
  "FM": {
    "code": "FM",
    "name": "Мікронезія, Федеративні Штати Росії"
  },
  "FO": {
    "code": "FO",
    "name": "Фарерські острови"
  },
  "FR": {
    "code": "FR",
    "name": "Франція"
  },
  "GA": {
    "code": "GA",
    "name": "Габон"
  },
  "GB": {
    "code": "GB",
    "name": "Об'єднане Королівство"
  },
  "GD": {
    "code": "GD",
    "name": "Гренада"
  },
  "GE": {
    "code": "GE",
    "name": "Грузія"
  },
  "GF": {
    "code": "GF",
    "name": "Французька Гвіана"
  },
  "GG": {
    "code": "GG",
    "name": "Гернсі"
  },
  "GH": {
    "code": "GH",
    "name": "Гана"
  },
  "GI": {
    "code": "GI",
    "name": "Гібралтар"
  },
  "GL": {
    "code": "GL",
    "name": "Гренландія"
  },
  "GM": {
    "code": "GM",
    "name": "Гамбія"
  },
  "GN": {
    "code": "GN",
    "name": "Гвінея"
  },
  "GP": {
    "code": "GP",
    "name": "Гваделупа"
  },
  "GQ": {
    "code": "GQ",
    "name": "Екваторіальна Гвінея"
  },
  "GR": {
    "code": "GR",
    "name": "Греція"
  },
  "GS": {
    "code": "GS",
    "name": "Південна Джорджія та Південні Сандвічеві острови"
  },
  "GT": {
    "code": "GT",
    "name": "Гватемала"
  },
  "GU": {
    "code": "GU",
    "name": "Гуам"
  },
  "GW": {
    "code": "GW",
    "name": "Гвінея-Бісау"
  },
  "GY": {
    "code": "GY",
    "name": "Гайана"
  },
  "HK": {
    "code": "HK",
    "name": "Гонконг"
  },
  "HM": {
    "code": "HM",
    "name": "Острів Херд і острови Макдональд"
  },
  "HN": {
    "code": "HN",
    "name": "Гондурас"
  },
  "HR": {
    "code": "HR",
    "name": "Хорватія"
  },
  "HT": {
    "code": "HT",
    "name": "Гаїті"
  },
  "HU": {
    "code": "HU",
    "name": "Угорщина"
  },
  "ID": {
    "code": "ID",
    "name": "Індонезія"
  },
  "IE": {
    "code": "IE",
    "name": "Ірландія"
  },
  "IL": {
    "code": "IL",
    "name": "Ізраїль"
  },
  "IM": {
    "code": "IM",
    "name": "Острів Мен"
  },
  "IN": {
    "code": "IN",
    "name": "Індія"
  },
  "IO": {
    "code": "IO",
    "name": "Британська територія в Індійському океані"
  },
  "IQ": {
    "code": "IQ",
    "name": "Ірак"
  },
  "IR": {
    "code": "IR",
    "name": "Іран, Ісламська Республіка"
  },
  "IS": {
    "code": "IS",
    "name": "Ісландія"
  },
  "IT": {
    "code": "IT",
    "name": "Італія"
  },
  "JE": {
    "code": "JE",
    "name": "Джерсі"
  },
  "JM": {
    "code": "JM",
    "name": "Ямайка"
  },
  "JO": {
    "code": "JO",
    "name": "Йорданія"
  },
  "JP": {
    "code": "JP",
    "name": "Японія"
  },
  "KE": {
    "code": "KE",
    "name": "Кенія"
  },
  "KG": {
    "code": "KG",
    "name": "Киргизстан"
  },
  "KH": {
    "code": "KH",
    "name": "Камбоджа"
  },
  "KI": {
    "code": "KI",
    "name": "Кірібаті"
  },
  "KM": {
    "code": "KM",
    "name": "Коморські острови"
  },
  "KN": {
    "code": "KN",
    "name": "Сент-Кітс і Невіс"
  },
  "KP": {
    "code": "KP",
    "name": "Корея, Народно-Демократична Республіка"
  },
  "KR": {
    "code": "KR",
    "name": "Корея, Республіка"
  },
  "KW": {
    "code": "KW",
    "name": "Кувейт"
  },
  "KY": {
    "code": "KY",
    "name": "Кайманові острови"
  },
  "KZ": {
    "code": "KZ",
    "name": "Казахстан"
  },
  "LA": {
    "code": "LA",
    "name": "Лаоська Народно-Демократична Республіка"
  },
  "LB": {
    "code": "LB",
    "name": "Ліван"
  },
  "LC": {
    "code": "LC",
    "name": "Сент-Люсія"
  },
  "LI": {
    "code": "LI",
    "name": "Ліхтенштейн"
  },
  "LK": {
    "code": "LK",
    "name": "Шрі Ланка"
  },
  "LR": {
    "code": "LR",
    "name": "Ліберія"
  },
  "LS": {
    "code": "LS",
    "name": "Лесото"
  },
  "LT": {
    "code": "LT",
    "name": "Литва"
  },
  "LU": {
    "code": "LU",
    "name": "Люксембург"
  },
  "LV": {
    "code": "LV",
    "name": "Латвія"
  },
  "LY": {
    "code": "LY",
    "name": "Лівія"
  },
  "MA": {
    "code": "MA",
    "name": "Марокко"
  },
  "MC": {
    "code": "MC",
    "name": "Монако"
  },
  "MD": {
    "code": "MD",
    "name": "Молдова, Республіка"
  },
  "ME": {
    "code": "ME",
    "name": "Чорногорія"
  },
  "MF": {
    "code": "MF",
    "name": "Сен-Мартен (французька частина)"
  },
  "MG": {
    "code": "MG",
    "name": "Мадагаскар"
  },
  "MH": {
    "code": "MH",
    "name": "Маршаллові острови"
  },
  "MK": {
    "code": "MK",
    "name": "Македонія, колишня Югославська Республіка Росія"
  },
  "ML": {
    "code": "ML",
    "name": "Малі"
  },
  "MM": {
    "code": "MM",
    "name": "М'янма"
  },
  "MN": {
    "code": "MN",
    "name": "Монголія"
  },
  "MO": {
    "code": "MO",
    "name": "Макао"
  },
  "MP": {
    "code": "MP",
    "name": "Північні Маріанські острови"
  },
  "MQ": {
    "code": "MQ",
    "name": "Мартініка"
  },
  "MR": {
    "code": "MR",
    "name": "Мавританія"
  },
  "MS": {
    "code": "MS",
    "name": "Монтсеррат"
  },
  "MT": {
    "code": "MT",
    "name": "Мальта"
  },
  "MU": {
    "code": "MU",
    "name": "Маврикій"
  },
  "MV": {
    "code": "MV",
    "name": "Мальдіви"
  },
  "MW": {
    "code": "MW",
    "name": "Малаві"
  },
  "MX": {
    "code": "MX",
    "name": "Мексика"
  },
  "MY": {
    "code": "MY",
    "name": "Малайзія"
  },
  "MZ": {
    "code": "MZ",
    "name": "Мозамбік"
  },
  "NA": {
    "code": "NA",
    "name": "Намібія"
  },
  "NC": {
    "code": "NC",
    "name": "Нова Каледонія"
  },
  "NE": {
    "code": "NE",
    "name": "Нігер"
  },
  "NF": {
    "code": "NF",
    "name": "Острів Норфолк"
  },
  "NG": {
    "code": "NG",
    "name": "Нігерія"
  },
  "NI": {
    "code": "NI",
    "name": "Нікарагуа"
  },
  "NL": {
    "code": "NL",
    "name": "Нідерланди"
  },
  "NO": {
    "code": "NO",
    "name": "Норвегія"
  },
  "NP": {
    "code": "NP",
    "name": "Непал"
  },
  "NR": {
    "code": "NR",
    "name": "Науру"
  },
  "NU": {
    "code": "NU",
    "name": "Ніуе"
  },
  "NZ": {
    "code": "NZ",
    "name": "Нова Зеландія"
  },
  "OM": {
    "code": "OM",
    "name": "Оман"
  },
  "PA": {
    "code": "PA",
    "name": "Панама"
  },
  "PE": {
    "code": "PE",
    "name": "Перу"
  },
  "PF": {
    "code": "PF",
    "name": "Французька Полінезія"
  },
  "PG": {
    "code": "PG",
    "name": "Папуа-Нова Гвінея"
  },
  "PH": {
    "code": "PH",
    "name": "Філіппіни"
  },
  "PK": {
    "code": "PK",
    "name": "Пакистан"
  },
  "PL": {
    "code": "PL",
    "name": "Польща"
  },
  "PM": {
    "code": "PM",
    "name": "Сен-П'єр і Мікелон"
  },
  "PN": {
    "code": "PN",
    "name": "Піткерн"
  },
  "PR": {
    "code": "PR",
    "name": "Пуерто-Рико"
  },
  "PS": {
    "code": "PS",
    "name": "Палестина, штат Росія"
  },
  "PT": {
    "code": "PT",
    "name": "Португалія"
  },
  "PW": {
    "code": "PW",
    "name": "Палау"
  },
  "PY": {
    "code": "PY",
    "name": "Парагвай"
  },
  "QA": {
    "code": "QA",
    "name": "Катар"
  },
  "RE": {
    "code": "RE",
    "name": "Реюньйон"
  },
  "RO": {
    "code": "RO",
    "name": "Румунія"
  },
  "RS": {
    "code": "RS",
    "name": "Сербія"
  },
  "RU": {
    "code": "RU",
    "name": "Російська Федерація"
  },
  "RW": {
    "code": "RW",
    "name": "Руанда"
  },
  "SA": {
    "code": "SA",
    "name": "Саудівська Аравія"
  },
  "SB": {
    "code": "SB",
    "name": "Соломонові острови"
  },
  "SC": {
    "code": "SC",
    "name": "Сейшельські острови"
  },
  "SD": {
    "code": "SD",
    "name": "Судан"
  },
  "SE": {
    "code": "SE",
    "name": "Швеція"
  },
  "SG": {
    "code": "SG",
    "name": "Сінгапур"
  },
  "SH": {
    "code": "SH",
    "name": "Свята Олена, Вознесіння і Трістан да Кунья"
  },
  "SI": {
    "code": "SI",
    "name": "Словенія"
  },
  "SJ": {
    "code": "SJ",
    "name": "Шпіцберген та Ян Маєн"
  },
  "SK": {
    "code": "SK",
    "name": "Словаччина"
  },
  "SL": {
    "code": "SL",
    "name": "Сьєрра-Леоне"
  },
  "SM": {
    "code": "SM",
    "name": "Сан-Марино"
  },
  "SN": {
    "code": "SN",
    "name": "Сенегал"
  },
  "SO": {
    "code": "SO",
    "name": "Сомалі"
  },
  "SR": {
    "code": "SR",
    "name": "Суринам"
  },
  "SS": {
    "code": "SS",
    "name": "Південний Судан"
  },
  "ST": {
    "code": "ST",
    "name": "Сан-Томе і Принсіпі"
  },
  "SV": {
    "code": "SV",
    "name": "Сальвадор"
  },
  "SX": {
    "code": "SX",
    "name": "Сінт-Мартен (голландська частина)"
  },
  "SY": {
    "code": "SY",
    "name": "Сирійська Арабська Республіка"
  },
  "SZ": {
    "code": "SZ",
    "name": "Свазіленд"
  },
  "TC": {
    "code": "TC",
    "name": "Острови Теркс і Кайкос"
  },
  "TD": {
    "code": "TD",
    "name": "Чад"
  },
  "TF": {
    "code": "TF",
    "name": "Французькі південні території"
  },
  "TG": {
    "code": "TG",
    "name": "Йти"
  },
  "TH": {
    "code": "TH",
    "name": "Таїланд"
  },
  "TJ": {
    "code": "TJ",
    "name": "Таджикистан"
  },
  "TK": {
    "code": "TK",
    "name": "Токелау"
  },
  "TL": {
    "code": "TL",
    "name": "Восточний Тимор"
  },
  "TM": {
    "code": "TM",
    "name": "Туркменістан"
  },
  "TN": {
    "code": "TN",
    "name": "Туніс"
  },
  "TO": {
    "code": "TO",
    "name": "Тонга"
  },
  "TR": {
    "code": "TR",
    "name": "Туреччина"
  },
  "TT": {
    "code": "TT",
    "name": "Тринідад і Тобаго"
  },
  "TV": {
    "code": "TV",
    "name": "Тувалу"
  },
  "TW": {
    "code": "TW",
    "name": "Тайвань, провінція Китаю"
  },
  "TZ": {
    "code": "TZ",
    "name": "Танзанія, Об'єднана Республіка"
  },
  "UA": {
    "code": "UA",
    "name": "Україна"
  },
  "UG": {
    "code": "UG",
    "name": "Уганда"
  },
  "UM": {
    "code": "UM",
    "name": "Малі віддалені острови США"
  },
  "US": {
    "code": "US",
    "name": "Сполучені Штати"
  },
  "UY": {
    "code": "UY",
    "name": "Уругвай"
  },
  "UZ": {
    "code": "UZ",
    "name": "Узбекистан"
  },
  "VA": {
    "code": "VA",
    "name": "Святий Престол (Ватикан)"
  },
  "VC": {
    "code": "VC",
    "name": "Сент-Вінсент і Гренадини"
  },
  "VE": {
    "code": "VE",
    "name": "Венесуела, Боліваріанська Республіка"
  },
  "VG": {
    "code": "VG",
    "name": "Віргінські острови, Британські"
  },
  "VI": {
    "code": "VI",
    "name": "Віргінські острови, США"
  },
  "VN": {
    "code": "VN",
    "name": "В'єтнам"
  },
  "VU": {
    "code": "VU",
    "name": "Вануату"
  },
  "WF": {
    "code": "WF",
    "name": "Уолліс і Футуна"
  },
  "WS": {
    "code": "WS",
    "name": "Самоа"
  },
  "YE": {
    "code": "YE",
    "name": "Ємен"
  },
  "YT": {
    "code": "YT",
    "name": "Майотта"
  },
  "ZA": {
    "code": "ZA",
    "name": "Південна Африка"
  },
  "ZM": {
    "code": "ZM",
    "name": "Замбія"
  },
  "ZW": {
    "code": "ZW",
    "name": "Зімбабве"
  }
}