/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { SeizuresBreakDto } from './seizures-break-dto';

/**
 * 
 * @export
 * @interface SeizuresHistoryDto
 */
export interface SeizuresHistoryDto {
    /**
     * 
     * @type {Array<SeizuresBreakDto>}
     * @memberof SeizuresHistoryDto
     */
    'breaks': Array<SeizuresBreakDto>;
    /**
     * 
     * @type {string}
     * @memberof SeizuresHistoryDto
     */
    'firstSeizureDate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SeizuresHistoryDto
     */
    'followingSeizuresDates': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SeizuresHistoryDto
     */
    'patientSeizureTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SeizuresHistoryDto
     */
    'patientSeizureTypeName'?: string;
    /**
     * 
     * @type {number}
     * @memberof SeizuresHistoryDto
     */
    'seizuresFrequencyNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof SeizuresHistoryDto
     */
    'seizuresFrequencyPeriod'?: SeizuresHistoryDtoSeizuresFrequencyPeriodEnum;
    /**
     * 
     * @type {number}
     * @memberof SeizuresHistoryDto
     */
    'seizuresNumber'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum SeizuresHistoryDtoSeizuresFrequencyPeriodEnum {
    Year = 'YEAR',
    Month = 'MONTH',
    Day = 'DAY'
}


