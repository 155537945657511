/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ExternalPersonelDto } from './external-personel-dto';
import { MedicalResultDescriptionDto } from './medical-result-description-dto';
import { MedicalResultFileDto } from './medical-result-file-dto';
import { MedicalResultTypeDto } from './medical-result-type-dto';

/**
 * 
 * @export
 * @interface MedicalResultDto
 */
export interface MedicalResultDto {
    /**
     * 
     * @type {number}
     * @memberof MedicalResultDto
     */
    'consultationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalResultDto
     */
    'creationDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedicalResultDto
     */
    'doctorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalResultDto
     */
    'documentType'?: MedicalResultDtoDocumentTypeEnum;
    /**
     * 
     * @type {ExternalPersonelDto}
     * @memberof MedicalResultDto
     */
    'externalPersonel'?: ExternalPersonelDto;
    /**
     * 
     * @type {number}
     * @memberof MedicalResultDto
     */
    'externalPersonelId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicalResultDto
     */
    'id'?: number;
    /**
     * 
     * @type {Array<MedicalResultDescriptionDto>}
     * @memberof MedicalResultDto
     */
    'medicalResultDescriptions'?: Array<MedicalResultDescriptionDto>;
    /**
     * 
     * @type {Array<MedicalResultFileDto>}
     * @memberof MedicalResultDto
     */
    'medicalResultFiles'?: Array<MedicalResultFileDto>;
    /**
     * 
     * @type {number}
     * @memberof MedicalResultDto
     */
    'patientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalResultDto
     */
    'resultDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalResultDto
     */
    'systemType'?: MedicalResultDtoSystemTypeEnum;
    /**
     * 
     * @type {MedicalResultTypeDto}
     * @memberof MedicalResultDto
     */
    'type'?: MedicalResultTypeDto;
}

/**
    * @export
    * @enum {string}
    */
export enum MedicalResultDtoDocumentTypeEnum {
    HospitalInformationCard = 'HOSPITAL_INFORMATION_CARD',
    DiseaseHistory = 'DISEASE_HISTORY',
    VideoEvent = 'VIDEO_EVENT',
    LaboratoryTestResult = 'LABORATORY_TEST_RESULT',
    VisualResultMriFile = 'VISUAL_RESULT_MRI_FILE',
    VisualResultMriDesc = 'VISUAL_RESULT_MRI_DESC',
    VisualResultCtFile = 'VISUAL_RESULT_CT_FILE',
    VisualResultCtDesc = 'VISUAL_RESULT_CT_DESC',
    VisualResultEegFile = 'VISUAL_RESULT_EEG_FILE',
    VisualResultEegDesc = 'VISUAL_RESULT_EEG_DESC',
    VisualResultAdditionalFile = 'VISUAL_RESULT_ADDITIONAL_FILE',
    VisualResultAdditionalDesc = 'VISUAL_RESULT_ADDITIONAL_DESC',
    Other = 'OTHER',
    PatientCertificate = 'PATIENT_CERTIFICATE',
    StateCertificate = 'STATE_CERTIFICATE',
    EegDescription = 'EEG_DESCRIPTION'
}
/**
    * @export
    * @enum {string}
    */
export enum MedicalResultDtoSystemTypeEnum {
    External = 'EXTERNAL',
    HomeEeg = 'HOME_EEG',
    HomeVeeg = 'HOME_VEEG'
}


