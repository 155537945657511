/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { MessageUserDto } from './message-user-dto';

/**
 * 
 * @export
 * @interface MessageDto
 */
export interface MessageDto {
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'content': string;
    /**
     * 
     * @type {MessageUserDto}
     * @memberof MessageDto
     */
    'fromUser': MessageUserDto;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof MessageDto
     */
    'isEmergency'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'marking'?: MessageDtoMarkingEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'responseDeadline'?: string;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    'severity'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'status'?: MessageDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'subtype'?: MessageDtoSubtypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'timeCreated': string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'timeResponded'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'timeSent': string;
    /**
     * 
     * @type {MessageUserDto}
     * @memberof MessageDto
     */
    'toUser': MessageUserDto;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'type': MessageDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    'visitId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'visitPublicId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MessageDtoMarkingEnum {
    Marked = 'MARKED',
    NotMarked = 'NOT_MARKED'
}
/**
    * @export
    * @enum {string}
    */
export enum MessageDtoStatusEnum {
    New = 'NEW',
    Sent = 'SENT',
    Read = 'READ',
    Error = 'ERROR'
}
/**
    * @export
    * @enum {string}
    */
export enum MessageDtoSubtypeEnum {
    Prescribe = 'PRESCRIBE',
    LeadDoctor = 'LEAD_DOCTOR',
    NoteAdded = 'NOTE_ADDED'
}
/**
    * @export
    * @enum {string}
    */
export enum MessageDtoTypeEnum {
    Email = 'EMAIL',
    Sms = 'SMS',
    Sys = 'SYS',
    Chat = 'CHAT'
}


