/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AddressDto } from './address-dto';
import { PersonalDocumentEnum } from './personal-document-enum';

/**
 * 
 * @export
 * @interface LegalGuardianDto
 */
export interface LegalGuardianDto {
    /**
     * 
     * @type {AddressDto}
     * @memberof LegalGuardianDto
     */
    'address'?: AddressDto;
    /**
     * 
     * @type {string}
     * @memberof LegalGuardianDto
     */
    'affinity'?: LegalGuardianDtoAffinityEnum;
    /**
     * 
     * @type {string}
     * @memberof LegalGuardianDto
     */
    'documentNumber'?: string;
    /**
     * 
     * @type {PersonalDocumentEnum}
     * @memberof LegalGuardianDto
     */
    'documentType'?: PersonalDocumentEnum;
    /**
     * 
     * @type {string}
     * @memberof LegalGuardianDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalGuardianDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {number}
     * @memberof LegalGuardianDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LegalGuardianDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalGuardianDto
     */
    'pesel'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalGuardianDto
     */
    'phoneNumber'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LegalGuardianDtoAffinityEnum {
    LegalGuardian = 'LEGAL_GUARDIAN',
    Curator = 'CURATOR',
    Spouse = 'SPOUSE',
    Parent = 'PARENT',
    Adopter = 'ADOPTER',
    Guardian = 'GUARDIAN'
}


