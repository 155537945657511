/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DisabilityCertificateDto } from '../model';
// @ts-ignore
import { DrivingCertificateDto } from '../model';
// @ts-ignore
import { HealthStateCertificateDto } from '../model';
/**
 * StateCertificateControllerApi - axios parameter creator
 * @export
 */
export const StateCertificateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DisabilityCertificateDto} disabilityCertificateDto 
         * @param {boolean} [generateMedicalDoc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDisabilityCertificate: async (disabilityCertificateDto: DisabilityCertificateDto, generateMedicalDoc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'disabilityCertificateDto' is not null or undefined
            assertParamExists('addDisabilityCertificate', 'disabilityCertificateDto', disabilityCertificateDto)
            const localVarPath = `/api/state-certificate/disability-certificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (generateMedicalDoc !== undefined) {
                localVarQueryParameter['generateMedicalDoc'] = generateMedicalDoc;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(disabilityCertificateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DrivingCertificateDto} drivingCertificateDto 
         * @param {boolean} [generateMedicalDoc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDrivingCertificate: async (drivingCertificateDto: DrivingCertificateDto, generateMedicalDoc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'drivingCertificateDto' is not null or undefined
            assertParamExists('addDrivingCertificate', 'drivingCertificateDto', drivingCertificateDto)
            const localVarPath = `/api/state-certificate/driving-certificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (generateMedicalDoc !== undefined) {
                localVarQueryParameter['generateMedicalDoc'] = generateMedicalDoc;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(drivingCertificateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {HealthStateCertificateDto} healthStateCertificateDto 
         * @param {boolean} [generateMedicalDoc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHealthStateCertificate: async (healthStateCertificateDto: HealthStateCertificateDto, generateMedicalDoc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthStateCertificateDto' is not null or undefined
            assertParamExists('addHealthStateCertificate', 'healthStateCertificateDto', healthStateCertificateDto)
            const localVarPath = `/api/state-certificate/health-certificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (generateMedicalDoc !== undefined) {
                localVarQueryParameter['generateMedicalDoc'] = generateMedicalDoc;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(healthStateCertificateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStateCertificate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteStateCertificate', 'id', id)
            const localVarPath = `/api/state-certificate/document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('download', 'id', id)
            const localVarPath = `/api/state-certificate/document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartTreatmentDate: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getStartTreatmentDate', 'patientId', patientId)
            const localVarPath = `/api/state-certificate/treatment-start/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StateCertificateControllerApi - functional programming interface
 * @export
 */
export const StateCertificateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StateCertificateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DisabilityCertificateDto} disabilityCertificateDto 
         * @param {boolean} [generateMedicalDoc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDisabilityCertificate(disabilityCertificateDto: DisabilityCertificateDto, generateMedicalDoc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDisabilityCertificate(disabilityCertificateDto, generateMedicalDoc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DrivingCertificateDto} drivingCertificateDto 
         * @param {boolean} [generateMedicalDoc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDrivingCertificate(drivingCertificateDto: DrivingCertificateDto, generateMedicalDoc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDrivingCertificate(drivingCertificateDto, generateMedicalDoc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {HealthStateCertificateDto} healthStateCertificateDto 
         * @param {boolean} [generateMedicalDoc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addHealthStateCertificate(healthStateCertificateDto: HealthStateCertificateDto, generateMedicalDoc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addHealthStateCertificate(healthStateCertificateDto, generateMedicalDoc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStateCertificate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStateCertificate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.download(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStartTreatmentDate(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStartTreatmentDate(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StateCertificateControllerApi - factory interface
 * @export
 */
export const StateCertificateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StateCertificateControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {DisabilityCertificateDto} disabilityCertificateDto 
         * @param {boolean} [generateMedicalDoc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDisabilityCertificate(disabilityCertificateDto: DisabilityCertificateDto, generateMedicalDoc?: boolean, options?: any): Promise<void> {
            return localVarFp.addDisabilityCertificate(disabilityCertificateDto, generateMedicalDoc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DrivingCertificateDto} drivingCertificateDto 
         * @param {boolean} [generateMedicalDoc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDrivingCertificate(drivingCertificateDto: DrivingCertificateDto, generateMedicalDoc?: boolean, options?: any): Promise<void> {
            return localVarFp.addDrivingCertificate(drivingCertificateDto, generateMedicalDoc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HealthStateCertificateDto} healthStateCertificateDto 
         * @param {boolean} [generateMedicalDoc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHealthStateCertificate(healthStateCertificateDto: HealthStateCertificateDto, generateMedicalDoc?: boolean, options?: any): Promise<void> {
            return localVarFp.addHealthStateCertificate(healthStateCertificateDto, generateMedicalDoc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStateCertificate(id: number, options?: any): Promise<void> {
            return localVarFp.deleteStateCertificate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download(id: number, options?: any): Promise<any> {
            return localVarFp.download(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartTreatmentDate(patientId: number, options?: any): Promise<string> {
            return localVarFp.getStartTreatmentDate(patientId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StateCertificateControllerApi - object-oriented interface
 * @export
 * @class StateCertificateControllerApi
 * @extends {BaseAPI}
 */
export class StateCertificateControllerApi extends BaseAPI {
    /**
     * 
     * @param {DisabilityCertificateDto} disabilityCertificateDto 
     * @param {boolean} [generateMedicalDoc] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateCertificateControllerApi
     */
    public addDisabilityCertificate(disabilityCertificateDto: DisabilityCertificateDto, generateMedicalDoc?: boolean, options?: AxiosRequestConfig) {
        return StateCertificateControllerApiFp(this.configuration).addDisabilityCertificate(disabilityCertificateDto, generateMedicalDoc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DrivingCertificateDto} drivingCertificateDto 
     * @param {boolean} [generateMedicalDoc] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateCertificateControllerApi
     */
    public addDrivingCertificate(drivingCertificateDto: DrivingCertificateDto, generateMedicalDoc?: boolean, options?: AxiosRequestConfig) {
        return StateCertificateControllerApiFp(this.configuration).addDrivingCertificate(drivingCertificateDto, generateMedicalDoc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HealthStateCertificateDto} healthStateCertificateDto 
     * @param {boolean} [generateMedicalDoc] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateCertificateControllerApi
     */
    public addHealthStateCertificate(healthStateCertificateDto: HealthStateCertificateDto, generateMedicalDoc?: boolean, options?: AxiosRequestConfig) {
        return StateCertificateControllerApiFp(this.configuration).addHealthStateCertificate(healthStateCertificateDto, generateMedicalDoc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateCertificateControllerApi
     */
    public deleteStateCertificate(id: number, options?: AxiosRequestConfig) {
        return StateCertificateControllerApiFp(this.configuration).deleteStateCertificate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateCertificateControllerApi
     */
    public download(id: number, options?: AxiosRequestConfig) {
        return StateCertificateControllerApiFp(this.configuration).download(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StateCertificateControllerApi
     */
    public getStartTreatmentDate(patientId: number, options?: AxiosRequestConfig) {
        return StateCertificateControllerApiFp(this.configuration).getStartTreatmentDate(patientId, options).then((request) => request(this.axios, this.basePath));
    }
}
