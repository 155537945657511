/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HashtagConfigurationDto } from '../model';
/**
 * AdminHashtagControllerApi - axios parameter creator
 * @export
 */
export const AdminHashtagControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} hashtag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHashtag: async (hashtag: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hashtag' is not null or undefined
            assertParamExists('addHashtag', 'hashtag', hashtag)
            const localVarPath = `/api/admin/hashtag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (hashtag !== undefined) {
                localVarQueryParameter['hashtag'] = hashtag;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHashtag: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteHashtag', 'id', id)
            const localVarPath = `/api/admin/hashtag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} hashtag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editHashtag: async (id: number, hashtag: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editHashtag', 'id', id)
            // verify required parameter 'hashtag' is not null or undefined
            assertParamExists('editHashtag', 'hashtag', hashtag)
            const localVarPath = `/api/admin/hashtag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (hashtag !== undefined) {
                localVarQueryParameter['hashtag'] = hashtag;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignedPatients: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAssignedPatients', 'id', id)
            const localVarPath = `/api/admin/hashtag/{id}/patients`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHashtags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/hashtag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminHashtagControllerApi - functional programming interface
 * @export
 */
export const AdminHashtagControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminHashtagControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} hashtag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addHashtag(hashtag: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<HashtagConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addHashtag(hashtag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHashtag(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHashtag(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} hashtag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editHashtag(id: number, hashtag: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<HashtagConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editHashtag(id, hashtag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignedPatients(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignedPatients(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHashtags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<HashtagConfigurationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHashtags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminHashtagControllerApi - factory interface
 * @export
 */
export const AdminHashtagControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminHashtagControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} hashtag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHashtag(hashtag: string, options?: any): Promise<HashtagConfigurationDto> {
            return localVarFp.addHashtag(hashtag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHashtag(id: number, options?: any): Promise<void> {
            return localVarFp.deleteHashtag(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} hashtag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editHashtag(id: number, hashtag: string, options?: any): Promise<HashtagConfigurationDto> {
            return localVarFp.editHashtag(id, hashtag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignedPatients(id: number, options?: any): Promise<Array<string>> {
            return localVarFp.getAssignedPatients(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHashtags(options?: any): Promise<Array<HashtagConfigurationDto>> {
            return localVarFp.getHashtags(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminHashtagControllerApi - object-oriented interface
 * @export
 * @class AdminHashtagControllerApi
 * @extends {BaseAPI}
 */
export class AdminHashtagControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} hashtag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminHashtagControllerApi
     */
    public addHashtag(hashtag: string, options?: AxiosRequestConfig) {
        return AdminHashtagControllerApiFp(this.configuration).addHashtag(hashtag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminHashtagControllerApi
     */
    public deleteHashtag(id: number, options?: AxiosRequestConfig) {
        return AdminHashtagControllerApiFp(this.configuration).deleteHashtag(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} hashtag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminHashtagControllerApi
     */
    public editHashtag(id: number, hashtag: string, options?: AxiosRequestConfig) {
        return AdminHashtagControllerApiFp(this.configuration).editHashtag(id, hashtag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminHashtagControllerApi
     */
    public getAssignedPatients(id: number, options?: AxiosRequestConfig) {
        return AdminHashtagControllerApiFp(this.configuration).getAssignedPatients(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminHashtagControllerApi
     */
    public getHashtags(options?: AxiosRequestConfig) {
        return AdminHashtagControllerApiFp(this.configuration).getHashtags(options).then((request) => request(this.axios, this.basePath));
    }
}
