/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { EReferralDropdownEntry } from './ereferral-dropdown-entry';

/**
 * 
 * @export
 * @interface EReferralDropdownList
 */
export interface EReferralDropdownList {
    /**
     * 
     * @type {string}
     * @memberof EReferralDropdownList
     */
    'type': EReferralDropdownListTypeEnum;
    /**
     * 
     * @type {Array<EReferralDropdownEntry>}
     * @memberof EReferralDropdownList
     */
    'values': Array<EReferralDropdownEntry>;
}

/**
    * @export
    * @enum {string}
    */
export enum EReferralDropdownListTypeEnum {
    Clinic = 'CLINIC',
    Laboratory = 'LABORATORY',
    Hospital = 'HOSPITAL'
}


