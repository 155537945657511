import Enums from "./enums/enums";
import ServerErrors from "./errors/server-errors";

export default {
  "translations": {
    "$vuetify": {
      "noDataText": "Keine Daten",
      "dataFooter": {
        "itemsPerPageAll": "Zeige alles",
        "pageText": "Navigieren:"
      },
      "dataTable": {
        "sortBy": "Sortiere nach",
        "ariaLabel": {
          "sortNone": "sortNone"
        },
        "itemsPerPageText": "Pro Seite:"
      },
      "timepicker": {
        "am": "Bin",
        "pm": "Uhr"
      },
      "dataIterator": {
        "loadingText": "Daten werden geladen"
      },
      "badge": "Abzeichen",
      "datePicker": {
        "prevMonthAriaLabel": "Vorheriger Monat",
        "nextMonthAriaLabel": "Nächsten Monat"
      }
    },
    "view": {
      "login": {
        "footer": {
          "z1": "Organisatorische Regelungen",
          "z2": "Regelungen zur Erbringung elektronischer Dienstleistungen",
          "z3": "Datenschutzrichtlinie von Neurosphere",
          "z4": "Datenschutzrichtlinie von Neuroterminal"
        },
        "login": {
          "loginTitle": "Loggen Sie sich ein und vereinbaren Sie einen Termin",
          "registerLinkMsg": "Registrieren",
          "loginBtnLbl": "Melden Sie sich bei Neurosphere an",
          "resetPassMsg": "Passwort vergessen?",
          "passwordFieldLabel": "Passwort",
          "loginEmailLabel": "Email",
          "registerMsg": "Sie haben noch kein Konto?"
        },
        "reset": {
          "title": "Passwort vergessen?",
          "subTitle": "An die unten eingegebene Adresse senden wir eine E-Mail mit einem Link zum Festlegen eines neuen Passworts",
          "content1": "Wir haben den Link zur Passwortänderung an folgende Adresse gesendet:",
          "content2": "Überprüfen Sie Ihren E-Mail-Posteingang und klicken Sie auf den Link zur Passwort-Chance",
          "form": {
            "email": "Email",
            "send": "Bestätigen"
          },
          "changePassword": "Kennwort ändern",
          "recoverPassword": "Passwort wiederherstellen",
          "response1": "An die angegebene E-Mail-Adresse wurde eine Nachricht mit einem Bestätigungslink zum Zurücksetzen gesendet.",
          "response2": "Bitte schließen Sie dieses Browserfenster und klicken Sie auf den per E-Mail erhaltenen Link.",
          "sendAgainPrefix": "Sie haben keine E-Mail erhalten:",
          "sendAgain": "Nochmals senden!",
          "contactReception": "oder wenden Sie sich an die Rezeption:",
          "resendBlocked": "Falls Sie keine E-Mail mit einem Link zur Passwortänderung erhalten haben, wenden Sie sich bitte an den Kundendienst."
        },
        "resetConfirm": {
          "title": "Bestätigen Sie die Passwortänderung",
          "form": {
            "password": "Neues Kennwort",
            "passwordConfirm": "Bestätige das Passwort",
            "send": "Bestätigen"
          },
          "response": "Passwort geändert.",
          "loginBtnLbl": "Anmeldung"
        },
        "register": {
          "title": "Willkommen bei Neuroterminal",
          "subtitle1": "Erstellen Sie ein Konto und vereinbaren Sie einen Termin",
          "firstName": "Vorname",
          "lastName": "Familienname, Nachname",
          "email": "Email",
          "password": "Passwort",
          "passwordConfirm": "Bestätige das Passwort",
          "selectAll": "Wählen Sie Alle",
          "isApprove1": "Ich habe die Regeln für die Verarbeitung meiner personenbezogenen Daten gelesen",
          "isApprove2": "Neurosphera sp. z o.o. Datenschutzrichtlinie",
          "isApprove3": "Und",
          "isApprove4": "Datenschutzrichtlinie von Neuroterminal S.A",
          "maptitle": "Wie funktioniert Neuroterminal?",
          "mapSubtitle": "Es ist sehr einfach",
          "cards": [
            "Fill out the registration form to create account in Neurotermianl aplication.",
            "Receive an activation email to confirm your credentials",
            "Log into Neuroterminal and fill out the information in the patient profile.",
            "Choose the doctor and date of the first visit (visit ZERO)",
            "You will pay for it with ease using PayU (bank transfer, BLIK or credit card)",
            "For your convenience we will remind you about your visits by sending you SMS",
            "Each subsequent visit, no matter if it's remote or stationary, you will do it directly from Neuroterminal."
          ],
          "passwordRequirement": {
            "length": "8 Zeichen",
            "upperCase": "Großbuchstaben",
            "lowerCase": "Kleinbuchstaben",
            "digit": "Ziffer",
            "sign": "Sonderzeichen ( ! @ $ \\ - _ . * )"
          },
          "resendMessage": "Der Aktivierungslink wurde erneut gesendet"
        },
        "confirm": {
          "registerSuccessMsg": "Die Registrierung wurde erfolgreich abgeschlossen",
          "passwordChangeSuccessMsg": "das Passwort wurde erfolgreich geändert",
          "goToLoginMsg": "Klicken Sie auf die Schaltfläche unten, um sich bei Neuroterminal anzumelden, dem Portal für Patienten von Neurosphera",
          "outcomeFailureMsg": "Es gab ein Problem mit der Registrierungsbestätigung",
          "outcomeFailureDetMsg": "Bitte versuchen Sie es erneut, indem Sie auf den Link in der E-Mail klicken oder kontaktieren Sie das Neuroterminal-Team",
          "gotTologinBtn": "Gehen Sie zur Anmeldeseite",
          "passwordChangeFailedMsg": "Der Link zum Festlegen Ihres Passworts ist abgelaufen"
        }
      },
      "admin": {
        "settings": {
          "ewusPasswordChanged": "EWUŚ-Passwort geändert",
          "save": "Speichern",
          "ewusPasswordTitle": "EWUŚ-Passwortänderung",
          "ewusPasswordLabel": "Neues Kennwort",
          "removeAccount": "Konto entfernen",
          "registrationDate": "Registrierungsdatum",
          "registrationSource": "Registriert durch",
          "registeredBy": {
            "PATIENT": "Geduldig",
            "DOCPLANNER": "Znany lekarz",
            "APPOINTING_WIDGET": "Widget"
          },
          "deleteAccount": "Konto entfernen",
          "accountNotForDeletion": "Dieses Konto kann nicht entfernt werden",
          "accountDeletionConfirmation": "Möchten Sie dieses Konto wirklich entfernen?",
          "yes": "Ja",
          "no": "NEIN",
          "accountRemovedMsg": "Das Patientenkonto wurde entfernt"
        },
        "notes": {
          "title": "Anmerkungen",
          "current": "Aktuell",
          "archive": "Archiv",
          "mine": "Meins"
        },
        "noteDetails": {
          "added": "Erstellungszeitpunkt",
          "patient": "Geduldig",
          "pesel": "Pesel",
          "address": "Adresse",
          "phone": "Telefon",
          "content": "Inhalt",
          "sharedBy": "Geteilt von",
          "moveToArchive": "Ins Archiv verschieben",
          "save": "Speichern",
          "reply": "Antwort",
          "initialNote": "Arztbrief",
          "yes": "Ja",
          "no": "NEIN",
          "addedFiles": "Dateien hinzugefügt"
        },
        "reassignment": {
          "noReplacementAvailable": "Kein alternativer Arzt zum gleichen Preis",
          "backToVisitToMoveTable": "Zurück zur Liste der zu ersetzenden Termine",
          "visitNotFoundRedirection": "Nie znaleziono wizyty, powrócisz do strony głównej",
          "reassignmentSummary": "Zusammenfassung",
          "visitNotToMove": "Der Besuch kann nicht verschoben werden, da sein Status bereits aktualisiert wurde.",
          "title": "Arztwechsel",
          "currentVisitDate": "Besuchsdatum",
          "currentVisitHeader": "Wechselgeld für Besuch",
          "currentVisitFromPackageHeader": "Wechselgeld für den Besuch aus dem Paket",
          "additionalInfo": "Weitere Informationen",
          "upcomingVisit": "Bevorstehender Patientenbesuch",
          "patient": "Geduldig",
          "doctor": "Arzt",
          "cannotReassignDueToRelatedVisit": "Der Besuch kann nicht aufgrund einer Verbindung zu einem anderen, bereits durchgeführten Besuch verschoben werden"
        },
        "patients": {
          "selectPatient": "Patienten auswählen",
          "name": "Name",
          "email": "E-Mail",
          "pesel": "Pesel"
        },
        "certificates": {
          "issuingDoctor": "Arzt stellt Bescheinigung aus",
          "changeLbl": "Ändern",
          "selectDoctorLbl": "Arzt auswählen",
          "hide": "Verstecken"
        }
      },
      "visits": {
        "DoctorVisits": {
          "titleLbl": "Meine Besuche",
          "returnLbl": "zurückkehren",
          "noDataLbl": "Keine Besuche",
          "noResultsLbl": "Keine Besuche gefunden",
          "filters": {
            "visitTime": {
              "all": "Alle",
              "future": "Zukunft",
              "past": "Vergangenheit"
            },
            "searchPatientLbl": "Suche nach Patient",
            "advancedFiltersLbl": "Erweiterte Suche",
            "advancedFilters": {
              "from": "Stammen aus",
              "betweenDatesLbl": "Tun",
              "till": "Datum bis",
              "type": "Besuchstyp",
              "typeAll": "Alle",
              "clearBtn": "klar",
              "searchBtn": "suchen"
            }
          },
          "table": {
            "headers": {
              "state": "Zustand",
              "lastName": "Familienname, Nachname",
              "firstName": "Vorname",
              "pesel": "PESEL",
              "age": "Alter",
              "visitType": "Besuchstyp",
              "visitCategory": "Kategorie",
              "visitStatus": "Status",
              "dateFrom": "Besuchsdatum",
              "sinceLast": "Seit dem letzten"
            },
            "actions": {
              "summary": "Zusammenfassung des Besuchs",
              "start": "Besuchen"
            }
          }
        }
      },
      "patients": {
        "DoctorPatients": {
          "titleLbl": "Patienten",
          "returnLbl": "zurückkehren",
          "noDataLbl": "Keine Patienten",
          "noResultsLbl": "Keine Besuche gefunden",
          "filters": {
            "scope": {
              "all": "Alle Patienten",
              "my": "Meine Patienten"
            },
            "search": "Suchen",
            "advancedFiltersLbl": "Erweiterte Suche",
            "from": "aus",
            "birthDate": "Geburtsdatum",
            "till": "Zu",
            "clearBtn": "klar",
            "searchBtn": "suchen",
            "tabs": {
              "personal": "personenbezogene Daten",
              "address": "Adressdaten",
              "biomedic": "Biomedizinische Daten",
              "medic": "Medizinische Daten"
            }
          },
          "table": {
            "headers": {
              "lastName": "Familienname, Nachname",
              "firstName": "Vorname",
              "medicalHistory": "Krankengeschichte",
              "pesel": "PESEL",
              "email": "Email",
              "age": "Alter",
              "sex": "Sex",
              "leadDoctor": "Leitender Arzt",
              "personalData": "persönliche Daten",
              "phone": "Telefonnummer",
              "city": "Stadt",
              "zipCode": "PLZ",
              "country": "Land",
              "bloodType": "Blutgruppe",
              "rh": "Rh",
              "weight": "Gewicht",
              "height": "Höhe",
              "hasEpilepsy": "Hat Epilepsie?",
              "mainIcd10": "Hauptdiagnose",
              "comorbidities": "Komorbiditäten",
              "vns": "Implantiertes VNS",
              "activeSubstance": "Wirkstoff",
              "product": "Medizin",
              "therapyType": "Therapietyp"
            }
          }
        }
      },
      "leadDoctor": {
        "noDoctor": "Der Patient hat keinen leitenden Arzt",
        "change": "Ändern",
        "choose": "Arzt wählen",
        "dialogTitle": "Wählen Sie den leitenden Arzt für den Patienten",
        "genericError": "Ein Fehler ist aufgetreten",
        "assign": "Arzt zuweisen",
        "patientsNumber": "Anzahl der Patienten",
        "assignNoteTitle": "Sie haben {name} als leitenden Arzt für den Patienten ausgewählt. Der ausgewählte Arzt erhält folgende Benachrichtigung",
        "assignNoteContent": "Arzt {name} hat den Patienten in Ihre Obhut gegeben. Überprüfen Sie die Krankengeschichte des Patienten.",
        "assignNoteComment": "Wenn Sie möchten, können Sie eine zusätzliche Nachricht hinzufügen. Wenn Sie diese hinzufügen, wird die Notiz auch mit dem Administrator geteilt.",
        "assignNoteDialogTitle": "Hinweis für ausgewählten Arzt"
      },
      "visit": {
        "Visit": {
          "titleLbl": "Besuchen",
          "returnLbl": "zurückkehren",
          "printLbl": "drucken",
          "survey": "Umfrage 0",
          "documentation": "Besuchen Sie die Dokumentation",
          "visitDocumentation": "Dokumentation vom Besuch",
          "calendar": "Veranstaltungskalender",
          "eDiagnosisBtn": "E-Diagnose",
          "eTherapyBtn": "E-Therapie",
          "medHistoryBtn": "Krankengeschichte",
          "messHistoryBtn": "Mitteilungen",
          "notesBtn": "Anmerkungen",
          "observations": "Beobachtungen",
          "additionalObservations": "Zusätzliche Beobachtungen",
          "notMandatoryLbl": "Kein Pflichtfeld",
          "observationsPlaceholder": "Beim Ausfüllen des Besuchsabschnitts werden die Beobachtungen automatisch ausgefüllt",
          "additionalObservationsPlaceholder": "Ergänzen Sie zusätzliche Beobachtungen",
          "addObservationLbl": "Beobachtungen speichern",
          "diagnosis": "Diagnose (Komorbiditäten)",
          "seizureDiagnosis": "Diagnose (Anfälle)",
          "medication": "Medikamente",
          "recommendations": "Empfehlungen",
          "prescriptionBtn": "Rezept",
          "certificateBtn": "Zertifikat",
          "referralBtn": "Verweisung",
          "changeTherapyBtn": "Veränderungstherapie",
          "cancel": "stornieren",
          "save": "speichern",
          "end": "beenden",
          "leadDoctorHeader": "Leitender Arzt",
          "visitLeaveConfirmMsg": "Sind Sie sicher, dass Sie den Besuch verlassen möchten?",
          "visitSaveConfirmMsg": "Möchten Sie den Besuch wirklich speichern und verlassen?",
          "visitEndConfirmMsg": "Möchten Sie den Besuch beenden? Nach Beendigung sind keine weiteren Änderungen daran mehr möglich",
          "dataSavedMsg": "Besuchsdaten gespeichert",
          "visitEndedMsg": "Der Besuch wurde beendet",
          "dataSaveErrorMsg": "Fehler beim Speichern der Besuchsdaten",
          "messageHistoryEmpty": "Es liegen keine Nachrichten zu diesem Patienten vor",
          "drugsImported": "Medikamentöse Therapien wurden eingeleitet",
          "drugTherapiesInEditionMode": "Arzneimitteltherapien befinden sich im Editionsmodus. Der Besuch kann nicht gespeichert werden.",
          "emptyRecommendations": "Empfehlungen werden nicht abgegeben. Der Besuch kann nicht beendet werden.",
          "absentConsultationInfo": "Die Abwesenheitsberatung findet ohne Beteiligung des Patienten statt",
          "referrals": {
            "title": "Empfehlungen",
            "removeDialogTitle": "Möchten Sie diese Empfehlung wirklich entfernen?",
            "addReferral": "Verweisung",
            "addDialogTitle": "Neue Empfehlung",
            "noAvailableServices": "Für diesen Patienten sind keine Leistungen verfügbar",
            "consultationService": "Beratungstyp",
            "doctor": "Arzt",
            "description": "Beschreibung",
            "cancel": "Stornieren",
            "empty": "Für diesen Besuch wurde keine Überweisung ausgestellt",
            "scheduled1Month": "1 Monat",
            "scheduled2Months": "2 Monate",
            "scheduled3Months": "3 Monate",
            "scheduled6Months": "6 Monate",
            "scheduled1Year": "1 Jahr",
            "scheduled1month": "1 Monat",
            "scheduled2month": "2 Monate",
            "scheduled3month": "3 Monate",
            "scheduled6month": "6 Monate",
            "scheduled12month": "1 Jahr",
            "scheduledDate": "Geplantes Datum",
            "availableFree": {
              "title": "Sie haben keine Empfehlungen für kostenlose Besuche ausgestellt",
              "subtitle": "Nach diesem Besuch hat der Patient Anspruch auf einen kostenlosen Besuch für eine der Leistungen",
              "subtitle2": "Gehen Sie zurück zu Ihrem Pflegeplan und stellen Sie die entsprechende Überweisung aus!",
              "back": "Kehren Sie zum Besuch zurück, um eine Überweisung auszustellen",
              "endWithoutReferral": "Ohne Überweisung abschließen",
              "visitDescription": "Nach diesem Besuch hat der Patient Anspruch auf einen kostenlosen Besuch der folgenden Dienste",
              "visitAddTitle": "Empfehlung für einen kostenlosen Besuch"
            },
            "doctorPackage": "Arztpaket",
            "doctorPackageQuestion": "Überweisung für Arztpaket?"
          },
          "prescriptions": {
            "title": "Rezepte",
            "addPrescription": "Rezept",
            "empty": "Für diesen Besuch wurde kein Rezept ausgestellt"
          },
          "certificates": {
            "title": "Zertifikate",
            "addCertificate": "Zertifikat",
            "empty": "Für diesen Besuch wurde kein Zertifikat ausgestellt"
          },
          "ended": {
            "titleLbl": "Die Telefonkonferenz ist beendet",
            "questionMsg": "Möchten Sie diesen Besuch beenden und zum Hauptbildschirm zurückkehren?",
            "confirmationMsg": "Vielen Dank für Ihren Besuch"
          },
          "saving": {
            "warningMsg": "Der Besuch wird automatisch in {no} Minuten gespeichert",
            "autoSaveMsg": "Der Besuch wurde automatisch gespeichert. Bitte beenden Sie den Besuch sofort"
          },
          "icd10": {
            "title": "Diagnose"
          },
          "leaveDialog": {
            "leave": "Ohne zu speichern gehen",
            "saveAndLeave": "Speichern und gehen",
            "endAndLeave": "Ende und geh"
          },
          "wrongDataDialog": {
            "title": "Ihre Daten können derzeit nicht gespeichert werden.",
            "description": "Bitte wenden Sie sich an das Kundenbüro des Neurosphere Epilepsy Therapy Center."
          },
          "documentationTabs": {
            "visitSurvey": "Besuchsumfrage",
            "therapyArrangement": "Therapiearrangement",
            "healthCare": "Pflegeplan",
            "recommendations": "Empfehlungen",
            "certificates": "Zertifikate",
            "appoint": "ernennen",
            "skip": "nicht ernennen",
            "referralsSaved": "Empfehlungen wurden gespeichert",
            "carePlan": {
              "addNext": "Als nächstes hinzufügen",
              "remove": "Entfernen",
              "loading": "Daten werden geladen...",
              "referralListEmpty": "Die Empfehlungsliste ist leer",
              "epilepsy": {
                "name": "Besuch bei Epilepsie",
                "title": "nächster epileptischer Besuch - Terminvereinbarung",
                "date": "Besuchsdatum"
              },
              "eeg": {
                "name": "EEG-Untersuchung",
                "title": "EEG-Untersuchung – Terminvereinbarung",
                "date": "EEG-Datum",
                "service": "EEG-Typ"
              },
              "consultation": {
                "name": "Beratung",
                "title": "NSPH-Beratung – Terminvereinbarung",
                "date": "Beratungstermin",
                "service": "Beratungstyp"
              },
              "other": {
                "name": "Andere",
                "title": "Externe Empfehlungen",
                "emptyList": "Die Liste der ausgestellten externen Überweisungen für diesen Besuch ist leer",
                "addNextEReferral": "E-Überweisung hinzufügen",
                "isEReferral": "E-Überweisung",
                "referralCode": "Referenzcode",
                "noDataLbl": "Die Empfehlungsliste ist leer"
              },
              "message": {
                "name": "Nachricht an den Arzt",
                "title": "Nachricht Besuch - Terminvereinbarung",
                "date": "Besuchsdatum"
              }
            }
          },
          "recommendationTemplate": {
            "title": "Empfehlungsvorlage",
            "examinationNames": "Prüfungsnamen",
            "template": {
              "message": "Bitte schreiben Sie dem behandelnden Arzt eine Nachricht über den Gesundheitszustand",
              "therapy": "Medikamente nach dem Schema in der medikamentösen Therapie und auf Kosten des Patienten im Neuroterminalen System in der Registerkarte Therapie",
              "urgentContact": "Bei dringendem Arztkontakt oder unvorhergesehenen Umständen rufen Sie bitte die Rezeption unter +48 609 463 191 an",
              "noTherapy": "Ohne pharmakologische Behandlung",
              "calendar": "Bitte pflegen Sie einen Veranstaltungskalender auf dem Patientenportal im Neuroterminal-System",
              "headMr": "Bitte machen Sie ein MRT des Kopfes",
              "medicalExaminations": "Bitte führen Sie zusätzliche Tests durch und fügen Sie die Ergebnisse der medizinischen Dokumentation im Neuroterminal-System bei"
            }
          },
          "epicrisis": "Epikrise",
          "noteZeroObservations": "Hinweis 0 Beobachtungen",
          "editNoteZeroObservations": "Notiz bearbeiten 0 Beobachtungen",
          "saveObservationLbl": "Notiz speichern 0 Beobachtungen",
          "downloadTestRecommendations": "Testempfehlungen herunterladen",
          "mainDoctor": "Oberarzt",
          "supervisors": "Betreuer"
        },
        "ictal": {
          "diagnosis": {
            "title": "Sehr geehrte Dame, sehr geehrter Herr",
            "suggestedDiagnoseMsg": "Mögliche Diagnose für",
            "fillSurveyMsg": "Noch keine Anfälle definiert. Füllen Sie die Umfrage 0 aus",
            "ictalDiagnose": "Diagnose mit Ictall",
            "ictalDiagnoseOneMoreTime": "Noch einmal diagnostizieren",
            "patientSeizureType": "Ausgewählter Anfallstyp (vom Patienten definiert)",
            "subtitle1": "Denken Sie daran, dass unsere",
            "subtitle2": "KI-Roboter – Ictal®",
            "subtitle3": "- ist ein innovatives Werkzeug, das Ihnen hilft und ständig lernt, Sie noch besser zu unterstützen, aber",
            "subtitle4": "Es handelt sich nicht um ein medizinisches Gerät und kann nicht zur Beurteilung des tatsächlichen klinischen Zustands Ihrer Patienten verwendet werden.",
            "subtitle5": "Die gesamte Epilepsie-Ontologie-Bibliothek wurde entwickelt, um den Typ eines epileptischen Anfalls gemäß der neuesten MLPP-Klassifikation genau zu erkennen. Daher kann das Tool zur Beurteilung hypothetischer klinischer Zustände für Bildungs- oder Trainingszwecke verwendet werden."
          },
          "outcome": {
            "approveLbl": "Ich stimme zu",
            "rejectLbl": "ich stimme nicht zu",
            "saveLbl": "Diagnose speichern",
            "fixCritical": "Beheben Sie kritische Fragen",
            "reasonLbl": "Grund für die Wahl dieser Diagnose",
            "selectDiagnosisMsg": "Diagnose auswählen",
            "calcDiagnosisMsg": "Diagnose erstellen..",
            "otherDiagnosis1Lbl": "Andere",
            "otherDiagnosis2Lbl": "Diagnose",
            "updatedByLbl": "Nach der Diagnose von"
          },
          "therapy": {
            "title": "Sehr geehrte Dame, sehr geehrter Herr",
            "ictalCheck": "Überprüfen",
            "seizureType": "Art des Anfalls",
            "proposal": "Mögliche Therapieoptionen",
            "type": {
              "0": "ICTAL-Vorschlag",
              "1": "I Monotherapie",
              "2": "II Monotherapie",
              "3": "III Monotherapie",
              "4": "Ich Politherapie",
              "5": "II Polytherapie",
              "6": "III Politherapie",
              "7": "Weitere Politherapie:"
            },
            "drug": {
              "description": {
                "lastUsed": "Zuletzt verwendeten:",
                "color": {
                  "undefined": "Das Medikament wurde bisher noch nicht verwendet.",
                  "orange": "Das Medikament wurde schon einmal verwendet: keine Wirkung / schwache Wirkung.",
                  "red": "Das Medikament wurde zuvor verwendet: negative Wirkung, der Zustand des Patienten verschlechterte sich.",
                  "green": "Das Medikament wurde schon einmal verwendet: zufriedenstellende Wirkung."
                }
              }
            },
            "filter": {
              "availableInPL": "Medikament in Polen erhältlich",
              "refundedInPL": "Medikament wird in Polen erstattet",
              "reproductiveAgeOptional": "Fortpflanzungsfähiges Alter"
            },
            "notFound": "ICTAL hat es versäumt, die Behandlungen auf den Patienten zuzuschneiden.",
            "apply": "Wenden Sie eine Therapie an",
            "subtitle1": "Denken Sie daran, dass unsere",
            "subtitle2": "KI-Roboter – Ictal®",
            "subtitle3": "- ist ein innovatives Werkzeug, das Ihnen hilft und ständig lernt, Sie noch besser zu unterstützen, aber",
            "subtitle4": "Es handelt sich nicht um ein medizinisches Gerät und kann nicht zur Beurteilung des tatsächlichen klinischen Zustands Ihrer Patienten verwendet werden.",
            "subtitle5": "Der gesamte Epilepsietherapieprozess wurde von einem Expertenteam entwickelt und basiert auf den Richtlinien der führenden medizinisch-wissenschaftlichen Gesellschaften, die sich mit Epilepsie befassen. Daher kann das Tool zur Beurteilung hypothetischer klinischer Zustände für Bildungs- oder Schulungszwecke verwendet werden."
          }
        },
        "doctorNotes": {
          "title": "Anmerkungen",
          "subtitle": "Hier können Sie Ihre privaten Notizen zu diesem Patienten speichern",
          "addNote": "Notiz hinzufügen",
          "save": "Änderungen speichern",
          "saveSuccess": "Notiz wurde gespeichert",
          "modified": "Zuletzt bearbeitet",
          "editCancel": "Änderungen verwerfen",
          "edit": "Notiz bearbeiten",
          "delete": "Notiz löschen",
          "askDelete": "Sind Sie sicher, dass Sie diese Notiz löschen möchten?",
          "deleteSuccess": "Hinweis wurde gelöscht",
          "publicVisible": "Für alle Ärzte sichtbar",
          "limitedShare": "Sichtbar für ausgewählte Ärzte",
          "sharedBy": "Geteilt von",
          "forAdmin": "Für den Administrator sichtbar",
          "repliedTo": "Als Antwort auf",
          "sharedByPatient": "Wird vom Patienten wegen fehlender Rücksprache geteilt",
          "absentConsultation": "Fehlende Beratung",
          "addFiles": "Datei hinzufügen"
        },
        "diagnosis": {
          "confirmTitle": "Diagnoseänderung",
          "confirmText": "Sind Sie sicher, dass Sie die Diagnose nicht ändern möchten?",
          "updatedMsg": "Aktualisierte Diagnose für diesen Anfallstyp"
        },
        "patientMessageHistory": {
          "title": "Verlauf der Patientennachrichten",
          "emptyList": "Der Verlauf der Patientennachrichten ist leer"
        }
      },
      "landing": {
        "common": {
          "menu": {
            "observe": "Beobachten Sie uns",
            "link": {
              "database": "NT - Datenbank",
              "doctors": "Für Ärzte",
              "contact": "Kontakt",
              "login": "Anmeldung",
              "register": "Registrieren"
            },
            "terms": {
              "title": "Nutzungsbedingungen",
              "z1": "Organisatorische Regelungen",
              "z2": "Regelungen zur Erbringung elektronischer Dienstleistungen",
              "z3": "Datenschutzrichtlinie von Neurosphere",
              "z4": "Datenschutzrichtlinie von Neuroterminal",
              "payuInstallments": "PayU-Ratenzahlungen – FAQ"
            }
          },
          "contact": {
            "title": "Kontaktiere uns",
            "media": {
              "visit": "Besuchen Sie uns"
            },
            "dataTitle": "Kontaktiere uns",
            "formTitle": "Kontakt Formular",
            "form": {
              "email": "Eine E-Mail senden",
              "phone": "Rufen Sie uns an",
              "message": "Nachricht",
              "info": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bis zu einem gewissen Grad ist es uns gelungen, unsere Arbeit zu erledigen, ohne dass wir sie von der entsprechenden Kommode übernehmen müssen. Duis aute irure dolor in",
              "send": "Nachricht senden",
              "ApproveDataProcessing": "Ich stimme der Verarbeitung meiner personenbezogenen Daten durch Neuroterminal S.A. zur Bearbeitung meiner Bewerbung freiwillig zu. Ausführliche Informationen zu den Grundsätzen des Schutzes personenbezogener Daten und Ihren Rechten finden Sie in",
              "policy": "Datenschutzrichtlinie",
              "feedback": {
                "header": "Vielen Dank, dass Sie uns Ihre Nachricht geschickt haben!",
                "content": "Wir werden Sie in Kürze kontaktieren\nper Telefon oder E-Mail"
              }
            }
          },
          "help": {
            "title": "Gibt es Fragen?"
          }
        },
        "main": {
          "title1": "Moderne Epilepsie",
          "title2": "Behandlungssystem",
          "bullet1": "Bequeme telemedizinische Beratung\nab 99 PLN",
          "bullet2": "Zugang zum Besten\nEpileptologen",
          "bullet3": "Umfassende medizinische Betreuung\nohne das Haus zu verlassen",
          "arrangeConsultation": "Einen Termin machen",
          "features": {
            "consultations": {
              "title": "Telemedizin\nBeratungen",
              "description": "Führen Sie Online-Besuche durch\nper Videokonferenz"
            },
            "emergency": {
              "title": "Notfall\nFälle",
              "description": "24-Stunden-SOS-Anruf\nmit Ärzten"
            },
            "panel": {
              "title": "Geduldig\nPanel",
              "description": "Krankengeschichte,\nmedizinische Dokumente\nund Empfehlungen\nin einem Platz"
            },
            "edocuments": {
              "title": "Elektronische Rezepte,\nkranke Blätter",
              "description": "und Empfehlungen\nohne das Haus zu verlassen"
            },
            "doctors": {
              "title": "\nÄrzte",
              "description": "Zugang zu den besten Epileptologen\nohne Warteschlangen"
            },
            "quickAccess": {
              "title": "Schnell\nZugang",
              "description": "Konsultationen mit Ärzten\nohne Warteschlangen"
            }
          },
          "subHeaderBullet1": "Bleiben Sie in Kontakt mit Ihrem Arzt,\nWer nutzt das Modul der künstlichen Intelligenz?\num die Wirksamkeit Ihrer Behandlung zu überwachen.",
          "subHeaderBullet2": "Erhalten Sie Zugang zu den besten Neurologen in Polen\nohne das Haus zu verlassen.",
          "subHeaderBullet3": "Treten Sie dem Neuroterminal kostenlos bei\nund beginnen Sie mit der Nutzung innovativer Lösungen\nzur Behandlung von Epilepsie!",
          "howItWorks": "Wie es funktioniert?",
          "steps": {
            "choose": {
              "title": "Der Patient wählt\nein Arzt",
              "description": "aus einer Datenbank von Spezialisten"
            },
            "appoint": {
              "title": "Macht\neinen Termin",
              "description": "für einen telemedizinischen Besuch\nzu einem passenden Zeitpunkt"
            },
            "diagnose": {
              "title": "Der Arzt diagnostiziert",
              "description": "und wählt eine Therapie.\nDas künstliche Intelligenzsystem iCtal\nhilft ihm dabei"
            },
            "documents": {
              "title": "Fügt medizinische hinzu\nUnterlagen",
              "description": ""
            },
            "contact": {
              "title": "Der Arzt bleibt\nin Kontakt",
              "description": "mit Geduld,\nüberwacht regelmäßig den Fortschritt\nder durchgeführten Behandlung"
            }
          },
          "opinions": "Meinungen der Benutzer",
          "register": "Erstellen Sie ein Konto, um einen Termin zu vereinbaren"
        },
        "database": {
          "epilepsy": {
            "title": "Was ist Epilepsie?",
            "subheader1": "Epilepsie ist eine medizinische Erkrankung\ngekennzeichnet durch wiederkehrende Anfälle.",
            "subheader2": "Ein Anfall ist die Folge\neiner vorübergehenden Änderung\nGehirnfunktion\nverursacht durch\nanormal oder übermäßig\nelektrische Entladungen\ninnerhalb der Gehirnzellen."
          },
          "seizure": {
            "title": "Wie kommt es zu einem Anfall?\naussehen?",
            "subheader": "Anfallssymptome können sehr unterschiedlich sein.",
            "bullet1": "Manche Menschen leiden an Epilepsie\neinfach den Kontakt verlieren\nfür ein paar Sekunden während eines Anfalls",
            "bullet2": "Andere Menschen fallen zu Boden\nmit Krämpfen der Gliedmaßen",
            "footer": "Die Klassifizierung der Art des Anfalls ist sehr wichtig\nund hilft dem Arzt bei der Planung der erforderlichen Behandlung.\nEin einzelner Anfall bedeutet nicht, dass Sie an Epilepsie leiden."
          },
          "factors": {
            "title": "Provozierende Faktoren",
            "subheader": "Es können auch Krampfanfälle auftreten\ndurch bekannte und reversible Faktoren,\nwie zum Beispiel",
            "bullet1": "Kein Schlaf",
            "bullet2": "Alkohol\nRückzug",
            "bullet3": "Wenig Zucker",
            "seizureRecognition": "Mindestens zwei unprovozierte Anfälle\nsind in der Regel erforderlich, um eine Epilepsie zu diagnostizieren.",
            "seizuresRelatedTo": "Anfälle können damit verbunden sein",
            "relatedToBullet1": "Gehirnschaden",
            "relatedToBullet2": "Familiäre Tendenz",
            "relatedToBullet3": "Oft ihre Ursache\nist völlig unbekannt"
          },
          "whoCanSick": {
            "title": "Wer kann krank werden?",
            "subheader": "Epilepsie kann jeden treffen,\nunabhängig von Geschlecht und Alter.",
            "bullet1": "Die meisten Anfälle treten bei Kindern auf\nbis 1 Jahr alt,\nwährend ältere Menschen normalerweise einen Anstieg haben\nin der Inzidenz nach 65 Jahren.",
            "bullet2": "Es wird geschätzt, dass bis zu 1 % der Menschen\nleiden an Epilepsie (etwa 400.000 Menschen in Polen)."
          },
          "treatment": {
            "title": "Wie wird Epilepsie behandelt?",
            "bullet1": "Medikamente können Anfälle wirksam kontrollieren\nin ca. 70 %\nvon Patienten mit Epilepsie",
            "bullet1Header": "Medikamente",
            "bullet2": "Für Patienten mit unkontrollierter Epilepsie:\nNeurochirurgie kann eine „Heilung“ bieten\ndurch Eliminierung der Quelle\nvon Anfällen und Epilepsie.",
            "bullet2Header": "Operation",
            "footer": "Medikamentöse Behandlung oder manchmal Operation\nkann Anfälle bei den meisten Menschen mit Epilepsie kontrollieren.\nManche Menschen benötigen eine lebenslange Behandlung, um Anfälle zu kontrollieren.\naber andere werden irgendwann aufhören. Einige Kinder mit Epilepsie\nkann mit zunehmendem Alter aus den Anfällen herauswachsen."
          },
          "impact": {
            "title": "Wie sich Epilepsie auswirkt\nAlltagsleben?",
            "subheader": "Krampfanfälle und Epilepsie können Auswirkungen haben",
            "bullet1": "Sicherheit",
            "bullet2": "Beziehungen",
            "bullet3": "Arbeit",
            "bullet4": "Ein Auto fahren",
            "bullet5": "Und sehr viel mehr",
            "footer": "Öffentliche Wahrnehmung und Behandlung von Menschen mit Epilepsie\nsind oft größere Probleme als tatsächliche Anfälle."
          },
          "help": {
            "title": "Der neuroterminale Wille\nhelfen Ihnen, Epilepsie zu heilen",
            "bullet1Header": "Komfort",
            "bullet1": "Sie werden es arrangieren\neine Konsultation mit einem Arzt\nohne das Haus zu verlassen.",
            "bullet2Header": "Innovation",
            "bullet2": "Wir verwenden künstliche\nGeheimdienstsysteme\nund moderne Analysetools\nzur Behandlung von Epilepsie.",
            "bullet3Header": "Sicherheit",
            "bullet3": "Sie erhalten Zugang\nzu einer neurologischen 24-Stunden-Verschiebung\nwas Ihnen zur Verfügung gestellt wird\nsofortige Hilfe.",
            "makeAnAppointment": "Einen Termin machen"
          }
        },
        "doctors": {
          "title1": "Ein Doktor werden",
          "titleIn": "In",
          "title2": "NEUROTERMINAL",
          "subtitle": "Beraten Sie Patienten online\nmit der neuesten Version\nTechnologien.",
          "register": "Melden Sie sich an",
          "features": {
            "consultations": {
              "title": "Telemedizin\nBeratungen",
              "description": "Führen Sie Besuche online durch\ndurch Videokonferenzen\noder chatten"
            },
            "digitalOffice": {
              "title": "Digital\nBüro",
              "description": "Alle Besuche und medizinischen Daten\nIhrer Patienten\nin einem Platz"
            },
            "eDocuments": {
              "title": "\nE-Dokumente",
              "description": "Rezepte erstellen,\nKrankenstände und Überweisungen\nonline"
            },
            "flexibleSchedule": {
              "title": "Flexibel\nZeitplan",
              "description": "Vereinbaren Sie Beratungstermine\nzu für Sie passenden Terminen\n7 Tage pro Woche"
            },
            "system": {
              "title": "System\nNeuroterminal",
              "description": "Nutzen Sie ein innovatives\nKünstliches Intelligenzsystem\nPatienten zu diagnostizieren"
            },
            "patients": {
              "title": "\nPatienten",
              "description": "Führen Sie Ihre Patienten\nund schnell neue gewinnen"
            }
          },
          "steps": {
            "register": {
              "title": "Melden Sie sich an",
              "description": ""
            },
            "training": {
              "title": "Machen Sie eine Ausbildung",
              "description": "wie man das Neuroterminal benutzt\nPlattform und Verhalten\ntelemedizinische Beratungen"
            },
            "schedule": {
              "title": "Beratungsgespräche vereinbaren\nZeitplan",
              "description": "frei planen\nIhre Verfügbarkeit"
            },
            "pricing": {
              "title": "Geben Sie die Preisliste an",
              "description": "Dein Lohn\nzur Beratung"
            },
            "consultations": {
              "title": "Konsultieren",
              "description": "Telemedizin online durch\ndie Neuroterminal-Plattform\nper Video oder Chat"
            },
            "monitor": {
              "title": "Monitor\nPatienten",
              "description": "und deren Behandlungsfortschritt"
            }
          },
          "firstSteps": "Erste Schritte"
        },
        "patients": {},
        "removePatient": {
          "title": "Kontoentfernung bestätigen",
          "subTitle1": "Konto registriert bei",
          "subTitle2": "wird entfernt",
          "why": "Warum möchten Sie das Konto löschen?",
          "whyPrompt": "Ihre Antwort ist uns sehr wichtig. Sie hilft uns, unseren Service zu verbessern.",
          "removeBtn": "Entfernen Sie Ihr Konto von Neuroterminal",
          "accountRemoved": "Ihr Konto wurde entfernt",
          "noAccount": "Das Konto existiert nicht oder kann nicht gelöscht werden",
          "accountRemovedDescription": "Denken Sie daran, dass Sie sich jederzeit beim Neuroterminal registrieren und einen Besuch in Neurosphera buchen können."
        }
      },
      "ictal": {
        "lab": {
          "title": "IctalLab",
          "subtitle": "Geben Sie den Vektor ein oder laden Sie den Patienten, um die automatische Diagnose zu starten.",
          "diagnose": "Diagnostizieren",
          "clear": "Klar",
          "inputVector": "Eingabevektor",
          "loadPatient": "Patienten auswählen",
          "changePatient": "Patienten wechseln",
          "selectedPatient": "Ausgewählter Patient",
          "survey": "Umfrage",
          "diagnosis": "Diagnose",
          "factorsConfiguration": "Faktorenkonfiguration",
          "sum": "Summe",
          "therapy": "Therapie",
          "check": "Überprüfen",
          "selectTherapy": "Therapieart auswählen",
          "selectedTherapy": "Ausgewählte Therapieart",
          "selectSeizureType": "Wählen Sie Anfallsarten aus",
          "selectedSeizureType": "Ausgewählte Anfallsarten",
          "drugAvailableInPl": "Medikament in Polen erhältlich",
          "drugRefunded": "Medikament erstattet",
          "patientIsPregnant": "Patientin ist schwanger",
          "patientWithSomaticDiseases": "Patient mit somatischen Erkrankungen",
          "patientInOlderAge": "Patient im höheren Alter",
          "patientOfChildbearingAge": "Patient im gebärfähigen Alter",
          "patientAge": "Alter des Patienten",
          "recommendedTherapy": "Empfohlene Therapie",
          "errors": {
            "factorsError": "Die Summe der Koeffizienten sollte 100 betragen",
            "vectorLengthError": "Falsche Länge des Eingabevektors",
            "vectorInvalidError": "Falscher Eingabevektor"
          },
          "factors": {
            "nn": "NN",
            "knn": "KNN",
            "jacc": "Jaccard",
            "jaro": "Jaro",
            "sodi": "Sørensen–Würfel",
            "over": "Überlappung",
            "tver": "Tversky: α=1, β=1"
          },
          "surveyHeaders": {
            "name": "Frage",
            "always": "Stets",
            "veryOften": "Sehr oft",
            "often": "Oft",
            "sometimes": "Manchmal",
            "rarely": "Selten",
            "veryRarely": "Sehr selten",
            "never": "Niemals",
            "veryLong": "Sehr lang",
            "long": "Lang",
            "bitLong": "Etwas lang",
            "variable": "Variable",
            "short": "Kurz",
            "veryShort": "Sehr kurze",
            "ultraShort": "Ultrakurz"
          },
          "seizuresTableHeaders": {
            "seizureType": "Art des Anfalls",
            "probability": "Wahrscheinlichkeit [ % ]"
          },
          "selectUserDialog": {
            "title": "Patienten auswählen",
            "noPatientsFound": "Keine Patienten gefunden."
          },
          "configuration": {
            "subtitle": "Aufbau",
            "drugCatalog": {
              "title": "Arzneimittelkatalog",
              "add": "Neues Medikament hinzufügen"
            },
            "factors": {
              "save": "Faktoren speichern",
              "coefModifiedMsg": "Koeffizienten gespeichert"
            }
          },
          "drugs": {
            "deleteConfirmationDialogText": "Durch das Löschen des Medikaments werden auch die Trainingsdaten der Ictal-Engine gelöscht. Alle mit diesem Medikament verbundenen Daten werden gelöscht. Möchten Sie fortfahren?",
            "updateDrugDialogTitle": "Medikament aktualisieren",
            "createDrugDialogTitle": "Medikament hinzufügen",
            "save": "Speichern",
            "cancel": "Stornieren",
            "validation": {
              "minAgeRequired": "Mindestalter ist erforderlich",
              "minAgeRange": "Das Mindestalter muss eine Zahl im Bereich von 0 bis 120 sein",
              "weightRequired": "Gewicht ist erforderlich",
              "weightRange": "Das Gewicht muss eine Zahl im Bereich von -3 bis 3 sein"
            },
            "legend": {
              "title": "Drogen – Legende",
              "column": "Spalte",
              "value": "Wert",
              "thrAbove": "Altersgrenze erreicht; Alter über dem festgelegten Schwellenwert",
              "thrBelow": "Altersgrenze nicht erreicht; Alter unter dem festgelegten Schwellenwert",
              "drugAvailable": "Medikament in PL erhältlich",
              "drugNotAvailable": "Medikament in Polen nicht verfügbar",
              "drugRefunded": "Medikament wird in PL erstattet",
              "drugNotRefunded": "Medikament wird in Polen nicht erstattet",
              "usageForbidden": "Verboten",
              "usageVeryRare": "Sehr selten verwendet",
              "usageRare": "Kaum benutzt",
              "usageAmbivalent": "Ambivalent",
              "usageSometimes": "Manchmal verwendet",
              "usageOften": "Oft benutzt",
              "usageRecommended": "Empfohlen"
            }
          },
          "usedDrugs": "Auszufilternde Medikamente (impliziert – extreme Verschlechterung)",
          "drugProposal": "Arzneimittelvorschlag",
          "wageSum": "Lohnsumme (höher – besser)",
          "patientSex": {
            "label": "Sex",
            "type": {
              "MALE": "Männlich",
              "FEMALE": "Weiblich"
            }
          },
          "experts": {
            "titleLbl": "Experten-Ärzte",
            "addExpertMsg": "Experten hinzufügen",
            "editExpertMsg": "Bearbeitungsexperte",
            "deleteExpertTitleMsg": "Experten entfernen",
            "deleteExpertDescMsg": "Das Entfernen des Experten hat zur Folge, dass alle mit diesem Experten verknüpften Ictal-Engine-Trainingsdaten entfernt werden. Möchtest du fortfahren?",
            "saveMsg": "Expertendaten geändert",
            "save": "Speichern",
            "cancel": "Stornieren",
            "validation": {
              "weightRange": "Das Gewicht muss eine Zahl im Bereich von 0 bis 1 sein"
            }
          },
          "recommendations": {
            "titleLbl": "Von Experten empfohlene Medikamente",
            "addRecommendationLbl": "Empfehlung hinzufügen",
            "saveMsg": "Medikamentenempfehlungsvektor gespeichert",
            "save": "Speichern",
            "cancel": "Stornieren",
            "dialog": {
              "titleLbl": "Empfohlene Medikamente",
              "expertLbl": "Experte",
              "therapyLbl": "Therapie"
            }
          },
          "epilepsies": {
            "titleLbl": "Epilepsietypen",
            "addMsg": "Epilepsietyp hinzufügen",
            "editMsg": "Epilepsietyp bearbeiten",
            "deleteTitleMsg": "Epilepsietyp entfernen",
            "deleteDescMsg": "Durch das Entfernen des Epilepsietyps werden alle mit diesem Epilepsietyp verbundenen Ictal-Engine-Trainingsdaten entfernt. Möchtest du fortfahren?",
            "saveMsg": "Daten zu Epilepsietypen geändert",
            "save": "Speichern",
            "cancel": "Stornieren"
          },
          "ediagnosis": {
            "titleLbl": "E-Diagnose",
            "headers": {
              "eventsTrailer": "Lange vor dem Anfall (Stunden, Tage)",
              "eventsBefore": "Kurz vor dem Anfall (Sekunden)",
              "eventsStart": "Beginn eines Anfalls – erstes Symptom",
              "eventsNext": "Nächste Anfallssymptome",
              "eventsEnd": "Ende der Beschlagnahme",
              "eventsAfter": "Nach dem Anfall",
              "eventsFeature": "Allgemeine Anfallscharakteristik"
            },
            "dialog": {
              "titleLbl": "E-Diagnose-Vektor",
              "epilepsyLbl": "Epilepsietyp",
              "save": "Speichern",
              "cancel": "Stornieren",
              "saveMsg": "E-Diagnose-Vektor geändert"
            },
            "events": {
              "trailerPrecise": "Präzise Vorhersage (nachlaufend)",
              "trailerUnspecified": "Vorhersage nicht spezifiziert (nachfolgend)",
              "beforeAura": "Aura (vorher)",
              "beforeSpecificStimuli": "Spezifische Reize (vorher)",
              "beforeNystagmus": "Nystagmus (vorher)",
              "beforeImmobility": "Immobilität (vorher)",
              "startFaint": "Ohnmacht (Anfang)",
              "startLossConsciousness": "Bewusstlosigkeit (Anfang)",
              "startBodyConvulsions": "Körperkrämpfe (Anfang)",
              "startBodyPartConvulsions": "Krämpfe von Körperteilen (Anfang)",
              "startFaceConvulsions": "Gesichtskrämpfe (Anfang)",
              "startBodyStiffness": "Körpersteifigkeit (Anfang)",
              "startLimbEjection": "Auswerfen von Gliedmaßen (Start)",
              "startEyeMovementDisorders": "Augenbewegungsstörungen (Start)",
              "startEyeTurnPernament": "Eye Turn dauerhaft (Start)",
              "startBodyTurn": "Körperdrehung (Start)",
              "startGroundFall": "Bodensturz (Beginn)",
              "startBitTongue": "Beißende Zunge (Anfang)",
              "startShout": "Schreien (Anfang)",
              "startSounds": "Geräusche (Start)",
              "startMotorAutomatisms": "Motorautomatik (Start)",
              "startImmobility": "Immobilität (Anfang)",
              "startLaugh": "Lachen (Anfang)",
              "startChuck": "Chucking (Anfang)",
              "startCry": "Weinen (Anfang)",
              "startEpigastricSymptoms": "Epigastrische Symptome (Anfang)",
              "startDejaVu": "Déjà-vu (Anfang)",
              "startVisualHallucinations": "Visuelle Halluzinationen (Anfang)",
              "startAuditoryHallucinations": "Auditive Halluzinationen (Anfang)",
              "startEyelidsMyoclonus": "Myoklonus der Augenlider (Anfang)",
              "startMyoclonus": "Myoklonus (Anfang)",
              "nextFaint": "Ohnmacht (nächster)",
              "nextLossConsciousness": "Bewusstlosigkeit (weiter)",
              "nextBodyConvulsions": "Körperkrämpfe (nächste)",
              "nextBodyPartConvulsions": "Krämpfe von Körperteilen (weiter)",
              "nextFaceConvulsions": "Gesichtskrämpfe (weiter)",
              "nextBodyStiffness": "Körpersteifheit (weiter)",
              "nextEyeMovementDisorders": "Augenbewegungsstörungen (weiter)",
              "nextEyeTurnPernament": "Eye Turn dauerhaft (nächster)",
              "nextBodyTurn": "Körperdrehung (nächste)",
              "nextGroundFall": "Bodensturz (nächster)",
              "nextBitTongue": "Beißende Zunge (nächster)",
              "nextUrinating": "Wasserlassen (nächster)",
              "nextDefecation": "Stuhlgang (nächster)",
              "nextShout": "Schreien (nächstes)",
              "nextSounds": "Geräusche (nächste)",
              "nextAggression": "Aggression (weiter)",
              "nextMotorAutomatisms": "Motorautomatik (weiter)",
              "nextImmobility": "Immobilität (nächste)",
              "nextLaugh": "Lachen (weiter)",
              "nextChuck": "Chucking (nächster)",
              "nextCry": "Weinen (nächster)",
              "nextEpigastricSymptoms": "Epigastrische Symptome (nächste)",
              "nextDejaVu": "Déjà-vu (nächstes)",
              "nextVisualHallucinations": "Visuelle Halluzinationen (weiter)",
              "nextAuditoryHallucinations": "Auditive Halluzinationen (weiter)",
              "nextEyelidsMyoclonus": "Myoklonus der Augenlider (nächstes)",
              "endAnotherSeizure": "Ein weiterer Anfall (Ende)",
              "endBodyConvulsions": "Körperkrämpfe (Ende)",
              "endBodyPartConvulsions": "Krämpfe von Körperteilen (Ende)",
              "endFaceConvulsions": "Gesichtskrämpfe (Ende)",
              "endEyeMovementDisorders": "Augenbewegungsstörungen (Ende)",
              "endEyeTurnPernament": "Eye Turn dauerhaft (Ende)",
              "endBodyTurn": "Körperdrehung (Ende)",
              "endGroundFall": "Bodensturz (Ende)",
              "endUrinating": "Wasserlassen (Ende)",
              "endDefecation": "Stuhlgang (Ende)",
              "endSounds": "Geräusche (Ende)",
              "endAggression": "Aggression (Ende)",
              "endMotorAutomatisms": "Motorautomatik (Ende)",
              "endImmobility": "Immobilität (Ende)",
              "endLaugh": "Lachen (Ende)",
              "endChuck": "Spannen (Ende)",
              "endCry": "Weinen (Ende)",
              "endVomit": "Erbrechen (Ende)",
              "afterEyeMovementDisorders": "Augenbewegungsstörungen (nachher)",
              "afterEyeTurnPernament": "Das Auge wird dauerhaft (danach)",
              "afterUrinating": "Wasserlassen (nachher)",
              "afterDefecation": "Stuhlgang (nachher)",
              "afterConfusion": "Verwirrung (nachher)",
              "afterSleep": "Schlafen (danach)",
              "afterAggression": "Aggression (nachher)",
              "afterMotorAutomatisms": "Motorautomatik (nachher)",
              "afterVomit": "Erbrechen (nachher)",
              "afterHighTemperature": "Hohe Temperatur (nachher)",
              "featureMany": "Viele (Feature)",
              "featureDayOccurrence": "Tagesvorkommen (Merkmal)",
              "featureNightOccurrence": "Nachtvorkommen (Merkmal)",
              "featureAwakeningOccurrence": "Erwachendes Ereignis (Merkmal)",
              "featureMorningOccurrence": "Morgenereignis (Feature)",
              "featureDuration": "Dauer (Feature)"
            }
          },
          "model": {
            "search": "Suchen",
            "manage": "Verwalten",
            "drug": {
              "general": "Allgemeine Daten",
              "weights": "Gewichte",
              "shortName": "Kurzer Name",
              "name": "Name",
              "minAge": "Mindestalter",
              "plAvailability": "Verfügbar in PL",
              "plRefunded": "Rückerstattung in PL",
              "reproductiveAgeWeight": "Fortpflanzungsfähiges Alter",
              "pregnantWeight": "Schwanger",
              "oldAgeWeight": "Hohes Alter",
              "somaticDiseaseWeight": "Somatische Erkrankung"
            },
            "expert": {
              "description": "Experte",
              "factor": "Faktor"
            },
            "epilepsy": {
              "name": "Name"
            }
          },
          "algorithmSteps": {
            "filterName": "Filter",
            "order": "Befehl",
            "usedFilters": "Gebrauchte Filter",
            "filters": {
              "SeizureDrugFilter": "Durg-Selektion durch Anfälle",
              "UsedDrugFilter": "Entsorgung gebrauchter Medikamente",
              "TherapyDrugFilter": "Medikamentenbestellung nach Expertenempfehlung (Gewicht)",
              "WeightDrugFilter": "Gewichtsberechnung"
            },
            "tableModel": {
              "drugName": "Name",
              "drugShortName": "Kurzer Name",
              "minAge": "Mindestalter",
              "oldAgeWeight": "Älteres Alter",
              "pregnantWeight": "Schwangerschaft",
              "reproductiveAgeWeight": "Fortpflanzungsfähiges Alter",
              "somaticDiseaseWeight": "Somatische Erkrankungen",
              "weightSum": "Gewichtssumme"
            }
          },
          "furtherPoliOffset": "Weitere Politherapie-Nummer (beginnt bei 0)"
        }
      },
      "duties": {
        "Duties": {
          "duty": "Geplanter Dienst",
          "availableDuty": "Verfügbarer Dienst",
          "notAvailableDuty": "Pflicht nicht verfügbar",
          "selectedDuty": "Ausgewählt",
          "blockedDuty": "Nicht verfügbar",
          "cancelledDuty": "Zur Stornierung ausgewählt",
          "dutyToChange": "Antrag auf Übernahme der Pflicht",
          "changes": {
            "approve": {
              "text": "Änderungen speichern",
              "tooltipInstruction": "Wählen Sie Aufgaben aus den verfügbaren aus und speichern Sie, indem Sie auf diese Schaltfläche klicken"
            },
            "discard": {
              "text": "Änderungen verwerfen"
            }
          }
        },
        "confirmationDialog": {
          "text": "Ihre Änderungen werden nicht gespeichert. Möchten Sie sie verwerfen?"
        },
        "youAreNotAllowedDialog": {
          "title": "Berechtigungen erforderlich",
          "text": "Sie haben keine Berechtigung zur Eintragung von Pflichten. Kontaktieren Sie uns für weitere Informationen."
        },
        "obligatoryDutyDialog": {
          "title": "Betrieb nicht erlaubt",
          "subtitle": "Gemäß den Allgemeinen Geschäftsbedingungen ist eine Stornierung des gewählten Dienstes nicht möglich.",
          "text": "Weitere Informationen finden Sie in unserem",
          "termsAndConditions": "Allgemeine Geschäftsbedingungen",
          "close": "Schließen"
        },
        "dutiesLimitAchievedDialog": {
          "title": "Betrieb nicht erlaubt",
          "subtitle": "Sie haben Ihr Dienstlimit für diese Woche erreicht.",
          "text": "Weitere Informationen finden Sie in unserem",
          "termsAndConditions": "Allgemeine Geschäftsbedingungen",
          "close": "Schließen"
        }
      },
      "medicalHistory": {
        "MedicalHistory": {
          "title": "Krankengeschichte",
          "return": "Zurückkehren",
          "show": "Zeigen",
          "psychomotorDevelopment": "Psychomotorische Entwicklung",
          "comorbidities": "Komorbiditäten",
          "notDiagnosedLbl": "Nicht diagnostiziert",
          "bloodType": "Blutgruppe",
          "height": "Höhe",
          "weight": "Gewicht",
          "bmi": "BMI",
          "tabs": {
            "psychomotorDevelopment": "Psychomotorische Entwicklung",
            "seizuresHistory": "Anfallsgeschichte",
            "seizuresCharacteristics": "Merkmale von Anfällen",
            "comorbidities": "Komorbiditäten",
            "treatment": "Behandlung"
          },
          "treatment": {
            "title": "Behandlungs- und Besuchsgeschichte",
            "currentTherapy": "Aktuelle Therapie",
            "biomedicalData": "Biomedizinische Daten",
            "comorbiditiesData": "Daten zu Komorbiditäten",
            "previousTherapies": "Geschichte der Therapien",
            "number": "NEIN",
            "medicine": "Medizin",
            "dosage": "Dosierung",
            "effect": "Wirkung",
            "noCurrentTherapy": "Keine aktuelle Therapie",
            "start": "Start",
            "end": "Ende",
            "noPreviousTherapies": "Der Therapieverlauf ist leer",
            "treat": "Behandlung",
            "visits": "Besuche"
          },
          "survey": {
            "title": "Umfrage 0"
          },
          "calendar": {
            "title": "Veranstaltungskalender",
            "visitCalendarLabel": "Besuchskalender"
          },
          "visitCalendar": {
            "title": "Meinung von Arzt und Patient",
            "opinionTypeHeader": "Meinungstyp",
            "seizureEvaluation": "Schwere der Anfälle",
            "wellBeing": "Wohlbefinden",
            "treatmentRating": "Behandlungseffizienz",
            "sideEffects": "Nebenwirkungen",
            "treatmentSafety": "Behandlungssicherheit",
            "patientOpinion": "Meinung des Patienten",
            "doctorOpinion": "Meinung des Arztes",
            "noData": "Keine Daten aus Umfragen",
            "noVisits": "Keine Besuche"
          },
          "prescriptions": {
            "title": "E-Rezepte"
          },
          "consultations": {
            "title": "Besuche",
            "noConsultations": "Keine Beratungen",
            "observations": "Beobachtungen",
            "recomendation": "Empfehlung",
            "doctor": "Arzt",
            "date": "Datum",
            "type": "Typ",
            "category": "Kategorie",
            "none": "Keiner"
          },
          "icd10": {
            "title": "Diagnose",
            "current": "Aktuelle Diagnose",
            "history": "Geschichte",
            "from": "Aus",
            "added": "Hinzugefügt",
            "removed": "ENTFERNT",
            "noCurrentIcd10": "Keine aktuelle Diagnose",
            "noIcd10History": "Der Diagnoseverlauf ist leer"
          },
          "bioMedInfo": {
            "edit": "Bearbeiten"
          },
          "epicrisis": {
            "title": "Epikrise"
          }
        },
        "healthSurvey": {
          "title": "Umfrage 0",
          "readOnlyAccess": "Nur-Lese-Vorschau. Der Fragebogen kann vom Arzt beim ersten Besuch ausgefüllt werden",
          "noAnswer": "Keine Antwort",
          "allDisabled": "Umfrage 0 kann nach dem ersten Besuch nicht mehr geändert werden",
          "partCompleted": "Dieser Umfrageteil ist abgeschlossen",
          "currentTabDisabled": "Dieser Teil der Gesundheitsbefragung ist abgeschlossen und kann nach dem ersten Besuch nicht mehr geändert werden",
          "emptyFilterSet": "In der Liste werden nur Fragen ohne Antwort angezeigt.",
          "surveyUpdatedByDoctor": "Der Arzt hat gerade eine Umfrage im Teil „{sectionName}“ aktualisiert",
          "filters": {
            "show": "Zeigen",
            "all": "Alle",
            "empty": "Leer"
          },
          "hintPopup": {
            "title": "Sie wissen nicht, wie Sie antworten sollen?",
            "content": "Keine Sorge! Lassen Sie eine schwierige Frage unbeantwortet. Der Arzt hilft Ihnen beim Ausfüllen der fehlenden Teile des Fragebogens in der ersten Telefonkonferenz."
          },
          "SeizureHistory": {
            "introTitle": "Wir würden gerne wissen, wie lange und welche Art von Anfällen Sie haben.",
            "introSubtitle": "Es ist sehr wichtig. Bitte beachten Sie: Dies ist Ihre Krankengeschichte, die nur auf diese Weise ermittelt werden kann. Es gibt keine andere Möglichkeit, sie zu beschreiben. Versuchen Sie daher, genaue Fakten und Daten zu erhalten!",
            "next": "Nächste",
            "edit": "Bearbeiten",
            "back": "Vorherige",
            "historyCanBeModified": "Ihr Anfallsverlauf wurde bereits initialisiert. Sie können es vor dem ersten Besuch ändern.",
            "historyCannotBeModified": "Ihre Anfallshistorie kann nach dem ersten Besuch nicht mehr geändert werden.",
            "return": "Zurückkehren",
            "outroTitle": "Herzlichen Glückwunsch, Sie haben alle Fragen zu Ihrer Anfallsgeschichte beantwortet.",
            "outroSubtitle": "Danke schön!",
            "save": "Speichern",
            "generatingInProgress": "Generierung der Anfallshistorie läuft. Bitte warten...",
            "saveSuccess": "Der Anfallsverlauf wurde erfolgreich gespeichert",
            "generateSuccess": "Der Anfallsverlauf wurde erfolgreich erstellt",
            "stages": {
              "firstSeizure": "Erster Anfall",
              "quantityAndRegularity": "Menge und Regelmäßigkeit",
              "frequency": "Frequenz",
              "breaks": "Geht kaputt"
            },
            "seizureTypesForm": {
              "title": "Bestimmen Sie die Anzahl der Arten Ihrer Anfälle und benennen Sie sie (maximal 3).",
              "modifyWarning": "Aufmerksamkeit! Nach der Änderung der Anfallstypen müssen die Anfallsgeschichte und die Anfallsmerkmale neu definiert werden",
              "modifyDisabled": "Anfallstypen können aufgrund von Anfällen nicht geändert werden",
              "typeName": "Name des Anfallstyps",
              "addType": "Anfallstyp hinzufügen"
            },
            "firstSeizureForm": {
              "title": "Geben Sie das Datum des ersten Anfalls ein",
              "subtitle1": "Gehen Sie zurück und erinnern Sie sich an jeden ersten Anfall.",
              "subtitle2": "Bestimmen Sie das Datum Ihres ersten Anfalls so genau wie möglich.",
              "subtitle3": "Wir nennen es Anfall {name} (den Typ definieren wir später gemeinsam mit Ihrem leitenden Arzt).",
              "sickSince": "Ok, du bist also krank wegen:"
            },
            "quantityAndRegularity": {
              "title": "Anfälle wie der erste dauern bis heute an, was darauf hindeutet, ob:",
              "knowsAllDates": "Kennen Sie die Daten aller Anfälle?",
              "quantityYes": "Von diesem ersten Anfall bis heute sind sie aufgetaucht",
              "quantityNo": "Anfälle wie beim ersten Mal traten nicht mehr auf",
              "quantityUndefined": "Es waren so viele, dass es unmöglich ist, die genaue Zahl anzugeben",
              "seizuresQuantity": "Anzahl der Anfälle",
              "yes": "Ja",
              "no": "NEIN",
              "regularOccurrence": "Kommt es regelmäßig zu Episoden?"
            },
            "knowsAllDates": {
              "subtitle": "Geben Sie die Daten der folgenden Anfälle ein",
              "date": "Datum",
              "remove": "Entfernen",
              "add": "Datum hinzufügen"
            },
            "frequency": {
              "title": "Häufigkeit angeben – Geben Sie ein, wie viele Anfälle normalerweise in einem bestimmten Zeitraum auftreten:",
              "in": "In"
            },
            "breaks": {
              "title": "Gab es Pausen, in denen keine Anfälle auftraten?",
              "subtitle": "Identifizieren Sie Pausen, in denen keine Anfälle aufgetreten sind",
              "date": "Datum",
              "to": "Zu",
              "remove": "Entfernen",
              "add": "Punkt hinzufügen"
            }
          },
          "Intro": {
            "title1": "Die von Ihnen hier bereitgestellten Informationen werden an Ihren Arzt weitergegeben. Sie helfen Ihnen bei der richtigen Diagnose und Behandlung.",
            "fillSurvey": "Füllen Sie die Gesundheitsumfrage aus",
            "inVisit": {
              "title1": "Die Telekonsultation ist im Gange.",
              "title2": "Es ist Zeit, den Fragebogen gemeinsam mit Ihrem Arzt auszufüllen.",
              "title3": "Ihr Arzt hilft Ihnen bei der Beantwortung unklarer Fragen.",
              "next": "Nächste"
            }
          },
          "PsychomotorDevelopment": {
            "introTitle": "Psychomotorische Entwicklung",
            "introSubtitle1": "An dieser Stelle möchten wir feststellen, wie Ihr allgemeiner psycho-physischer Zustand ist.",
            "introSubtitle2": "Dank Ihrer Antworten wird der Arzt die optimale Therapie auswählen.",
            "introSubtitle3": "Denken Sie daran: Wenn eine Frage unklar ist, können Sie diese während der ersten Telefonkonferenz mit Ihrem Arzt beantworten.",
            "stages": {
              "psychoPhysicalState": "Körperliche und geistige Verfassung",
              "senses": "Sinne",
              "drugs": "Drogen"
            },
            "psychoPhysicalState": {
              "overall": "Bestimmen Sie die psychomotorische Entwicklung",
              "retardation": "Psychomotorische Retardierung (Behinderung) feststellen",
              "iq": "Bestimmen Sie den IQ des Patienten",
              "notTested": "Nicht getestet",
              "tested": "Geprüft",
              "iqValue": "Geben Sie den IQ-Wert ein",
              "speech": "Aktive und passive Rede. (Sprechen und Verstehen von Sprache)",
              "mentalDisorders": "Geschichte psychiatrischer Erkrankungen",
              "neurologicalSyndrome": "Bestätigtes neurologisches Syndrom",
              "walk": "Gehen",
              "sex": "Sex"
            },
            "senses": {
              "nystagmus": "Hat der Patient Nystagmus?",
              "vision": "Vision",
              "hearing": "Hören"
            },
            "drugs": {
              "smoking": "Rauchen",
              "alcohol": "Alkohol",
              "narcotics": "Betäubungsmittel"
            },
            "mentalDisorders": {
              "psychosis": "Psychose aktuell oder in der Vergangenheit",
              "depression": "Depression derzeit oder in der Vergangenheit",
              "autism": "Autismus diagnostiziert",
              "adhd": "ADHS und dergleichen",
              "mentalDisordersNotKnown": "Fehlt oder ist nicht bekannt"
            },
            "neurologicalSyndrome": {
              "piramidalDeficit": "Pyramidendefizit",
              "brainDeficit": "Gehirn",
              "sensoryDisturbance": "Sinnesstörung",
              "peripharalParesis": "Peripharalparese",
              "noNeurologicalSyndrome": "Nicht oder nicht bekannt"
            }
          },
          "SeizuresCharacteristics": {
            "introTitle": "Anfallsmerkmale",
            "introSubtitle1": "Denken Sie daran, dass Sie einen typischen Anfall oder Anfall beschreiben. Wenn Sie einen Anfall beschreiben, können Sie genau angeben, was passiert ist. Bei mehreren Anfällen können sich die Anfälle teilweise leicht voneinander unterscheiden, deshalb listen Sie deren zufällige Merkmale auf. Hier beschreiben Sie die Zustände vor dem Anfall: Trailer, unmittelbare Ereignisse und Stadien des Anfalls (Beginn, Fortsetzung, Ende und Ereignis nach dem Anfall). Außerdem geben Sie allgemeine Informationen zum Anfall.",
            "introSubtitle2": "Jede Information ist wichtig und kann einen Einfluss darauf haben, ob der Arzt die Diagnose ersetzt. Versuchen Sie, so genau und präzise wie möglich zu sein.",
            "question": "Frage",
            "seizure": "Art des Anfalls",
            "emptySeizureTypesInfo": "Sie müssen zunächst die Anfallstypen im Reiter „Anfallsverlauf“ definieren",
            "stages": {
              "trailers": "Beschlagnahme\nProphezeiungen",
              "before": "Voranfall\nPhase",
              "start": "Erster Anfall\nSymptom",
              "next": "Nächster Anfall\nSymptom",
              "end": "Letztes Symptom\neines Anfalls",
              "after": "Nach\nBeschlagnahme",
              "feature": "Beschlagnahme\nMerkmale",
              "premonition": "Vorahnung"
            },
            "trailers": {
              "trailerPrecise": "Gibt es bestimmte Vorboten eines Anfalls?",
              "trailerPreciseDescription": "Eine Vorhersage ist eine mehr oder weniger genaue Vorahnung eines Anfalls. Mit einem expliziten Trailer meinen wir ein bestimmtes Gefühl, das beschrieben werden kann: Angst, Traurigkeit, Aufregung usw. Manchmal ist der Trailer undefiniert und für den Patienten schwer zu beschreiben. Es handelt sich um ein langanhaltendes Phänomen, das Stunden (seltener Tage) vor einem Anfall auftritt, was es von der Aura unterscheidet, die dem Anfall unmittelbar vorausgeht. Manche Patienten entwickeln erst nach einiger Zeit die Fähigkeit, einen Anfall zu spüren.",
              "trailerUnspecified": "Gibt es (oder gab es nur einen Anfall) nicht näher bezeichnete Trailer zu einem Anfall?",
              "trailerUnspecifiedDescription": ""
            },
            "before": {
              "beforeAura": "Aura vor dem Anfall",
              "beforeAuraDescription": "Ein Gefühl eines beginnenden Anfalls unmittelbar davor (normalerweise Sekunden)",
              "beforeNystagmus": "Nystagmus/pathologische Augenbewegungen",
              "beforeNystagmusDescription": "Gab es unmittelbar vor dem Anfall einen Nystagmus oder eine Bewegungsstörung des Augapfels?",
              "beforeImmobility": "Teilweise Immobilität oder Bewegungslosigkeit",
              "beforeImmobilityDescription": "Gab es unmittelbar vor dem Anfall eine teilweise Immobilität (oder Mobilitätseinschränkung) oder eine vollständige Immobilität?"
            },
            "start": {
              "startFaint": "Bewusstlosigkeit",
              "startFaintDescription": "War der Bewusstseinsverlust das erste Anzeichen eines Anfalls?",
              "startLossConsciousness": "Vollständiger Bewusstseinsverlust",
              "startLossConsciousnessDescription": "War der völlige Bewusstseinsverlust das erste Anzeichen eines Anfalls?",
              "startLossConsciousnessPartially": "Teilweiser Bewusstseinsverlust",
              "startLossConsciousnessPartiallyDescription": "War der teilweise Bewusstseinsverlust das erste Anzeichen eines Anfalls? Hat jeden Bewusstseinsbereich gestört",
              "startBodyConvulsions": "Ganzkörperkrämpfe (generalisiert)",
              "startBodyConvulsionsDescription": "Waren die Ganzkörperkrämpfe das erste Anzeichen eines Anfalls?",
              "startBodyPartConvulsions": "Krämpfe von Körperteilen (fokal)",
              "startBodyPartConvulsionsDescription": "Waren die Zuckungen von Körperteilen das erste Anzeichen eines Anfalls?",
              "startFaceConvulsions": "Gesichtszuckungen",
              "startFaceConvulsionsDescription": "Waren die Gesichtszuckungen (generalisiert oder fokal) das erste Symptom eines Anfalls?",
              "startRhythmicConvulsions": "Rhythmische Krämpfe",
              "startRhythmicConvulsionsDescription": "Waren diese Krämpfe das erste Symptom eines rhythmischen Anfalls?",
              "startBodyStiffness": "Versteifung des gesamten Körpers",
              "startBodyStiffnessDescription": "War das erste Symptom eines Anfalls eine tonische Versteifung des gesamten Körpers?",
              "startLimbEjection": "Epileptischer Krampf",
              "startLimbEjectionDescription": "War das erste Symptom des Anfalls ein epileptischer Anfall vom West-Typ?",
              "startHyperkineticSymptoms": "Hyperkinetische Symptome",
              "startHyperkineticSymptomsDescription": "War das erste Symptom des Anfalls der Beginn schwerwiegender hyperkinetischer Symptome: Treten, multidirektionale Beckenstöße und Ähnliches?",
              "startEyeMovementDisorders": "Nystagmus oder pathologische Augenbewegungen",
              "startEyeMovementDisordersDescription": "War das erste Symptom des Anfalls ein Nystagmus oder eine pathologische Augenbewegung?",
              "startEyeTurnPernament": "Abweichung/Rotation der Augäpfel",
              "startEyeTurnPernamentDescription": "War das erste Symptom des Anfalls das Drehen der Augäpfel auf eine der vier Seiten?",
              "startBodyTurn": "Den Körper und/oder den Kopf zur Seite drehen",
              "startBodyTurnDescription": "War das erste Symptom des Anfalls eine Drehung des Körpers und/oder des Kopfes zur Seite?",
              "startGroundFall": "Fallen",
              "startGroundFallDescription": "War das erste Symptom des Anfalls ein Sturz auf den Boden oder ein teilweiser Sturz auf den Boden (z. B. auf die Knie)?",
              "startAtony": "Atonie oder astatischer Anfall des gesamten Körpers oder eines Teils davon",
              "startAtonyDescription": "War das erste Symptom des Anfalls eine Muskelatonie oder ein astatischer Anfall, der den ganzen Körper oder einen Teil davon (z. B. nur den Kopf) betraf?",
              "startBitTongue": "Beißen auf die Zunge oder Teile des Mundes",
              "startBitTongueDescription": "Ist das erste Symptom eines Anfalls das Beißen der Zunge oder von Teilen des Mundes?",
              "startShout": "Schrei",
              "startShoutDescription": "War das Schreien das erste Symptom des Anfalls?",
              "startSounds": "Verbale und nonverbale Lautäußerungen",
              "startSoundsDescription": "War das erste Symptom des Anfalls eine verbale oder nonverbale Lautäußerung?",
              "startSpeechStop": "Verhaftete Rede",
              "startSpeechStopDescription": "War die angehaltene Rede das erste Symptom des Anfalls?",
              "startMotorAutomatisms": "Gesichts- und orale Automatismen",
              "startMotorAutomatismsDescription": "Ist das erste Symptom eines Anfalls das Auftreten von Gesichts- und Mundautomatismen: Kauen, Schlucken, Klicken, Blinzeln?",
              "startOtherAutomatisms": "Andere Automatismen",
              "startOtherAutomatismsDescription": "Waren das erste Symptom des Anfalls andere Automatismen: ganzer Körper, Gliedmaßen, Stimmausdauer, sexuelle Automatismen, andere komplexe Automatismen?",
              "startImmobility": "Deutliche Einschränkung der Mobilität oder Immobilität",
              "startImmobilityDescription": "War das erste Symptom des Anfalls eine deutliche Einschränkung/Hemmung der Beweglichkeit oder eine völlige Immobilität?",
              "startLaugh": "Paroxysmales Lachen",
              "startLaughDescription": "War das erste Symptom des Anfalls paroxysmales Lachen (gelastischer Anfall)?",
              "startChuck": "Schmatzen und schlürfen",
              "startChuckDescription": "Sind die ersten Symptome Schmatzen und Schlürfen?",
              "startCry": "Paroxysmaler Schrei",
              "startCryDescription": "War das erste Symptom des Anfalls paroxysmales Weinen?",
              "startEpigastricSymptoms": "Paroxysmale epigastrische Symptome, Speichelfluss, Geschmacksveränderung",
              "startEpigastricSymptomsDescription": "Waren die ersten Symptome des Anfalls epigastrische Symptome, Speichelfluss oder eine Geschmacksveränderung?",
              "startHeartSymptoms": "Kardiale autonome Symptome: Herzklopfen/Tachykardie/Bradylosigkeit/Asystolie",
              "startHeartSymptomsDescription": "Waren die ersten Symptome des Anfalls kardiovaskuläre autonome Symptome: Herzklopfen, Tachykardie oder Bradykardie oder Asystolie?",
              "startAutonomicSymptoms": "Zusätzliche autonome Symptome: Blässe, Hitzegefühl, Atemnot, abnormale Pupillenbewegung, Harn-/Stuhldrang",
              "startAutonomicSymptomsDescription": "War das erste Symptom des Anfalls zusätzliche autonome Symptome: Gesichtsausdruck, Hitzegefühl, Atemnot, Pupillenbewegungsstörung, Harndrang/Kot?",
              "startLanguageIssues": "Dysphasie/Aphasie, Legasthenie/Alexie und/oder mangelndes Sprachverständnis",
              "startLanguageIssuesDescription": "Waren die ersten Symptome des Anfalls Sprachstörungen wie Dysphasie/Aphasie, Legasthenie/Alexie und/oder mangelndes Sprachverständnis?",
              "startMemoryDisorders": "Gedächtnisstörungen während des Anfalls (andere Sinne normal)",
              "startMemoryDisordersDescription": "Gab es als erstes Symptom des Anfalls Gedächtnisstörungen während des Anfalls, bei vollem Erhalt der anderen Sinne?",
              "startDejaVu": "Wahnvorstellungen: Déjà-vu, Déjà-vecu und ähnliches",
              "startDejaVuDescription": "Waren die ersten Symptome des Anfalls Wahnvorstellungen wie Déjà-vu, Déjà-vecu oder Ähnliches?",
              "startVisualHallucinations": "Visuelle Halluzinationen: mehrfarbige Formen (Lichtkreise, Blitze, Verlust eines Teils des Gesichtsfeldes oder Blindheit (Amaurose). Und/oder beidseitiger Blackout oder Whiteout-Verlust des Gesichtsfeldes.",
              "startVisualHallucinationsDescription": "Waren die ersten Symptome des Anfalls visuelle Halluzinationen: mehrfarbige Formen (Lichtkreise, Blitze, Verlust eines Teils des Gesichtsfeldes oder Blindheit (Amaurosis)? Und/oder beidseitiger Blackout oder Whiteout-Verlust des Gesichtsfeldes?",
              "startAuditoryHallucinations": "Halluzinationen und ähnliche Hörsymptome",
              "startAuditoryHallucinationsDescription": "Waren die ersten Symptome des Anfalls Halluzinationen und/oder ähnliche Hörsymptome?",
              "startFeelingFearOrAnxiety": "Paroxysmale Angst oder Furcht",
              "startFeelingFearOrAnxietyDescription": "War das erste Symptom des Anfalls paroxysmale Angst oder Furcht?",
              "startSomatosensorySymptoms": "Fokale somatosensorische Symptome, am häufigsten Parästhesien mit Kribbeln und/oder Taubheitsgefühl, Stechen, Kitzeln, Krabbeln oder dem Gefühl eines Stromschlags.",
              "startSomatosensorySymptomsDescription": "Waren die ersten Symptome des Anfalls fokale somatosensorische Symptome, am häufigsten Parästhesien mit Kribbeln und/oder Taubheitsgefühl, Stechen, Kitzeln, Krabbeln oder dem Gefühl eines Stromschlags?",
              "startBodyDistortion": "Verzerrtes Körperbild mit Bewegungsgefühl (z. B. Schweben) oder veränderter Haltung (z. B. Drehbewegung) in einem ruhenden Glied",
              "startBodyDistortionDescription": "Waren die ersten Symptome eines Anfalls ein verzerrtes Körperbild mit dem Gefühl einer Bewegung (z. B. Schweben) oder einer veränderten Haltung (z. B. Drehbewegung) in einer ruhenden Gliedmaße?",
              "startEyelidsMyoclonus": "Myoklonische Anfälle am Augenlid",
              "startEyelidsMyoclonusDescription": "Waren die ersten Symptome des Augenlidanfalls myoklonische Anfälle?",
              "startMyoclonus": "Generalisierte myoklonische Anfälle",
              "startMyoclonusDescription": "Waren die ersten Symptome des Anfalls generalisierte myoklonische Anfälle?"
            },
            "next": {
              "nextFaint": "Bewusstlosigkeit",
              "nextFaintDescription": "War die Bewusstlosigkeit das nächste Anzeichen eines Anfalls?",
              "nextLossConsciousness": "Vollständiger Bewusstseinsverlust",
              "nextLossConsciousnessDescription": "War der völlige Bewusstseinsverlust das nächste Anzeichen eines Anfalls?",
              "nextLossConsciousnessPartially": "Teilweiser Bewusstseinsverlust",
              "nextLossConsciousnessPartiallyDescription": "War der teilweise Bewusstseinsverlust das nächste Anzeichen eines Anfalls? Hat jeden Bewusstseinsbereich gestört",
              "nextBodyConvulsions": "Ganzkörperkrämpfe (generalisiert)",
              "nextBodyConvulsionsDescription": "Waren die Ganzkörperkrämpfe das nächste Anzeichen eines Anfalls?",
              "nextBodyPartConvulsions": "Krämpfe von Körperteilen (fokal)",
              "nextBodyPartConvulsionsDescription": "Waren die Zuckungen von Körperteilen das nächste Anzeichen eines Anfalls?",
              "nextFaceConvulsions": "Gesichtszuckungen",
              "nextFaceConvulsionsDescription": "Waren die Gesichtszuckungen (generalisiert oder fokal) das nächste Symptom eines Anfalls?",
              "nextRhythmicConvulsions": "Rhythmische Krämpfe",
              "nextRhythmicConvulsionsDescription": "Waren diese Krämpfe das nächste Symptom eines rhythmischen Anfalls?",
              "nextBodyStiffness": "Versteifung des gesamten Körpers",
              "nextBodyStiffnessDescription": "War das nächste Symptom eines Anfalls eine tonische Versteifung des gesamten Körpers?",
              "nextHyperkineticSymptoms": "Hyperkinetische Symptome",
              "nextHyperkineticSymptomsDescription": "War das nächste Symptom des Anfalls der Beginn schwerer hyperkinetischer Symptome: Treten, multidirektionale Beckenstöße und Ähnliches?",
              "nextEyeMovementDisorders": "Nystagmus oder pathologische Augenbewegungen",
              "nextEyeMovementDisordersDescription": "War das nächste Symptom des Anfalls Nystagmus oder pathologische Augenbewegungen?",
              "nextEyeTurnPernament": "Abweichung/Rotation der Augäpfel",
              "nextEyeTurnPernamentDescription": "War das nächste Symptom des Anfalls das Drehen der Augäpfel auf eine der vier Seiten?",
              "nextBodyTurn": "Den Körper und/oder Kopf zur Seite drehen",
              "nextBodyTurnDescription": "War das nächste Symptom des Anfalls eine Drehung des Körpers und/oder des Kopfes zur Seite?",
              "nextGroundFall": "Fallen",
              "nextGroundFallDescription": "War ein weiteres Symptom des Anfalls ein Sturz auf den Boden oder ein teilweiser Sturz auf den Boden (z. B. auf die Knie)? Wenn der Sturz das erste Symptom war, hat sich der Patient nach dem Sturz weiter hingelegt?",
              "nextBitTongue": "Beißen auf die Zunge oder Teile des Mundes",
              "nextBitTongueDescription": "Ist das nächste Symptom eines Anfalls das Beißen der Zunge oder von Teilen des Mundes?",
              "nextUrinating": "Harndrang oder Harndrang",
              "nextUrinatingDescription": "War das nächste Symptom des Anfalls ein Drang oder Harndrang?",
              "nextShout": "Schrei",
              "nextShoutDescription": "War das Schreien das nächste Symptom des Anfalls?",
              "nextSounds": "Verbale und nonverbale Lautäußerungen",
              "nextSoundsDescription": "War das nächste Symptom des Anfalls eine verbale oder nonverbale Lautäußerung?",
              "nextMotorAutomatisms": "Gesichts- und orale Automatismen",
              "nextMotorAutomatismsDescription": "Ist das nächste Symptom eines Anfalls das Auftreten von Gesichts- und Mundautomatismen: Kauen, Schlucken, Klicken, Blinzeln?",
              "nextOtherAutomatisms": "Andere Automatismen",
              "nextOtherAutomatismsDescription": "Waren das nächste Symptom des Anfalls andere Automatismen: ganzer Körper, Gliedmaßen, Stimmausdauer, sexuelle Automatismen, andere komplexe Automatismen?",
              "nextImmobility": "Deutliche Einschränkung der Mobilität oder Immobilität",
              "nextImmobilityDescription": "War das nächste Symptom des Anfalls eine deutliche Einschränkung/Hemmung der Beweglichkeit oder völlige Immobilität?",
              "nextChuck": "Schmatzen und schlürfen",
              "nextChuckDescription": "Ist das nächste Symptom Schmatzen und Schlürfen?",
              "nextEpigastricSymptoms": "Paroxysmale epigastrische Symptome, Speichelfluss, Geschmacksveränderung",
              "nextEpigastricSymptomsDescription": "War das nächste Symptom des Anfalls epigastrische Symptome, Speichelfluss oder Geschmacksveränderung?",
              "nextHeartSymptoms": "Kardiale autonome Symptome: Herzklopfen/Tachykardie/Bradylosigkeit/Asystolie",
              "nextHeartSymptomsDescription": "Waren die nächsten Symptome des Anfalls kardiovaskuläre autonome Symptome: Herzklopfen, Tachykardie oder Bradykardie oder Asystolie?",
              "nextAutonomicSymptoms": "Zusätzliche autonome Symptome: Blässe, Hitzegefühl, Atemnot, abnormale Pupillenbewegung, Harn-/Stuhldrang",
              "nextAutonomicSymptomsDescription": "War das nächste Symptom des Anfalls zusätzliche autonome Symptome: Gesichtsausdruck, Hitzegefühl, Atemnot, Pupillenbewegungsstörung, Harndrang/Kot?",
              "nextLanguageIssues": "Dysphasie/Aphasie, Legasthenie/Alexie und/oder mangelndes Sprachverständnis",
              "nextLanguageIssuesDescription": "Waren die nächsten Symptome des Anfalls Sprachstörungen wie Dysphasie/Aphasie, Legasthenie/Alexie und/oder mangelndes Sprachverständnis?",
              "nextMemoryDisorders": "Gedächtnisstörungen während des Anfalls (andere Sinne normal)",
              "nextMemoryDisordersDescription": "Gab es als nächstes Symptom des Anfalls Gedächtnisstörungen während des Anfalls, bei vollem Erhalt der anderen Sinne?",
              "nextDejaVu": "Wahnvorstellungen: Déjà-vu, Déjà-vecu und ähnliches",
              "nextDejaVuDescription": "War das nächste Symptom des Anfalls ein Déjà-vu, ein Déjà-vecu o.ä.?",
              "nextVisualHallucinations": "Visuelle Halluzinationen: mehrfarbige Formen (Lichtkreise, Blitze, Verlust eines Teils des Gesichtsfeldes oder Blindheit (Amaurose). Und/oder beidseitiger Blackout oder Whiteout-Verlust des Gesichtsfeldes.",
              "nextVisualHallucinationsDescription": "Ob das nächste Symptom des Anfalls visuelle Halluzinationen waren: mehrfarbige Formen (Lichtkreise, Blitze, Verlust eines Teils des Gesichtsfeldes oder Blindheit (Amaurose)? Und/oder beidseitiger Blackout oder Whiteout-Verlust des Gesichtsfeldes?",
              "nextAuditoryHallucinations": "Halluzinationen und ähnliche Hörsymptome",
              "nextAuditoryHallucinationsDescription": "Waren die nächsten Symptome des Anfalls Halluzinationen und/oder ähnliche Hörsymptome?",
              "nextSomatosensorySymptoms": "Fokale somatosensorische Symptome, am häufigsten Parästhesien mit Kribbeln und/oder Taubheitsgefühl, Stechen, Kitzeln, Krabbeln oder dem Gefühl eines Stromschlags.",
              "nextSomatosensorySymptomsDescription": "Waren die nächsten Symptome des Anfalls fokale somatosensorische Symptome, am häufigsten Parästhesien mit Kribbeln und/oder Taubheitsgefühl, Stechen, Kitzeln, Krabbeln oder dem Gefühl eines Stromschlags?",
              "nextBodyDistortion": "Verzerrtes Körperbild mit Bewegungsgefühl (z. B. Schweben) oder veränderter Haltung (z. B. Drehbewegung) in einem ruhenden Glied",
              "nextBodyDistortionDescription": "War das nächste Symptom eines Anfalls ein verzerrtes Körperbild mit dem Gefühl einer Bewegung (z. B. Schweben) oder einer veränderten Haltung (z. B. Drehbewegung) in einem ruhenden Glied?"
            },
            "end": {
              "endAnotherSeizure": "Kann sich dieser Anfall in einen weiteren Anfall verwandeln?",
              "endAnotherSeizureDescription": "Kann dieser Anfall am Ende in einen weiteren Anfall übergehen? (Kein anderes vom gleichen Typ, sondern von einem anderen Typ).",
              "endBodyConvulsions": "Ganzkörperkrämpfe (generalisiert)",
              "endBodyConvulsionsDescription": "Waren die Ganzkörperkrämpfe das letzte Anzeichen eines Anfalls?",
              "endBodyPartConvulsions": "Krämpfe von Körperteilen (fokal)",
              "endBodyPartConvulsionsDescription": "Waren die Zuckungen von Körperteilen das letzte Anzeichen eines Anfalls?",
              "endFaceConvulsions": "Gesichtszuckungen",
              "endFaceConvulsionsDescription": "Waren die Gesichtszuckungen (generalisiert oder fokal) das letzte Symptom eines Anfalls?",
              "endEyeTurnPernament": "Abweichung/Rotation der Augäpfel",
              "endEyeTurnPernamentDescription": "War das letzte Symptom des Anfalls die Drehung der Augäpfel auf eine der vier Seiten?",
              "endBodyTurn": "Den Körper und/oder den Kopf zur Seite drehen",
              "endBodyTurnDescription": "War das letzte Symptom des Anfalls eine Drehung des Körpers und/oder des Kopfes zur Seite?",
              "endGroundFall": "Fallen",
              "endGroundFallDescription": "War das letzte Symptom des Anfalls ein Sturz auf den Boden oder ein teilweiser Sturz auf den Boden (z. B. auf die Knie)? Oder liegt der Patient, wenn er bis zu diesem Zeitpunkt gelegen hat, immer noch?",
              "endUrinating": "Harndrang oder Harndrang",
              "endUrinatingDescription": "War das letzte Symptom des Anfalls ein Drang oder Harndrang?",
              "endSounds": "Verbale und nonverbale Lautäußerungen",
              "endSoundsDescription": "War das letzte Symptom des Anfalls eine verbale oder nonverbale Lautäußerung?",
              "endImmobility": "Deutliche Einschränkung der Mobilität oder Immobilität",
              "endImmobilityDescription": "War das letzte Symptom des Anfalls eine deutliche Einschränkung/Hemmung der Beweglichkeit oder eine völlige Immobilität?",
              "endChuck": "Schmatzen und schlürfen",
              "endChuckDescription": "Sind Schmatzen und Schlürfen die letzten Symptome?",
              "endVomit": "Erbrechen",
              "endVomitDescription": "War das Erbrechen das letzte Symptom des Anfalls?"
            },
            "after": {
              "afterParesisOrParalysis": "Postiktale Parese/Lähmung",
              "afterParesisOrParalysisDescription": "War nach dem Anfall eine postiktale Parese oder eine Lähmung vom Todd-Typ vorhanden?",
              "afterUrinating": "Harndrang oder Harndrang",
              "afterUrinatingDescription": "Gab es nach dem Anfall Harndrang oder Harndrang?",
              "afterConfusion": "Postiktale Verwirrung und/oder retrograde Amnesie",
              "afterConfusionDescription": "Gab es nach dem Anfall Verwirrtheit und/oder retrograde Amnesie?",
              "afterSleep": "Postiktaler Schlaf",
              "afterSleepDescription": "Kam es nach dem Anfall zu postiktalem Schlaf?",
              "afterSalivaOrBloodySalivaFoam": "Schaum auf den Lippen / blutiger Schaum",
              "afterSalivaOrBloodySalivaFoamDescription": "War nach dem Anfall Schaum oder blutiger Schaum auf den Lippen?",
              "afterHyperventilation": "Hyperventilation",
              "afterHyperventilationDescription": "Kam es nach dem Anfall zu einer Hyperventilation?",
              "afterVomit": "Erbrechen",
              "afterVomitDescription": "Kam es nach dem Anfall zu Erbrechen?"
            },
            "feature": {
              "featureDuration": "Dauer des Anfalls",
              "featureDurationDescription": "Wie lange dauert ein solcher Angriff im Durchschnitt?",
              "featureMorningOccurrence": "Morgendliche Anfälle",
              "featureMorningOccurrenceDescription": "Treten Anfälle in den Morgenstunden auf?",
              "featureAwakeningOccurrence": "Anfälle vor/nach dem Aufwachen",
              "featureAwakeningOccurrenceDescription": "Treten Anfälle kurz vor oder kurz nach dem Aufwachen auf?",
              "featureNightOccurrence": "Nächtliche Anfälle",
              "featureNightOccurrenceDescription": "Treten Anfälle während des Nachtschlafs auf?",
              "featureDayOccurrence": "Anfälle tagsüber",
              "featureDayOccurrenceDescription": "Treten tagsüber Anfälle auf?",
              "featureMany": "Cluster-Anfälle",
              "featureManyDescription": "Treten Anfälle in Serie auf? Serien mit oder ohne Unterbrechung (Cluster-Anfälle oder Status epilepticus)",
              "beforeSpecificStimuli": "Anfall, der durch einen bestimmten Reiz ausgelöst wird",
              "beforeSpecificStimuliDescription": "Kann der Anfall durch einen bestimmten Reiz oder Zustand (FS, Geräusche, Schlafmangel, Emotionen, Stress usw.) ausgelöst werden?",
              "formChanges": "Variabler und polymorpher Anfall",
              "formChangesDescription": "Ändert sich dieser Anfall in Form und Dauer? Nicht zu verwechseln mit anderen Arten von Anfällen, wenn sie gleichzeitig auftreten!"
            },
            "fixCriticals": {
              "title": "Verbesserung des Vektors für einen Anfall",
              "subtitle": "Bestätigter Anfall",
              "description1": "Patientenreaktionen, die stark vom ausgewählten Anfalls-Referenzvektor abweichen, werden unten angezeigt.",
              "description2": "Fragen Sie den Patienten sorgfältig nach der Antwort. Wenn er mit der Antwort aus der Umfrage nicht einverstanden ist, korrigieren Sie sie.",
              "oldAnswer": "Umfrage 0 Antwort",
              "newAnswer": "Neue Antwort",
              "saveConfirm": "Sind Sie sicher, dass Sie diesen Teil von Umfrage 0 überschreiben möchten?",
              "saveSuccess": "Die Änderungen wurden erfolgreich gespeichert",
              "vectorChangedBy": "Vektor geändert von"
            },
            "premonition": {
              "trailer": "Vorahnung eines Anfalls",
              "trailerDescription": "Geht dem Anfall eine Vorahnung voraus – ein Gefühl eines bevorstehenden Anfalls in den Minuten/Stunden vor dem Anfall? Gefühl/Ankündigung bewusst oder unbewusst."
            }
          },
          "Comorbidities": {
            "introTitle": "Komorbiditäten",
            "introSubtitle1": "Hier möchten wir feststellen, ob bei Ihnen Begleiterkrankungen vorliegen.",
            "introSubtitle2": "Bedenken Sie, dass Krankheiten den Verlauf einer Epilepsie beeinflussen können.",
            "dataSavedMsg": "Informationen zu Komorbiditäten wurden gespeichert",
            "hasHeadaches": "Kopfschmerzen",
            "answerNo": "Keiner",
            "answerYes": "Ja, das gibt es",
            "answerYesInt": "Ja",
            "disease": "Krankheit",
            "typeDisease": "Geben Sie den Krankheitscode oder den Namen ein",
            "hasPregnancies": "Schwangerschaft",
            "pregnancies": "Anzahl der Schwangerschaften",
            "hasChildbirths": "Geburten",
            "childbirths": "Anzahl der Geburten",
            "hasPsychiatricDiseases": "Psychiatrische Erkrankungen",
            "hasNeurologicalDiseases": "Neurologische Erkrankungen",
            "hasInfectiousDiseases": "Entzündliche und infektiöse Erkrankungen",
            "hasCancerDiseases": "Krebserkrankungen",
            "hasImmuneDiseases": "Blut- und Immunerkrankungen",
            "hasEndocrineDiseases": "Endokrine und Stoffwechselerkrankungen",
            "hasEyeDiseases": "Augenkrankheiten",
            "hasLaryngologicalDiseases": "Laryngologische Erkrankungen",
            "hasHeartDiseases": "Erkrankungen des Herzens und des Kreislaufsystems",
            "hasRespiratoryDiseases": "Erkrankungen der Atemwege",
            "hasDigestiveDiseases": "Erkrankungen des Verdauungssystems",
            "hasSkinDiseases": "Hautkrankheiten",
            "hasBoneDiseases": "Knochenerkrankungen",
            "hasUrogenitalDiseases": "Urogenitalerkrankungen",
            "hasDevelopmentalDefects": "Entwicklungsfehler, Geburtsfehler und Chromosomenaberrationen",
            "hasInjuriesOrPoisoning": "Verletzungen und Vergiftungen",
            "somaticDiseases": "Der Patient hat Krankheiten, die die Behandlung von Epilepsie beeinträchtigen",
            "headaches": "Patient hat Kopfschmerzen"
          },
          "DrugsHistory": {
            "introTitle": "Behandlung",
            "introSubtitle1": "Dies ist der Verlauf Ihrer Behandlung bis heute.",
            "introSubtitle2": "Geben Sie an, welche Medikamente Sie in der Vergangenheit eingenommen haben und welche Wirkung sie hatten. Jedes Arzneimittel trägt den Namen des Wirkstoffs in vollständiger und abgekürzter Form. Wenn Sie sich nicht daran erinnern, machen Sie sich keine Sorgen.",
            "introSubtitle3": "Es ist wichtig, dass Sie den richtigen Medikamentennamen wählen.",
            "drug": "Medizin",
            "drugEffects": "Auswirkungen der Einnahme des Medikaments",
            "addDrug": "Medizin hinzufügen",
            "removeDrug": "Arzneimittel entfernen",
            "typeDrug": "Geben Sie den Namen des Arzneimittels ein",
            "dataSavedMsg": "Der Behandlungsverlauf wurde gespeichert"
          }
        },
        "visitSurvey": {
          "stages": {
            "patientState": "Zustand des Patienten",
            "patientOpinion": "Meinung des Patienten",
            "doctorOpinion": "Meinung des Arztes"
          },
          "patientState": {
            "lastSeizureDate": "Datum des letzten Anfalls vor diesem Besuch",
            "wellBeing": "Allgemeines Wohlbefinden seit dem letzten Besuch",
            "allSeizureRegistered": "Ich habe meine Anfälle seit meinem letzten Besuch im Veranstaltungskalender eingetragen bzw. nicht eingetragen",
            "additionalIncidents": "Die folgenden Ereignisse haben seit dem letzten Besuch stattgefunden – abgesehen von den im Veranstaltungskalender aufgeführten Ereignissen"
          },
          "patientOpinion": {
            "seizureCountByPatient": "War die Anzahl der Anfälle subjektiv gleich?",
            "seizureEvaluationByPatient": "Wie schlimm waren die Anfälle subjektiv?",
            "currentTreatment": "Meine aktuelle Behandlung ist",
            "sideEffects": "Nebenwirkungen der aktuellen Behandlung"
          },
          "doctorOpinion": {
            "seizureCountByDoctor": "Anzahl der Anfälle laut Arzt",
            "seizureEvaluationByDoctor": "Die Qualität von Anfällen nach Meinung des Arztes",
            "treatmentEfficiency": "Die Wirksamkeit der Behandlung nach Meinung des Arztes",
            "treatmentSafety": "Sicherheit der Behandlung nach Meinung des Arztes"
          },
          "date": "Datum",
          "fromDate": "Aus",
          "toDate": "Zu",
          "seizure": "Beschlagnahme",
          "save": "Speichern",
          "addSeizure": "Anfall hinzufügen",
          "noEpisodeAdded": "Es wurde kein Anfall registriert",
          "episodeTypeLbl": "Anfallsarten",
          "incidentDescription": "Beschreibung des Anfalls",
          "noSeizure": "Kein Anfall",
          "next": "Nächste",
          "howManySeizures": "Wie viele Anfälle seit dem letzten Besuch?",
          "countableLbl": "Es gab",
          "unCountableLbl": "Es waren so viele, dass es nicht möglich ist, eine genaue Zahl anzugeben",
          "seizuresMandatoryError": "Beschlagnahmungstermine hinzufügen",
          "add": "Hinzufügen",
          "close": "Schließen",
          "seizuresNotAddedSummary": "Seit dem letzten Besuch sind keine Anfälle hinzugekommen",
          "seizuresDates": "Termine für Anfälle",
          "seizuresSinceLastVisit": "Anzahl der Anfälle seit dem letzten Besuch",
          "per": "pro",
          "addNewSeizureType": "Neuen Anfallstyp hinzufügen",
          "addSeizureTypeDialog": {
            "title": "Neuer Anfallstyp hinzugefügt",
            "name": "Name des Anfalls",
            "success": "Neuer Anfallstyp wurde hinzugefügt"
          },
          "seizurePeriod": "Zeitraum angeben",
          "addPeriodicSeizure": "Punkt hinzufügen",
          "deletePeriodicSeizure": "Punkt entfernen",
          "until": "Bis",
          "seizuresInPeriod": "Episoden zählen"
        },
        "visitCertificates": {
          "create": "Zertifikat erstellen",
          "doNotCreate": "Kein Zertifikat erstellen",
          "certificateType": "Art des Zertifikats",
          "additionalRemarks": "Zusätzliche Bemerkungen",
          "additionalRemarksPlaceholder": "Geben Sie zusätzliche Bemerkungen ein",
          "generate": "Erstellen",
          "certificateContent": "Inhalt des Zertifikats:",
          "pickDate": "Suche dir einen Termin aus",
          "threeMonths": "3 Monate",
          "sixMonths": "6 Monate",
          "oneYear": "1 Jahr",
          "date": "Datum",
          "enterProcedureName": "Geben Sie den Verfahrensnamen ein",
          "enterActivityName": "Geben Sie den Aktivitätsnamen ein",
          "validationError": "Pflichtfeld",
          "addNext": "Nächstes Zertifikat hinzufügen",
          "otherDescriptionLbl": "Geben Sie eine Zertifikatsbeschreibung ein",
          "endOfYear": "Ende des Jahres",
          "addCertificate": "Zertifikat hinzufügen",
          "noCertificates": "Die Liste der Zertifikate ist leer"
        },
        "sickLeaves": {
          "title": "Kranke Blätter",
          "number": "Nummer",
          "dateFrom": "Aus",
          "dateTo": "Zu",
          "type": "Typ",
          "status": "Status",
          "add": "Krankenurlaub hinzufügen",
          "addPopup": {
            "title": "Ausstellung eines neuen Krankenstandes",
            "addBtn": "Lassen Sie sich krankschreiben",
            "codes": {
              "a": "die Arbeitsunfähigkeit nach einer Pause von höchstens 60 Tagen eingetreten ist – verursacht durch dieselbe Krankheit, die die Arbeitsunfähigkeit vor der Pause verursacht hat",
              "b": "während der Schwangerschaft eintretende Arbeitsunfähigkeit",
              "c": "Arbeitsunfähigkeit aufgrund von Alkoholmissbrauch",
              "d": "Arbeitsunfähigkeit aufgrund von Tuberkulose",
              "e": "Arbeitsunfähigkeit aufgrund einer Krankheit, die spätestens innerhalb von 3 Monaten nach Beendigung des Krankenversicherungsschutzes eingetreten ist – im Falle einer Infektionskrankheit, deren Inkubationszeit mehr als 14 Tage beträgt, oder einer anderen Krankheit, deren Symptome offensichtlich sind nach einem Zeitraum von mehr als 14 Tagen ab dem Datum des Ausbruchs der Krankheit"
            },
            "insuredPersonTitle": "Daten der versicherten Person, für die die ZUS ZLA ausgestellt wird",
            "type": "Grund der Unfähigkeit",
            "pesel": "Pesel",
            "fetchData": "Daten bekommen",
            "insuredIn": "Ort der Versicherung",
            "change": "Ändern",
            "payersTitle": "Auswahl der Zahler, für die die Zertifikate erstellt werden",
            "sickLeavesTitle": "Liste der bisherigen ZUS ZLA der versicherten Person",
            "detailsTitle": "Informationen zur Arbeitsunfähigkeit",
            "inabilityFrom": "Unfähigkeit von",
            "inabilityTo": "Unfähigkeit zu",
            "daysNumber": "Anzahl der Tage",
            "reason": "Grund",
            "careForData": "Daten der betreuten Person",
            "relationship": "Verwandtschaft",
            "relationship1": "Baby",
            "relationship2": "Ehepartner, Eltern, Stiefvater, Eltern des Kindes, Großeltern, Schwiegereltern, Enkelkinder, Stiefmutter",
            "relationship3": "andere Person",
            "letterCode": "Buchstabencode",
            "indication": "Medizinische Indikation",
            "addressesTitle": "Adresse des Aufenthalts der versicherten Person während der Zeit der Arbeitsunfähigkeit",
            "payerName": "Name",
            "payerNip": "NIP",
            "hasPue": "Hat sie/er ein PUE-Profil?",
            "address": "Adresse",
            "inabilityRange": "Zeitraum der Unfähigkeit",
            "disease": "Krankheit",
            "number": "Serie und Nummer",
            "status": "Status",
            "successToast": "Es wurde ein Krankenstand ausgestellt",
            "addPayerTitle": "Neuen Zahler hinzufügen",
            "search": "Suchen",
            "payerNotFound": "Zahler nicht gefunden",
            "addNewPayer": "Neuen Zahler hinzufügen",
            "confirmIssueSickLeave": "Sind Sie sicher, dass Sie sich krankschreiben lassen möchten?",
            "addNewAddress": "Neue Adresse hinzufügen",
            "workPlace": "Arbeitsplatz",
            "icd10Note": "Notiz! Der Haupt-ICD-10-Code wird an ZUS weitergeleitet"
          },
          "cancelPopup": {
            "title": "Einen Krankenstand stornieren",
            "reason": "Stornierungsgrund",
            "confirmBtn": "Stornieren Sie einen Krankenstand",
            "confirmCancelSickLeave": "Sind Sie sicher, dass Sie diesen Krankenstand stornieren möchten?",
            "successToast": "Der Krankenstand wurde gestrichen"
          }
        }
      },
      "patientVisits": {
        "nextVisit": {
          "title": "Besuche",
          "nextTitle": "Nachfolgende Besuche",
          "changeDate": "Datum ändern",
          "join": "Besuch beginnen",
          "forego": "Stornieren",
          "payFor": "Bezahlen Sie für den Besuch"
        },
        "lastRecommendation": {
          "title": "Empfehlung vom vorherigen Besuch",
          "pdf": "Zusammenfassung herunterladen",
          "drug": "Arzneimittel",
          "dosages": "Dosierungen",
          "careLbl": "Aufpassen",
          "from": "aus",
          "to": "Zu"
        },
        "visitList": {
          "title": "Besuchsarchiv",
          "subtitle": "vergangene Besuche",
          "pdf": "PDF Herunterladen",
          "date": "Datum",
          "type": "Typ",
          "category": "Kategorie",
          "doctor": "Arzt",
          "summary": "Empfehlungen",
          "invoice": "Rechnung"
        }
      },
      "password": {
        "reset": {
          "title": "Passwort-Wiederherstellung",
          "subtitle1": "Geh zurück zu",
          "subtitle2": "Anmeldebildschirm",
          "form": {
            "email": "E-Mail-Adresse",
            "send": "Bestätigen"
          },
          "response1": "An die angegebene E-Mail-Adresse wurde eine Nachricht mit einem Bestätigungslink zum Zurücksetzen gesendet.",
          "response2": "Bitte schließen Sie dieses Browserfenster und klicken Sie auf den gesendeten Link.",
          "resendMessage": "Der Link zur Passwortänderung wurde erneut gesendet"
        },
        "resetConfirm": {
          "title": "Bestätigen Sie die Passwortänderung",
          "form": {
            "password": "Neues Kennwort",
            "passwordConfirm": "Bestätige das Passwort",
            "send": "Bestätigen"
          },
          "response": "Passwort geändert.",
          "loginBtnLbl": "Anmeldung"
        }
      },
      "patient": {
        "account": {
          "register": {
            "confirm": {
              "outcomeSuccesMsg": "Registrierung erfolgreich abgeschlossen",
              "outcomeSuccesDetMsg": "Klicken Sie auf die Schaltfläche unten, um zur Anmeldeseite zu gelangen",
              "outcomeFailureMsg": "Beim Versuch, die Registrierung zu bestätigen, ist ein Problem aufgetreten",
              "outcomeFailureDetMsg": "Bitte versuchen Sie es erneut, indem Sie auf den in der E-Mail gesendeten Link klicken, oder kontaktieren Sie das Neuroterminal-Team",
              "saveBtn": "Anmeldung"
            }
          },
          "settings": {
            "title": "Einstellungen",
            "saveLbl": "Speichern",
            "succesMsg": "Deine Einstellungen wurden gespeichert"
          }
        },
        "therapy": {
          "prescriptions": {
            "newLbl": "neue Rezepte",
            "archiveLbl": "Rezeptarchiv",
            "dateFromLbl": "Veröffentlicht von",
            "dateToLbl": "Lautend auf",
            "noDataLbl": "Keine Rezepte",
            "previewLbl": "Vorschau",
            "prescriptionLbl": "Rezept",
            "headers": {
              "dateLbl": "Datum",
              "doctorLbl": "Arzt",
              "numberLbl": "Rezeptcode",
              "actionsLbl": "Rezept"
            },
            "dialog": {
              "downloadLbl": "Herunterladen",
              "closeLbl": "Schließen"
            }
          }
        }
      },
      "doctor": {
        "account": {
          "activation": {
            "title": "Kontoaktivierung im System",
            "alreadySent": "Die Unterlagen wurden bereits verschickt",
            "alreadyFinished": "Die Kontoaktivierung ist abgeschlossen",
            "fillInFormMsg": "Bitte geben Sie unten die Daten ein und klicken Sie auf „Senden“.",
            "formAgreementScanLbl": "Vereinbarungsscan",
            "formInsuranceScanLbl": "Versicherungsscan",
            "acceptTerms1": "Ich akzeptiere",
            "acceptTerms2": "Nutzungsbedingungen",
            "acceptPolicy1": "Ich akzeptiere",
            "acceptPolicy2": "Datenschutzrichtlinie",
            "sendLbl": "Schicken",
            "submitMsg1": "Vielen Dank, wir überprüfen Ihre Daten.",
            "submitMsg2": "Wir senden Ihnen eine Bestätigung per E-Mail."
          },
          "settings": {
            "title": "Einstellungen",
            "saveLbl": "Speichern",
            "successMsg": "Einstellungen erfolgreich gespeichert"
          }
        }
      },
      "prescriptions": {
        "home": {
          "titleLbl": "E-Rezepte",
          "text1Lbl": "Bitte um Rezept",
          "text2Lbl": "Keine Rezeptanfragen"
        },
        "list": {
          "currentLbl": "Aktuell",
          "archiveLbl": "Archiviert",
          "showLbl": "Zeigen",
          "sortNewestLbl": "Neueste",
          "sortDeadlingLbl": "Dringendste"
        },
        "details": {
          "medHistLbl": "Krankengeschichte",
          "pleadLbl": "bittet um ein Rezept",
          "peselLbl": "Pesel",
          "copyPeselLbl": "Pesel kopieren",
          "checkEwusLbl": "Checken Sie in EWUŚ ein",
          "addressLbl": "Adresse",
          "phoneLbl": "Telefonnummer",
          "issueDateLbl": "Datum der letzten Ausstellung des Rezepts",
          "viewLbl": "Rezeptvorschau",
          "drugsLbl": "Angeforderte Medikamente",
          "commentLbl": "Kommentar für den Arzt",
          "openLbl": "Öffnen Sie den E-Rezept-Ersteller",
          "denyInfoLbl": "Sollten Sie entscheiden, dass dieser Antrag ungültig ist, können Sie die Ausstellung eines E-Rezepts ablehnen",
          "denyLbl": "Leugnen",
          "prescriptionLbl": "Rezept",
          "confirmInfo1Lbl": "Rezeptanfrage ablehnen",
          "confirmInfo2Lbl": "Sind Sie sicher, dass Sie die Ausstellung dieses Rezepts ablehnen möchten?",
          "confirmYesLl": "Ja, leugnen",
          "confirmNoLbl": "NEIN",
          "rejectInfoLbl": "Rezeptanfrage abgelehnt",
          "forwardInfoLbl": "Informationen wurden an den Administrator gesendet",
          "rejectBtn": "Anfrage ablehnen",
          "rejectDlgTitle": "Warum möchten Sie die Anfrage ablehnen?",
          "rejectReasonPlaceholder": "Grund für die Ablehnung",
          "forwardPrescription": "Wenn Sie dem Patienten kein E-Rezept ausstellen können und dies lieber der Rezeption überlassen möchten",
          "forwardBtn": "E-Rezept ablehnen",
          "forwardDlgTitle": "Geben Sie eine Notiz ein, die an die Rezeption (Administrator) gesendet wird.",
          "rejectCommentDisclaimer": "Der Grund für die Ablehnung wird dem Patienten mitgeteilt",
          "characterCount": "Zeichenanzahl:",
          "infoForAdmin": "Arzt verweigert Ausstellung eines E-Rezeptes:"
        },
        "creator": {
          "typeLbl": "Stellen Sie ein E-Rezept aus über",
          "returnLbl": "Zurückkehren",
          "peselLbl": "Pesel",
          "copyPeselLbl": "Pesel kopieren",
          "checkEwusLbl": "Checken Sie in EWUŚ ein",
          "issueDateLbl": "Datum der letzten Ausstellung des Rezepts",
          "viewLbl": "Rezeptvorschau",
          "drugsLbl": "Angeforderte Medikamente",
          "commentLbl": "Kommentar für den Arzt",
          "addProductLbl": "Fügen Sie ein weiteres Produkt hinzu",
          "govText1Lb": "Gehe zur Seite",
          "govText2Lb": "und ein Rezept ausstellen",
          "pinLbl": "Geben Sie die 4-stellige Rezept-PIN ein",
          "fileLbl": "Rezept-PDF-Datei (optional)",
          "fileChooseLbl": "Wählen Sie, um eine Datei auszuwählen",
          "issueLbl": "E-Rezept ausstellen",
          "prescriptionLbl": "Rezept",
          "notValidMsg": "Der Inhalt des Rezeptformulars ist ungültig",
          "successMsg": "Rezept wurde ausgestellt",
          "prescriptionList": "Ausgestellte Rezepte haben folgende Codes"
        },
        "product": {
          "drugLbl": "Arzneimittel",
          "removeLbl": "Entfernen",
          "packageLbl": "Paket",
          "refundLbl": "Erstattung",
          "packNumLbl": "Anzahl der Packstücke",
          "dosageLbl": "Aktuelle Dosierung",
          "refundCauseLbl": "Zahlung",
          "recieptType": "Quittungstyp",
          "permissions": "Zusätzliche Berechtigungen",
          "description": "Benutzerdefinierte Beschreibung der Dosierung",
          "dosage": "Dosierung"
        },
        "ewus": {
          "checkingLbl": "Pesel in EWUŚ prüfen",
          "notFoundLbl": "Der Patient konnte in EWUŚ nicht gefunden werden",
          "insuredLbl": "Der Patient ist versichert",
          "notInsuredLbl": "Der Patient ist nicht versichert",
          "closeLbl": "Schließen"
        },
        "doctorPass": {
          "titleLbl": "Passwort des ZUS-Zertifikats",
          "cancelLbl": "Stornieren",
          "confirmLbl": "Bestätigen"
        }
      },
      "eReferrals": {
        "creator": {
          "title": "Neue E-Überweisung",
          "type": "Empfehlungstyp",
          "destination": "Klinik / Labor / Krankenstation",
          "icd10": "Diagnose",
          "selectedIcd9": "Ausgewählte medizinische Verfahren",
          "selectIcd9": "Wählen Sie mindestens einen medizinischen Eingriff",
          "icd9": "Medizinische Prozedur",
          "comment": "Kommentar des Arztes",
          "issueReferral": "E-Überweisung ausstellen",
          "icdNoResults": "Keine Suchergebnisse",
          "successMsg": "Die E-Überweisung wurde erfolgreich ausgestellt",
          "issueDate": "Ausgabedatum"
        }
      },
      "header": {
        "labelP1": "Geduldig",
        "labelP2": "Portal"
      },
      "epicrisis": {
        "treatmentStart": "Der Patient befindet sich seit",
        "ictalDiagnosis": "ICTAL-Diagnose",
        "mainDiagnosis": "ICD10-Diagnose",
        "additionalDiagnosis": "Zusätzliche ICD10-Diagnose",
        "note0Header": "Hinweis 0",
        "note0Label": "Erste Patientenbeobachtungen – durchgeführt im Rahmen der Umfrage 0 oder beim ersten Besuch des Patienten bei Neurosphera",
        "epicrisisEntryHeader": "Einträge in Epicrisis",
        "epicrisisEntryLabel": "Hier finden Sie wichtige Informationen zur Krankheit des Patienten",
        "addEntryLabel": "Eintrag hinzufügen",
        "entrySavedMsg": "Eintrag wurde gespeichert",
        "createdBy": "Erstellt von",
        "addedToObservation": "Zur Epikrise hinzugefügt",
        "migration": "Migration"
      }
    },
    "component": {
      "consultation": {
        "diagnosisSelection": {
          "searchIcd10": "Suche nach Diagnose",
          "searchIcd10NoResult": "keine Ergebnisse gefunden",
          "empty": "Die Patientendiagnoseliste ist leer",
          "main": "Hauptsächlich",
          "markAsMain": "Als Haupt markieren",
          "icd10": "ICD 10"
        },
        "seizureDiagnosisSelection": {
          "first": "Anfälle ersten Typs",
          "second": "Anfälle zweiter Art",
          "third": "Anfälle dritter Art"
        },
        "drugDosageDialog": {
          "standardTimes": {
            "morning": "Morgen",
            "noon": "Mittag",
            "evening": "Abend"
          },
          "standardDose": {
            "morning": "Morgendosis",
            "noon": "Mittagsdosierung",
            "evening": "Abenddosierung"
          },
          "addCustomDose": "Geben Sie die Dosierung zum angegebenen Zeitpunkt hinzu",
          "cancel": "Stornieren",
          "save": "Speichern"
        },
        "drugDosageEdition": {
          "drugs": "Medikamente",
          "stage": "Schritt",
          "searchDrug": "Medikamente hinzufügen",
          "searchDrugNoResult": "keine Ergebnisse gefunden",
          "dosage": "Wählen Sie die Dosierung",
          "cancelTherapy": "stornieren",
          "saveTherapy": "Therapie retten",
          "addTherapy": "Nächsten Schritt hinzufügen",
          "previousEndDateError": "Für den vorherigen Schritt ist kein Enddatum definiert",
          "previousEndDateBeforeCurrentError": "Das Enddatum der Therapie muss nach dem aktuellen Datum liegen",
          "activeSubstanceSearch": "Suche nach Medikamenten mit Wirkstoff",
          "removeManyTitle": "Das Medikament automatisch aus Folgetherapien entfernen?",
          "addManyTitle": "Das Medikament automatisch zu Folgetherapien hinzufügen?",
          "yesSameDosage": "Ja, bei gleicher Dosierung",
          "yesDifferentDosage": "Ja, aber mit der gleichen Dosierung",
          "refreshOrder": "Bestellung aktualisieren"
        },
        "drugTherapySummary": {
          "current": "Aktuelle Medikamententherapie",
          "future": "Zukünftige medikamentöse Therapie",
          "past": "Frühere medikamentöse Therapie",
          "from": "Aus",
          "to": "Zu"
        },
        "drugTherapyHistory": {
          "title": "Bearbeiten der Geschichte medikamentöser Therapien",
          "changeHistory": "Vergangene Therapien",
          "noAccess": "Sie haben keinen Zugriff, um den Verlauf der medikamentösen Therapien für diesen Patienten zu ändern",
          "updateSuccess": "Der Verlauf der Arzneimitteltherapien wurde erfolgreich aktualisiert"
        },
        "medicalHistory": {
          "chart": {
            "loadingLbl": "Daten werden geladen",
            "seizuresLbl": "Anfälle",
            "drugsLbl": "Medikamente",
            "resultsLbl": "Ergebnisse",
            "visitsLbl": "Besuche",
            "eventsLbl": "Veranstaltungen",
            "consultationLbl": "Beratung",
            "seizuresNumberLbl": "Anfälle",
            "seizuresDurationLbl": "Dauer",
            "trendBtn": "Trendlinie",
            "disabledLbl": "Jetzt unzugänglich",
            "trendBetterLbl": "Trend besser",
            "trendWorseLbl": "Tendenz schlechter",
            "rangeLbl": "Datenreichweite"
          }
        },
        "patientSummary": {
          "personalInfo": {
            "personalData": "persönliche Daten"
          }
        }
      },
      "doctor": {
        "registration": {
          "form": {
            "firstName": "Name",
            "lastName": "Familienname, Nachname",
            "pesel": "Pesel",
            "pwz": "PWZ-Nummer",
            "email": "E-Mail-Adresse",
            "password": "Passwort",
            "passwordConfirm": "Bestätige das Passwort",
            "phone": "Telefon",
            "diploma": "Gescanntes Diplom hinzufügen",
            "isSpecialization": "Ich habe mich auf Neurologie spezialisiert",
            "isPractise": "Ich leite eine private Arztpraxis",
            "practiceInfoName": "Name der Praxis",
            "practiceInfoNipNr": "NIP",
            "practiceInfoRpwdlNr": "RPWDL-Nummer",
            "practiceInfoRegonNr": "REGON",
            "practiceAddressStreet": "Straße",
            "practiceAddressHouseAndApartmentNr": "Haus-/Wohnungsnr",
            "practiceAddressHouseNr": "Straße Hausnr",
            "practiceAddressApartmentNr": "Wohnung Nr",
            "practiceAddressZipcode": "PLZ",
            "practiceAddressCity": "Stadt",
            "practiceAddressCountry": "Land",
            "isApproveDataProcessing": "Ich stimme der Verarbeitung meiner Daten zu",
            "send": "Anfrage senden",
            "response1": "Danke für die Registrierung",
            "response2": "Wir werden Sie per E-Mail kontaktieren",
            "gotAccountMsg1": "Sie haben bereits ein Konto?",
            "gotAccountMsg2": "Anmeldung"
          }
        },
        "database": {
          "doctor": {
            "list": {
              "noMedicalHistoryMsg": "Der Anamnesebogen wurde noch nicht ausgefüllt. Bitte füllen Sie es mit möglichst vielen Informationen aus, bevor Sie einen führenden Arzt auswählen.",
              "gotoMedicalHistoryLbl": "Gehen Sie zum Formular",
              "titleLbl": "Arztdatenbank",
              "titleDescLbl": "Wenn Sie Ihren leitenden Arzt wechseln möchten, klicken Sie im Profil des von Ihnen gewählten Arztes auf die Schaltfläche „Auswählen“ und füllen Sie anschließend einen kurzen Fragebogen aus.",
              "subtitleLbl": "Ärzte",
              "patientTypeLbL": "Patienten geführt",
              "sortTypeLbL": "Sortiere nach",
              "noResultsLbL": "Es konnten keine Ärzte gefunden werden, die den Suchparametern entsprechen",
              "sortTypes": {
                "patientsAsc": "Führte Patienten aufsteigend",
                "patientsDesc": "Führte die Patienten zum Abstieg",
                "alphabeticAsc": "Alphabetisch aufsteigend",
                "alphabeticDesc": "Alphabetisch absteigend",
                "remotePriceAsc": "Preis für Fernbesuche steigend",
                "remotePriceDesc": "Preis für Fernbesuche sinkend",
                "stationaryPriceAsc": "Stationärer Besuchspreis steigend",
                "stationaryPriceDesc": "Stationärer Besuchspreis absteigend"
              },
              "content": {
                "juniorExpLbl": "Junior-Experte",
                "standardExpLbl": "Experte",
                "seniorExpLbl": "Senior-Experte",
                "leadLbL": "leitender Arzt",
                "bioLbl": "BIO",
                "pricesLbl": "PREISLISTE",
                "servicesLbl": "LEISTUNGSUMFANG",
                "remoteLbl": "Fernbesuch",
                "stationaryLbl": "Stationärer Besuch",
                "messageLbl": "Nachricht",
                "prescriptionLbl": "Rezept"
              }
            },
            "selection": {
              "request": {
                "alert": {
                  "youHaveTimeTo": "Sie müssen bis antworten",
                  "toReplyTo": "auf die Bitte um Betreuung des Patienten",
                  "detailsLbl": "Einzelheiten",
                  "closeLbl": "Schließen"
                }
              }
            }
          },
          "lead": {
            "titleLbl": "Leitender Arzt",
            "patientsLbl": "Patienten",
            "visitsLbl": "Besuche",
            "firstVisitLbl": "Erste",
            "subsequentVisitLbl": "Anschließend",
            "bioLbl": "Bio",
            "bookVisitLbl": "Buchen Sie einen Besuch",
            "doctor": {
              "change": {
                "request": {
                  "dialog": {
                    "title1": "Bestätigen Sie die Auswahl des leitenden Arztes",
                    "title2": "Sind Sie sicher, dass Sie Ihren Lead-Dcotor ändern möchten?",
                    "selectionConfirmMsg": "Sind Sie sicher, dass Sie diesen Arzt wählen möchten?",
                    "historyCommentLbl": "Kommentar zu Ihrer Krankheitsgeschichte",
                    "reasonLbl": "Warum haben Sie sich entschieden, Ihren Arzt zu wechseln?",
                    "reasonCommentLbl": "Grund ändern",
                    "evaluationLbl": "Wie würden Sie Ihren derzeitigen Arzt bewerten?",
                    "overallCommentLbl": "Kommentare zur Zusammenarbeit mit dem derzeitigen Arzt",
                    "overallCommentInfo": "Dadurch können wir die Qualität der erbrachten Leistungen kontrollieren und die leistungsschwachen Ärzte aus dem System entfernen, nachdem wir die Gründe überprüft haben.",
                    "confirmLbl": "Bestätigen Sie den Wechsel des leitenden Arztes",
                    "cancelLbl": "Stornieren",
                    "reasons": {
                      "punctuality": "Arzt ist nicht pünktlich",
                      "result": "Keine Verbesserung",
                      "communication": "Kommunikationsprobleme",
                      "other": "Andere"
                    },
                    "evaluations": {
                      "vgood": "sehr gut",
                      "good": "Gut",
                      "average": "Durchschnitt",
                      "bad": "schlecht"
                    },
                    "confirmationLbl": "Eine Nachricht wurde an den Arzt gesendet",
                    "closeLbL": "Schließen"
                  }
                },
                "response": {
                  "dialog": {
                    "title": "Sind Sie damit einverstanden, der leitende Arzt für diesen Patienten zu werden?",
                    "firstSeizureLbl": "Datum der Erstbeschlagnahme",
                    "epilepsyDescLbl": "Epilepsie-/Anfallstyp",
                    "drugsUsedLbl": "Bisher verwendete Medikamente",
                    "patientCommentLbl": "Patientenkommentar",
                    "acceptLbl": "Ich stimme zu",
                    "rejectLbl": "ich bin nicht einverstanden",
                    "rejectReasonLbl": "Ablehnungsgrund",
                    "confirmLbl": "Bestätigen",
                    "cancelLbl": "Stornieren",
                    "rejection": {
                      "reasons": {
                        "punctuality": "Arzt ist nicht pünktlich",
                        "result": "Keine Verbesserung",
                        "communication": "Kommunikationsprobleme",
                        "full": "Keine offenen Stellen",
                        "seizure": "Anfallstyp entspricht nicht der Spezialisierung",
                        "age": "Nicht konformes Altersprofil",
                        "other": "Andere"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "patient": {
        "registration": {
          "form": {
            "email": "E-Mail-Adresse",
            "password": "Passwort",
            "mandatoryDescription": "Felder, die mit * markiert sind, sind Pflichtfelder",
            "passwordConfirm": "Bestätige das Passwort",
            "isAgreeTerms1": "Ich stimme zu",
            "isAgreeTerms2": "die Nutzungsbedingungen",
            "isAgreeTerms3": "",
            "isApprove1": "Ich habe die darin enthaltenen Regeln zur Verarbeitung meiner personenbezogenen Daten gelesen",
            "isApprove2": "Neurosphera sp. z o.o. Datenschutzrichtlinie",
            "isApprove3": "Und",
            "isApprove4": "Datenschutzrichtlinie von Neuroterminal S.A",
            "marketing1and2": "Ich bin damit einverstanden, Marketingmitteilungen (einschließlich kommerzieller Informationen) zu erhalten, deren Zweck darin besteht, Dienstleistungen und Waren zu bewerben, einschließlich der Kommunikation, die unter Verwendung meiner persönlichen Daten unter Verwendung meiner E-Mail-Adresse (Empfang von E-Mail-Nachrichten) und Telefonnummer (Empfang von Textnachrichten) erfolgt und eingehende Anrufe).",
            "marketing3": "Ich möchte im Namen von Neurosphera sp. einen Newsletter von Neuroterminal S.A. erhalten. z o.o.",
            "send": "Account registrieren",
            "response1": "Danke für die Registrierung",
            "response2": "Wir senden Ihnen die Anmeldebestätigung per E-Mail zu",
            "gotoLoginLbl": "Melden Sie sich beim System an",
            "gotAccountMsg1": "Sie haben bereits ein Konto?",
            "gotAccountMsg2": "Anmeldung",
            "consentsTitle": "Akzeptanz von Vorschriften und Marketinggenehmigungen",
            "consentsAccept": "Ausgewählte akzeptieren"
          },
          "confirmation": {
            "header": "Registrierung bestätigen!",
            "content1": "Wir haben den Aktivierungslink gesendet an:",
            "content2": "Überprüfen Sie Ihre E-Mails und klicken Sie auf den Aktivierungslink",
            "sendAgainPrefix": "Sie haben keine E-Mail erhalten:",
            "sendAgain": "Nochmals senden!",
            "contactReception": "oder wenden Sie sich an die Rezeption:",
            "resendBlocked": "Falls Sie keine E-Mail mit dem Registrierungslink erhalten haben, wenden Sie sich bitte an den Kundendienst."
          }
        },
        "documentation": {
          "document": {
            "add": {
              "title": "Dokument hinzufügen",
              "nameLbL": "Name",
              "typeLbl": "Typ",
              "resultDateLbl": "Ergebnisdatum",
              "confirmLbl": "Anfügen",
              "filesToLargeLbl": "Einige Dateien waren zu groß zum Hinzufügen",
              "filesEmptyContentLbl": "Einige Dateien sind beschädigt oder haben keinen Inhalt",
              "wrongFileType": "Falscher Dateityp. Nur PDF, Bilder oder Videos erlaubt.",
              "unsavedFormWarningMsg1": "Das Formular enthält nicht gespeicherte Daten",
              "unsavedFormWarningMsg2": "Möchten Sie die Daten hochladen, bevor Sie den Bildschirm verlassen?",
              "yesLbl": "Ja",
              "noLbl": "NEIN",
              "uploadingMsg1": "Datei-Upload läuft.",
              "uploadingMsg2": "Bei größeren Dateien kann dies eine Weile dauern",
              "uploadingMsg1Templated": "Datei-Upload läuft {filename}"
            },
            "confirm": {
              "title": "Dokument entfernen?",
              "cancelLbl": "Stornieren",
              "confirmLbl": "Bestätigen",
              "successMsg": "Dokument entfernt"
            },
            "edit": {
              "title": "Dokument bearbeiten",
              "nameLbL": "Name",
              "typeLbl": "Typ",
              "resultDateLbl": "Ergebnisdatum",
              "fileNameLbL": "Dateiname",
              "cancelLbl": "Stornieren",
              "confirmLbl": "Bestätigen",
              "changedMsg": "Dokument erfolgreich geändert"
            },
            "viewer": {
              "closeLbl": "Schließen",
              "typeNotSupported": "Dieser Dateityp wird im Viewer nicht unterstützt"
            },
            "files": {
              "selection": {
                "title1": "Ziehen und ablegen",
                "title2": "Dateien, die Sie hier hinzufügen möchten",
                "title3": "oder",
                "title4": "Dateien auswählen",
                "title5": "vom Computer"
              }
            },
            "list": {
              "title1": "Medizinische Dokumentation",
              "title2": "Hier können Sie Ihre mediale Dokumentation aufbewahren",
              "title3": "Durch das Hinzufügen von Testergebnissen können Sie dem Arzt helfen, eine genaue Diagnose zu stellen und die Behandlungschancen zu verbessern.",
              "noDataLbl": "Keine Daten",
              "noResultsLbl": "Kein Datum",
              "loadingLbl": "Dokumentation wird geladen",
              "downloadLbl": "Herunterladen",
              "showLbl": "Zeigen",
              "editLbl": "Bearbeiten",
              "removeLbl": "Entfernen",
              "headers": {
                "type": "Typ",
                "name": "Name",
                "fileName": "Datei",
                "resultDate": "Ausgegeben",
                "additionDate": "Hinzugefügt"
              },
              "filters": {
                "documentTypeLbl": "Zeigen",
                "documentTypeAllLbl": "Zeige alles",
                "resultDateFromLbl": "Ergebnisdatum von",
                "resultDateToLbl": "Ergebnisdatum bis"
              },
              "markCompleted": "Als abgeschlossen markieren",
              "markCompletedDescription": "Wenn Sie die Beschreibung als abgeschlossen markieren, wird sie von der Rezeption an den Patienten gesendet. Wenn Sie möchten, können Sie optionalen Text für die Rezeption hinzufügen.",
              "isCompleted": "Vollendet"
            },
            "disk": {
              "space": {
                "filledLbl": "{filled} GB von {available} GB belegt"
              }
            }
          }
        },
        "nearest": {
          "visits": {
            "forthcomingLbl": "bevorstehende Besuche",
            "forthcomingConsultLbl": "(Beratungen)",
            "noVisitMsg": "Keine bevorstehenden Besuche.",
            "noVisitZeroMsg": "Keine bevorstehenden Besuche. Bitte buchen Sie einen Nullbesuch.",
            "noVisitZeroDisclaimerMsg": "Während des Besuchs Null analysiert ein Arzt Ihre Krankengeschichte und erstellt Ihren Gesundheitsplan",
            "sosVisitLbl": "SOS",
            "bookVisitLbl": "Buchbesuch",
            "cannotBookVisitMsg": "Sie können keinen Besuch buchen, da nicht alle Felder im Abschnitt „Meine Daten“ ausgefüllt sind",
            "editLbl": "Bearbeiten",
            "cancelLbl": "Stornieren",
            "payLbl": "Zahlen",
            "absentConsultation": "Fehlende Beratung – ohne Beteiligung des Patienten",
            "dialog": {
              "paymentPending": "Wir warten auf die Zahlungsbestätigung",
              "paymentError": "Die Zahlung ist fehlgeschlagen",
              "payTill1": "Sie müssen bis bezahlen",
              "payTill2": "andernfalls wird Ihre Anfrage storniert.",
              "pay": "Zahlen Sie für einen Besuch"
            }
          }
        },
        "treatment": {
          "plan": {
            "titleLbl": "Gesundheitsplan",
            "inaccessibleLbl": "nicht zugänglich",
            "noVisitZeroDisclaimerMsg": "Sie wählen den Krankenversicherungsplan während Ihres Besuchs bei Null."
          },
          "prescription": {
            "request": {
              "stockLbl": "Lagerbestand für {days} Tage",
              "otherQuestLbl": "Brauche ein anderes Medikament",
              "otherDrugLbl": "Geben Sie im Kommentar den Namen des Arzneimittels ein. Denken Sie daran, den genauen Namen des Arzneimittels anzugeben.",
              "confirmTitleLbl": "Bestätigung",
              "confirmTextLbl": "Sie werden zur Seite für elektronische Zahlungen weitergeleitet. Weitermachen?",
              "cancelBtn": "Stornieren",
              "confirmBtn": "Bestätigen",
              "chooseDoctor": "Wählen Sie den Arzt aus, den Sie um ein Rezept bitten möchten",
              "askForPrescriptionNotAvailable": "Sie können eine Rezeptanfrage senden, nachdem Sie den Arzt aufgesucht haben, der das Rezept ausstellt",
              "noLeadDoctorAvailable": "Ihr leitender Arzt ist derzeit nicht erreichbar. Sie können eine Rezeptanfrage an den diensthabenden Arzt senden."
            }
          }
        },
        "lead": {
          "doctor": {
            "titleLbl": "leitender Arzt",
            "noVisitZeroDisclaimerMsg1": "Sie wählen Ihren leitenden Arzt",
            "noVisitZeroDisclaimerMsg2": "während des Besuchs Null",
            "changeLbl": "Ändern",
            "prescriptLbl": "Fordern Sie ein Rezept an",
            "messageLbl": "Nachricht schreiben",
            "servicesLbl": "Erbrachte Dienstleistungen",
            "services": {
              "interview": "Interview",
              "therapy": "Einstellungstherapie",
              "commission": "Ausgabekommission",
              "prescription": "Rezept ausstellen",
              "documents": "Überweisung, Bescheinigung, Krankenstand ausstellen"
            },
            "scope": {
              "bullet1": "Erster medizinischer Kontakt des Patienten",
              "bullet2": "Umfassende Patientenbetreuung, die zusätzliche Tests anordnet oder möglicherweise Berater an Spezialisten überweist",
              "bullet3": "Berater, der den Patienten direkt unterstützt und den Therapieplan überwacht",
              "bullet4": "Unterstützt vom gesamten Team, dem iCtal®-Roboter und dem medizinischen Überwachungs- und Automatisierungssystem"
            }
          }
        },
        "hashtags": {
          "title": "Hashtags",
          "subtitle": "Es ist ein Ort, an dem Patienten mit einem vordefinierten Hashtag markiert werden können",
          "label": "Suchen Sie nach Hashtags"
        }
      },
      "finances": {
        "payment": {
          "history": {
            "title": "Siedlungsgeschichte",
            "headers": {
              "month": "Monat",
              "sum": "Gesamt"
            }
          }
        },
        "invoice": {
          "summary": {
            "text1": "Finanzübersicht",
            "text2": "Dort finden Sie die für die Rechnungsstellung notwendigen Daten",
            "text3": "Fragen zu Rechnungen?",
            "text4": "Kontaktieren Sie unsere Finanzabteilung",
            "btn_lbl": "Zusammenfassung herunterladen"
          }
        },
        "general": {
          "summary": {
            "title": "Finanzen",
            "return": "Zurück",
            "table": {
              "headers": {
                "label": "Art des Besuchs",
                "price": "Preis",
                "amount": "Menge",
                "sum": "Gesamt"
              },
              "summary": "Gesamt"
            }
          }
        }
      },
      "genericDialog": {
        "approve": "Ja",
        "cancel": "NEIN",
        "close": "Schließen"
      },
      "videochat": {
        "waitingForPatient": "Warten auf die Verbindung des Patienten",
        "waitingForDoctor": "Warten auf die Verbindung zum Arzt",
        "connectionError": "Es ist ein Verbindungsfehler aufgetreten",
        "visitEnded": "Die Telefonkonferenz wurde beendet. Sitzung wurde beendet.",
        "deviceLoadError": "Fehler beim Laden der Geräte",
        "accessDenied": "Bitte gewähren Sie im Browser Zugriff auf Mikrofon und Kamera und aktualisieren Sie die Seite ggf",
        "accessDeniedAlert": "Kein Zugriff auf Mikrofon und Kamera",
        "missingAudioInput": "Mikrofongerät fehlt",
        "missingVideoInput": "Kameragerät fehlt",
        "accessGrantedBut": "Es wurden jedoch Genehmigungen eingeholt:",
        "cannotConnectCamera": "Es kann keine Verbindung zur Kamera hergestellt werden",
        "cannotConnectMicrophone": "Es kann keine Verbindung zum Mikrofon hergestellt werden",
        "deviceAlreadyInUse": "Bitte stellen Sie sicher, dass das Gerät nicht bereits verwendet wird, und versuchen Sie es dann erneut",
        "chooseDeviceDialog": {
          "chooseDevices": "Wählen Sie Geräte aus",
          "visitStartMsg1": "Der Besuch beginnt um",
          "visitStartMsg2": "Für ein reibungsloses Meeting wählen Sie die Audio- und Videogeräte aus, die Sie verwenden möchten",
          "camera": "Kamera",
          "microphone": "Mikrofon",
          "speakers": "Lautsprecher"
        },
        "dialog": {
          "confirm": "Bestätigen"
        },
        "tooltip": {
          "disableAudio": "Deaktiviere Audio",
          "enableAudio": "Audio aktivieren",
          "disableVideo": "Video deaktivieren",
          "enableVideo": "Video aktivieren",
          "terminate": "Beenden Sie Ihren Online-Besuch"
        },
        "confirm": {
          "warningMsg1": "Besichtigung noch im Gange",
          "warningMsg2": "Möchten Sie eine Telefonkonferenz für einen laufenden Besuch beenden?"
        }
      },
      "updateMedicalEventDialog": {
        "title": "Veranstaltung bearbeiten",
        "changesSaved": "Änderungen erfolgreich gespeichert"
      },
      "addMedicalEventDialog": {
        "title": "Ereignis hinzufügen",
        "descriptionPlaceholder": "Geben Sie eine kurze Beschreibung ein",
        "patientSeizureTypePlaceholder": "Art des Anfalls",
        "from": "Aus",
        "to": "Zu",
        "duration": "Dauer",
        "cancel": "Stornieren",
        "approve": "Speichern",
        "addNext": "Neue hinzufügen",
        "delete": "Löschen",
        "addSuccessMsg": "Veranstaltung wurde hinzugefügt",
        "noSeizureTypes": "Um einen neuen Anfall hinzuzufügen, müssen Sie Ihre Anfallstypen in der Gesundheitsumfrage definieren",
        "goToHealthSurvey": "Gehen Sie zur Gesundheitsumfrage",
        "serialSeizures": "Serienanfälle",
        "seizuresCount": "Anfälle zählen"
      },
      "user": {
        "settings": {
          "sms": {
            "title": "SMS-Benachrichtigungen"
          },
          "other": {
            "title": "Andere"
          }
        }
      },
      "visitChat": {
        "title": "Besuchen Sie den Chat",
        "loading": "Nachrichten werden geladen...",
        "emptyList": "Die Nachrichtenliste ist leer",
        "inputLabel": "Nachricht schreiben"
      },
      "payu": {
        "banner": {
          "installmentPaymentAvailable": "Ratenzahlung ist hier möglich",
          "startsFrom": "aus",
          "decisionIn": "Entscheidung in",
          "decisionInSeconds": "{val} Sekunden",
          "checkConditions": "Konditionen prüfen"
        }
      },
      "maintenance": {
        "newAddress": "<b>Achtung!</b> Wir ändern die Adresse der Filiale in Warschau. Unsere neue Adresse lautet <b>ul. Taneczna 30, 02-829 Warschau (Ursynów)</b>"
      }
    },
    "ui": {
      "help": "Helfen",
      "policy": "Datenschutzrichtlinie",
      "logout": "Ausloggen",
      "navigation": {
        "home": "Startbildschirm",
        "account": {
          "name": "Über mich",
          "personalData": "Mein Konto",
          "medicalDocuments": "Medizinische Dokumente",
          "medicalHistory": "Gesundheitsumfrage"
        },
        "messages": "Mitteilungen",
        "therapy": "Therapie",
        "visits": {
          "name": "Besuche",
          "archive": "Archiv der Besuche",
          "doctorDatabase": "Arztdatenbank"
        },
        "myVisits": "Meine Besuche",
        "patients": "Patienten",
        "ediagnosis": "IctalLab",
        "calendar": "Kalender",
        "workplan": "Arbeitsplan",
        "visitsAndDuties": "Besuche und Pflichten",
        "finances": "Finanzen",
        "ehealth": {
          "name": "E-Gesundheit",
          "prescriptions": "E-Rezepte",
          "referrals": "E-Empfehlungen"
        },
        "settings": {
          "name": "Einstellungen",
          "notifications": "Benachrichtigungen",
          "regulations": "Vorschriften",
          "payments": "Zahlungen"
        },
        "documentation": "Dokumentation",
        "examinations": "Prüfungen"
      },
      "closeDialog": "Schließen",
      "visitSummary": "visit_summary",
      "invoice": "Rechnung",
      "correctionInvoice": "Korrektur_Rechnung",
      "ieAlert": "Leider unterstützen wir Internet Explorer nicht mehr. Bitte verwenden Sie einen anderen Internetbrowser."
    },
    "components": {
      "workPlan": {
        "header": {
          "TIMETABLE": "Planen",
          "TIMETABLE_CHANGES": "Ändert den Kalender"
        },
        "editDialog": {
          "workPosition": "Arbeitsplanposition bearbeiten",
          "unsavedWorkPosition": "Neue Arbeitsplanposition",
          "vacation": "Urlaub bearbeiten",
          "unsavedVacation": "Neuer Urlaub",
          "dayOfWeekLabel": "Wochentag",
          "officeLabel": "Büro",
          "visitTypeLabel": "Besuchstyp",
          "startTimeLabel": "Startzeit",
          "endTimeLabel": "Endzeit",
          "description": "Beschreibung",
          "cancel": "Stornieren",
          "approve": "Genehmigen",
          "visitType": "Besuchstyp",
          "visitPlace": "Besuchen Sie den Ort",
          "stationaryAndRemote": "Aufmerksamkeit! Bei dieser Besuchsart können Patienten sowohl stationäre als auch Fernbesuche vereinbaren",
          "workPlaceMissing": "Um stationäre Besuche zu ermöglichen, müssen Sie Ihren Arbeitsplatz auf der Einstellungsseite festlegen"
        },
        "deleteDialog": {
          "text": "Sind Sie sicher, dass Sie diese Position löschen möchten?",
          "cancel": "Stornieren",
          "confirm": "Löschen"
        },
        "visitType": {
          "unavailable": "Pflicht",
          "any": "Jeder Typ",
          "stationaryOrRemore": "Stationär / Remote"
        },
        "event": {
          "duty": {
            "yourDutyToChange": "Markiert als ändern",
            "state": {
              "AVAILABLE": "Zoll verfügbar",
              "NOT_AVAILABLE": "Zoll nicht verfügbar",
              "TO_CHANGE": "Antrag auf Übernahme der Pflicht",
              "PLANNED": "Ihr geplanter Dienst"
            }
          }
        },
        "description": "Planen Sie Ihre Arbeit – wählen Sie aus, wann Sie für Besuche verfügbar sind",
        "selectedWorkPlan": "Ausgewählter Arbeitsplan:",
        "newWorkPlan": "Neuer Arbeitsplan:",
        "untilFurtherNotice": "bis auf weiteres",
        "couldNotDeleteActiveWorkPlan": "Der aktive Arbeitsplan konnte nicht entfernt werden",
        "validFrom": "Gültig ab",
        "validTo": "Gültig bis",
        "resetChanges": "Zurücksetzen",
        "saveChanges": "Speichern",
        "unsavedChanges": "Es gibt nicht gespeicherte Änderungen im Kalender",
        "visits": "Besuche",
        "newPlan": "Neuer Plan",
        "messages": {
          "deleteSuccess": "Arbeitsplan wurde gelöscht",
          "addSuccess": "Arbeitsplan wurde hinzugefügt",
          "updateSuccess": "Der Arbeitsplan wurde aktualisiert"
        }
      },
      "doctor": {
        "calendar": {
          "todayBtn": "Heute"
        },
        "messages": {
          "title": "Aktuelle Nachrichten",
          "show": "Zeigen",
          "item": {
            "systemLbl": "Systemmeldung",
            "types": {
              "prescribeLbl": "Antrag auf Ausstellung eines Rezepts von: {sender}",
              "prescribeLblMovedFrom": "statt {movedFrom}",
              "selectionLbl": "Antrag auf Ernennung zum leitenden Arzt von: {sender}",
              "noteLbl": "Hinweis für den Patienten: {patient} wurde hinzugefügt"
            }
          },
          "new": "Neu"
        },
        "visits": {
          "title": "Tagesplan",
          "show": "Zeigen",
          "types": "Besuchstyp",
          "categories": "Kategorie besuchen",
          "type": {
            "remote": "Online-Besuche",
            "stationary": "Stationär"
          },
          "item": {
            "statusPayed": "bezahlt",
            "statusNotPayed": "nicht bezahlt",
            "statusDone": "Erledigt",
            "statusToMove": "bewegen",
            "visitBtnLbl": "besuchen",
            "visitStartBtnLbl": "Besuch beginnen",
            "historyBtnLbl": "Krankengeschichte",
            "numberLbl": "Anzahl der Besuche",
            "causeLbl": "Besuchen Sie Ursache",
            "lastVisitLbl": "Letzter Besuch:",
            "diagnosisLbl": "Diagnose",
            "diagnosisNoEpilepsyLbl": "Nicht epileptisch",
            "diagnosisNoDataLbl": "Keine Diagnose",
            "drugsLbl": "Aktuelle Medikamente",
            "drugsNoneLbl": "Keiner",
            "frequencyLbl": "Anfallshäufigkeit",
            "moodLbl": "Stimmung",
            "summaryLbl": "Zusammenfassung des Besuchs",
            "visitDuration": "Dauer:"
          }
        },
        "form": {
          "pricelist": {
            "titleInfo": "Neuer Preislistenartikel",
            "cancelBtn": "Stornieren",
            "saveBtn": "Bestätigen"
          },
          "avatar": {
            "titleInfo": "Bild auswählen",
            "addImageBtn": "Bild aus dem Dateisystem",
            "cancelBtn": "Stornieren",
            "saveBtn": "Bestätigen"
          },
          "address": {
            "streetLbl": "Straße",
            "homeBuildingLbl": "Gebäudenr. / Wohnung",
            "apartmentLbl": "Wohnung Nr",
            "buildingLbl": "Hausnummer",
            "cityLbl": "Stadt",
            "zipcodeLbl": "PLZ",
            "countryLbl": "Land"
          }
        }
      },
      "message": {
        "history": {
          "list": "Nachrichtenliste",
          "title": "Mitteilungen",
          "newMessage": "Neue Nachricht",
          "new": "Neu",
          "search": "Suchen",
          "visitEnded": "beendet",
          "filters": {
            "current": "Aktuell",
            "archive": "Archiv",
            "contact": "Suchen"
          },
          "noMessages": "Keine Nachrichten",
          "noTherapies": "Keine Therapien",
          "born": "geboren",
          "historyBtnLbl": "Krankengeschichte",
          "return": "Zurückkehren",
          "send": "Schicken",
          "sendAndClose": "Senden und abschließender Besuch",
          "closeNoAnswer": "Die Nachricht erfordert keine Antwort",
          "noAnswerContent": "Danke für deine Nachricht. Grüße",
          "confirmNoAnswer": "Möchten Sie den Besuch wirklich beenden, ohne zu antworten?",
          "hoursToAnswer": "H",
          "daysToAnswer": "Tage",
          "timeIsUp": "Die Zeit ist um",
          "hoursToAnswerRich": "Sie haben {hours} Stunden Zeit, um zu antworten",
          "daysToAnswerRich": "Sie haben {days} Tage Zeit, um zu antworten",
          "timeIsUpRich": "Die angegebene Zeit für eine Antwort ist bereits abgelaufen. Geben Sie es so schnell wie möglich!",
          "summary": "Zusammenfassung",
          "medicalHistory": "Krankengeschichte",
          "sort": {
            "label": "Zeigen",
            "newest": "Neueste",
            "alpha": "Alphabetisch",
            "deadline": "Dringendste"
          },
          "sendAndCloseDialog": {
            "header": "Möchten Sie den Thread wirklich beenden?",
            "title": "Wenn Sie den Besuch schließen, kann der Patient nicht auf Ihre Nachricht antworten",
            "subtitle": "Wenn Sie möchten, dass der Patient auf Ihre Nachricht antworten kann, klicken Sie auf „Senden“."
          },
          "newMessageOnDuty": "Neue Nachricht an den diensthabenden Arzt",
          "unidirectional": {
            "send": "Unidirektionale Nachricht senden",
            "sendTitle": "Unidirektionale Nachricht an",
            "sendSubtitle": "Der Patient kann auf diese Nachricht nicht antworten",
            "sendSuccess": "Unidirektionale Nachricht an den Patienten wurde gesendet",
            "infoForPatient": "Dies ist eine Nachricht - Informationen von einem Arzt. In dieser Nachricht hat Ihnen Ihr Arzt zusätzliche Informationen zu Ihrer Krankheit gegeben. Sie können nicht auf die Nachricht antworten. Wenn Sie zusätzliche Informationen wünschen, schreiben Sie eine neue Nachricht - besuchen Sie Ihren Arzt"
          },
          "addToEpicrisis": "Zur Epicrisis hinzufügen",
          "textAddedToEpicrisis": "Eintrag zu epicrisis hinzugefügt"
        },
        "respondeDeadlineAlert": {
          "youHaveTimeTo": "Sie müssen bis antworten",
          "toReplyTo": "zur Nachricht von",
          "reply": "Antwort"
        },
        "newPaidMessageAlert": {
          "content": "Sie haben für eine Nachricht an den Arzt bezahlt",
          "reply": "Nachricht schreiben"
        },
        "patientAlerts": {
          "healthSurveyContent": "Ihre Gesundheitsbefragung muss ausgefüllt werden",
          "healthSurvey": "Gehen Sie zur Umfrage"
        },
        "newMessageVisit": {
          "noDoctorTitle": "Ihnen ist kein leitender Arzt zugewiesen",
          "title": "Konsultieren Sie Ihren Arzt – senden Sie eine Nachricht",
          "descriptionLbl": "Beschreibung",
          "description": "Eine Besuchsnachricht ist eine kurze Frage, die an den Arzt gesendet wird. Ihr Arzt wird darauf antworten. Wenn Sie diese Kontaktform nutzen möchten, bezahlen Sie Ihre Besuchsnachricht, indem Sie auf die Schaltfläche „Neue Nachricht“ klicken. Wenn Sie gesundheitliche Bedenken haben oder eine längere Beratung benötigen, vereinbaren Sie bitte REGELMÄSSIG einen Termin mit Ihrem Arzt.",
          "messageVisitsDisabled": "Leider bietet Ihr Arzt diese Art von Service nicht an.",
          "noLeadDoctorAvailable": "Ihr leitender Arzt ist momentan nicht erreichbar. Sie können dem diensthabenden Arzt eine Nachricht schreiben."
        },
        "drugStock": {
          "lowMsg": "Für folgende Medikamente sind die Lagerbestände zur Neige gegangen:",
          "notInitialized": "Initialisieren Sie Ihren Arzneimittelbestand für:",
          "daysLbl": "Tage"
        },
        "toast": {
          "text": "Neue eingehende Nachricht von"
        },
        "unidirectionalMessageAlert": {
          "content": "Sie haben eine Nachricht erhalten - Informationen von einem Arzt",
          "reply": "Nachricht anzeigen"
        },
        "personalDataAcceptanceAlert": {
          "title": "Bestätigung der personenbezogenen Daten",
          "subtitle": "Ihre persönlichen Daten im Neuroterminal-System sind wichtig. Stellen Sie sicher, dass sie auf dem neuesten Stand sind! So können Sie unsere Dienste in vollem Umfang nutzen.",
          "changeButton": "Ändern Sie Ihre persönlichen Daten",
          "acceptButton": "Meine persönlichen Daten bestätigen"
        }
      },
      "utility": {
        "multipleSelect": {
          "all": "Alle"
        },
        "confirmationDialog": {
          "title": "Bist du sicher?",
          "text": "Bist du dir sicher, dass du weitermachen willst?"
        },
        "youAreNotAllowedDialog": {
          "title": "Berechtigungen erforderlich",
          "text": "Sie haben keine Berechtigung. Kontaktieren Sie uns für weitere Informationen.",
          "goBackToMainPage": "Zurück zur Hauptseite"
        },
        "phoneInput": {
          "phoneNumberLabel": "Telefonnummer",
          "legalGuardPhoneNumberLabel": "Telefonnummer des Elternteils oder gesetzlichen Vertreters"
        }
      },
      "health": {
        "state": {
          "noData": "Keine Daten",
          "stateNoChange": "Keine Änderung",
          "stateBetter": "Besser",
          "stateWorse": "Schlechter"
        }
      },
      "calendar": {
        "title": "Besuche und Pflicht",
        "description": "Ihre Besuche für die aktuelle Woche. Klicken Sie auf einen Besuch, um dessen Details anzuzeigen.",
        "visitCancelled": "Der Besuch wurde abgesagt",
        "visitDateChanged": "Das Besuchsdatum wurde geändert",
        "legend": {
          "duty": "SOS-Dienst",
          "vacation": "Urlaub"
        },
        "filters": {
          "showVisits": "Besuche anzeigen",
          "showDuties": "Pflichten anzeigen"
        },
        "visitDetailsDialog": {
          "title": "Besuchen Sie Details",
          "onDay": "An",
          "fromHour": "aus",
          "toHour": "Zu",
          "close": "Schließen"
        },
        "dutyDetailsDialog": {
          "title": "Angaben zum Dienst",
          "fromHour": "aus",
          "toHour": "Zu",
          "close": "Schließen",
          "signUpForDuty": "Melden Sie sich zum Dienst an",
          "markDutyToChange": "Antrag auf Dienstübertragung",
          "toChangeRequestText": "Fragt nach einem diensthabenden Transger. Sie können diesem Arzt helfen, indem Sie sich für diesen Dienst anmelden.",
          "cantMarkDutyAsToChange": "Eine Übertragung dieser Pflicht können Sie leider nicht beantragen.",
          "dutyNotAvailable": "Diese Pflicht ist bereits übernommen. Wählen Sie einen anderen Diensttermin.",
          "cancelToChange": "Machen Sie die Anforderung zur Zollübertragung rückgängig"
        },
        "confirmVisitCancellationDialog": {
          "title": "Absage eines Besuchs",
          "sureToCancelVisit": "Sind Sie sicher, dass Sie den Patientenbesuch absagen möchten?",
          "fromDate": ", die ab stattfinden sollte",
          "from": "und weiter bis",
          "to": "Zu",
          "description": "Der Patient wird gebeten, einen anderen Termin zu wählen, oder der Dienst wird sich mit ihm in Verbindung setzen.",
          "confirm": "Besuch absagen",
          "cancel": "Stornieren",
          "visitAlert": "Sie stornieren einen Besuch, der in weniger als 48 Stunden beginnen soll! Wir unterstützen solche Praktiken nicht. Weitere Informationen finden Sie in unserem",
          "termsAndConditions": "Geschäftsbedingungen",
          "fromDatePassed": ", die vom",
          "fromPassed": "und dauerte bis",
          "visitError": "Dieser Besuch wurde bereits durchgeführt. Stellen Sie sicher, dass Sie diesen Besuch wirklich stornieren möchten"
        },
        "visits": {
          "title": "Arztbesuche",
          "description": "Ausgewählte Arzttermine für den Tag. Klicken Sie auf ein Feld im Kalender, um dessen Details anzuzeigen.",
          "dateSelectPrefix": "Ausgewählter Tag",
          "noVisits": "Keine Besuche",
          "doctors": "Ärzte"
        }
      },
      "duties": {
        "applyDutyDialog": {
          "title": "Pflichtenausgabe",
          "subtitle": "Stellen Sie sicher, dass Ihre Änderungen korrekt sind.",
          "addedDuties": "Zusätzliche Aufgaben:",
          "cancelledDuties": "Abgesagte Aufgaben:",
          "inHours": "An",
          "back": "Zurück zum Bearbeiten",
          "approve": "Speichern",
          "acceptTermsAndConditions": "Ich akzeptiere die Allgemeinen Geschäftsbedingungen"
        },
        "alert": {
          "text": "Sie haben die Aufgabe:"
        },
        "description": "Melden Sie sich für den SOS-Dienst an. Wählen Sie ein passendes Datum aus, indem Sie im Kalender darauf klicken"
      },
      "payment": {
        "history": {
          "title": "Zahlungshistorie",
          "filters": {
            "typeAll": "Alle",
            "advFiltLbl": "Erweiterte Suche",
            "advanced": {
              "from": "Startdatum",
              "till": "bis",
              "to": "Endtermin",
              "clearLbl": "Klar",
              "searchLbl": "Suchen"
            }
          },
          "noDataLbl": "Keine Daten",
          "noResultsLbl": "Keine Suchergebnisse gefunden",
          "headers": {
            "date": "Datum",
            "type": "Typ",
            "amount": "Menge",
            "invoice": "Rechnung",
            "service": "Dienstname"
          },
          "pdfLbl": "Herunterladen",
          "invoice": "Rechnung",
          "correctionInvoice": "Korrektur"
        }
      },
      "dialog": {
        "generic": {
          "confirmation": {
            "dialog": {
              "defaultCancelLbl": "Stornieren",
              "defaultConfirmLbl": "Bestätigen"
            }
          },
          "attention": "Uwaga"
        }
      },
      "patient": {
        "visits": {
          "next": {
            "visit": {
              "info": {}
            }
          },
          "operations": {
            "cancel": {
              "title": "Bestätigen Sie die Absage des Besuchs?",
              "warning": "Gemäß den Nutzungsbedingungen unterliegen Besuche, die innerhalb von 48 Stunden vor dem Besuchsdatum storniert werden, einer Verwaltungsgebühr in Höhe von 50 % der Besuchskosten. Der Restbetrag wird zurückerstattet (Rückgabemethode wird besprochen).",
              "reason": "Grund für die Absage des Besuchs (optional)",
              "confirmLbl": "Ja",
              "cancelLbl": "NEIN",
              "warningCustom": "Gemäß den Nutzungsbedingungen wird für Besuche, die innerhalb von {days} Tagen vor dem Besuchsdatum storniert werden, eine Verwaltungsgebühr von {percentage} % der Besuchskosten erhoben. Der Restbetrag wird zurückerstattet (Rückerstattungsmethode wird besprochen)."
            },
            "modify": {
              "title": "Wählen Sie ein neues Besuchsdatum",
              "confirmMsg": "Neuer Besuchstermin",
              "modifyDisabled": "Dieser Besuch kann nicht verschoben werden",
              "dateBefore": "Vorheriges Datum",
              "dateCurrent": "Aktuelles Datum",
              "change": "Datum ändern",
              "cancelLbl": "Stornieren",
              "yesLbl": "Bestätigen Sie die Änderungen",
              "noLbl": "Stornieren",
              "changeWarning": "Achtung! Das System erlaubt einem Patienten nur einmal, das Datum / den Arzt für einen Termin zu ändern"
            }
          }
        }
      },
      "surveyZeroNote": {
        "addButton": "Hinweis 0",
        "title": "Umfragebeobachtungen 0",
        "addDialog": {
          "visitPurpose": "Zweck des Besuchs 1",
          "visitPurposeDescription": "Fragen Sie den Patienten, welchen Zweck der Termin 1 hat. Idealerweise sollte die Aussage des Patienten eingetragen werden.",
          "surveyCompleted": "Ist Umfrage 0 abgeschlossen?",
          "surveyCompletedDescription": "Wenn Umfrage 0 nicht abgeschlossen wurde oder einige Teile der Umfrage abgeschlossen wurden, geben Sie bitte einen Grund an.",
          "seizures": "Beschreiben Sie die Anfälle in Worten",
          "seizuresEmpty": "Keine Anfallsarten definiert",
          "chronicDiseases": "Hat der Patient andere chronische Erkrankungen – führend? (z. B. Autismus, Schlaganfall)",
          "chronicDiseasesDescription": "Chronische Krankheit",
          "epilepsy": "Hat der Patient in der Familienanamnese Epilepsie oder andere neurologische Erkrankungen?",
          "epilepsyDescription": "Beschreiben Sie kurz, bei wessen Familienanamnese Epilepsie oder andere neurologische Erkrankungen aufgetreten sind",
          "eegCompleted": "Hatte der Patient einen EEG-Test?",
          "mriCompleted": "Hatte der Patient eine MRT-Untersuchung?",
          "ctCompleted": "Hatte der Patient eine CT-Untersuchung?",
          "geneticResearch": "Wurde bei dem Patienten ein Gentest durchgeführt?",
          "relevantExaminations": "Hat der Patient andere relevante Studien durchgeführt?",
          "otherMedicines": "Nimmt der Patient derzeit andere Medikamente als die im Fragebogen 0 aufgeführten AEDs ein?",
          "otherMedicinesDescription": "Beschreiben Sie in Worten, welche Medikamente/Substanzen der Patient einnimmt",
          "allergies": "Hat der Patient eine Arzneimittelallergie, einschließlich Antibiotika?",
          "allergiesDescription": "Beschreiben Sie in Worten, gegen welche Medikamente/Substanzen der Patient allergisch ist",
          "notes": "Zusätzliche Bemerkungen",
          "examinationDescription": "Beschreiben Sie ggf. Testergebnisse",
          "additionalDescription": "Wortbeschreibung",
          "treatment": "Bisherige Behandlung",
          "treatmentDescription": "Verbale Beschreibung",
          "specialDiet": "Gibt es eine spezielle Diät?",
          "specialDietDescription": "Verbale Beschreibung",
          "perinatalInterviewUnimportant": "Wichtige Informationen",
          "birthDate": "Datum der Entbindung (Geburt)",
          "birthType": "Art der Geburt",
          "pregnancyComplications": "Komplikationen während der Schwangerschaft",
          "birthWeight": "Gewicht bei der Geburt",
          "birthLength": "Länge bei der Geburt",
          "apgar": "Apgar-Punkte (0-10)",
          "apgarFirst": "1 Minute",
          "apgarNext": "Nächste Messung",
          "birthAdditionalProcedures": "Wurden perinatal zusätzliche Eingriffe durchgeführt?",
          "birthPrematureAgeCorrected": "Wurde das Alter für Frühgeborene korrigiert?",
          "hasSiblings": "Hat der Patient Geschwister?",
          "sibling": "Person",
          "siblingBirthDate": "Geburtsdatum",
          "siblingSameParents": "Sind es dieselben Eltern?",
          "siblingHealthy": "Ist er gesund?",
          "addSibling": "Weitere Person hinzufügen",
          "geneticPredisposition": "Gibt es eine umweltbedingte und genetische/erbliche Veranlagung und Familienanamnese?",
          "developmentCorrect": "Ist die aktuelle Entwicklung richtig?",
          "retardationDetermined": "Wurde ermittelt, in welchem Ausmaß die Person im Verhältnis zu ihrem Alter zurückgeblieben ist?",
          "retardationExamined": "Gab es diesbezüglich eine fachärztliche Untersuchung?",
          "nystagmus": "Leidet der Patient unter Nystagmus?",
          "vision": "Sicht",
          "hearing": "Anhörung",
          "tuxedo": "Zigaretten rauchen",
          "alcohol": "Alkohol",
          "narcotics": "Medikamente",
          "leadingDisease": "Welche Erkrankung ist beim Patienten am weitesten verbreitet?",
          "leadingDiseaseDominantSymptoms": "Dominante Symptome der Krankheit",
          "leadingDiseaseLasting": "Wie lange besteht die Krankheit schon?",
          "accompanyingSymptoms": "Begleitsymptome: Möchte der Patient noch etwas ergänzen?",
          "next": "Nächste",
          "previous": "Zurück",
          "saveAndClose": "Speichern und schließen",
          "sections": {
            "surveyZero": "Umfrage 0",
            "perinatalInterview": "Perinatales Interview",
            "development": "Entwicklung",
            "familyInterview": "Familieninterview",
            "treatment": "Behandlung",
            "comorbidities": "Komorbiditäten",
            "medicalExams": "Medizinische Untersuchungen",
            "others": "Andere"
          }
        },
        "preview": {
          "userCreated": "Erstellt von",
          "seizures": "Beschreibung der eingegebenen Anfälle",
          "chronicDiseases": "Hat der Patient weitere schwerwiegende Krankheiten?",
          "epilepsy": "Familieninterview",
          "eegCompleted": "EEG-Test",
          "mriCompleted": "MRT-Untersuchung",
          "ctCompleted": "CT-Untersuchung",
          "geneticResearch": "Gentest",
          "relevantExaminations": "Andere Forschung",
          "otherMedicines": "Medikamente außer LPP-Medikamenten",
          "allergies": "Arzneimittelallergien",
          "addToObservations": "Zu Beobachtungen hinzufügen",
          "addFragmentToObservations": "Ausgewähltes Fragment hinzufügen Beobachtungen durchführen",
          "addWholeToObservations": "Ganze Notiz zu Beobachtungen hinzufügen"
        }
      },
      "medicalHistoryPrintout": {
        "download": "Krankengeschichte herunterladen",
        "withObservations": "Version mit Beobachtungen",
        "withoutObservations": "Version ohne Bemerkungen",
        "downloadHistory": "Downloadverlauf anzeigen",
        "history": {
          "title": "Verlauf der Downloads der Krankengeschichte des Patienten",
          "timeDownload": "Datum herunterladen",
          "userDownload": "Downloader",
          "observations": "Enthält Beobachtungen"
        },
        "parametersPopup": {
          "title": "Krankengeschichte herunterladen",
          "subtitle": "Wählen Sie den Zeitraum aus, aus dem Sie die Krankengeschichte herunterladen möchten",
          "allHistory": "der gesamte Verlauf des Neuroterminalsystems",
          "selectedRange": "Geschichte aus einer bestimmten Zeit",
          "download": "Herunterladen",
          "cancel": "Stornieren"
        }
      },
      "patientExaminations": {
        "chooseExaminationLbl": "Wählen Sie die Prüfung aus, für die Sie die Beschreibung vervollständigen möchten",
        "eeg": "EEG-Untersuchung",
        "addEegDescription": "Beschreibung der EEG-Untersuchung hinzufügen",
        "eegDescriptionHeader": "Beschreibung der EEG-Untersuchung",
        "describingDoctor": "Beschreibung Ersteller",
        "months": "Monate",
        "removeEegTitle": "Beschreibung der EEG-Untersuchung entfernen",
        "removeEegContent": "Möchten Sie diese Beschreibung wirklich entfernen?"
      }
    },
    "common": {
      "sex": {
        "man": "Männer",
        "woman": "Frau",
        "nondefined": "Nicht definiert"
      },
      "role": {
        "patientLbl": "geduldig",
        "doctorLbl": "Arzt"
      },
      "years": " | Jahr | {n} Jahre | {n} Jahre",
      "visit": {
        "paid": "bezahlt",
        "notPaid": "nicht bezahlt",
        "additional": "zusätzlich",
        "toMove": "bewegen",
        "confirmed": "bestätigt",
        "done": "Erledigt",
        "cancelled": "abgesagt",
        "messagePaid": "im Gange"
      },
      "bornAbbr": "geboren",
      "months": "| 1 Monat | {n} Monate | {n} Monate",
      "days": "| 1 Tag | {n} Tage | {n} Tage",
      "confirm": "Bestätigen",
      "cancel": "Stornieren",
      "select": "Wählen"
    },
    "views": {
      "profile": {
        "welcome": "WILLKOMMEN !",
        "choose": "Anmelden als:",
        "doctor": "Arzt",
        "patient": "geduldig"
      },
      "login": {
        "loginFldLbl": "Email",
        "passwordFldLbl": "Passwort",
        "loginBtnLbl": "Anmeldung",
        "loggingAs": "Sie sind angemeldet als",
        "wantToLoginAs": "Ich möchte mich anmelden als",
        "registerMsg": "Sie haben noch kein Konto?",
        "registerLinkMsg": "Account registrieren",
        "resetMsg": "Erinnern Sie sich nicht an Ihr Passwort?",
        "resetLinkMsg": "Passwort zurücksetzen",
        "return": "zurückkehren"
      },
      "register": {
        "patientLbl": "Geduldig",
        "doctorLbl": "Arzt",
        "loginFldLbl": "Anmeldung",
        "newPassFldLbl": "Passwort",
        "newPassConfFldLbl": "Bestätige das Passwort",
        "firstNameFldLbl": "Vorname",
        "secNameFldLbl": "Zweitname",
        "lastNameFldLbl": "Familienname, Nachname",
        "emailFldLbl": "Email",
        "workCertNrFldLbl": "Zertifikatsnr",
        "confirmBtnLbl": "Bestätigen",
        "backToLoginPageLbl": "Zurück zur Anmeldeseite"
      },
      "reset": {
        "loginFldLbl": "Nutzername",
        "newPasswordLbl": "Neues Kennwort",
        "newPasswordConfirmLbl": "Bestätige neues Passwort",
        "confirmBtnLbl": "Bestätigen",
        "backToLoginPageLbl": "Zurück zur Anmeldeseite"
      },
      "dashboard": {
        "welcomeMessage": "Angemeldeter Benutzer: {userName}",
        "enableEditable": "Ansicht bestätigen",
        "disableEditable": "Ansicht verwalten"
      },
      "visits": {
        "titleLbl": "Meine Besuche",
        "noDataLbl": "Keine Besuche",
        "noResultsLbl": "Keine Besuche gefunden",
        "filters": {
          "visitTime": {
            "all": "Alle",
            "future": "Zukunft",
            "past": "Vergangenheit"
          },
          "searchPatientLbl": "Suche nach Patient",
          "advancedFiltersLbl": "Erweiterte Suche",
          "advancedFilters": {
            "from": "Stammen aus",
            "betweenDatesLbl": "Tun",
            "till": "Datum bis",
            "type": "Besuchstyp",
            "category": "Kategorie besuchen",
            "all": "Alle",
            "clearBtn": "klar",
            "searchBtn": "suchen"
          }
        }
      },
      "account": {
        "common": {
          "requiredMsg": "Erforderliche Felder sind mit einem Sternchen gekennzeichnet",
          "clearBtn": "Klar",
          "editBtn": "Bearbeiten",
          "saveBtn": "Bestätigen",
          "yes": "Ja",
          "no": "NEIN",
          "woman": "Frau",
          "man": "Mann",
          "nondef": "nicht definiert"
        },
        "home": {
          "myInfoLbl": "Meine Information",
          "generalInfoLbl": "Allgemeine Information",
          "accountInfoLbl": "Praxisinfos",
          "pricelistInfoLbl": "Finanzdaten",
          "incompleteFormWarningMsg1": "Es wurden nicht alle Felder ausgefüllt",
          "incompleteFormWarningMsg2": "Möchten Sie ein unvollständiges Formular wirklich speichern?"
        },
        "general": {
          "basicInfoLbl": "Basisinformation",
          "avatarLbl": "Benutzerbild",
          "nameLbl": "Name",
          "lastNameLbl": "Familienname, Nachname",
          "sexLbl": "Sex",
          "emailLbl": "Email",
          "phoneLbl": "Telefonnummer",
          "pwzLbl": "PWZ-Nummer",
          "dataSavedMsg": "Allgemeine Informationen gespeichert"
        },
        "practise": {
          "basicInfoLbl": "Berechtigungen, erbrachte Leistungen, wissenschaftliche Tätigkeit",
          "type": "Ebene",
          "consultationType": "Beratungstyp",
          "academicTitle": "Akademischer Titel",
          "specialization": "Spezialisierung",
          "workPlace": "Arbeitsplatz",
          "epileptologyCert": "Zertifizierung zur Epileptologie",
          "nfzContract": "NFZ-Vertrag",
          "testResDescriptions": "Beschreibungen der Testergebnisse",
          "consultations": "Beratungen",
          "scientificWork": "Wissenschaftliche Arbeit",
          "scientificSocieties": "Wissenschaftliche Gesellschaften",
          "www": "WWW-Seite",
          "bio": "Bio",
          "certificate": "ZUS-Zertifikat",
          "chooseFile": "Datei wählen",
          "patientsInfoLbl": "Patienten",
          "newPatients": {
            "label": "Neue Patienten",
            "accept": "Gibt zu",
            "noaccept": "Gibt nicht zu"
          },
          "acceptedPatients": "Akzeptierte Patienten",
          "leadPractiseInfoLbl": "Informationen zur Führungspraxis",
          "workplaceName": "Name des Arbeitsplatzes",
          "workplacePosition": "Position",
          "communicators": "Kommunikatoren",
          "usernameMessanger": "Messenger-Benutzername",
          "usernameWhatsup": "WhatsApp-Benutzername",
          "usernameSkype": "Skype-Benutzername",
          "usernameHangouts": "Hangouts-Benutzername",
          "dataSavedMsg": "Übungsinformationen gespeichert",
          "neuroscreenDiagnoses": "Geförderte Krankheiten",
          "partner": "Partner NSPH"
        },
        "pricelist": {
          "services": "Dienstleistungen",
          "visitsLbl": "Besuche",
          "othersLbl": "Andere",
          "systemItemsLbl": "Systemelemente",
          "systemItemsDescription": "Diese Dienste werden weltweit von Neuroterminal bereitgestellt",
          "descriptionsLbl": "Beschreibungen der Testergebnisse",
          "table": {
            "statusLbl": "Status",
            "activeLbl": "Aktiv",
            "inactiveLbl": "Inaktiv",
            "nameLbl": "Dienstname",
            "durationLbl": "Dauer",
            "priceLbl": "Preis",
            "paymentLbl": "Deine Bezahlung",
            "perHourLbl": "pro Stunde",
            "dutyLbl": "Pflicht"
          },
          "bankAccount": "Bankkonto",
          "widgetVisibility": "Sichtbar im Termin-Widget",
          "practiceName": "Name der Privatpraxis",
          "nip": "Nip",
          "regon": "Regon",
          "dataSavedMsg": "Preislisteninformationen gespeichert",
          "visitsDescription": "Legen Sie Preise für bestimmte Besuchsarten fest.",
          "othersDescription": "Legen Sie Tarife für andere Dienste fest, die nicht nach Patiententypen gruppiert sind, z. B. Nachrichten oder medizinische Dokumente",
          "choosePatientTypes": "Sie müssen mindestens einen Patiententyp auswählen, um die Preisliste für Besuche zu definieren"
        }
      },
      "patientDashboard": {
        "yourLeadDoctor": "Ihr leitender Arzt",
        "addEvent": "Ereignis hinzufügen",
        "showCalendar": "Kalender anzeigen",
        "filesAdded": "Dateien hinzugefügt",
        "addFiles": "Dateien hinzufügen",
        "showAllFiles": "Alle Dateien anzeigen",
        "surveyZeroDesc": "Die Befragung wird vom Arzt während des Besuchs durchgeführt. Befragung 0",
        "showSurveyZero": "Zeigen Sie die Umfrage an",
        "aboutMe": "Über mich",
        "myData": "meine Daten",
        "myDataEdit": "Bearbeiten",
        "myDataFill": "Füllen",
        "myDataShow": "Zeigen",
        "medicalDocuments": "medizinische Dokumentation",
        "healthQuestionnaire": "Gesundheitsfragebogen",
        "visitsLbl": "Besuche",
        "eventCalendar": "Veranstaltungskalender",
        "addEventLbl": "Hinzufügen",
        "confirmDeletionTitle": "Ereignislöschung",
        "confirmDeletionText": "Möchten Sie dieses Ereignis löschen?",
        "seizure": "Beschlagnahme",
        "infection": "Infektion",
        "fever": "Fieber",
        "insomnia": "Schlaflosigkeit",
        "menstruation": "Menstruation",
        "pregnancyStart": "Beginn der Schwangerschaft oder Vorschwangerschaft",
        "pregnancyEnd": "Ende der Schwangerschaft oder Vorschwangerschaft",
        "chooseLeadDoctor": "Wählen Sie den leitenden Arzt",
        "noLeadDoctor": "Nach Besuch Null wählen Sie den leitenden Arzt",
        "notAvailableNow": "Nicht verfügbar",
        "doctorCare": "Medizinische Versorgung",
        "alert": {
          "yourVisitWithDoctor": "Ihr Besuch beim Arzt",
          "startsAt": "startet um",
          "lastsFrom": "dauert von",
          "join": "Verbinden",
          "visitToMove": {
            "first": "Ihr Besuch bei",
            "second": "wurde abgesagt",
            "makeNewAppointment": "Wählen Sie ein neues Datum"
          },
          "myData": {
            "label": "Um das Patientenkonto zu nutzen, füllen Sie das Formular mit den persönlichen Daten aus.",
            "action": "Gehen Sie zum Formular"
          },
          "prescriptionsToRead": {
            "label": "Der Arzt stellte ein Rezept aus. Um den PIN-Code anzuzeigen, gehen Sie zur Registerkarte „Therapie“.",
            "action": "Rezepte anzeigen"
          },
          "selectLeadDoctor": {
            "label": "Wählen Sie Ihren leitenden Arzt.",
            "action": "Arzt auswählen"
          },
          "doctorRejectedRequest": "{doctorName} hat nicht zugestimmt, Ihr leitender Arzt zu werden. Grund: {reason}"
        },
        "activation": {
          "welcomeLbl": "Willkommen bei Neuroterminal",
          "init1Lbl": "Schön, dass du bei uns bist.",
          "init2Lbl": "Geben Sie vor dem ersten Besuch Informationen zu Ihrem Gesundheitszustand ein.",
          "init3Lbl": "meine Daten",
          "init4Lbl": "medizinische Dokumentation",
          "init5Lbl": "Dann buchen Sie Besuch Null.",
          "nextLbl": "Nächste"
        },
        "therapy": "aktuelle Therapie",
        "noCurrentTherapies": "Keine aktuelle Therapie",
        "drugStock": "Arzneimittelbestand",
        "drugStockDescription": "Klicken Sie auf „Aktualisieren“, um Ihren aktuellen Arzneimittelbestand bereitzustellen.",
        "supplyFor": "Vorrat für {days} Tage",
        "amountUndefined": "Betrag ist nicht definiert",
        "supplyEndsIn": "endet in {days} Tagen",
        "medicineLbl": "Medizin",
        "runningOutStocks": "Ihr Vorrat geht zur Neige. Bitten Sie Ihren leitenden Arzt um die Ausstellung eines Rezepts.",
        "update": "Aktualisieren",
        "save": "Speichern",
        "askForPrescription": "Fordern Sie ein Rezept an",
        "updateDrugStockLbl": "Aktualisierung des Arzneimittelbestands",
        "updateDrugStockLblDesc": "Zählen Sie Ihren aktuellen Arzneimittelbestand und geben Sie einen Wert in das Feld unten ein.",
        "drugStockUpdateHelpLbl": "Geben Sie ab heute die Arzneimittelbestände ein. Es wird jeden Morgen automatisch mit den Dosierungen Ihrer aktuellen Arzneimitteltherapie aktualisiert.",
        "drugStockUpdated": "Arzneimittelbestände für {products} erfolgreich aktualisiert",
        "drugStockRecalculationFailed": "Die automatische Aktualisierung des Arzneimittelbestands ist fehlgeschlagen. Sie können Ihren Arzneimittelbestand manuell aktualisieren.",
        "referrals": {
          "title": "Empfehlung vom Arzt",
          "appoint": "Ernennen"
        },
        "nonepileptic": {
          "infoMsg": "Bei allen Konsultationen handelt es sich um einmalige Besuche, die von den besten Spezialisten durchgeführt werden. Detaillierte Servicebedingungen mit der Liste der verfügbaren Ärzte finden Sie im Rahmen des Besuchsbuchungsprozesses.",
          "consultationsLbl": "Beratungen",
          "specialistsLbl": "Spezialisten",
          "epileptLbl": "Epileptologe",
          "neurosurLbl": "Neurochirurg",
          "neurologLbl": "Neurologe",
          "psychLbl": "Psychiater"
        },
        "dataNotFilled": {
          "title": "Willkommen in der Neuroterminal-Anwendung!",
          "footer": "Wählen Sie aus, wer der Neurosphera-Patient sein soll",
          "patient": "Ich bin Patient",
          "patientDescription": "Für Patienten über 18",
          "guardian": "Der Patient ist ein Kind",
          "guardianDescription": "Für Patienten, die unter 18 Jahre alt sind oder einen Erziehungsberechtigten haben"
        },
        "carePlan": {
          "title": "Pflegeplan",
          "date": "Datum",
          "incomingVisitsAndReferrals": "Eingehende Besuche und Empfehlungen",
          "incomingVisitsAndReferralsDesc": "Hier erscheinen Ihre eingehenden Termine und Arztüberweisungen",
          "bookFirstVisit": "Buchen Sie den ersten Besuch",
          "archiveVisits": "Archivbesuche",
          "archiveVisitsDesc": "Hier sehen Sie bereits stattgefundene Besuche",
          "scheduledDate": "vorgeschlagener Termin",
          "getSummaryPdf": "Erhalten Sie Empfehlungen von Besuch",
          "cancelReferral": "Empfehlung entfernen",
          "changeDate": "Datum ändern",
          "cancelVisit": "Besuch absagen",
          "reassignVisit": "Arzt wechseln",
          "payForVisit": "für den Besuch bezahlen",
          "startingAt": "Beginnt um",
          "goToVisit": "Geben Sie den Besuch ein",
          "showMoreArchiveVisits": "Alle Archivbesuche anzeigen",
          "cancelReferralConfirmation": "Möchten Sie diese Empfehlung wirklich entfernen?",
          "cancelReferralSuccess": "Der Verweis wurde entfernt",
          "chooseDate": "Datum auswählen",
          "chooseDateFreeReferral": "Wählen Sie einen Termin für einen kostenlosen Besuch",
          "change": "Pflegeplan ändern",
          "suggestedDate": "Terminvorschlag",
          "referralRemoved": "Empfehlung entfernt",
          "downloadSummary": "Zusammenfassung herunterladen",
          "description": "Beschreibung",
          "filters": {
            "canceled": "Show abgesagt",
            "visits": "Besuche anzeigen",
            "messages": "Nachrichten anzeigen",
            "referrals": "Empfehlungen anzeigen"
          }
        },
        "neuroscreen": "Neuroscreen",
        "doctorOnDuty": "Arzt im Dienst"
      },
      "patientAccount": {
        "info": {
          "general": "Patientendaten",
          "generalDesc": "Wir benötigen einige Ihrer Angaben. Hierbei handelt es sich um eine formelle Anforderung. Denken Sie daran, sie sind rechtlich und technisch geschützt!",
          "generalDescLegal": "Wir benötigen auch die Daten unserer Patienten. Hierbei handelt es sich um eine formelle Voraussetzung, aber auch um die Fähigkeit, ein Rezept, Atteste oder eine Überweisung korrekt auszustellen.",
          "infoLbl": "Persönliche Daten",
          "avatarLbl": "Benutzerbild",
          "nameLbl": "Name",
          "lastNameLbl": "Familienname, Nachname",
          "sexLbl": "Sex",
          "emailLbl": "Email",
          "phoneLbl": "Telefon-Nr",
          "peselLbl": "Pesel",
          "nameLblRich": "<b>Name</b> des Patienten",
          "lastNameLblRich": "<b>Nachname</b> des Patienten",
          "peselLblRich": "<b>Pesel</b> des Patienten",
          "otherDocumentLbl": "Anderes Dokument",
          "documentTypeLbl": "Art des Dokuments",
          "documentNumberLbl": "Dokumentnummer",
          "birthDate": "Geburtsdatum",
          "repLbl": "Offizielle repräsentative Daten",
          "parentLbl": "Elterndaten",
          "repLblDesc": "Wir benötigen noch einige weitere Informationen über Sie, liebe Eltern, um Sie im Einklang mit den gesetzlichen Anforderungen betreuen zu können. Denken Sie daran, dass Ihre Daten rechtlich und technisch geschützt sind!",
          "repLblShort": "Gesetzlicher Vertreter",
          "repNameLbl": "<b>Name</b> des Elternteils oder gesetzlichen Vertreters",
          "repLastNameLbl": "<b>Nachname</b> des Elternteils oder gesetzlichen Vertreters",
          "repPhoneLbl": "<b>Telefon</b> des Elternteils oder gesetzlichen Vertreters",
          "repPeselLbl": "<b>PESEL</b> des Elternteils oder gesetzlichen Vertreters",
          "teleAddrLbl": "Patientenadresse",
          "teleAddrLblDesc": "Für die Ausstellung eines Rezepts, einer Bescheinigung oder einer Überweisung durch den Arzt sind korrekte Kontaktdaten unerlässlich.",
          "bioMedLbl": "Biomedizinische Daten",
          "bioMedLblDesc": "Die folgenden Angaben sind nicht verpflichtend. Sie können sie später wieder auffüllen. Denken Sie daran, dass die Bereitstellung biometrischer Daten dem Arzt hilft, die richtige Medikamentendosis auszuwählen.",
          "bloodTypeLbl": "Blutgruppe",
          "rhTypeLbl": "RH",
          "heightLbl": "Höhe",
          "weightLbl": "Gewicht",
          "iceLbl": "ICE (Im Notfall – in unmittelbaren Situationen)",
          "iceShortLbl": "EIS",
          "others": "Andere",
          "othersDesc": "Die folgenden Daten sind nicht zwingend erforderlich, wenn Sie uns Informationen darüber hinterlassen möchten, wen wir im Notfall benachrichtigen sollen.",
          "icePers1Lbl": "Anzurufende Person 1",
          "icePers1PhoneLbl": "Person 1, Telefonnummer 1",
          "icePers2Lbl": "Anzurufende Person 2",
          "icePers2PhoneLbl": "Person 2 Telefonnr",
          "addIceContact": "Fügen Sie eine weitere Person hinzu",
          "nsphSourceQuestion": "Wie haben Sie von Neurosphere erfahren?",
          "nsphSourceDoctorDescription": "Bitte geben Sie den Namen eines Arztes an",
          "clearBtn": "Klar",
          "saveBtn": "Bestätigen",
          "dataSavedMsg": "Daten gespeichert",
          "entitledStatementMsg": "Hiermit erkläre ich, dass ich über alle gesetzlichen Rechte verfüge",
          "legalGuardianMsg": "Hiermit erkläre ich, dass ich ein gesetzlicher Vertreter bin, der berechtigt ist, den Patienten zu vertreten als:",
          "invalidPeselWarnMsg": "Sofern PESEL falsch ist, werden Geschlecht und Geburtsdatum nicht gespeichert",
          "fromNsph": "Wurde der Patient bei CTP Neurosphera behandelt?",
          "hasEpilepsy": "Hat der Patient Epilepsie?",
          "incompleteFormWarningMsg1": "Es wurden nicht alle Felder ausgefüllt",
          "incompleteFormWarningMsg2": "Wenn Sie nicht alle erforderlichen Felder ausfüllen, wird Ihr Konto nicht aktiviert und Sie können keinen Besuch 1 planen. Dies ist eine gesetzliche Anforderung, zu deren Erfüllung wir als Gesundheitseinrichtung verpflichtet sind. Korrekte Daten ermöglichen unseren Ärzten die Erbringung medizinischer Leistungen, darunter beispielsweise die Ausstellung von Rezepten.",
          "incompleteFormConfirmBtn": "Vervollständigen Sie die Daten",
          "yesLbl": "Ja",
          "noLbl": "NEIN",
          "contactEmailLbl": "Zusätzliche E-Mail-Adresse zum Empfang von Systemnachrichten",
          "contactEmailDesc": "Für den Empfang der Klinik steht Ihnen die angegebene Adresse zur Verfügung. Wenn Sie auch Benachrichtigungen vom System an die angegebene Adresse erhalten möchten, markieren Sie die entsprechenden Punkte:",
          "contactVisitNotification": "Besuche - Zahlung, Bestätigung",
          "contactEmailPlaceholder": "E-Mail-Adresse",
          "contactDescriptionPlaceholder": "Adressenbeschreibung",
          "contactReferralNotification": "Überweisungen – Pflegeplan",
          "addAdditionalContactEmail": "Nächste E-Mail-Adresse hinzufügen",
          "documentCountry": "Land",
          "invoiceBuyer": {
            "title": "Rechnungsdaten",
            "checkboxLbl": "Ich möchte andere Daten für die Rechnung festlegen",
            "firm": "Firma",
            "person": "Privatperson",
            "firmLbl": "Firmenname",
            "nipLbl": "Nip",
            "firstNameLbl": "Vorname",
            "lastNameLbl": "Familienname, Nachname",
            "streetLbl": "Straße",
            "buildingLbl": "Gebäude",
            "zipcodeLbl": "Postleitzahl",
            "cityLbl": "Stadt",
            "comment": "Kommentar"
          },
          "address": {
            "streetLbl": "<b>Straße</b> des Patienten",
            "cityLbl": "<b>Stadt</b> des Patienten",
            "countryLbl": "<b>Land</b> des Patienten"
          },
          "leaveDialog": {
            "title": "Sie haben die Dateneingabe noch nicht abgeschlossen. Möchten Sie sich wirklich abmelden?",
            "subtitle": "Sie müssen diese beim nächsten Login erneut eingeben",
            "footer": "Wenn Sie weitere Fragen haben oder Probleme beim Ausfüllen der Bewerbung haben, kontaktieren Sie uns:"
          },
          "partner": "Partner NSPH"
        },
        "bioMed": {
          "saveSuccess": "Zmiany zostały zapisane",
          "fullInfo": "Alle Daten anzeigen"
        },
        "bioMedEditDialog": {
          "title": "Bearbeiten biomedizinischer Patientendaten",
          "subtitle": "Bei einer Wertänderung werden die Daten automatisch gespeichert"
        }
      },
      "awaitingPaymentNotAvailable": {
        "title": "Besuchsbuchung fehlgeschlagen!",
        "subtitle": "Entschuldigen Sie die Unannehmlichkeiten",
        "ageDescription": "Ihr gewählter Arzt nimmt keine Patienten in diesem Alter auf",
        "changeDescription": "Wählen Sie einen anderen Arzt und dann einen Termin",
        "action": "Wählen Sie einen Arzt und einen Termin aus"
      },
      "visitReservation": {
        "title": "Besuchen Sie die Reservierung",
        "consultationTitle": "Beratungsreservierung",
        "specializationChoice": "Wahl der Spezialisierung",
        "visitTypeChoice": "Besuchstyp",
        "doctorChoice": "Wahl des Arztes",
        "slotChoice": "Slot-Wahl",
        "summaryAndPayment": "Zusammenfassung und Zahlung",
        "noServicesAvailable": "Sie können neue Epilepsiebesuche vereinbaren, nachdem Sie bereits einen Termin vereinbart haben",
        "goToVisitsList": "Gehen Sie zur Besuchsliste",
        "appointConsultation": "Vereinbaren Sie einen Beratungstermin / EEG",
        "leadDoctor": "leitender Arzt",
        "next": "Nächste",
        "choose": "Wählen",
        "expand": "Expandieren",
        "approve": "Bestätigen",
        "cancel": "Stornieren",
        "fold": "Falten",
        "back": "Zurück",
        "doctor": "Arzt",
        "service": "Service",
        "price": "Preis",
        "payment": "Zahlungsformular",
        "date": "Datum",
        "pay": "Zahlen",
        "cancelAndChangeDate": "Datum stornieren / ändern",
        "calculateInstallments": "In Raten zahlen",
        "noSlotsAvailable": "Keine Slots verfügbar",
        "doctors": "Ärzte",
        "availableSlots": "Verfügbare Plätze",
        "selectPreviousSlots": "Verfügbare Plätze werden nach vorheriger Terminauswahl bekannt gegeben",
        "summary": "Zusammenfassung",
        "serviceDescription": "Beschreibung",
        "priceFrom": "Preis von",
        "visitDuration": "Dauer des Besuchs",
        "durationSelectDesc1": "Die Prüfung kann dauern von",
        "durationSelectDesc2": "Zu",
        "durationSelectDesc3": "Std.",
        "durationSelectDesc4": "Um mit einem Termin fortzufahren, wählen Sie bitte die Anzahl der Stunden aus",
        "durationTime": "Zeitpunkt der Prüfung",
        "eegDescription": "Im Neuroterminal-System melden Sie sich für einen 30-minütigen Standard-EEG-Test an. Der Test wird im Neurosphera Epilepsie-Therapiezentrum durchgeführt. Wenn Sie sich für einen nicht standardmäßigen EEG-Test oder eine EEG-Modifikation (VEEG, DEEG, PSG oder andere) anmelden möchten, vereinbaren Sie telefonisch einen Termin mit dem Sekretariat des Neurosphera Epilepsie-Therapiezentrums unter +48 609 463 191",
        "allDoctors": "Alle Ärzte",
        "allWorkplaces": "alle Orte",
        "allTechniciansIntro": "Die Untersuchung wird von einem unserer erfahrenen Techniker durchgeführt",
        "paymentLinkSent": "Der Zahlungslink wurde an den Patienten gesendet",
        "markedAsPaid": "Der Besuch wurde vereinbart und als bezahlt markiert",
        "openPaymentLink": "Zahlungslink öffnen",
        "linkCopied": "Der Link wurde in die Zwischenablage kopiert",
        "markAsPaid": "Als bezahlt markieren",
        "stage": "Bühne",
        "sum": "Summe",
        "freeAppointmentPrice": "Frei",
        "appointmentSuccess": "Wizyta została umówiona",
        "sameDateAsPrevious": "Der Besuch sollte am selben Tag wie der vorherige Pauschalbesuch erfolgen",
        "serviceTypeSelection": {
          "label": "Besuchstyp",
          "epilepsy": "Besuch bei Epilepsie",
          "consultation": "Beratung / EEG"
        },
        "sorting": {
          "label": "Sortieren",
          "alpha": "Alphabetisch",
          "teleconferencePriceAsc": "Telefonkonferenzpreis: aufsteigend",
          "teleconferencePriceDesc": "Preis für Telefonkonferenz: absteigend",
          "stationaryPriceAsc": "Stationärer Besuchspreis: aufsteigend",
          "stationaryPriceDesc": "Stationärer Besuchspreis: absteigend",
          "bestMatch": "Beste Übereinstimmung"
        },
        "serviceGroupDescriptions": {
          "eeg_consultation": {
            "groupSelectionTitle": "Verfügbare EEG/VEEG-Untersuchungen",
            "title": "Wer führt den EEG-Test durch?",
            "description": "Der EEG-Test wird auf Wunsch eines Epileptologen, Neurologen oder Psychiaters von einem auf diesem Gebiet spezialisierten Techniker durchgeführt. Verantwortlich für die Planung und Durchführung von Forschungen zu allen Modalitäten bei Erwachsenen und Kindern. Die Forschung erfolgt im Wach-Einschlafen-Schlaf. Sie können auch beim Patienten zu Hause durchgeführt werden. Der Test dauert etwa 30 Minuten.",
            "bulletsTitle": "Wann ist ein EEG-Test notwendig?",
            "bullet1": "Der EEG-Test ist der Basistest zur Diagnose von Epilepsie",
            "bullet2": "Das EEG wird häufig verwendet, um die Ursachen von Schlafstörungen aufzuzeigen",
            "bullet3": "Kann bei Kopfverletzungen beauftragt werden",
            "bullet4": "Weitere Anzeichen sind Sehstörungen, Stottern, wiederkehrende Kopfschmerzen, Ohnmacht oder Bewusstlosigkeit"
          }
        },
        "serviceDescriptions": {
          "vns": {
            "title": "Was ist ein VNS-Besuch?",
            "description": "Bei einem VNS-Besuch überprüft Ihr Arzt Ihre aktuelle Einstellung. Basierend auf der Vorgeschichte der aufgezeichneten Anfälle wählt er oder sie die geeignete VNS-Therapie aus. Der Arzt beim Besuch ändert die Stimulationseinstellungen mithilfe eines Computers, einer Konfigurationssoftware und einer Programmierfernbedienung. Der Arzt kann den Strom verringern oder erhöhen und die Inaktivitätszeit des Geräts verlängern oder verkürzen. Der Besuch dauert 15 Minuten.",
            "bulletsTitle": "Wann sollte man einen VNS-Termin buchen?",
            "bullet1": "Bei der VNS-Therapie kommt es zu einer allmählichen Änderung der aktuellen Parameter",
            "bullet2": "Wenn ein Patient einen deutlichen Anstieg der Anfallshäufigkeit bemerkt",
            "bullet3": "Wenn ein Patient eine deutliche Verschlechterung seiner Gesundheit und seines Wohlbefindens bemerkt",
            "bullet4": "Wenn ein Patient merkt, dass der implantierte Stimulator nicht mehr funktioniert, also keine Impulse mehr aussendet",
            "bullet5": "Bevor Sie sich medizinischen Tests unterziehen, die das VNS-Gerät beeinträchtigen oder von ihm beeinflusst werden könnten, wie z. B. einer Magnetresonanztomographie (MRT)."
          },
          "dietician": {
            "title": "Wer ist Ernährungsberater?",
            "description": "Ein Spezialist mit einem Hochschulabschluss mit medizinischem oder ernährungswissenschaftlichem (landwirtschaftlichem) Profil. Verfügt über ausreichende Kenntnisse zur Durchführung von Diäten bei verschiedenen Patientengruppen und berücksichtigt bei der Abgabe von Empfehlungen unter anderem Geschlecht, Alter, Gewicht und Größe, Krankheiten, einschließlich Allergien, und Patientenpräferenzen. So erstellt er für jeden Patienten einen individuellen Ernährungsplan und betreut ihn. Abhängig von den Testergebnissen oder den vom Patienten erhaltenen Informationen werden die erforderlichen Änderungen vorgenommen. Daher ist eine enge Zusammenarbeit mit dem Patienten und seinem Arzt äußerst wichtig.",
            "bulletsTitle": "Wann sollte man einen Ernährungsberater konsultieren?",
            "bullet1": "Als der Neurosphery-Arzt eine Behandlung mit einer ketogenen Diät vorschlug",
            "bullet2": "Wenn Sie der Meinung sind, dass die ketogene Diät in Ihrem Fall eine Behandlungsoption sein könnte (HINWEIS: Ein Arztbesuch ist ebenfalls erforderlich. Wenn Sie Neurospeha-Patient sind, fragen Sie Ihren Arzt nach dieser Behandlungsoption, sofern Sie nicht bereits Neurospeha-Patient sind , vereinbaren Sie einen Termin für einen Besuch bei ZERO)",
            "bullet3": "Wenn Sie sich ketogen ernähren und Ihre aktuelle Behandlung überprüfen möchten (HINWEIS – ein Arztbesuch ist ebenfalls erforderlich)",
            "bullet4": "Wenn Sie sich ketogen ernähren – ein Kontrollbesuch (der Ernährungsberater informiert Sie während des Besuchs über den besten Zeitpunkt für die Anmeldung)",
            "bullet5": "Wenn Sie Ihre aktuelle Ernährung überprüfen möchten"
          },
          "neurologist": {
            "title": "Wer ist Neurologe?",
            "description": "Ein Spezialist, der die Reaktionen des Körpers des Patienten beobachtet, die Funktionen des Nervensystems untersucht und auch die Reaktionen des menschlichen Körpers auf Reize überprüft. Er beschäftigt sich mit Erkrankungen des peripheren und zentralen Nervensystems. Der Neurologe beschäftigt sich vor allem mit Erkrankungen, die durch Prozesse verursacht werden, die das menschliche Nervensystem zerstören. Er untersucht die Grundlagen der auftretenden neurologischen Erkrankungen, stellt eine Diagnose und leitet die entsprechende Behandlung ein. Er befasst sich mit der Behandlung von Krankheiten wie Ischias, Degeneration der Wirbelsäule, Epilepsie, Parkinson-Krankheit, Alzheimer-Krankheit, Chorea Huntington, Schlaganfall und Post-Schlaganfall-Erkrankungen, Migräne und anderen Kopfschmerzen.",
            "bulletsTitle": "Wann sollte man einen Neurologen konsultieren?",
            "bullet1": "Wenn Ohnmacht und Bewusstlosigkeit auftreten",
            "bullet2": "Wenn das Gedächtnis stark nachlässt und es zu Konzentrationsproblemen kommt",
            "bullet3": "Wenn Sie Schlafstörungen haben",
            "bullet4": "Bei Gleichgewichts- und Koordinationsproblemen",
            "bullet5": "Wenn ein Kribbeln in den Extremitäten zusammen mit einer Gefühlsstörung auftritt",
            "bullet6": "Bei chronischen Schmerzen, die mit Schwindel einhergehen"
          },
          "neurosurgeon": {
            "title": "Wer ist ein Neurochirurg?",
            "description": "Ein Chirurg, der auf die Diagnose und chirurgische Behandlung bestimmter Erkrankungen des Nervensystems (Gehirn, Rückenmark, periphere Nerven) spezialisiert ist, bei denen die pharmakologische Behandlung nicht funktioniert hat und eine Operation durchgeführt werden sollte.",
            "bulletsTitle": "Wann sollte man einen Neurochirurgen konsultieren?",
            "bullet1": "Auftreten neurologischer Probleme, bei denen die pharmakologische Behandlung nicht gewirkt hat, z.B. Diskopathie, chronische Rückenschmerzen und -verletzungen, Hirntumoren und neoplastische Läsionen der Wirbelsäule, Hämangiome usw.",
            "bullet2": "Starke und anhaltende Kopfschmerzen und Schwindel",
            "bullet3": "Unbekannte Ursache: Taubheitsgefühl oder Kribbeln in den Extremitäten",
            "bullet4": "Auftreten häufiger Synkopen",
            "bullet5": "Vorliegen einer unbekannten Ursache: Beeinträchtigung des Seh- und Hörvermögens",
            "bullet6": "Prävalenz von Gliedmaßenparesen"
          },
          "neuropsychologist": {
            "title": "Wer ist Neuropsychologe?",
            "description": "Ein Spezialist für klinische Psychologie, der sich mit der Beziehung zwischen der Funktion des Gehirns und menschlichem Verhalten beschäftigt. Die Tätigkeit des Neuropsychologen richtet sich vor allem an Menschen mit Hirnschäden, die durch verschiedene Krankheiten, Verletzungen oder Verletzungen verursacht werden.",
            "bulletsTitle": "Wann sollte man einen Neuropsychologen konsultieren?",
            "bullet1": "Wenn bei einem Patienten Probleme im normalen Funktionieren diagnostiziert werden, wie z. B. Kopfverletzungen, Hirntumoren oder Krankheiten, die das Nervengewebe zerstören. z.B. Alzheimer- oder Parkinson-Krankheit",
            "bullet2": "Es kommt zu einer Gedächtnisstörung",
            "bullet3": "Konzentrationsprobleme",
            "bullet4": "Auftreten von Sprachstörungen",
            "bullet5": "Schwierigkeiten, sich zu orientieren, einen Weg zu finden",
            "bullet6": "Trotz normaler ophthalmologischer Ergebnisse kommt es zu Sehstörungen",
            "bullet7": "Es treten Stimmungsschwankungen, Angstzustände und Wahnvorstellungen auf",
            "bullet8": "In Suchtzuständen"
          },
          "neurologopedist": {
            "title": "Wer ist Neurologe?",
            "description": "Spezialist für die Diagnose und Behandlung von Sprach- und Kommunikationsstörungen neurologischer Genese. Der Neurologe kann sowohl mit Kindern mit verzögerter Sprachentwicklung oder Sprachstörungen unterschiedlicher Herkunft als auch mit erwachsenen Patienten arbeiten.",
            "bulletsTitle": "Wann sollte man einen Neurologen konsultieren?",
            "bullet1": "Wenn bei einem Kind eine neurologische Störung, ein genetisches Syndrom oder eine Autismus-Spektrum-Störung diagnostiziert wird",
            "bullet2": "Wenn ein Elternteil eine psychomotorische Behinderung seines Kindes bemerkt",
            "bullet3": "Wenn ein Kind Sprachschwierigkeiten hat – ein 2-jähriges oder älteres Kind spricht überhaupt nicht, spricht wenig oder hat eine Sprachbehinderung",
            "bullet4": "Wenn ein Kind übermäßig viel Speichel produziert, hat es Schwierigkeiten beim Essen, schließt den Mund nicht und atmet nicht durch die Nase",
            "bullet5": "Wenn ein Erwachsener nach einem Schlaganfall oder Unfall eine Sprachstörung hat",
            "bullet6": "Wenn ein Erwachsener aufgrund einer Altersdemenz, der Parkinson-Krankheit, der Alzheimer-Krankheit oder der Multiplen Sklerose an Sprachstörungen leidet"
          },
          "psychiatrist": {
            "title": "Wer ist Psychiater?",
            "description": "Ein Arzt, der sich mit psychischen Gesundheitsproblemen – psychischen Störungen und Krankheiten – befasst, sowohl im Hinblick auf die Diagnose als auch auf die weitere Therapie. Der Psychiater kann eine pharmakologische Behandlung durchführen und zusätzliche Konsultationen an andere Fachgebiete verweisen.",
            "bulletsTitle": "Wann sollte man einen Psychiater konsultieren?",
            "bullet1": "Wenn sie lange Zeit nerven: Traurigkeit, Leid, Apathie, Energiemangel, Hilflosigkeit, pessimistische Zukunftsvision",
            "bullet2": "Beginn von Selbstmordgedanken",
            "bullet3": "Bei Problemen und Schlafstörungen",
            "bullet4": "Bei Angststörungen",
            "bullet5": "Das Einsetzen von Hyperaktivität, unnatürlich gehobener Stimmung und Verwirrung",
            "bullet6": "Auftreten von Konzentrationsproblemen und Gedächtnisstörungen",
            "bullet7": "Kontaktvermeidung und Ruhestand",
            "bullet8": "Bei Wahnvorstellungen, die die Wahrnehmung der Realität beeinträchtigen, und Halluzinationen"
          },
          "epileptologist": {
            "title": "Wer ist Epileptologe?",
            "description": "Ein Neurologe, der auf die Diagnose und Behandlung von Epilepsie spezialisiert ist. Ein Epileptologe kann sich auf die Diagnose und Behandlung von Epilepsie bei Kindern oder Erwachsenen spezialisieren. Neben der Auswahl der Therapie koordiniert der Arzt den Therapieablauf und überweist den Patienten auch an Ärzte anderer Fachrichtungen.",
            "bulletsTitle": "Wann sollte man einen Epileptologen konsultieren?",
            "bullet1": "Das Auftreten eines plötzlichen Anfalls, bei dem der Patient das Bewusstsein verliert und stürzt und die Muskeln anspannen. Es kommt zu Krämpfen und der Patient kann aufhören zu atmen",
            "bullet2": "Es kommt zu einem plötzlichen Verlust des Kontakts mit der Umgebung und der Patient erstarrt bewegungslos",
            "bullet3": "Muskelkrämpfe treten ohne Bewusstlosigkeit auf",
            "bullet4": "Muskelentspannung, Bewusstlosigkeit, Sturz des Patienten"
          },
          "geneticist": {
            "title": "Wer ist Genetiker?",
            "description": "Ein klinischer Genetiker ist ein Arzt, dessen Aufgabe darin besteht, festzustellen, ob eine Krankheit erblich bedingt ist und welcher Mechanismus zur Vererbung einer Anomalie geführt hat. Der Genetiker bestimmt auch die Wahrscheinlichkeit, dass in nachfolgenden Generationen dieselben Defekte oder Krankheiten auftreten. Ihr Zweck besteht auch darin, Familienangehörige der erkrankten Person zu identifizieren, bei denen eine Erbkrankheit auftreten könnte. Die Aufgabe der Genetik besteht auch darin, die Familie über die Präventions- und Behandlungsmöglichkeiten der Krankheit zu informieren und sie genetisch zu beraten. Ein wichtiger Bestandteil ist auch die Durchführung einer fachärztlichen Behandlung einer Erbkrankheit.",
            "bulletsTitle": "Wann sollte man einen Genetiker konsultieren?",
            "bullet1": "Wenn in Ihrer Familie eine Krankheit mit bekannten oder vermuteten genetischen Ursachen vorliegt",
            "bullet2": "Wann möchten Sie den WES-Test machen?",
            "bullet3": "Wenn Sie eine angeborene Krankheit ausschließen möchten, die Epilepsie verursachen könnte",
            "bullet4": "Wenn Sie eine Schwangerschaft planen und herausfinden möchten, ob Ihr Baby möglicherweise auch an Epilepsie leidet"
          },
          "absent": {
            "title": "Was ist eine Abwesenheitsberatung?",
            "description": "Bei einer Abwesenheitssprechstunde handelt es sich um eine Art Arztbesuch, den ein ausgewählter Neurosphera-Spezialist ohne Anwesenheit des Patienten durchführt. Die Abwesenheitskonsultation dient der Analyse medizinischer Unterlagen (Entlassungskarten, Ergebnisse von Gentests und anderen) oder einer zusätzlichen Untersuchung (EEG, MRT und anderen). Der Neurosphärenspezialist wird die Dokumentation analysieren, eine entsprechende Notiz und Empfehlungen für den Patienten verfassen. Die Durchführung und das Ergebnis werden im Besuchsarchiv des Patienten gespeichert. HINWEIS: Die Abwesenheitsberatung dauert 30 Minuten und wird ohne Beteiligung des Patienten durchgeführt.",
            "bulletsTitle": "Wann konsultieren?",
            "bullet1": "Sie werden aufgefordert, sich für eine Abwesenheitskonsultation bei Besuch 1 oder später anzumelden – sofern der Arzt eine solche Konsultation für angemessen hält. Zweck ist die Durchführung einer zusätzlichen klinischen Analyse, die über den Zeitrahmen hinausgeht oder die Rücksprache mit einem anderen Arzt erfordert (Beispiel: Es stellt sich heraus, dass z. B. die Notwendigkeit besteht, eine Stoffwechseluntersuchung bei einem ausgewählten Facharzt oder mehrere MRT-Untersuchungen über einen Zeitraum von mehreren Jahren zu konsultieren). es sollte ein Zeitraum von mehreren Jahren analysiert werden).",
            "bullet2": "Normalerweise hat der klinische Spezialist während einer 30-minütigen Konsultation die Möglichkeit, zwei unterstützende Tests mit Beschreibungen zu sehen."
          },
          "metabolic": {
            "title": "Was ist eine Stoffwechselberatung?",
            "description": "Die Stoffwechselberatung betrifft seltene Krankheiten – angeborene Stoffwechselstörungen, bei denen es sich um genetische Erkrankungen handelt. Die Diagnostik von Stoffwechselerkrankungen umfasst im Wesentlichen zahlreiche Untersuchungen und Laboruntersuchungen, oft unterstützt durch fachärztliche Untersuchungen.",
            "bulletsTitle": "Wann konsultieren?",
            "bullet1": "Es lohnt sich, zu einer Stoffwechselberatung zu kommen, wenn bei genetischen Untersuchungen ein Verdacht auf die Erkrankung besteht, oder wenn die Untersuchungen: Tandem-Blutmassenspektrometrie (Tandem-MS, MS/MS) oder organisches Säureprofil im Urin (GC-MS-Test) sind falsch.",
            "bullet2": "Einige mit Epilepsie einhergehende Krankheiten können eine metabolische Grundlage haben, z. Pyruvatdehydrogenase (PDHD)-Mangel, Defizit des Glukosetransporters zum Gehirn (GLUT-1 DS) oder ausgewählte mitochondriale Erkrankungen."
          },
          "visit0": {
            "bulletsTitle": "Was ist Umfrage 0?",
            "bullet1": "<b>Vorbereitet und vorbereitet</b> 1 Besuch beim Neurosphery Clinical Specialist Doctor",
            "bullet2": "Es handelt sich um <b>eine ausführliche Befragung Ihrer Krankengeschichte</b>",
            "bullet3": "Die Leitung übernimmt eine <b>medizinisch qualifizierte Person</b>, die wir individuell für Sie auswählen",
            "bullet4": "<b>Bereiten Sie sich darauf vor.</b> Erinnern Sie sich daran, welche Medikamente eingenommen wurden, wie oft Anfälle auftreten, wie sie verlaufen und was ihre Natur ist",
            "bullet5": "Die Befragungsperson 0 erstellt eine digitale Dokumentation Ihres Falles in Form eines strukturierten Interviews. Auf diese Weise organisierte digitale Daten können von unserem Ictal-Roboter analysiert werden, um während der Visite 1 eine Diagnose und Therapie vorzuschlagen",
            "bullet6": "Dauer <b>bis zu 60 Minuten</b>",
            "bullet7": "Sie können die Art des Besuchs wählen – <b>online oder stationär</b>"
          },
          "visit1": {
            "bulletsTitle": "Was ist Besuch 1?",
            "bullet1": "Es ist ein Besuch beim Neurosphären-Klinikspezialisten Ihrer Wahl",
            "bullet2": "Der Arzt sieht die Ergebnisse der Umfrage 0, die Ergebnisse der Analyse unseres Ictal-Roboters und die von Ihnen beigefügten Materialien, die es ihm ermöglichen, die Diagnose und Therapie wirksam zu bestätigen",
            "bullet3": "Der Arzt wird Ihnen bei Bedarf zusätzliche Untersuchungen anbieten",
            "bullet4": "Der Arzt wird Ihnen einen umfassenden Pflegeplan anbieten",
            "bullet5": "Dauer <b>bis zu 30 Minuten</b>",
            "bullet6": "Sie können die Art des Besuchs wählen – <b>online oder stationär</b>"
          },
          "veegOnStandby": {
            "title": "Was ist die VEEG-Untersuchung im Wachzustand?",
            "description": "VEEG ist eine Echtzeitaufzeichnung von synchronisiertem EEG und Video. Für eine korrekte Diagnose muss der Arzt wissen, was während der Anfälle mit dem Patienten passiert. Alle Details sind wichtig: in welche Richtung sich der Kopf dreht, in welche Richtung die Augäpfel „laufen“, wo die Anfälle auftreten usw. Die videometrische Untersuchung ermöglicht es dem Arzt, den Patienten in diesem für die Diagnose der Krankheit entscheidenden Moment zu sehen. Die Untersuchung wird in einem speziell vorbereiteten Labor durchgeführt. Die Wachuntersuchung wird tagsüber durchgeführt. Der Patient entscheidet nach Anweisung des Arztes, wie lange die Untersuchung dauern soll. Dem Patienten stehen im Untersuchungsraum ein Sessel und ein Bett zur Verfügung. Der Patient kann während der Untersuchung die Toilette benutzen, während er am EEG-Gerät angeschnallt ist. Nach der Untersuchung erhält der Patient eine CD mit einer aufgezeichneten Aufnahme. Die Beschreibung der Untersuchung ist im Preis enthalten.",
            "bulletsTitle": "Wie bereitet man sich auf die Prüfung vor?",
            "bullet1": "Der Patient sollte einen sauberen Kopf haben",
            "bullet2": "Vor der Untersuchung sollte kein Gel oder Haarspray auf die Haare aufgetragen werden",
            "bullet3": "Nehmen Sie die von Ihrem Arzt verordneten Medikamente ein",
            "bullet4": "Führen Sie keinen Schlafentzug ohne Absprache mit dem Arzt durch – der Patient schläft ein"
          },
          "veegDuringSleep": {
            "title": "Was ist eine VEEG-Untersuchung im Schlaf?",
            "description": "VEEG ist eine Echtzeitaufzeichnung von synchronisiertem EEG und Video. Für eine korrekte Diagnose muss der Arzt wissen, was während der Anfälle mit dem Patienten passiert. Alle Details sind wichtig: in welche Richtung sich der Kopf dreht, in welche Richtung die Augäpfel „laufen“, wo die Anfälle auftreten usw. Die videometrische Untersuchung ermöglicht es dem Arzt, den Patienten in diesem für die Diagnose der Krankheit entscheidenden Moment zu sehen. Die Untersuchung wird in einem speziell vorbereiteten Labor durchgeführt. Die Schlafuntersuchung wird abends oder nachts durchgeführt. Wie lange die Untersuchung dauern soll, entscheidet der Patient nach Anweisung des Arztes. Dem Patienten stehen im Untersuchungsraum ein Sessel und ein Bett zur Verfügung. Der Patient kann während der Untersuchung die Toilette benutzen, während er am EEG-Gerät angeschnallt ist. Große Kinder und Erwachsene liegen alleine auf dem Bett, kleine Kinder mit einer Begleitperson, das Wartezimmer steht der Begleitperson zur Verfügung. Nach der Untersuchung erhält der Patient eine CD mit der aufgezeichneten Aufnahme. Eine Beschreibung der Untersuchung ist im Preis enthalten.",
            "bulletsTitle": "Wie bereitet man sich auf die Prüfung vor?",
            "bullet1": "Der Patient sollte einen sauberen Kopf haben",
            "bullet2": "Vor der Untersuchung sollte kein Gel oder Haarspray auf die Haare aufgetragen werden",
            "bullet3": "Nehmen Sie die von Ihrem Arzt verordneten Medikamente ein",
            "bullet4": "Führen Sie keinen Schlafentzug ohne Absprache mit dem Arzt durch – der Patient schläft ein",
            "bullet5": "Es schadet nicht, sein Lieblingskissen von zu Hause mitzunehmen"
          },
          "veegNight": {
            "title": "Was ist ein 12-Stunden-VEEG-Test?",
            "description": "VEEG ist eine Echtzeitaufzeichnung von synchronisiertem EEG und Video. Für eine korrekte Diagnose muss der Arzt wissen, was während der Anfälle mit dem Patienten passiert. Alle Details sind wichtig: in welche Richtung sich der Kopf dreht, in welche Richtung die Augäpfel „laufen“, wo die Anfälle auftreten usw. Die videometrische Untersuchung ermöglicht es dem Arzt, den Patienten in diesem für die Diagnose der Krankheit entscheidenden Moment zu sehen. Die Untersuchung wird in einem speziell vorbereiteten Labor durchgeführt. Dem Patienten stehen in der Praxis ein Sessel und ein Bett zur Verfügung. Wir beginnen mit der Untersuchung, sobald der Patient einschläft, durchlaufen dann die einzelnen Phasen und erfassen schließlich den Moment des Aufwachens. Der Patient hat die Möglichkeit, während der Untersuchung die Toilette zu benutzen, während er am EEG-Gerät angeschlossen bleibt. Große Kinder und Erwachsene liegen allein auf dem Bett, kleine Kinder mit ihrer Bezugsperson, ein Wartezimmer ist vorhanden. Nach der Untersuchung erhält der Patient eine CD mit der aufgezeichneten Aufnahme. Eine Beschreibung der Untersuchung ist im Preis enthalten.",
            "bulletsTitle": "Wie bereitet man sich auf die Prüfung vor?",
            "bullet1": "Der Patient sollte einen sauberen Kopf haben",
            "bullet2": "Vor der Untersuchung sollte kein Gel oder Haarspray auf die Haare aufgetragen werden",
            "bullet3": "Nehmen Sie die von Ihrem Arzt verordneten Medikamente ein",
            "bullet4": "Führen Sie keinen Schlafentzug ohne Absprache mit dem Arzt durch – der Patient schläft ein",
            "bullet5": "Es schadet nicht, sein Lieblingskissen von zu Hause mitzunehmen"
          },
          "veegDay": {
            "title": "Was ist ein 24-Stunden-VEEG-Test?",
            "description": "Ein VEEG-Test ist eine Echtzeitaufzeichnung einer synchronisierten EEG- und Videostudie. Für eine korrekte Diagnose muss der Arzt wissen, was während der Anfälle mit dem Patienten passiert. Alle Details sind wichtig: in welche Richtung sich der Kopf dreht, in welche Richtung die Augäpfel „laufen“, wo die Anfälle auftreten usw. Die videometrische Untersuchung ermöglicht es dem Arzt, den Patienten in diesem für die Diagnose der Krankheit entscheidenden Moment zu sehen. Die Untersuchung wird in einem speziell vorbereiteten Labor durchgeführt. Dem Patienten stehen in der Praxis ein Sessel und ein Bett zur Verfügung. Normalerweise beginnt die Untersuchung mit einer nächtlichen Untersuchung und dann überwachen wir den Patienten tagsüber. Der Patient hat die Möglichkeit, während der Untersuchung die Toilette zu benutzen, während er am EEG-Gerät angeschlossen bleibt. Die Verpflegung ist im Prüfungspreis inbegriffen. Große Kinder und Erwachsene liegen allein auf einem Bett, kleine Kinder mit einer Begleitperson, ein Wartezimmer ist vorhanden. Nach der Untersuchung erhält der Patient eine CD mit der aufgezeichneten Aufnahme. Eine Beschreibung der Untersuchung ist im Preis enthalten.",
            "bulletsTitle": "Wie bereitet man sich auf die Prüfung vor?",
            "bullet1": "Der Patient sollte einen sauberen Kopf haben",
            "bullet2": "Vor der Untersuchung sollte kein Gel oder Haarspray auf die Haare aufgetragen werden",
            "bullet3": "Nehmen Sie die von Ihrem Arzt verordneten Medikamente ein",
            "bullet4": "Führen Sie keinen Schlafentzug ohne Absprache mit dem Arzt durch – der Patient schläft ein",
            "bullet5": "Es schadet nicht, sein Lieblingskissen von zu Hause mitzunehmen",
            "bullet6": "Weitere Einzelheiten können mit dem Techniker telefonisch an der Rezeption der Praxis unter +48 609 463 191 vereinbart werden"
          },
          "eegConsultation": {
            "title": "Was ist eine EEG-Untersuchung?",
            "description": "Eine Standard-EEG-Untersuchung wird durchgeführt, während der Patient wach ist. Der Techniker wählt eine geeignete Kappe aus und platziert Elektroden auf dem Kopf des Patienten. Für die Qualität der Untersuchung ist es wichtig, dass sie möglichst eng an der Kopfhaut anliegen. Die Prüfung selbst dauert etwa 20 Minuten. Unmittelbar nach der Untersuchung kann der Patient seine normale Aktivität wieder aufnehmen. Nach der Untersuchung erhält der Patient eine CD mit einer aufgezeichneten Aufnahme. Die Beschreibung der Untersuchung ist im Preis enthalten.",
            "bulletsTitle": "Wie bereitet man sich auf die Prüfung vor?",
            "bullet1": "Der Patient sollte einen sauberen Kopf haben",
            "bullet2": "Vor der Untersuchung sollte kein Gel oder Haarspray auf die Haare aufgetragen werden",
            "bullet3": "Nehmen Sie die von Ihrem Arzt verordneten Medikamente ein",
            "bullet4": "Führen Sie keinen Schlafentzug ohne Absprache mit dem Arzt durch – der Patient schläft ein"
          },
          "sleepDisturbance": {
            "title": "Was ist eine Schlafstörungsberatung?",
            "description": "Schlafstörungen können sowohl primärer Natur sein (aufgrund von Anomalien im Schlaf selbst) als auch ein Symptom von Erkrankungen anderer Systeme sein. Manchmal ist eine psychiatrische Konsultation angezeigt. Während des Besuchs wird ein ausführliches Interview geführt, das den Verlauf der Nacht und den Ablauf des Tages abdeckt. Es lohnt sich, für einen solchen Besuch ein Schlaftagebuch zu erstellen, das sich über mindestens fünf Tage erstreckt und in dem die störenden Episoden, die nachts auftreten, zu Hause aufgezeichnet werden. Nach der Beratung wird beurteilt, ob das Problem physiologischer Natur ist oder nicht. Wenn eine Schlafstörung diagnostiziert wird, wird Ihnen eine Behandlung angeboten – Behandlung oder Überweisung an einen Spezialisten, der sich mit dem zugrunde liegenden Problem befasst.",
            "bulletsTitle": "Wann konsultieren?",
            "bullet1": "Wenn Sie tagsüber schläfrig oder gereizt sind",
            "bullet2": "Wenn Sie unter Schlaflosigkeit oder übermäßiger Schläfrigkeit leiden, die durch emotionale Faktoren verursacht wird",
            "bullet3": "Wenn Sie Störungen im zirkadianen Rhythmus von Schlaf und Aufwachen beobachten",
            "bullet4": "Wenn Sie im Schlaf schnarchen"
          },
          "regularVisitEegPackage": {
            "title": "Was ist das reguläre Besuchs- und EEG-Paket?",
            "description": "EEG-Test – ist ein Standardtest zur Beurteilung der bioelektrischen Aktivität des Gehirns, auf dessen Grundlage die Richtigkeit seiner Funktion beurteilt wird. Die Prüfung dauert etwa 20 Minuten. Nach der Untersuchung geht der Patient zu einem Besuch. REGELMÄßIGER stationärer Besuch – jeder weitere Besuch. Hält bis zu 30 Minuten an. Während des Besuchs analysiert der Arzt den durchgeführten EEG-Test, stellt die endgültige Diagnose und analysiert die im System gesammelten Daten. Der Patient erhält eine CD mit dem aufgezeichneten EEG-Test und Empfehlungen von einem regelmäßigen Besuch.",
            "bulletsTitle": "Wann muss ich mich für das Paket anmelden?",
            "bullet1": "Der EEG-Test ist der Basistest zur Diagnose von Epilepsie",
            "bullet2": "Ein Patient, der an Epilepsie leidet, sollte regelmäßig jedes Jahr eine EEG-Untersuchung durchführen lassen und regelmäßig einen Epileptologen aufsuchen",
            "bullet3": "Wenn Sie einen stationären Arztbesuch planen, lohnt sich die Durchführung eines EEG zur Beurteilung der bioelektrischen Aktivität des Gehirns",
            "bullet4": "Sie spüren keine Verbesserung oder Verschlechterung Ihres Gesundheitszustandes und möchten mit Ihrem Arzt über eine Änderung Ihrer Behandlung sprechen"
          },
          "adhdPackage": {
            "title": "Wie wird ADHS diagnostiziert?",
            "description": "Die ADHS-Diagnostik besteht aus 2 Gesprächen mit einem Neuropsychologen von jeweils 1,5 Stunden Dauer, in denen ein klinisches Interview und neuropsychologische Tests durchgeführt werden. Nach den Gesprächen gibt der Psychologe eine Stellungnahme ab und empfiehlt dem Patienten, zu wem er gehen soll, sei es ein Neurologe (30 Minuten) oder ein Psychiater (45 Minuten). Beim entsprechenden Besuch erhält der Patient eine Diagnose und Empfehlungen zur weiteren Behandlung (pharmakologische Behandlung oder psychologische Therapie).",
            "bulletsTitle": "Wann sollte man sich für das ADHS-Paket anmelden?",
            "bullet1": "Wenn es Schwierigkeiten mit der Organisation von Zeit und Aufgaben gibt. Es fällt Ihnen schwer, Projekte zu starten und abzuschließen, zu planen und Ordnung zu halten",
            "bullet2": "Wenn es emotionale Schwierigkeiten gibt, neigen Sie zu Stimmungsschwankungen, Frustration und Schwierigkeiten, Ihre Emotionen zu kontrollieren. Sie reagieren stark auf Stresssituationen",
            "bullet3": "Wenn Sie zwischenmenschliche Schwierigkeiten bei sich selbst bemerken. Sie sind unruhig, impulsiv und haben Schwierigkeiten, die Perspektiven anderer Menschen zu berücksichtigen",
            "bullet4": "Wenn es Ihnen schwerfällt, die für die Erledigung einer Aufgabe benötigte Zeit einzuschätzen"
          },
          "standardVisit": {
            "title": "Was ist ein regelmäßiger Besuch?",
            "description": "REGELMÄßIGER Besuch – jeder weitere Besuch. Hält bis zu 30 Minuten an. Während des Besuchs führt der Arzt ein Interview und analysiert den Veranstaltungskalender des Patienten. Der Arzt überprüft die medizinischen Unterlagen des Patienten und schlägt möglicherweise zusätzliche Tests vor. Nach jedem Besuch erhält der Patient Empfehlungen aus dem Besuch, einen Therapieplan und einen Plan zur weiteren Betreuung. Der Patient kann die Art des Besuchs wählen – online oder stationär.",
            "bulletsTitle": "Wie sieht ein regelmäßiger Besuch aus?",
            "bullet1": "Besprechen Sie Ihren aktuellen Gesundheitszustand – erinnern Sie sich an das Datum des letzten Angriffs und Ihr Wohlbefinden – vervollständigen Sie den Veranstaltungskalender",
            "bullet2": "Informieren Sie sich über die Ergebnisse kürzlich durchgeführter Tests. Wenn Sie kürzlich Tests durchgeführt haben, stellen Sie sicher, dass alle Ergebnisse der medizinischen Dokumentation beigefügt sind",
            "bullet3": "Besprechung der weiteren Therapie",
            "bullet4": "Erstellung eines umfassenden Pflegeplans",
            "bullet5": "Ausstellung eines Rezepts, einer Überweisung, eines Krankenstands, einer Bescheinigung"
          },
          "caseConference": {
            "title": "Was ist eine Fallkonferenz?",
            "description": "Eine medizinische Fallkonferenz zwischen Fachärzten und den medizinischen Partnern von Neurosphery ist ein gemeinsames Treffen, bei dem der klinische Fall des Patienten besprochen und der optimale Behandlungsplan festgelegt wird. Eine Konsultation wird in Situationen organisiert, in denen Unterstützung oder zusätzliches Fachwissen erforderlich ist, um die besten Behandlungsergebnisse zu erzielen. Es handelt sich um eine Art interdisziplinäre medizinische Konferenz, bei der Wissen und Meinungen zwischen Spezialisten ausgetauscht werden."
          },
          "mmpi2Package": {
            "title": "Was ist der MMPI-2-Persönlichkeitstest?",
            "description": "Wir ermöglichen die Diagnose von Persönlichkeitsstörungen mithilfe des umfangreichsten und universellsten klinischen Bewertungsinstruments, das in Polen verfügbar ist – dem Minnesota Multidimensional Personality Inventory (die vollständige Anpassung des MMPI-2 wurde 2012 veröffentlicht). Der Test enthält zehn Kontrollskalen, mit denen die Zuverlässigkeit des erhaltenen Ergebnisprofils beschrieben und ihre klinische Interpretation begründet werden kann. Kontrollskalen können den Diagnostiker auch über die Motivation, das Anpassungsniveau und mögliche Abwehrmechanismen des Probanden informieren.",
            "bulletsTitle": "Was ist der MMPI-2-Persönlichkeitstest?",
            "bullet1": "Das Inventar selbst kann zur klinischen Beschreibung von Persönlichkeitsproblemen verwendet werden, es kann aber auch nützlich sein, um Personen zu identifizieren, die für die Ausübung einer bestimmten beruflichen Funktion ungeeignet sind. Es kann zur Diagnose von Erwachsenen verwendet werden, die mindestens 6 Jahre formale Bildung absolviert haben. Der Test muss unter Aufsicht eines Psychologen durchgeführt werden. Sie sollten etwa 90 Minuten einplanen, um den Test durchzuführen und abzuschließen. Beim Ausfüllen des Tests müssen Sie auf 567 Aussagen verweisen, indem Sie WAHR/FALSCH auswählen. Dem Ausfüllen des Tests selbst sollte ein etwa 50-minütiges Treffen vorausgehen, um Daten aus dem Interview zu sammeln und der getesteten Person den Test vorzustellen. Innerhalb einer Woche, nach Abschluss des Besuchs und Abschluss des Tests, erhält die untersuchte Person einen Bericht, in dem die erzielten Ergebnisse zusammengefasst sind."
          },
          "cbtPackage": {
            "title": "Was ist eine kognitive Verhaltenstherapie gegen Schlaflosigkeit?",
            "description": "Die kognitive Verhaltenstherapie (CBT) gegen Schlaflosigkeit gilt als Erstbehandlung für Menschen, die unter Schlaflosigkeit leiden, und wird von Behörden wie den National Institutes of Health (NIH), dem National Institute for Health and Care Excellence (NICE) und dem American College of Physicians empfohlen. CBT-I ist eine wirksame Alternative zu Schlafmitteln und bietet dauerhafte Lösungen durch Veränderung der Schlafgewohnheiten und des Denkens. Der gesamte CBT-I-Prozess umfasst fünf Sitzungen über sechs Wochen verteilt, aber Verbesserungen sind normalerweise schon früher zu erkennen. Die Sitzungen können stationär oder online abgehalten werden. Etwa 75 % der Menschen mit chronischer Schlaflosigkeit erleben eine deutliche Verbesserung ihres Schlafs und 90 % reduzieren die Einnahme von Schlafmitteln oder setzen diese ganz ab.",
            "bulletsTitle": "Die CBT-I-Therapie wird für Personen empfohlen, die:",
            "bullet1": "Sie leiden unter chronischer Schlaflosigkeit: Mindestens drei Monate lang treten mindestens dreimal pro Woche Schlafprobleme auf.",
            "bullet2": "Haben Einschlafschwierigkeiten: Sie verbringen mehr als 30 Minuten damit, nachts einzuschlafen.",
            "bullet3": "Nächtliches Aufwachen: Sie wachen regelmäßig mitten in der Nacht auf und können nur schwer länger als 30 Minuten einschlafen.",
            "bullet4": "Dadurch fühlen sie sich tagsüber müde und gereizt: Schlaflosigkeit beeinträchtigt ihr tägliches Wohlbefinden und ihre Lebensqualität.",
            "bullet5": "Sie suchen nach einer Alternative zu Schlafmitteln: Sie möchten eine Abhängigkeit von Pharmakotherapien vermeiden und suchen nach langfristigen, natürlichen Lösungen."
          }
        },
        "visitPackageDescriptions": {
          "startPackage1": "Als neuer Neurosphera-Patient oder Elternteil des Patienten müssen Sie einen Termin 1 mit einem klinischen Spezialisten vereinbaren. Vorab erfolgt eine Umfrage 0, die es uns ermöglicht, Informationen für eine wirksame Diagnose und Therapie zu sammeln. Und das alles zu einem erschwinglichen Preis.",
          "startPackage2": "Wählen Sie den Facharzt aus, der Besuch 1 sehen soll, und ob dieser online oder stationär stattfinden soll. Achten Sie auf die Preisunterschiede und den Ort des stationären Besuchs.",
          "startPackage3": "Die Befragung 0 geht dem Besuch 1 voraus und bereitet ihn vor. Sie wird mit einer medizinisch qualifizierten Person unserer Wahl durchgeführt. Wählen Sie die für Sie passende Befragungsart 0 (online oder stationär) und deren Datum. Denken Sie daran, dass Umfrage 0 bis zu 60 Minuten dauert.",
          "startPackage4": "Durch eine Zahlung können wir für Sie einen Termin bei einem bestimmten Arzt vereinbaren. Denken Sie daran, Sie können das Datum jederzeit kostenlos ändern!"
        },
        "slotChoiceDescriptions": {
          "VISIT_1": "Besuch 1 findet nach Umfrage 0 statt. Der Neurosphery Clinical Specialist und die Art des Besuchs haben Sie bereits im vorherigen Schritt ausgewählt. Der Arzt wird eine Diagnose stellen und eine geeignete Therapie vorschlagen. Außerdem erstellt er einen individuellen Pflegeplan. Denken Sie daran, dass Besuch 1 bis zu 30 Minuten dauert."
        },
        "payments": {
          "directPayment": "Sie können sicher mit {provider} bezahlen:",
          "creditCard": "Kreditkarte",
          "blik": "Blik",
          "onlineTransfer": "Online-Überweisung",
          "onlineApplication": "Online-Bewerbung",
          "mobilePayments": "Mobile Zahlungen",
          "payLater": "Später bezahlen"
        },
        "refundRestrictions": "Stornierungsbedingungen für Besuche",
        "refundRestrictionsAccept": "Ich akzeptiere die Stornierungsbedingungen",
        "supervisorDoctor": "Besuch beim Vorgesetzten",
        "supervisorDoctorAbbr": "Aufsicht",
        "doctorPackageDescription": "Das Ärztepaket ist ein Besuch mit Beteiligung von 2 oder mehr Ärzten. Der den Besuch durchführende Arzt führt alle mit dem Besuch verbundenen Aktivitäten durch. Der beobachtende Arzt beobachtet den Besuch und kann klinische Diskussionen mit dem behandelnden Arzt und dem Patienten führen.",
        "specialist": "Facharzt für"
      },
      "paymentOutcome": {
        "title": "Zahlungsbestätigung",
        "loading": "Zahlungsdaten werden geladen",
        "status": {
          "title": "Status",
          "types": {
            "pending": {
              "title": "Zahlungsabwicklung läuft",
              "subtitle": "Die Zahlung ist noch nicht bestätigt",
              "comment": "Bitte warten Sie einen Moment, während die Zahlung abgeschlossen ist"
            },
            "success": {
              "title": "Service wurde bezahlt",
              "subtitle": "Die Zahlung wurde bestätigt",
              "comment": "Vielen Dank für die Zahlung"
            },
            "error": {
              "title": "Service wurde nicht bezahlt",
              "subtitle": "Die Zahlung wurde storniert",
              "comment": "Bitte wiederholen Sie den Zahlungsvorgang"
            }
          }
        },
        "details": {
          "title": "Einzelheiten",
          "date": "Datum",
          "name": "Name des Dienstes",
          "doctor": "Arzt"
        }
      },
      "visitPaymentOutcome": {
        "title": "Besuchen Sie die Reservierung",
        "subtitle": "Bestätigung",
        "details": "Einzelheiten",
        "loading": "Besuchsdaten werden geladen...",
        "card": {
          "successTitle": "Ihr Besuch wurde bezahlt",
          "successLabel1": "Vielen Dank für eine Zahlung",
          "successLabel1Package": "Danke schön! Sie sind gut aufgehoben!",
          "successLabel2": "Über einen Besuch werden Sie 24 Stunden vorher benachrichtigt",
          "failTitle": "Ihr Besuch wurde nicht bezahlt",
          "failLabel1": "Bei einem Zahlungsvorgang ist ein Fehler aufgetreten",
          "retryPayment": "Wiederholen Sie die Zahlung",
          "toGoMessageView": "Gehen Sie zur Nachrichtenansicht",
          "paymentPending": "Ihre Zahlung wurde noch nicht bestätigt. Es kann bis zu 1 Minute dauern",
          "paymentConfirmed": "Die Zahlung wurde bestätigt!"
        },
        "goHome": "Gehen Sie zur Hauptseite",
        "visitScope": "Umfang des Besuchs",
        "scopeBullets": {
          "visit0Bullet1": "<b>Vorbereitet und vorbereitet</b> 1 Besuch beim Neurosphery Clinical Specialist Doctor",
          "visit0Bullet2": "Es handelt sich um <b>eine ausführliche Befragung Ihrer Krankengeschichte</b>",
          "visit0Bullet3": "Die Leitung übernimmt eine <b>medizinisch qualifizierte Person</b>, die wir individuell für Sie auswählen",
          "visit0Bullet4": "<b>Bereiten Sie sich darauf vor.</b> Erinnern Sie sich daran, welche Medikamente eingenommen wurden, wie oft Anfälle auftreten, wie sie verlaufen und was ihre Natur ist",
          "visit0Bullet5": "Die Befragungsperson 0 erstellt eine digitale Dokumentation Ihres Falles in Form eines strukturierten Interviews. Auf diese Weise organisierte digitale Daten können von unserem Ictal-Roboter analysiert werden, um während der Visite 1 eine Diagnose und Therapie vorzuschlagen",
          "visit0Bullet6": "Dauer <b>bis zu 60 Minuten</b>",
          "visit0Bullet7": "Sie können die Art des Besuchs wählen – <b>online oder stationär</b>",
          "migrationVisitBullet1": "Gedacht für Patienten, die vor 2021 in unserer Obhut waren oder stehen",
          "migrationVisitBullet2": "Überprüfung der medizinischen, biometrischen und persönlichen Daten des Patienten",
          "migrationVisitBullet3": "Übergang zu einem neuen Pflegesystem",
          "visit1Bullet1": "Es ist ein Besuch beim Neurosphären-Klinikspezialisten Ihrer Wahl",
          "visit1Bullet2": "Der Arzt sieht die Ergebnisse der Umfrage 0, die Ergebnisse der Analyse unseres Ictal-Roboters und die von Ihnen beigefügten Materialien, die es ihm ermöglichen, die Diagnose und Therapie wirksam zu bestätigen",
          "visit1Bullet3": "Der Arzt wird Ihnen bei Bedarf zusätzliche Untersuchungen anbieten",
          "visit1Bullet4": "Der Arzt wird Ihnen einen umfassenden Pflegeplan anbieten",
          "visit1Bullet5": "Dauer <b>bis zu 30 Minuten</b>",
          "visit1Bullet6": "Sie können die Art des Besuchs wählen – <b>online oder stationär</b>",
          "standardVisitBullet1": "Dauer von 30 bis 45 Minuten",
          "standardVisitBullet2": "Festlegung der endgültigen Diagnose",
          "standardVisitBullet3": "Ständige Analyse der im ICtal®-System gesammelten Daten",
          "standardVisitBullet4": "Möglichkeit einzugreifen, wenn keine Verbesserung oder Verschlechterung eintritt – Beratung durch ICtal® oder den Rat",
          "consultationBullet1": "Dauer von 45 bis 60 Minuten",
          "consultationBullet2": "Besuchen Sie einen Arzt anderer Fachrichtung",
          "consultationBullet3": "Krankengeschichte",
          "consultationBullet4": "Analyse der Vorgeschichte von Epilepsie und Komorbiditäten",
          "consultationBullet5": "Beratung medizinische Beratung in der Fachrichtung des Konsiliararztes",
          "messageBullet1": "Denken Sie daran, dass es sich bei einer Nachricht um eine kurze Textfrage handelt, die der Arzt während seiner Arbeitszeit so schnell wie möglich beantwortet.",
          "messageBullet2": "Im Rahmen der Gebühr können Sie sich dafür entscheiden, nur eine Nachricht zu senden – eine Frage. Wenn der Arzt antwortet, haben Sie die Möglichkeit, eine weitere Folgefrage zu stellen, auf die der Arzt erneut antwortet und der Thread automatisch endet.",
          "messageBullet3": "Aufmerksamkeit! Der Arzt hat das Recht, die Beratung im Rahmen der Nachricht zu verweigern, wenn er der Ansicht ist, dass Ihre Anfrage einen regulären Besuch erfordert. Die Nachricht ist nicht dazu gedacht, in Notfällen oder lebensbedrohlichen Situationen zu helfen!"
        }
      },
      "neuroscreen": {
        "main": {
          "title": "Die Neurosphera-Klinik schafft eine neue Qualität in der Patientenbehandlung!",
          "subtitle1": "Wir verbinden Wissen und langjährige Erfahrung wirkungsvoll mit dem Anspruch einer ständigen Weiterentwicklung und Verbesserung unserer Behandlungsmethoden!",
          "subtitle2": "Wir haben für Sie eine Umfrage vorbereitet, die Ihnen bei der Auswahl des richtigen Arztes und des richtigen Behandlungsplans in der Neurosphera-Klinik hilft.",
          "subtitle3": "Durch das Ausfüllen des Fragebogens kann der Arzt ihn vor dem Besuch lesen und weiß, wegen welchem Problem Sie sich melden.",
          "subtitle4": "Das Ausfüllen der Umfrage dauert etwa 5 Minuten.",
          "fillNeuroscreen": "Füllen Sie die Umfrage aus",
          "fillNeuroscreenDescription": "Ich möchte die Umfrage ausfüllen und den Behandlungsplan des Patienten eingeben",
          "goToReservation": "Arzt auswählen",
          "goToReservationDescription": "Ich möchte die Umfrage nicht ausfüllen, sondern sofort einen Arzt auswählen"
        },
        "survey": {
          "title": "Neuroscreen - Neurosphera-Patientenbefragung",
          "subtitle1": "Nach Abschluss der Befragung teilen wir Ihnen mit, für welchen Besuch und Arzt Sie einen Termin vereinbaren sollten.",
          "subtitle2": "Die hier von Ihnen angegebenen Informationen werden an Ihren Arzt weitergegeben und helfen ihm bei der richtigen Diagnose und Behandlung.",
          "next": "Nächste",
          "prev": "Zurück",
          "save": "Umfrage abschließen",
          "form": {
            "section": {
              "HEADACHES": "Kopfschmerzen",
              "MOVEMENT_ISSUES": "Bewegungsstörungen",
              "MEMORY_ISSUES": "Gedächtnisstörungen",
              "EATING_ISSUES": "Essstörungen",
              "CONSCIOUSNESS_ISSUES": "Bewusstseinsstörungen oder Bewusstseinsstörungen",
              "PSYCHOMOTOR_DEVELOPMENT_ISSUES": "Psychomotorische Entwicklungsstörungen",
              "EPILEPSY": "Epilepsie und Krampfanfälle",
              "SPINE_ISSUES": "Wirbelsäulenprobleme",
              "MUTIPLE_SCLEROSIS": "Multiple Sklerose",
              "DIZZINESS": "Schwindel",
              "AUTISM": "Autismus-Spektrum",
              "OTHER_SYMPTOMS": "Andere Symptome"
            },
            "adult": {
              "field": {
                "headachesGeneral": "Leiden Sie unter Kopfschmerzen?",
                "headaches1": "Leiden Sie ständig unter dumpfen Kopfschmerzen?",
                "headaches2": "Leiden Sie unter pochenden Schmerzen, meist auf einer Seite Ihres Kopfes?",
                "headaches3": "Gehen Ihre Kopfschmerzen mit Licht- oder Geräuschempfindlichkeit, Übelkeit oder Erbrechen einher?",
                "headaches4": "Leiden Sie vor dem Beginn der Kopfschmerzen unter Sehstörungen wie Lichtblitzen oder Punkten vor den Augen?",
                "headaches5": "Leiden Sie bei Kopfschmerzen unter roten Augen, tränenden Augen oder geschwollenen Augenlidern auf der schmerzenden Seite oder unter einer verstopften Nase?",
                "movementGeneral": "Haben Sie ein Zittern in Ihren Gliedmaßen, Muskelsteifheit oder Bewegungsschwierigkeiten bemerkt?",
                "movement1": "Leiden Sie unter Zittern in den Händen oder anderen Körperteilen?",
                "movement2": "Wird der Tremor in Ruhe schlimmer?",
                "movement3": "Spüren Sie eine Steifheit in Ihren Muskeln, die es Ihnen schwer macht, in Bewegung zu kommen?",
                "movement4": "Haben Sie eine Verlangsamung Ihrer täglichen Aktivitäten bemerkt?",
                "movement5": "Haben Sie Probleme beim Gehen bemerkt, z. B. kleine Schritte oder unerwartete Stürze?",
                "memoryIssuesGeneral": "Haben Sie Schwierigkeiten, sich an neue Informationen zu erinnern, Wörter zu finden oder sich räumlich zu orientieren?",
                "memoryIssues1": "Fällt es Ihnen schwer, sich an aktuelle Ereignisse zu erinnern?",
                "memoryIssues2": "Vergessen Sie manchmal Namen oder Daten oder haben Sie beim Sprechen Schwierigkeiten, die richtigen Worte zu finden?",
                "memoryIssues3": "Benötigen Sie zunehmend Hilfe bei alltäglichen Aktivitäten wie der Bedienung von Haushaltsgeräten, der Verwaltung von Finanzen oder Planungen?",
                "memoryIssues4": "Fällt es Ihnen schwer, sich an Orten zu orientieren, die Sie bereits kennen?",
                "memoryIssues5": "Bemerken Sie Veränderungen in Ihrem Verhalten, wie etwa sozialen Rückzug, Apathie, Impulsivität oder mangelndes Taktgefühl?",
                "epilepsyGeneral": "Haben Sie schon einmal das Bewusstsein verloren, unkontrollierbare Krämpfe gehabt oder waren Sie kurzzeitig außer Gefecht gesetzt?",
                "epilepsy1": "Leiden Sie unter plötzlichen, unerklärlichen Blackouts?",
                "epilepsy2": "Fühlen Sie sich verwirrt, wenn Sie das Bewusstsein verlieren, oder fällt es Ihnen schwer, sofort zu Ihren normalen Aktivitäten zurückzukehren?",
                "epilepsy3": "Gehen Ohnmachtsanfälle mit Krämpfen einher?",
                "epilepsy4": "Spüren Sie vor dem Bewusstseinsverlust/den Krämpfen bestimmte Gefühle oder Signale (z. B. „Aura“)?",
                "epilepsy5": "Gibt es Momente, in denen Sie plötzlich nicht mehr reagieren und für kurze Zeit „einfrieren“?",
                "spineIssuesGeneral": "Leiden Sie unter chronischen oder akuten Rücken- oder Nackenschmerzen?",
                "spineIssues1": "Leiden Sie unter chronischen Rücken- oder Nackenschmerzen?",
                "spineIssues2": "Leiden Sie unter Taubheitsgefühlen in Armen oder Beinen?",
                "spineIssues3": "Haben Sie einen Kraftverlust in Ihren Armen oder Beinen bemerkt?",
                "spineIssues4": "Haben Sie Schwierigkeiten, Ihre Blase oder Ihren Darm zu kontrollieren?",
                "spineIssues5": "Strahlt der Schmerz von der Wirbelsäule in andere Körperteile aus?",
                "mutipleSclerosisGeneral": "Haben Sie ein Kribbeln, Taubheitsgefühl oder andere Veränderungen der Sinnesempfindungen bemerkt, die in verschiedenen Körperteilen auftreten können?",
                "mutipleSclerosis1": "Leiden Sie unter unerwarteter Schwäche in Ihren Gliedmaßen oder Schwierigkeiten beim Gehen?",
                "mutipleSclerosis2": "Haben Sie Probleme mit dem Gleichgewicht oder der Koordination?",
                "mutipleSclerosis3": "Leiden Sie unter plötzlich auftretenden Sehstörungen, wie Doppeltsehen oder verschwommenem Sehen?",
                "mutipleSclerosis4": "Leiden Sie unter ungewöhnlicher Müdigkeit, die nicht mit körperlicher Betätigung zusammenhängt?",
                "mutipleSclerosis5": "Leiden Sie unter Muskelschmerzen und -krämpfen?",
                "dizzinessGeneral": "Leiden Sie unter Schwindel oder Gleichgewichtsstörungen?",
                "dizziness1": "Tritt der Schwindel plötzlich und ohne Vorwarnung auf?",
                "dizziness2": "Leiden Sie bei Schwindel auch unter Übelkeit oder Erbrechen?",
                "dizziness3": "Wird der Schwindel schlimmer, wenn Sie Ihre Körperhaltung ändern, zum Beispiel aufstehen oder den Kopf neigen?",
                "dizziness4": "Hängt das Auftreten von Schwindel mit Ohrenschmerzen oder Hörveränderungen zusammen?",
                "dizziness5": "Geht Schwindel mit Tinnitus oder einem Völlegefühl in den Ohren einher?",
                "otherSymptomsGeneral": "Leiden Sie unter weiteren Symptomen, die nicht in die oben genannten Kategorien passen?",
                "otherSymptoms1": "Leiden Sie unter Schluckbeschwerden oder verschlucken Sie sich häufig beim Essen?",
                "otherSymptoms2": "Leiden Sie unter Schlafstörungen wie etwa Schlaflosigkeit oder übermäßiger Tagesmüdigkeit?",
                "otherSymptoms3": "Leiden Sie zeitweise unter Konzentrationsschwierigkeiten oder einem „Gehirnnebel“, der Sie daran hindert, sich auf Aufgaben zu konzentrieren?",
                "otherSymptoms4": "Haben Sie bemerkt, dass Ihr Schnarchen im Schlaf beginnt oder schlimmer wird?",
                "otherSymptoms5": "Spüren Sie manchmal eine plötzliche Schwäche in einem Teil oder auf einer Seite Ihres Körpers?"
              }
            },
            "child": {
              "field": {
                "headachesGeneral": "Leidet Ihr Kind unter Kopfschmerzen?",
                "headaches1": "Leidet Ihr Kind unter ständigen, dumpfen Kopfschmerzen?",
                "headaches2": "Ist es ein pochender Schmerz, normalerweise einseitig?",
                "headaches3": "Gehen die Schmerzen mit Überempfindlichkeit gegenüber Licht und Geräuschen, Übelkeit und Erbrechen einher?",
                "headaches4": "Hat das Kind Sehstörungen, wie etwa Lichtblitze oder Punkte vor den Augen, bevor die Kopfschmerzen beginnen?",
                "headaches5": "Leiden Sie bei Kopfschmerzen unter roten Augen, tränenden Augen oder geschwollenen Augenlidern auf der schmerzenden Seite oder unter einer verstopften Nase?",
                "movementGeneral": "Leidet Ihr Kind unter Bewegungsstörungen: Zittern, Steifheit, Lähmungen?",
                "movement1": "Leidet Ihr Kind unter Zittern?",
                "movement2": "Tritt dieser Tremor auch im Ruhezustand auf?",
                "movement3": "Tritt dieses Zittern während der Bewegung auf?",
                "movement4": "Leiden Sie bei Ihrem Kind unter zunehmender Steifheit der Gliedmaßen?",
                "movement5": "Macht Ihr Kind in seiner motorischen Entwicklung keine Fortschritte oder ist es rückläufig?",
                "epilepsyGeneral": "Hat Ihr Kind Anfälle?",
                "epilepsy1": "Treten die Anfälle wieder auf?",
                "epilepsy2": "Sind die Anfälle unterschiedlich oder ähnlich?",
                "epilepsy3": "Treten Anfälle im Wachzustand auf?",
                "epilepsy4": "Treten die Anfälle während des Schlafs (nachts oder tagsüber) auf?",
                "epilepsy5": "Sind die Anfälle generalisiert?",
                "epilepsy6": "Sind die Anfälle fokal, also nicht auf den ganzen Körper bezogen, sondern betreffen z. B. ein oder zwei Gliedmaßen oder eine Körperhälfte?",
                "epilepsy7": "Sind die Anfälle rhythmisch?",
                "consciousnessIssuesGeneral": "Leidet das Kind unter Bewusstseinsstörungen und/oder Bewusstlosigkeit?",
                "consciousnessIssues1": "Leidet das Kind unter Bewusstseinsstörungen?",
                "consciousnessIssues2": "Erleidet das Kind bei einem Sturz eine völlige Bewusstlosigkeit?",
                "consciousnessIssues3": "Leidet Ihr Kind unter Komplexen oder Gedanken?",
                "consciousnessIssues4": "Dauern Bewusstseinszustände einige Sekunden?",
                "consciousnessIssues5": "Dauern Bewusstseinszustände länger als 30 Sekunden?",
                "eatingIssuesGeneral": "Leidet Ihr Kind unter einer Essstörung?",
                "eatingIssues1": "Leidet Ihr Kind unter krankhaftem Appetitmangel?",
                "eatingIssues2": "Leidet Ihr Kind unter krankhaft übermäßigem Appetit?",
                "autismGeneral": "Weist das Kind Merkmale einer Autismus-Spektrum-Störung auf oder liegt eine Diagnose dieser vor?",
                "autism1": "Leidet das Kind unter einer Autismus-Spektrum-Störung?",
                "autism2": "Besteht bei dem Kind der Verdacht auf Autismus?",
                "psychomotorDevelopmentIssuesGeneral": "Hat das Kind Störungen der psychomotorischen Entwicklung?",
                "psychomotorDevelopmentIssues1": "Wird eine Störung der psychomotorischen Entwicklung von einem Arzt/medizinischen Zentrum diagnostiziert und bestätigt?",
                "psychomotorDevelopmentIssues2": "Wird eine Störung der psychomotorischen Entwicklung lediglich vermutet und nicht bestätigt?"
              }
            }
          }
        },
        "rating": {
          "title": "Vielen Dank für die Teilnahme an der Umfrage!",
          "subtitle": "Das Neuroterminal-System nennt Ihnen den richtigen Arzt für Ihre Erkrankung",
          "showMore": "Mehr anzeigen",
          "diagnosis": {
            "HEADACHES": "<b>Kopfschmerzen sind ein komplexes neurologisches Problem,</b> das Dutzende verschiedener Formen und Intensitäten annehmen kann. <b>Diese Beschwerden können ein Symptom vieler Krankheiten sein,</b> nicht nur im Bereich der Neurologie, sondern auch in anderen Bereichen der Medizin. Es ist entscheidend, dass die Diagnose und Behandlung von Kopfschmerzen von einem Neurologen durchgeführt wird – einem Spezialisten für Kephalologie, einer Wissenschaft, die sich diesem Thema widmet. <br/><b>Das Neurosphera Center geht die Diagnose und Behandlung von Kopfschmerzen individuell und im Team an.</b>",
            "MOVEMENT_ISSUES": "<b>Bewegungsstörungen sind eine Gruppe neurologischer Störungen, die sich in verschiedenen Bewegungsproblemen äußern.</b> Dies können Muskelschwäche und -paresen sowie Zittern, Steifheit oder Spastik sein. Dieses breite Spektrum an Symptomen ist das Ergebnis von Schäden oder Störungen in der Funktion des Nervensystems. <b>Es ist wichtig, bei besorgniserregenden Symptomen einen Spezialisten aufzusuchen,</b> denn eine frühzeitige Diagnose und eine geeignete Behandlung sind der Schlüssel zur Verbesserung der Lebensqualität des Patienten. <br/><b>Neurosphera ist in der Lage, die Ursache der Probleme genau zu diagnostizieren und geeignete Behandlungsmethoden auszuwählen.</b>",
            "MEMORY_ISSUES": "<b>Gedächtnisstörungen werden oft mit Demenz in Verbindung gebracht, wo sie eines der Hauptsymptome sind.</b> Gedächtnisprobleme können jedoch in jedem Alter auftreten und ein Anzeichen für verschiedene Krankheiten sein, von leicht bis schwerwiegend. Deshalb ist es so wichtig, <b>bei besorgniserregenden Symptomen einen Spezialisten aufzusuchen</b>, der die Gedächtnisfunktion umfassend beurteilt und mögliche Ursachen diagnostiziert. <br/><b>Im Neurosphera Center hat der Patient die Möglichkeit, einen Neuropsychologen zu wählen, der entsprechende Tests durchführt und die Krankheitsursache diagnostiziert, sowie einen Neurologen, der die geeignete Therapie für den Patienten auswählt.</b>",
            "EATING_ISSUES": "<b>Essstörungen sind komplexe Gesundheitsprobleme, die einen umfassenden Ansatz erfordern.</b> An ihrer Behandlung sind verschiedene Spezialisten wie Diätassistenten, Neurologen und Psychiater beteiligt. Obwohl Essstörungen oft ein Symptom tiefer liegender geistiger oder körperlicher Probleme sind, <b>können eine richtig ausgewählte Ernährung und Ernährungstherapie ein wichtiger Bestandteil der Behandlung sein</b> und sich sowohl auf die körperliche als auch auf die geistige Gesundheit des Patienten auswirken. <br/><b>Das Neurosphera Center bietet die Möglichkeit, das Problem der Essstörungen von einem Neurologen oder Diätspezialisten diagnostizieren zu lassen, der eine gut abgestimmte Diät für den Patienten erstellt.</b>",
            "CONSCIOUSNESS_ISSUES": "<b>Bewusstseins- und Wahrnehmungsstörungen sind Zustände, die immer Anlass zur Sorge geben und dringend eine Konsultation mit einem Neurologen erforderlich machen sollten.</b> Sie sind oft das erste Anzeichen einer schweren neurologischen Erkrankung oder einer anderen schwerwiegenden Gesundheitsstörung. Um die Ursache dieser Beschwerden genau zu bestimmen, <b>müssen zusätzliche Tests wie EEG (Elektroenzephalographie) und MRT (Magnetresonanztomographie) durchgeführt werden.</b> Diese Diagnosemethoden ermöglichen die Beurteilung der elektrischen Aktivität des Gehirns und eine detaillierte Visualisierung seiner Strukturen, was für die korrekte Diagnose und die Durchführung der entsprechenden Behandlung von entscheidender Bedeutung ist. <br/><b>Das Neurosphera Center geht Diagnostik und Therapie individuell und im Team an. Die Klinik bietet die Möglichkeit, EEG-Tests durchzuführen.</b>",
            "PSYCHOMOTOR_DEVELOPMENT_ISSUES": "<b>Psychomotorische Entwicklungsstörungen bei Kindern sind ein komplexes klinisches Problem, das einen fachspezifischen und umfassenden Ansatz erfordert.</b> Aufgrund der Vielzahl der Symptome und möglichen Ursachen sollten Diagnose und Therapie von einem multidisziplinären Team von Spezialisten durchgeführt werden. <br/><b>Das Neurosphera Center bietet eine umfassende Betreuung und ermöglicht eine breite Palette von Tests und Konsultationen mit verschiedenen Spezialisten.</b> Dies ermöglicht eine genaue Bestimmung der Ursachen von Störungen und die Umsetzung eines geeigneten Therapieprogramms. Eine frühzeitige Diagnose und Therapie sind für die optimale Entwicklung des Kindes von entscheidender Bedeutung.",
            "EPILEPSY": "<b>Die Behandlung von Epilepsie im Neurosphera-Zentrum ist unsere Spezialität.</b> Wir verfügen über 25 Jahre Erfahrung in der Diagnose und Therapie dieser Krankheit, was uns zu einem der führenden Zentren in Polen macht. <b>Unser Team, bestehend aus 20 Spezialisten, verwendet die neuesten Diagnoseinstrumente,</b> darunter fortschrittliche Systeme auf Basis künstlicher Intelligenz, um Patienten eine umfassende Betreuung zu bieten. Dank dessen können wir selbst die komplexesten Fälle von Epilepsie effektiv diagnostizieren und behandeln und unseren Patienten den höchsten Standard der medizinischen Versorgung bieten.",
            "SPINE_ISSUES": "Rücken- und Wirbelsäulenschmerzen sind ein Problem, das viele Menschen betrifft und verschiedene Ursachen haben kann. <b>Diese Erkrankung erfordert einen umfassenden Ansatz, der das Wissen und die Erfahrung von Neurologen und Neurochirurgen kombiniert.</b> <br/><b>Im Neurosphera Center verfügen wir über ein spezialisiertes Team, das sich mit der Diagnose und Behandlung dieser Art von Beschwerden befasst.</b> Dank des umfassenden Wissens und der Erfahrung unserer Spezialisten und der Verfügbarkeit moderner Diagnosemethoden sind wir in der Lage, die Schmerzursache genau zu bestimmen und eine optimale Behandlung vorzuschlagen. <b>Unser multidisziplinärer Ansatz ermöglicht eine umfassende Beurteilung des Patienten und die Entwicklung eines individuellen Therapieplans.</b>",
            "MUTIPLE_SCLEROSIS": "<b>Multiple Sklerose ist eine chronische neurologische Erkrankung, deren Verlauf sehr individuell ist und von Patient zu Patient erheblich variieren kann.</b> Die Diagnose von MS ist aufgrund der Vielzahl der Symptome, die denen anderer Krankheiten ähneln können, oft eine Herausforderung. <b>Eine wirksame Behandlung von Multipler Sklerose erfordert einen spezialisierten Ansatz und die Zusammenarbeit vieler Spezialisten.</b> Die Therapie sollte auf die individuellen Bedürfnisse jedes Patienten zugeschnitten sein und sowohl medikamentöse als auch nicht-medikamentöse Behandlungen umfassen. <b>Für eine korrekte Diagnose und umfassende Therapie von Menschen mit MS ist ein Team erfahrener Neurologen unerlässlich.</b>",
            "DIZZINESS": "<b>Schwindel ist ein Zustand, der meistens ein Symptom und keine eigenständige Krankheit ist.</b> Er kann verschiedene Ursachen haben, sowohl laryngologische (z. B. Gleichgewichtsstörungen) als auch neurologische (z. B. Schäden am zentralen Nervensystem). <br/><b>Patienten mit Schwindel gehen häufig zu einem Neurologen, und wir im Neurosphera Center sind bestens darauf vorbereitet, solche Patienten aufzunehmen.</b> Wir bieten umfassende Diagnostik an, die eine genaue Bestimmung der Ursache der Symptome ermöglicht. <b>In einigen Fällen kann es notwendig sein, einen Neurochirurgen aufzusuchen, insbesondere wenn der Schwindel mit Wirbelsäulenproblemen zusammenhängt.</b> Dank unseres multidisziplinären Ansatzes sind wir in der Lage, unseren Patienten wirksam zu helfen.",
            "AUTISM": "<b>Autismus-Spektrum-Störung ist eine komplexe neurologische Entwicklungsstörung, die Schwierigkeiten mit sozialer Kommunikation, sozialen Interaktionen sowie eingeschränkte und sich wiederholende Verhaltensmuster umfasst.</b> Autismus kann viele Formen annehmen, von leicht bis schwer, und tritt häufig zusammen mit anderen Störungen auf. <b>Sowohl typischer als auch atypischer Autismus im Kindesalter erfordern eine fachärztliche Diagnose und einen umfassenden therapeutischen Ansatz.</b> <br/><b>Im Neurosphera Center verfügen wir über ein erfahrenes Team von Spezialisten, die sich mit der Diagnose und Behandlung von Autismus und seinen Komplikationen befassen.</b> Wir bieten eine breite Palette von Dienstleistungen an, die auf die individuellen Bedürfnisse jedes Patienten zugeschnitten sind.",
            "OTHER_SYMPTOMS": "<b>Neurologische Symptome können äußerst vielfältig und oft mehrdeutig sein.</b> Neben typischeren Symptomen wie Taubheitsgefühlen oder Gleichgewichtsstörungen berichten Patienten auch von Schwäche, Schmerzen, Schlafproblemen, Reizbarkeit und Konzentrationsschwierigkeiten. <b>Dieses breite Spektrum an Symptomen kann eine genaue Diagnose zu einer Herausforderung machen, da viele von ihnen auf verschiedene neurologische Erkrankungen und manchmal sogar auf Erkrankungen mit unterschiedlicher Ursache hinweisen können.</b> <br/><b>Das Neurosphera Center verfügt über spezielle Geräte und ein erfahrenes Team,</b> dank dessen wir in der Lage sind, genaue Diagnosen durchzuführen und die Ursache selbst der unklarsten neurologischen Symptome zu ermitteln."
          }
        }
      }
    },
    "error": {
      "LOGIN_CRED_MISSING": "Login und Passwort sind erforderlich",
      "LOGIN_INVALID_CRED": "Ungültiger Benutzername oder Passwort",
      "PASS_AND_CONF_NOT_EQUAL": "Passwort und Bestätigung unterscheiden sich",
      "AUTHORIZATION": "Unbefugter Zugriff auf die Ressource, Weiterleitung zur Anmeldeseite",
      "WRONG_PROFILE": "Die Anmeldung ist fehlgeschlagen, weil das falsche Profil ausgewählt wurde"
    },
    "validation": {
      "required": "Feld ist erforderlich",
      "alphanumeric": "Darf nur Buchstaben und Ziffern enthalten",
      "email": "Ungültige E-Mail-Adresse",
      "invalidRange": "Ungültiger Bereich",
      "nip": "Ungültige NIP-Nummer",
      "regon": "Ungültige REGON-Nummer",
      "pesel": "Ungültige PESEL-Nummer",
      "max": "Bis zu {length} Zeichen zulässig",
      "min": "Mindestens {length} Zeichen erforderlich",
      "passNonMatching": "Passwörter stimmen nicht überein",
      "maxFileSize": "Die Datei sollte weniger als {size} MB haben",
      "pwz": "Ungültige PWZ-Nummer",
      "zusCertificate": "Die zulässige Dateierweiterung ist .pfx",
      "password": {
        "lowercase": "Kleinbuchstabe erforderlich",
        "uppercase": "Großbuchstabe erforderlich",
        "minimumChar": "Mindestens {length} Zeichen",
        "numericChar": "Eine Nummer erforderlich",
        "specialChar": "Sonderzeichen erforderlich, verfügbare Zeichen sind !@$\\-_.*"
      }
    },
    "office": {
      "phone": "+48 609 463 191",
      "phoneHref": "tel:+48609463191",
      "mail": "biuro@neurosphera.pl",
      "mailHref": "mailto:biuro@neurosphera.pl"
    },
    "appointWidget": {
      "appoint": {
        "title": "Vereinbaren Sie einen Besuch",
        "visitTypeLabel": "Besuchstyp",
        "visitLocationLabel": "Standort",
        "doctorListDisclaimer": "Der Endpreis hängt von der Art des Besuchs ab",
        "visitTypeListDisclaimer": "Der Endpreis hängt von der Art des Besuchs ab",
        "doctorListHeader": "Arzt wählen",
        "visitTypeListHeader": "Wählen Sie den Besuchstyp",
        "visitLocationListHeader": "Wählen Sie den Besuchsort",
        "doctorLabel": "Arzt",
        "startingPricePrefix": "aus",
        "clearSelection": "Klare Auswahl",
        "visitOrder": "Ist dies Ihr erster Besuch bei diesem Spezialisten?",
        "visitOrderFirst": "Ja",
        "visitOrderNext": "NEIN",
        "appointment": {
          "selectHeader": {
            "START_PACKAGE": "Planen Sie Besuch 0 und Besuch 1 – Epilepsie",
            "ADHD_PACKAGE": "Planen Sie Besuche im ADHS-Paket",
            "REGULAR_VISIT_EEG_PACKAGE": "Planen Sie einen regelmäßigen Epilepsiebesuch und ein EEG ein",
            "MIGRATION_VISIT": "Planen Sie einen Migrationsbesuch",
            "STANDARD_VISIT": "Planen Sie einen regelmäßigen Besuch ein – Epilepsie",
            "NEUROSURGEON_CONSULTATION": "Vereinbaren Sie eine neurochirurgische Beratung",
            "NEUROPSYCHOLOGIST_CONSULTATION": "Vereinbaren Sie eine neuropsychologische Beratung",
            "NEUROLOGOPEDIST_CONSULTATION": "Vereinbaren Sie eine logopädische (neurologische) Beratung",
            "PSYCHIATRIST_CONSULTATION": "Vereinbaren Sie eine psychiatrische Beratung",
            "GENETICIST_CONSULTATION": "Vereinbaren Sie eine genetische Beratung",
            "EPILEPTOLOGIST_CONSULTATION": "Vereinbaren Sie eine epileptologische Beratung",
            "EEG_CONSULTATION": "Planen Sie eine EEG-Untersuchung",
            "NEUROLOGIST_CONSULTATION": "Vereinbaren Sie eine neurologische Beratung",
            "DIETICIAN_CONSULTATION": "Vereinbaren Sie eine Ernährungsberatung",
            "VNS_CONSULTATION": "Vereinbaren Sie einen VNS-Besuch",
            "ABSENT_CONSULTATION": "Vereinbaren Sie einen Beratungstermin in Abwesenheit",
            "METABOLIC_CONSULTATION": "Vereinbaren Sie eine Stoffwechselberatung",
            "SLEEP_DISTURBANCE_CONSULTATION": "Vereinbaren Sie eine Beratung zu Schlafstörungen"
          },
          "description": {
            "START_PACKAGE": "Besuch 0 ist ein kostenloser Besuch, bei dem vor Besuch 1 ein detailliertes Gespräch über die Krankengeschichte des Patienten geführt wird. Während Besuch 1 werden eine Diagnose, eine Therapie und ein umfassender Pflegeplan für das nächste Jahr erstellt. Besuch 0 dauert ca. 1 Stunde, Besuch 1 - 30 Minuten; beides kann vor Ort oder online stattfinden.",
            "ADHD_PACKAGE": "Das Paket beinhaltet 2 Besuche bei einem Psychologen (jeweils 1,5 Stunden) und einen Besuch bei einem Neurologen oder einen Besuch bei einem Psychiater. Das Paket richtet sich an Personen, die Folgendes bemerken:",
            "REGULAR_VISIT_EEG_PACKAGE": "Das Paket beinhaltet einen EEG-Test und einen regelmäßigen stationären Besuch. Das Paket richtet sich an aktuelle Neurosphärenpatienten, die Visit 1 oder Migration Visit bereits abgeschlossen haben. Melden Sie sich für ein Paket an, wenn:",
            "MIGRATION_VISIT": "Migrationsbesuch",
            "STANDARD_VISIT": "Der regelmäßige epileptologische Besuch ist für aktuelle Neurosphera-Patienten gedacht, die bereits Besuch 1 oder Migrationsbesuch hatten.",
            "NEUROSURGEON_CONSULTATION": "Bei chronischen neurologischen Problemen empfiehlt sich eine Beratung, wie zum Beispiel:",
            "NEUROPSYCHOLOGIST_CONSULTATION": "Angezeigt im Fall von:",
            "NEUROLOGOPEDIST_CONSULTATION": "Logopädische (neurologische) Beratung",
            "PSYCHIATRIST_CONSULTATION": "Ein Besuch für Menschen, die mit psychischen Problemen zu kämpfen haben, darunter:",
            "GENETICIST_CONSULTATION": "Angezeigt im Fall von:",
            "EPILEPTOLOGIST_CONSULTATION": "Beratung - Epileptologe",
            "EEG_CONSULTATION": "Basisuntersuchung zur Epilepsiediagnostik, außerdem:",
            "NEUROLOGIST_CONSULTATION": "Angezeigt im Fall von:",
            "DIETICIAN_CONSULTATION": "Besuch für Leute:",
            "VNS_CONSULTATION": "VNS-Besuch",
            "ABSENT_CONSULTATION": "Beratung in Abwesenheit",
            "METABOLIC_CONSULTATION": "Angezeigt bei angeborenen Stoffwechselstörungen. Einige Stoffwechselerkrankungen können zur Entstehung einer Epilepsie beitragen. Es lohnt sich abzuklären, ob in genetischen Tests ein Verdacht auf die Erkrankung besteht.",
            "SLEEP_DISTURBANCE_CONSULTATION": "Angezeigt im Fall von:",
            "MMPI2_PACKAGE": "Wir ermöglichen die Diagnose von Persönlichkeitsstörungen mithilfe des umfassendsten und universellsten klinischen Bewertungsinstruments, das in Polen verfügbar ist – dem Minnesota Multidimensional Personality Inventory (die vollständige Adaption des MMPI-2 wurde 2012 veröffentlicht).",
            "CBT_PACKAGE": "Die kognitive Verhaltenstherapie (CBT) gegen Schlaflosigkeit gilt als Erstbehandlung für Menschen, die unter Schlaflosigkeit leiden, und wird von Behörden wie den National Institutes of Health (NIH), dem National Institute for Health and Care Excellence (NICE) und dem American College of Physician empfohlen."
          },
          "bulletPoints": {
            "ADHD_PACKAGE": [
              "Difficulty Organizing",
              "Underestimating time",
              "interpersonal difficulties",
              "Emotional difficulties"
            ],
            "REGULAR_VISIT_EEG_PACKAGE": [
              "You haven't had an EEG done in the last year",
              "You are planning a regular stationary visit with an Egyptologist",
              "You feel a lack of improvement or deterioration of your health"
            ],
            "DIETICIAN_CONSULTATION": [
              "for whom the ketogenic diet is indicated by physician or is of interest",
              "who are using a ketogenic diet and want to review their current treatment",
              "they want to verify their current diet"
            ],
            "EEG_CONSULTATION": [
              "to indicate the causes of sleep disorders",
              "in head injuries and recurring headaches",
              "fainting or loss of consciousness"
            ],
            "NEUROLOGIST_CONSULTATION": [
              "fainting and loss of consciousness, problems with balance and coordination",
              "chronic headaches combined with dizziness",
              "deterioration of memory and trouble concentrating",
              "tingling in the extremities with a disturbed sensation"
            ],
            "PSYCHIATRIST_CONSULTATION": [
              "depression",
              "anxiety disorders",
              "sleep problems",
              "unnaturally elevated mood and hyperactivity"
            ],
            "GENETICIST_CONSULTATION": [
              "family history of diseases with genetic causes",
              "the exclusion of congenital disease as a cause of epilepsy",
              "assessment of the risk of a child having epilepsy in the event of a planned pregnancy",
              "before performing the WES test"
            ],
            "NEUROSURGEON_CONSULTATION": [
              "chronic pain and injuries of the spine",
              "brain tumors and neoplastic changes in the spine",
              "numbness, tingling and paresis of the limbs",
              "severe headaches and dizziness, visual and hearing disturbances",
              "consultation of magnetic resonance imaging of the head"
            ],
            "NEUROPSYCHOLOGIST_CONSULTATION": [
              "problems in normal functioning that have arisen as a result of, for example, head injuries, brain tumors, diseases that destroy nervous tissue",
              "impairment of memory or concentration",
              "speech, orientation or wayfinding difficulties",
              "visual disturbances despite normal ophthalmic results"
            ],
            "SLEEP_DISTURBANCE_CONSULTATION": [
              "daytime sleepiness or irritability",
              "sleepiness or insomnia caused by emotional factors",
              "disruption of the daily rhythm of sleep and waking up",
              "snoring while sleeping"
            ]
          },
          "detailsHeader": {
            "VISIT_0": "Besuch 0 - Epilepsie",
            "MIGRATION_VISIT": "Migrationsbesuch",
            "VISIT_1": "Besuch 1 – Epilepsie",
            "START_PACKAGE": "Besuchen Sie 0 und besuchen Sie 1",
            "ADHD_PACKAGE": "ADHS-Paket",
            "REGULAR_VISIT_EEG_PACKAGE": "Regelmäßiger Epilepsiebesuch und EEG",
            "STANDARD_VISIT": "Regelmäßiger Besuch - Epilepsie",
            "NEUROSURGEON_CONSULTATION": "Beratung - Neurochirurg",
            "NEUROPSYCHOLOGIST_CONSULTATION": "Beratung - Neuropsychologe",
            "NEUROLOGOPEDIST_CONSULTATION": "Beratung - Logopäde (Neurologe)",
            "PSYCHIATRIST_CONSULTATION": "Beratung - Psychiater",
            "GENETICIST_CONSULTATION": "Beratung - Genetiker",
            "EPILEPTOLOGIST_CONSULTATION": "Beratung - Epileptologe",
            "EEG_CONSULTATION": "Planen Sie einen EEG-Test",
            "NEUROLOGIST_CONSULTATION": "Beratung - Neurologe",
            "DIETICIAN_CONSULTATION": "Beratung - Ernährungsberater",
            "VNS_CONSULTATION": "VNS-Besuch",
            "ABSENT_CONSULTATION": "Beratung in Abwesenheit",
            "METABOLIC_CONSULTATION": "Stoffwechselberatung",
            "SLEEP_DISTURBANCE_CONSULTATION": "Beratung bei Schlafstörungen"
          },
          "details": {
            "VISIT_0": "Besuch 0 ist ein vorbereitender Besuch, bei dem ein ausführliches, sehr detailliertes Interview geführt wird. Der Besuch dauert ca. 1 Stunde. Besuch 0 muss vor Besuch 1 durchgeführt werden.",
            "MIGRATION_VISIT": "",
            "VISIT_1": "Bei Besuch 1 wird die Diagnose bestätigt und ein umfassender Pflegeplan erstellt. Der Besuch dauert ca. 30 Minuten.",
            "STANDARD_VISIT": "Die Dauer des Besuchs beträgt ca. 30 Minuten.",
            "NEUROSURGEON_CONSULTATION": "Die Dauer der Beratung beträgt 15 bis 45 Minuten.",
            "NEUROPSYCHOLOGIST_CONSULTATION": "Die Dauer der Beratung beträgt ca. 1 Stunde.",
            "NEUROLOGOPEDIST_CONSULTATION": "Beratung - Logopäde (Neurologe)",
            "PSYCHIATRIST_CONSULTATION": "Die Dauer der Beratung beträgt ca. 45 Minuten.",
            "GENETICIST_CONSULTATION": "Die Dauer der Beratung beträgt ca. 45 Minuten.",
            "EPILEPTOLOGIST_CONSULTATION": "Beratung - Epileptologe",
            "EEG_CONSULTATION": "Planen Sie eine EEG-Bewertung",
            "NEUROLOGIST_CONSULTATION": "Termin vereinbaren bei:<ul><li>Migräne oder Kopfschmerzen</li><li>Wirbelsäulenschmerzen, Gefühlsstörungen</li><li>Bewusstlosigkeit</li><li>Schlaganfall, Gedächtnisstörungen</li ><li>andere neurologische Erkrankung</li></ul></br>Beratungsdauer ca. 30 Minuten.",
            "DIETICIAN_CONSULTATION": "Die Beratungsdauer beträgt ca. 1 Stunde.",
            "VNS_CONSULTATION": "VNS-Besuch",
            "ABSENT_CONSULTATION": "Beratung in Abwesenheit",
            "METABOLIC_CONSULTATION": "Die Beratungsdauer beträgt ca. 30 Minuten.",
            "SLEEP_DISTURBANCE_CONSULTATION": "Die Beratungsdauer beträgt ca. 45 Minuten."
          }
        },
        "remoteVisitDescription": "Fernbesuch über Neuroterminal. Erforderlich ist ein Gerät mit Kamera und Mikrofon, das mit dem Internet verbunden ist",
        "details": "Einzelheiten",
        "visitHeader": {
          "VISIT_0": "Besuchen Sie 0",
          "MIGRATION_VISIT": "Migrationsbesuch",
          "VISIT_1": "Besuchen Sie 1",
          "START_PACKAGE": "Besuchen Sie 0 und besuchen Sie 1",
          "ADHD_PACKAGE": "ADHS-Paket",
          "REGULAR_VISIT_EEG_PACKAGE": "Regelmäßiger Epilepsiebesuch und EEG",
          "STANDARD_VISIT": "Regelmäßiger Besuch",
          "NEUROSURGEON_CONSULTATION": "Beratung - Neurochirurg",
          "NEUROPSYCHOLOGIST_CONSULTATION": "Beratung - Neuropsychologe",
          "NEUROLOGOPEDIST_CONSULTATION": "Beratung - Logopäde (Neurologe)",
          "PSYCHIATRIST_CONSULTATION": "Beratung - Psychiater",
          "GENETICIST_CONSULTATION": "Beratung - Genetiker",
          "EPILEPTOLOGIST_CONSULTATION": "Beratung - Epileptologe",
          "EEG_CONSULTATION": "Planen Sie einen EEG-Test",
          "NEUROLOGIST_CONSULTATION": "Beratung - Neurologe",
          "DIETICIAN_CONSULTATION": "Beratung - Ernährungsberater",
          "VNS_CONSULTATION": "VNS-Besuch",
          "ABSENT_CONSULTATION": "Beratung in Abwesenheit",
          "METABOLIC_CONSULTATION": "Stoffwechselberatung",
          "SLEEP_DISTURBANCE_CONSULTATION": "Beratung bei Schlafstörungen"
        },
        "instruction": {
          "START_PACKAGE": "Wenn Sie nicht den für Sie passenden Termin sehen, versuchen Sie, einen anderen Arzt, eine andere Art des Besuchs oder einen früheren Termin für den Besuch zu wählen"
        },
        "visitPriceLabel": "Der Preis des Besuchs",
        "showAllSlots": "Weitere Stunden anzeigen",
        "hideSlots": "Zusammenbruch",
        "nextLabel": "Nächste",
        "validationErrorHeader": "Um zur Zusammenfassung zu gelangen, wählen Sie:",
        "ok": "OK",
        "validationError": {
          "slot": "Datum",
          "location": "Standort",
          "doctor": "Arzt",
          "service": "Besuchstyp"
        },
        "chooseLabel": "Wählen",
        "doctorMissingLabel": "Arzt",
        "locationMissingLabel": "Besuchstyp",
        "andLabel": "Und",
        "unavailableDoctors": "Ärzte, die für das ausgewählte Datum, die ausgewählte Art und/oder die Art des Besuchs nicht verfügbar sind",
        "ADULT": "Erwachsene",
        "CHILD": "Kind",
        "visitNotAvailable": "Es tut uns leid, aber das von Ihnen ausgewählte Datum <b>{visitName}</b> ist nicht verfügbar. Gehen Sie zurück und wählen Sie ein anderes Datum",
        "visitNotSaved": "Der Besuch wurde noch nicht registriert. Gehen Sie zurück zur Zusammenfassung, um den Terminbuchungsprozess abzuschließen."
      },
      "exitPoll": {
        "title": "Warum willst du gehen?",
        "description": "Ihre Antwort ist uns sehr wichtig! Dadurch können wir unsere Dienstleistungen verbessern.",
        "SERVICE": "Ich habe den Dienst, der mich interessiert, nicht gefunden",
        "DATE": "Keine passenden Besuchstermine",
        "PRICE": "Mir gefällt der Preis der Dienstleistung nicht",
        "OTHER": "Andere",
        "sendResponse": "Senden Sie eine Antwort",
        "back": "Kehren Sie zur Website zurück",
        "whatService": "Nach welcher Dienstleistung haben Sie gesucht?",
        "enterMessage": "Bitte gib deine Nachricht ein",
        "reasonMissing": "Bitte begründen Sie Ihren Rücktritt"
      },
      "main": {
        "exitPopup": {
          "description": "Sind Sie sicher, dass Sie den Terminprozess abbrechen möchten? Ihre Auswahl wird nicht gespeichert.",
          "confirm": "Ja, ich möchte gehen",
          "decline": "Nein, gehen Sie zurück zur Terminvereinbarung"
        }
      },
      "summary": {
        "title": "Zusammenfassung",
        "grossPrice": "Gesamtbetrag",
        "availableOnlinePayments": "Wir akzeptieren Online-Zahlungen",
        "transfer": "Banküberweisung",
        "installments": "0% Raten",
        "payment": "Zahlung",
        "paymentDescription": "Um mit der Zahlung fortzufahren, geben Sie bitte Ihre E-Mail-Adresse an. Wir senden Ihnen dann eine Zusammenfassung und einen Zahlungslink.",
        "descriptionExistingAccount": "Wenn Sie ein Konto bei Neuroterminal haben, geben Sie bitte die E-Mail-Adresse an, die Sie zum Anmelden verwenden.",
        "makeReservations": "Termine buchen",
        "makeReservation": "Termin buchen",
        "unexpectedError": "Beim Planen des Termins ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es erneut."
      },
      "thanku": {
        "title": "Danke schön!",
        "emailHasBeenSent": "Wir haben eine Nachricht mit einer Zusammenfassung und einem Zahlungslink an die angegebene E-Mail-Adresse gesendet:",
        "finalizeAppointment": "Gehen Sie zu Ihrer E-Mail und <b>schließen Sie den Terminvereinbarungsprozess ab</b>, indem Sie auf den gesendeten Zahlungslink klicken. Sie haben bis zum <b>{deadline}</b> Zeit, den Terminvereinbarungsprozess abzuschließen.",
        "resendEmail": "Sie haben die E-Mail nicht erhalten? Überprüfen Sie Ihren Spam-Ordner oder",
        "resendEmailLink": "E-Mail erneut senden",
        "resendEmailDone": "E-Mail wurde erneut gesendet",
        "backToNeurosphera": "Zurück zu neurosphera.pl"
      }
    },
    "server": {
      "episode": {
        "description": "Anfallsepisode während des Besuchs hinzugefügt"
      },
      "generatedepisode": {
        "description": "Anfallsepisode während des Besuchs hinzugefügt"
      }
    },
    "enums": Enums.de,
    "serverErrors": ServerErrors.de,
    "dates": {
      "today": "Heute",
      "tomorrow": "morgen"
    }
  },
  "formats": {
    "currency": {
      "style": "Währung",
      "currency": "US Dollar"
    }
  }
}