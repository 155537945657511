export default {
    user: {
        notFound: 'Nie odnaleziono użytkownika',
        alreadyExists: 'Uzytkownik o podanym loginie już istnieje',
        unauthorized: 'Brak uprawnień pozwalających na wykonanie akcji',
        emailAlreadyExists: 'Ten adres e-mail jest już zarejestrowany w systemie',
        cannotRemove: {
            paymentsNotRefunded: 'Nie można usunąć użytkownika z powodu niezwróconych płatnośći'
        }
    },
    doctor: {
        notFound: 'Nie odnaleziono lekarza',
        missingCertificate: 'Brak certyfikatu ZUS w systemie',
        priceList: {
            notFound: 'Nie odnaleziono cennika lekarza',
            wrongGrossPrice: 'Wybrano niepoprawną wartość ceny usługi',
            grossPriceCalculation: 'Wystąpił błąd kalkulacji ceny wizyty',
            notAvailable: 'Wybrana usługa jest niedostępna',
            durationOutOfSync: 'Czas trwania usług wybranego typu powinien być taki sam'
        },
        medicalService: {
            notFound: 'Nie znaleziono wybranej usługi'
        },
        forms: {
            nfzContract: {
                numberDuplicated: "Lekarz z podanym numerem kontraktu NFZ już istnieje"
            }
        },
        certificate: {
            numberInvalid: "Niepoprawny numer certyfikatu lekarza"
        },
        selection: {
            request: {
                alreadyPending: "Wysłano już prośbę o zmianę lekarza prowadzącego, oczekuje ona na odpowiedź lekarza",
                incorrectStatus: "Prośba o zmianę lekarza prowadzącego jest ma nieprawidłowy status",
                incorrectState: "Prośba o zmianę lekarza prowadzącego ma nieprawidłowy stan"
            }
        }
    },
    patient: {
        notFound: 'Nie odnaleziono pacjenta',
        mood: {
            future: 'Nie można określić samopoczucia z przyszłości'
        },
        remove: {
            active: 'Nie można usunać konta pacjanta ponieważ jest ono aktywne'
        }
    },
    position: {
        notFound: 'Nie odnaleziono pozycji'
    },
    visit: {
        notFound: 'Nie odnaleziono wizyty',
        notDone: 'Wizyta nie została zakończona',
        notPaid: 'Wizyta nie została opłacona',
        alreadyReported: 'Wizyta została już zaraportowana do P1 i nie można jej zmienić',
        edit: {
            notAllowed: 'Edycja wizyty niedozwolona'
        },
        time: {
            booked: 'Czas wizyty jest już zajęty'
        },
        appointment: {
            typeInvalid: "Niepoprawny typ spotkania"
        },
        recommendations: {
            empty: 'Przed zakończeniem wizyty należy uzupełnić zalecenia'
        },
        processing: {
            error: 'Błąd podczas przetwarzania wizyty'
        },
        summary: {
            pdf: 'Wystąpił błąd podczas generowania dokumentu pdf dla wizyty'
        },
        invoice: {
            pdf: 'Wystąpił błąd podczas generowania pdf faktury dla wizyty'
        },
        message: {
            notInitialized: 'Konwersjacja nie została jeszcze rozpoczęta przez pacjenta',
            limitExceeded: 'Limit wiadomości w ramach jednej wizyty został wyczerpany',
            notResponded: 'Odpowiedź na twoją ostatnią wiadomość nie została jeszcze udzielona'
        },
        afterVisitZeroAvailable: 'Funkcjonalność niedostępna przed odbyciem pierwszej wizyty',
        paymentMarkingNotAllowed: 'Nie można oznaczyć tej wizyty z powodu zbyt wysokiej ceny',
        changeDisabled: 'Termin lub lekarz dla tej wizyty został już zmieniony. System nie pozwala na kolejną zmianę. W razie wątpliwości skontaktuj się z naszą recepcją.'
    },
    slot: {
      stepNotAllowed: 'Usługa medyczna nie definiuje możliwości wyboru długości wizyty',
      timeNotInRange: 'Wybrana długość wizyty nie mieści się w możliwym zakresie',
      timeIndivisibleByStep: 'Wybrana długość wizyty jest niepodzielna przez zdefiniowany krok'
    },
    payment: {
        receipt: {
            pdf: 'Wystąpił błąd podczas generowania pokwitowania płatności'
        },
        creation: 'Wystąpił błąd podczas gzapisywania metody płatności',
        visit: {
            alreadyDone: 'Wizyta została już opłacona',
            stillProcessing: 'Płatność za wizytę jest wciąż przetwarzana',
            unknownState: 'Błąd podczas przetwarzanai płatności, prosze skontaktować się z Neuroterminal'
        },
        alreadyDone: 'Płatność została już wykonana',
        genericError: 'Wystpił błąd podczas przetwarzania płatności'
    },
    date: {
        selected: {
            fromPast: 'Wybrany termin już minął'
        },
        range: {
            collision: 'Wybrane zakresy dat kolidują ze sobą'
        }
    },
    consultation: {
        notFound: "Nie odnaleziono konsultacji"
    },
    nip: {
        invalid: "NIP jest niepoprawny"
    },
    medicalEvent: {
        notFound: "Nie odnaleziono zdarzenia",
        invalidData: "Niepoprawne dane zdarzenia"
    },
    medicalRequest: {
        notFound: "Nie odnaleziono prośby",
        invalidData: "Niepoprawne dane prośby",
        alreadyAssigned: "Prośba jest już przypisana"
    },
    medicalResult: {
        invalidData: "Niepoprawne dane badań",
        notFound: "Nie odnaleziono badania",
        description: {
            notFound: "Nie odnaleziono opisu badania"
        },
        file: {
            invalidData: "Niepoprawne dane pliku z badaniami"
        }
    },
    medicalDocument: {
        addError: "Wystąpił błąd podczas zapisywania dokumentu",
        editError: "Wystąpił błąd podczas edycji dokumentu",
        downloadError: "Wystąpił błąd podczas pobierania pliku",
        deleteError: "Wystąpił błąd podczas usuwania dokumentu",
        file: {
            saveError: "Wystąpił błąd podczas zapisywania załącznika do dokumentu"
        }
    },
    drugStock: {
        negativeAmount: 'Ilość nie może być mniejsza od 0'
    },
    product: {
        notFound: 'Nie odnaleziono produktu',
        notUnique: 'Nie można wybrać tego samego leku wielokrotnie'
    },
    patientSeizuresHistory: {
        futureDateSelected: 'Wybrano datę z przyszłości',
        periodConflict: 'Wskazane okresy kolidują ze sobą'
    },
    patientSeizuresCharacteristics: {
        notFound: 'Historia napadów pacjenta nie została w pełni uzupełniona'
    },
    healthSurvey: {
        alreadyInitialized: 'Twoja historia choroby została już zainicjowana',
        patientHasNotEpilepsy: 'Tylko pacjenci chorujący na padaczkę mają dostęp do ankiety zero'
    },
    form: {
        invalidEmail: 'Niepoprawny format adresu e-mail',
        invalidPhone: 'Niepoprawny format numeru telefonu'
    },
    passwordReset: {
        tokenIncorrect: 'Przesłany link jets błędny lub wygasł',
        internalError: 'Błąd podczas przetwarzania żądania zmiany hasła',
        unsecure: 'Hasło powinno zawierać co najmniej 10 znaków, wielkie i małe litery, cyfry oraz znaki specjalne'
    },
    email: {
        sendError: 'Wystąpił błąd podczas wysyłania wiadomości'
    },
    sms: {
        noPhone: 'Nieznany numer telefonu dla wskazanego użytkownika'
    },
    videochat: {
        getTokenFailed: 'Wystąpił błąd przy nawiązywaniu połączenia',
        sessionExpired: 'Wizyta została zakończona. Nie można ponownie nawiązać połączenia'
    },
    doctorNote: {
        notFound: 'Nie odnaleziono notatki'
    },
    referral: {
        notFound: 'Nie odnaleziono skierowania',
        cancelFree: 'Nie można anulować skierowania na darmową wizytę'
    },
    harmonogram: {
        notFound: 'Wybrany termin wizyty jest już zarezerwowany'
    },
    unexpected: 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później',
    incompleteData: 'Dane są niekompletne',
    cered: {
        prescription: {
            creation: 'Wystąpił błąd podczas tworzenia recepty',
            psychotropicNotUnique: 'Recepta nie może zawierać kombinacji leków psychotropowych z innymi lekami',
            prescriptionTypeNotUnique: 'Recepta nie może zawierać kombinacji leków RP i RPW',
            prescriptionFileNotAvailable: 'Wydruk tej recepty nie jest dostępny'
        },
        ereferral: {
            creation: 'Wystąpił błąd podczas tworzenia e-skierowania'
        },
        ewus: {
            login: 'Wystąpił błąd podczas logowania do EWUŚ',
            check: 'Wystąpił błąd podczas sprawdzania pacjenta w EWUŚ'
        },
        zus: {
            connect: 'Wystąpił błąd podczas logowania do ZUS',
            cannotCancel: 'Anulowanie tego zwolnienia nie jest możliwe',
            cannotFindWorkPlace: 'Nie odnaleziono miejsca pracy NEUROSPHERA na koncie ZUS'
        }
    },
    icd10: {
        notUnique: 'Nie można wybrać tego samego icd10 wielokrotnie'
    },
    visitPackage: {
        dateCollision: 'Wizyty z pakietu nie mogą być umówione na ten sam dzień',
        dateNotEqual: 'Określone z wizyt powinny odbyć się tego samego dnia',
        timeCollision: 'Wybrane terminy wizyt kolidują ze sobą',
        wrongDuration: 'Niepoprawny czas trwania wizyty z pakietu',
        invalidService: 'Niektóre z wybranych usług są niedostępne w tym pakiecie',
        moveDateCollision: 'Nowy termin koliduje z terminem innej wizyty z pakietu',
        someVisitsDone: 'Nie można anulować wizyt z pakietu, w którym została już odbyta wizyta',
        notActive: 'Pakiet wizyt nie jest dostępny',
        differentDoctor: 'Niektóre z wizyt powinny odbyć się u tego samego lekarza',
        differentPlace: 'Niektóre z wizyt powinny odbyć się w tym samym miejscu (lub online)'
    },
    request: {
        blocked: {
            reset: 'Wysłanie linku do zmiany hasła pod podany adres email jest niemożliwe w czasie {n} godzin od ostatniego żądania zmiany hasła. W celu usunięcia blokady proszę skontaktować się bezpośrednio z biurem obsługi klientów.',
            registration: 'Wysłanie zgłoszenia rejestracyjnego pod podany adres email jest niemożliwe w czasie {n} godzin od ostatniego zgłoszenia. W celu usunięcia blokady proszę skontaktować się bezpośrednio z biurem obsługi klientów.',
            notFound: 'Nie można odblokować resetu hasła, ponieważ nie znaleziono poprzedniego żądania.'
        }
    },
    docplanner: {
        issue: {
            alreadyResolved: 'Problem został już oznaczony jako rozwiązany',
            actionNotImplemented: 'Dla tego typu problemów nie zostało przygotowane automatyczne rozwiązanie',
            notFound: 'Nie znaleziono rekordu'
        }
    },
    hashtag: {
        alreadyExists: 'Hashtag o podanej nazwie już istnieje'
    },
    generic: {
        alreadyExists: 'Rekord o wybranej charakterystyce już istnieje'
    },
    partner: {
        notMatch: 'Nie odnaleziono pasującej usługi u partnera'
    }
}
