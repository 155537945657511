/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { PayUBuyerDto } from './pay-ubuyer-dto';
import { PayUMethodDto } from './pay-umethod-dto';
import { PayUMethodsDto } from './pay-umethods-dto';
import { PayUProductDto } from './pay-uproduct-dto';

/**
 * 
 * @export
 * @interface PayUOrderDto
 */
export interface PayUOrderDto {
    /**
     * 
     * @type {PayUBuyerDto}
     * @memberof PayUOrderDto
     */
    'buyer'?: PayUBuyerDto;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderDto
     */
    'continueUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderDto
     */
    'currencyCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderDto
     */
    'customerIp'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderDto
     */
    'extOrderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderDto
     */
    'merchantPosId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderDto
     */
    'notifyUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderDto
     */
    'orderCreateDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderDto
     */
    'orderId'?: string;
    /**
     * 
     * @type {PayUMethodDto}
     * @memberof PayUOrderDto
     */
    'payMethod'?: PayUMethodDto;
    /**
     * 
     * @type {PayUMethodsDto}
     * @memberof PayUOrderDto
     */
    'payMethods'?: PayUMethodsDto;
    /**
     * 
     * @type {Array<PayUProductDto>}
     * @memberof PayUOrderDto
     */
    'products'?: Array<PayUProductDto>;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderDto
     */
    'status'?: PayUOrderDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderDto
     */
    'totalAmount'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderDto
     */
    'validityTime'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PayUOrderDtoStatusEnum {
    Pending = 'PENDING',
    Completed = 'COMPLETED',
    Canceled = 'CANCELED'
}


