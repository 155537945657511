import PlErrors from "./server-errors-pl";
import EnErrors from "./server-errors-en";
import FrErrors from "./server-errors-fr";
import CsErrors from "./server-errors-cs";
import UkErrors from "./server-errors-uk";
import HuErrors from "./server-errors-hu";
import DeErrors from "./server-errors-de";

export default {

    pl: PlErrors,

    en: EnErrors,

    fr: FrErrors,

    cs: CsErrors,

    uk: UkErrors,

    hu: HuErrors,

    de: DeErrors
}
