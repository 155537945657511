/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DrugsHistoryDto } from '../model';
// @ts-ignore
import { HealthSurveyStatusDto } from '../model';
// @ts-ignore
import { PatientSeizureTypeBasicDto } from '../model';
// @ts-ignore
import { PatientSeizureTypeDto } from '../model';
// @ts-ignore
import { PatientSeizureTypesStatus } from '../model';
// @ts-ignore
import { SeizuresCharacteristicsDto } from '../model';
// @ts-ignore
import { SeizuresCharacteristicsStatusDto } from '../model';
// @ts-ignore
import { SeizuresHistoryDto } from '../model';
/**
 * HealthStateSurveyApiControllerApi - axios parameter creator
 * @export
 */
export const HealthStateSurveyApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} visitId 
         * @param {SeizuresHistoryDto} seizuresHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSeizureType: async (visitId: number, seizuresHistoryDto: SeizuresHistoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('addSeizureType', 'visitId', visitId)
            // verify required parameter 'seizuresHistoryDto' is not null or undefined
            assertParamExists('addSeizureType', 'seizuresHistoryDto', seizuresHistoryDto)
            const localVarPath = `/api/health-survey/seizure-types/add/{visitId}`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(seizuresHistoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} implantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImplant: async (patientId: number, implantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('deleteImplant', 'patientId', patientId)
            // verify required parameter 'implantId' is not null or undefined
            assertParamExists('deleteImplant', 'implantId', implantId)
            const localVarPath = `/api/health-survey/drug-history/{patientId}/implant/{implantId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"implantId"}}`, encodeURIComponent(String(implantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePatientSeizuresHistory: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('generatePatientSeizuresHistory', 'patientId', patientId)
            const localVarPath = `/api/health-survey/seizure/generate/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugsHistory: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getDrugsHistory', 'patientId', patientId)
            const localVarPath = `/api/health-survey/drug-history/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthSurveyStatus: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getHealthSurveyStatus', 'patientId', patientId)
            const localVarPath = `/api/health-survey/status/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {boolean} [includeCorrectionInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientSeizureTypesStatus: async (patientId: number, includeCorrectionInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientSeizureTypesStatus', 'patientId', patientId)
            const localVarPath = `/api/health-survey/seizure-types/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeCorrectionInfo !== undefined) {
                localVarQueryParameter['includeCorrectionInfo'] = includeCorrectionInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientTreatedBefore: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientTreatedBefore', 'patientId', patientId)
            const localVarPath = `/api/health-survey/drug-history/{patientId}/treated-before`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeizureHistory: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getSeizureHistory', 'patientId', patientId)
            const localVarPath = `/api/health-survey/seizure/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeizureHistoryStatus: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getSeizureHistoryStatus', 'patientId', patientId)
            const localVarPath = `/api/health-survey/seizure/status/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeizuresCharacteristics: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getSeizuresCharacteristics', 'patientId', patientId)
            const localVarPath = `/api/health-survey/seizures-characteristics/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} seizureTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeizuresCharacteristicsWithCriticalDifferences: async (patientId: number, seizureTypeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getSeizuresCharacteristicsWithCriticalDifferences', 'patientId', patientId)
            // verify required parameter 'seizureTypeId' is not null or undefined
            assertParamExists('getSeizuresCharacteristicsWithCriticalDifferences', 'seizureTypeId', seizureTypeId)
            const localVarPath = `/api/health-survey/seizures-characteristics/{patientId}/fix-critical/{seizureTypeId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"seizureTypeId"}}`, encodeURIComponent(String(seizureTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {DrugsHistoryDto} drugsHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDrugsHistory: async (patientId: number, drugsHistoryDto: DrugsHistoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('saveDrugsHistory', 'patientId', patientId)
            // verify required parameter 'drugsHistoryDto' is not null or undefined
            assertParamExists('saveDrugsHistory', 'drugsHistoryDto', drugsHistoryDto)
            const localVarPath = `/api/health-survey/drug-history/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(drugsHistoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {SeizuresHistoryDto} seizuresHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSeizureHistory: async (patientId: number, seizuresHistoryDto: SeizuresHistoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('saveSeizureHistory', 'patientId', patientId)
            // verify required parameter 'seizuresHistoryDto' is not null or undefined
            assertParamExists('saveSeizureHistory', 'seizuresHistoryDto', seizuresHistoryDto)
            const localVarPath = `/api/health-survey/seizure/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(seizuresHistoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Array<PatientSeizureTypeDto>} patientSeizureTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSeizureTypes: async (patientId: number, patientSeizureTypeDto: Array<PatientSeizureTypeDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('saveSeizureTypes', 'patientId', patientId)
            // verify required parameter 'patientSeizureTypeDto' is not null or undefined
            assertParamExists('saveSeizureTypes', 'patientSeizureTypeDto', patientSeizureTypeDto)
            const localVarPath = `/api/health-survey/seizure-types/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientSeizureTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Array<SeizuresCharacteristicsDto>} seizuresCharacteristicsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSeizuresCharacteristics: async (patientId: number, seizuresCharacteristicsDto: Array<SeizuresCharacteristicsDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('saveSeizuresCharacteristics', 'patientId', patientId)
            // verify required parameter 'seizuresCharacteristicsDto' is not null or undefined
            assertParamExists('saveSeizuresCharacteristics', 'seizuresCharacteristicsDto', seizuresCharacteristicsDto)
            const localVarPath = `/api/health-survey/seizures-characteristics/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(seizuresCharacteristicsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {SeizuresCharacteristicsDto} seizuresCharacteristicsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCriticalsSeizuresCharacteristics: async (patientId: number, seizuresCharacteristicsDto: SeizuresCharacteristicsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('updateCriticalsSeizuresCharacteristics', 'patientId', patientId)
            // verify required parameter 'seizuresCharacteristicsDto' is not null or undefined
            assertParamExists('updateCriticalsSeizuresCharacteristics', 'seizuresCharacteristicsDto', seizuresCharacteristicsDto)
            const localVarPath = `/api/health-survey/seizures-characteristics/{patientId}/fix-critical`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(seizuresCharacteristicsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {PatientSeizureTypeDto} patientSeizureTypeDto 
         * @param {boolean} [includeCorrectionInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientSeizureType: async (patientId: number, patientSeizureTypeDto: PatientSeizureTypeDto, includeCorrectionInfo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('updatePatientSeizureType', 'patientId', patientId)
            // verify required parameter 'patientSeizureTypeDto' is not null or undefined
            assertParamExists('updatePatientSeizureType', 'patientSeizureTypeDto', patientSeizureTypeDto)
            const localVarPath = `/api/health-survey/seizure-types/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeCorrectionInfo !== undefined) {
                localVarQueryParameter['includeCorrectionInfo'] = includeCorrectionInfo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientSeizureTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthStateSurveyApiControllerApi - functional programming interface
 * @export
 */
export const HealthStateSurveyApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthStateSurveyApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} visitId 
         * @param {SeizuresHistoryDto} seizuresHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSeizureType(visitId: number, seizuresHistoryDto: SeizuresHistoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientSeizureTypeBasicDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSeizureType(visitId, seizuresHistoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} implantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImplant(patientId: number, implantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImplant(patientId, implantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatePatientSeizuresHistory(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generatePatientSeizuresHistory(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDrugsHistory(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DrugsHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDrugsHistory(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealthSurveyStatus(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<HealthSurveyStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealthSurveyStatus(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {boolean} [includeCorrectionInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientSeizureTypesStatus(patientId: number, includeCorrectionInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientSeizureTypesStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientSeizureTypesStatus(patientId, includeCorrectionInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientTreatedBefore(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientTreatedBefore(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeizureHistory(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<SeizuresHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSeizureHistory(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeizureHistoryStatus(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSeizureHistoryStatus(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeizuresCharacteristics(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<SeizuresCharacteristicsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSeizuresCharacteristics(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} seizureTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeizuresCharacteristicsWithCriticalDifferences(patientId: number, seizureTypeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<SeizuresCharacteristicsStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSeizuresCharacteristicsWithCriticalDifferences(patientId, seizureTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {DrugsHistoryDto} drugsHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDrugsHistory(patientId: number, drugsHistoryDto: DrugsHistoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDrugsHistory(patientId, drugsHistoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {SeizuresHistoryDto} seizuresHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSeizureHistory(patientId: number, seizuresHistoryDto: SeizuresHistoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<SeizuresHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSeizureHistory(patientId, seizuresHistoryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Array<PatientSeizureTypeDto>} patientSeizureTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSeizureTypes(patientId: number, patientSeizureTypeDto: Array<PatientSeizureTypeDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<SeizuresHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSeizureTypes(patientId, patientSeizureTypeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Array<SeizuresCharacteristicsDto>} seizuresCharacteristicsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSeizuresCharacteristics(patientId: number, seizuresCharacteristicsDto: Array<SeizuresCharacteristicsDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSeizuresCharacteristics(patientId, seizuresCharacteristicsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {SeizuresCharacteristicsDto} seizuresCharacteristicsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCriticalsSeizuresCharacteristics(patientId: number, seizuresCharacteristicsDto: SeizuresCharacteristicsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCriticalsSeizuresCharacteristics(patientId, seizuresCharacteristicsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {PatientSeizureTypeDto} patientSeizureTypeDto 
         * @param {boolean} [includeCorrectionInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePatientSeizureType(patientId: number, patientSeizureTypeDto: PatientSeizureTypeDto, includeCorrectionInfo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePatientSeizureType(patientId, patientSeizureTypeDto, includeCorrectionInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthStateSurveyApiControllerApi - factory interface
 * @export
 */
export const HealthStateSurveyApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthStateSurveyApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} visitId 
         * @param {SeizuresHistoryDto} seizuresHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSeizureType(visitId: number, seizuresHistoryDto: SeizuresHistoryDto, options?: any): Promise<PatientSeizureTypeBasicDto> {
            return localVarFp.addSeizureType(visitId, seizuresHistoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} implantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImplant(patientId: number, implantId: number, options?: any): Promise<void> {
            return localVarFp.deleteImplant(patientId, implantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePatientSeizuresHistory(patientId: number, options?: any): Promise<string> {
            return localVarFp.generatePatientSeizuresHistory(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugsHistory(patientId: number, options?: any): Promise<DrugsHistoryDto> {
            return localVarFp.getDrugsHistory(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthSurveyStatus(patientId: number, options?: any): Promise<HealthSurveyStatusDto> {
            return localVarFp.getHealthSurveyStatus(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {boolean} [includeCorrectionInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientSeizureTypesStatus(patientId: number, includeCorrectionInfo?: boolean, options?: any): Promise<PatientSeizureTypesStatus> {
            return localVarFp.getPatientSeizureTypesStatus(patientId, includeCorrectionInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientTreatedBefore(patientId: number, options?: any): Promise<boolean> {
            return localVarFp.getPatientTreatedBefore(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeizureHistory(patientId: number, options?: any): Promise<Array<SeizuresHistoryDto>> {
            return localVarFp.getSeizureHistory(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeizureHistoryStatus(patientId: number, options?: any): Promise<string> {
            return localVarFp.getSeizureHistoryStatus(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeizuresCharacteristics(patientId: number, options?: any): Promise<Array<SeizuresCharacteristicsDto>> {
            return localVarFp.getSeizuresCharacteristics(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} seizureTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeizuresCharacteristicsWithCriticalDifferences(patientId: number, seizureTypeId: number, options?: any): Promise<SeizuresCharacteristicsStatusDto> {
            return localVarFp.getSeizuresCharacteristicsWithCriticalDifferences(patientId, seizureTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {DrugsHistoryDto} drugsHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDrugsHistory(patientId: number, drugsHistoryDto: DrugsHistoryDto, options?: any): Promise<string> {
            return localVarFp.saveDrugsHistory(patientId, drugsHistoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {SeizuresHistoryDto} seizuresHistoryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSeizureHistory(patientId: number, seizuresHistoryDto: SeizuresHistoryDto, options?: any): Promise<SeizuresHistoryDto> {
            return localVarFp.saveSeizureHistory(patientId, seizuresHistoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Array<PatientSeizureTypeDto>} patientSeizureTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSeizureTypes(patientId: number, patientSeizureTypeDto: Array<PatientSeizureTypeDto>, options?: any): Promise<Array<SeizuresHistoryDto>> {
            return localVarFp.saveSeizureTypes(patientId, patientSeizureTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Array<SeizuresCharacteristicsDto>} seizuresCharacteristicsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSeizuresCharacteristics(patientId: number, seizuresCharacteristicsDto: Array<SeizuresCharacteristicsDto>, options?: any): Promise<string> {
            return localVarFp.saveSeizuresCharacteristics(patientId, seizuresCharacteristicsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {SeizuresCharacteristicsDto} seizuresCharacteristicsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCriticalsSeizuresCharacteristics(patientId: number, seizuresCharacteristicsDto: SeizuresCharacteristicsDto, options?: any): Promise<boolean> {
            return localVarFp.updateCriticalsSeizuresCharacteristics(patientId, seizuresCharacteristicsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {PatientSeizureTypeDto} patientSeizureTypeDto 
         * @param {boolean} [includeCorrectionInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientSeizureType(patientId: number, patientSeizureTypeDto: PatientSeizureTypeDto, includeCorrectionInfo?: boolean, options?: any): Promise<boolean> {
            return localVarFp.updatePatientSeizureType(patientId, patientSeizureTypeDto, includeCorrectionInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthStateSurveyApiControllerApi - object-oriented interface
 * @export
 * @class HealthStateSurveyApiControllerApi
 * @extends {BaseAPI}
 */
export class HealthStateSurveyApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} visitId 
     * @param {SeizuresHistoryDto} seizuresHistoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthStateSurveyApiControllerApi
     */
    public addSeizureType(visitId: number, seizuresHistoryDto: SeizuresHistoryDto, options?: AxiosRequestConfig) {
        return HealthStateSurveyApiControllerApiFp(this.configuration).addSeizureType(visitId, seizuresHistoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {number} implantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthStateSurveyApiControllerApi
     */
    public deleteImplant(patientId: number, implantId: number, options?: AxiosRequestConfig) {
        return HealthStateSurveyApiControllerApiFp(this.configuration).deleteImplant(patientId, implantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthStateSurveyApiControllerApi
     */
    public generatePatientSeizuresHistory(patientId: number, options?: AxiosRequestConfig) {
        return HealthStateSurveyApiControllerApiFp(this.configuration).generatePatientSeizuresHistory(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthStateSurveyApiControllerApi
     */
    public getDrugsHistory(patientId: number, options?: AxiosRequestConfig) {
        return HealthStateSurveyApiControllerApiFp(this.configuration).getDrugsHistory(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthStateSurveyApiControllerApi
     */
    public getHealthSurveyStatus(patientId: number, options?: AxiosRequestConfig) {
        return HealthStateSurveyApiControllerApiFp(this.configuration).getHealthSurveyStatus(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {boolean} [includeCorrectionInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthStateSurveyApiControllerApi
     */
    public getPatientSeizureTypesStatus(patientId: number, includeCorrectionInfo?: boolean, options?: AxiosRequestConfig) {
        return HealthStateSurveyApiControllerApiFp(this.configuration).getPatientSeizureTypesStatus(patientId, includeCorrectionInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthStateSurveyApiControllerApi
     */
    public getPatientTreatedBefore(patientId: number, options?: AxiosRequestConfig) {
        return HealthStateSurveyApiControllerApiFp(this.configuration).getPatientTreatedBefore(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthStateSurveyApiControllerApi
     */
    public getSeizureHistory(patientId: number, options?: AxiosRequestConfig) {
        return HealthStateSurveyApiControllerApiFp(this.configuration).getSeizureHistory(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthStateSurveyApiControllerApi
     */
    public getSeizureHistoryStatus(patientId: number, options?: AxiosRequestConfig) {
        return HealthStateSurveyApiControllerApiFp(this.configuration).getSeizureHistoryStatus(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthStateSurveyApiControllerApi
     */
    public getSeizuresCharacteristics(patientId: number, options?: AxiosRequestConfig) {
        return HealthStateSurveyApiControllerApiFp(this.configuration).getSeizuresCharacteristics(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {number} seizureTypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthStateSurveyApiControllerApi
     */
    public getSeizuresCharacteristicsWithCriticalDifferences(patientId: number, seizureTypeId: number, options?: AxiosRequestConfig) {
        return HealthStateSurveyApiControllerApiFp(this.configuration).getSeizuresCharacteristicsWithCriticalDifferences(patientId, seizureTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {DrugsHistoryDto} drugsHistoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthStateSurveyApiControllerApi
     */
    public saveDrugsHistory(patientId: number, drugsHistoryDto: DrugsHistoryDto, options?: AxiosRequestConfig) {
        return HealthStateSurveyApiControllerApiFp(this.configuration).saveDrugsHistory(patientId, drugsHistoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {SeizuresHistoryDto} seizuresHistoryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthStateSurveyApiControllerApi
     */
    public saveSeizureHistory(patientId: number, seizuresHistoryDto: SeizuresHistoryDto, options?: AxiosRequestConfig) {
        return HealthStateSurveyApiControllerApiFp(this.configuration).saveSeizureHistory(patientId, seizuresHistoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {Array<PatientSeizureTypeDto>} patientSeizureTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthStateSurveyApiControllerApi
     */
    public saveSeizureTypes(patientId: number, patientSeizureTypeDto: Array<PatientSeizureTypeDto>, options?: AxiosRequestConfig) {
        return HealthStateSurveyApiControllerApiFp(this.configuration).saveSeizureTypes(patientId, patientSeizureTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {Array<SeizuresCharacteristicsDto>} seizuresCharacteristicsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthStateSurveyApiControllerApi
     */
    public saveSeizuresCharacteristics(patientId: number, seizuresCharacteristicsDto: Array<SeizuresCharacteristicsDto>, options?: AxiosRequestConfig) {
        return HealthStateSurveyApiControllerApiFp(this.configuration).saveSeizuresCharacteristics(patientId, seizuresCharacteristicsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {SeizuresCharacteristicsDto} seizuresCharacteristicsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthStateSurveyApiControllerApi
     */
    public updateCriticalsSeizuresCharacteristics(patientId: number, seizuresCharacteristicsDto: SeizuresCharacteristicsDto, options?: AxiosRequestConfig) {
        return HealthStateSurveyApiControllerApiFp(this.configuration).updateCriticalsSeizuresCharacteristics(patientId, seizuresCharacteristicsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {PatientSeizureTypeDto} patientSeizureTypeDto 
     * @param {boolean} [includeCorrectionInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthStateSurveyApiControllerApi
     */
    public updatePatientSeizureType(patientId: number, patientSeizureTypeDto: PatientSeizureTypeDto, includeCorrectionInfo?: boolean, options?: AxiosRequestConfig) {
        return HealthStateSurveyApiControllerApiFp(this.configuration).updatePatientSeizureType(patientId, patientSeizureTypeDto, includeCorrectionInfo, options).then((request) => request(this.axios, this.basePath));
    }
}
