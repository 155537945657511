/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompleteEegDescriptionDto } from '../model';
// @ts-ignore
import { EegDescriptionDto } from '../model';
// @ts-ignore
import { EegDescriptionSummaryDto } from '../model';
// @ts-ignore
import { EegDescriptionUpdateResult } from '../model';
/**
 * PatientExaminationControllerApi - axios parameter creator
 * @export
 */
export const PatientExaminationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {CompleteEegDescriptionDto} completeEegDescriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeEegDescription: async (id: number, completeEegDescriptionDto: CompleteEegDescriptionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('completeEegDescription', 'id', id)
            // verify required parameter 'completeEegDescriptionDto' is not null or undefined
            assertParamExists('completeEegDescription', 'completeEegDescriptionDto', completeEegDescriptionDto)
            const localVarPath = `/api/patient-examination/eeg-description/{id}/complete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeEegDescriptionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEegDescription: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEegDescription', 'id', id)
            const localVarPath = `/api/patient-examination/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEegDescription: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchEegDescription', 'id', id)
            const localVarPath = `/api/patient-examination/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEegDescriptionByPatient: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('fetchEegDescriptionByPatient', 'patientId', patientId)
            const localVarPath = `/api/patient-examination/patient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EegDescriptionDto} dto 
         * @param {Array<any>} [newAttachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEegDescription: async (dto: EegDescriptionDto, newAttachments?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dto' is not null or undefined
            assertParamExists('saveEegDescription', 'dto', dto)
            const localVarPath = `/api/patient-examination/eeg-description`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (dto !== undefined) { 
                localVarFormParams.append('dto', new Blob([JSON.stringify(dto)], { type: "application/json", }));
            }
                if (newAttachments) {
                newAttachments.forEach((element) => {
                    localVarFormParams.append('newAttachments', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EegDescriptionDto} dto 
         * @param {boolean} [generateMedicalDoc] 
         * @param {Array<any>} [newAttachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEegDescription: async (id: number, dto: EegDescriptionDto, generateMedicalDoc?: boolean, newAttachments?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEegDescription', 'id', id)
            // verify required parameter 'dto' is not null or undefined
            assertParamExists('updateEegDescription', 'dto', dto)
            const localVarPath = `/api/patient-examination/eeg-description/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (generateMedicalDoc !== undefined) {
                localVarQueryParameter['generateMedicalDoc'] = generateMedicalDoc;
            }


            if (dto !== undefined) { 
                localVarFormParams.append('dto', new Blob([JSON.stringify(dto)], { type: "application/json", }));
            }
                if (newAttachments) {
                newAttachments.forEach((element) => {
                    localVarFormParams.append('newAttachments', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientExaminationControllerApi - functional programming interface
 * @export
 */
export const PatientExaminationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientExaminationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {CompleteEegDescriptionDto} completeEegDescriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeEegDescription(id: number, completeEegDescriptionDto: CompleteEegDescriptionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeEegDescription(id, completeEegDescriptionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEegDescription(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEegDescription(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchEegDescription(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<EegDescriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchEegDescription(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchEegDescriptionByPatient(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<EegDescriptionSummaryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchEegDescriptionByPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EegDescriptionDto} dto 
         * @param {Array<any>} [newAttachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveEegDescription(dto: EegDescriptionDto, newAttachments?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<EegDescriptionUpdateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveEegDescription(dto, newAttachments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EegDescriptionDto} dto 
         * @param {boolean} [generateMedicalDoc] 
         * @param {Array<any>} [newAttachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEegDescription(id: number, dto: EegDescriptionDto, generateMedicalDoc?: boolean, newAttachments?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<EegDescriptionUpdateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEegDescription(id, dto, generateMedicalDoc, newAttachments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientExaminationControllerApi - factory interface
 * @export
 */
export const PatientExaminationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientExaminationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {CompleteEegDescriptionDto} completeEegDescriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeEegDescription(id: number, completeEegDescriptionDto: CompleteEegDescriptionDto, options?: any): Promise<void> {
            return localVarFp.completeEegDescription(id, completeEegDescriptionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEegDescription(id: number, options?: any): Promise<void> {
            return localVarFp.deleteEegDescription(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEegDescription(id: number, options?: any): Promise<EegDescriptionDto> {
            return localVarFp.fetchEegDescription(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEegDescriptionByPatient(patientId: number, options?: any): Promise<Array<EegDescriptionSummaryDto>> {
            return localVarFp.fetchEegDescriptionByPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EegDescriptionDto} dto 
         * @param {Array<any>} [newAttachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEegDescription(dto: EegDescriptionDto, newAttachments?: Array<any>, options?: any): Promise<EegDescriptionUpdateResult> {
            return localVarFp.saveEegDescription(dto, newAttachments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EegDescriptionDto} dto 
         * @param {boolean} [generateMedicalDoc] 
         * @param {Array<any>} [newAttachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEegDescription(id: number, dto: EegDescriptionDto, generateMedicalDoc?: boolean, newAttachments?: Array<any>, options?: any): Promise<EegDescriptionUpdateResult> {
            return localVarFp.updateEegDescription(id, dto, generateMedicalDoc, newAttachments, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientExaminationControllerApi - object-oriented interface
 * @export
 * @class PatientExaminationControllerApi
 * @extends {BaseAPI}
 */
export class PatientExaminationControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {CompleteEegDescriptionDto} completeEegDescriptionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientExaminationControllerApi
     */
    public completeEegDescription(id: number, completeEegDescriptionDto: CompleteEegDescriptionDto, options?: AxiosRequestConfig) {
        return PatientExaminationControllerApiFp(this.configuration).completeEegDescription(id, completeEegDescriptionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientExaminationControllerApi
     */
    public deleteEegDescription(id: number, options?: AxiosRequestConfig) {
        return PatientExaminationControllerApiFp(this.configuration).deleteEegDescription(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientExaminationControllerApi
     */
    public fetchEegDescription(id: number, options?: AxiosRequestConfig) {
        return PatientExaminationControllerApiFp(this.configuration).fetchEegDescription(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientExaminationControllerApi
     */
    public fetchEegDescriptionByPatient(patientId: number, options?: AxiosRequestConfig) {
        return PatientExaminationControllerApiFp(this.configuration).fetchEegDescriptionByPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EegDescriptionDto} dto 
     * @param {Array<any>} [newAttachments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientExaminationControllerApi
     */
    public saveEegDescription(dto: EegDescriptionDto, newAttachments?: Array<any>, options?: AxiosRequestConfig) {
        return PatientExaminationControllerApiFp(this.configuration).saveEegDescription(dto, newAttachments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EegDescriptionDto} dto 
     * @param {boolean} [generateMedicalDoc] 
     * @param {Array<any>} [newAttachments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientExaminationControllerApi
     */
    public updateEegDescription(id: number, dto: EegDescriptionDto, generateMedicalDoc?: boolean, newAttachments?: Array<any>, options?: AxiosRequestConfig) {
        return PatientExaminationControllerApiFp(this.configuration).updateEegDescription(id, dto, generateMedicalDoc, newAttachments, options).then((request) => request(this.axios, this.basePath));
    }
}
