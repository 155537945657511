/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BrainActivityDtoEegIncorrectInterictalActivityEnum
 */
export interface BrainActivityDtoEegIncorrectInterictalActivityEnum {
    /**
     * 
     * @type {string}
     * @memberof BrainActivityDtoEegIncorrectInterictalActivityEnum
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BrainActivityDtoEegIncorrectInterictalActivityEnum
     */
    'electrodes'?: Array<BrainActivityDtoEegIncorrectInterictalActivityEnumElectrodesEnum>;
    /**
     * 
     * @type {string}
     * @memberof BrainActivityDtoEegIncorrectInterictalActivityEnum
     */
    'lateralization'?: BrainActivityDtoEegIncorrectInterictalActivityEnumLateralizationEnum;
    /**
     * 
     * @type {string}
     * @memberof BrainActivityDtoEegIncorrectInterictalActivityEnum
     */
    'name': BrainActivityDtoEegIncorrectInterictalActivityEnumNameEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof BrainActivityDtoEegIncorrectInterictalActivityEnum
     */
    'regions'?: Array<BrainActivityDtoEegIncorrectInterictalActivityEnumRegionsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum BrainActivityDtoEegIncorrectInterictalActivityEnumElectrodesEnum {
    Fp1 = 'FP1',
    F3 = 'F3',
    C3 = 'C3',
    P3 = 'P3',
    F7 = 'F7',
    T7 = 'T7',
    P7 = 'P7',
    O1 = 'O1',
    F9 = 'F9',
    T9 = 'T9',
    P9 = 'P9',
    Fz = 'FZ',
    Cz = 'CZ',
    Pz = 'PZ',
    Fp2 = 'FP2',
    F4 = 'F4',
    C4 = 'C4',
    P4 = 'P4',
    F8 = 'F8',
    T8 = 'T8',
    P8 = 'P8',
    O2 = 'O2',
    F10 = 'F10',
    T10 = 'T10',
    P10 = 'P10'
}
/**
    * @export
    * @enum {string}
    */
export enum BrainActivityDtoEegIncorrectInterictalActivityEnumLateralizationEnum {
    RightSide = 'RIGHT_SIDE',
    LeftSide = 'LEFT_SIDE',
    BothSide = 'BOTH_SIDE'
}
/**
    * @export
    * @enum {string}
    */
export enum BrainActivityDtoEegIncorrectInterictalActivityEnumNameEnum {
    None = 'NONE',
    UnfocusedActivity = 'UNFOCUSED_ACTIVITY',
    FocusedActivity = 'FOCUSED_ACTIVITY',
    DeltaActivity = 'DELTA_ACTIVITY',
    ThetaActivity = 'THETA_ACTIVITY',
    AlphaActivity = 'ALPHA_ACTIVITY',
    BetaActivity = 'BETA_ACTIVITY',
    GammaActivity = 'GAMMA_ACTIVITY',
    PolymorphicActivity = 'POLYMORPHIC_ACTIVITY',
    FrontalDelta = 'FRONTAL_DELTA',
    OccipitalDelta = 'OCCIPITAL_DELTA',
    TemporalDelta = 'TEMPORAL_DELTA',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum BrainActivityDtoEegIncorrectInterictalActivityEnumRegionsEnum {
    Frontal = 'FRONTAL',
    Temporal = 'TEMPORAL',
    Central = 'CENTRAL',
    Parietal = 'PARIETAL',
    Occipital = 'OCCIPITAL',
    General = 'GENERAL'
}


