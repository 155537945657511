/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UserAccountDto
 */
export interface UserAccountDto {
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserAccountDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'idNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'identityDocumentType'?: UserAccountDtoIdentityDocumentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'locale'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'pesel'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'publicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'secondName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'sex'?: UserAccountDtoSexEnum;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    'timezone'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserAccountDtoIdentityDocumentTypeEnum {
    IdentityCard = 'IDENTITY_CARD',
    Passport = 'PASSPORT'
}
/**
    * @export
    * @enum {string}
    */
export enum UserAccountDtoSexEnum {
    Male = 'MALE',
    Female = 'FEMALE'
}


