/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DoctorSelectionRequestDto } from '../model';
// @ts-ignore
import { MedicalHistoryItemAggDto } from '../model';
// @ts-ignore
import { PagePatientDto } from '../model';
// @ts-ignore
import { PagePatientMoodDto } from '../model';
// @ts-ignore
import { PagePatientOnListDto } from '../model';
// @ts-ignore
import { Pageable } from '../model';
// @ts-ignore
import { PatientAccountInfoDto } from '../model';
// @ts-ignore
import { PatientAccountSummaryDto } from '../model';
// @ts-ignore
import { PatientAdvancedFilter } from '../model';
// @ts-ignore
import { PatientBasicDataDto } from '../model';
// @ts-ignore
import { PatientDashboardAlertsDto } from '../model';
// @ts-ignore
import { PatientDto } from '../model';
// @ts-ignore
import { PatientHealthPlanDto } from '../model';
// @ts-ignore
import { PatientMoodDto } from '../model';
// @ts-ignore
import { PatientPersonalDataSummaryDto } from '../model';
// @ts-ignore
import { PatientSeizureTypeDto } from '../model';
// @ts-ignore
import { PatientSummaryDto } from '../model';
// @ts-ignore
import { SeizuresHistoryDto } from '../model';
// @ts-ignore
import { SetLeadDoctorRequestDto } from '../model';
/**
 * PatientApiControllerApi - axios parameter creator
 * @export
 */
export const PatientApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptPatientPersonalDataSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/patient/personal-data-summary/accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatientMoodDto} patientMoodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPatientMood: async (patientMoodDto: PatientMoodDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientMoodDto' is not null or undefined
            assertParamExists('addPatientMood', 'patientMoodDto', patientMoodDto)
            const localVarPath = `/api/patient/mood`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientMoodDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firstSeizure: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('firstSeizure', 'patientId', patientId)
            const localVarPath = `/api/patient/{patientId}/first-seizure`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientAccountDataSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/patient/account/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientBasicDataByPublicId: async (publicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('getPatientBasicDataByPublicId', 'publicId', publicId)
            const localVarPath = `/api/patient/public/{publicId}/basic`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientByUserAccountId: async (userAccountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAccountId' is not null or undefined
            assertParamExists('getPatientByUserAccountId', 'userAccountId', userAccountId)
            const localVarPath = `/api/patient/user/{userAccountId}`
                .replace(`{${"userAccountId"}}`, encodeURIComponent(String(userAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientDashboardAlerts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/patient/alerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientDataSummary: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientDataSummary', 'patientId', patientId)
            const localVarPath = `/api/patient/{patientId}/summary`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientHealthPlan: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/patient/health-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientManagementInfo: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientManagementInfo', 'patientId', patientId)
            const localVarPath = `/api/patient/{patientId}/management-info`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {string} [since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientMedicalHistoryById: async (patientId: number, since?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientMedicalHistoryById', 'patientId', patientId)
            const localVarPath = `/api/patient/{patientId}/history`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString().substr(0,10) :
                    since;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientMoodList: async (pageable: Pageable, dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getPatientMoodList', 'pageable', pageable)
            const localVarPath = `/api/patient/mood`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientPersonalDataSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/patient/personal-data-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientSeizureTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/patient/seizure-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatientAdvancedFilter} patientAdvancedFilter 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsFilteredAdvanced: async (patientAdvancedFilter: PatientAdvancedFilter, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientAdvancedFilter' is not null or undefined
            assertParamExists('getPatientsFilteredAdvanced', 'patientAdvancedFilter', patientAdvancedFilter)
            const localVarPath = `/api/patient/advanced-search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientAdvancedFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsWithSeizuresCharacteristics: async (pageable: Pageable, searchString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getPatientsWithSeizuresCharacteristics', 'pageable', pageable)
            const localVarPath = `/api/patient/seizuresCharacteristics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalInfo: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPersonalInfo', 'patientId', patientId)
            const localVarPath = `/api/patient/{patientId}/personal-info`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DoctorSelectionRequestDto} doctorSelectionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerDoctorSelectionRequest: async (doctorSelectionRequestDto: DoctorSelectionRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorSelectionRequestDto' is not null or undefined
            assertParamExists('registerDoctorSelectionRequest', 'doctorSelectionRequestDto', doctorSelectionRequestDto)
            const localVarPath = `/api/patient/select/doctor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorSelectionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {SetLeadDoctorRequestDto} setLeadDoctorRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLeadDoctor: async (patientId: number, setLeadDoctorRequestDto: SetLeadDoctorRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('setLeadDoctor', 'patientId', patientId)
            // verify required parameter 'setLeadDoctorRequestDto' is not null or undefined
            assertParamExists('setLeadDoctor', 'setLeadDoctorRequestDto', setLeadDoctorRequestDto)
            const localVarPath = `/api/patient/{patientId}/leadDoctor`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setLeadDoctorRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientApiControllerApi - functional programming interface
 * @export
 */
export const PatientApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptPatientPersonalDataSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientPersonalDataSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptPatientPersonalDataSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PatientMoodDto} patientMoodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPatientMood(patientMoodDto: PatientMoodDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientMoodDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPatientMood(patientMoodDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firstSeizure(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<SeizuresHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firstSeizure(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientAccountDataSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientAccountSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientAccountDataSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientBasicDataByPublicId(publicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientBasicDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientBasicDataByPublicId(publicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientByUserAccountId(userAccountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientByUserAccountId(userAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientDashboardAlerts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientDashboardAlertsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientDashboardAlerts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientDataSummary(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientDataSummary(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientHealthPlan(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientHealthPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientHealthPlan(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientManagementInfo(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientAccountInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientManagementInfo(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {string} [since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientMedicalHistoryById(patientId: number, since?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<MedicalHistoryItemAggDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientMedicalHistoryById(patientId, since, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientMoodList(pageable: Pageable, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PagePatientMoodDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientMoodList(pageable, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientPersonalDataSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientPersonalDataSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientPersonalDataSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientSeizureTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<PatientSeizureTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientSeizureTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PatientAdvancedFilter} patientAdvancedFilter 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientsFilteredAdvanced(patientAdvancedFilter: PatientAdvancedFilter, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PagePatientOnListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientsFilteredAdvanced(patientAdvancedFilter, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientsWithSeizuresCharacteristics(pageable: Pageable, searchString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PagePatientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientsWithSeizuresCharacteristics(pageable, searchString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonalInfo(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientAccountInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonalInfo(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DoctorSelectionRequestDto} doctorSelectionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerDoctorSelectionRequest(doctorSelectionRequestDto: DoctorSelectionRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorSelectionRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerDoctorSelectionRequest(doctorSelectionRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {SetLeadDoctorRequestDto} setLeadDoctorRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setLeadDoctor(patientId: number, setLeadDoctorRequestDto: SetLeadDoctorRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setLeadDoctor(patientId, setLeadDoctorRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientApiControllerApi - factory interface
 * @export
 */
export const PatientApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptPatientPersonalDataSummary(options?: any): Promise<PatientPersonalDataSummaryDto> {
            return localVarFp.acceptPatientPersonalDataSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PatientMoodDto} patientMoodDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPatientMood(patientMoodDto: PatientMoodDto, options?: any): Promise<PatientMoodDto> {
            return localVarFp.addPatientMood(patientMoodDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firstSeizure(patientId: number, options?: any): Promise<SeizuresHistoryDto> {
            return localVarFp.firstSeizure(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientAccountDataSummary(options?: any): Promise<PatientAccountSummaryDto> {
            return localVarFp.getPatientAccountDataSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientBasicDataByPublicId(publicId: string, options?: any): Promise<PatientBasicDataDto> {
            return localVarFp.getPatientBasicDataByPublicId(publicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientByUserAccountId(userAccountId: number, options?: any): Promise<PatientDto> {
            return localVarFp.getPatientByUserAccountId(userAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientDashboardAlerts(options?: any): Promise<PatientDashboardAlertsDto> {
            return localVarFp.getPatientDashboardAlerts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientDataSummary(patientId: number, options?: any): Promise<PatientSummaryDto> {
            return localVarFp.getPatientDataSummary(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientHealthPlan(options?: any): Promise<PatientHealthPlanDto> {
            return localVarFp.getPatientHealthPlan(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientManagementInfo(patientId: number, options?: any): Promise<PatientAccountInfoDto> {
            return localVarFp.getPatientManagementInfo(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {string} [since] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientMedicalHistoryById(patientId: number, since?: string, options?: any): Promise<Array<MedicalHistoryItemAggDto>> {
            return localVarFp.getPatientMedicalHistoryById(patientId, since, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientMoodList(pageable: Pageable, dateFrom?: string, dateTo?: string, options?: any): Promise<PagePatientMoodDto> {
            return localVarFp.getPatientMoodList(pageable, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientPersonalDataSummary(options?: any): Promise<PatientPersonalDataSummaryDto> {
            return localVarFp.getPatientPersonalDataSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientSeizureTypes(options?: any): Promise<Array<PatientSeizureTypeDto>> {
            return localVarFp.getPatientSeizureTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PatientAdvancedFilter} patientAdvancedFilter 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsFilteredAdvanced(patientAdvancedFilter: PatientAdvancedFilter, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<PagePatientOnListDto> {
            return localVarFp.getPatientsFilteredAdvanced(patientAdvancedFilter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsWithSeizuresCharacteristics(pageable: Pageable, searchString?: string, options?: any): Promise<PagePatientDto> {
            return localVarFp.getPatientsWithSeizuresCharacteristics(pageable, searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalInfo(patientId: number, options?: any): Promise<PatientAccountInfoDto> {
            return localVarFp.getPersonalInfo(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DoctorSelectionRequestDto} doctorSelectionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerDoctorSelectionRequest(doctorSelectionRequestDto: DoctorSelectionRequestDto, options?: any): Promise<DoctorSelectionRequestDto> {
            return localVarFp.registerDoctorSelectionRequest(doctorSelectionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {SetLeadDoctorRequestDto} setLeadDoctorRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLeadDoctor(patientId: number, setLeadDoctorRequestDto: SetLeadDoctorRequestDto, options?: any): Promise<void> {
            return localVarFp.setLeadDoctor(patientId, setLeadDoctorRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientApiControllerApi - object-oriented interface
 * @export
 * @class PatientApiControllerApi
 * @extends {BaseAPI}
 */
export class PatientApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public acceptPatientPersonalDataSummary(options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).acceptPatientPersonalDataSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PatientMoodDto} patientMoodDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public addPatientMood(patientMoodDto: PatientMoodDto, options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).addPatientMood(patientMoodDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public firstSeizure(patientId: number, options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).firstSeizure(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public getPatientAccountDataSummary(options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).getPatientAccountDataSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public getPatientBasicDataByPublicId(publicId: string, options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).getPatientBasicDataByPublicId(publicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public getPatientByUserAccountId(userAccountId: number, options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).getPatientByUserAccountId(userAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public getPatientDashboardAlerts(options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).getPatientDashboardAlerts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public getPatientDataSummary(patientId: number, options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).getPatientDataSummary(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public getPatientHealthPlan(options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).getPatientHealthPlan(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public getPatientManagementInfo(patientId: number, options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).getPatientManagementInfo(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {string} [since] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public getPatientMedicalHistoryById(patientId: number, since?: string, options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).getPatientMedicalHistoryById(patientId, since, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public getPatientMoodList(pageable: Pageable, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).getPatientMoodList(pageable, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public getPatientPersonalDataSummary(options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).getPatientPersonalDataSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public getPatientSeizureTypes(options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).getPatientSeizureTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PatientAdvancedFilter} patientAdvancedFilter 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public getPatientsFilteredAdvanced(patientAdvancedFilter: PatientAdvancedFilter, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).getPatientsFilteredAdvanced(patientAdvancedFilter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public getPatientsWithSeizuresCharacteristics(pageable: Pageable, searchString?: string, options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).getPatientsWithSeizuresCharacteristics(pageable, searchString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public getPersonalInfo(patientId: number, options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).getPersonalInfo(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DoctorSelectionRequestDto} doctorSelectionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public registerDoctorSelectionRequest(doctorSelectionRequestDto: DoctorSelectionRequestDto, options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).registerDoctorSelectionRequest(doctorSelectionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {SetLeadDoctorRequestDto} setLeadDoctorRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiControllerApi
     */
    public setLeadDoctor(patientId: number, setLeadDoctorRequestDto: SetLeadDoctorRequestDto, options?: AxiosRequestConfig) {
        return PatientApiControllerApiFp(this.configuration).setLeadDoctor(patientId, setLeadDoctorRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
