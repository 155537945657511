import Countries from "../countries/countries";

export default {
  "SeizureTypeEnum": {
    "IA": "Crise focale simple",
    "IB": "Crise complexe focale",
    "IC": "La crise focale se déployant en",
    "IIA": "Absence épileptique",
    "IIB": "Crise mioclonique",
    "IIC": "Crise tonique",
    "IID": "Crise clonique",
    "IIE": "Crise tonico-clonique",
    "IIF": "Crise atonique",
    "GEN": "Crise générée",
    "UNK": "Saisie inconnue"
  },
  "SeizureTypeBriefEnum": {
    "IA": "Crise focale simple",
    "IB": "Crise complexe focale",
    "IC": "La crise focale se déployant en",
    "IIA": "Absence",
    "IIB": "Mioclonique",
    "IIC": "Tonique",
    "IID": "Clonique",
    "IIE": "Tonique-clonique",
    "IIF": "Atonique",
    "GEN": "Généré",
    "UNK": "Inconnue"
  },
  "MedicalEventTypeEnum": {
    "SEIZURE": "Crise d'épilepsie",
    "MENSTRUATION": "Menstruation",
    "INFECTION": "Infection",
    "SLEEP_DEPRIVATION": "Privation de sommeil",
    "FEVER": "Fièvre",
    "OTHER": "Autre",
    "PREGNANCY_OR_PRE_PREGNANCY_PERIOD_START": "Début de grossesse ou période pré-grossesse",
    "PREGNANCY_OR_PRE_PREGNANCY_PERIOD_END": "Fin de grossesse ou période pré-grossesse"
  },
  "MessageTypeEnum": {
    "EMAIL": "email",
    "SMS": "SMS",
    "SYS": "système",
    "CHAT": "bavarder"
  },
  "MessageStatusEnum": {
    "NEW": "Nouveau",
    "SENT": "expédié",
    "READ": "lis"
  },
  "MessageMarkerEnum": {
    "MARKED": "marqué",
    "NOT_MARKED": "non marqué"
  },
  "AffinityTypeEnumAdults": {
    "LEGAL_GUARDIAN": "tuteur légal",
    "CURATOR": "conservateur",
    "SPOUSE": "époux"
  },
  "AffinityTypeEnumChildren": {
    "PARENT": "parent",
    "ADOPTER": "adoter",
    "GUARDIAN": "tuteur désigné"
  },
  "VisitTypeEnumUndefined": "Autre",
  "DocumentTypeEnum": {
    "REFERRAL_GENERAL_PURPOSE": "Référence générale",
    "REFERRAL_CLINIC": "Orientation vers une clinique spécialisée",
    "REFERRAL_TO_HOSPITAL": "Orientation vers l'hôpital",
    "REFERRAL_TO_PSYCHIATRIC_HOSPITAL": "Orientation vers un hôpital psychiatrique",
    "REFERRAL_TO_SPECIALIST": "Orientation vers un spécialiste",
    "REFERRAL_TO_LAB": "Référence au laboratoire",
    "REFERRAL_TO_DIAGNOSTIC_LABORATORY": "Orientation vers un laboratoire de diagnostic",
    "CERTIFICATE": "Certificat",
    "CERTIFICATE_FOR_ZUS": "Certificat pour ZUS",
    "CERTIFICATE_OF_DISABILITY": "Certificat d'invalidité",
    "HEALTH_CERTIFICATE": "Certificat sanitaire",
    "INFORMATION_FOR_POZ_DOCTOR": "Informations pour le médecin POZ",
    "APPLICATION_FOR_REFUND": "Demande de remboursement",
    "APPLICATION_FOR_DRUG_REFUND": "Demande de remboursement de médicaments",
    "DEMAND_FOR_DRUG_FROM_ABROAD": "Demande d'un médicament de l'étranger",
    "DEMAND_FOR_FOODSTUFF_FROM_ABROAD": "Demande de denrées alimentaires de l'étranger",
    "OTHER": "Autres documents"
  },
  "MedicalResultDocumentTypeEnum": {
    "HOSPITAL_INFORMATION_CARD": "Carte d'information sur les traitements hospitaliers",
    "DISEASE_HISTORY": "Histoire de la maladie",
    "VIDEO_EVENT": "Vidéo - événement",
    "LABORATORY_TEST_RESULT": "Résultat du test de laboratoire",
    "VISUAL_RESULT_MRI_FILE": "Fichier de résultats de test - résonance magnétique",
    "VISUAL_RESULT_MRI_DESC": "Description du résultat du test - résonance magnétique",
    "VISUAL_RESULT_CT_FILE": "Fichier de résultats de test - tomographie par ordinateur",
    "VISUAL_RESULT_CT_DESC": "Description du résultat du test - tomographie par ordinateur",
    "VISUAL_RESULT_EEG_FILE": "Fichier de résultats de test - EEG",
    "VISUAL_RESULT_EEG_DESC": "Description des résultats de test - EEG",
    "VISUAL_RESULT_ADDITIONAL_FILE": "Fichier de résultats de test supplémentaire",
    "VISUAL_RESULT_ADDITIONAL_DESC": "Description supplémentaire des résultats de test",
    "PATIENT_CERTIFICATE": "Certificat Neurosphera",
    "STATE_CERTIFICATE": "Certificat d'État",
    "EEG_DESCRIPTION": "Description de l'EEG",
    "OTHER": "Autre"
  },
  "DoctorTitle": {
    "DR_MED": "dr n. med.",
    "DR_HAB": "dr hab.",
    "DR_PROF": "prof dr hab.",
    "LEK_MED": "lek. méd.",
    "TECHN": "technique.",
    "MGR": "directeur",
    "DR": "docteur",
    "STUDENT": "étudiant UM"
  },
  "PricelistItemGroupEnum": {
    "NORMAL_VISIT": "visite normale",
    "SOS_VISIT": "visite sos",
    "DIAGNOSTIC": "Description du test",
    "OTHER": "autre"
  },
  "PricelistItemTypeEnum": {
    "FIRST_TIME": "pierwszorazowa",
    "SUBSEQUENT": "Kolejna",
    "OTHER": "inne"
  },
  "SexEnum": {
    "FEMALE": "Femme",
    "MALE": "Masculin"
  },
  "SeizureFrequencyPeriodEnum": {
    "YEAR": "an",
    "MONTH": "mois",
    "DAY": "journée"
  },
  "BloodType": {
    "TYPE_A": "UNE",
    "TYPE_B": "B",
    "TYPE_AB": "UN B",
    "TYPE_0": "0"
  },
  "RhType": {
    "RH_PLUS": "RH +",
    "RH_MINUS": "RH-"
  },
  "PsychomotorDevelopmentOverallEnum": {
    "NORMAL": "Ordinaire",
    "RETARDED": "Retardé",
    "VERY_RETARDED": "Très retardé",
    "NOT_APPLICABLE": "N'est pas applicable"
  },
  "PsychomotorRetardationEnum": {
    "NO": "Non",
    "LIGHT": "Lumière",
    "MODERATE": "Modérer",
    "SIGNIFICANT": "Important"
  },
  "PsychomotorSpeechEnum": {
    "NORMAL": "Ordinaire",
    "DISTURBED": "Perturbé",
    "NOT_APPLICABLE": "N'est pas applicable"
  },
  "PsychomotorWalkEnum": {
    "NORMAL": "Ordinaire",
    "INCORRECT": "Incorrect",
    "NOT_APPLICABLE": "N'est pas applicable"
  },
  "PsychomotorSexEnum": {
    "NORMAL": "Ordinaire",
    "LIBIDO_DISORDERS": "Troubles de la libido",
    "NOT_APPLICABLE": "N'est pas applicable"
  },
  "NystagmusEnum": {
    "NO": "Non",
    "VESTIBULAR": "Vestibulaire",
    "CNS": "CNS"
  },
  "VisionEnum": {
    "NORMAL": "Ordinaire",
    "DEFECT_PLUS": "Défaut de vision plus (+)",
    "DEFECT_MINUS": "Défaut de vision moins (-)",
    "DEFECT_OTHER": "Autre défaut de vision",
    "BLIND": "Aveugle",
    "NOT_APPLICABLE": "N'est pas applicable"
  },
  "HearingEnum": {
    "NORMAL": "Ordinaire",
    "DISTURBED": "Perturbé",
    "NOT_APPLICABLE": "N'est pas applicable"
  },
  "SmokingEnum": {
    "NO": "Non",
    "YES_IN_PAST": "Oui, dans le passé",
    "YES_NOW": "Oui, actuellement",
    "NOT_APPLICABLE": "N'est pas applicable"
  },
  "AlcoholEnum": {
    "NO": "Non",
    "SOMETIMES_LITTLE": "Parfois peu",
    "SOMETIMES_A_LOT": "Parfois beaucoup",
    "OFTEN_LITTLE": "Souvent peu",
    "OFTEN_A_LOT": "Souvent beaucoup",
    "NOT_APPLICABLE": "N'est pas applicable"
  },
  "NarcoticsEnum": {
    "NO": "Non",
    "YES_IN_PAST": "Oui, dans le passé",
    "YES_NOW_RARELY": "Oui, actuellement - occasionnellement",
    "YES_NOW_OFTEN": "Oui, obecnie - souvent",
    "NOT_APPLICABLE": "N'est pas applicable"
  },
  "SeizuresCharacteristicsFrequencyEnum": {
    "ALWAYS": "Oui (1 crise) / Oui, toujours (100% de crises)",
    "VERY_OFTEN": "Oui, très souvent (75 à 99% des crises)",
    "OFTEN": "Oui, souvent (51 à 74% des crises)",
    "SOMETIMES": "Oui, parfois (~ 50% des crises)",
    "RARELY": "Oui, mais rarement (25 à 49% des crises)",
    "VERY_RARELY": "Oui, mais extrêmement rare (1 à 24% des crises)",
    "NEVER": "Non (1 crise) / Non, jamais (0% de crises)",
    "NOT_KNOWN": "Pas connu"
  },
  "DrugHistoryTimeEnum": {
    "PAST": "Oui, dans le passé",
    "CURRENTLY": "Oui, actuellement"
  },
  "DrugHistoryEffectEnum": {
    "REMISSION": "Rémission",
    "BIG_IMPROVEMENT": "Amélioration> 50%",
    "LITTLE_IMPROVEMENT": "Jusqu'à 50% d'amélioration",
    "NO_CHANGE": "Pas de changement",
    "LITTLE_DETERIORATION": "Légère détérioration",
    "BIG_DETERIORATION": "Détérioration importante",
    "EXTREME_DETERIORATION": "Effets secondaires de détérioration / élimination extrêmes (par exemple éruption cutanée)"
  },
  "SeizuresCharacteristicsDurationEnum": {
    "VERY_LONG": "Très long, plus de 5 minutes",
    "LONG": "Long, de 1 à 5 minutes",
    "BIT_LONG": "30 à 60 secondes",
    "VARIABLE": "Complètement différent, modifiable",
    "SHORT": "Brièvement de quelques à 30 secondes",
    "VERY_SHORT": "Très court, moins de 5 s",
    "ULTRA_SHORT": "Ultra-court, une fraction de seconde à une seconde",
    "NOT_KNOWN": "Je ne sais pas"
  },
  "UserAccountSettingCodeEnum": {
    "VISIT_CHANGED_BY_PATIENT": "Alertes lorsque le patient change une heure de visite",
    "VISIT_APPOINTED_REMINDER": "Rappels sur les visites prévues le lendemain",
    "VISIT_PLANNED_REMINDER": "Rappels sur les visites prévues le lendemain",
    "DUTY_PLANNED_REMINDER": "Rappels sur les tâches prévues le lendemain",
    "VISIT_CANCELED_BY_DOCTOR": "Alertes lorsque le médecin annule une visite",
    "VISIT_CANCELLED_BY_PATIENT": "Alertes lorsque le patient annule une visite",
    "ADMIT_PATIENTS": "Visible pour les patients dans la base de données des médecins",
    "MESSAGE_RECEIVED_BY_DOCTOR": "Alerte lorsque le patient envoie un message",
    "PRESCRIPTION_REQUEST_RECEIVED_BY_DOCTOR": "Alertes lorsque le patient envoie une demande de prescription"
  },
  "VisitCancellationReason": {
    "INCONVENIENT_DATE": "Date peu pratique",
    "DOCTOR_CHANGE": "Changement de médecin"
  },
  "CountryEnum": Countries.fr,
  "MedicalServiceSystemItemEnum": {
    "SOS_TELECONFERENCE": "Téléconférence SOS",
    "SOS_CHAT": "Chat SOS"
  },
  "MedicalServiceEnum": {
    "VISIT_0": "Visite 0",
    "MIGRATION_VISIT": "Visite migratoire",
    "VISIT_1": "Visite 1",
    "STANDARD_VISIT": "Visite standard",
    "MESSAGE": "Un message",
    "PRESCRIPTION": "Ordonnance",
    "NEUROSURGEON_CONSULTATION": "Consultation - neurochirurgien",
    "NEUROPSYCHOLOGIST_CONSULTATION": "Consultation - neuropsychologue",
    "NEUROLOGOPEDIST_CONSULTATION": "Consultation - neurologopédiste",
    "PSYCHIATRIST_CONSULTATION": "Consultation - psychiatre",
    "EPILEPTOLOGIST_CONSULTATION": "Consultation - épileptologue",
    "EEG_CONSULTATION": "EEG de Badanie",
    "NEUROLOGIST_CONSULTATION": "Consultation - neurologue",
    "DIETICIAN_CONSULTATION": "Consultation - diététicienne",
    "VNS_CONSULTATION": "visite VNS",
    "GENETICIST_CONSULTATION": "Consultation - généticien",
    "ABSENT_CONSULTATION": "Consultation absente",
    "METABOLIC_CONSULTATION": "Consultation métabolique",
    "VEEG_ON_STANDBY_CONSULTATION": "Examen VEEG en éveil 1 - 6 h",
    "VEEG_DURING_SLEEP_CONSULTATION": "VEEG pendant l'examen du sommeil 1 -6 h",
    "VEEG_NIGHT_CONSULTATION": "Examen nocturne VEEG 12 h",
    "VEEG_DAY_CONSULTATION": "Examen VEEG 24h 24h",
    "SLEEP_DISTURBANCE_CONSULTATION": "Consultation sur les troubles du sommeil",
    "CASE_CONFERENCE": "Conférence de cas médical",
    "VEEG_ON_STANDBY_CONSULTATION_1H": "Examen VEEG en éveil 1 h",
    "VEEG_ON_STANDBY_CONSULTATION_2H": "Examen VEEG en éveil 2 h",
    "VEEG_ON_STANDBY_CONSULTATION_6H": "Examen VEEG en éveil 6 h",
    "VEEG_DURING_SLEEP_CONSULTATION_1H": "Examen VEEG pendant le sommeil 1 h",
    "VEEG_DURING_SLEEP_CONSULTATION_2H": "Examen VEEG pendant le sommeil 2 h",
    "VEEG_DURING_SLEEP_CONSULTATION_6H": "Examen VEEG pendant le sommeil 6 h"
  },
  "VisitTypeEnum": {
    "STATIONARY": "Stationnaire",
    "REMOTE": "À distance",
    "ABSENT": "Absent"
  },
  "VisitTypeDescriptionEnum": {
    "STATIONARY": "La visite a lieu dans une clinique externe à {place}. Lors de la visite, le médecin rencontre directement le patient. Sur place, il examine la documentation actuelle du patient. Si une ordonnance est délivrée - le médecin délivre une ordonnance sur place et l'envoie par SMS ou l'imprime.",
    "REMOTE": "Visite en ligne où le médecin et le patient se voient à l'écran. La connexion se fait via Neuroterminal et ne nécessite pas l'installation de logiciel supplémentaire. La documentation est envoyée par le patient par voie électronique via Neuroterminal. Si une ordonnance est requise, le médecin émettra une e-prescription.",
    "ABSENT": "Une consultation d'absence est un type de visite médicale effectuée par un spécialiste sélectionné sans la présence du patient. Une consultation d'absence vise l'analyse du dossier médical ou un examen complémentaire. Le spécialiste de Neurosphère analysera la documentation, rédigera une note appropriée et des recommandations pour le patient."
  },
  "MedicalResultTypeEnum": {
    "VIDEO_EVENT": "Vidéo",
    "LABORATORY_TEST_RESULT": "Test de laboratoire",
    "VISUAL_RESULT_MRI_FILE": "Résonance magnétique",
    "VISUAL_RESULT_MRI_DESC": "Résonance magnétique",
    "VISUAL_RESULT_CT_FILE": "Tomographie informatique",
    "VISUAL_RESULT_CT_DESC": "Tomographie informatique",
    "VISUAL_RESULT_EEG_FILE": "EEG",
    "VISUAL_RESULT_EEG_DESC": "EEG",
    "VISUAL_RESULT_ADDITIONAL_FILE": "Supplémentaire",
    "VISUAL_RESULT_ADDITIONAL_DESC": "Supplémentaire"
  },
  "DoctorTypeEnum": {
    "RESIDENT": "Résident clinique",
    "SPECIALIST": "Spécialiste clinique",
    "EXPERT": "Partenaire Médical",
    "CONSULTANT": "Consultante clinique",
    "ASSISTANT": "Assistant",
    "INTERNATIONAL_CONSULTANT": "Consultante clinique internationale",
    "PARTNER_CONSULTANT": "Consultant partenaire"
  },
  "PatientTypeEnum": {
    "INFANT": "enfants jusqu'à 2 ans",
    "CHILD": "enfants de 2 à 12 ans",
    "YOUTH": "jeunes de 12 à 18 ans",
    "ADULT": "adultes de 18 à 65 ans",
    "SENIOR": "seniors de plus de 65 ans"
  },
  "HasEpilepsyEnum": {
    "NO": "Non",
    "YES": "Oui",
    "NOT_KNOWN": "Pas connu"
  },
  "HealthSurveyPartEnum": {
    "PSYCHOMOTOR_DEVELOPMENT": "développement psychomoteur",
    "SEIZURE_TYPES": "types de crises",
    "SEIZURES_HISTORY": "historique des crises",
    "SEIZURES_CHARACTERISTICS": "caractéristiques des crises",
    "COMORBIDITIES": "comorbidités",
    "DRUGS_HISTORY": "traitement"
  },
  "TimeRangeEnum": {
    "1M": "1 mois",
    "3M": "3 mois",
    "6M": "6 mois",
    "1Y": "1 année",
    "2Y": "2 ans",
    "3Y": "3 années",
    "SINCE_BIRTH": "Depuis la naissance",
    "SINCE_FIRST_SEIZURE": "Depuis la première saisie",
    "1H": "1 heure",
    "2H": "2 heures",
    "3H": "3 heures",
    "4H": "4 heures",
    "5H": "5 heures",
    "6H": "6 heures"
  },
  "MedicalRequestStatusEnum": {
    "NEW": "Nouvelle",
    "ACCEPTED": "Accepté",
    "DONE": "Terminé",
    "REJECTED": "Rejeté",
    "CANCELLED": "Annulé"
  },
  "PersonalDocumentEnum": {
    "PASSPORT": "Passeport",
    "DRIVING_LICENSE": "Permis de conduire"
  },
  "NSPHSourceEnum": {
    "INTERNET_SEARCH": "recherche Internet",
    "SOCIAL_MEDIA": "Des médias sociaux",
    "INTERNET_ARTICLE": "article Internet",
    "PRESS_ARTICLE": "Article de presse",
    "TV": "Télévision",
    "FAMILY_FRIENDS": "De la famille ou des amis",
    "DOCTOR": "Du docteur",
    "OTHER": "Autre"
  },
  "NFZBranchEnum": {
    "NFZ_01": "01 - Branche de Basse-Silésie de la Caisse nationale de santé à Wrocław",
    "NFZ_02": "02 - Branche Kujawsko-Pomorskie du Fonds national de la santé à Bydgoszcz",
    "NFZ_03": "03 - Branche Lublin de la Caisse nationale de santé à Lublin",
    "NFZ_04": "04 - Branche Lubuski de la Caisse nationale de santé à Zielona Góra",
    "NFZ_05": "05 - ódź Branche de la Caisse nationale de santé à Łódź",
    "NFZ_06": "06 - Branche Małopolska de la Caisse nationale de santé à Cracovie",
    "NFZ_07": "07 - Branche Mazowiecki de la Caisse nationale de santé à Varsovie",
    "NFZ_08": "08 - Opole Branche de la Caisse Nationale de Santé à Opole",
    "NFZ_09": "09 - Branche Podkarpackie de la Caisse nationale de santé à Rzeszów",
    "NFZ_10": "10 - Branche Podlaski de la Caisse nationale de santé à Białystok",
    "NFZ_11": "11 - Branche poméranienne de la Caisse nationale de santé à Gdańsk",
    "NFZ_12": "12 - Branche silésienne de la Caisse nationale de santé à Katowice",
    "NFZ_13": "13 - Branche Świętokrzyski de la Caisse nationale de santé à Kielce",
    "NFZ_14": "14 - Branche Warmie et Mazurie de la Caisse nationale de santé à Olsztyn",
    "NFZ_15": "15 - Branche Wielkopolska de la Caisse nationale de santé à Poznań",
    "NFZ_16": "16 - Branche de Poméranie occidentale de la Caisse nationale de santé à Szczecin"
  },
  "VisitPackageEnum": {
    "START_PACKAGE": "Sondage 0 + Visite 1",
    "ADHD_PACKAGE": "Forfait TDAH",
    "REGULAR_VISIT_EEG_PACKAGE": "Visite régulière + EEG",
    "MMPI2_PACKAGE": "Test de personnalité MMPI-2",
    "CBT_PACKAGE": "Thérapie cognitivo-comportementale (TCC)"
  },
  "MedicalServiceGroupEnum": {
    "VISIT_0": "Enquête 0",
    "MIGRATION_VISIT": "Visite migratoire",
    "VISIT_1": "Visite 1",
    "STANDARD_VISIT": "Visite standard",
    "MESSAGE": "Un message",
    "PRESCRIPTION": "Ordonnance",
    "NEUROSURGEON_CONSULTATION": "Consultation - neurochirurgien",
    "NEUROPSYCHOLOGIST_CONSULTATION": "Consultation - neuropsychologue",
    "NEUROLOGOPEDIST_CONSULTATION": "Consultation - neurologopédiste",
    "PSYCHIATRIST_CONSULTATION": "Consultation - psychiatre",
    "EPILEPTOLOGIST_CONSULTATION": "Consultation - épileptologue",
    "NEUROLOGIST_CONSULTATION": "Consultation - neurologue",
    "DIETICIAN_CONSULTATION": "Consultation - diététicienne",
    "VNS_CONSULTATION": "Visite VNS",
    "GENETICIST_CONSULTATION": "Consultation - généticien",
    "ABSENT_CONSULTATION": "Consultation absente",
    "METABOLIC_CONSULTATION": "Consultation métabolique",
    "EEG_CONSULTATION": "Examen EEG",
    "SLEEP_DISTURBANCE_CONSULTATION": "Consultation sur les troubles du sommeil",
    "PARTNER_CONSULTATION": "Consultation des partenaires"
  },
  "ShortTimeRangeEnum": {
    "h": "h",
    "m": "min"
  },
  "WellBeingEnum": {
    "WORST": "maximum pire / mauvais",
    "MUCH_WORSE": "bien pire",
    "BIT_WORSE": "un peu pire",
    "SAME": "même",
    "BIT_BETTER": "un peu mieux",
    "MUCH_BETTER": "beaucoup mieux",
    "IDEAL": "parfaite et pleine santé"
  },
  "CurrentTreatmentEnum": {
    "WORST": "totalement mauvais, ça ne marche pas et je ne veux pas continuer",
    "VERY_BAD": "pas bon je préfère le changer",
    "BAD": "Je ne vais pas bien, mais je peux continuer",
    "NO_CHANGE": "je ne ressens aucun changement",
    "BIT_BETTER": "ça va, je me sens un peu mieux avec ce traitement",
    "MUCH_BETTER": "il y a une amélioration, le traitement aide clairement",
    "IDEAL": "le traitement est parfait, pleine réussite"
  },
  "SideEffectsEnum": {
    "WORST": "ils sont si graves que je ne veux pas continuer ce traitement à coup sûr",
    "BAD": "ils sont considérables et me dérangent, mais si nécessaire je peux continuer",
    "BEARABLE": "ils sont perceptibles, mais pas si mal de changer de traitement",
    "MINIMAL": "c'est OK, les effets secondaires sont minimes, aucun effet sur moi"
  },
  "TreatmentEfficiencyEnum": {
    "IDEAL": "Parfait",
    "GOOD": "Bien",
    "MEDIOCRE": "Médiocre",
    "BAD": "Mal",
    "WORST": "Pire, besoin de changer de traitement"
  },
  "TreatmentSafetyEnum": {
    "TOTAL": "Total",
    "ACCEPTABLE": "Acceptable",
    "WEAK": "Faible",
    "BAD": "Mal",
    "WORST": "Élimine la drogue, changement nécessaire"
  },
  "AdditionalIncidentsEnum": {
    "NEW_DISEASES": "Nouvelles maladies",
    "INJURIES": "Blessures",
    "HOSPITALIZATIONS": "Hospitalisation",
    "OTHER": "Autre",
    "NONE": "Aucun"
  },
  "SeizureCountEnum": {
    "MORE": "Suite",
    "LESS": "Moins",
    "SAME": "Même",
    "NONE": "Aucun"
  },
  "SeizureEvaluationEnum": {
    "WORSE": "Pire",
    "BETTER": "Plus léger",
    "NONE": "Aucun"
  },
  "AllSeizureRegisteredEnum": {
    "TRUE": "Je les ai tous enregistrés",
    "FALSE": "Je ne les ai pas enregistrés - je vais le faire maintenant"
  },
  "ExternalReferralTypeEnum": {
    "CLINIC": "à la clinique spécialisée",
    "LABORATORY": "au laboratoire de diagnostic",
    "HOSPITAL": "pour traitement hospitalier"
  },
  "CertificateTypeEnum": {
    "DRUGS_INTAKE": "Un certificat au centre de santé sur les médicaments pris",
    "POSTPONE_VACCINATION": "Attestation de report de vaccination",
    "NO_CONTRAINDICATION_FOR_VACCINE": "Certificat de non contre-indication aux vaccinations",
    "NO_CONTRAINDICATION_FOR_PROCEDURE": "Certificat de non contre-indication à la procédure",
    "NO_CONTRAINDICATION_FOR_ACTIVITIES": "Certificat de non contre-indication pour participer aux cours",
    "NO_CONTRAINDICATION_FOR_WORK": "Certificat qu'il n'y a pas de contre-indications pour effectuer un travail physique",
    "OTHER": "Autre"
  },
  "CertificateTypeDescriptionEnum": {
    "DRUGS_INTAKE": "Je certifie par la présente que le patient est pris en charge par le Centre de traitement de l'épilepsie Neurosphera. Je demande la prescription de médicaments au lieu de résidence. Le patient prend les médicaments suivants",
    "POSTPONE_VACCINATION": "Je certifie par la présente que le patient est pris en charge par le Centre de traitement de l'épilepsie Neurosphera. Je demande le report des vaccinations jusqu'à",
    "NO_CONTRAINDICATION_FOR_VACCINE": "Je certifie par la présente que le patient est pris en charge par le Centre de traitement de l'épilepsie Neurosphera. Il n'y a pas de contre-indications aux vaccinations obligatoires",
    "NO_CONTRAINDICATION_FOR_PROCEDURE": "Je certifie par la présente que le patient est pris en charge par le Centre de traitement de l'épilepsie Neurosphera. Il n'y a pas de contre-indications à l'anesthésie générale pendant la procédure prévue",
    "NO_CONTRAINDICATION_FOR_ACTIVITIES": "Je certifie par la présente que le patient est pris en charge par le Centre de traitement de l'épilepsie Neurosphera. Il n'y a pas de contre-indications pour participer aux activités",
    "NO_CONTRAINDICATION_FOR_WORK": "Je certifie par la présente que le patient est pris en charge par le Centre de traitement de l'épilepsie Neurosphera. Il n'y a pas de contre-indications au travail physique.",
    "OTHER": "Je certifie par la présente que le patient est pris en charge par Neurosphera Epilepsy Therapy Center."
  },
  "MessageVisitSummaryStatusEnum": {
    "NEW": "Non réalisé",
    "PENDING": "En attente de réponse",
    "RESPONDED": "A répondu",
    "DONE": "Fait",
    "UNIDIRECTIONAL": "Unidirectionnel"
  },
  "SickLeaveTypeEnum": {
    "DISEASE": "Maladie",
    "CARE": "Prendre soin des malades"
  },
  "SickLeaveIndicationEnum": {
    "SHOULD_LIE": "Devrait mentir",
    "CAN_WALK": "Peut marcher"
  },
  "SickLeaveInsuredInEnum": {
    "ZUS": "ZUS",
    "KRUS": "KRUS",
    "OTHER_PL": "autre en Pologne",
    "OTHER_COUNTRY": "dans un autre pays"
  },
  "SickLeaveStatusEnum": {
    "ISSUED": "Publié",
    "CANCELED": "Annulé"
  },
  "SurveyCompletedEnum": {
    "YES": "Oui",
    "NO": "Non",
    "PARTIALLY": "À moitié terminé"
  },
  "EegCompletedEnum": {
    "YES": "Oui",
    "NO": "Non",
    "BEFORE_NEXT": "Fera avant la visite 1"
  },
  "DocplannerIssueTypeEnum": {
    "DP_VISIT_NOT_BOOKED": "Visite non réservée en ZN",
    "DP_VISIT_NOT_CANCELED": "Visite non annulée en ZN",
    "DP_VISIT_NOT_MOVED": "Visite non déplacée en ZN",
    "NOT_ELIGIBLE_FOR_CONSULTATION": "Nouveau patient - consultation"
  },
  "DocplannerVisitStatusEnum": {
    "NEW": "Nouveau",
    "CANCELED_BY_SCHEDULER": "Impayé",
    "CANCELED_BY_PERSON": "Annulé",
    "SOLVED": "Résolu",
    "PAID_NOT_DONE": "Payé",
    "DONE": "Fait"
  },
  "ReferralStatusEnum": {
    "NEW": "Nouveau",
    "CANCELLED": "Annulé",
    "DONE": "Fait"
  },
  "VisitCaseSolvedEnum": {
    "APPOINTMENT": "Rendez-vous",
    "RESIGNATION": "Démission",
    "OTHER": "Autre"
  },
  "MedicalServiceGroupConsultantEnum": {
    "NEUROSURGEON_CONSULTATION": "neurochirurgien",
    "NEUROPSYCHOLOGIST_CONSULTATION": "neuropsychologue",
    "NEUROLOGOPEDIST_CONSULTATION": "neurologue",
    "PSYCHIATRIST_CONSULTATION": "psychiatre",
    "EPILEPTOLOGIST_CONSULTATION": "épileptologue",
    "NEUROLOGIST_CONSULTATION": "neurologue",
    "DIETICIAN_CONSULTATION": "diététicien",
    "VNS_CONSULTATION": "Consultant VNS",
    "GENETICIST_CONSULTATION": "généticien",
    "METABOLIC_CONSULTATION": "consultant métabolique",
    "EEG_CONSULTATION": "Consultant EEG/VEEG",
    "SLEEP_DISTURBANCE_CONSULTATION": "consultante en troubles du sommeil"
  },
  "VisitPaymentMarkingEnum": {
    "WAITING_FOR_PAYMENT": "Marqué pour paiement",
    "PAYMENT_RECEIVED": "Marqué comme payé"
  },
  "PaymentProviderEnum": {
    "PAYU": "Payer",
    "PAYNOW": "Payez maintenant"
  },
  "VisitPackageTemplateType": {
    "EPILEPSY": "Épilepsie",
    "CONSULTATION": "Consultation"
  },
  "SexEnumAbbr": {
    "FEMALE": "F",
    "MALE": "M"
  },
  "TherapyTypeEnum": {
    "POLI": "Polithérapie",
    "MONO": "Monothérapie",
    "NO_ACTIVE": "Thérapie non active"
  },
  "ComorbidityTypeEnum": {
    "HEADACHES": "Maux de tête",
    "PSYCHIATRIC": "Maladies psychiatriques",
    "NEUROLOGICAL": "Maladies neurologiques",
    "INFECTIOUS": "Maladies inflammatoires et infectieuses",
    "CANCER": "Maladies cancéreuses",
    "IMMUNE": "Maladies du sang et du système immunitaire",
    "ENDOCRINE": "Maladies endocriniennes et métaboliques",
    "EYE": "Maladies oculaires",
    "LARYNGOLOGICAL": "Maladies laryngologiques",
    "HEART": "Maladies du cœur et du système circulatoire",
    "RESPIRATORY": "Maladies respiratoires",
    "DIGESTIVE": "Maladies du système digestif",
    "SKIN": "Maladies de la peau",
    "BONE": "Maladies osseuses",
    "UROGENITAL": "Maladies génito-urinaires",
    "DEVELOPMENTAL_DEFECTS": "Anomalies du développement, anomalies congénitales et aberrations chromosomiques",
    "INJURIES_POISONING": "Blessures et empoisonnements",
    "SOMATIC": "Maladies qui affectent le traitement de l'épilepsie"
  },
  "PatientAccountRemovalReason": {
    "NO_MEDICAL_SERVICE": "Je n'ai pas trouvé de service qui m'intéresse",
    "NO_DATES": "Je n'ai pas trouvé de date de visite appropriée",
    "PRICE": "Les prix des services ne me conviennent pas",
    "OTHER": "Autre"
  },
  "PrescriptionRejectedReasonEnum": {
    "VISIT_ONLY": "Une ordonnance pour un médicament sélectionné ne peut être délivrée que lors d'une visite",
    "INQUIRY_REQUIRED": "Une ordonnance pour un médicament sélectionné peut être délivrée après avoir pris connaissance de l'état de santé actuel du patient.",
    "ALREADY_PRESCRIBED": "La prescription précédemment délivrée doit répondre aux besoins du patient",
    "OTHER": "Autre"
  },
  "BirthTypeEnum": {
    "PHYSIOLOGICAL": "Physiologique (forces de la nature)",
    "PLANNED_CESAREAN": "Césarienne (planifiée)",
    "UNPLANNED_CESAREAN": "Césarienne (non planifiée)",
    "VACUUM": "Forces / vide"
  },
  "PaymentRefundRestrictionsEnum": {
    "REFUND_100": "plus de 7 jours avant la prestation, remboursement à 100%",
    "REFUND_75": "Remboursement de 75% 6 à 5 jours avant la prestation",
    "REFUND_50": "Remboursement de 50% 4 à 3 jours avant la prestation",
    "REFUND_25": "Remboursement de 25% 2 jours avant la prestation",
    "REFUND_0": "la veille de la prestation et le jour de la prestation sans remboursement"
  },
  "NeuroscreenDiagnosisEnum": {
    "HEADACHES": "Maux de tête",
    "MOVEMENT_ISSUES": "Troubles du mouvement",
    "MEMORY_ISSUES": "Troubles de la mémoire",
    "EATING_ISSUES": "Troubles de l'alimentation",
    "CONSCIOUSNESS_ISSUES": "Troubles de la conscience",
    "PSYCHOMOTOR_DEVELOPMENT_ISSUES": "Troubles du développement psychomoteur",
    "EPILEPSY": "Épilepsie et crises",
    "SPINE_ISSUES": "Problèmes de colonne vertébrale",
    "MUTIPLE_SCLEROSIS": "Sclérose en plaques",
    "DIZZINESS": "Vertige",
    "AUTISM": "Spectre autistique",
    "OTHER_SYMPTOMS": "Autres symptômes neurologiques"
  },
  "NeuroscreenFrequencyEnum": {
    "ALWAYS": "Oui",
    "SOMETIMES": "Parfois",
    "RARELY": "Rarement",
    "NEVER": "Jamais"
  }
}