/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DoctorVacationDto } from '../model';
// @ts-ignore
import { DoctorWorkPlaceDto } from '../model';
// @ts-ignore
import { DoctorWorkPlanChangeDto } from '../model';
// @ts-ignore
import { DoctorWorkPlanDto } from '../model';
// @ts-ignore
import { MedicalServiceBaseDto } from '../model';
// @ts-ignore
import { PageDutyDto } from '../model';
// @ts-ignore
import { Pageable } from '../model';
/**
 * AdminWorkPlanApiControllerApi - axios parameter creator
 * @export
 */
export const AdminWorkPlanApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} doctorId 
         * @param {DoctorVacationDto} doctorVacationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDoctorVacation: async (doctorId: number, doctorVacationDto: DoctorVacationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('addDoctorVacation', 'doctorId', doctorId)
            // verify required parameter 'doctorVacationDto' is not null or undefined
            assertParamExists('addDoctorVacation', 'doctorVacationDto', doctorVacationDto)
            const localVarPath = `/api/admin/workplan/vacation/doctor/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorVacationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorVacationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoctorVacation: async (doctorVacationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorVacationId' is not null or undefined
            assertParamExists('deleteDoctorVacation', 'doctorVacationId', doctorVacationId)
            const localVarPath = `/api/admin/workplan/vacation/{doctorVacationId}`
                .replace(`{${"doctorVacationId"}}`, encodeURIComponent(String(doctorVacationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} workPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoctorWorkPlan1: async (workPlanId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workPlanId' is not null or undefined
            assertParamExists('deleteDoctorWorkPlan1', 'workPlanId', workPlanId)
            const localVarPath = `/api/admin/workplan/{workPlanId}`
                .replace(`{${"workPlanId"}}`, encodeURIComponent(String(workPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoctorWorkPlanChanges: async (doctorId: number, requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('deleteDoctorWorkPlanChanges', 'doctorId', doctorId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteDoctorWorkPlanChanges', 'requestBody', requestBody)
            const localVarPath = `/api/admin/workplan/change/doctor/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDoctorWorkPlans: async (doctorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('getAllDoctorWorkPlans', 'doctorId', doctorId)
            const localVarPath = `/api/admin/workplan/{doctorId}/all`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectiveVacation: async (date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getCollectiveVacation', 'date', date)
            const localVarPath = `/api/admin/workplan/vacation/collective`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectiveWorkplan: async (date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getCollectiveWorkplan', 'date', date)
            const localVarPath = `/api/admin/workplan/collective`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectiveWorkplanChange: async (date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getCollectiveWorkplanChange', 'date', date)
            const localVarPath = `/api/admin/workplan/change/collective`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {number} doctorId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorDuties: async (dateFrom: string, dateTo: string, doctorId: number, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getDoctorDuties', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getDoctorDuties', 'dateTo', dateTo)
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('getDoctorDuties', 'doctorId', doctorId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getDoctorDuties', 'pageable', pageable)
            const localVarPath = `/api/admin/workplan/duty/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorVacations1: async (doctorId: number, dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('getDoctorVacations1', 'doctorId', doctorId)
            const localVarPath = `/api/admin/workplan/vacation/doctor/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkPlaces1: async (doctorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('getDoctorWorkPlaces1', 'doctorId', doctorId)
            const localVarPath = `/api/admin/workplan/workplace/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkPlanChanges1: async (doctorId: number, dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('getDoctorWorkPlanChanges1', 'doctorId', doctorId)
            const localVarPath = `/api/admin/workplan/change/doctor/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkPlans1: async (doctorId: number, dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('getDoctorWorkPlans1', 'doctorId', doctorId)
            const localVarPath = `/api/admin/workplan/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalServicesProvided: async (doctorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('getMedicalServicesProvided', 'doctorId', doctorId)
            const localVarPath = `/api/admin/workplan/medical-service/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {DoctorWorkPlanDto} doctorWorkPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDoctorWorkPlan: async (doctorId: number, doctorWorkPlanDto: DoctorWorkPlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('saveDoctorWorkPlan', 'doctorId', doctorId)
            // verify required parameter 'doctorWorkPlanDto' is not null or undefined
            assertParamExists('saveDoctorWorkPlan', 'doctorWorkPlanDto', doctorWorkPlanDto)
            const localVarPath = `/api/admin/workplan/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorWorkPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {Array<DoctorWorkPlanChangeDto>} doctorWorkPlanChangeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDoctorWorkPlanChanges: async (doctorId: number, doctorWorkPlanChangeDto: Array<DoctorWorkPlanChangeDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('saveDoctorWorkPlanChanges', 'doctorId', doctorId)
            // verify required parameter 'doctorWorkPlanChangeDto' is not null or undefined
            assertParamExists('saveDoctorWorkPlanChanges', 'doctorWorkPlanChangeDto', doctorWorkPlanChangeDto)
            const localVarPath = `/api/admin/workplan/change/doctor/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorWorkPlanChangeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} workPlanId 
         * @param {DoctorWorkPlanDto} doctorWorkPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoctorWorkPlan: async (workPlanId: number, doctorWorkPlanDto: DoctorWorkPlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workPlanId' is not null or undefined
            assertParamExists('updateDoctorWorkPlan', 'workPlanId', workPlanId)
            // verify required parameter 'doctorWorkPlanDto' is not null or undefined
            assertParamExists('updateDoctorWorkPlan', 'doctorWorkPlanDto', doctorWorkPlanDto)
            const localVarPath = `/api/admin/workplan/{workPlanId}`
                .replace(`{${"workPlanId"}}`, encodeURIComponent(String(workPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorWorkPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminWorkPlanApiControllerApi - functional programming interface
 * @export
 */
export const AdminWorkPlanApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminWorkPlanApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} doctorId 
         * @param {DoctorVacationDto} doctorVacationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDoctorVacation(doctorId: number, doctorVacationDto: DoctorVacationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorVacationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDoctorVacation(doctorId, doctorVacationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorVacationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDoctorVacation(doctorVacationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDoctorVacation(doctorVacationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} workPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDoctorWorkPlan1(workPlanId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDoctorWorkPlan1(workPlanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDoctorWorkPlanChanges(doctorId: number, requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDoctorWorkPlanChanges(doctorId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDoctorWorkPlans(doctorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorWorkPlanDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDoctorWorkPlans(doctorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectiveVacation(date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorVacationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectiveVacation(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectiveWorkplan(date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorWorkPlanDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectiveWorkplan(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectiveWorkplanChange(date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorWorkPlanChangeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectiveWorkplanChange(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {number} doctorId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorDuties(dateFrom: string, dateTo: string, doctorId: number, pageable: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PageDutyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorDuties(dateFrom, dateTo, doctorId, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorVacations1(doctorId: number, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorVacationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorVacations1(doctorId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorWorkPlaces1(doctorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorWorkPlaceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorWorkPlaces1(doctorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorWorkPlanChanges1(doctorId: number, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorWorkPlanChangeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorWorkPlanChanges1(doctorId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorWorkPlans1(doctorId: number, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorWorkPlanDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorWorkPlans1(doctorId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMedicalServicesProvided(doctorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<MedicalServiceBaseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMedicalServicesProvided(doctorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {DoctorWorkPlanDto} doctorWorkPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDoctorWorkPlan(doctorId: number, doctorWorkPlanDto: DoctorWorkPlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorWorkPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDoctorWorkPlan(doctorId, doctorWorkPlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {Array<DoctorWorkPlanChangeDto>} doctorWorkPlanChangeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDoctorWorkPlanChanges(doctorId: number, doctorWorkPlanChangeDto: Array<DoctorWorkPlanChangeDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorWorkPlanChangeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDoctorWorkPlanChanges(doctorId, doctorWorkPlanChangeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} workPlanId 
         * @param {DoctorWorkPlanDto} doctorWorkPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDoctorWorkPlan(workPlanId: number, doctorWorkPlanDto: DoctorWorkPlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorWorkPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDoctorWorkPlan(workPlanId, doctorWorkPlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminWorkPlanApiControllerApi - factory interface
 * @export
 */
export const AdminWorkPlanApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminWorkPlanApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} doctorId 
         * @param {DoctorVacationDto} doctorVacationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDoctorVacation(doctorId: number, doctorVacationDto: DoctorVacationDto, options?: any): Promise<DoctorVacationDto> {
            return localVarFp.addDoctorVacation(doctorId, doctorVacationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorVacationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoctorVacation(doctorVacationId: number, options?: any): Promise<void> {
            return localVarFp.deleteDoctorVacation(doctorVacationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} workPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoctorWorkPlan1(workPlanId: number, options?: any): Promise<void> {
            return localVarFp.deleteDoctorWorkPlan1(workPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoctorWorkPlanChanges(doctorId: number, requestBody: Array<number>, options?: any): Promise<void> {
            return localVarFp.deleteDoctorWorkPlanChanges(doctorId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDoctorWorkPlans(doctorId: number, options?: any): Promise<Array<DoctorWorkPlanDto>> {
            return localVarFp.getAllDoctorWorkPlans(doctorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectiveVacation(date: string, options?: any): Promise<Array<DoctorVacationDto>> {
            return localVarFp.getCollectiveVacation(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectiveWorkplan(date: string, options?: any): Promise<Array<DoctorWorkPlanDto>> {
            return localVarFp.getCollectiveWorkplan(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectiveWorkplanChange(date: string, options?: any): Promise<Array<DoctorWorkPlanChangeDto>> {
            return localVarFp.getCollectiveWorkplanChange(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {number} doctorId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorDuties(dateFrom: string, dateTo: string, doctorId: number, pageable: Pageable, options?: any): Promise<PageDutyDto> {
            return localVarFp.getDoctorDuties(dateFrom, dateTo, doctorId, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorVacations1(doctorId: number, dateFrom?: string, dateTo?: string, options?: any): Promise<Array<DoctorVacationDto>> {
            return localVarFp.getDoctorVacations1(doctorId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkPlaces1(doctorId: number, options?: any): Promise<DoctorWorkPlaceDto> {
            return localVarFp.getDoctorWorkPlaces1(doctorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkPlanChanges1(doctorId: number, dateFrom?: string, dateTo?: string, options?: any): Promise<Array<DoctorWorkPlanChangeDto>> {
            return localVarFp.getDoctorWorkPlanChanges1(doctorId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkPlans1(doctorId: number, dateFrom?: string, dateTo?: string, options?: any): Promise<Array<DoctorWorkPlanDto>> {
            return localVarFp.getDoctorWorkPlans1(doctorId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalServicesProvided(doctorId: number, options?: any): Promise<Array<MedicalServiceBaseDto>> {
            return localVarFp.getMedicalServicesProvided(doctorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {DoctorWorkPlanDto} doctorWorkPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDoctorWorkPlan(doctorId: number, doctorWorkPlanDto: DoctorWorkPlanDto, options?: any): Promise<DoctorWorkPlanDto> {
            return localVarFp.saveDoctorWorkPlan(doctorId, doctorWorkPlanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {Array<DoctorWorkPlanChangeDto>} doctorWorkPlanChangeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDoctorWorkPlanChanges(doctorId: number, doctorWorkPlanChangeDto: Array<DoctorWorkPlanChangeDto>, options?: any): Promise<Array<DoctorWorkPlanChangeDto>> {
            return localVarFp.saveDoctorWorkPlanChanges(doctorId, doctorWorkPlanChangeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} workPlanId 
         * @param {DoctorWorkPlanDto} doctorWorkPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoctorWorkPlan(workPlanId: number, doctorWorkPlanDto: DoctorWorkPlanDto, options?: any): Promise<DoctorWorkPlanDto> {
            return localVarFp.updateDoctorWorkPlan(workPlanId, doctorWorkPlanDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminWorkPlanApiControllerApi - object-oriented interface
 * @export
 * @class AdminWorkPlanApiControllerApi
 * @extends {BaseAPI}
 */
export class AdminWorkPlanApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} doctorId 
     * @param {DoctorVacationDto} doctorVacationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlanApiControllerApi
     */
    public addDoctorVacation(doctorId: number, doctorVacationDto: DoctorVacationDto, options?: AxiosRequestConfig) {
        return AdminWorkPlanApiControllerApiFp(this.configuration).addDoctorVacation(doctorId, doctorVacationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorVacationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlanApiControllerApi
     */
    public deleteDoctorVacation(doctorVacationId: number, options?: AxiosRequestConfig) {
        return AdminWorkPlanApiControllerApiFp(this.configuration).deleteDoctorVacation(doctorVacationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} workPlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlanApiControllerApi
     */
    public deleteDoctorWorkPlan1(workPlanId: number, options?: AxiosRequestConfig) {
        return AdminWorkPlanApiControllerApiFp(this.configuration).deleteDoctorWorkPlan1(workPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlanApiControllerApi
     */
    public deleteDoctorWorkPlanChanges(doctorId: number, requestBody: Array<number>, options?: AxiosRequestConfig) {
        return AdminWorkPlanApiControllerApiFp(this.configuration).deleteDoctorWorkPlanChanges(doctorId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlanApiControllerApi
     */
    public getAllDoctorWorkPlans(doctorId: number, options?: AxiosRequestConfig) {
        return AdminWorkPlanApiControllerApiFp(this.configuration).getAllDoctorWorkPlans(doctorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlanApiControllerApi
     */
    public getCollectiveVacation(date: string, options?: AxiosRequestConfig) {
        return AdminWorkPlanApiControllerApiFp(this.configuration).getCollectiveVacation(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlanApiControllerApi
     */
    public getCollectiveWorkplan(date: string, options?: AxiosRequestConfig) {
        return AdminWorkPlanApiControllerApiFp(this.configuration).getCollectiveWorkplan(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlanApiControllerApi
     */
    public getCollectiveWorkplanChange(date: string, options?: AxiosRequestConfig) {
        return AdminWorkPlanApiControllerApiFp(this.configuration).getCollectiveWorkplanChange(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dateFrom 
     * @param {string} dateTo 
     * @param {number} doctorId 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlanApiControllerApi
     */
    public getDoctorDuties(dateFrom: string, dateTo: string, doctorId: number, pageable: Pageable, options?: AxiosRequestConfig) {
        return AdminWorkPlanApiControllerApiFp(this.configuration).getDoctorDuties(dateFrom, dateTo, doctorId, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlanApiControllerApi
     */
    public getDoctorVacations1(doctorId: number, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return AdminWorkPlanApiControllerApiFp(this.configuration).getDoctorVacations1(doctorId, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlanApiControllerApi
     */
    public getDoctorWorkPlaces1(doctorId: number, options?: AxiosRequestConfig) {
        return AdminWorkPlanApiControllerApiFp(this.configuration).getDoctorWorkPlaces1(doctorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlanApiControllerApi
     */
    public getDoctorWorkPlanChanges1(doctorId: number, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return AdminWorkPlanApiControllerApiFp(this.configuration).getDoctorWorkPlanChanges1(doctorId, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlanApiControllerApi
     */
    public getDoctorWorkPlans1(doctorId: number, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return AdminWorkPlanApiControllerApiFp(this.configuration).getDoctorWorkPlans1(doctorId, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlanApiControllerApi
     */
    public getMedicalServicesProvided(doctorId: number, options?: AxiosRequestConfig) {
        return AdminWorkPlanApiControllerApiFp(this.configuration).getMedicalServicesProvided(doctorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {DoctorWorkPlanDto} doctorWorkPlanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlanApiControllerApi
     */
    public saveDoctorWorkPlan(doctorId: number, doctorWorkPlanDto: DoctorWorkPlanDto, options?: AxiosRequestConfig) {
        return AdminWorkPlanApiControllerApiFp(this.configuration).saveDoctorWorkPlan(doctorId, doctorWorkPlanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {Array<DoctorWorkPlanChangeDto>} doctorWorkPlanChangeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlanApiControllerApi
     */
    public saveDoctorWorkPlanChanges(doctorId: number, doctorWorkPlanChangeDto: Array<DoctorWorkPlanChangeDto>, options?: AxiosRequestConfig) {
        return AdminWorkPlanApiControllerApiFp(this.configuration).saveDoctorWorkPlanChanges(doctorId, doctorWorkPlanChangeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} workPlanId 
     * @param {DoctorWorkPlanDto} doctorWorkPlanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlanApiControllerApi
     */
    public updateDoctorWorkPlan(workPlanId: number, doctorWorkPlanDto: DoctorWorkPlanDto, options?: AxiosRequestConfig) {
        return AdminWorkPlanApiControllerApiFp(this.configuration).updateDoctorWorkPlan(workPlanId, doctorWorkPlanDto, options).then((request) => request(this.axios, this.basePath));
    }
}
