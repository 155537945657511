/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DoctorWorkPlaceDto } from '../model';
/**
 * AdminWorkPlaceControllerApi - axios parameter creator
 * @export
 */
export const AdminWorkPlaceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DoctorWorkPlaceDto} doctorWorkPlaceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWorkPlace: async (doctorWorkPlaceDto: DoctorWorkPlaceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorWorkPlaceDto' is not null or undefined
            assertParamExists('addWorkPlace', 'doctorWorkPlaceDto', doctorWorkPlaceDto)
            const localVarPath = `/api/admin/work-place`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorWorkPlaceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} workPlaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkPlace: async (workPlaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workPlaceId' is not null or undefined
            assertParamExists('deleteWorkPlace', 'workPlaceId', workPlaceId)
            const localVarPath = `/api/admin/work-place/{workPlaceId}`
                .replace(`{${"workPlaceId"}}`, encodeURIComponent(String(workPlaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DoctorWorkPlaceDto} doctorWorkPlaceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editWorkPlace: async (doctorWorkPlaceDto: DoctorWorkPlaceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorWorkPlaceDto' is not null or undefined
            assertParamExists('editWorkPlace', 'doctorWorkPlaceDto', doctorWorkPlaceDto)
            const localVarPath = `/api/admin/work-place`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorWorkPlaceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkPlaces: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/work-place`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkPlaces2: async (doctorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('getDoctorWorkPlaces2', 'doctorId', doctorId)
            const localVarPath = `/api/admin/work-place/doctor/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} workPlaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkPlace: async (workPlaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workPlaceId' is not null or undefined
            assertParamExists('getWorkPlace', 'workPlaceId', workPlaceId)
            const localVarPath = `/api/admin/work-place/{workPlaceId}`
                .replace(`{${"workPlaceId"}}`, encodeURIComponent(String(workPlaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminWorkPlaceControllerApi - functional programming interface
 * @export
 */
export const AdminWorkPlaceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminWorkPlaceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DoctorWorkPlaceDto} doctorWorkPlaceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addWorkPlace(doctorWorkPlaceDto: DoctorWorkPlaceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorWorkPlaceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addWorkPlace(doctorWorkPlaceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} workPlaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkPlace(workPlaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkPlace(workPlaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DoctorWorkPlaceDto} doctorWorkPlaceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editWorkPlace(doctorWorkPlaceDto: DoctorWorkPlaceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorWorkPlaceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editWorkPlace(doctorWorkPlaceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWorkPlaces(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorWorkPlaceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWorkPlaces(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorWorkPlaces2(doctorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorWorkPlaceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorWorkPlaces2(doctorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} workPlaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkPlace(workPlaceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorWorkPlaceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkPlace(workPlaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminWorkPlaceControllerApi - factory interface
 * @export
 */
export const AdminWorkPlaceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminWorkPlaceControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {DoctorWorkPlaceDto} doctorWorkPlaceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWorkPlace(doctorWorkPlaceDto: DoctorWorkPlaceDto, options?: any): Promise<DoctorWorkPlaceDto> {
            return localVarFp.addWorkPlace(doctorWorkPlaceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} workPlaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkPlace(workPlaceId: number, options?: any): Promise<void> {
            return localVarFp.deleteWorkPlace(workPlaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DoctorWorkPlaceDto} doctorWorkPlaceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editWorkPlace(doctorWorkPlaceDto: DoctorWorkPlaceDto, options?: any): Promise<DoctorWorkPlaceDto> {
            return localVarFp.editWorkPlace(doctorWorkPlaceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWorkPlaces(options?: any): Promise<Array<DoctorWorkPlaceDto>> {
            return localVarFp.getAllWorkPlaces(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkPlaces2(doctorId: number, options?: any): Promise<Array<DoctorWorkPlaceDto>> {
            return localVarFp.getDoctorWorkPlaces2(doctorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} workPlaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkPlace(workPlaceId: number, options?: any): Promise<DoctorWorkPlaceDto> {
            return localVarFp.getWorkPlace(workPlaceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminWorkPlaceControllerApi - object-oriented interface
 * @export
 * @class AdminWorkPlaceControllerApi
 * @extends {BaseAPI}
 */
export class AdminWorkPlaceControllerApi extends BaseAPI {
    /**
     * 
     * @param {DoctorWorkPlaceDto} doctorWorkPlaceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlaceControllerApi
     */
    public addWorkPlace(doctorWorkPlaceDto: DoctorWorkPlaceDto, options?: AxiosRequestConfig) {
        return AdminWorkPlaceControllerApiFp(this.configuration).addWorkPlace(doctorWorkPlaceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} workPlaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlaceControllerApi
     */
    public deleteWorkPlace(workPlaceId: number, options?: AxiosRequestConfig) {
        return AdminWorkPlaceControllerApiFp(this.configuration).deleteWorkPlace(workPlaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DoctorWorkPlaceDto} doctorWorkPlaceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlaceControllerApi
     */
    public editWorkPlace(doctorWorkPlaceDto: DoctorWorkPlaceDto, options?: AxiosRequestConfig) {
        return AdminWorkPlaceControllerApiFp(this.configuration).editWorkPlace(doctorWorkPlaceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlaceControllerApi
     */
    public getAllWorkPlaces(options?: AxiosRequestConfig) {
        return AdminWorkPlaceControllerApiFp(this.configuration).getAllWorkPlaces(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlaceControllerApi
     */
    public getDoctorWorkPlaces2(doctorId: number, options?: AxiosRequestConfig) {
        return AdminWorkPlaceControllerApiFp(this.configuration).getDoctorWorkPlaces2(doctorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} workPlaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWorkPlaceControllerApi
     */
    public getWorkPlace(workPlaceId: number, options?: AxiosRequestConfig) {
        return AdminWorkPlaceControllerApiFp(this.configuration).getWorkPlace(workPlaceId, options).then((request) => request(this.axios, this.basePath));
    }
}
