/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DoctorVisitSlotDto
 */
export interface DoctorVisitSlotDto {
    /**
     * 
     * @type {number}
     * @memberof DoctorVisitSlotDto
     */
    'doctorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DoctorVisitSlotDto
     */
    'doctorPackageId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DoctorVisitSlotDto
     */
    'doctorPriceListId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DoctorVisitSlotDto
     */
    'doctorType'?: DoctorVisitSlotDtoDoctorTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DoctorVisitSlotDto
     */
    'grossPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof DoctorVisitSlotDto
     */
    'key'?: string;
    /**
     * 
     * @type {number}
     * @memberof DoctorVisitSlotDto
     */
    'slotId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DoctorVisitSlotDto
     */
    'timeFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorVisitSlotDto
     */
    'timeTo'?: string;
    /**
     * 
     * @type {number}
     * @memberof DoctorVisitSlotDto
     */
    'visitId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DoctorVisitSlotDto
     */
    'visitType'?: DoctorVisitSlotDtoVisitTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DoctorVisitSlotDto
     */
    'workPlaceId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum DoctorVisitSlotDtoDoctorTypeEnum {
    Resident = 'RESIDENT',
    Specialist = 'SPECIALIST',
    Expert = 'EXPERT',
    Consultant = 'CONSULTANT',
    Assistant = 'ASSISTANT',
    InternationalConsultant = 'INTERNATIONAL_CONSULTANT',
    PartnerConsultant = 'PARTNER_CONSULTANT'
}
/**
    * @export
    * @enum {string}
    */
export enum DoctorVisitSlotDtoVisitTypeEnum {
    Remote = 'REMOTE',
    Stationary = 'STATIONARY',
    Absent = 'ABSENT'
}


