/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface EegSleep
 */
export interface EegSleep {
    /**
     * 
     * @type {Array<string>}
     * @memberof EegSleep
     */
    'main'?: Array<EegSleepMainEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EegSleep
     */
    'sub'?: Array<EegSleepSubEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum EegSleepMainEnum {
    None = 'NONE',
    RegisteredPhases = 'REGISTERED_PHASES',
    SleepSpindles = 'SLEEP_SPINDLES',
    SharpWaves = 'SHARP_WAVES',
    KComplex = 'K_COMPLEX',
    SawWaves = 'SAW_WAVES',
    Post = 'POST',
    Soremp = 'SOREMP'
}
/**
    * @export
    * @enum {string}
    */
export enum EegSleepSubEnum {
    N1 = 'N1',
    N2 = 'N2',
    N3 = 'N3',
    Rem = 'REM'
}


