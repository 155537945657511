/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PackageDto
 */
export interface PackageDto {
    /**
     * 
     * @type {string}
     * @memberof PackageDto
     */
    'amount'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageDto
     */
    'dose'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageDto
     */
    'ean'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageDto
     */
    'form'?: string;
    /**
     * 
     * @type {number}
     * @memberof PackageDto
     */
    'id'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PackageDto
     */
    'payments': Array<PackageDtoPaymentsEnum>;
    /**
     * 
     * @type {string}
     * @memberof PackageDto
     */
    'prescriptionType'?: PackageDtoPrescriptionTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PackageDto
     */
    'productId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PackageDtoPaymentsEnum {
    X = 'X',
    B = 'B',
    R = 'R',
    P30 = 'P30',
    P50 = 'P50',
    P100 = 'P100'
}
/**
    * @export
    * @enum {string}
    */
export enum PackageDtoPrescriptionTypeEnum {
    Rp = 'RP',
    Rpw = 'RPW',
    Rps = 'RPS',
    Lz = 'LZ',
    Rplz = 'RPLZ',
    Lzrp = 'LZRP',
    Xlz = 'XLZ',
    Xrplz = 'XRPLZ',
    Rpwlz = 'RPWLZ',
    Rpz = 'RPZ'
}


