/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface MedicalDocumentDto
 */
export interface MedicalDocumentDto {
    /**
     * 
     * @type {string}
     * @memberof MedicalDocumentDto
     */
    'additionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalDocumentDto
     */
    'documentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalDocumentDto
     */
    'documentType'?: MedicalDocumentDtoDocumentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MedicalDocumentDto
     */
    'fileContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalDocumentDto
     */
    'fileMimeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalDocumentDto
     */
    'fileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedicalDocumentDto
     */
    'fileSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicalDocumentDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicalDocumentDto
     */
    'patientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalDocumentDto
     */
    'resultDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedicalDocumentDto
     */
    'uploadedById'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum MedicalDocumentDtoDocumentTypeEnum {
    HospitalInformationCard = 'HOSPITAL_INFORMATION_CARD',
    DiseaseHistory = 'DISEASE_HISTORY',
    VideoEvent = 'VIDEO_EVENT',
    LaboratoryTestResult = 'LABORATORY_TEST_RESULT',
    VisualResultMriFile = 'VISUAL_RESULT_MRI_FILE',
    VisualResultMriDesc = 'VISUAL_RESULT_MRI_DESC',
    VisualResultCtFile = 'VISUAL_RESULT_CT_FILE',
    VisualResultCtDesc = 'VISUAL_RESULT_CT_DESC',
    VisualResultEegFile = 'VISUAL_RESULT_EEG_FILE',
    VisualResultEegDesc = 'VISUAL_RESULT_EEG_DESC',
    VisualResultAdditionalFile = 'VISUAL_RESULT_ADDITIONAL_FILE',
    VisualResultAdditionalDesc = 'VISUAL_RESULT_ADDITIONAL_DESC',
    Other = 'OTHER',
    PatientCertificate = 'PATIENT_CERTIFICATE',
    StateCertificate = 'STATE_CERTIFICATE',
    EegDescription = 'EEG_DESCRIPTION'
}


