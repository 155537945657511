/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PerinatalInterviewDto
 */
export interface PerinatalInterviewDto {
    /**
     * 
     * @type {number}
     * @memberof PerinatalInterviewDto
     */
    'apgarFirst'?: number;
    /**
     * 
     * @type {number}
     * @memberof PerinatalInterviewDto
     */
    'apgarSecond'?: number;
    /**
     * 
     * @type {number}
     * @memberof PerinatalInterviewDto
     */
    'apgarThird'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PerinatalInterviewDto
     */
    'birthAdditionalProcedures'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PerinatalInterviewDto
     */
    'birthAdditionalProceduresDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof PerinatalInterviewDto
     */
    'birthLength'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PerinatalInterviewDto
     */
    'birthPrematureAgeCorrected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PerinatalInterviewDto
     */
    'birthPrematureAgeCorrectedDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof PerinatalInterviewDto
     */
    'birthType'?: PerinatalInterviewDtoBirthTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PerinatalInterviewDto
     */
    'birthWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof PerinatalInterviewDto
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PerinatalInterviewDto
     */
    'pregnancyComplications'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PerinatalInterviewDto
     */
    'pregnancyComplicationsDescription'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PerinatalInterviewDtoBirthTypeEnum {
    Physiological = 'PHYSIOLOGICAL',
    PlannedCesarean = 'PLANNED_CESAREAN',
    UnplannedCesarean = 'UNPLANNED_CESAREAN',
    Vacuum = 'VACUUM'
}


