import Countries from "../countries/countries";

export default {
  "SeizureTypeEnum": {
    "IA": "Fokális egyszerű roham",
    "IB": "Fokális komplex roham",
    "IC": "A fokális roham generalizálttá bontakozik ki",
    "IIA": "Távolléti roham",
    "IIB": "Mioklónusos roham",
    "IIC": "Tonikus roham",
    "IID": "Klónusos roham",
    "IIE": "Tonikus-klónusos roham",
    "IIF": "Atonikus roham",
    "GEN": "Generált roham",
    "UNK": "Ismeretlen roham"
  },
  "SeizureTypeBriefEnum": {
    "IA": "Fokális egyszerű roham",
    "IB": "Fokális komplex roham",
    "IC": "A fokális roham generalizálttá bontakozik ki",
    "IIA": "Hiány",
    "IIB": "Mioklonikus",
    "IIC": "Tonik",
    "IID": "Klónikus",
    "IIE": "Tonikus-klónusos",
    "IIF": "Ellankadt",
    "GEN": "Generált",
    "UNK": "Ismeretlen"
  },
  "MedicalEventTypeEnum": {
    "SEIZURE": "Roham",
    "MENSTRUATION": "Menstruáció",
    "INFECTION": "Fertőzés",
    "SLEEP_DEPRIVATION": "Alvásmegvonás",
    "FEVER": "Láz",
    "OTHER": "Egyéb",
    "PREGNANCY_OR_PRE_PREGNANCY_PERIOD_START": "A terhesség kezdete vagy a terhesség előtti időszak",
    "PREGNANCY_OR_PRE_PREGNANCY_PERIOD_END": "Terhesség vége vagy terhesség előtti időszak"
  },
  "MessageTypeEnum": {
    "EMAIL": "email",
    "SMS": "sms",
    "SYS": "rendszer",
    "CHAT": "csevegés"
  },
  "MessageStatusEnum": {
    "NEW": "új",
    "SENT": "küldött",
    "READ": "olvas"
  },
  "MessageMarkerEnum": {
    "MARKED": "megjelölt",
    "NOT_MARKED": "nincs megjelölve"
  },
  "MedicalServiceSystemItemEnum": {
    "SOS_TELECONFERENCE": "SOS telekonferencia",
    "SOS_CHAT": "SOS Chat"
  },
  "MedicalServiceGroupEnum": {
    "VISIT_0": "0. felmérés",
    "MIGRATION_VISIT": "Migrációs látogatás",
    "VISIT_1": "Látogassa meg az 1",
    "STANDARD_VISIT": "Normál látogatás",
    "MESSAGE": "Üzenet",
    "PRESCRIPTION": "Recept",
    "NEUROSURGEON_CONSULTATION": "Konzultáció - idegsebész",
    "NEUROPSYCHOLOGIST_CONSULTATION": "Konzultáció - neuropszichológus",
    "NEUROLOGOPEDIST_CONSULTATION": "Konzultáció - neurológus szakorvos",
    "PSYCHIATRIST_CONSULTATION": "Konzultáció - pszichiáter",
    "EPILEPTOLOGIST_CONSULTATION": "Konzultáció - epileptológus",
    "NEUROLOGIST_CONSULTATION": "Konzultáció - neurológus",
    "DIETICIAN_CONSULTATION": "Konzultáció - dietetikus",
    "VNS_CONSULTATION": "VNS látogatás",
    "GENETICIST_CONSULTATION": "Konzultáció - genetikus",
    "ABSENT_CONSULTATION": "Hiányzó konzultáció",
    "METABOLIC_CONSULTATION": "Anyagcsere konzultáció",
    "EEG_CONSULTATION": "EEG vizsgálat",
    "SLEEP_DISTURBANCE_CONSULTATION": "Alvászavarral kapcsolatos konzultáció",
    "PARTNER_CONSULTATION": "Partner konzultáció"
  },
  "MedicalServiceGroupConsultantEnum": {
    "NEUROSURGEON_CONSULTATION": "idegsebész",
    "NEUROPSYCHOLOGIST_CONSULTATION": "neuropszichológus",
    "NEUROLOGOPEDIST_CONSULTATION": "neurológus szakorvos",
    "PSYCHIATRIST_CONSULTATION": "pszichiáter",
    "EPILEPTOLOGIST_CONSULTATION": "epileptológus",
    "NEUROLOGIST_CONSULTATION": "neurológus",
    "DIETICIAN_CONSULTATION": "dietetikus",
    "VNS_CONSULTATION": "VNS tanácsadó",
    "GENETICIST_CONSULTATION": "genetikus",
    "METABOLIC_CONSULTATION": "anyagcsere tanácsadó",
    "EEG_CONSULTATION": "EEG/VEEG tanácsadó",
    "SLEEP_DISTURBANCE_CONSULTATION": "alvászavar tanácsadó"
  },
  "MedicalServiceEnum": {
    "VISIT_0": "0. felmérés",
    "MIGRATION_VISIT": "Migrációs látogatás",
    "VISIT_1": "Látogassa meg az 1",
    "STANDARD_VISIT": "Normál látogatás",
    "MESSAGE": "Üzenet",
    "PRESCRIPTION": "Recept",
    "NEUROSURGEON_CONSULTATION": "Konzultáció - idegsebész",
    "NEUROPSYCHOLOGIST_CONSULTATION": "Konzultáció - neuropszichológus",
    "NEUROLOGOPEDIST_CONSULTATION": "Konzultáció - neurológus szakorvos",
    "PSYCHIATRIST_CONSULTATION": "Konzultáció - pszichiáter",
    "EPILEPTOLOGIST_CONSULTATION": "Konzultáció - epileptológus",
    "NEUROLOGIST_CONSULTATION": "Konzultáció - neurológus",
    "DIETICIAN_CONSULTATION": "Konzultáció - dietetikus",
    "VNS_CONSULTATION": "VNS látogatás",
    "GENETICIST_CONSULTATION": "Konzultáció - genetikus",
    "ABSENT_CONSULTATION": "Hiányzó konzultáció",
    "METABOLIC_CONSULTATION": "Anyagcsere konzultáció",
    "EEG_CONSULTATION": "Standard EEG vizsgálat 30 perc",
    "VEEG_ON_STANDBY_CONSULTATION": "VEEG vizsgálat ébrenlétben 1-6 óra",
    "VEEG_DURING_SLEEP_CONSULTATION": "VEEG vizsgálat alvás közben 1-6 óra",
    "VEEG_NIGHT_CONSULTATION": "VEEG vizsgálat 12 óra",
    "VEEG_DAY_CONSULTATION": "VEEG vizsgálat 24 óra",
    "SLEEP_DISTURBANCE_CONSULTATION": "Alvászavarral kapcsolatos konzultáció",
    "CASE_CONFERENCE": "Orvosi eset konferencia",
    "VEEG_ON_STANDBY_CONSULTATION_1H": "VEEG vizsgálat ébrenlétben 1 óra",
    "VEEG_ON_STANDBY_CONSULTATION_2H": "VEEG vizsgálat ébrenlétben 2 óra",
    "VEEG_ON_STANDBY_CONSULTATION_6H": "VEEG vizsgálat ébrenlétben 6 óra",
    "VEEG_DURING_SLEEP_CONSULTATION_1H": "VEEG vizsgálat alvás közben 1 óra",
    "VEEG_DURING_SLEEP_CONSULTATION_2H": "VEEG vizsgálat alvás közben 2 óra",
    "VEEG_DURING_SLEEP_CONSULTATION_6H": "VEEG vizsgálat alvás közben 6 óra"
  },
  "VisitTypeEnum": {
    "STATIONARY": "Helyhez kötött",
    "REMOTE": "Távoli",
    "ABSENT": "Hiányzó"
  },
  "VisitTypeDescriptionEnum": {
    "STATIONARY": "A látogatásra egy járóbeteg-klinikán kerül sor, itt: {place}. A látogatás során az orvos közvetlenül találkozik a pácienssel. A helyszínen áttekinti a páciens aktuális dokumentációját. Ha receptet adnak ki - az orvos a helyszínen kiállítja a receptet, és SMS-ben elküldi vagy kinyomtatja.",
    "REMOTE": "Online látogatás, ahol az orvos és a páciens látja egymást a képernyőn. A kapcsolat neuroterminálon keresztül történik, és nem igényel további szoftver telepítését. A dokumentációt a páciens elektronikus úton küldi meg a Neuroterminálon keresztül. Ha recept szükséges, az orvos e-receptet állít ki.",
    "ABSENT": "A hiányzó konzultáció egy olyan orvosi vizit, amelyet egy kiválasztott szakember végez a beteg jelenléte nélkül. A hiányzó konzultáció célja az orvosi feljegyzések elemzése vagy kiegészítő vizsgálat. A neuroszférikus szakember elemzi a dokumentációt, megfelelő feljegyzést és ajánlásokat készít a páciens számára."
  },
  "AffinityTypeEnumAdults": {
    "LEGAL_GUARDIAN": "gyámja",
    "CURATOR": "kurátor",
    "SPOUSE": "házastárs"
  },
  "AffinityTypeEnumChildren": {
    "PARENT": "szülő",
    "ADOPTER": "hódoló",
    "GUARDIAN": "gyámnak nevezték ki"
  },
  "VisitTypeEnumUndefined": "Egyéb",
  "DocumentTypeEnum": {
    "REFERRAL_GENERAL_PURPOSE": "Általános beutaló",
    "REFERRAL_CLINIC": "Beutaló szakrendelőbe",
    "REFERRAL_TO_HOSPITAL": "Beutaló a kórházba",
    "REFERRAL_TO_PSYCHIATRIC_HOSPITAL": "Beutaló pszichiátriai kórházba",
    "REFERRAL_TO_SPECIALIST": "Beutaló szakemberhez",
    "REFERRAL_TO_LAB": "Beutaló a laborba",
    "REFERRAL_TO_DIAGNOSTIC_LABORATORY": "Beutaló diagnosztikai laboratóriumba",
    "CERTIFICATE": "Bizonyítvány",
    "CERTIFICATE_FOR_ZUS": "ZUS tanúsítvány",
    "CERTIFICATE_OF_DISABILITY": "Fogyatékossági bizonyítvány",
    "HEALTH_CERTIFICATE": "Egészségügyi bizonyítvány",
    "INFORMATION_FOR_POZ_DOCTOR": "Információ a POZ orvos számára",
    "APPLICATION_FOR_REFUND": "Visszatérítési kérelem",
    "APPLICATION_FOR_DRUG_REFUND": "Gyógyszer-visszatérítési kérelem",
    "DEMAND_FOR_DRUG_FROM_ABROAD": "Külföldi kereslet a gyógyszer iránt",
    "DEMAND_FOR_FOODSTUFF_FROM_ABROAD": "Élelmiszer iránti kereslet külföldről",
    "OTHER": "Egyéb dokumentumok"
  },
  "MedicalResultDocumentTypeEnum": {
    "HOSPITAL_INFORMATION_CARD": "Kórházi kezelés információs kártya",
    "DISEASE_HISTORY": "Betegségtörténet",
    "VIDEO_EVENT": "Videó - esemény",
    "LABORATORY_TEST_RESULT": "Laboratóriumi vizsgálat eredménye",
    "VISUAL_RESULT_MRI_FILE": "Vizsgálati eredmény fájl - mágneses rezonancia",
    "VISUAL_RESULT_MRI_DESC": "Vizsgálati eredmény leírása - mágneses rezonancia",
    "VISUAL_RESULT_CT_FILE": "Vizsgálati eredmény fájl - számítógépes tomográfia",
    "VISUAL_RESULT_CT_DESC": "Vizsgálati eredmény leírása - számítógépes tomográfia",
    "VISUAL_RESULT_EEG_FILE": "Vizsgálati eredmény fájl - EEG",
    "VISUAL_RESULT_EEG_DESC": "Vizsgálati eredmény leírása - EEG",
    "VISUAL_RESULT_ADDITIONAL_FILE": "További vizsgálati eredmény fájl",
    "VISUAL_RESULT_ADDITIONAL_DESC": "További vizsgálati eredmények leírása",
    "PATIENT_CERTIFICATE": "Neurosphera tanúsítvány",
    "STATE_CERTIFICATE": "Állami bizonyítvány",
    "EEG_DESCRIPTION": "EEG leírás",
    "OTHER": "Egyéb"
  },
  "MedicalResultTypeEnum": {
    "VIDEO_EVENT": "Videó",
    "LABORATORY_TEST_RESULT": "Laboratóriumi teszt",
    "VISUAL_RESULT_MRI_FILE": "Mágneses rezonancia",
    "VISUAL_RESULT_MRI_DESC": "Mágneses rezonancia",
    "VISUAL_RESULT_CT_FILE": "Számítógépes tomográfia",
    "VISUAL_RESULT_CT_DESC": "Számítógépes tomográfia",
    "VISUAL_RESULT_EEG_FILE": "EEG",
    "VISUAL_RESULT_EEG_DESC": "EEG",
    "VISUAL_RESULT_ADDITIONAL_FILE": "További",
    "VISUAL_RESULT_ADDITIONAL_DESC": "További"
  },
  "DoctorTitle": {
    "LEK_MED": "lek. med.",
    "DR": "dr",
    "DR_MED": "dr n. med.",
    "DR_HAB": "dr hab.",
    "DR_PROF": "prof dr hab.",
    "TECHN": "techn.",
    "STUDENT": "hallgató UM",
    "MGR": "mgr"
  },
  "PricelistItemGroupEnum": {
    "NORMAL_VISIT": "normál látogatás",
    "SOS_VISIT": "sos látogatás",
    "DIAGNOSTIC": "teszt leírása",
    "OTHER": "Egyéb"
  },
  "PricelistItemTypeEnum": {
    "FIRST_TIME": "pierwszorazowa",
    "SUBSEQUENT": "kolejna",
    "OTHER": "inne"
  },
  "SexEnum": {
    "FEMALE": "Női",
    "MALE": "Férfi"
  },
  "SeizureFrequencyPeriodEnum": {
    "YEAR": "év",
    "MONTH": "hónap",
    "DAY": "nap"
  },
  "BloodType": {
    "TYPE_A": "A",
    "TYPE_B": "B",
    "TYPE_AB": "AB",
    "TYPE_0": "0"
  },
  "RhType": {
    "RH_PLUS": "RH+",
    "RH_MINUS": "RH-"
  },
  "PsychomotorDevelopmentOverallEnum": {
    "NORMAL": "Normál",
    "RETARDED": "Retardált",
    "VERY_RETARDED": "Nagyon retardált",
    "NOT_APPLICABLE": "Nem alkalmazható"
  },
  "PsychomotorRetardationEnum": {
    "NO": "Nem",
    "LIGHT": "Fény",
    "MODERATE": "Mérsékelt",
    "SIGNIFICANT": "Jelentős"
  },
  "PsychomotorSpeechEnum": {
    "NORMAL": "Normál",
    "DISTURBED": "Zavart",
    "NOT_APPLICABLE": "Nem alkalmazható"
  },
  "PsychomotorWalkEnum": {
    "NORMAL": "Normál",
    "INCORRECT": "Helytelen",
    "NOT_APPLICABLE": "Nem alkalmazható"
  },
  "PsychomotorSexEnum": {
    "NORMAL": "Normál",
    "LIBIDO_DISORDERS": "Libidó zavarok",
    "NOT_APPLICABLE": "Nem alkalmazható"
  },
  "NystagmusEnum": {
    "NO": "Nem",
    "VESTIBULAR": "Vestibuláris",
    "CNS": "CNS"
  },
  "VisionEnum": {
    "NORMAL": "Normál",
    "DEFECT_PLUS": "Látáshiba plusz (+)",
    "DEFECT_MINUS": "Látáshiba mínusz (-)",
    "DEFECT_OTHER": "Egyéb látási hiba",
    "BLIND": "Vak",
    "NOT_APPLICABLE": "Nem alkalmazható"
  },
  "HearingEnum": {
    "NORMAL": "Normál",
    "DISTURBED": "Zavart",
    "NOT_APPLICABLE": "Nem alkalmazható"
  },
  "SmokingEnum": {
    "NO": "Nem",
    "YES_IN_PAST": "Igen, a múltban",
    "YES_NOW": "Igen, jelenleg",
    "NOT_APPLICABLE": "Nem alkalmazható"
  },
  "AlcoholEnum": {
    "NO": "Nem",
    "SOMETIMES_LITTLE": "Néha kicsit",
    "SOMETIMES_A_LOT": "Néha sokat",
    "OFTEN_LITTLE": "Gyakran kevés",
    "OFTEN_A_LOT": "Gyakran sokat",
    "NOT_APPLICABLE": "Nem alkalmazható"
  },
  "NarcoticsEnum": {
    "NO": "Nem",
    "YES_IN_PAST": "Igen, a múltban",
    "YES_NOW_RARELY": "Igen, jelenleg – alkalmanként",
    "YES_NOW_OFTEN": "Igen, obecnie - gyakran",
    "NOT_APPLICABLE": "Nem alkalmazható"
  },
  "SeizuresCharacteristicsFrequencyEnum": {
    "ALWAYS": "Igen (1 roham) / Igen, mindig (100%-os roham)",
    "VERY_OFTEN": "Igen, nagyon gyakran (a rohamok 75-99%-ában)",
    "OFTEN": "Igen, gyakran (a rohamok 51-74%-a)",
    "SOMETIMES": "Igen, néha (a rohamok kb. 50%-a)",
    "RARELY": "Igen, de ritkán (a rohamok 25-49%-a)",
    "VERY_RARELY": "Igen, de rendkívül ritka (a rohamok 1-24%-a)",
    "NEVER": "Nem (1 roham) / Nem, soha (0% roham)",
    "NOT_KNOWN": "Nem tudom"
  },
  "DrugHistoryTimeEnum": {
    "PAST": "Igen, a múltban",
    "CURRENTLY": "Igen, jelenleg"
  },
  "DrugHistoryEffectEnum": {
    "REMISSION": "Remisszió",
    "BIG_IMPROVEMENT": "Javulás > 50%",
    "LITTLE_IMPROVEMENT": "Akár 50%-os javulás",
    "NO_CHANGE": "Nincs változás",
    "LITTLE_DETERIORATION": "Enyhe állapotromlás",
    "BIG_DETERIORATION": "Jelentős romlás",
    "EXTREME_DETERIORATION": "Rendkívüli állapotromlás/megszüntetési mellékhatások (pl. bőrkiütés)"
  },
  "SeizuresCharacteristicsDurationEnum": {
    "VERY_LONG": "Nagyon hosszú, több mint 5 perc",
    "LONG": "Hosszú, 1-5 perc",
    "BIT_LONG": "30-60 másodperc",
    "VARIABLE": "Teljesen más, változtatható",
    "SHORT": "Röviden, néhány másodperctől 30 másodpercig",
    "VERY_SHORT": "Nagyon rövid, kevesebb mint 5 másodperc",
    "ULTRA_SHORT": "Ultra-rövid, a másodperc töredékétől a másodpercig",
    "NOT_KNOWN": "Nem tudom"
  },
  "UserAccountSettingCodeEnum": {
    "VISIT_CANCELED_BY_DOCTOR": "Figyelmeztetés, ha az orvos lemondja a látogatást",
    "VISIT_CANCELLED_BY_PATIENT": "Figyelmeztetés, ha a beteg lemondja a látogatást",
    "VISIT_CHANGED_BY_PATIENT": "Figyelmeztetés, ha a páciens megváltoztatja a látogatás időpontját",
    "VISIT_APPOINTED_REMINDER": "Emlékeztetők a következő napra tervezett látogatásokról",
    "VISIT_PLANNED_REMINDER": "Emlékeztetők a következő napra tervezett látogatásokról",
    "DUTY_PLANNED_REMINDER": "Emlékeztetők a következő napra tervezett feladatokról",
    "ADMIT_PATIENTS": "Látható a betegek számára az orvosi adatbázisban",
    "MESSAGE_RECEIVED_BY_DOCTOR": "Figyelmeztetés, ha a páciens üzenetet küld",
    "PRESCRIPTION_REQUEST_RECEIVED_BY_DOCTOR": "Figyelmeztet, ha a páciens felírási kérelmet küld"
  },
  "VisitCancellationReason": {
    "INCONVENIENT_DATE": "Kellemetlen dátum",
    "DOCTOR_CHANGE": "Orvosváltás"
  },
  "DoctorTypeEnum": {
    "RESIDENT": "Klinikai rezidens",
    "ASSISTANT": "Helyettes",
    "SPECIALIST": "Klinikai Szakorvos",
    "EXPERT": "Orvosi partner",
    "CONSULTANT": "Klinikai Tanácsadó",
    "INTERNATIONAL_CONSULTANT": "Nemzetközi Klinikai Tanácsadó",
    "PARTNER_CONSULTANT": "Partner tanácsadó"
  },
  "PatientTypeEnum": {
    "INFANT": "gyermekek 2 éves korig",
    "CHILD": "2-12 éves gyermekek",
    "YOUTH": "12-18 éves fiatalok",
    "ADULT": "felnőttek 18 és 65 év között",
    "SENIOR": "65 év feletti idősek"
  },
  "HasEpilepsyEnum": {
    "NO": "Nem",
    "YES": "Igen",
    "NOT_KNOWN": "Nem ismert"
  },
  "HealthSurveyPartEnum": {
    "PSYCHOMOTOR_DEVELOPMENT": "pszichomotoros fejlődés",
    "SEIZURE_TYPES": "rohamtípusok",
    "SEIZURES_HISTORY": "rohamok története",
    "SEIZURES_CHARACTERISTICS": "a rohamok jellemzői",
    "COMORBIDITIES": "társbetegségek",
    "DRUGS_HISTORY": "kezelés"
  },
  "TimeRangeEnum": {
    "1M": "1 hónap",
    "3M": "3 hónap",
    "6M": "6 hónap",
    "1Y": "1 év",
    "2Y": "2 év",
    "3Y": "3 év",
    "SINCE_FIRST_SEIZURE": "Az első roham óta",
    "SINCE_BIRTH": "Születés óta",
    "1H": "1 óra",
    "2H": "2 óra",
    "3H": "3 óra",
    "4H": "4 óra",
    "5H": "5 óra",
    "6H": "6 óra"
  },
  "ShortTimeRangeEnum": {
    "h": "h",
    "m": "min"
  },
  "MedicalRequestStatusEnum": {
    "NEW": "Új",
    "ACCEPTED": "Elfogadott",
    "DONE": "Kész",
    "REJECTED": "Elutasítva",
    "CANCELLED": "Törölve"
  },
  "PersonalDocumentEnum": {
    "PASSPORT": "Útlevél",
    "DRIVING_LICENSE": "Jogosítvány"
  },
  "NSPHSourceEnum": {
    "INTERNET_SEARCH": "Internetes keresés",
    "SOCIAL_MEDIA": "Közösségi média",
    "INTERNET_ARTICLE": "Internetes cikk",
    "PRESS_ARTICLE": "Sajtócikk",
    "TV": "Televízió",
    "FAMILY_FRIENDS": "Családtól vagy barátoktól",
    "DOCTOR": "Orvostól",
    "OTHER": "Egyéb"
  },
  "NFZBranchEnum": {
    "NFZ_01": "01 – Az Országos Egészségügyi Alap Wrocław-i alsó-sziléziai fiókja",
    "NFZ_02": "02 - Az Országos Egészségügyi Alap Kujawsko-Pomorskie fiókja Bydgoszczban",
    "NFZ_03": "03 - Az Országos Egészségpénztár lublini fiókja",
    "NFZ_04": "04 - Az Országos Egészségpénztár Lubuski Fióktelepe, Zielona Góra",
    "NFZ_05": "05 - Az Országos Egészségpénztár Łódź-i fiókja",
    "NFZ_06": "06 - Az Országos Egészségügyi Alap Małopolska fiókja Krakkóban",
    "NFZ_07": "07 - Az Országos Egészségügyi Alap Mazowiecki fiókja Varsóban",
    "NFZ_08": "08 – Az Országos Egészségpénztár opolei fiókja",
    "NFZ_09": "09. – Az Országos Egészségügyi Alap Kárpátaljai fiókja Rzeszówban",
    "NFZ_10": "10 - Az Országos Egészségpénztár Podlaski fiókja Białystokban",
    "NFZ_11": "11 – Az Országos Egészségügyi Alap pomerániai fiókja Gdańskban",
    "NFZ_12": "12 - Az Országos Egészségpénztár sziléziai fiókja Katowicében",
    "NFZ_13": "13 - Az Országos Egészségügyi Alap Świętokrzyski fiókja Kielcében",
    "NFZ_14": "14 - Az Országos Egészségpénztár Warmia és Mazury Fióktelepe Olsztynben",
    "NFZ_15": "15 - Az Országos Egészségügyi Alap Wielkopolska fiókja Poznańban",
    "NFZ_16": "16 - Az Országos Egészségügyi Alap nyugat-pomerániai fiókja Szczecinben"
  },
  "VisitPackageEnum": {
    "START_PACKAGE": "0. felmérés + 1. látogatás",
    "ADHD_PACKAGE": "ADHD csomag",
    "REGULAR_VISIT_EEG_PACKAGE": "Rendszeres vizit + EEG",
    "MMPI2_PACKAGE": "MMPI-2 személyiségteszt",
    "CBT_PACKAGE": "CBT terápia"
  },
  "CountryEnum": Countries.hu,
  "WellBeingEnum": {
    "WORST": "maximum rosszabb / rossz",
    "MUCH_WORSE": "sokkal rosszabb",
    "BIT_WORSE": "kicsit rosszabb",
    "SAME": "azonos",
    "BIT_BETTER": "egy kicsivel jobb",
    "MUCH_BETTER": "sokkal jobb",
    "IDEAL": "tökéletes, teljes egészség"
  },
  "CurrentTreatmentEnum": {
    "WORST": "teljesen rossz, nem működik, és nem akarom folytatni",
    "VERY_BAD": "nem jó, inkább lecserélem",
    "BAD": "Nem vagyok jól, de folytathatom",
    "NO_CHANGE": "Nem érzek változást",
    "BIT_BETTER": "rendben van, kicsit jobban érzem magam ettől a kezeléstől",
    "MUCH_BETTER": "javulás van, a kezelés egyértelműen segít",
    "IDEAL": "a kezelés tökéletes, teljes siker"
  },
  "SideEffectsEnum": {
    "WORST": "annyira komolyak, hogy nem akarom biztosan folytatni ezt a kezelést",
    "BAD": "jelentősek és zavarnak, de ha kell, folytathatom",
    "BEARABLE": "észrevehetőek, de nem olyan rosszak a kezelés megváltoztatásához",
    "MINIMAL": "rendben van, a mellékhatások minimálisak, rám nincs hatással"
  },
  "TreatmentEfficiencyEnum": {
    "IDEAL": "Tökéletes",
    "GOOD": "Jó",
    "MEDIOCRE": "Középszerű",
    "BAD": "Rossz",
    "WORST": "A legrosszabb, hogy változtatni kell a kezelésen"
  },
  "TreatmentSafetyEnum": {
    "TOTAL": "Teljes",
    "ACCEPTABLE": "Elfogadható",
    "WEAK": "Gyenge",
    "BAD": "Rossz",
    "WORST": "Kizárja a gyógyszert, a szükséges változtatást"
  },
  "AdditionalIncidentsEnum": {
    "NEW_DISEASES": "Új betegségek",
    "INJURIES": "Sérülések",
    "HOSPITALIZATIONS": "Kórházi ápolás",
    "OTHER": "Egyéb",
    "NONE": "Egyik sem"
  },
  "SeizureCountEnum": {
    "MORE": "Több",
    "LESS": "Kevésbé",
    "SAME": "Azonos",
    "NONE": "Egyik sem"
  },
  "SeizureEvaluationEnum": {
    "WORSE": "Rosszabb",
    "BETTER": "Öngyújtó",
    "NONE": "Egyik sem"
  },
  "AllSeizureRegisteredEnum": {
    "TRUE": "Mindegyiket regisztráltam",
    "FALSE": "Nem regisztráltam őket – most megteszem"
  },
  "ExternalReferralTypeEnum": {
    "CLINIC": "a szakrendelőbe",
    "LABORATORY": "a diagnosztikai laboratóriumba",
    "HOSPITAL": "kórházi kezelésre"
  },
  "CertificateTypeEnum": {
    "DRUGS_INTAKE": "Igazolvány az egészségügyi központnak a szedett gyógyszerekről",
    "POSTPONE_VACCINATION": "Igazolás az oltás elhalasztásáról",
    "NO_CONTRAINDICATION_FOR_VACCINE": "Bizonyítvány, hogy nincs ellenjavallat a védőoltásokhoz",
    "NO_CONTRAINDICATION_FOR_PROCEDURE": "Bizonyítvány, hogy nincs ellenjavallat az eljáráshoz",
    "NO_CONTRAINDICATION_FOR_ACTIVITIES": "Tanúsítvány, hogy nincs ellenjavallat az órákon való részvételhez",
    "NO_CONTRAINDICATION_FOR_WORK": "Tanúsítvány arról, hogy nincs ellenjavallat a fizikai munka elvégzéséhez",
    "OTHER": "Egyéb"
  },
  "CertificateTypeDescriptionEnum": {
    "DRUGS_INTAKE": "Ezúton igazolom, hogy a beteg a Neurosphera Neurológiai, Epilepszia és Pszichiátriai Centrum gondozása alatt áll. Gyógyszerek felírását kérem a lakhelyen. A beteg a következő gyógyszereket szedi",
    "POSTPONE_VACCINATION": "Ezúton igazolom, hogy a beteg a Neurosphera Neurológiai, Epilepszia és Pszichiátriai Centrum gondozása alatt áll. ig kérem az oltások elhalasztását",
    "NO_CONTRAINDICATION_FOR_VACCINE": "Ezúton igazolom, hogy a beteg a Neurosphera Neurológiai, Epilepszia és Pszichiátriai Centrum gondozása alatt áll. A kötelező védőoltásoknak nincs ellenjavallata",
    "NO_CONTRAINDICATION_FOR_PROCEDURE": "Ezúton igazolom, hogy a beteg a Neurosphera Neurológiai, Epilepszia és Pszichiátriai Centrum gondozása alatt áll. A tervezett eljárás során az általános érzéstelenítésnek nincs ellenjavallata",
    "NO_CONTRAINDICATION_FOR_ACTIVITIES": "Ezúton igazolom, hogy a beteg a Neurosphera Neurológiai, Epilepszia és Pszichiátriai Centrum gondozása alatt áll. A tevékenységekben való részvételnek nincs ellenjavallata",
    "NO_CONTRAINDICATION_FOR_WORK": "Ezúton igazolom, hogy a beteg a Neurosphera Neurológiai, Epilepszia és Pszichiátriai Centrum gondozása alatt áll. A fizikai munkának nincs ellenjavallata.",
    "OTHER": "Ezúton igazolom, hogy a beteg a Neurosphera Neurológiai, Epilepszia és Pszichiátriai Centrum gondozása alatt áll."
  },
  "MessageVisitSummaryStatusEnum": {
    "NEW": "Meg nem valósult",
    "PENDING": "Válaszra várva",
    "RESPONDED": "válaszolt",
    "DONE": "Kész",
    "UNIDIRECTIONAL": "Egyirányú"
  },
  "SickLeaveTypeEnum": {
    "DISEASE": "Betegség",
    "CARE": "Betegek gondozása"
  },
  "SickLeaveIndicationEnum": {
    "SHOULD_LIE": "Hazudni kéne",
    "CAN_WALK": "Tud járni"
  },
  "SickLeaveInsuredInEnum": {
    "ZUS": "ZUS",
    "KRUS": "KRUS",
    "OTHER_PL": "más Lengyelországban",
    "OTHER_COUNTRY": "más országban"
  },
  "SickLeaveStatusEnum": {
    "ISSUED": "Kiadott",
    "CANCELED": "Törölve"
  },
  "SurveyCompletedEnum": {
    "YES": "Igen",
    "NO": "Nem",
    "PARTIALLY": "Félig kész"
  },
  "EegCompletedEnum": {
    "YES": "Igen",
    "NO": "Nem",
    "BEFORE_NEXT": "1. látogatás előtt megteszi"
  },
  "DocplannerIssueTypeEnum": {
    "DP_VISIT_NOT_BOOKED": "A látogatás nem ZN-ben foglalt",
    "DP_VISIT_NOT_CANCELED": "A látogatást nem törölték ZN-ben",
    "DP_VISIT_NOT_MOVED": "Látogatás nem költözött ZN-ben",
    "NOT_ELIGIBLE_FOR_CONSULTATION": "Új beteg - konzultáció"
  },
  "DocplannerVisitStatusEnum": {
    "NEW": "Új",
    "CANCELED_BY_SCHEDULER": "Nem fizetett",
    "CANCELED_BY_PERSON": "Törölve",
    "SOLVED": "Megoldva",
    "PAID_NOT_DONE": "Fizetett",
    "DONE": "Kész"
  },
  "ReferralStatusEnum": {
    "NEW": "Új",
    "CANCELLED": "Törölve",
    "DONE": "Kész"
  },
  "VisitCaseSolvedEnum": {
    "APPOINTMENT": "Időpont egyeztetés",
    "RESIGNATION": "Lemondás",
    "OTHER": "Egyéb"
  },
  "VisitPaymentMarkingEnum": {
    "WAITING_FOR_PAYMENT": "Fizetésre jelölve",
    "PAYMENT_RECEIVED": "Fizetettként megjelölve"
  },
  "PaymentProviderEnum": {
    "PAYU": "PayU",
    "PAYNOW": "Fizess most"
  },
  "VisitPackageTemplateType": {
    "EPILEPSY": "Epilepszia",
    "CONSULTATION": "Konzultáció"
  },
  "SexEnumAbbr": {
    "FEMALE": "F",
    "MALE": "M"
  },
  "TherapyTypeEnum": {
    "POLI": "Politerápia",
    "MONO": "Monoterápia",
    "NO_ACTIVE": "Nem aktív terápia"
  },
  "ComorbidityTypeEnum": {
    "HEADACHES": "Fejfájás",
    "PSYCHIATRIC": "Pszichiátriai betegségek",
    "NEUROLOGICAL": "Neurológiai betegségek",
    "INFECTIOUS": "Gyulladásos és fertőző betegségek",
    "CANCER": "Rák betegségek",
    "IMMUNE": "Vér- és immunbetegségek",
    "ENDOCRINE": "Endokrin és anyagcsere betegségek",
    "EYE": "Szembetegségek",
    "LARYNGOLOGICAL": "Gégészeti betegségek",
    "HEART": "A szív és a keringési rendszer betegségei",
    "RESPIRATORY": "Légúti betegségek",
    "DIGESTIVE": "Emésztőrendszeri betegségek",
    "SKIN": "Bőrbetegségek",
    "BONE": "Csontbetegségek",
    "UROGENITAL": "Genitourináris betegségek",
    "DEVELOPMENTAL_DEFECTS": "Fejlődési, születési rendellenességek és kromoszóma-rendellenességek",
    "INJURIES_POISONING": "Sérülések és mérgezések",
    "SOMATIC": "Az epilepszia kezelését befolyásoló betegségek"
  },
  "PatientAccountRemovalReason": {
    "NO_MEDICAL_SERVICE": "Nem találtam olyan szolgáltatást, ami érdekelne",
    "NO_DATES": "Nem találtam megfelelő látogatási időpontot",
    "PRICE": "A szolgáltatások árai nem megfelelőek számomra",
    "OTHER": "Más"
  },
  "PrescriptionRejectedReasonEnum": {
    "VISIT_ONLY": "A kiválasztott gyógyszerre csak látogatás során lehet receptet felírni",
    "INQUIRY_REQUIRED": "A kiválasztott gyógyszerre a recept a beteg aktuális egészségi állapotának megismerése után írható ki",
    "ALREADY_PRESCRIBED": "A korábban kiadott receptnek meg kell felelnie a beteg szükségleteinek",
    "OTHER": "Más"
  },
  "BirthTypeEnum": {
    "PHYSIOLOGICAL": "Fiziológiai (a természeti erők)",
    "PLANNED_CESAREAN": "Császármetszés (tervben)",
    "UNPLANNED_CESAREAN": "császármetszés (nem tervezett)",
    "VACUUM": "Erők / vákuum"
  },
  "PaymentRefundRestrictionsEnum": {
    "REFUND_100": "több mint 7 nappal a szolgáltatás előtt, 100% visszatérítés",
    "REFUND_75": "75% visszatérítés 6-5 nappal a szolgáltatás előtt",
    "REFUND_50": "50% visszatérítés a szolgáltatás előtt 4-3 nappal",
    "REFUND_25": "25% visszatérítés 2 nappal a szolgáltatás előtt",
    "REFUND_0": "a szolgáltatást megelőző napon és a szolgáltatás napján visszatérítés nélkül"
  },
  "NeuroscreenDiagnosisEnum": {
    "HEADACHES": "Fejfájás",
    "MOVEMENT_ISSUES": "Mozgászavarok",
    "MEMORY_ISSUES": "Memóriazavarok",
    "EATING_ISSUES": "Étkezési zavarok",
    "CONSCIOUSNESS_ISSUES": "Tudatzavarok",
    "PSYCHOMOTOR_DEVELOPMENT_ISSUES": "Pszichomotoros fejlődési zavarok",
    "EPILEPSY": "Epilepszia és görcsrohamok",
    "SPINE_ISSUES": "Gerinc problémák",
    "MUTIPLE_SCLEROSIS": "Sclerosis multiplex",
    "DIZZINESS": "Szédülés",
    "AUTISM": "Autizmus spektrum",
    "OTHER_SYMPTOMS": "Egyéb neurológiai tünetek"
  },
  "NeuroscreenFrequencyEnum": {
    "ALWAYS": "Igen",
    "SOMETIMES": "Néha",
    "RARELY": "Ritkán",
    "NEVER": "Soha"
  }
}