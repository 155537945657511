/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { NoteZeroObservations } from '../model';
// @ts-ignore
import { SurveyZeroNoteDto } from '../model';
/**
 * SurveyZeroNoteControllerApi - axios parameter creator
 * @export
 */
export const SurveyZeroNoteControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} visitId 
         * @param {NoteZeroObservations} noteZeroObservations 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToObservations: async (visitId: number, noteZeroObservations: NoteZeroObservations, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('addToObservations', 'visitId', visitId)
            // verify required parameter 'noteZeroObservations' is not null or undefined
            assertParamExists('addToObservations', 'noteZeroObservations', noteZeroObservations)
            const localVarPath = `/api/survey-zero-note/observations/{visitId}`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteZeroObservations, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyZeroNote: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getSurveyZeroNote', 'patientId', patientId)
            const localVarPath = `/api/survey-zero-note/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SurveyZeroNoteDto} surveyZeroNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSurveyZeroNote: async (surveyZeroNoteDto: SurveyZeroNoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyZeroNoteDto' is not null or undefined
            assertParamExists('saveSurveyZeroNote', 'surveyZeroNoteDto', surveyZeroNoteDto)
            const localVarPath = `/api/survey-zero-note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveyZeroNoteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveyZeroNoteControllerApi - functional programming interface
 * @export
 */
export const SurveyZeroNoteControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SurveyZeroNoteControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} visitId 
         * @param {NoteZeroObservations} noteZeroObservations 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addToObservations(visitId: number, noteZeroObservations: NoteZeroObservations, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addToObservations(visitId, noteZeroObservations, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSurveyZeroNote(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<SurveyZeroNoteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSurveyZeroNote(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SurveyZeroNoteDto} surveyZeroNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSurveyZeroNote(surveyZeroNoteDto: SurveyZeroNoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<SurveyZeroNoteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSurveyZeroNote(surveyZeroNoteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SurveyZeroNoteControllerApi - factory interface
 * @export
 */
export const SurveyZeroNoteControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SurveyZeroNoteControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} visitId 
         * @param {NoteZeroObservations} noteZeroObservations 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToObservations(visitId: number, noteZeroObservations: NoteZeroObservations, options?: any): Promise<string> {
            return localVarFp.addToObservations(visitId, noteZeroObservations, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyZeroNote(patientId: number, options?: any): Promise<SurveyZeroNoteDto> {
            return localVarFp.getSurveyZeroNote(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SurveyZeroNoteDto} surveyZeroNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSurveyZeroNote(surveyZeroNoteDto: SurveyZeroNoteDto, options?: any): Promise<SurveyZeroNoteDto> {
            return localVarFp.saveSurveyZeroNote(surveyZeroNoteDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SurveyZeroNoteControllerApi - object-oriented interface
 * @export
 * @class SurveyZeroNoteControllerApi
 * @extends {BaseAPI}
 */
export class SurveyZeroNoteControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} visitId 
     * @param {NoteZeroObservations} noteZeroObservations 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyZeroNoteControllerApi
     */
    public addToObservations(visitId: number, noteZeroObservations: NoteZeroObservations, options?: AxiosRequestConfig) {
        return SurveyZeroNoteControllerApiFp(this.configuration).addToObservations(visitId, noteZeroObservations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyZeroNoteControllerApi
     */
    public getSurveyZeroNote(patientId: number, options?: AxiosRequestConfig) {
        return SurveyZeroNoteControllerApiFp(this.configuration).getSurveyZeroNote(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SurveyZeroNoteDto} surveyZeroNoteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyZeroNoteControllerApi
     */
    public saveSurveyZeroNote(surveyZeroNoteDto: SurveyZeroNoteDto, options?: AxiosRequestConfig) {
        return SurveyZeroNoteControllerApiFp(this.configuration).saveSurveyZeroNote(surveyZeroNoteDto, options).then((request) => request(this.axios, this.basePath));
    }
}
