export default {
    user: {
        notFound: 'User not found',
        alreadyExists: 'User with given login already exists',
        unauthorized: 'You cannot perform this action because you do not have permission',
        emailAlreadyExists: 'This e-mail address is already register in the system',
        cannotRemove: {
            paymentsNotRefunded: 'Account cannot be removed because of not returned payments'
        }
    },
    doctor: {
        notFound: 'Doctor not found',
        missingCertificate: 'ZUS certificate is missing',
        priceList: {
            notFound: 'Doctor\'s price list not found',
            wrongGrossPrice: 'Wrong gross price selected',
            grossPriceCalculation: 'Wrong calculation of the visit price',
            notAvailable: 'Selected service is not available',
            durationOutOfSync: 'The duration of the services of the selected type should be the same'
        },
        medicalService: {
            notFound: 'Medical service not found'
        },
        forms: {
            nfzContract: {
                numberDuplicated: "Doctor with specified NFZ contract number already exists"
            }
        },
        certificate: {
            numberInvalid: "Niepoprawny numer certyfikatu lekarza"
        },
        selection: {
            request: {
                alreadyPending: "A request to change lead doctor was already sent and is awaiting doctor reply",
                incorrectStatus: "A request to change lead doctor has incorrect status",
                incorrectState: "A request to change lead doctor is in incorrect state"
            }
        }
    },
    patient: {
        notFound: 'Patient not found',
        mood: {
            future: 'You cannot add mood from future'
        }
    },
    position: {
        notFound: 'Position not found'
    },
    visit: {
        notFound: 'Visit not found',
        notDone: 'Visit not done',
        notPaid: 'Visit not paid',
        alreadyReported: 'Visit has already been reported to P1 and cannot be modifier anymore',
        edit: {
            notAllowed: 'Visit edit not allowed'
        },
        time: {
            booked: 'Date from past selected'
        },
        appointment: {
            typeInvalid: "Invalid appointment type"
        },
        recommendations: {
            empty: 'Recommendations should be filled before ending the visit'
        },
        processing: {
            error: 'An error occurred while processing visit'
        },
        summary: {
            pdf: 'An error occurred while generating pdf document for visit'
        },
        invoice: {
            pdf: 'An error occurred while generating invoice pdf for visit'
        },
        message: {
            notInitialized: 'Conversation has not been started by patient yet',
            limitExceeded: 'Message limit for one visit has been exhausted',
            notResponded: 'Your last message has not been responded yet'
        },
        afterVisitZeroAvailable: 'Functionality not available before first visit',
        paymentMarkingNotAllowed: 'This visit cannot be marked because the price is too high',
        changeDisabled: 'The date or doctor for this appointment has already been changed. The system does not allow another change. If you have any doubts, please contact us.'
    },
    slot: {
        stepNotAllowed: 'The medical service does not define the possibility of choosing the length of the visit',
        timeNotInRange: 'The selected visit length is not within the possible range',
        timeIndivisibleByStep: 'The selected visit length is not divisible by the defined step'
    },
    payment: {
        receipt: {
            pdf: 'An error occurred while generating receipt for payment'
        },
        creation: 'An error occurred while saving payment method',
        visit: {
            alreadyDone: 'Visit already paid for',
            stillProcessing: 'Visit is still being processed',
            unknownState: 'Visit payment error, please contact Neuroterminal team'
        },
        alreadyDone: 'Payment already completed',
        genericError: 'An error occurred while processing payment'
    },
    date: {
        selected: {
            fromPast: 'Visit time booked'
        },
        range: {
            collision: 'Selected date ranges are in conflict with each other'
        }
    },
    consultation: {
        notFound: "Consultation not found"
    },
    nip: {
        invalid: "Invalid NIP"
    },
    medicalEvent: {
        notFound: "Event not found",
        invalidData: "Invalid event data"
    },
    medicalRequest: {
        notFound: "Request not found",
        invalidData: "Invalid request",
        alreadyAssigned: "Request already assigned"
    },
    medicalResult: {
        invalidData: "Invalid result",
        notFound: "Result not found",
        description: {
            notFound: "Result description not found"
        },
        file: {
            invalidData: "Invalid result file data"
        }
    },
    medicalDocument: {
        addError: "An error occurred while saving document",
        editError: "An error occurred while editing document",
        downloadError: "An error occurred while downloading file",
        deleteError: "An error occurred while deleting document",
        file: {
            saveError: "An error occurred while saving file"
        }
    },
    drugStock: {
        negativeAmount: 'Amount cannot be less than 0',
        notUnique: 'You cannot select the same product many times'
    },
    product: {  
        notFound: 'Product not found',
        notUnique: 'You cannot select the same product many times'
    },
    patientSeizuresHistory: {
        futureDateSelected: 'Future date was selected',
        periodConflict: 'Selected periods conflict with each other'
    },
    patientSeizureCharacteristics: {
        notFound: 'The patient\'s seizure history was not fully completed'
    },
    healthSurvey: {
        alreadyInitialized: 'Your medical history has been already initialized',
        patientHasNotEpilepsy: 'Only patients with epilepsy have access to survey 0'
    },
    form: {
        invalidEmail: 'Invalid e-mail address',
        invalidPhone: 'Invalid phone number'
    },
    passwordReset: {
        tokenIncorrect: 'Sent link is incorrect lub expired',
        internalError: 'Error while processing password change request',
        unsecure: 'Password should contain at least 10 characters, upper and lower case letters, numbers and special characters'
    },
    email: {
        sendError: 'Email send error occurred'
    },
    sms: {
        noPhone: 'Unknown phone number for selected user'
    },
    videochat: {
        getTokenFailed: 'Connection error occurred',
        sessionExpired: 'Visit time expired. Connection cannot be established.'
    },
    doctorNote: {
        notFound: 'Doctor note not found'
    },
    referral: {
        notFound: 'Referral not found',
        cancelFree: 'Cannot cancel referral for a free visit'
    },
    harmonogram: {
        notFound: 'The chosen date of visit is already booked'
    },
    unexpected: 'Unexpected error occurred. Try again later',
    incompleteData: 'Data is incomplete',
    cered: {
        prescription: {
            creation: 'An error occurred while issuing prescription',
            psychotropicNotUnique: 'Prescription cannot contain a combination of psychotropic drugs with other drugs',
            prescriptionTypeNotUnique: 'Prescription cannot contain a combination of RP i RPW drugs',
            prescriptionFileNotAvailable: 'Prescription printout is not available'
        },
        ereferral: {
            creation: 'An error occurred while issuing e-referral'
        },
        ewus: {
            login: 'An error occurred while logging into EWUŚ',
            check: 'An error occurred while checking patient insurance in EWUŚ'
        },
        zus: {
            connect: 'An error occurred while logging into ZUS',
            cannotCancel: 'This sick leave cannot be canceled',
            cannotFindWorkPlace: 'Cannot find NEUROSPHERA work place in ZUS account'
        }
    },
    icd10: {
        notUnique: 'You cannot select the same icd10 many times'
    },
    visitPackage: {
        dateCollision: 'Package visits cannot be appointed on the same day',
        dateNotEqual: 'Some of visits should take place the same day',
        timeCollision: 'Selected dates of visits conflict with each other',
        wrongDuration: 'Invalid visit duration',
        invalidService: 'Some of selected services are not available in this package',
        moveDateCollision: 'The new appointment overlaps with another appointment in the package',
        someVisitsDone: 'Visits from a package that has already been started cannot be canceled',
        notActive: 'Visit package is not available',
        differentDoctor: 'Some of the visits should be with the same doctor',
        differentPlace: 'Some of the visits should be at the same location (or online)'
    },
    request: {
        blocked: {
            reset: 'Cannot send link for password change before {n} hours has passed since last password change request. Please contact customer service.',
            registration: 'Cannot send link for password change before {n} hours has passed since last password change request. Please contact customer service.',
            notFound: 'Cannot unlock password reset because previous request not found.',
            notActive: 'Visit package is not available'
        }
    },
    docplanner: {
        issue: {
            alreadyResolved: 'Issue has been already marked as resolved',
            actionNotImplemented: 'There is no automatic action prepared for such issue type',
            notFound: 'Issue not found'
        }
    },
    hashtag: {
        alreadyExists: 'Hashtag with given name already exists'
    },
    generic: {
        alreadyExists: 'Object with given identifier already exists'
    },
    partner: {
        notMatch: 'Not found matching service'
    }
}
