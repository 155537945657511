/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MessageDto } from '../model';
// @ts-ignore
import { MessageVisitDto } from '../model';
// @ts-ignore
import { PageMessageDto } from '../model';
// @ts-ignore
import { PageMessageVisitDto } from '../model';
// @ts-ignore
import { Pageable } from '../model';
// @ts-ignore
import { VisitMessageContentDto } from '../model';
// @ts-ignore
import { VisitMessageContentUpdateDto } from '../model';
/**
 * MessageApiControllerApi - axios parameter creator
 * @export
 */
export const MessageApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} doctorPricelistItemId 
         * @param {number} [referralId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMessageVisit: async (doctorPricelistItemId: number, referralId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorPricelistItemId' is not null or undefined
            assertParamExists('addMessageVisit', 'doctorPricelistItemId', doctorPricelistItemId)
            const localVarPath = `/api/message/visit/new/{doctorPricelistItemId}`
                .replace(`{${"doctorPricelistItemId"}}`, encodeURIComponent(String(doctorPricelistItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referralId !== undefined) {
                localVarQueryParameter['referralId'] = referralId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewUnidirectionalMessage: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('createNewUnidirectionalMessage', 'patientId', patientId)
            const localVarPath = `/api/message/visit/new/unidirectional/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {boolean} [isDone] 
         * @param {string} [phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserMessageVisits: async (pageable: Pageable, isDone?: boolean, phrase?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getCurrentUserMessageVisits', 'pageable', pageable)
            const localVarPath = `/api/message/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isDone !== undefined) {
                localVarQueryParameter['isDone'] = isDone;
            }

            if (phrase !== undefined) {
                localVarQueryParameter['phrase'] = phrase;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastMessageVisitByPatientId: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getLastMessageVisitByPatientId', 'patientId', patientId)
            const localVarPath = `/api/message/patient/{patientId}/last`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} visitPublicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageVisitByVisitId: async (visitPublicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitPublicId' is not null or undefined
            assertParamExists('getMessageVisitByVisitId', 'visitPublicId', visitPublicId)
            const localVarPath = `/api/message/visit/{visitPublicId}`
                .replace(`{${"visitPublicId"}}`, encodeURIComponent(String(visitPublicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesByVisitId: async (visitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('getMessagesByVisitId', 'visitId', visitId)
            const localVarPath = `/api/message/visit/{visitId}/messages`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesForDashboardByTypeAndStatusAndMarking: async (pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getMessagesForDashboardByTypeAndStatusAndMarking', 'pageable', pageable)
            const localVarPath = `/api/message/pending/paged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientMessageVisitsArchive: async (patientId: number, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientMessageVisitsArchive', 'patientId', patientId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getPatientMessageVisitsArchive', 'pageable', pageable)
            const localVarPath = `/api/message/contacts/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnidirectionalVisitMessageInitials: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getUnidirectionalVisitMessageInitials', 'patientId', patientId)
            const localVarPath = `/api/message/visit/unidirectional/{patientId}/initials`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitMessageContent: async (visitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('getVisitMessageContent', 'visitId', visitId)
            const localVarPath = `/api/message/visit/{visitId}/content`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasNewPaidMessageVisits: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/message/new/exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsMarkedById: async (messageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('markMessageAsMarkedById', 'messageId', messageId)
            const localVarPath = `/api/message/{messageId}/mark`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} messageId 
         * @param {boolean} [setTimeResponded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsReadById: async (messageId: number, setTimeResponded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('markMessageAsReadById', 'messageId', messageId)
            const localVarPath = `/api/message/{messageId}/read`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (setTimeResponded !== undefined) {
                localVarQueryParameter['setTimeResponded'] = setTimeResponded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {VisitMessageContentUpdateDto} visitMessageContentUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVisitMessageContent: async (visitId: number, visitMessageContentUpdateDto: VisitMessageContentUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('saveVisitMessageContent', 'visitId', visitId)
            // verify required parameter 'visitMessageContentUpdateDto' is not null or undefined
            assertParamExists('saveVisitMessageContent', 'visitMessageContentUpdateDto', visitMessageContentUpdateDto)
            const localVarPath = `/api/message/visit/{visitId}/content`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visitMessageContentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        send: async (visitId: number, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('send', 'visitId', visitId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('send', 'body', body)
            const localVarPath = `/api/message/visit/{visitId}`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {VisitMessageContentUpdateDto} visitMessageContentUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendUnidirectionalMessageVisit: async (visitId: number, visitMessageContentUpdateDto: VisitMessageContentUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('sendUnidirectionalMessageVisit', 'visitId', visitId)
            // verify required parameter 'visitMessageContentUpdateDto' is not null or undefined
            assertParamExists('sendUnidirectionalMessageVisit', 'visitMessageContentUpdateDto', visitMessageContentUpdateDto)
            const localVarPath = `/api/message/visit/unidirectional/{visitId}/send`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visitMessageContentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMessagesFromVisitRead: async (visitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('setMessagesFromVisitRead', 'visitId', visitId)
            const localVarPath = `/api/message/read/visit/{visitId}`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageApiControllerApi - functional programming interface
 * @export
 */
export const MessageApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} doctorPricelistItemId 
         * @param {number} [referralId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMessageVisit(doctorPricelistItemId: number, referralId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMessageVisit(doctorPricelistItemId, referralId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewUnidirectionalMessage(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewUnidirectionalMessage(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {boolean} [isDone] 
         * @param {string} [phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUserMessageVisits(pageable: Pageable, isDone?: boolean, phrase?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PageMessageVisitDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUserMessageVisits(pageable, isDone, phrase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastMessageVisitByPatientId(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<MessageVisitDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastMessageVisitByPatientId(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} visitPublicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageVisitByVisitId(visitPublicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<MessageVisitDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageVisitByVisitId(visitPublicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessagesByVisitId(visitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<MessageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessagesByVisitId(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessagesForDashboardByTypeAndStatusAndMarking(pageable: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PageMessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessagesForDashboardByTypeAndStatusAndMarking(pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientMessageVisitsArchive(patientId: number, pageable: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PageMessageVisitDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientMessageVisitsArchive(patientId, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnidirectionalVisitMessageInitials(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<VisitMessageContentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnidirectionalVisitMessageInitials(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitMessageContent(visitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<VisitMessageContentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitMessageContent(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasNewPaidMessageVisits(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasNewPaidMessageVisits(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markMessageAsMarkedById(messageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markMessageAsMarkedById(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} messageId 
         * @param {boolean} [setTimeResponded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markMessageAsReadById(messageId: number, setTimeResponded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markMessageAsReadById(messageId, setTimeResponded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {VisitMessageContentUpdateDto} visitMessageContentUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveVisitMessageContent(visitId: number, visitMessageContentUpdateDto: VisitMessageContentUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<MessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveVisitMessageContent(visitId, visitMessageContentUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async send(visitId: number, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<MessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.send(visitId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {VisitMessageContentUpdateDto} visitMessageContentUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendUnidirectionalMessageVisit(visitId: number, visitMessageContentUpdateDto: VisitMessageContentUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<MessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendUnidirectionalMessageVisit(visitId, visitMessageContentUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMessagesFromVisitRead(visitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMessagesFromVisitRead(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessageApiControllerApi - factory interface
 * @export
 */
export const MessageApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} doctorPricelistItemId 
         * @param {number} [referralId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMessageVisit(doctorPricelistItemId: number, referralId?: number, options?: any): Promise<number> {
            return localVarFp.addMessageVisit(doctorPricelistItemId, referralId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewUnidirectionalMessage(patientId: number, options?: any): Promise<number> {
            return localVarFp.createNewUnidirectionalMessage(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {boolean} [isDone] 
         * @param {string} [phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserMessageVisits(pageable: Pageable, isDone?: boolean, phrase?: string, options?: any): Promise<PageMessageVisitDto> {
            return localVarFp.getCurrentUserMessageVisits(pageable, isDone, phrase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastMessageVisitByPatientId(patientId: number, options?: any): Promise<MessageVisitDto> {
            return localVarFp.getLastMessageVisitByPatientId(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} visitPublicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageVisitByVisitId(visitPublicId: string, options?: any): Promise<MessageVisitDto> {
            return localVarFp.getMessageVisitByVisitId(visitPublicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesByVisitId(visitId: number, options?: any): Promise<Array<MessageDto>> {
            return localVarFp.getMessagesByVisitId(visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesForDashboardByTypeAndStatusAndMarking(pageable: Pageable, options?: any): Promise<PageMessageDto> {
            return localVarFp.getMessagesForDashboardByTypeAndStatusAndMarking(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientMessageVisitsArchive(patientId: number, pageable: Pageable, options?: any): Promise<PageMessageVisitDto> {
            return localVarFp.getPatientMessageVisitsArchive(patientId, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnidirectionalVisitMessageInitials(patientId: number, options?: any): Promise<VisitMessageContentDto> {
            return localVarFp.getUnidirectionalVisitMessageInitials(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitMessageContent(visitId: number, options?: any): Promise<VisitMessageContentDto> {
            return localVarFp.getVisitMessageContent(visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasNewPaidMessageVisits(options?: any): Promise<boolean> {
            return localVarFp.hasNewPaidMessageVisits(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsMarkedById(messageId: number, options?: any): Promise<string> {
            return localVarFp.markMessageAsMarkedById(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} messageId 
         * @param {boolean} [setTimeResponded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsReadById(messageId: number, setTimeResponded?: boolean, options?: any): Promise<string> {
            return localVarFp.markMessageAsReadById(messageId, setTimeResponded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {VisitMessageContentUpdateDto} visitMessageContentUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVisitMessageContent(visitId: number, visitMessageContentUpdateDto: VisitMessageContentUpdateDto, options?: any): Promise<MessageDto> {
            return localVarFp.saveVisitMessageContent(visitId, visitMessageContentUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        send(visitId: number, body: string, options?: any): Promise<MessageDto> {
            return localVarFp.send(visitId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {VisitMessageContentUpdateDto} visitMessageContentUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendUnidirectionalMessageVisit(visitId: number, visitMessageContentUpdateDto: VisitMessageContentUpdateDto, options?: any): Promise<MessageDto> {
            return localVarFp.sendUnidirectionalMessageVisit(visitId, visitMessageContentUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMessagesFromVisitRead(visitId: number, options?: any): Promise<boolean> {
            return localVarFp.setMessagesFromVisitRead(visitId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageApiControllerApi - object-oriented interface
 * @export
 * @class MessageApiControllerApi
 * @extends {BaseAPI}
 */
export class MessageApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} doctorPricelistItemId 
     * @param {number} [referralId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiControllerApi
     */
    public addMessageVisit(doctorPricelistItemId: number, referralId?: number, options?: AxiosRequestConfig) {
        return MessageApiControllerApiFp(this.configuration).addMessageVisit(doctorPricelistItemId, referralId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiControllerApi
     */
    public createNewUnidirectionalMessage(patientId: number, options?: AxiosRequestConfig) {
        return MessageApiControllerApiFp(this.configuration).createNewUnidirectionalMessage(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {boolean} [isDone] 
     * @param {string} [phrase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiControllerApi
     */
    public getCurrentUserMessageVisits(pageable: Pageable, isDone?: boolean, phrase?: string, options?: AxiosRequestConfig) {
        return MessageApiControllerApiFp(this.configuration).getCurrentUserMessageVisits(pageable, isDone, phrase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiControllerApi
     */
    public getLastMessageVisitByPatientId(patientId: number, options?: AxiosRequestConfig) {
        return MessageApiControllerApiFp(this.configuration).getLastMessageVisitByPatientId(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} visitPublicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiControllerApi
     */
    public getMessageVisitByVisitId(visitPublicId: string, options?: AxiosRequestConfig) {
        return MessageApiControllerApiFp(this.configuration).getMessageVisitByVisitId(visitPublicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiControllerApi
     */
    public getMessagesByVisitId(visitId: number, options?: AxiosRequestConfig) {
        return MessageApiControllerApiFp(this.configuration).getMessagesByVisitId(visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiControllerApi
     */
    public getMessagesForDashboardByTypeAndStatusAndMarking(pageable: Pageable, options?: AxiosRequestConfig) {
        return MessageApiControllerApiFp(this.configuration).getMessagesForDashboardByTypeAndStatusAndMarking(pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiControllerApi
     */
    public getPatientMessageVisitsArchive(patientId: number, pageable: Pageable, options?: AxiosRequestConfig) {
        return MessageApiControllerApiFp(this.configuration).getPatientMessageVisitsArchive(patientId, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiControllerApi
     */
    public getUnidirectionalVisitMessageInitials(patientId: number, options?: AxiosRequestConfig) {
        return MessageApiControllerApiFp(this.configuration).getUnidirectionalVisitMessageInitials(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiControllerApi
     */
    public getVisitMessageContent(visitId: number, options?: AxiosRequestConfig) {
        return MessageApiControllerApiFp(this.configuration).getVisitMessageContent(visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiControllerApi
     */
    public hasNewPaidMessageVisits(options?: AxiosRequestConfig) {
        return MessageApiControllerApiFp(this.configuration).hasNewPaidMessageVisits(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiControllerApi
     */
    public markMessageAsMarkedById(messageId: number, options?: AxiosRequestConfig) {
        return MessageApiControllerApiFp(this.configuration).markMessageAsMarkedById(messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} messageId 
     * @param {boolean} [setTimeResponded] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiControllerApi
     */
    public markMessageAsReadById(messageId: number, setTimeResponded?: boolean, options?: AxiosRequestConfig) {
        return MessageApiControllerApiFp(this.configuration).markMessageAsReadById(messageId, setTimeResponded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {VisitMessageContentUpdateDto} visitMessageContentUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiControllerApi
     */
    public saveVisitMessageContent(visitId: number, visitMessageContentUpdateDto: VisitMessageContentUpdateDto, options?: AxiosRequestConfig) {
        return MessageApiControllerApiFp(this.configuration).saveVisitMessageContent(visitId, visitMessageContentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiControllerApi
     */
    public send(visitId: number, body: string, options?: AxiosRequestConfig) {
        return MessageApiControllerApiFp(this.configuration).send(visitId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {VisitMessageContentUpdateDto} visitMessageContentUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiControllerApi
     */
    public sendUnidirectionalMessageVisit(visitId: number, visitMessageContentUpdateDto: VisitMessageContentUpdateDto, options?: AxiosRequestConfig) {
        return MessageApiControllerApiFp(this.configuration).sendUnidirectionalMessageVisit(visitId, visitMessageContentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApiControllerApi
     */
    public setMessagesFromVisitRead(visitId: number, options?: AxiosRequestConfig) {
        return MessageApiControllerApiFp(this.configuration).setMessagesFromVisitRead(visitId, options).then((request) => request(this.axios, this.basePath));
    }
}
