/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FinalizeChunkUploadDto } from '../model';
// @ts-ignore
import { ListResponseDtoListMedicalDocumentDto } from '../model';
// @ts-ignore
import { MedicalDocumentDiskStatsDto } from '../model';
// @ts-ignore
import { MedicalDocumentDto } from '../model';
// @ts-ignore
import { Pageable } from '../model';
// @ts-ignore
import { PairLongString } from '../model';
/**
 * MedicalDocumentControllerApi - axios parameter creator
 * @export
 */
export const MedicalDocumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} medicalDocumentId 
         * @param {number} chunkNumber 
         * @param {any} chunk 
         * @param {string} [uploadId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appendChunk: async (medicalDocumentId: number, chunkNumber: number, chunk: any, uploadId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'medicalDocumentId' is not null or undefined
            assertParamExists('appendChunk', 'medicalDocumentId', medicalDocumentId)
            // verify required parameter 'chunkNumber' is not null or undefined
            assertParamExists('appendChunk', 'chunkNumber', chunkNumber)
            // verify required parameter 'chunk' is not null or undefined
            assertParamExists('appendChunk', 'chunk', chunk)
            const localVarPath = `/api/medical-document/{medicalDocumentId}/chunk/add`
                .replace(`{${"medicalDocumentId"}}`, encodeURIComponent(String(medicalDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (chunkNumber !== undefined) {
                localVarQueryParameter['chunkNumber'] = chunkNumber;
            }

            if (uploadId !== undefined) {
                localVarQueryParameter['uploadId'] = uploadId;
            }


            if (chunk !== undefined) { 
                localVarFormParams.append('chunk', chunk as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedicalDocumentDto} medicalDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentChunksPlaceholder: async (medicalDocumentDto: MedicalDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'medicalDocumentDto' is not null or undefined
            assertParamExists('createDocumentChunksPlaceholder', 'medicalDocumentDto', medicalDocumentDto)
            const localVarPath = `/api/medical-document/chunk/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medicalDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedicalDocument: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMedicalDocument', 'id', id)
            const localVarPath = `/api/medical-document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMedicalDocumentFileContent: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadMedicalDocumentFileContent', 'id', id)
            const localVarPath = `/api/medical-document/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [additionDate] 
         * @param {string} [documentName] 
         * @param {string} [documentType] 
         * @param {string} [fileContent] 
         * @param {string} [fileMimeType] 
         * @param {string} [fileName] 
         * @param {number} [fileSize] 
         * @param {number} [id2] 
         * @param {number} [patientId] 
         * @param {string} [resultDate] 
         * @param {number} [uploadedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editMedicalDocument: async (id: number, additionDate?: string, documentName?: string, documentType?: string, fileContent?: string, fileMimeType?: string, fileName?: string, fileSize?: number, id2?: number, patientId?: number, resultDate?: string, uploadedById?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editMedicalDocument', 'id', id)
            const localVarPath = `/api/medical-document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (additionDate !== undefined) { 
                localVarFormParams.append('additionDate', additionDate as any);
            }
    
            if (documentName !== undefined) { 
                localVarFormParams.append('documentName', documentName as any);
            }
    
            if (documentType !== undefined) { 
                localVarFormParams.append('documentType', documentType as any);
            }
    
            if (fileContent !== undefined) { 
                localVarFormParams.append('fileContent', fileContent as any);
            }
    
            if (fileMimeType !== undefined) { 
                localVarFormParams.append('fileMimeType', fileMimeType as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('fileName', fileName as any);
            }
    
            if (fileSize !== undefined) { 
                localVarFormParams.append('fileSize', fileSize as any);
            }
    
            if (id2 !== undefined) { 
                localVarFormParams.append('id', id2 as any);
            }
    
            if (patientId !== undefined) { 
                localVarFormParams.append('patientId', patientId as any);
            }
    
            if (resultDate !== undefined) { 
                localVarFormParams.append('resultDate', resultDate as any);
            }
    
            if (uploadedById !== undefined) { 
                localVarFormParams.append('uploadedById', uploadedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FinalizeChunkUploadDto} finalizeChunkUploadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeChunksImport: async (finalizeChunkUploadDto: FinalizeChunkUploadDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'finalizeChunkUploadDto' is not null or undefined
            assertParamExists('finalizeChunksImport', 'finalizeChunkUploadDto', finalizeChunkUploadDto)
            const localVarPath = `/api/medical-document/chunk/finalize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(finalizeChunkUploadDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalDocumentationDiskStats: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/medical-document/disk-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalDocumentsCount: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getMedicalDocumentsCount', 'patientId', patientId)
            const localVarPath = `/api/medical-document/patient/{patientId}/count`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {string} [nameSearchPhase] 
         * @param {Array<'HOSPITAL_INFORMATION_CARD' | 'DISEASE_HISTORY' | 'VIDEO_EVENT' | 'LABORATORY_TEST_RESULT' | 'VISUAL_RESULT_MRI_FILE' | 'VISUAL_RESULT_MRI_DESC' | 'VISUAL_RESULT_CT_FILE' | 'VISUAL_RESULT_CT_DESC' | 'VISUAL_RESULT_EEG_FILE' | 'VISUAL_RESULT_EEG_DESC' | 'VISUAL_RESULT_ADDITIONAL_FILE' | 'VISUAL_RESULT_ADDITIONAL_DESC' | 'OTHER' | 'PATIENT_CERTIFICATE' | 'STATE_CERTIFICATE' | 'EEG_DESCRIPTION'>} [documentTypes] 
         * @param {string} [resultDateFrom] 
         * @param {string} [resultDateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalDocumentsPaged: async (patientId: number, pageable: Pageable, nameSearchPhase?: string, documentTypes?: Array<'HOSPITAL_INFORMATION_CARD' | 'DISEASE_HISTORY' | 'VIDEO_EVENT' | 'LABORATORY_TEST_RESULT' | 'VISUAL_RESULT_MRI_FILE' | 'VISUAL_RESULT_MRI_DESC' | 'VISUAL_RESULT_CT_FILE' | 'VISUAL_RESULT_CT_DESC' | 'VISUAL_RESULT_EEG_FILE' | 'VISUAL_RESULT_EEG_DESC' | 'VISUAL_RESULT_ADDITIONAL_FILE' | 'VISUAL_RESULT_ADDITIONAL_DESC' | 'OTHER' | 'PATIENT_CERTIFICATE' | 'STATE_CERTIFICATE' | 'EEG_DESCRIPTION'>, resultDateFrom?: string, resultDateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getMedicalDocumentsPaged', 'patientId', patientId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getMedicalDocumentsPaged', 'pageable', pageable)
            const localVarPath = `/api/medical-document/patient/{patientId}/paged`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nameSearchPhase !== undefined) {
                localVarQueryParameter['nameSearchPhase'] = nameSearchPhase;
            }

            if (documentTypes) {
                localVarQueryParameter['documentTypes'] = documentTypes;
            }

            if (resultDateFrom !== undefined) {
                localVarQueryParameter['resultDateFrom'] = (resultDateFrom as any instanceof Date) ?
                    (resultDateFrom as any).toISOString().substr(0,10) :
                    resultDateFrom;
            }

            if (resultDateTo !== undefined) {
                localVarQueryParameter['resultDateTo'] = (resultDateTo as any instanceof Date) ?
                    (resultDateTo as any).toISOString().substr(0,10) :
                    resultDateTo;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<any>} uploads 
         * @param {MedicalDocumentDto} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDocument: async (uploads: Array<any>, data?: MedicalDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploads' is not null or undefined
            assertParamExists('saveDocument', 'uploads', uploads)
            const localVarPath = `/api/medical-document/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (data !== undefined) { 
                localVarFormParams.append('data', new Blob([JSON.stringify(data)], { type: "application/json", }));
            }
                if (uploads) {
                uploads.forEach((element) => {
                    localVarFormParams.append('uploads', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} medicalDocumentId 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamVideo: async (medicalDocumentId: number, range?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'medicalDocumentId' is not null or undefined
            assertParamExists('streamVideo', 'medicalDocumentId', medicalDocumentId)
            const localVarPath = `/api/medical-document/stream/{medicalDocumentId}`
                .replace(`{${"medicalDocumentId"}}`, encodeURIComponent(String(medicalDocumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (range !== undefined && range !== null) {
                localVarHeaderParameter['Range'] = String(range);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MedicalDocumentControllerApi - functional programming interface
 * @export
 */
export const MedicalDocumentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MedicalDocumentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} medicalDocumentId 
         * @param {number} chunkNumber 
         * @param {any} chunk 
         * @param {string} [uploadId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appendChunk(medicalDocumentId: number, chunkNumber: number, chunk: any, uploadId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appendChunk(medicalDocumentId, chunkNumber, chunk, uploadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedicalDocumentDto} medicalDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocumentChunksPlaceholder(medicalDocumentDto: MedicalDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PairLongString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocumentChunksPlaceholder(medicalDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMedicalDocument(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMedicalDocument(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadMedicalDocumentFileContent(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadMedicalDocumentFileContent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [additionDate] 
         * @param {string} [documentName] 
         * @param {string} [documentType] 
         * @param {string} [fileContent] 
         * @param {string} [fileMimeType] 
         * @param {string} [fileName] 
         * @param {number} [fileSize] 
         * @param {number} [id2] 
         * @param {number} [patientId] 
         * @param {string} [resultDate] 
         * @param {number} [uploadedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editMedicalDocument(id: number, additionDate?: string, documentName?: string, documentType?: string, fileContent?: string, fileMimeType?: string, fileName?: string, fileSize?: number, id2?: number, patientId?: number, resultDate?: string, uploadedById?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editMedicalDocument(id, additionDate, documentName, documentType, fileContent, fileMimeType, fileName, fileSize, id2, patientId, resultDate, uploadedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FinalizeChunkUploadDto} finalizeChunkUploadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalizeChunksImport(finalizeChunkUploadDto: FinalizeChunkUploadDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finalizeChunksImport(finalizeChunkUploadDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMedicalDocumentationDiskStats(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<MedicalDocumentDiskStatsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMedicalDocumentationDiskStats(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMedicalDocumentsCount(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMedicalDocumentsCount(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {string} [nameSearchPhase] 
         * @param {Array<'HOSPITAL_INFORMATION_CARD' | 'DISEASE_HISTORY' | 'VIDEO_EVENT' | 'LABORATORY_TEST_RESULT' | 'VISUAL_RESULT_MRI_FILE' | 'VISUAL_RESULT_MRI_DESC' | 'VISUAL_RESULT_CT_FILE' | 'VISUAL_RESULT_CT_DESC' | 'VISUAL_RESULT_EEG_FILE' | 'VISUAL_RESULT_EEG_DESC' | 'VISUAL_RESULT_ADDITIONAL_FILE' | 'VISUAL_RESULT_ADDITIONAL_DESC' | 'OTHER' | 'PATIENT_CERTIFICATE' | 'STATE_CERTIFICATE' | 'EEG_DESCRIPTION'>} [documentTypes] 
         * @param {string} [resultDateFrom] 
         * @param {string} [resultDateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMedicalDocumentsPaged(patientId: number, pageable: Pageable, nameSearchPhase?: string, documentTypes?: Array<'HOSPITAL_INFORMATION_CARD' | 'DISEASE_HISTORY' | 'VIDEO_EVENT' | 'LABORATORY_TEST_RESULT' | 'VISUAL_RESULT_MRI_FILE' | 'VISUAL_RESULT_MRI_DESC' | 'VISUAL_RESULT_CT_FILE' | 'VISUAL_RESULT_CT_DESC' | 'VISUAL_RESULT_EEG_FILE' | 'VISUAL_RESULT_EEG_DESC' | 'VISUAL_RESULT_ADDITIONAL_FILE' | 'VISUAL_RESULT_ADDITIONAL_DESC' | 'OTHER' | 'PATIENT_CERTIFICATE' | 'STATE_CERTIFICATE' | 'EEG_DESCRIPTION'>, resultDateFrom?: string, resultDateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<ListResponseDtoListMedicalDocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMedicalDocumentsPaged(patientId, pageable, nameSearchPhase, documentTypes, resultDateFrom, resultDateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<any>} uploads 
         * @param {MedicalDocumentDto} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDocument(uploads: Array<any>, data?: MedicalDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDocument(uploads, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} medicalDocumentId 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamVideo(medicalDocumentId: number, range?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamVideo(medicalDocumentId, range, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MedicalDocumentControllerApi - factory interface
 * @export
 */
export const MedicalDocumentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MedicalDocumentControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} medicalDocumentId 
         * @param {number} chunkNumber 
         * @param {any} chunk 
         * @param {string} [uploadId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appendChunk(medicalDocumentId: number, chunkNumber: number, chunk: any, uploadId?: string, options?: any): Promise<void> {
            return localVarFp.appendChunk(medicalDocumentId, chunkNumber, chunk, uploadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedicalDocumentDto} medicalDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocumentChunksPlaceholder(medicalDocumentDto: MedicalDocumentDto, options?: any): Promise<PairLongString> {
            return localVarFp.createDocumentChunksPlaceholder(medicalDocumentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedicalDocument(id: number, options?: any): Promise<object> {
            return localVarFp.deleteMedicalDocument(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadMedicalDocumentFileContent(id: number, options?: any): Promise<any> {
            return localVarFp.downloadMedicalDocumentFileContent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [additionDate] 
         * @param {string} [documentName] 
         * @param {string} [documentType] 
         * @param {string} [fileContent] 
         * @param {string} [fileMimeType] 
         * @param {string} [fileName] 
         * @param {number} [fileSize] 
         * @param {number} [id2] 
         * @param {number} [patientId] 
         * @param {string} [resultDate] 
         * @param {number} [uploadedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editMedicalDocument(id: number, additionDate?: string, documentName?: string, documentType?: string, fileContent?: string, fileMimeType?: string, fileName?: string, fileSize?: number, id2?: number, patientId?: number, resultDate?: string, uploadedById?: number, options?: any): Promise<object> {
            return localVarFp.editMedicalDocument(id, additionDate, documentName, documentType, fileContent, fileMimeType, fileName, fileSize, id2, patientId, resultDate, uploadedById, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FinalizeChunkUploadDto} finalizeChunkUploadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeChunksImport(finalizeChunkUploadDto: FinalizeChunkUploadDto, options?: any): Promise<void> {
            return localVarFp.finalizeChunksImport(finalizeChunkUploadDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalDocumentationDiskStats(options?: any): Promise<MedicalDocumentDiskStatsDto> {
            return localVarFp.getMedicalDocumentationDiskStats(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalDocumentsCount(patientId: number, options?: any): Promise<number> {
            return localVarFp.getMedicalDocumentsCount(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {string} [nameSearchPhase] 
         * @param {Array<'HOSPITAL_INFORMATION_CARD' | 'DISEASE_HISTORY' | 'VIDEO_EVENT' | 'LABORATORY_TEST_RESULT' | 'VISUAL_RESULT_MRI_FILE' | 'VISUAL_RESULT_MRI_DESC' | 'VISUAL_RESULT_CT_FILE' | 'VISUAL_RESULT_CT_DESC' | 'VISUAL_RESULT_EEG_FILE' | 'VISUAL_RESULT_EEG_DESC' | 'VISUAL_RESULT_ADDITIONAL_FILE' | 'VISUAL_RESULT_ADDITIONAL_DESC' | 'OTHER' | 'PATIENT_CERTIFICATE' | 'STATE_CERTIFICATE' | 'EEG_DESCRIPTION'>} [documentTypes] 
         * @param {string} [resultDateFrom] 
         * @param {string} [resultDateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalDocumentsPaged(patientId: number, pageable: Pageable, nameSearchPhase?: string, documentTypes?: Array<'HOSPITAL_INFORMATION_CARD' | 'DISEASE_HISTORY' | 'VIDEO_EVENT' | 'LABORATORY_TEST_RESULT' | 'VISUAL_RESULT_MRI_FILE' | 'VISUAL_RESULT_MRI_DESC' | 'VISUAL_RESULT_CT_FILE' | 'VISUAL_RESULT_CT_DESC' | 'VISUAL_RESULT_EEG_FILE' | 'VISUAL_RESULT_EEG_DESC' | 'VISUAL_RESULT_ADDITIONAL_FILE' | 'VISUAL_RESULT_ADDITIONAL_DESC' | 'OTHER' | 'PATIENT_CERTIFICATE' | 'STATE_CERTIFICATE' | 'EEG_DESCRIPTION'>, resultDateFrom?: string, resultDateTo?: string, options?: any): Promise<ListResponseDtoListMedicalDocumentDto> {
            return localVarFp.getMedicalDocumentsPaged(patientId, pageable, nameSearchPhase, documentTypes, resultDateFrom, resultDateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<any>} uploads 
         * @param {MedicalDocumentDto} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDocument(uploads: Array<any>, data?: MedicalDocumentDto, options?: any): Promise<void> {
            return localVarFp.saveDocument(uploads, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} medicalDocumentId 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamVideo(medicalDocumentId: number, range?: string, options?: any): Promise<object> {
            return localVarFp.streamVideo(medicalDocumentId, range, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MedicalDocumentControllerApi - object-oriented interface
 * @export
 * @class MedicalDocumentControllerApi
 * @extends {BaseAPI}
 */
export class MedicalDocumentControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} medicalDocumentId 
     * @param {number} chunkNumber 
     * @param {any} chunk 
     * @param {string} [uploadId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDocumentControllerApi
     */
    public appendChunk(medicalDocumentId: number, chunkNumber: number, chunk: any, uploadId?: string, options?: AxiosRequestConfig) {
        return MedicalDocumentControllerApiFp(this.configuration).appendChunk(medicalDocumentId, chunkNumber, chunk, uploadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalDocumentDto} medicalDocumentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDocumentControllerApi
     */
    public createDocumentChunksPlaceholder(medicalDocumentDto: MedicalDocumentDto, options?: AxiosRequestConfig) {
        return MedicalDocumentControllerApiFp(this.configuration).createDocumentChunksPlaceholder(medicalDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDocumentControllerApi
     */
    public deleteMedicalDocument(id: number, options?: AxiosRequestConfig) {
        return MedicalDocumentControllerApiFp(this.configuration).deleteMedicalDocument(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDocumentControllerApi
     */
    public downloadMedicalDocumentFileContent(id: number, options?: AxiosRequestConfig) {
        return MedicalDocumentControllerApiFp(this.configuration).downloadMedicalDocumentFileContent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [additionDate] 
     * @param {string} [documentName] 
     * @param {string} [documentType] 
     * @param {string} [fileContent] 
     * @param {string} [fileMimeType] 
     * @param {string} [fileName] 
     * @param {number} [fileSize] 
     * @param {number} [id2] 
     * @param {number} [patientId] 
     * @param {string} [resultDate] 
     * @param {number} [uploadedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDocumentControllerApi
     */
    public editMedicalDocument(id: number, additionDate?: string, documentName?: string, documentType?: string, fileContent?: string, fileMimeType?: string, fileName?: string, fileSize?: number, id2?: number, patientId?: number, resultDate?: string, uploadedById?: number, options?: AxiosRequestConfig) {
        return MedicalDocumentControllerApiFp(this.configuration).editMedicalDocument(id, additionDate, documentName, documentType, fileContent, fileMimeType, fileName, fileSize, id2, patientId, resultDate, uploadedById, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinalizeChunkUploadDto} finalizeChunkUploadDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDocumentControllerApi
     */
    public finalizeChunksImport(finalizeChunkUploadDto: FinalizeChunkUploadDto, options?: AxiosRequestConfig) {
        return MedicalDocumentControllerApiFp(this.configuration).finalizeChunksImport(finalizeChunkUploadDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDocumentControllerApi
     */
    public getMedicalDocumentationDiskStats(options?: AxiosRequestConfig) {
        return MedicalDocumentControllerApiFp(this.configuration).getMedicalDocumentationDiskStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDocumentControllerApi
     */
    public getMedicalDocumentsCount(patientId: number, options?: AxiosRequestConfig) {
        return MedicalDocumentControllerApiFp(this.configuration).getMedicalDocumentsCount(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {Pageable} pageable 
     * @param {string} [nameSearchPhase] 
     * @param {Array<'HOSPITAL_INFORMATION_CARD' | 'DISEASE_HISTORY' | 'VIDEO_EVENT' | 'LABORATORY_TEST_RESULT' | 'VISUAL_RESULT_MRI_FILE' | 'VISUAL_RESULT_MRI_DESC' | 'VISUAL_RESULT_CT_FILE' | 'VISUAL_RESULT_CT_DESC' | 'VISUAL_RESULT_EEG_FILE' | 'VISUAL_RESULT_EEG_DESC' | 'VISUAL_RESULT_ADDITIONAL_FILE' | 'VISUAL_RESULT_ADDITIONAL_DESC' | 'OTHER' | 'PATIENT_CERTIFICATE' | 'STATE_CERTIFICATE' | 'EEG_DESCRIPTION'>} [documentTypes] 
     * @param {string} [resultDateFrom] 
     * @param {string} [resultDateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDocumentControllerApi
     */
    public getMedicalDocumentsPaged(patientId: number, pageable: Pageable, nameSearchPhase?: string, documentTypes?: Array<'HOSPITAL_INFORMATION_CARD' | 'DISEASE_HISTORY' | 'VIDEO_EVENT' | 'LABORATORY_TEST_RESULT' | 'VISUAL_RESULT_MRI_FILE' | 'VISUAL_RESULT_MRI_DESC' | 'VISUAL_RESULT_CT_FILE' | 'VISUAL_RESULT_CT_DESC' | 'VISUAL_RESULT_EEG_FILE' | 'VISUAL_RESULT_EEG_DESC' | 'VISUAL_RESULT_ADDITIONAL_FILE' | 'VISUAL_RESULT_ADDITIONAL_DESC' | 'OTHER' | 'PATIENT_CERTIFICATE' | 'STATE_CERTIFICATE' | 'EEG_DESCRIPTION'>, resultDateFrom?: string, resultDateTo?: string, options?: AxiosRequestConfig) {
        return MedicalDocumentControllerApiFp(this.configuration).getMedicalDocumentsPaged(patientId, pageable, nameSearchPhase, documentTypes, resultDateFrom, resultDateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<any>} uploads 
     * @param {MedicalDocumentDto} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDocumentControllerApi
     */
    public saveDocument(uploads: Array<any>, data?: MedicalDocumentDto, options?: AxiosRequestConfig) {
        return MedicalDocumentControllerApiFp(this.configuration).saveDocument(uploads, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} medicalDocumentId 
     * @param {string} [range] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalDocumentControllerApi
     */
    public streamVideo(medicalDocumentId: number, range?: string, options?: AxiosRequestConfig) {
        return MedicalDocumentControllerApiFp(this.configuration).streamVideo(medicalDocumentId, range, options).then((request) => request(this.axios, this.basePath));
    }
}
