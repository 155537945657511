/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface MessageVisitSummaryDto
 */
export interface MessageVisitSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof MessageVisitSummaryDto
     */
    'doctorId': number;
    /**
     * 
     * @type {string}
     * @memberof MessageVisitSummaryDto
     */
    'doctorName': string;
    /**
     * 
     * @type {number}
     * @memberof MessageVisitSummaryDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageVisitSummaryDto
     */
    'patientId': number;
    /**
     * 
     * @type {string}
     * @memberof MessageVisitSummaryDto
     */
    'patientName': string;
    /**
     * 
     * @type {string}
     * @memberof MessageVisitSummaryDto
     */
    'recentMessageCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageVisitSummaryDto
     */
    'timeFrom'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MessageVisitSummaryDto
     */
    'unidirectional': boolean;
    /**
     * 
     * @type {string}
     * @memberof MessageVisitSummaryDto
     */
    'visitStatus': MessageVisitSummaryDtoVisitStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MessageVisitSummaryDtoVisitStatusEnum {
    New = 'NEW',
    Pending = 'PENDING',
    Responded = 'RESPONDED',
    Done = 'DONE',
    Unidirectional = 'UNIDIRECTIONAL'
}


