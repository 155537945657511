/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { PrescriptionMedicineDto } from './prescription-medicine-dto';

/**
 * 
 * @export
 * @interface PrescriptionIssuanceDto
 */
export interface PrescriptionIssuanceDto {
    /**
     * 
     * @type {string}
     * @memberof PrescriptionIssuanceDto
     */
    'doctorPass'?: string;
    /**
     * 
     * @type {Array<PrescriptionMedicineDto>}
     * @memberof PrescriptionIssuanceDto
     */
    'medicines'?: Array<PrescriptionMedicineDto>;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionIssuanceDto
     */
    'patientLogin'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionIssuanceDto
     */
    'pin'?: string;
    /**
     * 
     * @type {number}
     * @memberof PrescriptionIssuanceDto
     */
    'prescriptionRequestId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionIssuanceDto
     */
    'source'?: PrescriptionIssuanceDtoSourceEnum;
    /**
     * 
     * @type {number}
     * @memberof PrescriptionIssuanceDto
     */
    'visitId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PrescriptionIssuanceDtoSourceEnum {
    Cered = 'CERED',
    Gov = 'GOV'
}


