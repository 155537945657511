import Vue from "vue";
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import SecurityService from "@/service/security.service";

const {KEYCLOAK_URL, KEYCLOAK_REALM, KEYCLOAK_CLIENT_ID} = window['_env_'] || {};

export const keycloakInitOptions = {
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
}

const initOptions = {
    config: {
        realm: KEYCLOAK_REALM,
        url: KEYCLOAK_URL,
        clientId: KEYCLOAK_CLIENT_ID
    },
    init: keycloakInitOptions,
    logout: {
        redirectUri: window.location.origin
    },
    onReady: (keycloak) => {
        if (keycloak.authenticated) {
            SecurityService.setKeycloakAuthorization();
        }
    },
};

Vue.use(VueKeyCloak, initOptions)