import PlEnums from "./enums-pl";
import EnEnums from "./enums-en";
import FrEnums from "./enums-fr";
import CsEnums from "./enums-cs";
import UkEnums from "./enums-uk";
import HuEnums from "./enums-hu";
import DeEnums from "./enums-de";

export default {

    pl: PlEnums,

    en: EnEnums,

    fr: FrEnums,

    cs: CsEnums,

    uk: UkEnums,

    hu: HuEnums,

    de: DeEnums
}
