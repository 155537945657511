import HttpService from "./http.service";
import StorageService from "./storege.service";
import Vue from "vue";
import DataStore from "../utility/data.store";
import {instance as vue} from "../main";
import {Api} from "@/service/api";
import {keycloakInitOptions} from "@/plugin/keycloak";
import jwt_decode from "jwt-decode";

export default new Vue({
    data: () => ({
        authorization: null,
        router: null,
        expiration: null,
        refreshTokenExpiration: null,
        timeoutHandler: null,
        keycloakDomain: null
    }),
    methods: {
        parseJwt(jwt) {
            return jwt_decode(jwt);
        },

        setRouter(router, resetRouter) {
            this.router = router;
            this.resetRouter = resetRouter;
        },

        login(login, password, redirectTo, autologin) {
            this.keycloakLogin(password, autologin, redirectTo);
        },

        keycloakLogin(password, autologin, redirectTo) {
            if (autologin) {
                return HttpService.resourceServer.requests.exchangeAutologinToken(password).then((cookies) => {
                    this.router.replace({'query': null});
                    if (cookies) {
                        let domainPart = this.keycloakDomain ? " Domain=." + this.keycloakDomain + ";" : "";
                        cookies.forEach(cookie => document.cookie = cookie + domainPart);
                        this.$keycloak.keycloak.init(keycloakInitOptions);
                    }
                });
            } else {
                if (redirectTo) {
                    this.$keycloak.keycloak.login({
                        redirectUri: window.location.origin + "/" + redirectTo,
                        locale: vue.getLocaleConfig().language.substring(0, 2)
                    });
                } else {
                    this.$keycloak.keycloak.login({
                        locale: vue.getLocaleConfig().language.substring(0, 2)
                    });
                }
            }
        },

        updateUserLocale() {
            const locale = vue.getLocaleConfig().language;
            if (locale && DataStore.getUser().businessUser.userAccount.locale !== locale) {
                HttpService.resourceServer.requests.updateUserLocale();
            }
        },

        updateUserTimezone() {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if (timezone && DataStore.getUser().businessUser.userAccount.timezone !== timezone) {
                Api.user.updateUserTimezone(timezone);
            }
        },

        logout() {
            return this.keycloakLogout()
        },

        keycloakLogout() {
            this.$keycloak.keycloak.logout();
            DataStore.deleteUser();
            this.deleteAuthorization();
            this.resetRouter();
            return Promise.resolve(true);
        },

        resetPasswordRequest(data) {
            const lowerCase = data.toLowerCase();
            return HttpService.resourceServer.post(`user/password-reset/request`, lowerCase, {
                "Content-Type": "text/plain"
            });
        },

        resendPasswordRequest(email) {
            return HttpService.resourceServer.post(`user/password-reset/resend/${email}`, null);
        },

        resetPasswordConfirm(token, data) {
            return HttpService.resourceServer.post(`user/password-reset/confirm/${token}`, data, {
                "Content-Type": "text/plain"
            });
        },

        setAuthorization(data) {
            if (data) {
                StorageService.setInLocalStorage('authorization', JSON.stringify(data));
                this.authorization = data;
                const now = new Date().getTime();
                this.expiration = this.parseJwt(data.access_token).exp * 1000;
                this.refreshTokenExpiration = this.parseJwt(data.refresh_token).exp * 1000;
            }
        },

        setKeycloakAuthorization() {
            if (this.$keycloak && this.$keycloak.authenticated) {
                this.setAuthorization({
                    "access_token": this.$keycloak.token,
                    "refresh_token": this.$keycloak.refreshToken,
                });
                let roles = this.$keycloak.tokenParsed.realm_access.roles.filter(r => r.startsWith("ROLE_"));
                if (DataStore.isBusinessUserSet() && DataStore.getUser().username === this.$keycloak.userName) {
                    this.updateFeatures();
                    this.resetRouter();
                } else {
                    DataStore.setUser({
                        username: this.$keycloak.tokenParsed.preferred_username,
                        email: this.$keycloak.tokenParsed.email,
                        roles: roles,
                        profile: roles.includes("ROLE_ADMIN") ? "ADMIN" : (roles.includes("ROLE_DOCTOR") ? "DOCTOR" : "PATIENT"),
                        activated: this.$keycloak.tokenParsed.email_verified
                    });
                    HttpService.resourceServer.requests.getBusinessUser().then(result => {
                        DataStore.getUser().businessUser = result;
                        DataStore.updateUser();
                        this.updateFeatures();
                        this.updateUserLocale();
                        this.updateUserTimezone();
                        this.resetRouter();
                        const redirectTo = this.router.currentRoute.query.redirect;
                        this.router.push(redirectTo ? '/' + redirectTo : '/');
                    });
                }
            }
        },

        async updateFeatures() {
            DataStore.setUserFeatures(await Api.feature.getAvailable());
        },

        deleteAuthorization() {
            StorageService.removeFromLocalStorage('authorization');
            this.authorization = null;
            this.expiration = null;
            this.refreshTokenExpiration = null;
            if (this.timeoutHandler) {
                clearTimeout(this.timeoutHandler);
            }
            this.timeoutHandler = null;
        },

        getAccessToken() {
            if (this.$keycloak) {
                return this.$keycloak.token;
            } else {
                return null;
            }
        },

        isAccessTokenSet() {
            return !!this.getAccessToken();
        }
    },

    created() {
        const { KEYCLOAK_DOMAIN} = window['_env_'] || {};
        this.keycloakDomain = KEYCLOAK_DOMAIN;
    }
});
