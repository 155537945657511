/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UserSettingDto
 */
export interface UserSettingDto {
    /**
     * 
     * @type {string}
     * @memberof UserSettingDto
     */
    'code'?: UserSettingDtoCodeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettingDto
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserSettingDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSettingDto
     */
    'role'?: UserSettingDtoRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UserSettingDto
     */
    'type'?: UserSettingDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof UserSettingDto
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSettingDto
     */
    'userAccountLogin'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserSettingDtoCodeEnum {
    VisitCanceledByDoctor = 'VISIT_CANCELED_BY_DOCTOR',
    VisitCanceledByPatient = 'VISIT_CANCELED_BY_PATIENT',
    VisitChangedByPatient = 'VISIT_CHANGED_BY_PATIENT',
    VisitAppointedReminder = 'VISIT_APPOINTED_REMINDER',
    VisitPlannedReminder = 'VISIT_PLANNED_REMINDER',
    DutyPlannedReminder = 'DUTY_PLANNED_REMINDER',
    AdmitPatients = 'ADMIT_PATIENTS',
    MessageReceivedByDoctor = 'MESSAGE_RECEIVED_BY_DOCTOR',
    PrescriptionRequestReceivedByDoctor = 'PRESCRIPTION_REQUEST_RECEIVED_BY_DOCTOR'
}
/**
    * @export
    * @enum {string}
    */
export enum UserSettingDtoRoleEnum {
    Doctor = 'DOCTOR',
    Patient = 'PATIENT'
}
/**
    * @export
    * @enum {string}
    */
export enum UserSettingDtoTypeEnum {
    SmsReminder = 'SMS_REMINDER',
    Other = 'OTHER'
}


