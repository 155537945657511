/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActiveSubstanceDto } from '../model';
// @ts-ignore
import { ICD10Dto } from '../model';
// @ts-ignore
import { ICD9Dto } from '../model';
// @ts-ignore
import { MedicinePackageDto } from '../model';
// @ts-ignore
import { Pageable } from '../model';
// @ts-ignore
import { ProfessionDto } from '../model';
// @ts-ignore
import { ScientificSocietyDto } from '../model';
/**
 * DictionariesApiControllerApi - axios parameter creator
 * @export
 */
export const DictionariesApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveSubstances: async (phrase?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dictionary/active-substances/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (phrase !== undefined) {
                localVarQueryParameter['phrase'] = phrase;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dictionary/drug`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [phrase] 
         * @param {string} [activeSubstance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugs1: async (phrase?: string, activeSubstance?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dictionary/drug/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (phrase !== undefined) {
                localVarQueryParameter['phrase'] = phrase;
            }

            if (activeSubstance !== undefined) {
                localVarQueryParameter['activeSubstance'] = activeSubstance;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIcd10s: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dictionary/icd10`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [phrase] 
         * @param {boolean} [onlyMain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIcd10sBySearchPhrase: async (phrase?: string, onlyMain?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dictionary/icd10/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (phrase !== undefined) {
                localVarQueryParameter['phrase'] = phrase;
            }

            if (onlyMain !== undefined) {
                localVarQueryParameter['onlyMain'] = onlyMain;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIcd9sBySearchPhrase: async (phrase?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dictionary/icd9/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (phrase !== undefined) {
                localVarQueryParameter['phrase'] = phrase;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [filterValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfessions1: async (pageable: Pageable, filterValue?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getProfessions1', 'pageable', pageable)
            const localVarPath = `/api/dictionary/profession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterValue !== undefined) {
                localVarQueryParameter['filterValue'] = filterValue;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [filterValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScientificSocieties: async (pageable: Pageable, filterValue?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getScientificSocieties', 'pageable', pageable)
            const localVarPath = `/api/dictionary/scientific-society`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterValue !== undefined) {
                localVarQueryParameter['filterValue'] = filterValue;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictionariesApiControllerApi - functional programming interface
 * @export
 */
export const DictionariesApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictionariesApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveSubstances(phrase?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<ActiveSubstanceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveSubstances(phrase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDrugs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<MedicinePackageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDrugs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [phrase] 
         * @param {string} [activeSubstance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDrugs1(phrase?: string, activeSubstance?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<MedicinePackageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDrugs1(phrase, activeSubstance, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIcd10s(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<ICD10Dto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIcd10s(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [phrase] 
         * @param {boolean} [onlyMain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIcd10sBySearchPhrase(phrase?: string, onlyMain?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<ICD10Dto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIcd10sBySearchPhrase(phrase, onlyMain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIcd9sBySearchPhrase(phrase?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<ICD9Dto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIcd9sBySearchPhrase(phrase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [filterValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfessions1(pageable: Pageable, filterValue?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<ProfessionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfessions1(pageable, filterValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [filterValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScientificSocieties(pageable: Pageable, filterValue?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<ScientificSocietyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScientificSocieties(pageable, filterValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictionariesApiControllerApi - factory interface
 * @export
 */
export const DictionariesApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictionariesApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveSubstances(phrase?: string, options?: any): Promise<Array<ActiveSubstanceDto>> {
            return localVarFp.getActiveSubstances(phrase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugs(options?: any): Promise<Array<MedicinePackageDto>> {
            return localVarFp.getDrugs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [phrase] 
         * @param {string} [activeSubstance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugs1(phrase?: string, activeSubstance?: string, options?: any): Promise<Array<MedicinePackageDto>> {
            return localVarFp.getDrugs1(phrase, activeSubstance, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIcd10s(options?: any): Promise<Array<ICD10Dto>> {
            return localVarFp.getIcd10s(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [phrase] 
         * @param {boolean} [onlyMain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIcd10sBySearchPhrase(phrase?: string, onlyMain?: boolean, options?: any): Promise<Array<ICD10Dto>> {
            return localVarFp.getIcd10sBySearchPhrase(phrase, onlyMain, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIcd9sBySearchPhrase(phrase?: string, options?: any): Promise<Array<ICD9Dto>> {
            return localVarFp.getIcd9sBySearchPhrase(phrase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [filterValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfessions1(pageable: Pageable, filterValue?: string, options?: any): Promise<Array<ProfessionDto>> {
            return localVarFp.getProfessions1(pageable, filterValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [filterValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScientificSocieties(pageable: Pageable, filterValue?: string, options?: any): Promise<Array<ScientificSocietyDto>> {
            return localVarFp.getScientificSocieties(pageable, filterValue, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DictionariesApiControllerApi - object-oriented interface
 * @export
 * @class DictionariesApiControllerApi
 * @extends {BaseAPI}
 */
export class DictionariesApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} [phrase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiControllerApi
     */
    public getActiveSubstances(phrase?: string, options?: AxiosRequestConfig) {
        return DictionariesApiControllerApiFp(this.configuration).getActiveSubstances(phrase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiControllerApi
     */
    public getDrugs(options?: AxiosRequestConfig) {
        return DictionariesApiControllerApiFp(this.configuration).getDrugs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [phrase] 
     * @param {string} [activeSubstance] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiControllerApi
     */
    public getDrugs1(phrase?: string, activeSubstance?: string, options?: AxiosRequestConfig) {
        return DictionariesApiControllerApiFp(this.configuration).getDrugs1(phrase, activeSubstance, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiControllerApi
     */
    public getIcd10s(options?: AxiosRequestConfig) {
        return DictionariesApiControllerApiFp(this.configuration).getIcd10s(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [phrase] 
     * @param {boolean} [onlyMain] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiControllerApi
     */
    public getIcd10sBySearchPhrase(phrase?: string, onlyMain?: boolean, options?: AxiosRequestConfig) {
        return DictionariesApiControllerApiFp(this.configuration).getIcd10sBySearchPhrase(phrase, onlyMain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [phrase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiControllerApi
     */
    public getIcd9sBySearchPhrase(phrase?: string, options?: AxiosRequestConfig) {
        return DictionariesApiControllerApiFp(this.configuration).getIcd9sBySearchPhrase(phrase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {string} [filterValue] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiControllerApi
     */
    public getProfessions1(pageable: Pageable, filterValue?: string, options?: AxiosRequestConfig) {
        return DictionariesApiControllerApiFp(this.configuration).getProfessions1(pageable, filterValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {string} [filterValue] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiControllerApi
     */
    public getScientificSocieties(pageable: Pageable, filterValue?: string, options?: AxiosRequestConfig) {
        return DictionariesApiControllerApiFp(this.configuration).getScientificSocieties(pageable, filterValue, options).then((request) => request(this.axios, this.basePath));
    }
}
