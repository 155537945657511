/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppointVisitDto } from '../model';
// @ts-ignore
import { DoctorDashboardVisitDto } from '../model';
// @ts-ignore
import { DoctorVisitSlotDto } from '../model';
// @ts-ignore
import { ListResponseDtoListPatientVisitDto } from '../model';
// @ts-ignore
import { ListResponseDtoListVisitDto } from '../model';
// @ts-ignore
import { Pageable } from '../model';
// @ts-ignore
import { PatientVisitDto } from '../model';
// @ts-ignore
import { VisitAwaitingPaymentsInfo } from '../model';
// @ts-ignore
import { VisitBasicDto } from '../model';
// @ts-ignore
import { VisitDto } from '../model';
// @ts-ignore
import { VisitRecommendationDto } from '../model';
/**
 * VisitApiControllerApi - axios parameter creator
 * @export
 */
export const VisitApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {AppointVisitDto} appointVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointVisit: async (patientId: number, appointVisitDto: AppointVisitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('appointVisit', 'patientId', patientId)
            // verify required parameter 'appointVisitDto' is not null or undefined
            assertParamExists('appointVisit', 'appointVisitDto', appointVisitDto)
            const localVarPath = `/api/visit/appoint/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appointVisitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {string} visitPackageCode 
         * @param {AppointVisitDto} appointVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointVisitPackage: async (patientId: number, visitPackageCode: string, appointVisitDto: AppointVisitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('appointVisitPackage', 'patientId', patientId)
            // verify required parameter 'visitPackageCode' is not null or undefined
            assertParamExists('appointVisitPackage', 'visitPackageCode', visitPackageCode)
            // verify required parameter 'appointVisitDto' is not null or undefined
            assertParamExists('appointVisitPackage', 'appointVisitDto', appointVisitDto)
            const localVarPath = `/api/visit/appoint/{patientId}/package/{visitPackageCode}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"visitPackageCode"}}`, encodeURIComponent(String(visitPackageCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appointVisitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelVisit: async (visitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('cancelVisit', 'visitId', visitId)
            const localVarPath = `/api/visit/{visitId}/cancel`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeVisit: async (doctorVisitSlotDto: Array<DoctorVisitSlotDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorVisitSlotDto' is not null or undefined
            assertParamExists('changeVisit', 'doctorVisitSlotDto', doctorVisitSlotDto)
            const localVarPath = `/api/visit/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorVisitSlotDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endVisitConsultation: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('endVisitConsultation', 'id', id)
            const localVarPath = `/api/visit/{id}/end`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBasicInfo: async (visitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('getBasicInfo', 'visitId', visitId)
            const localVarPath = `/api/visit/{visitId}/basic`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBasicInfoByPublicId: async (publicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('getBasicInfoByPublicId', 'publicId', publicId)
            const localVarPath = `/api/visit/public/{publicId}/basic`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorsAllVisits: async (dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visit/doctor/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [patientSearchPhrase] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>} [visitTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorsVisits: async (pageable: Pageable, patientSearchPhrase?: string, dateFrom?: string, dateTo?: string, visitTypes?: Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getDoctorsVisits', 'pageable', pageable)
            const localVarPath = `/api/visit/doctor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (patientSearchPhrase !== undefined) {
                localVarQueryParameter['patientSearchPhrase'] = patientSearchPhrase;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }

            if (visitTypes) {
                localVarQueryParameter['visitTypes'] = visitTypes;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'ABSENT_CONSULTATION' | 'VISIT_0' | 'MIGRATION_VISIT' | 'VISIT_1' | 'FOLLOWUP_VISIT' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'METABOLIC_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'EEG_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'PARTNER_CONSULTATION'} group 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoneVisitsByGroup: async (group: 'ABSENT_CONSULTATION' | 'VISIT_0' | 'MIGRATION_VISIT' | 'VISIT_1' | 'FOLLOWUP_VISIT' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'METABOLIC_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'EEG_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'PARTNER_CONSULTATION', patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'group' is not null or undefined
            assertParamExists('getDoneVisitsByGroup', 'group', group)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getDoneVisitsByGroup', 'patientId', patientId)
            const localVarPath = `/api/visit/medical-group/{group}`
                .replace(`{${"group"}}`, encodeURIComponent(String(group)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (patientId !== undefined) {
                localVarQueryParameter['patientId'] = patientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirstAwaitingPayment: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visit/awaiting-payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowingVisit: async (visitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('getFollowingVisit', 'visitId', visitId)
            const localVarPath = `/api/visit/visit/following/{visitId}`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [withDrugTherapy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastPatientRecommendation: async (withDrugTherapy?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visit/recommendation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (withDrugTherapy !== undefined) {
                localVarQueryParameter['withDrugTherapy'] = withDrugTherapy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [next] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextPatientVisitsInfo: async (next?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visit/next`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (next !== undefined) {
                localVarQueryParameter['next'] = next;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [doctorSearchPhrase] 
         * @param {string} [dateFrom] 
         * @param {string} [timeFrom] 
         * @param {string} [dateTo] 
         * @param {string} [timeTo] 
         * @param {Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>} [visitTypes] 
         * @param {Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>} [visitServices] 
         * @param {boolean} [paidOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsVisits: async (pageable: Pageable, doctorSearchPhrase?: string, dateFrom?: string, timeFrom?: string, dateTo?: string, timeTo?: string, visitTypes?: Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>, visitServices?: Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>, paidOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getPatientsVisits', 'pageable', pageable)
            const localVarPath = `/api/visit/patient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (doctorSearchPhrase !== undefined) {
                localVarQueryParameter['doctorSearchPhrase'] = doctorSearchPhrase;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (timeFrom !== undefined) {
                localVarQueryParameter['timeFrom'] = (timeFrom as any instanceof Date) ?
                    (timeFrom as any).toISOString() :
                    timeFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }

            if (timeTo !== undefined) {
                localVarQueryParameter['timeTo'] = (timeTo as any instanceof Date) ?
                    (timeTo as any).toISOString() :
                    timeTo;
            }

            if (visitTypes) {
                localVarQueryParameter['visitTypes'] = visitTypes;
            }

            if (visitServices) {
                localVarQueryParameter['visitServices'] = visitServices;
            }

            if (paidOnly !== undefined) {
                localVarQueryParameter['paidOnly'] = paidOnly;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodayVisitOneId: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getTodayVisitOneId', 'patientId', patientId)
            const localVarPath = `/api/visit/patient/{patientId}/visit-one`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitByPublicId: async (visitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('getVisitByPublicId', 'visitId', visitId)
            const localVarPath = `/api/visit/{visitId}/detailed`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {boolean} [testRun] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitSummaryPdfById: async (id?: number, testRun?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visit/summary/pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (testRun !== undefined) {
                localVarQueryParameter['testRun'] = testRun;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} paymentUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitsByPaymentUid: async (paymentUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentUid' is not null or undefined
            assertParamExists('getVisitsByPaymentUid', 'paymentUid', paymentUid)
            const localVarPath = `/api/visit/payment/{paymentUid}`
                .replace(`{${"paymentUid"}}`, encodeURIComponent(String(paymentUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitsDatesForDoctorDashboardCalendar: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visit/doctor/dashboard/calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>} [services] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitsForDoctorDashboardByDoctorIdAndDateAndType: async (date: string, services?: Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getVisitsForDoctorDashboardByDoctorIdAndDateAndType', 'date', date)
            const localVarPath = `/api/visit/doctor/dashboard/date/{date}`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (services) {
                localVarQueryParameter['services'] = services;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [installments] 
         * @param {'PAYU' | 'PAYNOW'} [paymentProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payForVisit: async (id: number, installments?: boolean, paymentProvider?: 'PAYU' | 'PAYNOW', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('payForVisit', 'id', id)
            const localVarPath = `/api/visit/{id}/pay`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (installments !== undefined) {
                localVarQueryParameter['installments'] = installments;
            }

            if (paymentProvider !== undefined) {
                localVarQueryParameter['paymentProvider'] = paymentProvider;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignVisit: async (doctorVisitSlotDto: Array<DoctorVisitSlotDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorVisitSlotDto' is not null or undefined
            assertParamExists('reassignVisit', 'doctorVisitSlotDto', doctorVisitSlotDto)
            const localVarPath = `/api/visit/reassign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorVisitSlotDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVisitToMove: async (visitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('setVisitToMove', 'visitId', visitId)
            const localVarPath = `/api/visit/{visitId}/to-move`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VisitApiControllerApi - functional programming interface
 * @export
 */
export const VisitApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VisitApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {AppointVisitDto} appointVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointVisit(patientId: number, appointVisitDto: AppointVisitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointVisit(patientId, appointVisitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {string} visitPackageCode 
         * @param {AppointVisitDto} appointVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointVisitPackage(patientId: number, visitPackageCode: string, appointVisitDto: AppointVisitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointVisitPackage(patientId, visitPackageCode, appointVisitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelVisit(visitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelVisit(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeVisit(doctorVisitSlotDto: Array<DoctorVisitSlotDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeVisit(doctorVisitSlotDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endVisitConsultation(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endVisitConsultation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBasicInfo(visitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<VisitBasicDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBasicInfo(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBasicInfoByPublicId(publicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<VisitBasicDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBasicInfoByPublicId(publicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorsAllVisits(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<VisitDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorsAllVisits(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [patientSearchPhrase] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>} [visitTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorsVisits(pageable: Pageable, patientSearchPhrase?: string, dateFrom?: string, dateTo?: string, visitTypes?: Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<ListResponseDtoListVisitDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorsVisits(pageable, patientSearchPhrase, dateFrom, dateTo, visitTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'ABSENT_CONSULTATION' | 'VISIT_0' | 'MIGRATION_VISIT' | 'VISIT_1' | 'FOLLOWUP_VISIT' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'METABOLIC_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'EEG_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'PARTNER_CONSULTATION'} group 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoneVisitsByGroup(group: 'ABSENT_CONSULTATION' | 'VISIT_0' | 'MIGRATION_VISIT' | 'VISIT_1' | 'FOLLOWUP_VISIT' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'METABOLIC_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'EEG_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'PARTNER_CONSULTATION', patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<VisitDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoneVisitsByGroup(group, patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFirstAwaitingPayment(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<VisitAwaitingPaymentsInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFirstAwaitingPayment(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFollowingVisit(visitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<VisitDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFollowingVisit(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [withDrugTherapy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastPatientRecommendation(withDrugTherapy?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<VisitRecommendationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastPatientRecommendation(withDrugTherapy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [next] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNextPatientVisitsInfo(next?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<PatientVisitDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNextPatientVisitsInfo(next, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [doctorSearchPhrase] 
         * @param {string} [dateFrom] 
         * @param {string} [timeFrom] 
         * @param {string} [dateTo] 
         * @param {string} [timeTo] 
         * @param {Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>} [visitTypes] 
         * @param {Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>} [visitServices] 
         * @param {boolean} [paidOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientsVisits(pageable: Pageable, doctorSearchPhrase?: string, dateFrom?: string, timeFrom?: string, dateTo?: string, timeTo?: string, visitTypes?: Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>, visitServices?: Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>, paidOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<ListResponseDtoListPatientVisitDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientsVisits(pageable, doctorSearchPhrase, dateFrom, timeFrom, dateTo, timeTo, visitTypes, visitServices, paidOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTodayVisitOneId(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTodayVisitOneId(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitByPublicId(visitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<VisitDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitByPublicId(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {boolean} [testRun] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitSummaryPdfById(id?: number, testRun?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitSummaryPdfById(id, testRun, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} paymentUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitsByPaymentUid(paymentUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<VisitDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitsByPaymentUid(paymentUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitsDatesForDoctorDashboardCalendar(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitsDatesForDoctorDashboardCalendar(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>} [services] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitsForDoctorDashboardByDoctorIdAndDateAndType(date: string, services?: Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorDashboardVisitDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitsForDoctorDashboardByDoctorIdAndDateAndType(date, services, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [installments] 
         * @param {'PAYU' | 'PAYNOW'} [paymentProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payForVisit(id: number, installments?: boolean, paymentProvider?: 'PAYU' | 'PAYNOW', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payForVisit(id, installments, paymentProvider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reassignVisit(doctorVisitSlotDto: Array<DoctorVisitSlotDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reassignVisit(doctorVisitSlotDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setVisitToMove(visitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setVisitToMove(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VisitApiControllerApi - factory interface
 * @export
 */
export const VisitApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VisitApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {AppointVisitDto} appointVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointVisit(patientId: number, appointVisitDto: AppointVisitDto, options?: any): Promise<string> {
            return localVarFp.appointVisit(patientId, appointVisitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {string} visitPackageCode 
         * @param {AppointVisitDto} appointVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointVisitPackage(patientId: number, visitPackageCode: string, appointVisitDto: AppointVisitDto, options?: any): Promise<string> {
            return localVarFp.appointVisitPackage(patientId, visitPackageCode, appointVisitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelVisit(visitId: number, options?: any): Promise<boolean> {
            return localVarFp.cancelVisit(visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeVisit(doctorVisitSlotDto: Array<DoctorVisitSlotDto>, options?: any): Promise<object> {
            return localVarFp.changeVisit(doctorVisitSlotDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endVisitConsultation(id: number, options?: any): Promise<void> {
            return localVarFp.endVisitConsultation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBasicInfo(visitId: number, options?: any): Promise<VisitBasicDto> {
            return localVarFp.getBasicInfo(visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBasicInfoByPublicId(publicId: string, options?: any): Promise<VisitBasicDto> {
            return localVarFp.getBasicInfoByPublicId(publicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorsAllVisits(dateFrom?: string, dateTo?: string, options?: any): Promise<Array<VisitDto>> {
            return localVarFp.getDoctorsAllVisits(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [patientSearchPhrase] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>} [visitTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorsVisits(pageable: Pageable, patientSearchPhrase?: string, dateFrom?: string, dateTo?: string, visitTypes?: Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>, options?: any): Promise<ListResponseDtoListVisitDto> {
            return localVarFp.getDoctorsVisits(pageable, patientSearchPhrase, dateFrom, dateTo, visitTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'ABSENT_CONSULTATION' | 'VISIT_0' | 'MIGRATION_VISIT' | 'VISIT_1' | 'FOLLOWUP_VISIT' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'METABOLIC_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'EEG_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'PARTNER_CONSULTATION'} group 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoneVisitsByGroup(group: 'ABSENT_CONSULTATION' | 'VISIT_0' | 'MIGRATION_VISIT' | 'VISIT_1' | 'FOLLOWUP_VISIT' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'METABOLIC_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'EEG_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'PARTNER_CONSULTATION', patientId: number, options?: any): Promise<Array<VisitDto>> {
            return localVarFp.getDoneVisitsByGroup(group, patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirstAwaitingPayment(options?: any): Promise<VisitAwaitingPaymentsInfo> {
            return localVarFp.getFirstAwaitingPayment(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowingVisit(visitId: number, options?: any): Promise<VisitDto> {
            return localVarFp.getFollowingVisit(visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [withDrugTherapy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastPatientRecommendation(withDrugTherapy?: boolean, options?: any): Promise<VisitRecommendationDto> {
            return localVarFp.getLastPatientRecommendation(withDrugTherapy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [next] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextPatientVisitsInfo(next?: number, options?: any): Promise<Array<PatientVisitDto>> {
            return localVarFp.getNextPatientVisitsInfo(next, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {string} [doctorSearchPhrase] 
         * @param {string} [dateFrom] 
         * @param {string} [timeFrom] 
         * @param {string} [dateTo] 
         * @param {string} [timeTo] 
         * @param {Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>} [visitTypes] 
         * @param {Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>} [visitServices] 
         * @param {boolean} [paidOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsVisits(pageable: Pageable, doctorSearchPhrase?: string, dateFrom?: string, timeFrom?: string, dateTo?: string, timeTo?: string, visitTypes?: Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>, visitServices?: Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>, paidOnly?: boolean, options?: any): Promise<ListResponseDtoListPatientVisitDto> {
            return localVarFp.getPatientsVisits(pageable, doctorSearchPhrase, dateFrom, timeFrom, dateTo, timeTo, visitTypes, visitServices, paidOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodayVisitOneId(patientId: number, options?: any): Promise<number> {
            return localVarFp.getTodayVisitOneId(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitByPublicId(visitId: string, options?: any): Promise<VisitDto> {
            return localVarFp.getVisitByPublicId(visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {boolean} [testRun] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitSummaryPdfById(id?: number, testRun?: boolean, options?: any): Promise<any> {
            return localVarFp.getVisitSummaryPdfById(id, testRun, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} paymentUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitsByPaymentUid(paymentUid: string, options?: any): Promise<Array<VisitDto>> {
            return localVarFp.getVisitsByPaymentUid(paymentUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitsDatesForDoctorDashboardCalendar(options?: any): Promise<Array<string>> {
            return localVarFp.getVisitsDatesForDoctorDashboardCalendar(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>} [services] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitsForDoctorDashboardByDoctorIdAndDateAndType(date: string, services?: Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>, options?: any): Promise<Array<DoctorDashboardVisitDto>> {
            return localVarFp.getVisitsForDoctorDashboardByDoctorIdAndDateAndType(date, services, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [installments] 
         * @param {'PAYU' | 'PAYNOW'} [paymentProvider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payForVisit(id: number, installments?: boolean, paymentProvider?: 'PAYU' | 'PAYNOW', options?: any): Promise<string> {
            return localVarFp.payForVisit(id, installments, paymentProvider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignVisit(doctorVisitSlotDto: Array<DoctorVisitSlotDto>, options?: any): Promise<void> {
            return localVarFp.reassignVisit(doctorVisitSlotDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVisitToMove(visitId: number, options?: any): Promise<object> {
            return localVarFp.setVisitToMove(visitId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VisitApiControllerApi - object-oriented interface
 * @export
 * @class VisitApiControllerApi
 * @extends {BaseAPI}
 */
export class VisitApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} patientId 
     * @param {AppointVisitDto} appointVisitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public appointVisit(patientId: number, appointVisitDto: AppointVisitDto, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).appointVisit(patientId, appointVisitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {string} visitPackageCode 
     * @param {AppointVisitDto} appointVisitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public appointVisitPackage(patientId: number, visitPackageCode: string, appointVisitDto: AppointVisitDto, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).appointVisitPackage(patientId, visitPackageCode, appointVisitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public cancelVisit(visitId: number, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).cancelVisit(visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public changeVisit(doctorVisitSlotDto: Array<DoctorVisitSlotDto>, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).changeVisit(doctorVisitSlotDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public endVisitConsultation(id: number, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).endVisitConsultation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public getBasicInfo(visitId: number, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).getBasicInfo(visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public getBasicInfoByPublicId(publicId: string, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).getBasicInfoByPublicId(publicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public getDoctorsAllVisits(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).getDoctorsAllVisits(dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {string} [patientSearchPhrase] 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>} [visitTypes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public getDoctorsVisits(pageable: Pageable, patientSearchPhrase?: string, dateFrom?: string, dateTo?: string, visitTypes?: Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).getDoctorsVisits(pageable, patientSearchPhrase, dateFrom, dateTo, visitTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'ABSENT_CONSULTATION' | 'VISIT_0' | 'MIGRATION_VISIT' | 'VISIT_1' | 'FOLLOWUP_VISIT' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'METABOLIC_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'EEG_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'PARTNER_CONSULTATION'} group 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public getDoneVisitsByGroup(group: 'ABSENT_CONSULTATION' | 'VISIT_0' | 'MIGRATION_VISIT' | 'VISIT_1' | 'FOLLOWUP_VISIT' | 'STANDARD_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'METABOLIC_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'EEG_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'PARTNER_CONSULTATION', patientId: number, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).getDoneVisitsByGroup(group, patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public getFirstAwaitingPayment(options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).getFirstAwaitingPayment(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public getFollowingVisit(visitId: number, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).getFollowingVisit(visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [withDrugTherapy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public getLastPatientRecommendation(withDrugTherapy?: boolean, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).getLastPatientRecommendation(withDrugTherapy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [next] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public getNextPatientVisitsInfo(next?: number, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).getNextPatientVisitsInfo(next, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {string} [doctorSearchPhrase] 
     * @param {string} [dateFrom] 
     * @param {string} [timeFrom] 
     * @param {string} [dateTo] 
     * @param {string} [timeTo] 
     * @param {Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>} [visitTypes] 
     * @param {Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>} [visitServices] 
     * @param {boolean} [paidOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public getPatientsVisits(pageable: Pageable, doctorSearchPhrase?: string, dateFrom?: string, timeFrom?: string, dateTo?: string, timeTo?: string, visitTypes?: Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>, visitServices?: Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>, paidOnly?: boolean, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).getPatientsVisits(pageable, doctorSearchPhrase, dateFrom, timeFrom, dateTo, timeTo, visitTypes, visitServices, paidOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public getTodayVisitOneId(patientId: number, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).getTodayVisitOneId(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} visitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public getVisitByPublicId(visitId: string, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).getVisitByPublicId(visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {boolean} [testRun] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public getVisitSummaryPdfById(id?: number, testRun?: boolean, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).getVisitSummaryPdfById(id, testRun, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} paymentUid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public getVisitsByPaymentUid(paymentUid: string, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).getVisitsByPaymentUid(paymentUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public getVisitsDatesForDoctorDashboardCalendar(options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).getVisitsDatesForDoctorDashboardCalendar(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>} [services] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public getVisitsForDoctorDashboardByDoctorIdAndDateAndType(date: string, services?: Array<'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'>, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).getVisitsForDoctorDashboardByDoctorIdAndDateAndType(date, services, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} [installments] 
     * @param {'PAYU' | 'PAYNOW'} [paymentProvider] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public payForVisit(id: number, installments?: boolean, paymentProvider?: 'PAYU' | 'PAYNOW', options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).payForVisit(id, installments, paymentProvider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public reassignVisit(doctorVisitSlotDto: Array<DoctorVisitSlotDto>, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).reassignVisit(doctorVisitSlotDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApiControllerApi
     */
    public setVisitToMove(visitId: number, options?: AxiosRequestConfig) {
        return VisitApiControllerApiFp(this.configuration).setVisitToMove(visitId, options).then((request) => request(this.axios, this.basePath));
    }
}
