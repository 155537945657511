/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DoctorBasicDto } from './doctor-basic-dto';
import { ICD10Dto } from './icd10-dto';
import { ICD9Dto } from './icd9-dto';
import { PatientBasicDataDto } from './patient-basic-data-dto';

/**
 * 
 * @export
 * @interface ExternalReferralDto
 */
export interface ExternalReferralDto {
    /**
     * 
     * @type {string}
     * @memberof ExternalReferralDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalReferralDto
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalReferralDto
     */
    'destinationDescription'?: string;
    /**
     * 
     * @type {DoctorBasicDto}
     * @memberof ExternalReferralDto
     */
    'doctor'?: DoctorBasicDto;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalReferralDto
     */
    'ereferral'?: boolean;
    /**
     * 
     * @type {ICD10Dto}
     * @memberof ExternalReferralDto
     */
    'icd10'?: ICD10Dto;
    /**
     * 
     * @type {number}
     * @memberof ExternalReferralDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExternalReferralDto
     */
    'issueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalReferralDto
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalReferralDto
     */
    'packageKey'?: string;
    /**
     * 
     * @type {PatientBasicDataDto}
     * @memberof ExternalReferralDto
     */
    'patient'?: PatientBasicDataDto;
    /**
     * 
     * @type {Set<ICD9Dto>}
     * @memberof ExternalReferralDto
     */
    'procedures'?: Set<ICD9Dto>;
    /**
     * 
     * @type {number}
     * @memberof ExternalReferralDto
     */
    'sourceVisitId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExternalReferralDto
     */
    'type'?: ExternalReferralDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ExternalReferralDtoTypeEnum {
    Clinic = 'CLINIC',
    Laboratory = 'LABORATORY',
    Hospital = 'HOSPITAL'
}


