/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { RecentSeizuresDto } from './recent-seizures-dto';

/**
 * 
 * @export
 * @interface SurveySeizureDto
 */
export interface SurveySeizureDto {
    /**
     * 
     * @type {string}
     * @memberof SurveySeizureDto
     */
    'lastSeizureDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveySeizureDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SurveySeizureDto
     */
    'noRecentSeizure': boolean;
    /**
     * 
     * @type {RecentSeizuresDto}
     * @memberof SurveySeizureDto
     */
    'recentSeizures'?: RecentSeizuresDto;
    /**
     * 
     * @type {string}
     * @memberof SurveySeizureDto
     */
    'seizureCountByDoctor'?: SurveySeizureDtoSeizureCountByDoctorEnum;
    /**
     * 
     * @type {string}
     * @memberof SurveySeizureDto
     */
    'seizureCountByPatient'?: SurveySeizureDtoSeizureCountByPatientEnum;
    /**
     * 
     * @type {string}
     * @memberof SurveySeizureDto
     */
    'seizureEvaluationByDoctor'?: SurveySeizureDtoSeizureEvaluationByDoctorEnum;
    /**
     * 
     * @type {string}
     * @memberof SurveySeizureDto
     */
    'seizureEvaluationByPatient'?: SurveySeizureDtoSeizureEvaluationByPatientEnum;
    /**
     * 
     * @type {number}
     * @memberof SurveySeizureDto
     */
    'seizureId': number;
}

/**
    * @export
    * @enum {string}
    */
export enum SurveySeizureDtoSeizureCountByDoctorEnum {
    More = 'MORE',
    Less = 'LESS',
    Same = 'SAME',
    None = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum SurveySeizureDtoSeizureCountByPatientEnum {
    More = 'MORE',
    Less = 'LESS',
    Same = 'SAME',
    None = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum SurveySeizureDtoSeizureEvaluationByDoctorEnum {
    Worse = 'WORSE',
    Better = 'BETTER',
    None = 'NONE',
    Same = 'SAME'
}
/**
    * @export
    * @enum {string}
    */
export enum SurveySeizureDtoSeizureEvaluationByPatientEnum {
    Worse = 'WORSE',
    Better = 'BETTER',
    None = 'NONE',
    Same = 'SAME'
}


