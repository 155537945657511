<template>
    <v-app>

        <v-fade-transition :hide-on-leave="true">
            <router-view/>
        </v-fade-transition>

        <toast></toast>

        <v-dialog v-model="dialog.visible" :persistent="dialog.persistent" max-width="400">
            <v-card>
                <v-card-title class="headline">
                    {{ dialog.header }}
                </v-card-title>
                <v-card-text>
                    {{ dialog.content }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog = {visible: false}">
                        {{ $t('ui.closeDialog') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-app>
</template>

<script>
import EventBus from './utility/event.bus';
import Toast from "@/component/common/toast/toast.component";

export default {
    components: {Toast},
    data: () => ({
        processing: false,
        dialog: {
            visible: false,
            persistent: null,
            header: null,
            content: null,
        },
    }),
    mounted() {
        EventBus.$on('show-dialog', dialog => {
            this.dialog = {...dialog, visible: true};
        })
    },
};
</script>


<style>

html, body {
    margin: 0;
    padding: 0;
}


/*transitions*/

.fade-enter-active, .fade-leave-active {
    transition: opacity .35s ease;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .35s ease;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}


/**/
.view-container {
    max-width: 1400px;
    padding-left: 30px;
    padding-right: 30px;
    margin: auto;
}


/*background colors*/

.theme--light.v-application {
    background: #D5E3F2 !important;
    color: rgba(0, 0, 0, 0.87);
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
    background-color: #f5f5f5;
}

.theme--light.v-navigation-drawer {
    background-color: #f5f5f5;
}

.background-primary {
    background: rgb(31, 147, 208) !important;
}

.background-secondary {
    background: #c2e4f9 !important;
}

.background-success {
    background-color: #77b61e;
}

.background-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

/*margins*/

.fill-half-height {
    height: 50%;
}


/*old*/

.background-black {
    background-color: black;
}

.background-white {
    background-color: white;
}

.background-error-light {
    background-color: #fae0e0;
}

.v-messages__message {
    text-align: right;
}

.v-btn {
    text-transform: none !important;
}

.landing-ornament {
    background-image: url("/login/leftOrnament.svg");
    background-position-x: left;
    background-position-y: bottom;
}

.footer-ornament {
    background-image: url("/login/footerRightOrnament.svg");
    background-position-x: right;
    background-position-y: bottom;
}

/*.theme--light.v-application .application-view header:not(.custom-header) {*/
/*background: rgb(31, 147, 208) !important;*/
/*z-index: 9 !important;*/
/*}*/

.theme--light.v-chip:not(.v-chip--active) {
    background: #E6F7FF !important;
}

.header-color {
    color: #E6F7FF !important;
}

.header-background {
    background: #E6F7FF !important;
}

.header-background-2 {
    background: #9ddffd !important;
}

.header-color-2 {
    color: #9ddffd !important;
}

.landing-title-background {
    color: #172e46;
}

.landing-background {
    background-color: #F6F8FB;
}

.white-background {
    background-color: white;
}

.grey-light-background {
    background-color: #e2e8ef;
}

.caption-small {
    font-size: 0.7em;
}

.caption-medium {
    font-size: 0.875em;
}

.mt-15 {
    margin-top: 60px;
}

.mb-15 {
    margin-bottom: 60px;
}

.px-14 {
    padding-right: 53px !important;
    padding-left: 53px !important;
}

.px-15 {
    padding-right: 60px !important;
    padding-left: 60px !important;
}

.pl-15 {
    padding-left: 60px !important;
}

.pr-15 {
    padding-right: 60px !important;
}

.fill-width {
    width: 100% !important;
}

.fill-width-90p {
    width: 90% !important;
}

.fill-width-85p {
    width: 85% !important;
}

.fill-width-80p {
    width: 80% !important;
}

.fill-width-70p {
    width: 70% !important;
}

.fill-width-50p {
    width: 50% !important;
}

.fill-width-40p {
    width: 40% !important;
}

.fill-height-90p {
    height: 90% !important;
}

.display-none {
    display: none;
}

.item-avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.half-height {
    height: 50%;
}

.nowrap {
    white-space: nowrap;
}

.pre-wrap {
    white-space: pre-wrap;
}

.gradient-background {
    background-image: linear-gradient(to right, #055dbe, #1f92d0);
}

@media screen and (min-width: 1264px) {
    .absolute-header {
        position: absolute;
        left: 0;
        top: 0;
    }
}

.clickable {
    cursor: pointer;
}
.not-allowed {
    cursor: not-allowed;
}

.line-height-normal {
    line-height: 1;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}

@media screen and (max-width: 600px) {
    .landing-page-section-title {
        font-size: 2.5em;
    }
}

@media screen and (min-width: 601px) {
    .landing-page-section-title {
        font-size: 3em;
    }
}

.title-font {
    /*font-family: 'Baloo Paaji 2', serif;*/
}

.features-content {
    max-width: 900px;
}

.soft-edge {
    border-radius: 4px;
}

.register-form {
    padding-left: 20px;
    padding-right: 20px;
}

.health-survey-row {
    margin-top: 3px;
}

@media screen and (max-width: 960px) {
    .health-survey-row {
        padding-left: 10px !important;
    }
}

@media screen and (min-width: 961px) {
    .register-form-row {
        padding-left: 70px !important;
        padding-right: 70px !important;
    }

    .health-survey-row {
        padding-left: 100px !important;
        position: relative;
    }
}

.primary-border {
    border: 1px solid #1f93d0;
}

.primary-border-bold {
    border: 2px solid #1f93d0;
}

.invisible-border {
    border: 1px solid transparent;
}

.text-decoration-underline {
    text-decoration: underline;
}

.absolute {
    position: absolute;
}

.fixed {
    position: fixed;
}

.relative {
    position: relative;
}

.max-width-full {
    max-width: 100%;
}

.min-h-60vh {
    min-height: 60vh;
}

.visit-reservation-card {
    background-color: #eff4fa;
}

.disabled-item {
    opacity: .38;
}

.w-4 {
    width: 1rem;
}

.w-100 {
    width: 25em;
}


.w-200 {
    width: 50em;
}

.w-150 {
    width: 37.5em;
}

.w-80 {
    width: 20em;
}

.w-50 {
    width: 12.5em;
}

.w-70 {
    width: 17.5em;
}

.w-32 {
    width: 8em;
}

.max-w-150 {
    max-width: 37.5em;
}

.h-6 {
    height: 1.5em;
}

.h-30 {
    height: 7.5em;
}

.h-32 {
    height: 8em;
}

.h-40 {
    height: 10em;
}

.h-60 {
    height: 15em;
}

.explanation-gray {
    color: #9BA5B7;
}

.footer-text {
    color: #acb0c0 !important;
}

.hidden {
    visibility: hidden;
}

.selectable-card {
    background-color: white;
}

.selectable-card:hover {
    background-color: #E6F7FF;
    cursor: pointer;
}


.selectable-card:active {
    border: solid 1px #2399D5;
}

.selected-card {
    background-color: #E6F7FF;
    border: solid 1px #2399D5;
    cursor: pointer;
}

.disabled-card {
    border: solid 1px #DDE4F3;
}

.nt-blue-text {
    color: #2399D5;
}

.border-grey {
    border: solid 1px #77859A;
}

.widget-grey {
    color: #77859A;
}

.widget-dark-grey {
    color: #797979;
}

.widget-light-blue {
    color: #B2E6F5;
}

.widget-purple {
    color: #BCBBF0;
}

.widget-border-blue {
    border: solid 2px #2399D5;
}

.widget-bg-blue {
    background-color: #2399D5;
}

.h-full {
    height: 100% !important;
}

.min-h-full {
    min-height: 100% !important;
}

.opacity-40 {
    opacity: 0.4;
}

.visit-type-icon {
    padding: 5px;
    border-radius: 50%;
}

.visit-type-icon-stationary {
    background-color: #F29DDE;
}

.visit-type-icon-remote, .visit-type-icon-absent {
    background-color: #62E1E3;
}

.background-yellow {
    background-color: #F5E6B2;
}

.w-max-160 {
    max-width: 40rem;
}

.w-max-40 {
    max-width: 10rem;
}

.max-h-100 {
    max-height: 25rem;
}

.w-max-50p {
    max-width: 50%;
}

.widget-pink {
    background-color: #FFB8B8;
}

.top-dialog {
    align-self: flex-start;
}

</style>
