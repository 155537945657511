/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PayNowNotificationDto
 */
export interface PayNowNotificationDto {
    /**
     * 
     * @type {string}
     * @memberof PayNowNotificationDto
     */
    'externalId': string;
    /**
     * 
     * @type {string}
     * @memberof PayNowNotificationDto
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof PayNowNotificationDto
     */
    'paymentId': string;
    /**
     * 
     * @type {string}
     * @memberof PayNowNotificationDto
     */
    'status': PayNowNotificationDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PayNowNotificationDtoStatusEnum {
    New = 'NEW',
    Pending = 'PENDING',
    Confirmed = 'CONFIRMED',
    Rejected = 'REJECTED',
    Error = 'ERROR',
    Expired = 'EXPIRED',
    Abandoned = 'ABANDONED'
}


