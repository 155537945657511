/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DoctorPricelistItemDto } from './doctor-pricelist-item-dto';

/**
 * 
 * @export
 * @interface VisitChangeTypeResult
 */
export interface VisitChangeTypeResult {
    /**
     * 
     * @type {DoctorPricelistItemDto}
     * @memberof VisitChangeTypeResult
     */
    'doctorPricelistItem': DoctorPricelistItemDto;
    /**
     * 
     * @type {number}
     * @memberof VisitChangeTypeResult
     */
    'priceDifference': number;
    /**
     * 
     * @type {string}
     * @memberof VisitChangeTypeResult
     */
    'visitType': VisitChangeTypeResultVisitTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum VisitChangeTypeResultVisitTypeEnum {
    Remote = 'REMOTE',
    Stationary = 'STATIONARY',
    Absent = 'ABSENT'
}


