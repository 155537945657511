export default {
  "user": {
    "notFound": "Користувач не знайдений",
    "alreadyExists": "Користувач із вказаним логіном вже існує",
    "unauthorized": "Ви не можете виконати цю дію, оскільки у вас немає дозволу",
    "emailAlreadyExists": "Ця електронна адреса вже зареєстрована в системі",
    "cannotRemove": {
      "paymentsNotRefunded": "Акаунт неможливо видалити через неповернені платежі"
    }
  },
  "doctor": {
    "notFound": "Лікаря не знайшли",
    "priceList": {
      "notFound": "Прейскурант лікаря не знайдений",
      "wrongGrossPrice": "Вибрано неправильну ціну брутто",
      "notAvailable": "Вибрана послуга недоступна",
      "grossPriceCalculation": "Неправильний розрахунок ціни відвідування",
      "durationOutOfSync": "Тривалість надання послуг обраного типу має бути однаковою"
    },
    "medicalService": {
      "notFound": "Медичної служби не знайдено"
    },
    "forms": {
      "nfzContract": {
        "numberDuplicated": "Лікар із зазначеним номером контракту NFZ вже існує"
      }
    },
    "certificate": {
      "numberInvalid": "Niepoprawny numer certyfikatu lekarza"
    },
    "selection": {
      "request": {
        "alreadyPending": "Запит на зміну провідного лікаря вже надіслано і чекає відповіді лікаря",
        "incorrectStatus": "Прохання про зміну провідного лікаря має неправильний статус",
        "incorrectState": "Прохання про зміну провідного лікаря знаходиться в неправильному стані"
      }
    },
    "missingCertificate": "Сертифікат ZUS відсутній"
  },
  "patient": {
    "notFound": "Пацієнта не знайдено",
    "mood": {
      "future": "Ви не можете додати настрою з майбутнього"
    }
  },
  "position": {
    "notFound": "Позиції не знайдено"
  },
  "visit": {
    "notFound": "Відвідування не знайдено",
    "notDone": "Відвідування не здійснено",
    "notPaid": "Відвідування не оплачується",
    "edit": {
      "notAllowed": "Відвідати редагування заборонено"
    },
    "time": {
      "booked": "Вибрано дату з минулого"
    },
    "appointment": {
      "typeInvalid": "Недійсний тип зустрічі"
    },
    "recommendations": {
      "empty": "Рекомендації слід заповнити до закінчення візиту"
    },
    "processing": {
      "error": "Під час обробки відвідування сталася помилка"
    },
    "summary": {
      "pdf": "Під час створення PDF-документа для відвідування сталася помилка"
    },
    "message": {
      "notInitialized": "Розмова ще не розпочато пацієнтом",
      "limitExceeded": "Обмеження кількості повідомлень для одного відвідування вичерпано",
      "notResponded": "На ваше останнє повідомлення ще не відповіли"
    },
    "invoice": {
      "pdf": "Сталася помилка під час створення рахунку -фактури pdf для відвідування"
    },
    "afterVisitZeroAvailable": "Функціональні можливості недоступні до першого відвідування",
    "alreadyReported": "Про відвідування вже повідомлено P1, і він більше не може бути модифікатором",
    "paymentMarkingNotAllowed": "Цей візит не можна відзначити, оскільки ціна зависока",
    "changeDisabled": "Дату або лікаря для цього прийому вже змінено. Система не допускає іншої зміни. Якщо у вас виникли сумніви, зв'яжіться з нами."
  },
  "payment": {
    "receipt": {
      "pdf": "Під час створення квитанції про оплату сталася помилка"
    },
    "creation": "Під час збереження способу оплати сталася помилка",
    "visit": {
      "alreadyDone": "Відвідування вже оплачено",
      "stillProcessing": "Відвідування все ще обробляється",
      "unknownState": "Відвідайте помилку оплати, будь ласка, зв’яжіться з командою Neuroterminal"
    },
    "genericError": "Під час обробки платежу сталася помилка",
    "alreadyDone": "Оплата вже завершена"
  },
  "date": {
    "selected": {
      "fromPast": "Час відвідування заброньований"
    },
    "range": {
      "collision": "Вибрані діапазони дат конфліктують між собою"
    }
  },
  "consultation": {
    "notFound": "Консультацію не знайдено"
  },
  "nip": {
    "invalid": "Недійсний NIP"
  },
  "medicalEvent": {
    "notFound": "Подія не знайдена",
    "invalidData": "Недійсні дані про подію"
  },
  "medicalRequest": {
    "notFound": "Запит не знайдено",
    "invalidData": "Невірний запит",
    "alreadyAssigned": "Запит уже призначено"
  },
  "medicalResult": {
    "invalidData": "Недійсний результат",
    "notFound": "Результат не знайдено",
    "description": {
      "notFound": "Опис результату не знайдено"
    },
    "file": {
      "invalidData": "Недійсні дані файлу результатів"
    }
  },
  "medicalDocument": {
    "addError": "Під час збереження документа сталася помилка",
    "editError": "Під час редагування документа сталася помилка",
    "downloadError": "Під час завантаження файлу сталася помилка",
    "deleteError": "Під час видалення документа сталася помилка",
    "file": {
      "saveError": "Під час збереження файлу сталася помилка"
    }
  },
  "drugStock": {
    "negativeAmount": "Сума не може бути менше 0",
    "notUnique": "Ви не можете вибрати один і той же продукт багато разів"
  },
  "product": {
    "notFound": "Товар не знайдено",
    "notUnique": "Ви не можете обирати один і той же продукт багато разів"
  },
  "patientSeizuresHistory": {
    "futureDateSelected": "Вибрано дату майбутнього",
    "periodConflict": "Вибрані періоди конфліктують між собою"
  },
  "healthSurvey": {
    "alreadyInitialized": "Ваша історія хвороби вже ініціалізована",
    "patientHasNotEpilepsy": "Лише пацієнти з епілепсією мають доступ до опитування 0"
  },
  "form": {
    "invalidEmail": "Невірна адреса електронної пошти",
    "invalidPhone": "Недійсний номер телефону"
  },
  "passwordReset": {
    "tokenIncorrect": "Надіслане посилання неправильне",
    "internalError": "Помилка під час обробки запиту на зміну пароля",
    "unsecure": "Пароль повинен містити щонайменше 10 символів, великі та малі літери, цифри та спеціальні символи"
  },
  "email": {
    "sendError": "Сталася помилка надсилання електронної пошти"
  },
  "sms": {
    "noPhone": "Невідомий номер телефону для вибраного користувача"
  },
  "videochat": {
    "getTokenFailed": "Сталася помилка підключення",
    "sessionExpired": "Час відвідування минув. Неможливо встановити зв’язок."
  },
  "doctorNote": {
    "notFound": "Nie odnaleziono notatki"
  },
  "unexpected": "Сталася несподівана помилка. Спробуйте ще раз пізніше",
  "patientSeizureCharacteristics": {
    "notFound": "Історія нападів пацієнта не була повністю завершена"
  },
  "referral": {
    "notFound": "Направлення не знайдено",
    "cancelFree": "Неможливо скасувати направлення на безкоштовне відвідування"
  },
  "harmonogram": {
    "notFound": "Вибрану дату відвідування вже заброньовано"
  },
  "incompleteData": "Дані неповні",
  "cered": {
    "prescription": {
      "creation": "Під час видачі рецепта сталася помилка",
      "psychotropicNotUnique": "Рецепт не може містити комбінацію психотропних препаратів з іншими ліками",
      "prescriptionTypeNotUnique": "Рецепт не може містити комбінацію препаратів RP i RPW",
      "prescriptionFileNotAvailable": "Роздруківка рецепта недоступна"
    },
    "ewus": {
      "login": "Під час входу в EWUŚ сталася помилка",
      "check": "Сталася помилка під час перевірки страхування пацієнтів у EWUŚ"
    },
    "ereferral": {
      "creation": "Під час оформлення електронного направлення сталася помилка"
    },
    "zus": {
      "connect": "Під час входу в ZUS сталася помилка",
      "cannotCancel": "Цей листок непрацездатності анулювати не можна",
      "cannotFindWorkPlace": "Не можу знайти робоче місце NEUROSPHERA в обліковому записі ZUS"
    }
  },
  "icd10": {
    "notUnique": "Ви не можете вибрати один і той же icd10 багато разів"
  },
  "visitPackage": {
    "dateCollision": "Пакетні візити не можуть бути призначені на один день",
    "invalidService": "Деякі з вибраних послуг недоступні в цьому пакеті",
    "moveDateCollision": "Нова зустріч збігається з іншою зустріччю в пакеті",
    "someVisitsDone": "Відвідування з пакета, який уже розпочато, не можна скасувати",
    "dateNotEqual": "Деякі візити мають відбутися того ж дня",
    "timeCollision": "Вибрані дати візитів суперечать одна одній",
    "wrongDuration": "Недійсна тривалість відвідування",
    "notActive": "Пакет візитів недоступний",
    "differentDoctor": "Деякі візити повинні проходити з одним і тим же лікарем",
    "differentPlace": "Деякі відвідування мають бути в тому самому місці (або онлайн)"
  },
  "request": {
    "blocked": {
      "reset": "Неможливо надіслати посилання для зміни пароля, перш ніж пройде {n} год з моменту останнього запиту на зміну пароля. Зверніться до служби підтримки клієнтів.",
      "registration": "Неможливо надіслати посилання для зміни пароля, перш ніж пройде {n} год з моменту останнього запиту на зміну пароля. Зверніться до служби підтримки клієнтів.",
      "notFound": "Не вдається розблокувати скидання пароля, оскільки попередній запит не знайдено.",
      "notActive": "Пакет візитів недоступний"
    }
  },
  "slot": {
    "stepNotAllowed": "Можливість вибору тривалості візиту медична служба не визначає",
    "timeNotInRange": "Вибрана тривалість відвідування не входить у можливий діапазон",
    "timeIndivisibleByStep": "Вибрана тривалість відвідування не ділиться на визначений крок"
  },
  "docplanner": {
    "issue": {
      "alreadyResolved": "Проблему вже позначено як вирішену",
      "actionNotImplemented": "Немає автоматичної дії, підготовленої для такого типу проблеми",
      "notFound": "Проблема не знайдена"
    }
  },
  "hashtag": {
    "alreadyExists": "Хештег із вказаною назвою вже існує"
  },
  "generic": {
    "alreadyExists": "Об'єкт із заданим ідентифікатором уже існує"
  },
  "partner": {
    "notMatch": "Не знайдено відповідної служби"
  }
}