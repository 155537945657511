/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ParoxysmalDescription } from './paroxysmal-description';

/**
 * 
 * @export
 * @interface EegParoxysmal
 */
export interface EegParoxysmal {
    /**
     * 
     * @type {ParoxysmalDescription}
     * @memberof EegParoxysmal
     */
    'descriptions'?: ParoxysmalDescription;
    /**
     * 
     * @type {Array<string>}
     * @memberof EegParoxysmal
     */
    'main'?: Array<EegParoxysmalMainEnum>;
    /**
     * 
     * @type {string}
     * @memberof EegParoxysmal
     */
    'otherAutomatism'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegParoxysmal
     */
    'otherAutonomic'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegParoxysmal
     */
    'otherMotor'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegParoxysmal
     */
    'otherSensory'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EegParoxysmal
     */
    'sub'?: Array<EegParoxysmalSubEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum EegParoxysmalMainEnum {
    None = 'NONE',
    Sensory = 'SENSORY',
    Emotional = 'EMOTIONAL',
    Cognitive = 'COGNITIVE',
    SimpleMotor = 'SIMPLE_MOTOR',
    Automatism = 'AUTOMATISM',
    Hypermotor = 'HYPERMOTOR',
    Autonomic = 'AUTONOMIC',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum EegParoxysmalSubEnum {
    Visual = 'VISUAL',
    Auditory = 'AUDITORY',
    Olfactory = 'OLFACTORY',
    Taste = 'TASTE',
    Somatosensory = 'SOMATOSENSORY',
    Depersonalization = 'DEPERSONALIZATION',
    Vestibular = 'VESTIBULAR',
    CompulsiveThinking = 'COMPULSIVE_THINKING',
    BodyPerceptionIllusions = 'BODY_PERCEPTION_ILLUSIONS',
    OtherSensory = 'OTHER_SENSORY',
    Anxiety = 'ANXIETY',
    Fear = 'FEAR',
    Ecstasy = 'ECSTASY',
    Sadness = 'SADNESS',
    Anger = 'ANGER',
    Aphasia = 'APHASIA',
    Amnesia = 'AMNESIA',
    DejaVu = 'DEJA_VU',
    Akinetic = 'AKINETIC',
    Atonic = 'ATONIC',
    Dystonic = 'DYSTONIC',
    Nystagmus = 'NYSTAGMUS',
    Blinking = 'BLINKING',
    Flexion = 'FLEXION',
    Verse = 'VERSE',
    Myoclonic = 'MYOCLONIC',
    Clonic = 'CLONIC',
    JacksonMarch = 'JACKSON_MARCH',
    Tonic = 'TONIC',
    TonicClonic = 'TONIC_CLONIC',
    OtherMotor = 'OTHER_MOTOR',
    Oroalimentary = 'OROALIMENTARY',
    Verbal = 'VERBAL',
    Dacrist = 'DACRIST',
    Gelastic = 'GELASTIC',
    Manual = 'MANUAL',
    Gesture = 'GESTURE',
    OtherAutomatism = 'OTHER_AUTOMATISM',
    Bradycardia = 'BRADYCARDIA',
    Tachycardia = 'TACHYCARDIA',
    SkinReddening = 'SKIN_REDDENING',
    SkinPaleness = 'SKIN_PALENESS',
    Piloerection = 'PILOERECTION',
    Epigastric = 'EPIGASTRIC',
    Spitting = 'SPITTING',
    Vomiting = 'VOMITING',
    Apnea = 'APNEA',
    UrinaryIncontinence = 'URINARY_INCONTINENCE',
    OtherAutonomic = 'OTHER_AUTONOMIC'
}


