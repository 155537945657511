export default {
  "AD": {
    "code": "AD",
    "name": "Andorra"
  },
  "AE": {
    "code": "AE",
    "name": "Vereinigte Arabische Emirate"
  },
  "AF": {
    "code": "AF",
    "name": "Afghanistan"
  },
  "AG": {
    "code": "AG",
    "name": "Antigua und Barbuda"
  },
  "AI": {
    "code": "AI",
    "name": "Anguilla"
  },
  "AL": {
    "code": "AL",
    "name": "Albanien"
  },
  "AM": {
    "code": "AM",
    "name": "Armenien"
  },
  "AO": {
    "code": "AO",
    "name": "Angola"
  },
  "AQ": {
    "code": "AQ",
    "name": "Antarktis"
  },
  "AR": {
    "code": "AR",
    "name": "Argentinien"
  },
  "AS": {
    "code": "AS",
    "name": "Amerikanischen Samoa-Inseln"
  },
  "AT": {
    "code": "AT",
    "name": "Österreich"
  },
  "AU": {
    "code": "AU",
    "name": "Australien"
  },
  "AW": {
    "code": "AW",
    "name": "Aruba"
  },
  "AX": {
    "code": "AX",
    "name": "?Landinseln"
  },
  "AZ": {
    "code": "AZ",
    "name": "Aserbaidschan"
  },
  "BA": {
    "code": "BA",
    "name": "Bosnien und Herzegowina"
  },
  "BB": {
    "code": "BB",
    "name": "Barbados"
  },
  "BD": {
    "code": "BD",
    "name": "Bangladesch"
  },
  "BE": {
    "code": "BE",
    "name": "Belgien"
  },
  "BF": {
    "code": "BF",
    "name": "Burkina Faso"
  },
  "BG": {
    "code": "BG",
    "name": "Bulgarien"
  },
  "BH": {
    "code": "BH",
    "name": "Bahrain"
  },
  "BI": {
    "code": "BI",
    "name": "Burundi"
  },
  "BJ": {
    "code": "BJ",
    "name": "Benin"
  },
  "BL": {
    "code": "BL",
    "name": "St. Barthélemy"
  },
  "BM": {
    "code": "BM",
    "name": "Bermuda"
  },
  "BN": {
    "code": "BN",
    "name": "Brunei Darussalam"
  },
  "BO": {
    "code": "BO",
    "name": "Bolivien, plurinationaler Staat"
  },
  "BQ": {
    "code": "BQ",
    "name": "Bonaire, Sint Eustatius und Saba"
  },
  "BR": {
    "code": "BR",
    "name": "Brasilien"
  },
  "BS": {
    "code": "BS",
    "name": "Bahamas"
  },
  "BT": {
    "code": "BT",
    "name": "Bhutan"
  },
  "BV": {
    "code": "BV",
    "name": "Bouvetinsel"
  },
  "BW": {
    "code": "BW",
    "name": "Botswana"
  },
  "BY": {
    "code": "BY",
    "name": "Weißrussland"
  },
  "BZ": {
    "code": "BZ",
    "name": "Belize"
  },
  "CA": {
    "code": "CA",
    "name": "Kanada"
  },
  "CC": {
    "code": "CC",
    "name": "Kokosinseln (Keelinginseln)."
  },
  "CD": {
    "code": "CD",
    "name": "Kongo, Demokratische Republik"
  },
  "CF": {
    "code": "CF",
    "name": "Zentralafrikanische Republik"
  },
  "CG": {
    "code": "CG",
    "name": "Kongo"
  },
  "CH": {
    "code": "CH",
    "name": "Schweiz"
  },
  "CI": {
    "code": "CI",
    "name": "Elfenbeinküste"
  },
  "CK": {
    "code": "CK",
    "name": "Cookinseln"
  },
  "CL": {
    "code": "CL",
    "name": "Chile"
  },
  "CM": {
    "code": "CM",
    "name": "Kamerun"
  },
  "CN": {
    "code": "CN",
    "name": "China"
  },
  "CO": {
    "code": "CO",
    "name": "Kolumbien"
  },
  "CR": {
    "code": "CR",
    "name": "Costa Rica"
  },
  "CU": {
    "code": "CU",
    "name": "Kuba"
  },
  "CV": {
    "code": "CV",
    "name": "Kap Verde"
  },
  "CW": {
    "code": "CW",
    "name": "Curacao"
  },
  "CX": {
    "code": "CX",
    "name": "Weihnachtsinsel"
  },
  "CY": {
    "code": "CY",
    "name": "Zypern"
  },
  "CZ": {
    "code": "CZ",
    "name": "Tschechien"
  },
  "DE": {
    "code": "DE",
    "name": "Deutschland"
  },
  "DJ": {
    "code": "DJ",
    "name": "Dschibuti"
  },
  "DK": {
    "code": "DK",
    "name": "Dänemark"
  },
  "DM": {
    "code": "DM",
    "name": "Dominica"
  },
  "DO": {
    "code": "DO",
    "name": "Dominikanische Republik"
  },
  "DZ": {
    "code": "DZ",
    "name": "Algerien"
  },
  "EC": {
    "code": "EC",
    "name": "Ecuador"
  },
  "EE": {
    "code": "EE",
    "name": "Estland"
  },
  "EG": {
    "code": "EG",
    "name": "Ägypten"
  },
  "EH": {
    "code": "EH",
    "name": "Westsahara"
  },
  "ER": {
    "code": "ER",
    "name": "Eritrea"
  },
  "ES": {
    "code": "ES",
    "name": "Spanien"
  },
  "ET": {
    "code": "ET",
    "name": "Äthiopien"
  },
  "FI": {
    "code": "FI",
    "name": "Finnland"
  },
  "FJ": {
    "code": "FJ",
    "name": "Fidschi"
  },
  "FK": {
    "code": "FK",
    "name": "Falklandinseln (Malvinas)"
  },
  "FM": {
    "code": "FM",
    "name": "Mikronesien, Föderierte Staaten"
  },
  "FO": {
    "code": "FO",
    "name": "Färöer Inseln"
  },
  "FR": {
    "code": "FR",
    "name": "Frankreich"
  },
  "GA": {
    "code": "GA",
    "name": "Gabun"
  },
  "GB": {
    "code": "GB",
    "name": "Großbritannien"
  },
  "GD": {
    "code": "GD",
    "name": "Grenada"
  },
  "GE": {
    "code": "GE",
    "name": "Georgia"
  },
  "GF": {
    "code": "GF",
    "name": "Französisch-Guayana"
  },
  "GG": {
    "code": "GG",
    "name": "Guernsey"
  },
  "GH": {
    "code": "GH",
    "name": "Ghana"
  },
  "GI": {
    "code": "GI",
    "name": "Gibraltar"
  },
  "GL": {
    "code": "GL",
    "name": "Grönland"
  },
  "GM": {
    "code": "GM",
    "name": "Gambia"
  },
  "GN": {
    "code": "GN",
    "name": "Guinea"
  },
  "GP": {
    "code": "GP",
    "name": "Guadeloupe"
  },
  "GQ": {
    "code": "GQ",
    "name": "Äquatorialguinea"
  },
  "GR": {
    "code": "GR",
    "name": "Griechenland"
  },
  "GS": {
    "code": "GS",
    "name": "Süd-Georgien und die südlichen Sandwich-Inseln"
  },
  "GT": {
    "code": "GT",
    "name": "Guatemala"
  },
  "GU": {
    "code": "GU",
    "name": "Guam"
  },
  "GW": {
    "code": "GW",
    "name": "Guinea-Bissau"
  },
  "GY": {
    "code": "GY",
    "name": "Guyana"
  },
  "HK": {
    "code": "HK",
    "name": "Hongkong"
  },
  "HM": {
    "code": "HM",
    "name": "Heard-Insel und McDonald-Inseln"
  },
  "HN": {
    "code": "HN",
    "name": "Honduras"
  },
  "HR": {
    "code": "HR",
    "name": "Kroatien"
  },
  "HT": {
    "code": "HT",
    "name": "Haiti"
  },
  "HU": {
    "code": "HU",
    "name": "Ungarn"
  },
  "ID": {
    "code": "ID",
    "name": "Indonesien"
  },
  "IE": {
    "code": "IE",
    "name": "Irland"
  },
  "IL": {
    "code": "IL",
    "name": "Israel"
  },
  "IM": {
    "code": "IM",
    "name": "Isle of Man"
  },
  "IN": {
    "code": "IN",
    "name": "Indien"
  },
  "IO": {
    "code": "IO",
    "name": "Britisches Territorium des Indischen Ozeans"
  },
  "IQ": {
    "code": "IQ",
    "name": "Irak"
  },
  "IR": {
    "code": "IR",
    "name": "Iran, Islamische Republik"
  },
  "IS": {
    "code": "IS",
    "name": "Island"
  },
  "IT": {
    "code": "IT",
    "name": "Italien"
  },
  "JE": {
    "code": "JE",
    "name": "Jersey"
  },
  "JM": {
    "code": "JM",
    "name": "Jamaika"
  },
  "JO": {
    "code": "JO",
    "name": "Jordanien"
  },
  "JP": {
    "code": "JP",
    "name": "Japan"
  },
  "KE": {
    "code": "KE",
    "name": "Kenia"
  },
  "KG": {
    "code": "KG",
    "name": "Kirgisistan"
  },
  "KH": {
    "code": "KH",
    "name": "Kambodscha"
  },
  "KI": {
    "code": "KI",
    "name": "Kiribati"
  },
  "KM": {
    "code": "KM",
    "name": "Komoren"
  },
  "KN": {
    "code": "KN",
    "name": "St. Kitts und Nevis"
  },
  "KP": {
    "code": "KP",
    "name": "Korea, Demokratische Volksrepublik"
  },
  "KR": {
    "code": "KR",
    "name": "Korea, Republik von"
  },
  "KW": {
    "code": "KW",
    "name": "Kuwait"
  },
  "KY": {
    "code": "KY",
    "name": "Cayman Inseln"
  },
  "KZ": {
    "code": "KZ",
    "name": "Kasachstan"
  },
  "LA": {
    "code": "LA",
    "name": "Demokratische Volksrepublik Laos"
  },
  "LB": {
    "code": "LB",
    "name": "Libanon"
  },
  "LC": {
    "code": "LC",
    "name": "St. Lucia"
  },
  "LI": {
    "code": "LI",
    "name": "Liechtenstein"
  },
  "LK": {
    "code": "LK",
    "name": "Sri Lanka"
  },
  "LR": {
    "code": "LR",
    "name": "Liberia"
  },
  "LS": {
    "code": "LS",
    "name": "Lesotho"
  },
  "LT": {
    "code": "LT",
    "name": "Litauen"
  },
  "LU": {
    "code": "LU",
    "name": "Luxemburg"
  },
  "LV": {
    "code": "LV",
    "name": "Lettland"
  },
  "LY": {
    "code": "LY",
    "name": "Libyen"
  },
  "MA": {
    "code": "MA",
    "name": "Marokko"
  },
  "MC": {
    "code": "MC",
    "name": "Monaco"
  },
  "MD": {
    "code": "MD",
    "name": "Moldawien, Republik"
  },
  "ME": {
    "code": "ME",
    "name": "Montenegro"
  },
  "MF": {
    "code": "MF",
    "name": "Saint Martin (französischer Teil)"
  },
  "MG": {
    "code": "MG",
    "name": "Madagaskar"
  },
  "MH": {
    "code": "MH",
    "name": "Marshallinseln"
  },
  "MK": {
    "code": "MK",
    "name": "Mazedonien, ehemalige jugoslawische Republik"
  },
  "ML": {
    "code": "ML",
    "name": "Mali"
  },
  "MM": {
    "code": "MM",
    "name": "Myanmar"
  },
  "MN": {
    "code": "MN",
    "name": "Mongolei"
  },
  "MO": {
    "code": "MO",
    "name": "Macau"
  },
  "MP": {
    "code": "MP",
    "name": "Nördliche Marianneninseln"
  },
  "MQ": {
    "code": "MQ",
    "name": "Martinique"
  },
  "MR": {
    "code": "MR",
    "name": "Mauretanien"
  },
  "MS": {
    "code": "MS",
    "name": "Montserrat"
  },
  "MT": {
    "code": "MT",
    "name": "Malta"
  },
  "MU": {
    "code": "MU",
    "name": "Mauritius"
  },
  "MV": {
    "code": "MV",
    "name": "Malediven"
  },
  "MW": {
    "code": "MW",
    "name": "Malawi"
  },
  "MX": {
    "code": "MX",
    "name": "Mexiko"
  },
  "MY": {
    "code": "MY",
    "name": "Malaysia"
  },
  "MZ": {
    "code": "MZ",
    "name": "Mosambik"
  },
  "NA": {
    "code": "NA",
    "name": "Namibia"
  },
  "NC": {
    "code": "NC",
    "name": "Neu-Kaledonien"
  },
  "NE": {
    "code": "NE",
    "name": "Niger"
  },
  "NF": {
    "code": "NF",
    "name": "Norfolkinsel"
  },
  "NG": {
    "code": "NG",
    "name": "Nigeria"
  },
  "NI": {
    "code": "NI",
    "name": "Nicaragua"
  },
  "NL": {
    "code": "NL",
    "name": "Niederlande"
  },
  "NO": {
    "code": "NO",
    "name": "Norwegen"
  },
  "NP": {
    "code": "NP",
    "name": "Nepal"
  },
  "NR": {
    "code": "NR",
    "name": "Nauru"
  },
  "NU": {
    "code": "NU",
    "name": "Niue"
  },
  "NZ": {
    "code": "NZ",
    "name": "Neuseeland"
  },
  "OM": {
    "code": "OM",
    "name": "Oman"
  },
  "PA": {
    "code": "PA",
    "name": "Panama"
  },
  "PE": {
    "code": "PE",
    "name": "Peru"
  },
  "PF": {
    "code": "PF",
    "name": "Französisch Polynesien"
  },
  "PG": {
    "code": "PG",
    "name": "Papua Neu-Guinea"
  },
  "PH": {
    "code": "PH",
    "name": "Philippinen"
  },
  "PK": {
    "code": "PK",
    "name": "Pakistan"
  },
  "PL": {
    "code": "PL",
    "name": "Polen"
  },
  "PM": {
    "code": "PM",
    "name": "Saint-Pierre und Miquelon"
  },
  "PN": {
    "code": "PN",
    "name": "Pitcairn"
  },
  "PR": {
    "code": "PR",
    "name": "Puerto Rico"
  },
  "PS": {
    "code": "PS",
    "name": "Palästina, Bundesstaat"
  },
  "PT": {
    "code": "PT",
    "name": "Portugal"
  },
  "PW": {
    "code": "PW",
    "name": "Palau"
  },
  "PY": {
    "code": "PY",
    "name": "Paraguay"
  },
  "QA": {
    "code": "QA",
    "name": "Katar"
  },
  "RE": {
    "code": "RE",
    "name": "Wiedervereinigung"
  },
  "RO": {
    "code": "RO",
    "name": "Rumänien"
  },
  "RS": {
    "code": "RS",
    "name": "Serbien"
  },
  "RU": {
    "code": "RU",
    "name": "Russische Föderation"
  },
  "RW": {
    "code": "RW",
    "name": "Ruanda"
  },
  "SA": {
    "code": "SA",
    "name": "Saudi-Arabien"
  },
  "SB": {
    "code": "SB",
    "name": "Salomon-Inseln"
  },
  "SC": {
    "code": "SC",
    "name": "Seychellen"
  },
  "SD": {
    "code": "SD",
    "name": "Sudan"
  },
  "SE": {
    "code": "SE",
    "name": "Schweden"
  },
  "SG": {
    "code": "SG",
    "name": "Singapur"
  },
  "SH": {
    "code": "SH",
    "name": "Heilige Helena, Himmelfahrt und Tristan da Cunha"
  },
  "SI": {
    "code": "SI",
    "name": "Slowenien"
  },
  "SJ": {
    "code": "SJ",
    "name": "Spitzbergen und Jan Mayen"
  },
  "SK": {
    "code": "SK",
    "name": "Slowakei"
  },
  "SL": {
    "code": "SL",
    "name": "Sierra Leone"
  },
  "SM": {
    "code": "SM",
    "name": "San Marino"
  },
  "SN": {
    "code": "SN",
    "name": "Senegal"
  },
  "SO": {
    "code": "SO",
    "name": "Somalia"
  },
  "SR": {
    "code": "SR",
    "name": "Surinam"
  },
  "SS": {
    "code": "SS",
    "name": "Südsudan"
  },
  "ST": {
    "code": "ST",
    "name": "São Tomé und Príncipe"
  },
  "SV": {
    "code": "SV",
    "name": "El Salvador"
  },
  "SX": {
    "code": "SX",
    "name": "Sint Maarten (niederländischer Teil)"
  },
  "SY": {
    "code": "SY",
    "name": "Syrische Arabische Republik"
  },
  "SZ": {
    "code": "SZ",
    "name": "Swasiland"
  },
  "TC": {
    "code": "TC",
    "name": "Turks- und Caicosinseln"
  },
  "TD": {
    "code": "TD",
    "name": "Tschad"
  },
  "TF": {
    "code": "TF",
    "name": "Südfranzösische Territorien"
  },
  "TG": {
    "code": "TG",
    "name": "Gehen"
  },
  "TH": {
    "code": "TH",
    "name": "Thailand"
  },
  "TJ": {
    "code": "TJ",
    "name": "Tadschikistan"
  },
  "TK": {
    "code": "TK",
    "name": "Tokelau"
  },
  "TL": {
    "code": "TL",
    "name": "Timor-Leste"
  },
  "TM": {
    "code": "TM",
    "name": "Turkmenistan"
  },
  "TN": {
    "code": "TN",
    "name": "Tunesien"
  },
  "TO": {
    "code": "TO",
    "name": "Tonga"
  },
  "TR": {
    "code": "TR",
    "name": "Truthahn"
  },
  "TT": {
    "code": "TT",
    "name": "Trinidad und Tobago"
  },
  "TV": {
    "code": "TV",
    "name": "Tuvalu"
  },
  "TW": {
    "code": "TW",
    "name": "Taiwan, Provinz Chinas"
  },
  "TZ": {
    "code": "TZ",
    "name": "Tansania, Vereinigte Republik"
  },
  "UA": {
    "code": "UA",
    "name": "Ukraine"
  },
  "UG": {
    "code": "UG",
    "name": "Uganda"
  },
  "UM": {
    "code": "UM",
    "name": "Kleinere vorgelagerte Inseln der Vereinigten Staaten"
  },
  "US": {
    "code": "US",
    "name": "Vereinigte Staaten"
  },
  "UY": {
    "code": "UY",
    "name": "Uruguay"
  },
  "UZ": {
    "code": "UZ",
    "name": "Usbekistan"
  },
  "VA": {
    "code": "VA",
    "name": "Heiliger Stuhl (Vatikanstadtstaat)"
  },
  "VC": {
    "code": "VC",
    "name": "St. Vincent und die Grenadinen"
  },
  "VE": {
    "code": "VE",
    "name": "Venezuela, Bolivarische Republik"
  },
  "VG": {
    "code": "VG",
    "name": "Virgin Inseln, Britisch"
  },
  "VI": {
    "code": "VI",
    "name": "Jungferninseln, USA"
  },
  "VN": {
    "code": "VN",
    "name": "Vietnam"
  },
  "VU": {
    "code": "VU",
    "name": "Vanuatu"
  },
  "WF": {
    "code": "WF",
    "name": "Wallis und Futuna"
  },
  "WS": {
    "code": "WS",
    "name": "Samoa"
  },
  "YE": {
    "code": "YE",
    "name": "Jemen"
  },
  "YT": {
    "code": "YT",
    "name": "Mayotte"
  },
  "ZA": {
    "code": "ZA",
    "name": "Südafrika"
  },
  "ZM": {
    "code": "ZM",
    "name": "Sambia"
  },
  "ZW": {
    "code": "ZW",
    "name": "Zimbabwe"
  }
}