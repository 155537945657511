/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HarmonogramPartDto } from './harmonogram-part-dto';
import { LocalTime } from './local-time';

/**
 * 
 * @export
 * @interface HarmonogramDto
 */
export interface HarmonogramDto {
    /**
     * 
     * @type {string}
     * @memberof HarmonogramDto
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof HarmonogramDto
     */
    'doctorId'?: number;
    /**
     * 
     * @type {Array<HarmonogramPartDto>}
     * @memberof HarmonogramDto
     */
    'harmonogramParts'?: Array<HarmonogramPartDto>;
    /**
     * 
     * @type {number}
     * @memberof HarmonogramDto
     */
    'id'?: number;
    /**
     * 
     * @type {LocalTime}
     * @memberof HarmonogramDto
     */
    'timeFrom'?: LocalTime;
    /**
     * 
     * @type {LocalTime}
     * @memberof HarmonogramDto
     */
    'timeTo'?: LocalTime;
    /**
     * 
     * @type {string}
     * @memberof HarmonogramDto
     */
    'visitType'?: HarmonogramDtoVisitTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof HarmonogramDto
     */
    'workPlaceId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum HarmonogramDtoVisitTypeEnum {
    Remote = 'REMOTE',
    Stationary = 'STATIONARY',
    Absent = 'ABSENT'
}


