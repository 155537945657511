import Enums from "./enums/enums";
import ServerErrors from "./errors/server-errors";

export default {
  "translations": {
    "$vuetify": {
      "noDataText": "Pas de données",
      "dataFooter": {
        "itemsPerPageAll": "Afficher tout",
        "pageText": "Naviguer:"
      },
      "dataTable": {
        "sortBy": "Trier par",
        "ariaLabel": {
          "sortNone": "trierAucun"
        },
        "itemsPerPageText": "Par page:"
      },
      "timepicker": {
        "am": "un m",
        "pm": "après-midi"
      },
      "dataIterator": {
        "loadingText": "Chargement des données"
      },
      "badge": "badge",
      "datePicker": {
        "prevMonthAriaLabel": "Le mois précédent",
        "nextMonthAriaLabel": "Le mois prochain"
      }
    },
    "view": {
      "admin": {
        "settings": {
          "ewusPasswordChanged": "Mot de passe EWUŚ changé",
          "save": "sauver",
          "ewusPasswordTitle": "Changement de mot de passe EWUŚ",
          "ewusPasswordLabel": "Nouveau mot de passe",
          "removeAccount": "Supprimer le compte",
          "registrationDate": "Date d'inscription",
          "registrationSource": "Enregistré par",
          "registeredBy": {
            "PATIENT": "Patient",
            "DOCPLANNER": "Znany lekarz",
            "APPOINTING_WIDGET": "Widget"
          },
          "deleteAccount": "Supprimer le compte",
          "accountNotForDeletion": "Ce compte ne peut pas être supprimé",
          "accountDeletionConfirmation": "Voulez-vous vraiment supprimer ce compte ?",
          "yes": "Oui",
          "no": "Non",
          "accountRemovedMsg": "Le compte du patient a été supprimé"
        },
        "notes": {
          "title": "Remarques",
          "current": "Courant",
          "archive": "Archiver",
          "mine": "Exploiter"
        },
        "noteDetails": {
          "added": "Temps de creation",
          "patient": "Patient",
          "pesel": "Pesel",
          "address": "Adresse",
          "phone": "Téléphoner",
          "content": "Contenu",
          "sharedBy": "Partagé par",
          "moveToArchive": "Déplacer vers l'archive",
          "save": "Sauvegarder",
          "reply": "Répondre",
          "initialNote": "note du médecin",
          "yes": "Oui",
          "no": "Non",
          "addedFiles": "Fichiers ajoutés"
        },
        "reassignment": {
          "noReplacementAvailable": "Pas de médecin alternatif au même prix",
          "backToVisitToMoveTable": "Retour à la liste des rendez-vous à remplacer",
          "visitNotFoundRedirection": "Nie znaleziono wizyty, powrócisz do strony głównej",
          "reassignmentSummary": "Résumé",
          "visitNotToMove": "La visite ne peut pas être déplacée car son statut a déjà été mis à jour.",
          "title": "Changement de docteur",
          "currentVisitDate": "Date de visite",
          "currentVisitHeader": "Changer pour la visite",
          "additionalInfo": "Information additionnelle",
          "upcomingVisit": "Visite du patient à venir",
          "patient": "Patient",
          "doctor": "Médecin",
          "currentVisitFromPackageHeader": "Changement pour visite à partir du forfait",
          "cannotReassignDueToRelatedVisit": "La visite ne peut être réaffectée en raison d'un lien avec une autre visite déjà effectuée"
        },
        "patients": {
          "selectPatient": "Sélectionner un patient",
          "name": "Nom",
          "email": "E-mail",
          "pesel": "Pesel"
        },
        "certificates": {
          "issuingDoctor": "Médecin délivrant un certificat",
          "changeLbl": "Changement",
          "selectDoctorLbl": "Sélectionnez un médecin",
          "hide": "Cacher"
        }
      },
      "visits": {
        "DoctorVisits": {
          "titleLbl": "Mes visites",
          "returnLbl": "revenir",
          "noDataLbl": "Aucune visite",
          "noResultsLbl": "Aucune visite trouvée",
          "filters": {
            "visitTime": {
              "all": "Tous",
              "future": "Avenir",
              "past": "Passé"
            },
            "searchPatientLbl": "Recherche de patient",
            "advancedFiltersLbl": "Recherche Avancée",
            "advancedFilters": {
              "from": "Dater de",
              "betweenDatesLbl": "faire",
              "till": "Date jusqu'à",
              "type": "Type de visite",
              "typeAll": "Tous",
              "clearBtn": "dégager",
              "searchBtn": "chercher"
            }
          },
          "table": {
            "headers": {
              "state": "État",
              "lastName": "Nom de famille",
              "firstName": "Prénom",
              "pesel": "PESEL",
              "age": "Âge",
              "visitType": "Type de visite",
              "visitCategory": "Catégorie",
              "visitStatus": "Statut",
              "dateFrom": "Date de visite",
              "sinceLast": "Depuis la dernière"
            },
            "actions": {
              "summary": "Résumé de la visite",
              "start": "Visite"
            }
          }
        }
      },
      "patients": {
        "DoctorPatients": {
          "titleLbl": "Les patients",
          "returnLbl": "revenir",
          "noDataLbl": "Aucun patient",
          "noResultsLbl": "Aucune visite trouvée",
          "filters": {
            "scope": {
              "all": "Tous les patients",
              "my": "Mes patients"
            },
            "search": "Chercher",
            "advancedFiltersLbl": "Recherche Avancée",
            "from": "de",
            "birthDate": "Date de naissance",
            "till": "à",
            "clearBtn": "dégager",
            "searchBtn": "chercher",
            "tabs": {
              "personal": "Données personnelles",
              "address": "Coordonnées",
              "biomedic": "Données biomédicales",
              "medic": "Données médicales"
            }
          },
          "table": {
            "headers": {
              "lastName": "Nom de famille",
              "firstName": "Prénom",
              "medicalHistory": "Antécédents médicaux",
              "pesel": "PESEL",
              "age": "Âge",
              "sex": "Sexe",
              "leadDoctor": "Médecin chef de file",
              "personalData": "Données personnelles",
              "email": "E-mail",
              "phone": "Numéro de téléphone",
              "city": "Ville",
              "zipCode": "Code postal",
              "country": "Pays",
              "bloodType": "Groupe sanguin",
              "rh": "Rh",
              "weight": "Poids",
              "height": "Hauteur",
              "hasEpilepsy": "Souffrez-vous d’épilepsie ?",
              "mainIcd10": "Diagnostic principal",
              "comorbidities": "Comorbidités",
              "vns": "VNS implanté",
              "activeSubstance": "Substance active",
              "product": "Médecine",
              "therapyType": "Type de thérapie"
            }
          }
        }
      },
      "visit": {
        "Visit": {
          "titleLbl": "Visite",
          "returnLbl": "revenir",
          "printLbl": "imprimer",
          "survey": "Sondage 0",
          "documentation": "Visiter la documentation",
          "visitDocumentation": "Documentation de visite",
          "calendar": "Calendrier des événements",
          "eDiagnosisBtn": "e-diagnostic",
          "eTherapyBtn": "e-thérapie",
          "medHistoryBtn": "antécédents médicaux",
          "messHistoryBtn": "historique des messages",
          "notesBtn": "Remarques",
          "observations": "observations",
          "diagnosis": "diagnostic (comorbidités)",
          "seizureDiagnosis": "diagnostic (convulsions)",
          "medication": "des médicaments",
          "recommendations": "recommandations",
          "prescriptionBtn": "ordonnance",
          "certificateBtn": "certificat",
          "referralBtn": "référence",
          "changeTherapyBtn": "changer la thérapie",
          "cancel": "Annuler",
          "save": "enregistrer",
          "end": "terminer",
          "visitLeaveConfirmMsg": "Êtes-vous sûr de vouloir quitter la visite ?",
          "visitSaveConfirmMsg": "Voulez-vous vraiment enregistrer et quitter la visite ?",
          "visitEndConfirmMsg": "Voulez-vous terminer la visite? Après y avoir mis fin, aucune autre modification ne sera possible",
          "dataSavedMsg": "Données de visite enregistrées",
          "visitEndedMsg": "La visite s'est terminée",
          "dataSaveErrorMsg": "Erreur lors de l'enregistrement des données de visite",
          "messageHistoryEmpty": "Il n'y a pas de messages avec ce patient",
          "drugsImported": "Les thérapies médicamenteuses ont été initialisées",
          "drugTherapiesInEditionMode": "Les thérapies médicamenteuses sont en mode édition. La visite ne peut pas être enregistrée.",
          "emptyRecommendations": "Les recommandations ne sont pas fournies. La visite ne peut pas être terminée.",
          "referrals": {
            "title": "références",
            "removeDialogTitle": "Êtes-vous sûr de vouloir supprimer cette référence ?",
            "addReferral": "Référence",
            "addDialogTitle": "Nouvelle référence",
            "noAvailableServices": "Il n'y a pas de services disponibles pour ce patient",
            "consultationService": "Type de consultation",
            "doctor": "Docteur",
            "description": "La description",
            "cancel": "Annuler",
            "empty": "Aucune référence émise pour cette visite",
            "scheduled1Month": "1 mois",
            "scheduled2Months": "2 mois",
            "scheduled3Months": "3 mois",
            "scheduled6Months": "6 mois",
            "scheduled1Year": "1 année",
            "scheduled1month": "1 mois",
            "scheduled2month": "2 mois",
            "scheduled3month": "3 mois",
            "scheduled6month": "6 mois",
            "scheduled12month": "1 année",
            "scheduledDate": "Date prévue",
            "availableFree": {
              "title": "Vous n'avez pas émis de références pour des visites gratuites",
              "subtitle": "Après cette visite, le patient a droit à 1 visite gratuite pour l'un des services",
              "subtitle2": "Revenez à votre plan de soins et émettez la référence appropriée !",
              "back": "Revenir à la visite pour émettre une référence",
              "endWithoutReferral": "Terminer sans référence",
              "visitDescription": "Après cette visite, le patient a droit à une visite gratuite dans les services suivants",
              "visitAddTitle": "référence pour une visite gratuite"
            },
            "doctorPackage": "Forfait médecin",
            "doctorPackageQuestion": "Orientation vers un forfait médecin ?"
          },
          "prescriptions": {
            "title": "ordonnance",
            "addPrescription": "Ordonnance",
            "empty": "Aucune ordonnance délivrée pour cette visite"
          },
          "certificates": {
            "title": "certificats",
            "addCertificate": "Certificat",
            "empty": "Aucun certificat délivré pour cette visite"
          },
          "ended": {
            "titleLbl": "La téléconférence est terminée",
            "questionMsg": "Souhaitez-vous terminer cette visite et revenir à l'écran principal ?",
            "confirmationMsg": "Merci d'avoir assisté à la visite"
          },
          "saving": {
            "warningMsg": "La visite sera enregistrée automatiquement dans {no} minutes",
            "autoSaveMsg": "La visite a été automatiquement enregistrée. Veuillez terminer la visite immédiatement"
          },
          "icd10": {
            "title": "diagnostic"
          },
          "leaveDialog": {
            "leave": "Partir sans enregistrer",
            "saveAndLeave": "Enregistrer et quitter",
            "endAndLeave": "Finir et partir"
          },
          "absentConsultationInfo": "La consultation absente a lieu sans la participation du patient",
          "wrongDataDialog": {
            "title": "Vos données ne peuvent pas être enregistrées pour le moment.",
            "description": "Veuillez contacter le bureau client du centre de traitement de l'épilepsie Neurosphera."
          },
          "additionalObservations": "Observations supplémentaires",
          "notMandatoryLbl": "champ non obligatoire",
          "observationsPlaceholder": "Les observations se remplissent automatiquement lors du remplissage de la section visite",
          "additionalObservationsPlaceholder": "Remplir des observations supplémentaires",
          "addObservationLbl": "Enregistrer les observations",
          "leadDoctorHeader": "Médecin responsable",
          "documentationTabs": {
            "visitSurvey": "Enquête de visite",
            "therapyArrangement": "Arrangement de thérapie",
            "healthCare": "Plan de santé",
            "recommendations": "Recommandations",
            "certificates": "Certificats",
            "appoint": "nommer",
            "skip": "ne nomme pas",
            "referralsSaved": "Les parrainages ont été enregistrés",
            "carePlan": {
              "addNext": "Ajouter suivant",
              "remove": "Retirer",
              "loading": "Chargement des données...",
              "referralListEmpty": "La liste de référence est vide",
              "epilepsy": {
                "name": "Visite d'épilepsie",
                "title": "prochaine visite épileptique - prise de rendez-vous",
                "date": "Date de visite"
              },
              "eeg": {
                "name": "Examen EEG",
                "title": "Examen EEG - prise de rendez-vous",
                "date": "Date de l'Eeg",
                "service": "Type d'Eeg"
              },
              "consultation": {
                "name": "Consultation",
                "title": "Consultation NSPH - prise de rendez-vous",
                "date": "Date de consultation",
                "service": "Type de consultations"
              },
              "other": {
                "name": "Autre",
                "title": "Références externes",
                "emptyList": "La liste des références externes émises pour cette visite est vide",
                "addNextEReferral": "Ajouter une référence électronique",
                "isEReferral": "E-référence",
                "referralCode": "Code de Parrainage",
                "noDataLbl": "La liste de référence est vide"
              },
              "message": {
                "name": "Message au docteur",
                "title": "message visite - prise de rendez-vous",
                "date": "Date de la visite"
              }
            }
          },
          "recommendationTemplate": {
            "title": "modèle de recommandation",
            "examinationNames": "Noms des examens",
            "template": {
              "message": "Veuillez écrire un message au médecin traitant concernant l'état de santé",
              "therapy": "Médicaments selon le schéma en pharmacothérapie et sur le compte du patient dans le système Neuroterminal dans l'onglet thérapie",
              "urgentContact": "En cas de contact urgent avec un médecin ou de circonstances imprévues, veuillez appeler la réception +48 609 463 191",
              "noTherapy": "Sans traitement pharmacologique",
              "calendar": "Veuillez maintenir un calendrier des événements sur le portail patient du système Neuroterminal",
              "headMr": "Veuillez faire une IRM de la tête",
              "medicalExaminations": "Veuillez effectuer des tests supplémentaires et joindre les résultats à la documentation médicale dans le système Neuroterminal"
            }
          },
          "epicrisis": "Epicrise",
          "noteZeroObservations": "Remarque 0 observation",
          "editNoteZeroObservations": "Modifier la note 0 observations",
          "saveObservationLbl": "Enregistrer la note 0 observations",
          "downloadTestRecommendations": "Télécharger les recommandations d'essai",
          "mainDoctor": "Médecin principal",
          "supervisors": "Superviseurs"
        },
        "ictal": {
          "diagnosis": {
            "title": "Chère Madame, cher Monsieur",
            "fillSurveyMsg": "Aucune crise définie pour le moment. Remplissez le sondage 0",
            "ictalDiagnose": "Diagnostiquer avec Ictall",
            "ictalDiagnoseOneMoreTime": "Diagnostiquer une fois de plus",
            "patientSeizureType": "Type de crise sélectionné (défini par le patient)",
            "suggestedDiagnoseMsg": "Diagnostic potentiel pour",
            "subtitle1": "N'oubliez pas que notre",
            "subtitle2": "Robot IA – Ictal®",
            "subtitle3": "- est un outil innovant, qui vous aidera et apprendra constamment pour vous aider encore mieux, mais",
            "subtitle4": "ce n’est pas un dispositif médical et ne peut pas être utilisé pour évaluer les conditions cliniques réelles de vos patients.",
            "subtitle5": "L'ensemble de la bibliothèque d'ontologies d'épilepsie a été développé pour reconnaître avec précision le type de crise d'épilepsie selon la dernière classification MLPP, de sorte que l'outil peut être utilisé pour évaluer des conditions cliniques hypothétiques à des fins éducatives ou de formation."
          },
          "outcome": {
            "saveLbl": "Enregistrer le diagnostic",
            "reasonLbl": "Raison du choix de ce diagnostic",
            "selectDiagnosisMsg": "Sélectionnez le diagnostic",
            "calcDiagnosisMsg": "Génération du diagnostic..",
            "otherDiagnosis1Lbl": "Autre",
            "otherDiagnosis2Lbl": "diagnostic",
            "approveLbl": "Je suis d'accord",
            "rejectLbl": "Je ne suis pas d'accord",
            "fixCritical": "Résoudre les questions critiques",
            "updatedByLbl": "Selon le diagnostic de"
          },
          "therapy": {
            "title": "Chère Madame, cher Monsieur",
            "ictalCheck": "Vérifier",
            "seizureType": "Type de saisie",
            "type": {
              "1": "I Monothérapie",
              "2": "II Monothérapie",
              "3": "III Monothérapie",
              "4": "I Polithérapie",
              "5": "II Polithérapie",
              "6": "III Polithérapie",
              "0": "Suggestion ICTAL",
              "7": "Polithérapie supplémentaire :"
            },
            "drug": {
              "description": {
                "lastUsed": "Dernière utilisation :",
                "color": {
                  "undefined": "Le médicament n'a pas été utilisé auparavant.",
                  "orange": "Le médicament a déjà été utilisé : aucun effet / effet faible.",
                  "red": "Le médicament a été utilisé auparavant : effet négatif, l'état du patient s'est aggravé.",
                  "green": "Le médicament a été utilisé auparavant : effet satisfaisant."
                }
              }
            },
            "filter": {
              "availableInPL": "Médicament disponible en Pologne",
              "refundedInPL": "Médicament remboursé en Pologne",
              "reproductiveAgeOptional": "Âge de reproduction"
            },
            "notFound": "ICTAL n'a pas réussi à adapter les traitements au patient.",
            "apply": "Appliquer la thérapie",
            "proposal": "Options thérapeutiques possibles",
            "subtitle1": "N'oubliez pas que notre",
            "subtitle2": "Robot IA – Ictal®",
            "subtitle3": "- est un outil innovant, qui vous aidera et apprendra constamment pour vous aider encore mieux, mais",
            "subtitle4": "ce n’est pas un dispositif médical et ne peut pas être utilisé pour évaluer les conditions cliniques réelles de vos patients.",
            "subtitle5": "L'ensemble du processus thérapeutique de l'épilepsie a été développé par une équipe d'experts et basé sur les lignes directrices des principales sociétés scientifiques médicales traitant de l'épilepsie, de sorte que l'outil peut être utilisé pour évaluer des conditions cliniques hypothétiques à des fins éducatives ou de formation."
          }
        },
        "doctorNotes": {
          "title": "Remarques",
          "subtitle": "C'est un endroit où vous pouvez stocker vos notes privées sur ce patient",
          "addNote": "Ajouter une note",
          "save": "Sauvegarder les modifications",
          "saveSuccess": "La note a été enregistrée",
          "modified": "Dernière modification",
          "editCancel": "Annuler les modifications",
          "edit": "Note éditée",
          "delete": "Supprimer la note",
          "askDelete": "Voulez-vous vraiment supprimer cette note ?",
          "deleteSuccess": "La note a été supprimée",
          "publicVisible": "Visible par tous les médecins",
          "sharedBy": "Partagé par",
          "limitedShare": "Visible par les médecins sélectionnés",
          "doctor": "Médecin",
          "forAdmin": "Visible pour l'administrateur",
          "repliedTo": "W odpowiedzi na",
          "sharedByPatient": "Partagé par patient pour consultation absente",
          "absentConsultation": "Consultation absente",
          "addFiles": "Ajouter le fichier"
        },
        "diagnosis": {
          "confirmTitle": "Changement de diagnostic",
          "confirmText": "Êtes-vous sûr de ne pas vouloir modifier le diagnostic ?",
          "updatedMsg": "Diagnostic mis à jour pour ce type de crise"
        },
        "patientMessageHistory": {
          "title": "Historique des messages des patients",
          "emptyList": "L'historique des messages du patient est vide"
        }
      },
      "landing": {
        "common": {
          "menu": {
            "observe": "Observez-nous",
            "link": {
              "database": "NT - Base de données",
              "doctors": "Pour les médecins",
              "contact": "Contact",
              "login": "Connexion",
              "register": "S'inscrire"
            },
            "terms": {
              "title": "Conditions d'utilisation",
              "z1": "Règlement d'organisation",
              "z2": "Réglementation pour la fourniture de services électroniques",
              "z3": "Politique de confidentialité de Neurosphère",
              "z4": "Politique de confidentialité du Neuroterminal",
              "payuInstallments": "Paiements échelonnés - FAQ"
            }
          },
          "contact": {
            "title": "Nous contacter",
            "media": {
              "visit": "Rendez nous visite"
            },
            "dataTitle": "Nous contacter",
            "formTitle": "Formulaire de contact",
            "form": {
              "email": "Envoyer un e-mail",
              "phone": "Appelez-nous",
              "message": "Un message",
              "info": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercice ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in",
              "send": "Envoyer le message",
              "ApproveDataProcessing": "J'accepte volontairement que mes données personnelles soient traitées par Neuroterminal S.A. afin de traiter ma candidature. Vous trouverez des informations détaillées sur les principes de protection des données personnelles et vos droits dans",
              "policy": "Politique de confidentialité",
              "feedback": {
                "header": "Merci de nous avoir envoyé votre message !",
                "content": "nous vous contacterons sous peu\npar téléphone ou e-mail"
              }
            }
          },
          "help": {
            "title": "Vous avez des questions ?"
          }
        },
        "main": {
          "title1": "L'épilepsie moderne",
          "title2": "système de traitement",
          "bullet1": "Consultations de télémédecine pratiques\nà partir de 99 PLN",
          "bullet2": "Accès au meilleur\népileptologues",
          "bullet3": "Soins médicaux complets\nsans quitter la maison",
          "arrangeConsultation": "Prendre rendez-vous",
          "features": {
            "consultations": {
              "title": "Télémédecine\nconsultations",
              "description": "Effectuer des visites en ligne\npar visioconférence"
            },
            "emergency": {
              "title": "Urgence\ncas",
              "description": "Appel SOS 24h/24\navec des médecins"
            },
            "panel": {
              "title": "Patient\npanneau",
              "description": "Antécédents médicaux,\ndocuments médicaux\net recommandations\ndans un endroit"
            },
            "edocuments": {
              "title": "Ordonnance électronique,\nles congés de maladie",
              "description": "et références\nsans quitter la maison"
            },
            "doctors": {
              "title": "\nMédecins",
              "description": "Accès aux meilleurs épileptologues\nsans files d'attente"
            },
            "quickAccess": {
              "title": "Rapide\naccéder",
              "description": "Consultations avec des médecins\nsans files d'attente"
            }
          },
          "subHeaderBullet1": "Restez en contact avec votre médecin,\nqui utilise le module d'intelligence artificielle\npour surveiller l'efficacité de votre traitement.",
          "subHeaderBullet2": "Accédez aux meilleurs neurologues en Pologne\nsans quitter la maison.",
          "subHeaderBullet3": "Rejoignez gratuitement le Neuroterminal\net commencez à utiliser des solutions innovantes\npour soigner l'épilepsie !",
          "howItWorks": "Comment ça fonctionne?",
          "steps": {
            "choose": {
              "title": "Choix du patient\nun docteur",
              "description": "à partir d'une base de données de spécialistes"
            },
            "appoint": {
              "title": "Fait du\nun rendez-vous",
              "description": "pour une visite de télémédecine\nà un moment opportun"
            },
            "diagnose": {
              "title": "Le médecin diagnostique",
              "description": "et choisit la thérapie.\nLe système d'intelligence artificielle iCtal\nl'aide avec ça"
            },
            "documents": {
              "title": "Ajoute médical\ndocuments",
              "description": ""
            },
            "contact": {
              "title": "Le docteur reste\nen contact",
              "description": "avec patiente,\nsuit régulièrement les progrès\ndu traitement mis en place"
            }
          },
          "opinions": "Avis des utilisateurs",
          "register": "Créer un compte pour prendre rendez-vous"
        },
        "database": {
          "epilepsy": {
            "title": "Qu'est-ce que l'épilepsie ?",
            "subheader1": "L'épilepsie est une condition médicale\ncaractérisé par des crises récurrentes.",
            "subheader2": "La saisie est le résultat\nd'un temporairement modifié\nfonction cérébrale\ncausé par\nanormal ou excessif\ndécharges électriques\nà l'intérieur des cellules du cerveau."
          },
          "seizure": {
            "title": "Comment la saisie\nressembler?",
            "subheader": "Les symptômes des crises peuvent varier considérablement.",
            "bullet1": "Certaines personnes épileptiques\nsimplement \"perdre le contact\"\npendant quelques secondes lors d'une crise",
            "bullet2": "D'autres personnes tombent au sol\navec convulsions des membres",
            "footer": "La classification du type de crise est très importante\net aide le médecin à planifier le traitement nécessaire.\nUne seule crise ne signifie pas que vous souffrez d'épilepsie."
          },
          "factors": {
            "title": "Facteurs provoquants",
            "subheader": "Des convulsions peuvent également être causées\npar des facteurs connus et réversibles,\ntel que",
            "bullet1": "Pas de sommeil",
            "bullet2": "De l'alcool\nRetrait",
            "bullet3": "Peu sucré",
            "seizureRecognition": "Au moins deux crises non provoquées\nsont généralement nécessaires pour diagnostiquer l'épilepsie.",
            "seizuresRelatedTo": "Les crises peuvent être associées à",
            "relatedToBullet1": "Dommages cérébraux",
            "relatedToBullet2": "Tendance familiale",
            "relatedToBullet3": "Souvent leur cause\nest complètement inconnu"
          },
          "whoCanSick": {
            "title": "Qui peut tomber malade ?",
            "subheader": "L'épilepsie peut toucher n'importe qui,\nindépendamment du sexe et de l'âge.",
            "bullet1": "La plupart des crises surviennent chez les enfants\njusqu'à 1 an,\nalors que les personnes âgées ont généralement une augmentation\ndans l'incidence après 65 ans.",
            "bullet2": "On estime que jusqu'à 1 % des personnes\nsouffrent d'épilepsie (environ 400 000 personnes en Pologne)."
          },
          "treatment": {
            "title": "Comment l'épilepsie est-elle traitée ?",
            "bullet1": "Les médicaments peuvent contrôler efficacement les crises\ndans environ 70%\ndes patients épileptiques",
            "bullet1Header": "Médicaments",
            "bullet2": "Pour les patients souffrant d'épilepsie non contrôlée,\nla neurochirurgie peut fournir un \"remède\"\nen éliminant la source\nde convulsions et d'épilepsie.",
            "bullet2Header": "Opération",
            "footer": "Traitement médicamenteux ou parfois chirurgie\npeut contrôler les crises chez la plupart des personnes épileptiques.\nCertaines personnes ont besoin d'un traitement à vie pour contrôler les crises,\nmais d'autres finiront par s'arrêter. Certains enfants épileptiques\npeut se développer hors des crises avec l'âge."
          },
          "impact": {
            "title": "Comment l'épilepsie affecte\nvie courante?",
            "subheader": "Les convulsions et l'épilepsie peuvent affecter",
            "bullet1": "Sécurité",
            "bullet2": "Rapports",
            "bullet3": "Emploi",
            "bullet4": "Conduire une voiture",
            "bullet5": "Et bien plus encore",
            "footer": "Perception du public et traitement des personnes épileptiques\nsont souvent des problèmes plus importants que les crises réelles."
          },
          "help": {
            "title": "La volonté neuroterminale\nvous aider à guérir l'épilepsie",
            "bullet1Header": "Confort",
            "bullet1": "vous arrangerez\nune consultation avec un médecin\nsans quitter la maison.",
            "bullet2Header": "Innovation",
            "bullet2": "Nous utilisons artificiel\nsystèmes de renseignement\net des outils d'analyse modernes\npour traiter l'épilepsie.",
            "bullet3Header": "Sécurité",
            "bullet3": "Vous aurez accès\nà un changement neurologique de 24 heures\nqui vous fournira\naide immédiate.",
            "makeAnAppointment": "Prendre rendez-vous"
          }
        },
        "doctors": {
          "title1": "Devenir médecin",
          "titleIn": "dans",
          "title2": "NEUROTERMINAL",
          "subtitle": "Consulter les patients en ligne\nen utilisant la dernière\nles technologies.",
          "register": "S'inscrire",
          "features": {
            "consultations": {
              "title": "Télémédecine\nconsultations",
              "description": "Effectuer des visites en ligne\npar visioconférence\nou discuter"
            },
            "digitalOffice": {
              "title": "Numérique\nBureau",
              "description": "Toutes les visites et données médicales\nde vos patients\ndans un endroit"
            },
            "eDocuments": {
              "title": "\nDocuments électroniques",
              "description": "Créer des ordonnances,\ncongés de maladie et références\nen ligne"
            },
            "flexibleSchedule": {
              "title": "Souple\nprogramme",
              "description": "Planifier des consultations\naux dates qui vous conviennent\n7 jours par semaine"
            },
            "system": {
              "title": "Système\nNeuroterminal",
              "description": "Utiliser une innovation\nsystème d'intelligence artificielle\ndiagnostiquer les patients"
            },
            "patients": {
              "title": "\nLes patients",
              "description": "Dirigez vos patients\net en gagner rapidement de nouveaux"
            }
          },
          "steps": {
            "register": {
              "title": "S'inscrire",
              "description": ""
            },
            "training": {
              "title": "Passer par la formation",
              "description": "comment utiliser le Neuroterminal\nplate-forme et conduite\nconsultations de télémédecine"
            },
            "schedule": {
              "title": "Définir les consultations\nprogramme",
              "description": "planifier librement\nvotre disponibilité"
            },
            "pricing": {
              "title": "Précisez la liste de prix",
              "description": "votre salaire\nà consulter"
            },
            "consultations": {
              "title": "Consulter",
              "description": "télémédecine en ligne via\nla plateforme Neuroterminal\npar vidéo ou chat"
            },
            "monitor": {
              "title": "Surveiller\nles patients",
              "description": "et les progrès de leur traitement"
            }
          },
          "firstSteps": "Premiers pas"
        },
        "patients": {},
        "removePatient": {
          "title": "Confirmer la suppression du compte",
          "subTitle1": "Compte enregistré à",
          "subTitle2": "sera supprimé",
          "why": "Pourquoi voulez-vous supprimer le compte",
          "whyPrompt": "Votre réponse est très importante pour nous. Elle nous aidera à améliorer notre service.",
          "removeBtn": "Supprimez votre compte de Neuroterminal",
          "accountRemoved": "Votre compte a été supprimé",
          "noAccount": "Le compte n'existe pas ou ne peut pas être supprimé",
          "accountRemovedDescription": "N'oubliez pas que vous pouvez vous inscrire sur Neuroterminal et réserver une visite à Neurosphera à tout moment."
        }
      },
      "ictal": {
        "lab": {
          "title": "IctalLab",
          "subtitle": "Entrez le vecteur ou chargez le patient pour lancer le diagnostic automatique.",
          "diagnose": "Diagnostiquer",
          "clear": "Dégager",
          "inputVector": "Vecteur d'entrée",
          "loadPatient": "Sélectionnez un patient",
          "changePatient": "Changer de patient",
          "selectedPatient": "Patiente sélectionnée",
          "survey": "Enquête",
          "diagnosis": "Diagnostic",
          "factorsConfiguration": "Configuration des facteurs",
          "sum": "Somme",
          "therapy": "Thérapie",
          "check": "Vérifier",
          "selectTherapy": "Sélectionnez le type de thérapie",
          "selectedTherapy": "Type de thérapie sélectionné",
          "selectSeizureType": "Sélectionnez les types de crises",
          "selectedSeizureType": "Types de crises sélectionnés",
          "drugAvailableInPl": "Médicament disponible en Pologne",
          "drugRefunded": "Médicament remboursé",
          "patientIsPregnant": "La patiente est enceinte",
          "patientWithSomaticDiseases": "Patient atteint de maladies somatiques",
          "patientInOlderAge": "Patient plus âgé",
          "patientOfChildbearingAge": "Patiente en âge de procréer",
          "patientAge": "Âge du patient",
          "recommendedTherapy": "Thérapie recommandée",
          "errors": {
            "factorsError": "La somme des coefficients doit être de 100",
            "vectorLengthError": "Longueur de vecteur d'entrée incorrecte",
            "vectorInvalidError": "Vecteur d'entrée incorrect"
          },
          "factors": {
            "nn": "NN",
            "knn": "KNN",
            "jacc": "Jaccard",
            "jaro": "Jaro",
            "sodi": "Sørensen–Dice",
            "over": "Chevauchement",
            "tver": "Tversky : =1, β=1"
          },
          "surveyHeaders": {
            "name": "Question",
            "always": "Toujours",
            "veryOften": "Très souvent",
            "often": "Souvent",
            "sometimes": "quelquefois",
            "rarely": "Rarement",
            "veryRarely": "Très rarement",
            "never": "Jamais",
            "veryLong": "Très long",
            "long": "Longue",
            "bitLong": "Un peu long",
            "variable": "Variable",
            "short": "Court",
            "veryShort": "Très court",
            "ultraShort": "Ultra court"
          },
          "seizuresTableHeaders": {
            "seizureType": "Type de saisie",
            "probability": "Probabilité [ % ]"
          },
          "selectUserDialog": {
            "title": "Sélectionnez un patient",
            "noPatientsFound": "Aucun patient trouvé."
          },
          "configuration": {
            "subtitle": "Configuration",
            "drugCatalog": {
              "title": "Catalogue de médicaments",
              "add": "Ajouter un nouveau médicament"
            },
            "factors": {
              "save": "Enregistrer les facteurs",
              "coefModifiedMsg": "Coefficients enregistrés"
            }
          },
          "drugs": {
            "deleteConfirmationDialogText": "La suppression du médicament supprimera également les données d'entraînement du moteur Ictal. Toutes les données associées à ce médicament seront supprimées. Souhaitez-vous continuer?",
            "updateDrugDialogTitle": "Mettre à jour le médicament",
            "createDrugDialogTitle": "Ajouter un médicament",
            "save": "sauver",
            "cancel": "Annuler",
            "validation": {
              "minAgeRequired": "L'âge minimum est requis",
              "minAgeRange": "L'âge minimum doit être un nombre compris entre 0 et 120",
              "weightRequired": "Le poids est requis",
              "weightRange": "Le poids doit être un nombre compris entre -3 et 3"
            },
            "legend": {
              "title": "Drogues - légende",
              "column": "Colonne",
              "value": "Valeur",
              "thrAbove": "Seuil d'âge atteint ; âge au-dessus du seuil fixé",
              "thrBelow": "Seuil d'âge non atteint ; âge inférieur au seuil fixé",
              "drugAvailable": "Médicament disponible en PL",
              "drugNotAvailable": "Médicament non disponible en PL",
              "drugRefunded": "Médicament remboursé en PL",
              "drugNotRefunded": "Médicament non remboursé en PL",
              "usageForbidden": "Interdit",
              "usageVeryRare": "Très rarement utilisé",
              "usageRare": "Rarement utilisé",
              "usageAmbivalent": "Ambivalent",
              "usageSometimes": "Parfois utilisé",
              "usageOften": "Souvent utilisé",
              "usageRecommended": "conseillé"
            }
          },
          "usedDrugs": "Médicaments à filtrer (implicite - détérioration extrême)",
          "drugProposal": "Proposition de médicament",
          "wageSum": "Somme salariale (plus élevée - meilleure)",
          "patientSex": {
            "label": "Sexe",
            "type": {
              "MALE": "Homme",
              "FEMALE": "Femme"
            }
          },
          "experts": {
            "titleLbl": "Médecins experts",
            "addExpertMsg": "Ajouter un expert",
            "editExpertMsg": "Expert en édition",
            "deleteExpertTitleMsg": "Supprimer l'expert",
            "deleteExpertDescMsg": "La suppression de l'expert entraînera la suppression de toutes les données d'entraînement du moteur Ictal associées à cet expert. Voulez-vous continuer?",
            "saveMsg": "Données d'experts modifiées",
            "save": "sauver",
            "cancel": "Annuler",
            "validation": {
              "weightRange": "Le poids doit être un nombre compris entre 0 et 1"
            }
          },
          "recommendations": {
            "titleLbl": "Médicaments recommandés par les experts",
            "addRecommendationLbl": "Ajouter une recommandation",
            "saveMsg": "Vecteur de recommandation de médicament enregistré",
            "save": "sauver",
            "cancel": "Annuler",
            "dialog": {
              "titleLbl": "Médicaments recommandés",
              "expertLbl": "Expert",
              "therapyLbl": "Thérapie"
            }
          },
          "epilepsies": {
            "titleLbl": "Types d'épilepsie",
            "addMsg": "Ajouter un type d'épilepsie",
            "editMsg": "Modifier le type d'épilepsie",
            "deleteTitleMsg": "Supprimer le type d'épilepsie",
            "deleteDescMsg": "La suppression du type d'épilepsie entraînera la suppression de toutes les données d'entraînement du moteur Ictal associées à ce type d'épilepsie. Voulez-vous continuer?",
            "saveMsg": "Données sur les types d'épilepsie modifiées",
            "save": "sauver",
            "cancel": "Annuler"
          },
          "ediagnosis": {
            "titleLbl": "E-Diagnostic",
            "headers": {
              "eventsTrailer": "Bien avant la crise (heures, jours)",
              "eventsBefore": "Juste avant la crise (secondes)",
              "eventsStart": "Début de crise - premier symptôme",
              "eventsNext": "Prochains symptômes de crise",
              "eventsEnd": "Fin de la saisie",
              "eventsAfter": "Après la saisie",
              "eventsFeature": "Caractéristique globale des crises"
            },
            "dialog": {
              "titleLbl": "Vecteur de diagnostic électronique",
              "epilepsyLbl": "Type d'épilepsie",
              "save": "sauver",
              "cancel": "Annuler",
              "saveMsg": "Vecteur de diagnostic électronique modifié"
            },
            "events": {
              "trailerPrecise": "Prédiction précise (suivante)",
              "trailerUnspecified": "Prédiction non spécifiée (suivante)",
              "beforeAura": "Aura (avant)",
              "beforeSpecificStimuli": "Stimuli spécifiques (avant)",
              "beforeNystagmus": "Nystagmus (avant)",
              "beforeImmobility": "Immobilité (avant)",
              "startFaint": "Évanouissement (début)",
              "startLossConsciousness": "Perte de conscience (début)",
              "startBodyConvulsions": "Convulsions corporelles (début)",
              "startBodyPartConvulsions": "Convulsions des parties du corps (début)",
              "startFaceConvulsions": "Convulsions du visage (début)",
              "startBodyStiffness": "Raideur du corps (début)",
              "startLimbEjection": "Éjection des membres (début)",
              "startEyeMovementDisorders": "Troubles des mouvements oculaires (début)",
              "startEyeTurnPernament": "Tour des yeux permanent (début)",
              "startBodyTurn": "Tour du corps (début)",
              "startGroundFall": "Chute au sol (début)",
              "startBitTongue": "Langue mordante (début)",
              "startShout": "Crier (commencer)",
              "startSounds": "Sons (début)",
              "startMotorAutomatisms": "Automatisme moteur (démarrage)",
              "startImmobility": "Immobilité (début)",
              "startLaugh": "Rire (commencer)",
              "startChuck": "Jeter (commencer)",
              "startCry": "Pleurer (commencer)",
              "startEpigastricSymptoms": "Symptômes épigastriques (début)",
              "startDejaVu": "Déjà vu (début)",
              "startVisualHallucinations": "Hallucinations visuelles (début)",
              "startAuditoryHallucinations": "Hallucinations auditives (début)",
              "startEyelidsMyoclonus": "Myoclonies des paupières (début)",
              "startMyoclonus": "Myoclonie (début)",
              "nextFaint": "évanouissement (suivant)",
              "nextLossConsciousness": "Perte de conscience (suite)",
              "nextBodyConvulsions": "Convulsions corporelles (suite)",
              "nextBodyPartConvulsions": "Convulsions des parties du corps (suite)",
              "nextFaceConvulsions": "Convulsions du visage (suite)",
              "nextBodyStiffness": "Raideur du corps (suite)",
              "nextEyeMovementDisorders": "Troubles des mouvements oculaires (suite)",
              "nextEyeTurnPernament": "Tour des yeux permanent (suivant)",
              "nextBodyTurn": "Tour du corps (suivant)",
              "nextGroundFall": "Chute au sol (suivant)",
              "nextBitTongue": "Langue mordante (suivant)",
              "nextUrinating": "Miction (suivant)",
              "nextDefecation": "Défécation (suivant)",
              "nextShout": "Crier (suivant)",
              "nextSounds": "Sons (suivant)",
              "nextAggression": "Agression (suivant)",
              "nextMotorAutomatisms": "Automatisme moteur (suite)",
              "nextImmobility": "Immobilité (suivant)",
              "nextLaugh": "Rire (suivant)",
              "nextChuck": "Chucking (suivant)",
              "nextCry": "Pleurer (suivant)",
              "nextEpigastricSymptoms": "Symptômes épigastriques (suite)",
              "nextDejaVu": "Déjà vu (suivant)",
              "nextVisualHallucinations": "Hallucinations visuelles (suite)",
              "nextAuditoryHallucinations": "Hallucinations auditives (suite)",
              "nextEyelidsMyoclonus": "Paupières myoclonies (suivant)",
              "endAnotherSeizure": "Une autre saisie (fin)",
              "endBodyConvulsions": "Convulsions corporelles (fin)",
              "endBodyPartConvulsions": "Convulsions des parties du corps (fin)",
              "endFaceConvulsions": "Convulsions du visage (fin)",
              "endEyeMovementDisorders": "Troubles des mouvements oculaires (fin)",
              "endEyeTurnPernament": "Tour d'oeil permanent (fin)",
              "endBodyTurn": "Tour du corps (fin)",
              "endGroundFall": "Chute au sol (fin)",
              "endUrinating": "Miction (fin)",
              "endDefecation": "Défécation (fin)",
              "endSounds": "Sons (fin)",
              "endAggression": "Agression (fin)",
              "endMotorAutomatisms": "Automatisme moteur (fin)",
              "endImmobility": "Immobilité (fin)",
              "endLaugh": "Rire (fin)",
              "endChuck": "Chucking (fin)",
              "endCry": "Pleurer (fin)",
              "endVomit": "Vomissements (fin)",
              "afterEyeMovementDisorders": "Troubles des mouvements oculaires (après)",
              "afterEyeTurnPernament": "Tour des yeux permanent (après)",
              "afterUrinating": "Miction (après)",
              "afterDefecation": "défécation (après)",
              "afterConfusion": "Confusion (après)",
              "afterSleep": "Dormir (après)",
              "afterAggression": "Agression (après)",
              "afterMotorAutomatisms": "Automatisme moteur (après)",
              "afterVomit": "Vomissements (après)",
              "afterHighTemperature": "Haute température (après)",
              "featureMany": "Beaucoup (caractéristique)",
              "featureDayOccurrence": "Occurrence du jour (fonctionnalité)",
              "featureNightOccurrence": "Occurrence nocturne (fonctionnalité)",
              "featureAwakeningOccurrence": "Occurrence d'éveil (fonctionnalité)",
              "featureMorningOccurrence": "Apparition du matin (fonctionnalité)",
              "featureDuration": "Durée (fonctionnalité)"
            }
          },
          "model": {
            "search": "Chercher",
            "manage": "Gérer",
            "drug": {
              "general": "Données GENERALES",
              "weights": "Poids",
              "shortName": "Nom court",
              "name": "Nom",
              "minAge": "Âge minimum",
              "plAvailability": "Disponible en PL",
              "plRefunded": "Remboursé en PL",
              "reproductiveAgeWeight": "Âge de reproduction",
              "pregnantWeight": "Enceinte",
              "oldAgeWeight": "Vieillesse",
              "somaticDiseaseWeight": "Maladie somatique"
            },
            "expert": {
              "description": "Expert",
              "factor": "Facteur"
            },
            "epilepsy": {
              "name": "Nom"
            }
          },
          "algorithmSteps": {
            "filterName": "Filtre",
            "order": "Ordre",
            "usedFilters": "Filtres usagés",
            "filters": {
              "SeizureDrugFilter": "Sélection de Durg par saisies",
              "UsedDrugFilter": "Retrait des médicaments usagés",
              "TherapyDrugFilter": "Commande de médicaments par recommandation d'experts (poids)",
              "WeightDrugFilter": "Calcul des poids"
            },
            "tableModel": {
              "drugName": "Nom",
              "drugShortName": "Nom court",
              "minAge": "Âge minimum",
              "oldAgeWeight": "Âge plus avancé",
              "pregnantWeight": "Grossesse",
              "reproductiveAgeWeight": "Âge de reproduction",
              "somaticDiseaseWeight": "Maladies somatiques",
              "weightSum": "Somme des poids"
            }
          },
          "furtherPoliOffset": "Autre numéro de polithérapie (commence à 0)"
        }
      },
      "duties": {
        "Duties": {
          "duty": "Service prévu",
          "availableDuty": "Droit disponible",
          "notAvailableDuty": "Devoir non disponible",
          "selectedDuty": "Choisi",
          "blockedDuty": "Pas disponible",
          "cancelledDuty": "Sélectionné pour annulation",
          "dutyToChange": "Demande de reprise de fonction",
          "changes": {
            "approve": {
              "text": "Sauvegarder les modifications",
              "tooltipInstruction": "Choisissez les tâches parmi celles disponibles et enregistrez en cliquant sur ce bouton"
            },
            "discard": {
              "text": "Annuler les modifications"
            }
          }
        },
        "confirmationDialog": {
          "text": "Vos modifications ne sont pas enregistrées. Voulez-vous les jeter ?"
        },
        "youAreNotAllowedDialog": {
          "title": "Autorisations requises",
          "text": "Vous n'avez pas la permission d'enregistrer des tâches. Contactez-nous pour plus d'informations."
        },
        "obligatoryDutyDialog": {
          "title": "Opération non autorisée",
          "subtitle": "Selon le règlement des conditions générales, la prestation sélectionnée ne peut être annulée.",
          "text": "Pour plus d'informations, consultez notre",
          "termsAndConditions": "Termes et conditions",
          "close": "Fermer"
        },
        "dutiesLimitAchievedDialog": {
          "title": "Opération non autorisée",
          "subtitle": "Vous avez atteint votre limite de service pour cette semaine.",
          "text": "Pour plus d'informations, consultez notre",
          "termsAndConditions": "Termes et conditions",
          "close": "Fermer"
        }
      },
      "medicalHistory": {
        "MedicalHistory": {
          "title": "Antécédents médicaux",
          "return": "Revenir",
          "show": "Spectacle",
          "psychomotorDevelopment": "Développement psychomoteur",
          "comorbidities": "Comorbidités",
          "notDiagnosedLbl": "Non diagnostiqué",
          "bloodType": "Groupe sanguin",
          "height": "Hauteur",
          "weight": "Poids",
          "bmi": "IMC",
          "tabs": {
            "psychomotorDevelopment": "Développement psychomoteur",
            "seizuresHistory": "Historique des saisies",
            "seizuresCharacteristics": "Caractéristiques des crises",
            "comorbidities": "Comorbidités",
            "treatment": "Traitement"
          },
          "treatment": {
            "title": "Traitement et historique des visites",
            "currentTherapy": "Thérapie actuelle",
            "biomedicalData": "Données biomédicales",
            "comorbiditiesData": "Données sur les comorbidités",
            "previousTherapies": "Historique des thérapies",
            "number": "Non",
            "medicine": "Médicament",
            "dosage": "Dosage",
            "effect": "Effet",
            "noCurrentTherapy": "Pas de thérapie en cours",
            "start": "Début",
            "end": "Finir",
            "noPreviousTherapies": "L'historique des thérapies est vide",
            "treat": "traitement",
            "visits": "visites"
          },
          "survey": {
            "title": "Sondage 0"
          },
          "calendar": {
            "title": "Calendrier des événements",
            "visitCalendarLabel": "Calendrier des visites"
          },
          "prescriptions": {
            "title": "E-ordonnances"
          },
          "consultations": {
            "title": "Visites",
            "noConsultations": "Aucune consultation",
            "observations": "Observations",
            "recomendation": "Recommandation",
            "doctor": "Docteur",
            "date": "Date",
            "type": "Type",
            "category": "Catégorie",
            "none": "Rien"
          },
          "icd10": {
            "title": "Diagnostic",
            "current": "Diagnostic actuel",
            "history": "Histoire",
            "from": "De",
            "added": "Ajoutée",
            "removed": "Supprimé",
            "noCurrentIcd10": "Pas de diagnostic actuel",
            "noIcd10History": "L'historique des diagnostics est vide"
          },
          "bioMedInfo": {
            "edit": "Éditer"
          },
          "visitCalendar": {
            "title": "Avis du médecin et du patient",
            "opinionTypeHeader": "Type d'avis",
            "seizureEvaluation": "Sévérité des crises",
            "wellBeing": "Bien-être",
            "treatmentRating": "Efficacité du traitement",
            "sideEffects": "Effets secondaires",
            "treatmentSafety": "Sécurité du traitement",
            "patientOpinion": "L'avis du patient",
            "doctorOpinion": "L'avis du médecin",
            "noData": "Pas de données d'enquêtes",
            "noVisits": "Aucune visite"
          },
          "epicrisis": {
            "title": "Epicrise"
          }
        },
        "healthSurvey": {
          "title": "Sondage 0",
          "readOnlyAccess": "Aperçu en lecture seule. Le sondage peut être rempli par le médecin lors de la première visite",
          "noAnswer": "Pas de réponse",
          "allDisabled": "L'enquête 0 ne peut pas être modifiée après la première visite",
          "partCompleted": "Cette partie de l'enquête est terminée",
          "currentTabDisabled": "Cette partie de l'enquête de santé est complétée et ne peut pas être modifiée après la première visite",
          "emptyFilterSet": "Il n'y a que des questions sans réponse affichées sur la liste.",
          "surveyUpdatedByDoctor": "Le docteur vient de mettre à jour un sondage dans la partie '{sectionName}'",
          "filters": {
            "show": "Spectacle",
            "all": "Tous",
            "empty": "Vider"
          },
          "hintPopup": {
            "title": "Vous ne savez pas comment répondre ?",
            "content": "Ne t'en fais pas! Laissez une question difficile sans réponse. Le médecin vous aidera à remplir les parties manquantes de l'enquête lors de la première conférence téléphonique."
          },
          "SeizureHistory": {
            "introTitle": "Nous aimerions savoir combien de temps et quel type de crises vous avez.",
            "introSubtitle": "Il est très important. Remarque : ce sont vos antécédents médicaux qui ne peuvent être déterminés que de cette façon, il n'y a pas d'autre moyen de les décrire, alors essayez d'obtenir des faits et des dates précis !",
            "next": "Prochain",
            "edit": "Éditer",
            "back": "Précédent",
            "historyCanBeModified": "L'historique de vos crises a déjà été initialisé. Vous pouvez le modifier avant la première visite.",
            "historyCannotBeModified": "L'historique de vos crises ne peut pas être modifié après la première visite.",
            "return": "Revenir",
            "outroTitle": "Félicitations, vous avez répondu à toutes les questions sur l'historique de vos crises.",
            "outroSubtitle": "Je vous remercie!",
            "save": "sauver",
            "generatingInProgress": "Génération de l'historique des crises en cours. S'il vous plaît, attendez...",
            "saveSuccess": "L'historique des saisies a été enregistré avec succès",
            "generateSuccess": "L'historique des saisies a été généré avec succès",
            "stages": {
              "firstSeizure": "Première saisie",
              "quantityAndRegularity": "Quantité et régularité",
              "frequency": "La fréquence",
              "worsePeriods": "Pire périodes",
              "betterPeriods": "De meilleures périodes",
              "breaks": "Pauses"
            },
            "seizureTypesForm": {
              "title": "Déterminez le nombre de types de vos crises et nommez-les (maximum 3)",
              "modifyWarning": "Attention! Après modification des types de crises, l'historique des crises et les caractéristiques des crises doivent être redéfinis",
              "modifyDisabled": "Les types de crises ne peuvent pas être modifiés car les crises",
              "typeName": "Nom du type de saisie",
              "addType": "Ajouter un type de saisie"
            },
            "firstSeizureForm": {
              "title": "Entrez la date de la première saisie",
              "subtitle1": "Revenez en arrière et souvenez-vous de toute première crise.",
              "subtitle2": "Déterminez la date de votre première crise aussi précisément que possible.",
              "subtitle3": "Nous l'appelons crise {name} (nous définirons son type plus tard avec votre médecin traitant).",
              "sickSince": "Ok, donc tu es malade pour :"
            },
            "quantityAndRegularity": {
              "title": "Des crises comme la première se poursuivent à ce jour, indiquez si :",
              "quantityYes": "De cette première saisie à ce jour, ils sont apparus",
              "quantityNo": "La saisie comme la première ne s'est plus reproduite",
              "quantityUndefined": "Il y en avait tellement qu'il est impossible de donner le nombre exact",
              "seizuresQuantity": "Nombre de saisies",
              "title2": "Se sont-ils produits régulièrement, c'est-à-dire à des intervalles approximativement égaux",
              "yes": "Oui",
              "no": "Non",
              "knowsAllDates": "Connaissez-vous les dates de toutes les crises?",
              "regularOccurrence": "Les épisodes se produisent-ils régulièrement ?"
            },
            "frequency": {
              "title": "Spécifiez la fréquence - saisissez le nombre de crises qui se produisent habituellement au cours d'une période donnée :",
              "in": "Dans"
            },
            "worsePeriods": {
              "title": "Y a-t-il eu des périodes pires où les crises étaient plus fréquentes ?",
              "subtitle": "Identifier les pires périodes où les crises se sont produites plus souvent que d'habitude",
              "date": "Date",
              "frequency": "Fréquence des crises",
              "to": "à",
              "remove": "Supprimer",
              "addPeriod": "Ajouter une période"
            },
            "betterPeriods": {
              "title": "Y avait-il de meilleures périodes où les crises étaient moins fréquentes ?",
              "subtitle": "Identifier de meilleures périodes où les crises sont survenues moins souvent que d'habitude"
            },
            "breaks": {
              "title": "Y a-t-il eu des pauses alors qu'aucune crise n'a eu lieu ?",
              "subtitle": "Identifier les pauses lorsqu'aucune crise ne s'est produite",
              "date": "Date",
              "to": "à",
              "remove": "Supprimer",
              "add": "Ajouter une période"
            },
            "knowsAllDates": {
              "subtitle": "Remplir les dates des saisies suivantes",
              "date": "Date",
              "remove": "Supprimer",
              "add": "Ajouter une date"
            }
          },
          "Intro": {
            "title1": "Les informations que vous fournissez ici seront partagées avec votre médecin. Ils vous aideront à diagnostiquer et à traiter correctement.",
            "fillSurvey": "Remplissez le sondage sur la santé",
            "inVisit": {
              "title1": "La téléconsultation est en cours.",
              "title2": "Il est temps de remplir le questionnaire avec votre médecin.",
              "title3": "Votre médecin vous aidera à répondre à des questions peu claires.",
              "next": "Prochain"
            }
          },
          "PsychomotorDevelopment": {
            "introTitle": "Développement psychomoteur",
            "introSubtitle1": "À ce stade, nous voulons déterminer quel est votre état psycho-physique général.",
            "introSubtitle2": "Grâce à vos réponses, le médecin sélectionnera la thérapie optimale.",
            "introSubtitle3": "N'oubliez pas que si une question n'est pas claire, vous pouvez y répondre avec votre médecin lors de la première conférence téléphonique.",
            "stages": {
              "psychoPhysicalState": "Condition physique et mentale",
              "senses": "Sens",
              "drugs": "Drogues"
            },
            "psychoPhysicalState": {
              "overall": "Déterminer le développement psychomoteur",
              "retardation": "Déterminer le retard psychomoteur (handicap)",
              "iq": "Déterminer le QI du patient",
              "notTested": "Pas testé",
              "tested": "Testé",
              "iqValue": "Entrez la valeur du QI",
              "speech": "Discours actif et passif. (Parler et comprendre la parole)",
              "mentalDisorders": "Histoire des maladies psychiatriques",
              "neurologicalSyndrome": "Syndrome neurologique confirmé",
              "walk": "Marcher",
              "sex": "Sexe"
            },
            "senses": {
              "nystagmus": "Le patient a-t-il un nystagmus ?",
              "vision": "Vision",
              "hearing": "Audience"
            },
            "drugs": {
              "smoking": "Fumeur",
              "alcohol": "De l'alcool",
              "narcotics": "Narcotiques"
            },
            "mentalDisorders": {
              "psychosis": "Psychose actuelle ou passée",
              "depression": "Dépression actuelle ou passée",
              "autism": "Autisme diagnostiqué",
              "adhd": "TDAH et autres",
              "mentalDisordersNotKnown": "Manquant ou inconnu"
            },
            "neurologicalSyndrome": {
              "piramidalDeficit": "Déficit pyramidal",
              "brainDeficit": "Cerveau",
              "sensoryDisturbance": "Perturbation sensorielle",
              "peripharalParesis": "Parésie périphérique",
              "noNeurologicalSyndrome": "Pas ou pas connu"
            }
          },
          "SeizuresCharacteristics": {
            "introTitle": "Caractéristiques de la saisie",
            "introSubtitle1": "N'oubliez pas que vous décrivez une crise ou une crise typique. Lorsque vous décrivez une crise, vous pouvez dire exactement ce qui s'est passé. Dans le cas de crises multiples, certaines d'entre elles peuvent être légèrement différentes les unes des autres, vous énumérez donc leurs caractéristiques accidentelles. Vous décrivez ici les états pré-saisie : remorques, événements immédiats et étapes de la saisie (début, continuation, fin et événement post-saisie). Vous fournirez également des informations générales sur la saisie.",
            "introSubtitle2": "Chaque information est importante et peut avoir un impact sur la substitution du diagnostic par le médecin, essayez d'être aussi précis et précis que possible.",
            "question": "Question",
            "seizure": "Type de saisie",
            "emptySeizureTypesInfo": "Vous devez d'abord définir les types de saisies dans l'onglet 'Historique des saisies'",
            "stages": {
              "trailers": "Saisie\nprophéties",
              "before": "Pré-saisie immédiate\ndésordre",
              "start": "Premier symptôme\nd'une saisie",
              "next": "Un autre\nsymptôme de crise",
              "end": "Symptômes de\nfin de saisie",
              "after": "Après\nsaisie",
              "feature": "Saisie\nfonctionnalités",
              "premonition": "Prémonition"
            },
            "trailers": {
              "trailerPrecise": "Y a-t-il (ou n'y a-t-il eu qu'une seule saisie) des remorques portuaires spécifiques ?",
              "trailerPreciseDescription": "Une prédiction est une prémonition plus ou moins précise d'une crise. Par bande-annonce explicite, on entend un sentiment spécifique qui peut être décrit : anxiété, tristesse, excitation, etc. Parfois la bande-annonce est indéfinie, difficile à décrire par le patient. C'est un phénomène long, il se produit des heures (moins souvent des jours) avant une crise, ce qui le distingue de l'aura qui précède immédiatement la crise. Certains patients ne développent la capacité de ressentir une crise qu'après un certain temps.",
              "trailerUnspecified": "Y a-t-il (ou n'y a-t-il eu qu'une seule saisie) des remorques non précisées pour une saisie ?",
              "trailerUnspecifiedDescription": ""
            },
            "before": {
              "beforeAura": "Y a-t-il une aura avant la ou les crises ?",
              "beforeAuraDescription": "Une prémonition d'une crise (appelée aura) est un état subjectif, ressenti différemment, qui signale qu'une crise est sur le point de se produire (immédiatement ou à un moment indéfini). Il arrive qu'il n'y ait pas de crise après l'aura. Le patient peut ne pas savoir initialement qu'il a une aura paroxystique, généralement cette prise de conscience vient après des attaques d'aura ultérieures.",
              "beforeSpecificStimuli": "La crise/les crises peuvent-elles être causées par des stimuli spécifiques ?",
              "beforeSpecificStimuliDescription": "Ex. son soudain ou spécifique, impact ou douleur, lumière intermittente, flash ou autre phénomène. Parfois, une crise est réflexive en réponse à quelque chose.",
              "beforeNystagmus": "Y a-t-il des mouvements oculaires pathologiques et/ou un nystagmus avant la ou les crises ?",
              "beforeNystagmusDescription": "Le nystagmus est un mouvement rapide de boutons, généralement dans des directions opposées. D'autres mouvements sont la rotation rapide des boutons ou leur rotation.",
              "beforeImmobility": "Y a-t-il/y a-t-il une immobilité, une immobilisation soudaine ou un arrêt du mouvement avant la ou les crises ?",
              "beforeImmobilityDescription": "Il y a des moments où nous remarquons que le patient cesse de bouger et devient immobile. Cela peut être un symptôme de pré-crise."
            },
            "start": {
              "startFaint": "La ou les crises ont-elles commencé / ont-elles commencé par un black-out ?",
              "startFaintDescription": "La perte de conscience est le type le plus profond de perte de contact, de contrôle et de perception. Cela signifie que le cerveau \"s'éteint\" en quelque sorte, ne laissant que le rythme cardiaque et le souffle. Elle s'accompagne toujours d'une chute, d'un manque de réactivité et d'une absence totale de contact. La durée varie.",
              "startLossConsciousness": "La ou les crises ont-elles commencé / ont-elles commencé par une perte ou une perte de conscience partielle ?",
              "startLossConsciousnessDescription": "Lorsqu'il est inconscient, il y a une perte de conscience automatique. Lorsque seule une perte de conscience se produit, le contact avec l'environnement et la pensée consciente, le contact et la réaction sont perturbés. Le patient peut se tenir debout, parfois effectuer diverses activités motrices, voire parler, mais le contact et les réactions logiques et conscients sont perturbés / perdus.",
              "startBodyConvulsions": "La ou les crises ont-elles commencé / ont-elles commencé par des crises du corps entier ?",
              "startBodyConvulsionsDescription": "Les convulsions sont des mouvements rapides et rythmés des muscles lorsqu'ils se contractent et se détendent de manière rythmique. Ce ne sont pas des mouvements tremblants, brisés ou lents; sont généralement inutiles sans direction de mouvement.",
              "startBodyPartConvulsions": "La ou les crises ont-elles commencé / ont-elles commencé par des convulsions d'une partie du corps ? (un ou plusieurs membres, tronc, hémiplégie mais PAS tout le corps)",
              "startBodyPartConvulsionsDescription": "Une convulsion est un mouvement rapide et rythmé des muscles lorsqu'ils se contractent et se détendent de manière rythmique. Ce ne sont pas des mouvements tremblants, brisés ou lents; sont généralement inutiles sans direction de mouvement. Les convulsions partielles (focales) peuvent « errer », c'est-à-dire se déplacer (par exemple, du membre inférieur au membre supérieur)",
              "startFaceConvulsions": "La ou les crises ont-elles commencé / ont-elles commencé par des convulsions faciales ?",
              "startFaceConvulsionsDescription": "Les crises sont des mouvements rapides et rythmés des muscles lorsqu'ils se contractent et se détendent rythmiquement. Ce ne sont pas des mouvements tremblants, brisés ou lents; sont généralement inutiles sans direction de mouvement. Les convulsions partielles (focales) peuvent \"errer\", c'est-à-dire se déplacer, de la même manière - les convulsions faciales peuvent affecter tout le visage, une partie ou un côté.",
              "startBodyStiffness": "Est-ce que la ou les crise(s) débute(nt) par un raidissement généralisé du corps ?",
              "startBodyStiffnessDescription": "Cette raideur est appelée crise de phase/tonique, qui se traduit par une contraction tétanique et une tension extrême dans tous les muscles du corps.",
              "startLimbEjection": "La ou les crises ont-elles commencé / ont-elles commencé par une éjection des 4 membres ou une flexion soudaine ?",
              "startLimbEjectionDescription": "Cela se produit au début de certaines crises, principalement chez les enfants, et peut être un symptôme de crises dites de flexion.",
              "startEyeMovementDisorders": "Les troubles du mouvement oculaire (tels que ceux mentionnés ci-dessus) sont-ils le symptôme initial d'une ou de plusieurs crises ?",
              "startEyeMovementDisordersDescription": "Un nystagmus est un mouvement rapide de boutons, généralement dans des directions opposées. D'autres mouvements sont la rotation rapide des boutons ou leur rotation.",
              "startEyeTurnPernament": "Les globes oculaires peuvent-ils se tourner d'un côté au début d'une ou plusieurs crises ?",
              "startEyeTurnPernamentDescription": "Cette torsion peut être à gauche, à droite, de haut en bas. Cela peut être rapide et lent, il faut généralement un certain temps pour que les boutons reviennent à la position intermédiaire.",
              "startBodyTurn": "L'apparition d'une ou de crises peut-elle se manifester par un retour lent ou rapide du corps d'un côté (droit ou gauche) ?",
              "startBodyTurnDescription": "Cette phrase est souvent associée à la torsion du globe oculaire qui a été posée à la question précédente.",
              "startGroundFall": "Tombe-t-il au sol au début de la/des crise(s) ?",
              "startGroundFallDescription": "Une chute se définit comme un basculement plus ou moins brutal du patient qui peut être : « raide », « flasque », rapide et lent, glissant sur des objets, partiellement contrôlé ou incontrôlé.",
              "startBitTongue": "Vous mordez-vous la langue ou une partie de la bouche au début de la ou des crises ?",
              "startBitTongueDescription": "Habituellement - si une telle morsure doit se produire - elle se produit généralement au début d'une crise.",
              "startShout": "Le patient crie-t-il au début de la (des) crise(s) ?",
              "startShoutDescription": "Il s'agit d'un cri violent et généralement fort plus ou moins articulé ; ça peut être un son, ça peut être des mots simples",
              "startSounds": "Le patient fait-il des bruits ou des vocalisations au début de la ou des crises ?",
              "startSoundsDescription": "Cette fois, il s'agit de sons calmes et plus longs, de déclarations et de bruits composés ou complètement dénués de sens, chacun.",
              "startSpeechStop": "La parole s'arrête-t-elle au début de la crise ?",
              "startSpeechStopDescription": "C'est une situation dans laquelle le patient ne peut pas parler ou ne répond pas aux questions. Il est souvent impossible d'en déterminer la cause.",
              "startMotorAutomatisms": "Le patient réalise-t-il des automatismes moteurs simples ou complexes au début de la (des) crise(s) ?",
              "startMotorAutomatismsDescription": "Le patient peut faire sans le savoir des mouvements ou des gestes répétitifs, c'est ce qu'on appelle des automatismes.",
              "startImmobility": "Y a-t-il une immobilité ou une immobilité totale au début de la ou des crises ?",
              "startImmobilityDescription": "Le patient peut « mourir » en mouvement, ce qui fait partie du début de la crise.",
              "startLaugh": "Le malade peut-il rire au début de la crise/des crises ?",
              "startLaughDescription": "C'est un symptôme rare, le rire peut être violent, soudain ou lent à se développer.",
              "startChuck": "Y a-t-il des cliquetis, des claquements ou de la salivation au début de la ou des crises ?",
              "startChuckDescription": "C'est ce qu'on appelle des symptômes végétatifs, généralement pas réalisés.",
              "startCry": "Y a-t-il des pleurs au début de la ou des crises ?",
              "startCryDescription": "Ce cri peut être appelé un symptôme émotionnel dans les crises focales.",
              "startEpigastricSymptoms": "Développez-vous des symptômes épigastriques au début de la crise / des crises?",
              "startEpigastricSymptomsDescription": "Les symptômes comme celui-ci sont : une sensation de « vagabondage gastrique », de « débordement intestinal » ou une sensation de remplissage de l'estomac avec du contenu.",
              "startDejaVu": "Éprouvez-vous des délires temporels (deja vu et deja vecu) au début de la/des crises ?",
              "startDejaVuDescription": "Ce sont les symptômes de ce qu'on appelle le déjà-vu et le déjà-vu, c'est-à-dire le sentiment que « quelque chose s'est passé », « je l'ai déjà dit » ou « cette situation s'est déjà produite » ; il est possible de devenir irréel et de se sentir désorienté dans le temps.",
              "startVisualHallucinations": "Avez-vous des hallucinations visuelles au début de la ou des crises ?",
              "startVisualHallucinationsDescription": "De telles hallucinations (hallucinations) peuvent être simples et plus complexes : une personne peut voir quelque chose qui n'est pas là, des objets peuvent rétrécir et s'agrandir, un zigzag de différentes formes et couleurs peut apparaître, etc.",
              "startAuditoryHallucinations": "Avez-vous des hallucinations auditives au début de la crise ?",
              "startAuditoryHallucinationsDescription": "Ces hallucinations (hallucinations) peuvent être simples et plus complexes : vous pouvez entendre quelque chose qui n'est pas là, cela peut être des sons, des mots, des sifflements, etc.",
              "startFeelingFearOrAnxiety": "La crise commence-t-elle par des sentiments de peur ou d'anxiété ?",
              "startFeelingFearOrAnxietyDescription": "Ce sentiment peut survenir sans raison perceptible, généralement soudainement.",
              "startEyelidsMyoclonus": "La paupière est-elle myoclonique au début de la (des) crise(s) ?",
              "startEyelidsMyoclonusDescription": "La myoclonie des paupières est une déchirure rapide et rapide des paupières qui \"sautent\" généralement pendant quelques secondes, ce qui peut / peut ne pas être lié à des mouvements oculaires anormaux.",
              "startMyoclonus": "Y a-t-il une myoclonie au début de la crise/des crises ?",
              "startMyoclonusDescription": "La myoclonie est une secousse soudaine et violente (plus violente et généralement plus importante qu'une convulsion) d'un muscle général ou de certaines parties du corps ou de groupes musculaires.",
              "startLossConsciousnessPartially": "Perte partielle de conscience",
              "startLossConsciousnessPartiallyDescription": "La perte partielle de conscience était-elle le premier signe d'une crise ? Perturbé toute zone de conscience",
              "startRhythmicConvulsions": "Convulsions rythmiques",
              "startRhythmicConvulsionsDescription": "Ces convulsions étaient-elles le premier symptôme d'une crise rythmique ?",
              "startHyperkineticSymptoms": "Symptômes hyperkinétiques",
              "startHyperkineticSymptomsDescription": "Le premier symptôme de la crise était-il l'apparition de symptômes hyperkinétiques majeurs : pédalage, poussées pelviennes multidirectionnelles et similaires ?",
              "startAtony": "Atonie ou crise astatique de tout ou partie du corps",
              "startAtonyDescription": "Le premier symptôme de la crise était-il une atonie musculaire ou une crise astatique impliquant tout ou partie du corps (par exemple, juste la tête) ?",
              "startOtherAutomatisms": "Autres automatismes",
              "startOtherAutomatismsDescription": "Le premier symptôme de la crise était-il d'autres automatismes : corps entier, membres, persévérations vocales, automatismes sexuels, autres automatismes complexes ?",
              "startHeartSymptoms": "Symptômes cardiaques autonomes : palpitations/tachy/brady/asystolie",
              "startHeartSymptomsDescription": "Le premier symptôme de la crise était-il des symptômes autonomes cardiovasculaires : des palpitations, une tachycardie ou une bradycardie, ou une asystole ?",
              "startAutonomicSymptoms": "Symptômes autonomes supplémentaires : pâleur, bouffées vasomotrices, détresse respiratoire, mouvement anormal de la pupille, envie urinaire/fécale",
              "startAutonomicSymptomsDescription": "Le premier symptôme de la crise était-il d'autres symptômes autonomes : contenance, bouffées vasomotrices, détresse respiratoire, troubles des mouvements de la pupille, envie d'uriner/de matières fécales ?",
              "startLanguageIssues": "Dysphasie/Aphasie, Dyslexie/Alexie et/ou incompréhension de la parole/du langage",
              "startLanguageIssuesDescription": "Les premiers symptômes de la crise étaient-ils des troubles de la parole tels que la dysphasie/aphasie, la dyslexie/alexie et/ou le manque de compréhension de la parole/du langage ?",
              "startMemoryDisorders": "Troubles de la mémoire pendant la crise (autres sens normaux)",
              "startMemoryDisordersDescription": "Le premier symptôme de la crise était-il des troubles de la mémoire pendant la crise, avec préservation complète des autres sens ?",
              "startSomatosensorySymptoms": "Symptômes somatosensoriels focaux, le plus souvent paresthésies avec picotements et/ou engourdissements, picotements, chatouillements, rampants ou sensations de choc électrique.",
              "startSomatosensorySymptomsDescription": "Les premiers symptômes de la crise étaient-ils des symptômes somatosensoriels focaux, le plus souvent des paresthésies avec picotements et/ou engourdissements, des picotements, des chatouillements, des rampements ou une sensation de choc électrique ?",
              "startBodyDistortion": "Image corporelle déformée avec sensation de mouvement (par exemple, flotter) ou posture altérée (par exemple, mouvement de torsion) dans un membre immobile",
              "startBodyDistortionDescription": "Les premiers symptômes d'une crise étaient-ils une image corporelle déformée avec une sensation de mouvement (par exemple, flotter) ou une posture altérée (par exemple, un mouvement de torsion) dans un membre immobile ?"
            },
            "next": {
              "nextFaint": "Y a-t-il eu ou y a-t-il eu une perte de conscience pendant la crise (s'il y en a eu au début) ?",
              "nextFaintDescription": "L'inconscience peut se joindre ou continuer",
              "nextLossConsciousness": "Y a-t-il eu ou y a-t-il eu perte de conscience pendant la crise (s'il y en a eu au début) ?",
              "nextLossConsciousnessDescription": "La perte de conscience peut se joindre ou continuer",
              "nextBodyConvulsions": "Les crises ont-elles eu lieu maintenant ou l'ont-elles été dès le début ?",
              "nextBodyConvulsionsDescription": "Les crises peuvent avoir eu lieu tôt et persister, ou peuvent n'être apparues que maintenant.",
              "nextBodyPartConvulsions": "Les crises focales ont-elles commencé maintenant ou se poursuivent-elles comme elles ont commencé ?",
              "nextBodyPartConvulsionsDescription": "Les crises focales peuvent avoir eu lieu tôt et persister, ou n'être apparues que maintenant.",
              "nextFaceConvulsions": "Les contractions musculaires du visage se sont-elles produites maintenant ou se poursuivent-elles comme elles ont commencé ?",
              "nextFaceConvulsionsDescription": "Les convulsions faciales peuvent avoir eu lieu tôt et persister, ou peuvent ne pas être apparues jusqu'à maintenant.",
              "nextBodyStiffness": "Le raidissement de tout le corps est-il toujours en cours ou est-il devenu raide ?",
              "nextBodyStiffnessDescription": "Comme les convulsions, la raideur tonique pourrait se joindre ou continuer si c'était le cas.",
              "nextEyeMovementDisorders": "Si des mouvements oculaires pathologiques se sont joints ou sont toujours en cours",
              "nextEyeMovementDisordersDescription": "Comme les autres symptômes ci-dessus, ceux-ci peuvent apparaître ou durer.",
              "nextEyeTurnPernament": "Y a-t-il eu/y a-t-il un retour des potards sur l'un des 4 cotés ?",
              "nextEyeTurnPernamentDescription": "Comme symptômes jusqu'à présent: peut rejoindre ici ou dure comme cela a déjà été.",
              "nextBodyTurn": "Un virage à sens unique du corps s'est-il produit/est-il en cours ?",
              "nextBodyTurnDescription": "Comme les autres symptômes ci-dessus : ce symptôme est-il apparu ou est-il en cours ?",
              "nextGroundFall": "Y a-t-il eu une chute, et si c'était au début, le malade ment-il encore ?",
              "nextGroundFallDescription": "La chute - si c'était au début - peut avoir pris fin lorsque le patient se lève ou reste allongé.",
              "nextBitTongue": "Vous êtes-vous mordu la langue ou les parties de la bouche pendant la crise ?",
              "nextBitTongueDescription": "Si cela ne s'est pas produit au début de la crise ! Comme au début, pas maintenant !",
              "nextUrinating": "Avez-vous uriné pendant la crise?",
              "nextUrinatingDescription": "Le patient peut uriner sans le savoir pendant ou après une crise.",
              "nextDefecation": "Avez-vous eu une selle ou un sentiment d'urgence?",
              "nextDefecationDescription": "Tout comme l'urine - le patient peut évacuer des selles sans le savoir ou se sentir urgent d'évacuer des selles.",
              "nextShout": "Y a-t-il un cri pendant une crise?",
              "nextShoutDescription": "Si ce n'était pas un symptôme au début de la crise, il peut apparaître maintenant.",
              "nextSounds": "Y a-t-il eu ou y a-t-il (le cas échéant) des vocalisations ou des bruits verbaux pendant la crise ?",
              "nextSoundsDescription": "Le patient peut continuer à faire divers bruits, ou ils peuvent apparaître maintenant.",
              "nextAggression": "L'agressivité se développe-t-elle pendant la crise?",
              "nextAggressionDescription": "Des comportements agressifs ou des gestes du patient apparaissent-ils maintenant ?",
              "nextMotorAutomatisms": "Y a-t-il (le cas échéant) ou y a-t-il eu des automatismes pendant la crise ?",
              "nextMotorAutomatismsDescription": "Parfois, ce symptôme peut prendre beaucoup de temps.",
              "nextImmobility": "L'immobilité ou l'immobilité s'est-elle produite maintenant ou continue-t-elle depuis le début ?",
              "nextImmobilityDescription": "Comme les autres symptômes",
              "nextLaugh": "Y a-t-il des rires pendant la crise ?",
              "nextLaughDescription": "S'il n'était pas là au début de la crise, il peut apparaître maintenant.",
              "nextChuck": "Y a-t-il des cliquetis, des claquements ou un écoulement de salive pendant la crise/les crises ?",
              "nextChuckDescription": "S'ils sont antérieurs ou en cours, sinon, sont-ils arrivés maintenant ?",
              "nextCry": "A-t-il rejoint ou pleure-t-il?",
              "nextCryDescription": "Comme d'autres symptômes, il se peut que cela ait recommencé ou qu'il soit apparu maintenant.",
              "nextEpigastricSymptoms": "Les symptômes épigastriques surviennent-ils lors d'une ou de crises d'épilepsie ?",
              "nextEpigastricSymptomsDescription": "S'ils l'étaient, durent-ils, sinon - sont-ils arrivés ?",
              "nextDejaVu": "Est-ce que des idées délirantes liées au temps (deja vu et deja vecu) se produisent pendant une ou plusieurs crises ?",
              "nextDejaVuDescription": "Comment d'autres symptômes peuvent avoir été créés à partir de zéro ou attachés maintenant.",
              "nextVisualHallucinations": "Avez-vous des hallucinations visuelles pendant la ou les crises ?",
              "nextVisualHallucinationsDescription": "Sont-ils en cours ou ont-ils rejoint?",
              "nextAuditoryHallucinations": "Avez-vous des hallucinations auditives lors d'une ou plusieurs crises ?",
              "nextAuditoryHallucinationsDescription": "Sont-ils en cours ou ont-ils rejoint?",
              "nextEyelidsMyoclonus": "La myoclonie de la paupière se développe-t-elle lors d'une ou plusieurs crises ?",
              "nextEyelidsMyoclonusDescription": "Sont-ils en cours ou ont-ils rejoint?",
              "nextLossConsciousnessPartially": "Perte partielle de conscience",
              "nextLossConsciousnessPartiallyDescription": "La perte partielle de conscience était-elle le signe suivant d'une crise ? Perturbé toute zone de conscience",
              "nextRhythmicConvulsions": "Convulsions rythmiques",
              "nextRhythmicConvulsionsDescription": "Ces convulsions étaient-elles le symptôme suivant d'une crise rythmique ?",
              "nextHyperkineticSymptoms": "Symptômes hyperkinétiques",
              "nextHyperkineticSymptomsDescription": "Le symptôme suivant de la crise était-il l'apparition de symptômes hyperkinétiques majeurs : pédalage, poussées pelviennes multidirectionnelles et similaires ?",
              "nextOtherAutomatisms": "Autres automatismes",
              "nextOtherAutomatismsDescription": "Le symptôme suivant de la crise était-il d'autres automatismes : corps entier, membres, persévérations vocales, automatismes sexuels, autres automatismes complexes ?",
              "nextHeartSymptoms": "Symptômes cardiaques autonomes : palpitations/tachy/brady/asystole",
              "nextHeartSymptomsDescription": "Le symptôme suivant de la crise était-il des symptômes autonomes cardiovasculaires : des palpitations, une tachycardie ou une bradycardie, ou une asystole ?",
              "nextAutonomicSymptoms": "Symptômes autonomes supplémentaires : pâleur, bouffées vasomotrices, détresse respiratoire, mouvement anormal de la pupille, envie urinaire/fécale",
              "nextAutonomicSymptomsDescription": "Le symptôme suivant de la crise était-il d'autres symptômes autonomes ?",
              "nextLanguageIssues": "Dysphasie/Aphasie, Dyslexie/Alexie et/ou incompréhension de la parole/du langage",
              "nextLanguageIssuesDescription": "Les symptômes suivants de la crise d'épilepsie étaient-ils des troubles de la parole tels que la dysphasie/l'aphasie, la dyslexie/alexie et/ou le manque de compréhension de la parole/du langage ?",
              "nextMemoryDisorders": "Troubles de la mémoire pendant la crise (autres sens normaux)",
              "nextMemoryDisordersDescription": "Le symptôme suivant de la crise était-il des troubles de la mémoire pendant la crise, avec préservation complète des autres sens ?",
              "nextSomatosensorySymptoms": "Symptômes somatosensoriels focaux, le plus souvent paresthésies avec picotements et/ou engourdissements, picotements, chatouillements, rampants ou sensations de choc électrique.",
              "nextSomatosensorySymptomsDescription": "Les symptômes suivants de la crise étaient-ils des symptômes somatosensoriels focaux, le plus souvent des paresthésies avec picotements et/ou engourdissements, des picotements, des chatouillements, des rampements ou une sensation de choc électrique ?",
              "nextBodyDistortion": "Image corporelle déformée avec sensation de mouvement (par exemple, flotter) ou posture altérée (par exemple, mouvement de torsion) dans un membre immobile",
              "nextBodyDistortionDescription": "Les symptômes suivants d'une crise étaient-ils une image corporelle déformée avec une sensation de mouvement (par exemple, flotter) ou une posture altérée (par exemple, un mouvement de torsion) dans un membre immobile ?"
            },
            "end": {
              "endAnotherSeizure": "Cette crise peut-elle se transformer en une autre crise ?",
              "endAnotherSeizureDescription": "Une crise ne peut être isolée que telle que décrite, ou changer avec la fin pour une autre.",
              "endBodyConvulsions": "La crise se termine-t-elle par des crises généralisées ou sont-elles toujours en cours ?",
              "endBodyConvulsionsDescription": "S'ils l'étaient avant (au début et pendant ou joint pendant) ou à la fin de la crise.",
              "endBodyPartConvulsions": "Les crises focales sont-elles en cours ou apparaissent-elles à la fin ?",
              "endBodyPartConvulsionsDescription": "Les crises peuvent avoir eu lieu tôt et persister, ou peuvent n'être apparues que maintenant.",
              "endFaceConvulsions": "Les convulsions des muscles faciaux durent-elles ou apparaissent-elles à la fin ?",
              "endFaceConvulsionsDescription": "Comme les crises généralisées et focales.",
              "endEyeMovementDisorders": "La crise se termine-t-elle par des mouvements anormaux du cadran ?",
              "endEyeMovementDisordersDescription": "Ou continuent-ils comme avant ?",
              "endEyeTurnPernament": "La saisie se termine-t-elle en tournant les boutons sur l'un des 4 côtés ?",
              "endEyeTurnPernamentDescription": "Ou est-ce que ça se passe comme avant ?",
              "endBodyTurn": "La crise se termine-t-elle par un retournement du corps ?",
              "endBodyTurnDescription": "Ou est-ce que ça se passe comme avant ?",
              "endGroundFall": "Tombe-t-il à la fin d'une crise ?",
              "endGroundFallDescription": "S'il n'y avait pas eu de chute avant, cela peut-il arriver maintenant ? Ou le malade est-il toujours allongé comme s'il était tombé au début ou au milieu ?",
              "endUrinating": "Le patient a-t-il uriné à la fin de la crise ?",
              "endUrinatingDescription": "L'incontinence sphinctérienne survient-elle à la fin de la crise ?",
              "endDefecation": "À la fin de la crise, le patient a-t-il fait des selles sans le savoir ou est-il allé à la selle ?",
              "endDefecationDescription": "L'incontinence sphinctérienne survient-elle à la fin de la crise ?",
              "endSounds": "Y a-t-il eu une verbalisation à la fin de la crise ?",
              "endSoundsDescription": "Des vocalisations apparaissent-elles ou persistent-elles (le cas échéant) à la fin ?",
              "endAggression": "Y a-t-il eu agressivité à la fin de la crise ?",
              "endAggressionDescription": "L'agressivité peut-elle apparaître à la fin de la crise, ou se passe-t-elle comme avant ?",
              "endMotorAutomatisms": "Les comportements/mouvements automatiques se sont-ils produits à la fin de la crise ?",
              "endMotorAutomatismsDescription": "De tels automatismes sont-ils en cours ou sont-ils apparus maintenant ?",
              "endImmobility": "La saisie s'est-elle arrêtée ?",
              "endImmobilityDescription": "L'immobilité comme symptôme de fin de crise.",
              "endLaugh": "Le patient rit-il à la fin d'une crise ?",
              "endLaughDescription": "Comme les autres symptômes.",
              "endChuck": "Y a-t-il des cliquetis, des claquements ou un écoulement de salive à la fin de la ou des crises ?",
              "endChuckDescription": "Comme les autres symptômes.",
              "endCry": "Le patient pleure-t-il à la fin d'une crise ?",
              "endCryDescription": "Pleurer comme symptôme final.",
              "endVomit": "Le patient vomit-il à la fin d'une crise ?",
              "endVomitDescription": "Le vomissement peut être le contenu de l'estomac ou il peut être une envie de vomir."
            },
            "after": {
              "afterEyeMovementDisorders": "Les mouvements oculaires pathologiques sont-ils évidents après la crise ?",
              "afterEyeMovementDisordersDescription": "Il peut arriver que ce symptôme persiste même si la crise est terminée.",
              "afterEyeTurnPernament": "Y a-t-il un retour des globes oculaires après la crise?",
              "afterEyeTurnPernamentDescription": "Il peut y avoir des occasions où ce symptôme peut persister même si la crise est terminée.",
              "afterParesisOrParalysis": "Parésie/paralysie posturale",
              "afterparesisOrParalysisDescription": "Appelé Todds survient après les crises, affecte généralement 1 ou plusieurs membres, parfois sans conviction et disparaît dans les 24 heures.",
              "afterUrinating": "Le patient peut-il uriner/uriner après la crise ?",
              "afterUrinatingDescription": "La miction peut survenir après une crise.",
              "afterDefecation": "Le patient peut-il passer / déféquer après une crise?",
              "afterDefecationDescription": "Les selles peuvent très rarement se produire après une crise.",
              "afterConfusion": "L'EPI se produit-il après une crise?",
              "afterConfusionDescription": "Ce terme est utilisé pour décrire un trouble - généralement s'estompant rapidement - de la conscience ou son absence totale. Certains patients, cependant, sont immédiatement conscients et entièrement en contact après la crise.",
              "afterSleep": "Une crise est-elle suivie d'un DPP ?",
              "afterSleepDescription": "Les crises plus sévères sont suivies de ce type de sommeil, qui dure généralement une demi-heure.",
              "afterSalivaOrBloodySalivaFoam": "Y a-t-il de la mousse au niveau de la bouche après une crise : de la salive ou du sang coloré de sang ?",
              "afterSalivaOrBloodySalivaFoamDescription": "Il s'agit d'un symptôme de la salive \"compactée\" (elle mousse à cause de l'hyperventilation).",
              "afterHyperventilation": "L'hyperventilation se produit-elle après une crise?",
              "afterHyperventilationDescription": "Habituellement, l'hyperventilation est une conséquence de la dette d'oxygène due à l'apnée pendant la phase tonique.",
              "afterAggression": "Y a-t-il de l'agressivité après la crise?",
              "afterAggressionDescription": "L'agressivité peut être un symptôme de l'EPI.",
              "afterMotorAutomatisms": "Des automatismes peuvent-ils survenir après une crise ?",
              "afterMotorAutomatismsDescription": "Semblable à l'agression, cela pourrait être un symptôme d'EPI.",
              "afterVomit": "Le patient vomit-il après une crise?",
              "afterVomitDescription": "Le contenu de vomissement ou la collecte de vomissements peuvent survenir après une crise.",
              "afterHighTemperature": "La température corporelle augmente-t-elle après la crise?",
              "afterHighTemperatureDescription": "Cela peut arriver, cela pourrait être une hypothermie symptomatique.",
              "afterParesisOrParalysisDescription": "La parésie postcritique ou la paralysie de type Todd était-elle présente après la crise ?"
            },
            "feature": {
              "featureMany": "De telles crises peuvent-elles se produire en série / en groupe ?",
              "featureManyDescription": "Pour des situations de crises multiples ! Les crises ont tendance à être en série les unes après les autres ou sur une courte période de temps, parfois des séries de nuit ou de jour.",
              "featureDayOccurrence": "La crise a-t-elle eu lieu/les crises se sont-elles produites pendant la journée ?",
              "featureDayOccurrenceDescription": "Les crises éveillées sont des crises pendant la journée, nous supposons que le patient dort la nuit.",
              "featureNightOccurrence": "La crise s'est-elle produite/les crises se sont-elles produites la nuit ?",
              "featureNightOccurrenceDescription": "Nous supposons que les crises nocturnes surviennent pendant le sommeil.",
              "featureAwakeningOccurrence": "La crise a-t-elle eu lieu/les crises se sont-elles produites juste avant le réveil, pendant le réveil ou juste après le réveil ?",
              "featureAwakeningOccurrenceDescription": "Certains types ne se produisent que si.",
              "featureMorningOccurrence": "La crise/les crises se sont-elles produites/se produisent-elles le matin ?",
              "featureMorningOccurrenceDescription": "Il y a des moments où les premières heures du matin sont la seule saison des crises.",
              "featureDuration": "Combien de temps la crise a-t-elle duré (combien de temps les crises durent-elles habituellement) ?",
              "featureDurationDescription": "Sélectionnez l'heure par intervalle",
              "beforeSpecificStimuli": "Crise déclenchée par un stimulus spécifique",
              "beforeSpecificStimuliDescription": "La crise peut-elle être déclenchée par un stimulus ou une condition spécifique (FS, sons, manque de sommeil, émotions, stress, etc.) ?",
              "formChanges": "Crise variable et polymorphe",
              "formChangesDescription": "Cette crise change-t-elle de forme et de durée ? A ne pas confondre avec d'autres types de crises si elles sont simultanées !"
            },
            "fixCriticals": {
              "title": "Améliorer le vecteur d'une crise",
              "subtitle": "Crise confirmée",
              "description1": "Les réponses des patients qui diffèrent considérablement du vecteur de référence de crise sélectionné sont affichées ci-dessous.",
              "description2": "Demandez soigneusement la réponse au patient, s'il n'est pas d'accord avec la réponse de l'enquête 0 corrigez-la.",
              "oldAnswer": "Sondage 0 réponse",
              "newAnswer": "Nouvelle réponse",
              "saveConfirm": "Êtes-vous sûr de vouloir remplacer cette partie de l'enquête 0 ?",
              "saveSuccess": "Les modifications ont été enregistrées avec succès",
              "vectorChangedBy": "Vecteur modifié par"
            },
            "premonition": {
              "trailer": "Prémonition de saisie",
              "trailerDescription": "La crise est-elle précédée d'une prémonition - un sentiment d'une crise imminente dans les minutes/heures précédant la crise ? Sentiment/annonciation conscient ou inconscient."
            }
          },
          "Comorbidities": {
            "introTitle": "Comorbidités",
            "introSubtitle1": "Ici, nous voulons déterminer si vous avez des comorbidités.",
            "introSubtitle2": "N'oubliez pas que les maladies peuvent affecter l'évolution de l'épilepsie.",
            "dataSavedMsg": "Les informations sur les comorbidités ont été enregistrées",
            "hasHeadaches": "Maux de tête",
            "answerNo": "Rien",
            "answerYes": "Oui il y en a",
            "answerYesInt": "Oui",
            "disease": "Maladie",
            "typeDisease": "Entrez le code ou le nom de la maladie",
            "hasPregnancies": "Grossesse",
            "pregnancies": "Nombre de grossesses",
            "hasChildbirths": "Accouchements",
            "childbirths": "Nombre d'accouchements",
            "hasNeurologicalDiseases": "Maladies neurologiques",
            "hasInfectiousDiseases": "Maladies inflammatoires et infectieuses",
            "hasCancerDiseases": "Maladies cancéreuses",
            "hasImmuneDiseases": "Sang et maladies immunitaires",
            "hasEndocrineDiseases": "Maladies endocriniennes et métaboliques",
            "hasEyeDiseases": "Maladies des yeux",
            "hasLaryngologicalDiseases": "Maladies laryngologiques",
            "hasHeartDiseases": "Maladies du cœur et du système circulatoire",
            "hasRespiratoryDiseases": "Maladies respiratoires",
            "hasDigestiveDiseases": "Maladies du système digestif",
            "hasSkinDiseases": "Maladies de la peau",
            "hasBoneDiseases": "Maladies des os",
            "hasUrogenitalDiseases": "Maladies génito-urinaires",
            "hasDevelopmentalDefects": "Développement, anomalies congénitales et aberrations chromosomiques",
            "hasInjuriesOrPoisoning": "Blessures et empoisonnements",
            "somaticDiseases": "Le patient a des maladies qui affectent le traitement de l'épilepsie",
            "hasPsychiatricDiseases": "Maladies psychiatriques",
            "headaches": "Le patient a des maux de tête"
          },
          "DrugsHistory": {
            "introTitle": "Traitement",
            "introSubtitle1": "Voici l'historique de votre traitement jusqu'à aujourd'hui.",
            "introSubtitle2": "Entrez les médicaments que vous avez utilisés dans le passé et quel en a été l'effet. Chaque médicament porte le nom de la substance active sous forme complète et abrégée. Si vous ne vous en souvenez pas, ne vous inquiétez pas.",
            "introSubtitle3": "Il est important que vous choisissiez le bon nom de médicament.",
            "drug": "Médicament",
            "drugEffects": "Effets de la prise du médicament",
            "addDrug": "Ajouter un médicament",
            "removeDrug": "Retirer le médicament",
            "typeDrug": "Entrez le nom du médicament",
            "dataSavedMsg": "L'historique des traitements a été enregistré"
          }
        },
        "visitSurvey": {
          "stages": {
            "patientState": "État du patient",
            "patientOpinion": "L'avis du patient",
            "doctorOpinion": "L'avis du médecin"
          },
          "patientState": {
            "lastSeizureDate": "Date de la dernière saisie avant cette visite",
            "wellBeing": "Bien-être général depuis la dernière visite",
            "allSeizureRegistered": "J'ai inscrit/non inscrit mes crises dans le calendrier des événements depuis ma dernière visite",
            "additionalIncidents": "Les événements suivants se sont produits depuis la dernière visite - en dehors des événements répertoriés dans le calendrier des événements"
          },
          "patientOpinion": {
            "seizureCountByPatient": "Subjectivement, le nombre de saisies était-il le même ?",
            "seizureEvaluationByPatient": "Subjectivement, à quel point les crises étaient-elles graves",
            "currentTreatment": "Mon traitement actuel est",
            "sideEffects": "Effets secondaires du traitement actuel"
          },
          "doctorOpinion": {
            "seizureCountByDoctor": "Nombre de crises selon le médecin",
            "seizureEvaluationByDoctor": "La qualité des crises selon l'avis du médecin",
            "treatmentEfficiency": "L'efficacité du traitement de l'avis du médecin",
            "treatmentSafety": "Sécurité du traitement de l'avis du médecin"
          },
          "date": "Date",
          "fromDate": "De",
          "toDate": "À",
          "seizure": "Saisie",
          "save": "sauvegarder",
          "addSeizure": "Ajouter une saisie",
          "noEpisodeAdded": "Aucune saisie n'a été enregistrée",
          "episodeTypeLbl": "Types de saisie",
          "incidentDescription": "Description de la saisie",
          "noSeizure": "Pas de saisie",
          "next": "Prochain",
          "howManySeizures": "Combien de crises depuis la dernière visite",
          "countableLbl": "Il y avait",
          "unCountableLbl": "Il y en avait tellement qu'il n'est pas possible de donner le nombre exact",
          "seizuresMandatoryError": "Ajouter des dates de saisie",
          "add": "Ajouter",
          "close": "proche",
          "seizuresNotAddedSummary": "Saisies non ajoutées depuis la dernière visite",
          "seizuresDates": "Dates des saisies",
          "seizuresSinceLastVisit": "Nombre de saisies depuis la dernière visite",
          "per": "par",
          "addNewSeizureType": "Ajouter un nouveau type de crise",
          "addSeizureTypeDialog": {
            "title": "Ajout d'un nouveau type de crise",
            "name": "Nom de la saisie",
            "success": "Un nouveau type de saisie a été ajouté"
          },
          "seizurePeriod": "Précisez la période",
          "addPeriodicSeizure": "Ajouter une période",
          "deletePeriodicSeizure": "Supprimer la période",
          "until": "Jusqu'à",
          "seizuresInPeriod": "Les épisodes comptent"
        },
        "visitCertificates": {
          "create": "Créer un certificat",
          "doNotCreate": "Ne pas créer de certificat",
          "certificateType": "Type de certificat",
          "additionalRemarks": "Remarque additionnelle",
          "additionalRemarksPlaceholder": "Saisir des remarques supplémentaires",
          "generate": "Créer",
          "certificateContent": "Contenu du certificat :",
          "pickDate": "Choisis une date",
          "threeMonths": "3 mois",
          "sixMonths": "6 mois",
          "oneYear": "1 an",
          "date": "Date",
          "enterProcedureName": "Entrez le nom de la procédure",
          "enterActivityName": "Entrez le nom de l'activité",
          "validationError": "Champ obligatoire",
          "addNext": "Ajouter le certificat suivant",
          "otherDescriptionLbl": "Entrez la description du certificat",
          "endOfYear": "Fin d'année",
          "addCertificate": "Ajouter un certificat",
          "noCertificates": "La liste des certificats est vide"
        },
        "sickLeaves": {
          "title": "Les congés de maladie",
          "number": "Numéro",
          "dateFrom": "De",
          "dateTo": "À",
          "type": "Taper",
          "status": "Statut",
          "add": "Ajouter un congé de maladie",
          "addPopup": {
            "title": "Délivrer un nouveau congé de maladie",
            "addBtn": "Délivrer un arrêt maladie",
            "codes": {
              "a": "l'incapacité de travail s'est produite après une interruption n'excédant pas 60 jours - causée par la même maladie qui a causé l'incapacité de travail avant l'interruption",
              "b": "incapacité de travail tombant pendant la grossesse",
              "c": "incapacité de travail due à l'abus d'alcool",
              "d": "incapacité de travail due à la tuberculose",
              "e": "incapacité de travail causée par une maladie survenue au plus tard dans les 3 mois suivant la date de cessation de la couverture d'assurance maladie - dans le cas d'une maladie infectieuse dont la période d'incubation dépasse 14 jours ou d'une autre maladie dont les symptômes se manifestent après la période supérieure à 14 jours à compter de la date d'apparition de la maladie"
            },
            "insuredPersonTitle": "Données de la personne assurée pour laquelle la ZUS ZLA est délivrée",
            "type": "Raison de l'incapacité",
            "pesel": "Pessel",
            "fetchData": "Obtenir des données",
            "insuredIn": "Lieu d'assurance",
            "change": "Changer",
            "payersTitle": "Sélection des payeurs pour lesquels les certificats seront créés",
            "sickLeavesTitle": "Liste des ZUS ZLA précédentes de l'assuré",
            "detailsTitle": "Informations sur l'incapacité de travail",
            "inabilityFrom": "Incapacité de",
            "inabilityTo": "Incapacité à",
            "daysNumber": "Nombre de jours",
            "reason": "Raison",
            "careForData": "Données de la personne aidée",
            "relationship": "Parenté",
            "relationship1": "bébé",
            "relationship2": "conjoint, parents, beau-père, parent de l'enfant, grands-parents, beaux-parents, petits-enfants, belle-mère",
            "relationship3": "autre personne",
            "letterCode": "Lettre-code",
            "indication": "Indication médicale",
            "addressesTitle": "Adresse du séjour de l'assuré pendant la période d'incapacité de travail",
            "payerName": "Nom",
            "payerNip": "PINCER",
            "hasPue": "A-t-il/elle un profil PUE ?",
            "address": "Adresse",
            "inabilityRange": "Période d'incapacité",
            "disease": "Maladie",
            "number": "Série et numéro",
            "status": "Statut",
            "successToast": "Un congé de maladie a été délivré",
            "addPayerTitle": "Ajouter un nouveau payeur",
            "search": "Chercher",
            "payerNotFound": "Payeur introuvable",
            "addNewPayer": "Ajouter un nouveau payeur",
            "confirmIssueSickLeave": "Êtes-vous sûr de vouloir émettre un congé de maladie ?",
            "addNewAddress": "Ajouter une nouvelle adresse",
            "workPlace": "Lieu de travail",
            "icd10Note": "Noter! Le code principal icd 10 sera transmis à ZUS"
          },
          "cancelPopup": {
            "title": "Annulation d'un arrêt de travail",
            "reason": "Motif d'annulation",
            "confirmBtn": "Annuler un arrêt maladie",
            "confirmCancelSickLeave": "Êtes-vous sûr de vouloir annuler cet arrêt maladie ?",
            "successToast": "Les congés maladie ont été annulés"
          }
        }
      },
      "patientVisits": {
        "nextVisit": {
          "title": "Visites",
          "nextTitle": "Visites ultérieures",
          "changeDate": "Date de modification",
          "join": "Commencer la visite",
          "forego": "Annuler",
          "payFor": "Payer pour la visite"
        },
        "lastRecommendation": {
          "title": "Recommandation de la visite précédente",
          "pdf": "Télécharger le résumé",
          "drug": "Drogue",
          "dosages": "Posologie",
          "careLbl": "Prends soin de toi",
          "from": "de",
          "to": "à"
        },
        "visitList": {
          "title": "Archives des visites",
          "subtitle": "visites passées",
          "pdf": "Télécharger le PDF",
          "date": "Date",
          "type": "Taper",
          "category": "Catégorie",
          "doctor": "Docteur",
          "summary": "Recommandations",
          "invoice": "Facture d'achat"
        }
      },
      "password": {
        "reset": {
          "title": "Récupération de mot de passe",
          "subtitle1": "Revenir à",
          "subtitle2": "écran de connexion",
          "form": {
            "email": "Adresse e-mail",
            "send": "Confirmer"
          },
          "response1": "Un message a été envoyé à l'adresse e-mail indiquée avec le lien de confirmation de réinitialisation.",
          "response2": "Veuillez fermer cette fenêtre de navigateur et cliquer sur le lien envoyé.",
          "resendMessage": "Le lien pour le changement de mot de passe a été envoyé à nouveau"
        },
        "resetConfirm": {
          "title": "Confirmer le changement de mot de passe",
          "form": {
            "password": "Nouveau mot de passe",
            "passwordConfirm": "Confirmez le mot de passe",
            "send": "Confirmer"
          },
          "response": "Mot de passe changé.",
          "loginBtnLbl": "Connexion"
        }
      },
      "patient": {
        "account": {
          "register": {
            "confirm": {
              "outcomeSuccesMsg": "Inscription terminée avec succès",
              "outcomeSuccesDetMsg": "Cliquez sur le bouton ci-dessous pour accéder à la page de connexion",
              "outcomeFailureMsg": "Un problème est survenu lors de la tentative de confirmation de l'inscription",
              "outcomeFailureDetMsg": "Veuillez réessayer en cliquant sur le lien envoyé dans l'e-mail, ou contactez l'équipe Neuroterminal",
              "saveBtn": "Connexion"
            }
          },
          "settings": {
            "title": "Paramètres",
            "saveLbl": "sauver",
            "succesMsg": "Vos réglages ont été sauvegardés"
          }
        },
        "therapy": {
          "prescriptions": {
            "newLbl": "nouvelles ordonnances",
            "archiveLbl": "archives des ordonnances",
            "dateFromLbl": "Émise par",
            "dateToLbl": "Délivré à",
            "noDataLbl": "Aucune ordonnance",
            "previewLbl": "Aperçu",
            "prescriptionLbl": "Ordonnance",
            "headers": {
              "dateLbl": "Date",
              "doctorLbl": "Docteur",
              "numberLbl": "Code d'ordonnance",
              "actionsLbl": "Ordonnance"
            },
            "dialog": {
              "downloadLbl": "Télécharger",
              "closeLbl": "Fermer"
            }
          }
        }
      },
      "doctor": {
        "account": {
          "activation": {
            "title": "Activation du compte dans le système",
            "alreadySent": "Les documents ont déjà été envoyés",
            "alreadyFinished": "L'activation du compte est terminée",
            "fillInFormMsg": "Veuillez remplir les données ci-dessous et cliquez sur « Envoyer »",
            "formAgreementScanLbl": "Analyse de l'accord",
            "formInsuranceScanLbl": "Analyse d'assurance",
            "acceptTerms1": "J'accepte",
            "acceptTerms2": "conditions d'utilisation",
            "acceptPolicy1": "J'accepte",
            "acceptPolicy2": "politique de confidentialité",
            "sendLbl": "Envoyer",
            "submitMsg1": "Merci, nous vérifions vos données.",
            "submitMsg2": "Nous vous enverrons une confirmation par e-mail."
          },
          "settings": {
            "title": "Paramètres",
            "saveLbl": "sauver",
            "successMsg": "Paramètres enregistrés avec succès"
          }
        }
      },
      "prescriptions": {
        "home": {
          "titleLbl": "E-ordonnances",
          "text1Lbl": "Demande de prescription",
          "text2Lbl": "Aucune demande d'ordonnance"
        },
        "list": {
          "currentLbl": "Actuel",
          "archiveLbl": "Archivé",
          "showLbl": "Spectacle",
          "sortNewestLbl": "Le plus récent",
          "sortDeadlingLbl": "Le plus urgent"
        },
        "details": {
          "medHistLbl": "Antécédents médicaux",
          "pleadLbl": "demande une ordonnance",
          "peselLbl": "Pesel",
          "copyPeselLbl": "Copier le peloton",
          "checkEwusLbl": "Arrivée à l'EWUŚ",
          "addressLbl": "Adresse",
          "phoneLbl": "Numéro de téléphone",
          "issueDateLbl": "Dernière date d'émission de l'ordonnance",
          "viewLbl": "Aperçu de la prescription",
          "drugsLbl": "Médicaments demandés",
          "commentLbl": "Commentaire pour le docteur",
          "openLbl": "Ouvrir le créateur d'ordonnances électroniques",
          "denyInfoLbl": "Si vous décidez que cette demande est invalide, vous pouvez refuser la délivrance d'une e-prescription",
          "denyLbl": "Refuser",
          "prescriptionLbl": "Ordonnance",
          "confirmInfo1Lbl": "Refuser la demande de prescription",
          "confirmInfo2Lbl": "Êtes-vous sûr de vouloir refuser la délivrance de cette ordonnance ?",
          "confirmYesLl": "Oui, nier",
          "confirmNoLbl": "Non",
          "rejectInfoLbl": "Demande de prescription refusée",
          "forwardInfoLbl": "Les informations ont été envoyées à l'administrateur",
          "rejectBtn": "Rejeter la demande",
          "rejectDlgTitle": "Pourquoi voulez-vous rejeter la demande ?",
          "rejectReasonPlaceholder": "Motif du rejet",
          "forwardPrescription": "Si vous ne pouvez pas délivrer une ordonnance électronique au patient et que vous souhaitez que la réception s'occupe du dossier",
          "forwardBtn": "Rejeter l'ordonnance électronique",
          "forwardDlgTitle": "Entrez une note qui sera envoyée à la réception (admin)",
          "rejectCommentDisclaimer": "La raison du rejet sera envoyée au patient",
          "characterCount": "Nombre de caractères :",
          "infoForAdmin": "Le médecin a refusé de délivrer une ordonnance électronique :"
        },
        "creator": {
          "typeLbl": "Émettre une ordonnance électronique via",
          "returnLbl": "Revenir",
          "peselLbl": "Pesel",
          "copyPeselLbl": "Copier le peloton",
          "checkEwusLbl": "Arrivée à l'EWUŚ",
          "issueDateLbl": "Dernière date d'émission de l'ordonnance",
          "viewLbl": "Aperçu de la prescription",
          "drugsLbl": "Médicaments demandés",
          "commentLbl": "Commentaire pour le docteur",
          "addProductLbl": "Ajouter un autre produit",
          "govText1Lb": "Aller à la page",
          "govText2Lb": "et délivrer une ordonnance",
          "pinLbl": "Saisissez le code d'ordonnance à 4 chiffres",
          "fileLbl": "Fichier PDF de prescription (facultatif)",
          "fileChooseLbl": "Sélectionnez pour choisir le fichier",
          "issueLbl": "Émettre une ordonnance électronique",
          "prescriptionLbl": "Ordonnance",
          "notValidMsg": "Le contenu du formulaire de prescription est invalide",
          "successMsg": "L'ordonnance a été délivrée",
          "prescriptionList": "Les ordonnances délivrées ont les codes suivants"
        },
        "product": {
          "drugLbl": "Drogue",
          "removeLbl": "Supprimer",
          "packageLbl": "Paquet",
          "refundLbl": "Remboursement",
          "packNumLbl": "Nombre de colis",
          "dosageLbl": "Posologie actuelle",
          "refundCauseLbl": "Paiement",
          "recieptType": "Type de reçu",
          "permissions": "Autorisations supplémentaires",
          "description": "Dosage de description personnalisé",
          "dosage": "Dosage"
        },
        "ewus": {
          "checkingLbl": "Vérification de Pesel dans EWUŚ",
          "notFoundLbl": "Impossible de trouver le patient dans EWUŚ",
          "insuredLbl": "Le patient est assuré",
          "notInsuredLbl": "Le patient n'est pas assuré",
          "closeLbl": "Fermer"
        },
        "doctorPass": {
          "titleLbl": "Mot de passe du certificat ZUS",
          "cancelLbl": "Annuler",
          "confirmLbl": "Confirmer"
        }
      },
      "login": {
        "footer": {
          "z1": "Règlement d'organisation",
          "z2": "Réglementation pour la fourniture de services électroniques",
          "z3": "Politique de confidentialité de Neurosphère",
          "z4": "Politique de confidentialité du Neuroterminal"
        },
        "login": {
          "loginTitle": "Connectez-vous et prenez rendez-vous",
          "registerLinkMsg": "S'inscrire",
          "loginBtnLbl": "Connectez-vous à Neurosphera",
          "resetPassMsg": "mot de passe oublié?",
          "passwordFieldLabel": "Mot de passe",
          "loginEmailLabel": "E-mail",
          "registerMsg": "Vous n'avez pas encore de compte?"
        },
        "reset": {
          "title": "mot de passe oublié?",
          "subTitle": "À l'adresse saisie ci-dessous, nous enverrons un e-mail avec un lien pour définir un nouveau mot de passe",
          "content1": "Nous avons envoyé le lien pour le changement de mot de passe à :",
          "content2": "Vérifiez votre boîte de réception et cliquez sur le lien pour obtenir un mot de passe",
          "form": {
            "email": "E-mail",
            "send": "Confirmer"
          },
          "changePassword": "Changer le mot de passe",
          "recoverPassword": "Récupérer mot de passe",
          "response1": "Un message a été envoyé à l'adresse e-mail indiquée avec le lien de confirmation de réinitialisation.",
          "response2": "Veuillez fermer cette fenêtre de navigateur et cliquer sur le lien reçu par e-mail.",
          "sendAgainPrefix": "Vous n'avez pas reçu d'e-mail :",
          "sendAgain": "Envoyer à nouveau!",
          "contactReception": "ou contactez la réception :",
          "resendBlocked": "Si vous n'avez pas reçu d'e-mail avec le lien pour le changement de mot de passe, veuillez contacter le service client."
        },
        "resetConfirm": {
          "title": "Confirmer le changement de mot de passe",
          "form": {
            "password": "Nouveau mot de passe",
            "passwordConfirm": "Confirmez le mot de passe",
            "send": "Confirmer"
          },
          "response": "Mot de passe changé.",
          "loginBtnLbl": "Connexion"
        },
        "register": {
          "title": "Bienvenue au Neuroterminal",
          "subtitle1": "Créer un compte et prendre rendez-vous",
          "firstName": "Prénom",
          "lastName": "Nom de famille",
          "email": "E-mail",
          "password": "Mot de passe",
          "passwordConfirm": "Confirmez le mot de passe",
          "selectAll": "Tout sélectionner",
          "isApprove1": "J'ai lu les règles de traitement de mes données personnelles énoncées dans",
          "isApprove2": "Neurosphera sp. zoo. Politique de confidentialité",
          "isApprove3": "et",
          "isApprove4": "Politique de confidentialité de Neuroterminal S.A.",
          "maptitle": "Comment fonctionne le Neuroterminal ?",
          "mapSubtitle": "C'est très simple",
          "cards": [
            "Fill out the registration form to create account in Neurotermianl aplication.",
            "Receive an activation email to confirm your credentials",
            "Log into Neuroterminal and fill out the information in the patient profile.",
            "Choose the doctor and date of the first visit (visit ZERO)",
            "You will pay for it with ease using PayU (bank transfer, BLIK or credit card)",
            "For your convenience we will remind you about your visits by sending you SMS",
            "Each subsequent visit, no matter if it's remote or stationary, you will do it directly from Neuroterminal."
          ],
          "passwordRequirement": {
            "length": "8 signes",
            "upperCase": "haut de casse",
            "lowerCase": "minuscule",
            "digit": "chiffre",
            "sign": "signe spécial ( ! @ $ \\ - _ . * )"
          },
          "resendMessage": "Le lien d'activation a été envoyé à nouveau"
        },
        "confirm": {
          "registerSuccessMsg": "Inscription terminée avec succès",
          "passwordChangeSuccessMsg": "Le mot de passe a été changé avec succès",
          "goToLoginMsg": "Cliquez sur le bouton ci-dessous pour vous connecter à Neuroterminal, portail pour les patients de Neurosphera",
          "outcomeFailureMsg": "Il y a eu un problème avec la confirmation d'inscription",
          "outcomeFailureDetMsg": "Veuillez réessayer en cliquant sur le lien dans l'e-mail ou contactez l'équipe Neuroterminal",
          "gotTologinBtn": "Aller à la page de connexion",
          "passwordChangeFailedMsg": "Le lien pour définir votre mot de passe a expiré"
        }
      },
      "leadDoctor": {
        "noDoctor": "Le patient n'a pas de médecin responsable",
        "change": "Changer",
        "choose": "Choisissez un médecin",
        "dialogTitle": "Choisir le médecin responsable du patient",
        "genericError": "Une erreur est survenue",
        "assign": "Attribuer un médecin",
        "patientsNumber": "Nombre de patients",
        "assignNoteTitle": "Vous avez choisi {name} comme médecin responsable du patient. Le médecin sélectionné recevra la note suivante",
        "assignNoteContent": "Le docteur {name} vous a confié le patient. Passez en revue les antécédents médicaux du patient.",
        "assignNoteComment": "Si vous le souhaitez, vous pouvez ajouter un message supplémentaire. Si vous l'ajoutez, la note sera également partagée avec l'administrateur",
        "assignNoteDialogTitle": "Note pour le médecin sélectionné"
      },
      "eReferrals": {
        "creator": {
          "title": "Nouvelle référence électronique",
          "type": "Type de parrainage",
          "destination": "Clinique / laboratoire / service hospitalier",
          "icd10": "Diagnostic",
          "selectedIcd9": "Procédures médicales sélectionnées",
          "selectIcd9": "Choisissez au moins un acte médical",
          "icd9": "Procédure médicale",
          "comment": "Commentaire du docteur",
          "issueReferral": "Émettre une référence électronique",
          "icdNoResults": "Aucun résultat trouvé",
          "successMsg": "La recommandation électronique a été émise avec succès",
          "issueDate": "Date d'émission"
        }
      },
      "header": {
        "labelP1": "Portail",
        "labelP2": "Patient"
      },
      "epicrisis": {
        "treatmentStart": "Le patient est pris en charge par le Neurosphera depuis",
        "ictalDiagnosis": "Diagnostic ICTAL",
        "mainDiagnosis": "Diagnostic CIM10",
        "additionalDiagnosis": "Diagnostic complémentaire de la CIM10",
        "note0Header": "Remarque 0",
        "note0Label": "Observations initiales du patient - complétées lors de l'enquête 0 ou lors de la première visite du patient à Neurosphera",
        "epicrisisEntryHeader": "Entrées dans epicrisis",
        "epicrisisEntryLabel": "Il s'agit d'un endroit contenant des informations importantes sur la maladie du patient",
        "addEntryLabel": "Ajouter une entrée",
        "entrySavedMsg": "L'entrée a été enregistrée",
        "createdBy": "Créé par",
        "addedToObservation": "Ajouté à epicrisis",
        "migration": "migration"
      }
    },
    "component": {
      "consultation": {
        "diagnosisSelection": {
          "searchIcd10": "Recherche de diagnostic",
          "searchIcd10NoResult": "Aucun résultat trouvé",
          "empty": "La liste des diagnostics des patients est vide",
          "main": "Principale",
          "markAsMain": "Marquer comme principal",
          "icd10": "CIM 10"
        },
        "seizureDiagnosisSelection": {
          "first": "Saisies de premier type",
          "second": "Saisies de deuxième type",
          "third": "Saisies de troisième type"
        },
        "drugDosageDialog": {
          "standardTimes": {
            "morning": "Matin",
            "noon": "Midi",
            "evening": "Soirée"
          },
          "standardDose": {
            "morning": "Posologie matinale",
            "noon": "Dosage du midi",
            "evening": "Posologie du soir"
          },
          "addCustomDose": "Ajouter le dosage à l'heure spécifiée",
          "cancel": "Annuler",
          "save": "sauver"
        },
        "drugDosageEdition": {
          "drugs": "Des médicaments",
          "stage": "Étape",
          "searchDrug": "Ajouter un médicament",
          "searchDrugNoResult": "Aucun résultat trouvé",
          "dosage": "Choisissez la posologie",
          "cancelTherapy": "Annuler",
          "saveTherapy": "sauver la thérapie",
          "addTherapy": "ajouter l'étape suivante",
          "previousEndDateError": "Aucune date de fin définie pour l'étape précédente",
          "previousEndDateBeforeCurrentError": "La date de fin du traitement doit être postérieure à la date actuelle",
          "activeSubstanceSearch": "Recherche de médicaments contenant une substance active",
          "removeManyTitle": "Supprimer automatiquement le médicament des thérapies suivantes ?",
          "addManyTitle": "Ajouter automatiquement le médicament aux thérapies suivantes ?",
          "yesSameDosage": "Oui, avec le même dosage",
          "yesDifferentDosage": "Oui, mais avec le même dosage",
          "refreshOrder": "Actualiser la commande"
        },
        "drugTherapySummary": {
          "current": "Thrapy drogue actuelle",
          "future": "Futur traitement médicamenteux",
          "past": "Traitement médicamenteux antérieur",
          "from": "De",
          "to": "à"
        },
        "medicalHistory": {
          "chart": {
            "loadingLbl": "Chargement des données",
            "seizuresLbl": "Saisies",
            "drugsLbl": "Des médicaments",
            "resultsLbl": "Résultats",
            "visitsLbl": "Visites",
            "eventsLbl": "Événements",
            "consultationLbl": "Consultation",
            "seizuresNumberLbl": "Saisies",
            "seizuresDurationLbl": "Durée",
            "trendBtn": "Ligne de tendance",
            "disabledLbl": "Désormais inaccessible",
            "trendBetterLbl": "Meilleure tendance",
            "trendWorseLbl": "Tendance pire",
            "rangeLbl": "Plage de données"
          }
        },
        "patientSummary": {
          "personalInfo": {
            "personalData": "Données personnelles"
          }
        },
        "drugTherapyHistory": {
          "title": "Modification de l'historique des thérapies médicamenteuses",
          "changeHistory": "Thérapies passées",
          "noAccess": "Vous n'avez pas accès à la modification de l'historique des traitements médicamenteux pour ce patient",
          "updateSuccess": "L'historique des thérapies médicamenteuses a été mis à jour avec succès"
        }
      },
      "doctor": {
        "registration": {
          "form": {
            "firstName": "Nom",
            "lastName": "Nom de famille",
            "pesel": "Pesel",
            "pwz": "Numéro PWZ",
            "email": "Adresse e-mail",
            "password": "Mot de passe",
            "passwordConfirm": "Confirmez le mot de passe",
            "phone": "Téléphoner",
            "diploma": "Ajouter un diplôme scanné",
            "isSpecialization": "j'ai une spécialisation en neurologie",
            "isPractise": "Je dirige un cabinet médical privé",
            "practiceInfoName": "Nom de la pratique",
            "practiceInfoNipNr": "PINCER",
            "practiceInfoRpwdlNr": "Numéro RPWDL",
            "practiceInfoRegonNr": "REGON",
            "practiceAddressStreet": "rue",
            "practiceAddressHouseAndApartmentNr": "Maison / appartement non",
            "practiceAddressHouseNr": "numéro de maison",
            "practiceAddressApartmentNr": "Appartement non",
            "practiceAddressZipcode": "Code postal",
            "practiceAddressCity": "Ville",
            "practiceAddressCountry": "De campagne",
            "isApproveDataProcessing": "Je consens au traitement de mes données",
            "send": "Envoyer une demande",
            "response1": "Merci de votre inscription",
            "response2": "Nous vous contacterons par e-mail",
            "gotAccountMsg1": "Vous avez déjà un compte ?",
            "gotAccountMsg2": "Connexion"
          }
        },
        "database": {
          "doctor": {
            "list": {
              "noMedicalHistoryMsg": "Le formulaire d'antécédents médicaux n'a pas encore été rempli. Veuillez le remplir avec autant d'informations que possible avant de choisir un médecin de premier plan.",
              "gotoMedicalHistoryLbl": "Accéder au formulaire",
              "titleLbl": "Base de données des médecins",
              "titleDescLbl": "Si vous souhaitez changer de médecin principal, cliquez sur le bouton « Choisir » dans le profil du médecin que vous avez choisi, puis remplissez un court questionnaire.",
              "subtitleLbl": "Médecins",
              "patientTypeLbL": "Patients dirigés",
              "sortTypeLbL": "Trier par",
              "noResultsLbL": "Impossible de trouver des médecins correspondant aux paramètres de recherche",
              "sortTypes": {
                "patientsAsc": "Conduit les patients ascendant",
                "patientsDesc": "Conduit les patients descendant",
                "alphabeticAsc": "Par ordre alphabétique croissant",
                "alphabeticDesc": "Par ordre alphabétique décroissant",
                "remotePriceAsc": "Prix de la visite à distance croissant",
                "remotePriceDesc": "Tarif visite à distance décroissant",
                "stationaryPriceAsc": "Prix visite stationnaire ascendant",
                "stationaryPriceDesc": "Tarif visite stationnaire décroissant"
              },
              "content": {
                "juniorExpLbl": "Expert junior",
                "standardExpLbl": "Expert",
                "seniorExpLbl": "Expert principal",
                "leadLbL": "médecin en chef",
                "bioLbl": "BIO",
                "pricesLbl": "LISTE DE PRIX",
                "servicesLbl": "LA PORTÉE DES SERVICES",
                "remoteLbl": "Visite à distance",
                "stationaryLbl": "Visite à l'arrêt",
                "messageLbl": "Un message",
                "prescriptionLbl": "Ordonnance"
              }
            },
            "selection": {
              "request": {
                "alert": {
                  "youHaveTimeTo": "Vous devez répondre jusqu'à",
                  "toReplyTo": "à la demande de prise en charge du patient",
                  "detailsLbl": "Détails",
                  "closeLbl": "Fermer"
                }
              }
            }
          },
          "lead": {
            "titleLbl": "Médecin chef de file",
            "patientsLbl": "Les patients",
            "visitsLbl": "Visites",
            "firstVisitLbl": "Première",
            "subsequentVisitLbl": "Subséquent",
            "bioLbl": "Biographie",
            "bookVisitLbl": "Réserver une visite",
            "doctor": {
              "change": {
                "request": {
                  "dialog": {
                    "title1": "Confirmer la sélection du médecin principal",
                    "title2": "Êtes-vous sûr de vouloir changer de directeur principal ?",
                    "selectionConfirmMsg": "Êtes-vous sûr de vouloir choisir ce médecin?",
                    "historyCommentLbl": "Commentaire concernant vos antécédents de maladie",
                    "reasonLbl": "Pourquoi avez-vous décidé de changer de médecin ?",
                    "reasonCommentLbl": "Changer la raison",
                    "evaluationLbl": "Quelle note donneriez-vous à votre médecin actuel ?",
                    "overallCommentLbl": "Commentaires sur la coopération avec le médecin actuel",
                    "overallCommentInfo": "Cela nous permettra de contrôler la qualité des services fournis et de retirer du système les médecins sous-performants après en avoir vérifié les raisons.",
                    "confirmLbl": "Confirmer le changement de médecin principal",
                    "cancelLbl": "Annuler",
                    "reasons": {
                      "punctuality": "Le docteur n'est pas ponctuel",
                      "result": "Pas d'amélioration",
                      "communication": "Des problèmes de communication",
                      "other": "Autre"
                    },
                    "evaluations": {
                      "vgood": "très bien",
                      "good": "bon",
                      "average": "moyenne",
                      "bad": "mauvais"
                    },
                    "confirmationLbl": "Un message a été envoyé au médecin",
                    "closeLbL": "Fermer"
                  }
                },
                "response": {
                  "dialog": {
                    "title": "Acceptez-vous de devenir le médecin de référence pour ce patient ?",
                    "firstSeizureLbl": "Date de première saisie",
                    "epilepsyDescLbl": "Type d'épilepsie/crise",
                    "drugsUsedLbl": "Médicaments utilisés jusqu'à présent",
                    "patientCommentLbl": "Commentaire du patient",
                    "acceptLbl": "je suis d'accord",
                    "rejectLbl": "je ne suis pas d'accord",
                    "rejectReasonLbl": "Motif de rejet",
                    "confirmLbl": "Confirmer",
                    "cancelLbl": "Annuler",
                    "rejection": {
                      "reasons": {
                        "punctuality": "Le docteur n'est pas ponctuel",
                        "result": "Pas d'amélioration",
                        "communication": "Des problèmes de communication",
                        "full": "Pas de vacances",
                        "seizure": "Type de saisie non conforme à la spécialisation",
                        "age": "Profil d'âge non conforme",
                        "other": "Autre"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "patient": {
        "registration": {
          "form": {
            "email": "Adresse e-mail",
            "password": "Mot de passe",
            "passwordConfirm": "Confirmez le mot de passe",
            "isAgreeTerms1": "Je suis d'accord pour",
            "isAgreeTerms2": "conditions d'utilisation",
            "isAgreeTerms3": "",
            "isApprove1": "J'ai pris connaissance des règles de traitement de mes données personnelles énoncées dans",
            "isApprove2": "Neurosphère sp. zoo. Politique de confidentialité",
            "isApprove3": "et",
            "isApprove4": "Politique de confidentialité de Neuroterminal S.A.",
            "marketing1": "J'accepte de m'envoyer des communications marketing (y compris des informations commerciales), dont le but est de promouvoir des services et des biens, y compris la communication effectuée à l'aide de mes données personnelles à l'aide d'appareils de télécommunication dans le domaine de l'adresse e-mail (réception de messages e-mail) .",
            "marketing2": "J'accepte de m'envoyer une communication marketing (y compris des informations commerciales), dont le but est de promouvoir des services et des biens, y compris la communication effectuée à l'aide de mes données personnelles à l'aide d'appareils de télécommunication dans le domaine du numéro de téléphone (réception de SMS et d'appels entrants).",
            "marketing3": "Je souhaite recevoir une newsletter de Neuroterminal S.A. au nom de Neurosphera sp. zoo.",
            "send": "Créer un compte",
            "response1": "Merci de votre inscription",
            "response2": "Nous vous enverrons une confirmation d'inscription par e-mail",
            "gotoLoginLbl": "Connectez-vous au système",
            "gotAccountMsg1": "Vous avez déjà un compte ?",
            "gotAccountMsg2": "Connexion",
            "mandatoryDescription": "Les champs marqués d'une * sont obligatoires",
            "marketing1and2": "J'accepte de recevoir des communications marketing (y compris des informations commerciales), dont le but est de promouvoir des services et des biens, y compris les communications effectuées à l'aide de mes données personnelles en utilisant l'adresse e-mail (réception de messages électroniques) et le numéro de téléphone (réception de messages texte et appels entrants).",
            "consentsTitle": "Acceptation des réglementations et autorisations de commercialisation",
            "consentsAccept": "Accepter la sélection"
          },
          "confirmation": {
            "header": "Confirmez l'inscription !",
            "content1": "Nous avons envoyé le lien d'activation à :",
            "content2": "Vérifiez votre email et cliquez sur le lien d'activation",
            "sendAgainPrefix": "Vous n'avez pas reçu d'e-mail :",
            "sendAgain": "Envoyer à nouveau!",
            "contactReception": "ou contactez la réception :",
            "resendBlocked": "Si vous n'avez pas reçu d'e-mail avec le lien d'inscription, veuillez contacter le service client."
          }
        },
        "documentation": {
          "document": {
            "add": {
              "title": "Ajouter un document",
              "nameLbL": "Nom",
              "typeLbl": "Taper",
              "resultDateLbl": "Date de résultat",
              "confirmLbl": "Attacher",
              "filesToLargeLbl": "Certains fichiers étaient trop volumineux pour être ajoutés",
              "filesEmptyContentLbl": "Certains fichiers sont corrompus ou n'ont pas de contenu",
              "wrongFileType": "Type de fichier incorrect. PDF, images ou vidéo autorisés uniquement.",
              "unsavedFormWarningMsg1": "Il y a des données non enregistrées dans le formulaire",
              "unsavedFormWarningMsg2": "Souhaitez-vous télécharger les données avant de quitter l'écran",
              "yesLbl": "Oui",
              "noLbl": "Non",
              "uploadingMsg1": "Téléchargement de fichier en cours..",
              "uploadingMsg2": "En cas de fichiers plus volumineux, cela peut prendre un certain temps",
              "uploadingMsg1Templated": "Téléchargement de fichier en cours {filename}"
            },
            "confirm": {
              "title": "Supprimer le document ?",
              "cancelLbl": "Annuler",
              "confirmLbl": "Confirmer",
              "successMsg": "Document supprimé"
            },
            "edit": {
              "title": "Modifier le document",
              "nameLbL": "Nom",
              "typeLbl": "Taper",
              "resultDateLbl": "Date de résultat",
              "fileNameLbL": "Nom de fichier",
              "cancelLbl": "Annuler",
              "confirmLbl": "Confirmer",
              "changedMsg": "Document modifié avec succès"
            },
            "viewer": {
              "closeLbl": "Fermer",
              "typeNotSupported": "Ce type de fichier n'est pas pris en charge dans la visionneuse"
            },
            "files": {
              "selection": {
                "title1": "Glisser déposer",
                "title2": "fichiers que vous souhaitez ajouter ici",
                "title3": "ou alors",
                "title4": "sélectionnez les fichiers",
                "title5": "de l'ordinateur"
              }
            },
            "list": {
              "title1": "Documents médicaux",
              "title2": "Ici, vous pouvez conserver votre documentation médicale",
              "title3": "En ajoutant les résultats des tests, vous pouvez aider le médecin à établir un diagnostic précis et à améliorer les chances de traitement.",
              "noDataLbl": "Pas de données",
              "noResultsLbl": "Pas de date",
              "loadingLbl": "Chargement de la documentation",
              "downloadLbl": "Télécharger",
              "showLbl": "Spectacle",
              "editLbl": "Éditer",
              "removeLbl": "Supprimer",
              "headers": {
                "type": "Taper",
                "name": "Nom",
                "fileName": "Fichier",
                "resultDate": "Publié",
                "additionDate": "Ajoutée"
              },
              "filters": {
                "documentTypeLbl": "Spectacle",
                "documentTypeAllLbl": "Afficher tout",
                "resultDateFromLbl": "Date de résultat de",
                "resultDateToLbl": "Date de résultat au"
              },
              "markCompleted": "Marquer comme terminé",
              "markCompletedDescription": "Si vous marquez la description comme terminée, elle sera envoyée par la réception au patient. Si vous le souhaitez, vous pouvez ajouter un texte facultatif pour la réception",
              "isCompleted": "Complété"
            },
            "disk": {
              "space": {
                "filledLbl": "{filled} Go remplis sur {available} Go"
              }
            }
          }
        },
        "nearest": {
          "visits": {
            "forthcomingLbl": "visites à venir",
            "forthcomingConsultLbl": "(consultation)",
            "noVisitMsg": "Aucune visite à venir.",
            "noVisitZeroMsg": "Aucune visite à venir. Veuillez réserver une visite zéro.",
            "noVisitZeroDisclaimerMsg": "Lors de la visite zéro, un médecin analysera vos antécédents médicaux et établira votre plan de santé",
            "sosVisitLbl": "SOS",
            "bookVisitLbl": "Réserver une visite",
            "cannotBookVisitMsg": "Vous ne pouvez pas réserver une visite car tous les champs de la section 'Mes données' n'ont pas été remplis",
            "editLbl": "Éditer",
            "cancelLbl": "Annuler",
            "payLbl": "Payer",
            "dialog": {
              "paymentPending": "Nous attendons la confirmation du paiement",
              "paymentError": "Le paiement a échoué",
              "payTill1": "Vous devez payer jusqu'à",
              "payTill2": "sinon vous wisit sera annulé.",
              "pay": "Payer pour une visite"
            },
            "absentConsultation": "Consultation absente - sans la participation du patient"
          }
        },
        "treatment": {
          "plan": {
            "titleLbl": "plan de la santé",
            "inaccessibleLbl": "inaccessible",
            "noVisitZeroDisclaimerMsg": "Vous choisirez le plan de santé lors de la visite zéro."
          },
          "prescription": {
            "request": {
              "stockLbl": "Stock pendant {days} jours",
              "otherQuestLbl": "Besoin d'un autre médicament",
              "otherDrugLbl": "Tapez le nom du médicament dans le commentaire. N'oubliez pas de donner le nom exact du médicament.",
              "confirmTitleLbl": "Confirmation",
              "confirmTextLbl": "Vous serez redirigé vers la page des paiements électroniques. Continuer?",
              "cancelBtn": "Annuler",
              "confirmBtn": "Confirmer",
              "chooseDoctor": "Sélectionnez le médecin auquel vous souhaitez demander une ordonnance",
              "askForPrescriptionNotAvailable": "Vous pourrez envoyer une demande d'ordonnance après avoir consulté le médecin qui délivre les ordonnances",
              "noLeadDoctorAvailable": "Votre médecin traitant est actuellement injoignable. Vous pouvez adresser une demande de prescription au médecin de garde."
            }
          }
        },
        "lead": {
          "doctor": {
            "titleLbl": "médecin en chef",
            "noVisitZeroDisclaimerMsg1": "Vous choisirez votre médecin référent",
            "noVisitZeroDisclaimerMsg2": "pendant la visite zéro",
            "changeLbl": "Changement",
            "prescriptLbl": "Demander une ordonnance",
            "messageLbl": "Écrire un message",
            "servicesLbl": "Services fournis",
            "services": {
              "interview": "entrevue",
              "therapy": "thérapie de réglage",
              "commission": "commission d'émission",
              "prescription": "délivrer une ordonnance",
              "documents": "émettre une référence, un certificat, un congé de maladie"
            },
            "scope": {
              "bullet1": "Premier contact médical du patient",
              "bullet2": "Soignant complet du patient ordonnant des tests supplémentaires ou orientant éventuellement les consultants vers des spécialistes",
              "bullet3": "Conseiller, qui est le soutien direct du patient et supervise le plan thérapeutique",
              "bullet4": "Soutenu par toute l'équipe, le robot iCtal® et le système de supervision médicale et d'automatisation"
            }
          }
        },
        "hashtags": {
          "title": "Mots-clics",
          "subtitle": "C'est un endroit pour marquer le patient avec un hashtag prédéfini",
          "label": "Rechercher des hashtags"
        }
      },
      "finances": {
        "payment": {
          "history": {
            "title": "Historique de règlement",
            "headers": {
              "month": "Mois",
              "sum": "Total"
            }
          }
        },
        "invoice": {
          "summary": {
            "text1": "Résumé financier",
            "text2": "Vous y trouverez les données nécessaires à la facturation",
            "text3": "Des questions sur les factures ?",
            "text4": "Contactez notre service financier",
            "btn_lbl": "Télécharger le résumé"
          }
        },
        "general": {
          "summary": {
            "title": "La finance",
            "return": "Arrière",
            "table": {
              "headers": {
                "label": "Type de visite",
                "price": "Prix",
                "amount": "Montant",
                "sum": "Total"
              },
              "summary": "Total"
            }
          }
        }
      },
      "genericDialog": {
        "approve": "Oui",
        "cancel": "Non",
        "close": "Fermer"
      },
      "videochat": {
        "waitingForPatient": "En attente de connexion du patient",
        "waitingForDoctor": "En attente de connexion du médecin",
        "connectionError": "Une erreur de connexion s'est produite",
        "visitEnded": "La téléconférence est terminée. La session a été interrompue.",
        "accessDenied": "Veuillez autoriser l'accès au microphone et à la caméra dans le navigateur et actualiser la page si nécessaire",
        "accessDeniedAlert": "Pas d'accès au microphone et à la caméra",
        "missingAudioInput": "Le microphone est manquant",
        "missingVideoInput": "Appareil photo manquant",
        "tooltip": {
          "disableAudio": "Désactiver le son",
          "enableAudio": "Activer le son",
          "disableVideo": "Désactiver la vidéo",
          "enableVideo": "Activer la vidéo",
          "terminate": "Terminez votre visite en ligne"
        },
        "confirm": {
          "warningMsg1": "Visite toujours en cours",
          "warningMsg2": "Vous souhaitez mettre fin à une téléconférence pour une visite en cours ?"
        },
        "deviceLoadError": "Erreur de chargement des appareils",
        "accessGrantedBut": "Les autorisations ont été obtenues, cependant :",
        "cannotConnectCamera": "Impossible de se connecter à la caméra",
        "cannotConnectMicrophone": "Impossible de se connecter au microphone",
        "deviceAlreadyInUse": "Veuillez vous assurer que l'appareil n'est pas déjà utilisé, puis réessayez",
        "chooseDeviceDialog": {
          "chooseDevices": "Sélectionnez les appareils",
          "visitStartMsg1": "La visite commencera à",
          "visitStartMsg2": "Pour une réunion fluide, sélectionnez les appareils audio et vidéo que vous souhaitez utiliser",
          "camera": "Caméra",
          "microphone": "Microphone",
          "speakers": "Haut-parleurs"
        },
        "dialog": {
          "confirm": "Confirmer"
        }
      },
      "addMedicalEventDialog": {
        "title": "Ajouter un évènement",
        "descriptionPlaceholder": "Entrez une brève description",
        "patientSeizureTypePlaceholder": "Type de saisie",
        "from": "De",
        "to": "À",
        "duration": "Durée",
        "cancel": "Annuler",
        "approve": "sauver",
        "addNext": "Ajouter un autre",
        "delete": "Supprimer",
        "addSuccessMsg": "L'événement a été ajouté",
        "noSeizureTypes": "Pour ajouter une nouvelle crise, vous devez définir vos types de crise dans l'enquête de santé",
        "goToHealthSurvey": "Aller à l'enquête de santé",
        "serialSeizures": "Saisies en série",
        "seizuresCount": "Nombre de saisies"
      },
      "user": {
        "settings": {
          "sms": {
            "title": "Alertes SMS"
          },
          "other": {
            "title": "Autre"
          }
        }
      },
      "visitChat": {
        "title": "Visitez le chat",
        "loading": "Le chargement des messages est en cours...",
        "emptyList": "La liste des messages est vide",
        "inputLabel": "Écrire un message"
      },
      "payu": {
        "banner": {
          "installmentPaymentAvailable": "Le paiement échelonné est disponible ici",
          "startsFrom": "de",
          "decisionIn": "décision en",
          "decisionInSeconds": "{val} secondes",
          "checkConditions": "Vérifier les conditions"
        }
      },
      "updateMedicalEventDialog": {
        "title": "Modifier l'événement",
        "changesSaved": "Changements sauvegardés avec succès"
      },
      "maintenance": {
        "newAddress": "<b>Attention !</b> Nous changeons l'adresse de la succursale de Varsovie. Notre nouvelle adresse est <b>ul. Taneczna 30, 02-829 Varsovie (Ursynów)</b>"
      }
    },
    "ui": {
      "help": "Aider",
      "policy": "Politique de confidentialité",
      "logout": "Se déconnecter",
      "navigation": {
        "home": "Écran d'accueil",
        "account": {
          "name": "À propos de moi",
          "personalData": "Mon compte",
          "medicalDocuments": "Documents médicaux",
          "medicalHistory": "Enquête sur la santé"
        },
        "messages": "messages",
        "therapy": "Thérapie",
        "visits": {
          "name": "Visites",
          "archive": "Archives des visites",
          "doctorDatabase": "Base de données des médecins"
        },
        "myVisits": "Mes visites",
        "patients": "Les patients",
        "ediagnosis": "IctalLab",
        "calendar": "Calendrier",
        "workplan": "Plan de travail",
        "visitsAndDuties": "Visites et tâches",
        "finances": "Finances",
        "ehealth": {
          "name": "E-santé",
          "prescriptions": "E-ordonnances",
          "referrals": "Références électroniques"
        },
        "settings": {
          "name": "Paramètres",
          "notifications": "Notifications",
          "regulations": "Règlements",
          "payments": "Paiements"
        },
        "documentation": "Documentation",
        "examinations": "Examens"
      },
      "closeDialog": "Fermer",
      "visitSummary": "visite_résumé",
      "invoice": "facture d'achat",
      "correctionInvoice": "correction_facture",
      "ieAlert": "Désolé, nous ne prenons plus en charge Internet Explorer. Veuillez utiliser un autre navigateur Internet."
    },
    "components": {
      "workPlan": {
        "header": {
          "TIMETABLE": "Planifier",
          "TIMETABLE_CHANGES": "Calendrier des modifications"
        },
        "editDialog": {
          "workPosition": "Modifier la position du plan de travail",
          "unsavedWorkPosition": "Nouveau poste de plan de travail",
          "vacation": "Modifier les vacances",
          "unsavedVacation": "Nouvelles vacances",
          "dayOfWeekLabel": "Jour de la semaine",
          "officeLabel": "Bureau",
          "visitTypeLabel": "Type de visite",
          "startTimeLabel": "Heure de début",
          "endTimeLabel": "Heure de fin",
          "description": "La description",
          "cancel": "Annuler",
          "approve": "Approuver",
          "visitType": "Type de visite",
          "visitPlace": "Lieu de visite",
          "stationaryAndRemote": "Attention! Ce type de visite permet aux patients de désigner des visites fixes et à distance",
          "workPlaceMissing": "Pour activer les visites fixes, vous devez définir votre lieu de travail dans la page des paramètres"
        },
        "deleteDialog": {
          "text": "Êtes-vous sûr de vouloir supprimer cette position ?",
          "cancel": "Annuler",
          "confirm": "Supprimer"
        },
        "visitType": {
          "unavailable": "Devoir",
          "any": "N'importe quel type",
          "stationaryOrRemore": "Stationnaire / à distance"
        },
        "event": {
          "duty": {
            "yourDutyToChange": "Oznaczony jako do zmiany",
            "state": {
              "AVAILABLE": "Droit disponible",
              "NOT_AVAILABLE": "Droit non disponible",
              "TO_CHANGE": "Demande de reprise de fonction",
              "PLANNED": "Votre devoir prévu"
            }
          }
        },
        "description": "Planifiez votre travail - sélectionnez quand vous êtes disponible pour des visites",
        "selectedWorkPlan": "Plan de travail sélectionné :",
        "newWorkPlan": "Nouveau plan de travail :",
        "untilFurtherNotice": "jusqu'à nouvel ordre",
        "couldNotDeleteActiveWorkPlan": "Impossible de supprimer le plan de travail actif",
        "validFrom": "Valide à partir de",
        "validTo": "Valide pour",
        "resetChanges": "Réinitialiser",
        "saveChanges": "sauver",
        "unsavedChanges": "Il y a des modifications non enregistrées dans le calendrier",
        "visits": "Visites",
        "newPlan": "Nouveau plan",
        "messages": {
          "deleteSuccess": "Le plan de travail a été supprimé",
          "addSuccess": "Le plan de travail a été ajouté",
          "updateSuccess": "Le plan de travail a été mis à jour"
        }
      },
      "doctor": {
        "calendar": {
          "todayBtn": "Aujourd'hui"
        },
        "messages": {
          "title": "Messages actuels",
          "show": "Spectacle",
          "item": {
            "systemLbl": "Message système",
            "types": {
              "prescribeLbl": "Demande de délivrance d'une ordonnance de : {sender}",
              "selectionLbl": "Demande de devenir médecin principal de : {sender}",
              "noteLbl": "Remarque pour le patient : {patient} a été ajouté",
              "prescribeLblMovedFrom": "au lieu de {movedFrom}"
            }
          },
          "new": "Nouveau"
        },
        "visits": {
          "title": "Plan de jour",
          "show": "Spectacle",
          "types": "Type de visite",
          "categories": "Catégorie de visite",
          "type": {
            "remote": "Wizyty en ligne",
            "stationary": "Stacjonarne"
          },
          "item": {
            "statusPayed": "payé pour",
            "statusNotPayed": "pas payé",
            "statusDone": "terminé",
            "statusToMove": "bouger",
            "visitBtnLbl": "visite",
            "visitStartBtnLbl": "commencer la visite",
            "historyBtnLbl": "antécédents médicaux",
            "numberLbl": "Nombre de visites",
            "causeLbl": "Visite cause",
            "lastVisitLbl": "Derniere visite:",
            "diagnosisLbl": "Diagnostic",
            "diagnosisNoEpilepsyLbl": "Pas épileptique",
            "diagnosisNoDataLbl": "Pas de diagnostic",
            "drugsLbl": "Médicaments actuels",
            "drugsNoneLbl": "Rien",
            "frequencyLbl": "Fréquence des crises",
            "moodLbl": "Ambiance",
            "summaryLbl": "Résumé de la visite",
            "visitDuration": "Durée:"
          }
        },
        "form": {
          "pricelist": {
            "titleInfo": "Nouvel article de liste de prix",
            "cancelBtn": "Annuler",
            "saveBtn": "Confirmer"
          },
          "avatar": {
            "titleInfo": "Choisissez l'image",
            "addImageBtn": "Image du système de fichiers",
            "cancelBtn": "Annuler",
            "saveBtn": "Confirmer"
          },
          "address": {
            "streetLbl": "rue",
            "homeBuildingLbl": "Immeuble / appartement",
            "apartmentLbl": "Appartement non",
            "buildingLbl": "Bâtiment non",
            "cityLbl": "Ville",
            "zipcodeLbl": "Code postal",
            "countryLbl": "De campagne"
          }
        }
      },
      "message": {
        "history": {
          "title": "messages",
          "newMessage": "Nouveau message",
          "new": "Nouvelle",
          "search": "Chercher",
          "filters": {
            "current": "Actuel",
            "archive": "Archiver",
            "contact": "Chercher"
          },
          "noMessages": "Pas de messages",
          "born": "née",
          "historyBtnLbl": "Antécédents médicaux",
          "return": "Revenir",
          "send": "Envoyer",
          "sendAndClose": "Envoyer et fermer la visite",
          "hoursToAnswer": "h",
          "timeIsUp": "Le temps est écoulé",
          "hoursToAnswerRich": "Vous avez {hours} heures pour répondre",
          "timeIsUpRich": "Le temps déclaré pour une réponse est déjà écoulé. Donnez-le dès que possible!",
          "summary": "Résumé",
          "medicalHistory": "Antécédents médicaux",
          "sort": {
            "label": "Spectacle",
            "newest": "Le plus récent",
            "alpha": "Alphabétiquement",
            "deadline": "Le plus urgent"
          },
          "visitEnded": "terminé",
          "noTherapies": "Pas de thérapies",
          "list": "Liste des messages",
          "closeNoAnswer": "Message ne nécessitant pas de réponse",
          "noAnswerContent": "Merci pour votre message. Salutations",
          "confirmNoAnswer": "Voulez-vous vraiment mettre fin à la visite sans répondre ?",
          "daysToAnswer": "jours",
          "daysToAnswerRich": "Vous avez {days} jours pour répondre",
          "sendAndCloseDialog": {
            "header": "Êtes-vous sûr de vouloir mettre fin au fil ?",
            "title": "Si vous fermez la visite, le patient ne pourra pas répondre à votre message",
            "subtitle": "Si vous souhaitez que le patient puisse répondre à votre message, cliquez sur \"Envoyer\""
          },
          "newMessageOnDuty": "Nouveau message au médecin de garde",
          "unidirectional": {
            "send": "Envoyer un message unidirectionnel",
            "sendTitle": "Message unidirectionnel à",
            "sendSubtitle": "Le patient ne peut pas répondre à ce message",
            "sendSuccess": "Un message unidirectionnel a été envoyé au patient",
            "infoForPatient": "Ceci est un message - des informations d'un médecin. Dans ce message, votre médecin vous a fourni des informations supplémentaires sur votre maladie. Vous ne pouvez pas répondre au message, si vous souhaitez des informations supplémentaires, écrivez un nouveau message - consultez votre médecin"
          },
          "addToEpicrisis": "Ajouter à epicrisis",
          "textAddedToEpicrisis": "Entrée ajoutée à epicrisis"
        },
        "respondeDeadlineAlert": {
          "youHaveTimeTo": "Vous devez répondre jusqu'à",
          "toReplyTo": "au message de",
          "reply": "Réponse"
        },
        "patientAlerts": {
          "healthSurveyContent": "Votre enquête de santé doit être complétée",
          "healthSurvey": "Aller au sondage"
        },
        "newMessageVisit": {
          "noDoctorTitle": "Vous n'avez pas de médecin principal assigné",
          "title": "Consultez votre médecin - envoyez un message",
          "descriptionLbl": "La description",
          "description": "Le message de visite est une courte question envoyée au médecin. Votre médecin y répondra. Si vous souhaitez utiliser ce formulaire de contact, réglez votre visite-message en cliquant sur le bouton \"Nouveau message\". Si vous avez des inquiétudes concernant votre santé ou si vous avez besoin d'une consultation plus longue, veuillez prendre rendez-vous RÉGULIER avec votre médecin.",
          "messageVisitsDisabled": "Désolé, mais votre médecin ne fournit pas ce type de service.",
          "noLeadDoctorAvailable": "Votre médecin traitant est actuellement injoignable. Vous pouvez écrire un message au médecin de garde."
        },
        "drugStock": {
          "lowMsg": "Les stocks s'amenuisent pour les médicaments suivants :",
          "notInitialized": "Initialisez votre stock de médicaments pour :",
          "daysLbl": "journées"
        },
        "toast": {
          "text": "Nouveau message entrant de"
        },
        "newPaidMessageAlert": {
          "content": "Vous avez payé pour un message au médecin",
          "reply": "Écrire un message"
        },
        "unidirectionalMessageAlert": {
          "content": "Vous avez reçu un message - information d'un médecin",
          "reply": "Voir le message"
        },
        "personalDataAcceptanceAlert": {
          "title": "Confirmation des données personnelles",
          "subtitle": "Vos données personnelles dans le système Neuroterminal sont importantes - assurez-vous qu'elles sont à jour ! Grâce à cela, vous pourrez profiter pleinement de nos services.",
          "changeButton": "Modifiez vos données personnelles",
          "acceptButton": "Confirmer mes données personnelles"
        }
      },
      "utility": {
        "multipleSelect": {
          "all": "Tous"
        },
        "confirmationDialog": {
          "title": "Es-tu sûr?",
          "text": "Es-tu sur de vouloir continuer?"
        },
        "youAreNotAllowedDialog": {
          "title": "Autorisations requises",
          "text": "Tu n'as pas la permission. Contactez-nous pour plus d'informations.",
          "goBackToMainPage": "Retour à la page d'accueil"
        },
        "phoneInput": {
          "phoneNumberLabel": "Numéro de téléphone",
          "legalGuardPhoneNumberLabel": "Téléphone du parent ou du représentant légal"
        }
      },
      "health": {
        "state": {
          "noData": "Pas de données",
          "stateNoChange": "Pas de changement",
          "stateBetter": "Mieux",
          "stateWorse": "Pire"
        }
      },
      "calendar": {
        "title": "Visites et devoir",
        "description": "Vos visites pour la semaine en cours. Cliquez sur une visite pour en afficher les détails.",
        "visitCancelled": "La visite a été annulée",
        "visitDateChanged": "La date de la visite a été modifiée",
        "legend": {
          "duty": "devoir SOS",
          "vacation": "Vacances"
        },
        "filters": {
          "showVisits": "Afficher les visites",
          "showDuties": "Afficher les tâches"
        },
        "visitDetailsDialog": {
          "title": "Détails de la visite",
          "onDay": "sur",
          "fromHour": "de",
          "toHour": "à",
          "close": "Fermer"
        },
        "dutyDetailsDialog": {
          "title": "Détails du devoir",
          "fromHour": "de",
          "toHour": "à",
          "close": "Fermer",
          "signUpForDuty": "Inscrivez-vous au devoir",
          "markDutyToChange": "Demande de transfert de droits",
          "toChangeRequestText": "Demande un transger de devoir. Vous pouvez aider ce médecin en vous inscrivant à ce devoir pour lui.",
          "cantMarkDutyAsToChange": "Malheureusement, vous ne pouvez pas demander un transfert de cette obligation.",
          "dutyNotAvailable": "Ce devoir est déjà pris. Choisissez une autre date de service.",
          "cancelToChange": "Annuler la demande de transfert de droits"
        },
        "confirmVisitCancellationDialog": {
          "title": "Annulation d'une visite",
          "sureToCancelVisit": "Êtes-vous sûr de vouloir annuler la visite du patient",
          "fromDate": ", qui devait avoir lieu du",
          "from": "et continuer jusqu'à",
          "to": "à",
          "description": "Le patient sera invité à choisir une autre date ou le service le contactera.",
          "confirm": "Annuler la visite",
          "cancel": "Annuler",
          "visitAlert": "Vous annulez une visite qui doit commencer dans moins de 48 heures ! Nous ne soutenons pas de telles pratiques. Pour plus d'informations, veuillez consulter notre",
          "termsAndConditions": "termes et conditions",
          "fromDatePassed": ", qui a eu lieu à partir de",
          "fromPassed": "et a continué jusqu'à",
          "visitError": "Cette visite a déjà été effectuée. Assurez-vous que vous souhaitez vraiment annuler cette visite"
        },
        "visits": {
          "title": "Visites de médecins",
          "description": "Rendez-vous de médecins sélectionnés pour la journée. Cliquez sur un champ du calendrier pour afficher ses détails.",
          "dateSelectPrefix": "Jour sélectionné",
          "noVisits": "Aucune visite",
          "doctors": "Médecins"
        }
      },
      "duties": {
        "applyDutyDialog": {
          "title": "Édition des devoirs",
          "subtitle": "Assurez-vous que vos modifications sont correctes.",
          "addedDuties": "Tâches ajoutées :",
          "cancelledDuties": "Missions annulées :",
          "inHours": "sur",
          "back": "Retour à l'édition",
          "approve": "sauver",
          "acceptTermsAndConditions": "J'accepte les termes et conditions des devoirs"
        },
        "alert": {
          "text": "Vous avez un devoir à :"
        },
        "description": "Inscrivez-vous au service SOS. Sélectionnez une date qui vous convient en cliquant dessus dans le calendrier"
      },
      "payment": {
        "history": {
          "title": "Historique de paiement",
          "filters": {
            "typeAll": "Tous",
            "advFiltLbl": "Recherche Avancée",
            "advanced": {
              "from": "Date de début",
              "till": "jusqu'à",
              "to": "Date de fin",
              "clearLbl": "Dégager",
              "searchLbl": "Chercher"
            }
          },
          "noDataLbl": "Pas de données",
          "noResultsLbl": "Aucun résultat de recherche trouvé",
          "headers": {
            "date": "Date",
            "type": "Taper",
            "amount": "Montant",
            "invoice": "Facture d'achat",
            "service": "Nom du service"
          },
          "pdfLbl": "Télécharger",
          "invoice": "Facture d'achat",
          "correctionInvoice": "Correction"
        }
      },
      "dialog": {
        "generic": {
          "confirmation": {
            "dialog": {
              "defaultCancelLbl": "Annuler",
              "defaultConfirmLbl": "Confirmer"
            }
          },
          "attention": "Uwaga"
        }
      },
      "patient": {
        "visits": {
          "next": {
            "visit": {
              "info": {}
            }
          },
          "operations": {
            "cancel": {
              "title": "Confirmez-vous l'annulation de la visite ?",
              "warning": "Selon les conditions d'utilisation, les visites annulées dans les 48 heures précédant la date de la visite sont soumises à des frais administratifs de 50% du coût de la visite. Le montant restant sera retourné (la méthode de retour sera discutée).",
              "reason": "Motif de l'annulation de la visite (facultatif)",
              "confirmLbl": "Oui",
              "cancelLbl": "Non",
              "warningCustom": "Conformément aux conditions d'utilisation, les visites annulées dans les {days} jours précédant la date de la visite sont soumises à des frais administratifs de {percentage} % du coût de la visite. Le montant restant sera remboursé (le mode de remboursement sera discuté)."
            },
            "modify": {
              "title": "Sélectionnez une nouvelle date de visite",
              "confirmMsg": "Confirmez-vous le changement de date de visite?",
              "cancelLbl": "Annuler",
              "yesLbl": "Oui",
              "noLbl": "Non",
              "modifyDisabled": "Impossible de déplacer cette visite",
              "dateBefore": "Date précédente",
              "dateCurrent": "Date actuelle",
              "change": "Date de modification",
              "changeWarning": "Attention ! Le système permet à un patient de modifier la date/le médecin d'un rendez-vous une seule fois"
            }
          }
        }
      },
      "surveyZeroNote": {
        "addButton": "Remarque 0",
        "title": "Observations d'enquête 0",
        "addDialog": {
          "visitPurpose": "But de la visite 1",
          "visitPurposeDescription": "Demandez au patient à quoi sert le rendez-vous 1. Idéalement, la déclaration du patient devrait être saisie.",
          "surveyCompleted": "L'enquête 0 a-t-elle été complétée ?",
          "surveyCompletedDescription": "Si l'enquête 0 n'a pas été complétée ou si certaines des parties de l'enquête ont été complétées, veuillez fournir une raison.",
          "seizures": "Décrire les crises avec des mots",
          "seizuresEmpty": "Aucun type de crise défini",
          "chronicDiseases": "Le patient a-t-il d'autres maladies chroniques - principales ? (par exemple, autisme, accident vasculaire cérébral)",
          "chronicDiseasesDescription": "Maladies chroniques",
          "epilepsy": "Le patient a-t-il des antécédents familiaux d'épilepsie ou d'autres maladies neurologiques ?",
          "epilepsyDescription": "Décrivez brièvement les antécédents familiaux d'épilepsie ou d'autres maladies neurologiques",
          "eegCompleted": "Le patient a-t-il passé un test EEG ?",
          "mriCompleted": "Le patient a-t-il passé une IRM ?",
          "ctCompleted": "Le patient a-t-il passé un scanner ?",
          "geneticResearch": "Le patient a-t-il subi un test génétique ?",
          "relevantExaminations": "Le patient a-t-il fait d'autres études pertinentes ?",
          "otherMedicines": "Le patient prend-il actuellement des médicaments en dehors des DAE inclus dans le questionnaire 0 ?",
          "otherMedicinesDescription": "Décrire avec des mots les médicaments/substances que le patient prend",
          "allergies": "Le patient a-t-il des allergies aux médicaments, y compris aux antibiotiques ?",
          "allergiesDescription": "Décrire avec des mots à quels médicaments/substances le patient est allergique",
          "notes": "Notes complémentaires",
          "examinationDescription": "Décrivez les résultats des tests, le cas échéant",
          "additionalDescription": "Description du mot",
          "treatment": "Traitement jusqu'à présent",
          "treatmentDescription": "Description verbale",
          "specialDiet": "Existe-t-il un régime alimentaire particulier ?",
          "specialDietDescription": "Description verbale",
          "perinatalInterviewUnimportant": "Informations importantes",
          "birthDate": "Date d'accouchement (naissance)",
          "birthType": "Type de naissance",
          "pregnancyComplications": "Complications pendant la grossesse",
          "birthWeight": "Poids à la naissance",
          "birthLength": "Longueur à la naissance",
          "apgar": "Points d'Apgar (0-10)",
          "apgarFirst": "1 min",
          "apgarNext": "Mesure suivante",
          "birthAdditionalProcedures": "Des interventions supplémentaires ont-elles été réalisées au cours de la période périnatale ?",
          "birthPrematureAgeCorrected": "L’âge a-t-il été corrigé pour les bébés prématurés ?",
          "hasSiblings": "Le patient a-t-il des frères et sœurs ?",
          "sibling": "Personne",
          "siblingBirthDate": "Date de naissance",
          "siblingSameParents": "Sont-ils les mêmes parents ?",
          "siblingHealthy": "Est-il en bonne santé ?",
          "addSibling": "Ajouter une autre personne",
          "geneticPredisposition": "Existe-t-il une prédisposition environnementale, génétique/héréditaire et des antécédents familiaux ?",
          "developmentCorrect": "L’évolution actuelle est-elle correcte ?",
          "retardationDetermined": "A-t-on déterminé dans quelle mesure il est retardé par rapport à son âge ?",
          "retardationExamined": "Y a-t-il eu un examen spécialisé dans ce sens ?",
          "nystagmus": "Le patient présente-t-il un nystagmus ?",
          "vision": "Vue",
          "hearing": "Audience",
          "tuxedo": "Fumer des cigarettes",
          "alcohol": "Alcool",
          "narcotics": "Médicaments",
          "leadingDisease": "Quelle est la maladie principale du patient ?",
          "leadingDiseaseDominantSymptoms": "Symptômes dominants de la maladie",
          "leadingDiseaseLasting": "Depuis combien de temps la maladie dure-t-elle ?",
          "accompanyingSymptoms": "Symptômes accompagnants : le patient souhaite-t-il ajouter quelque chose ?",
          "next": "Suivant",
          "previous": "Dos",
          "saveAndClose": "Enregistrer et fermer",
          "sections": {
            "surveyZero": "Enquête 0",
            "perinatalInterview": "Entretien périnatal",
            "development": "Développement",
            "familyInterview": "Entretien de famille",
            "treatment": "Traitement",
            "comorbidities": "Comorbidités",
            "medicalExams": "Examens médicaux",
            "others": "Autre"
          }
        },
        "preview": {
          "userCreated": "Créé par",
          "seizures": "Description des saisies saisies",
          "chronicDiseases": "Le patient a-t-il d'autres maladies majeures ?",
          "epilepsy": "Entretien familial",
          "eegCompleted": "Test EEG",
          "mriCompleted": "Examen IRM",
          "ctCompleted": "Examen CT",
          "geneticResearch": "Test génétique",
          "relevantExaminations": "Autres recherches",
          "otherMedicines": "Médicaments autres que les médicaments LPP",
          "allergies": "Allergies médicamenteuses",
          "addToObservations": "Ajouter aux observations",
          "addFragmentToObservations": "Ajouter le fragment sélectionné pour faire des observations",
          "addWholeToObservations": "Ajouter une note complète aux observations"
        }
      },
      "medicalHistoryPrintout": {
        "download": "Télécharger l'historique médical",
        "withObservations": "Version avec observations",
        "withoutObservations": "Version sans observations",
        "downloadHistory": "Afficher l'historique des téléchargements",
        "history": {
          "title": "Historique des téléchargements des antécédents médicaux du patient",
          "timeDownload": "Date de téléchargement",
          "userDownload": "Téléchargeur",
          "observations": "Contient des observations"
        },
        "parametersPopup": {
          "title": "Télécharger l'historique médical",
          "subtitle": "Sélectionnez la période à partir de laquelle vous souhaitez télécharger l'historique médical",
          "allHistory": "toute l'histoire du système Neuroterminal",
          "selectedRange": "histoire d'une époque précise",
          "download": "Télécharger",
          "cancel": "Annuler"
        }
      },
      "patientExaminations": {
        "chooseExaminationLbl": "Sélectionnez l'examen pour lequel vous souhaitez compléter la description",
        "eeg": "Examen EEG",
        "addEegDescription": "Ajouter une description de l'examen EEG",
        "eegDescriptionHeader": "Description de l'examen EEG",
        "describingDoctor": "Créateur de description",
        "months": "mois",
        "removeEegTitle": "Supprimer la description de l'examen EEG",
        "removeEegContent": "Etes-vous sûr de vouloir supprimer cette description"
      }
    },
    "common": {
      "sex": {
        "man": "Hommes",
        "woman": "Femme",
        "nondefined": "Non défini"
      },
      "role": {
        "patientLbl": "patient",
        "doctorLbl": "docteur"
      },
      "years": " | année | {n} ans | {n} ans",
      "visit": {
        "paid": "payé",
        "additional": "Additionnel",
        "toMove": "bouger",
        "confirmed": "confirmé",
        "done": "terminé",
        "cancelled": "annulé",
        "notPaid": "impayé",
        "messagePaid": "en cours"
      },
      "bornAbbr": "née",
      "months": "| 1 mois | {n} mois | {n} mois",
      "days": "| 1 jour | {n} jours | {n} jours",
      "confirm": "Confirmer",
      "cancel": "Annuler",
      "select": "Sélectionner"
    },
    "views": {
      "profile": {
        "welcome": "BIENVENUE !",
        "choose": "Se connecter en tant que:",
        "doctor": "docteur",
        "patient": "patient"
      },
      "login": {
        "loginFldLbl": "E-mail",
        "passwordFldLbl": "Mot de passe",
        "loginBtnLbl": "Connexion",
        "loggingAs": "Vous êtes connecté en tant que",
        "wantToLoginAs": "Je veux me connecter en tant que",
        "registerMsg": "Vous n'avez pas encore de compte?",
        "registerLinkMsg": "Créer un compte",
        "resetMsg": "Vous ne vous souvenez pas de votre mot de passe ?",
        "resetLinkMsg": "Réinitialiser le mot de passe",
        "return": "revenir"
      },
      "register": {
        "patientLbl": "Patient",
        "doctorLbl": "Docteur",
        "loginFldLbl": "Connexion",
        "newPassFldLbl": "Mot de passe",
        "newPassConfFldLbl": "Confirmez le mot de passe",
        "firstNameFldLbl": "Prénom",
        "secNameFldLbl": "Nom de famille",
        "lastNameFldLbl": "Nom de famille",
        "emailFldLbl": "E-mail",
        "workCertNrFldLbl": "N° de certificat",
        "confirmBtnLbl": "Confirmer",
        "backToLoginPageLbl": "Retour à la page de connexion"
      },
      "reset": {
        "loginFldLbl": "Nom d'utilisateur",
        "newPasswordLbl": "Nouveau mot de passe",
        "newPasswordConfirmLbl": "Confirmer le nouveau mot de passe",
        "confirmBtnLbl": "Confirmer",
        "backToLoginPageLbl": "Retour à la page de connexion"
      },
      "dashboard": {
        "welcomeMessage": "Utilisateur connecté : {userName}",
        "enableEditable": "Confirmer la vue",
        "disableEditable": "Gérer la vue"
      },
      "visits": {
        "titleLbl": "Mes visites",
        "noDataLbl": "Aucune visite",
        "noResultsLbl": "Aucune visite trouvée",
        "filters": {
          "visitTime": {
            "all": "Tous",
            "future": "Avenir",
            "past": "Passé"
          },
          "searchPatientLbl": "Recherche de patient",
          "advancedFiltersLbl": "Recherche Avancée",
          "advancedFilters": {
            "from": "Dater de",
            "betweenDatesLbl": "faire",
            "till": "Date jusqu'à",
            "type": "Type de visite",
            "category": "Catégorie de visite",
            "all": "Tous",
            "clearBtn": "dégager",
            "searchBtn": "chercher"
          }
        }
      },
      "account": {
        "common": {
          "requiredMsg": "Les champs obligatoires sont marqués d'un astérisque",
          "clearBtn": "Dégager",
          "editBtn": "Éditer",
          "saveBtn": "Confirmer",
          "yes": "Oui",
          "no": "Non",
          "woman": "femme",
          "man": "homme",
          "nondef": "non défini"
        },
        "home": {
          "myInfoLbl": "Mes infos",
          "generalInfoLbl": "Informations générales",
          "accountInfoLbl": "Informations pratiques",
          "pricelistInfoLbl": "Données financières",
          "incompleteFormWarningMsg1": "Tous les champs n'ont pas été remplis",
          "incompleteFormWarningMsg2": "Voulez-vous vraiment enregistrer un formulaire incomplet ?"
        },
        "general": {
          "basicInfoLbl": "Informations de base",
          "avatarLbl": "Avatar",
          "nameLbl": "Nom",
          "lastNameLbl": "Nom de famille",
          "sexLbl": "Sexe",
          "emailLbl": "E-mail",
          "phoneLbl": "Numéro de téléphone",
          "pwzLbl": "Numéro PWZ",
          "dataSavedMsg": "Informations générales enregistrées"
        },
        "practise": {
          "basicInfoLbl": "Autorisations, services fournis, support scientifique",
          "type": "Niveau",
          "consultationType": "Type de consultation",
          "academicTitle": "Titre académique",
          "specialization": "Spécialisation",
          "workPlace": "Lieu de travail",
          "epileptologyCert": "Certificat d'épileptologie",
          "nfzContract": "Contrat NFZ",
          "testResDescriptions": "Descriptions des résultats des tests",
          "consultations": "Consultations",
          "scientificWork": "Travail scientifique",
          "scientificSocieties": "Sociétés scientifiques",
          "www": "page WWW",
          "bio": "Biographie",
          "certificate": "Certificat ZUS",
          "chooseFile": "Choisir le fichier",
          "patientsInfoLbl": "Les patients",
          "newPatients": {
            "label": "Nouveaux patients",
            "accept": "Admet",
            "noaccept": "n'admet pas"
          },
          "acceptedPatients": "Patients acceptés",
          "leadPractiseInfoLbl": "Informations sur les pratiques de pointe",
          "workplaceName": "Nom du lieu de travail",
          "workplacePosition": "Position",
          "communicators": "Communicateurs",
          "usernameMessanger": "Nom d'utilisateur Messenger",
          "usernameWhatsup": "Nom d'utilisateur Whatsapp",
          "usernameSkype": "Nom d'utilisateur Skype",
          "usernameHangouts": "Nom d'utilisateur Hangouts",
          "dataSavedMsg": "Informations sur l'entraînement enregistrées",
          "neuroscreenDiagnoses": "Maladies promues",
          "partner": "Partenaire NSPH"
        },
        "pricelist": {
          "services": "Prestations de service",
          "visitsLbl": "Visites",
          "othersLbl": "Les autres",
          "systemItemsLbl": "Éléments système",
          "systemItemsDescription": "Ces services sont définis globalement par Neuroterminal",
          "descriptionsLbl": "Descriptions des résultats des tests",
          "table": {
            "statusLbl": "Statut",
            "activeLbl": "actif",
            "inactiveLbl": "Inactif",
            "nameLbl": "Nom du service",
            "durationLbl": "Durée",
            "priceLbl": "Prix",
            "paymentLbl": "Votre payement",
            "perHourLbl": "par heure",
            "dutyLbl": "Devoir"
          },
          "bankAccount": "compte bancaire",
          "practiceName": "Nom du cabinet privé",
          "nip": "Pincer",
          "regon": "Régon",
          "dataSavedMsg": "Informations sur la liste de prix enregistrées",
          "visitsDescription": "Fixez des tarifs pour des types de visites particuliers.",
          "othersDescription": "Fixer des tarifs pour d'autres services non regroupés par types de patients comme des messages ou des documents médicaux",
          "choosePatientTypes": "Vous devez sélectionner au moins un type de patient pour définir la liste de prix des visites",
          "widgetVisibility": "Visible dans le widget de nomination"
        }
      },
      "patientDashboard": {
        "aboutMe": "À propos de moi",
        "myData": "mes données",
        "myDataEdit": "Éditer",
        "myDataFill": "Remplir",
        "myDataShow": "Spectacle",
        "medicalDocuments": "documentation médicale",
        "healthQuestionnaire": "questionnaire de santé",
        "visitsLbl": "Visites",
        "eventCalendar": "Calendrier des événements",
        "addEventLbl": "Ajouter",
        "seizure": "Saisie",
        "infection": "Infection",
        "fever": "Fièvre",
        "insomnia": "Insomnie",
        "menstruation": "Menstruation",
        "pregnancyStart": "Début de la période de grossesse ou de pré-grossesse",
        "pregnancyEnd": "Fin de la période de grossesse ou de pré-grossesse",
        "chooseLeadDoctor": "Choisir le médecin principal",
        "alert": {
          "yourVisitWithDoctor": "Votre visite chez le médecin",
          "startsAt": "commence à",
          "lastsFrom": "dure de",
          "join": "Rejoindre",
          "visitToMove": {
            "first": "Votre visite à",
            "second": "a été annulé",
            "makeNewAppointment": "Choisissez une nouvelle date"
          },
          "myData": {
            "label": "Pour utiliser le compte du Patient, remplissez le formulaire de données personnelles.",
            "action": "Accéder au formulaire"
          },
          "prescriptionsToRead": {
            "label": "Le médecin a délivré une ordonnance. Pour afficher le code PIN, accédez à l'onglet Thérapie.",
            "action": "Afficher les ordonnances"
          },
          "selectLeadDoctor": {
            "label": "Sélectionnez votre médecin principal.",
            "action": "Sélectionnez un médecin"
          },
          "doctorRejectedRequest": "{doctorName} n'a pas accepté de devenir votre médecin principal. Raison : {reason}"
        },
        "activation": {
          "welcomeLbl": "Bienvenue au Neuroterminal",
          "init1Lbl": "Content que tu sois avec nous.",
          "init2Lbl": "Avant la première visite, renseignez les informations sur votre état de santé.",
          "init3Lbl": "mes données",
          "init4Lbl": "documentation médicale",
          "init5Lbl": "Alors réservez la visite zéro.",
          "nextLbl": "Prochain"
        },
        "therapy": "thérapie actuelle",
        "noCurrentTherapies": "Pas de thérapie en cours",
        "drugStock": "stock de médicaments",
        "drugStockDescription": "Cliquez sur « Mettre à jour » pour fournir votre stock de médicaments actuel.",
        "supplyFor": "approvisionnement pendant {days} jours",
        "amountUndefined": "le montant n'est pas défini",
        "supplyEndsIn": "se termine dans {days} jours",
        "medicineLbl": "Médicament",
        "runningOutStocks": "Votre stock s'épuise. Demandez à votre médecin traitant de vous délivrer une ordonnance.",
        "update": "Mettre à jour",
        "save": "sauver",
        "askForPrescription": "Demander une ordonnance",
        "updateDrugStockLbl": "Mise à jour du stock de médicaments",
        "updateDrugStockLblDesc": "Comptez votre stock actuel de médicaments et mettez une valeur dans le champ ci-dessous.",
        "drugStockUpdateHelpLbl": "Entrez les stocks de médicaments à partir du début d'aujourd'hui. Il sera mis à jour automatiquement chaque matin avec les dosages de votre traitement médicamenteux actuel.",
        "drugStockUpdated": "Stocks de médicaments pour {products} mis à jour avec succès",
        "drugStockRecalculationFailed": "Échec de la mise à jour automatique du stock de médicaments. Vous pouvez mettre à jour votre stock de médicaments manuellement.",
        "referrals": {
          "title": "Recommandation du médecin",
          "appoint": "Nommer"
        },
        "nonepileptic": {
          "infoMsg": "Toutes les consultations sont des visites ponctuelles menées par les meilleurs spécialistes. Les conditions d'utilisation détaillées avec la liste des médecins disponibles sont disponibles dans le cadre du processus de réservation de la visite.",
          "consultationsLbl": "consultations",
          "specialistsLbl": "spécialistes",
          "epileptLbl": "épileptologue",
          "neurosurLbl": "neurochirurgien",
          "neurologLbl": "neurologopédiste",
          "psychLbl": "psychiatre"
        },
        "dataNotFilled": {
          "title": "Bienvenue dans l'application Neuroterminal !",
          "footer": "Choisissez qui sera le patient Neurosphera",
          "patient": "je suis une patiente",
          "patientDescription": "Pour les patients de plus de 18 ans",
          "guardian": "Le patient est un enfant",
          "guardianDescription": "Pour les patients de moins de 18 ans ou ayant un tuteur légal"
        },
        "yourLeadDoctor": "Votre médecin chef de file",
        "addEvent": "Ajouter un évènement",
        "showCalendar": "Afficher le calendrier",
        "filesAdded": "Fichiers ajoutés",
        "addFiles": "Ajouter des fichiers",
        "showAllFiles": "Voir tous les fichiers",
        "surveyZeroDesc": "L'enquête est remplie par le médecin lors de la visite Enquête 0",
        "showSurveyZero": "Afficher le sondage",
        "confirmDeletionTitle": "Suppression d'événement",
        "confirmDeletionText": "Voulez-vous supprimer cet événement ?",
        "noLeadDoctor": "Vous choisirez le médecin principal après la visite zéro",
        "notAvailableNow": "Indisponible",
        "carePlan": {
          "title": "Plan de santé",
          "date": "Date",
          "incomingVisitsAndReferrals": "Visites entrantes et références",
          "incomingVisitsAndReferralsDesc": "Vos rendez-vous entrants et vos références de médecin apparaîtront ici",
          "bookFirstVisit": "Réservez votre première visite",
          "archiveVisits": "Visites d'archives",
          "archiveVisitsDesc": "Ici vous verrez les visites qui ont déjà eu lieu",
          "scheduledDate": "date suggérée",
          "getSummaryPdf": "obtenir des recommandations de visite",
          "cancelReferral": "supprimer la référence",
          "changeDate": "date de modification",
          "cancelVisit": "annuler la visite",
          "payForVisit": "payer la visite",
          "startingAt": "À partir de",
          "goToVisit": "Entrez la visite",
          "showMoreArchiveVisits": "Afficher toutes les visites d'archives",
          "cancelReferralConfirmation": "Voulez-vous vraiment supprimer cette référence ?",
          "cancelReferralSuccess": "La recommandation a été supprimée",
          "chooseDate": "Choisissez la date",
          "change": "Changer de plan de soins",
          "reassignVisit": "changer de médecin",
          "chooseDateFreeReferral": "Choisissez la date pour la visite gratuite",
          "suggestedDate": "date suggérée",
          "referralRemoved": "Référence supprimée",
          "downloadSummary": "Télécharger le résumé",
          "description": "Description",
          "filters": {
            "canceled": "Spectacle annulé",
            "visits": "Visites du spectacle",
            "messages": "Afficher les messages",
            "referrals": "Afficher les références"
          }
        },
        "doctorCare": "Soins médicaux",
        "neuroscreen": "Neuroscreen",
        "doctorOnDuty": "Médecin de garde"
      },
      "patientAccount": {
        "info": {
          "general": "Données des patients",
          "infoLbl": "Données personnelles",
          "avatarLbl": "Avatar",
          "nameLbl": "Nom",
          "lastNameLbl": "Nom de famille",
          "sexLbl": "Sexe",
          "emailLbl": "E-mail",
          "phoneLbl": "Pas de téléphone",
          "peselLbl": "Pesel",
          "otherDocumentLbl": "Autre document",
          "documentTypeLbl": "Type de document",
          "documentNumberLbl": "Numéro de document",
          "birthDate": "Date de naissance",
          "repLbl": "Données du représentant officiel",
          "repLblShort": "Représentant légal",
          "teleAddrLbl": "Données de télé-adresse",
          "bioMedLbl": "Données biomécaniques",
          "bloodTypeLbl": "Groupe sanguin",
          "rhTypeLbl": "HR",
          "heightLbl": "haut",
          "weightLbl": "Poids",
          "iceLbl": "ICE (En cas d'urgence - dans des situations immédiates)",
          "iceShortLbl": "LA GLACE",
          "icePers1Lbl": "Personne à appeler 1",
          "icePers1PhoneLbl": "Personne 1 n° de téléphone 1",
          "icePers2Lbl": "Personne à appeler 2",
          "icePers2PhoneLbl": "Personne 2 n° de téléphone",
          "clearBtn": "Dégager",
          "saveBtn": "Confirmer",
          "dataSavedMsg": "Données enregistrées",
          "entitledStatementMsg": "Je déclare par la présente que j'ai tous les droits légaux",
          "legalGuardianMsg": "Je déclare par la présente que je suis une représentation légale habilitée à représenter le Patient en tant que :",
          "invalidPeselWarnMsg": "Si PESEL est incorrect, le sexe et la date de naissance ne seront pas enregistrés",
          "fromNsph": "Le patient a-t-il été traité au CTP Neurosphera ?",
          "hasEpilepsy": "Le patient est-il épileptique ?",
          "incompleteFormWarningMsg1": "Tous les champs n'ont pas été remplis",
          "incompleteFormWarningMsg2": "Êtes-vous sûr de vouloir enregistrer le formulaire incomplet ? N'oubliez pas que si vous ne remplissez pas tous les champs obligatoires, vous ne pourrez pas réserver la visite zéro.",
          "yesLbl": "Oui",
          "noLbl": "Non",
          "invoiceBuyer": {
            "title": "Données de facturation",
            "checkboxLbl": "Je veux définir des données différentes pour la facture",
            "firm": "Solidifier",
            "person": "Personne privée",
            "firmLbl": "Nom de l'entreprise",
            "nipLbl": "Pincer",
            "firstNameLbl": "Prénom",
            "lastNameLbl": "Nom de famille",
            "streetLbl": "rue",
            "buildingLbl": "Imeuble",
            "zipcodeLbl": "Code Postal",
            "cityLbl": "Ville",
            "comment": "Commenter"
          },
          "generalDesc": "Nous avons besoin de certaines de vos coordonnées. Il s'agit d'une exigence formelle. N'oubliez pas qu'ils sont protégés juridiquement et techniquement !",
          "generalDescLegal": "Nous avons également besoin des détails de notre patient. Il s'agit d'une exigence formelle, mais aussi de la capacité de délivrer correctement une ordonnance, des certificats ou des références.",
          "nameLblRich": "<b>nom</b> du patient",
          "lastNameLblRich": "<b>nom</b> du patient",
          "peselLblRich": "<b>pesel</b> du patient",
          "parentLbl": "Données parentales",
          "repLblDesc": "Nous avons besoin de plus d'informations sur vous, cher parent, afin de pouvoir prendre soin de vous conformément aux exigences de la loi. N'oubliez pas que vos données sont protégées juridiquement et techniquement !",
          "repNameLbl": "<b>Nom</b> du parent ou du représentant légal",
          "repLastNameLbl": "<b>Nom</b> du parent ou du représentant légal",
          "repPhoneLbl": "<b>Téléphone</b> du parent ou du représentant légal",
          "repPeselLbl": "<b>PESEL</b> du parent ou représentant légal",
          "teleAddrLblDesc": "Des coordonnées correctes sont essentielles pour que le médecin délivre une ordonnance, un certificat ou une référence.",
          "bioMedLblDesc": "Les informations suivantes ne sont pas obligatoires. Vous pouvez les recharger plus tard. N'oubliez pas que fournir des données biométriques aide le médecin à choisir la bonne dose de médicaments.",
          "others": "Autres",
          "othersDesc": "Les données suivantes ne sont pas obligatoires si vous souhaitez nous laisser des informations sur qui informer en cas d'urgence.",
          "addIceContact": "Ajouter une autre personne",
          "nsphSourceQuestion": "Comment avez-vous entendu parler de Neurosphera ?",
          "nsphSourceDoctorDescription": "Veuillez indiquer le nom d'un médecin",
          "incompleteFormConfirmBtn": "Complétez les données",
          "address": {
            "streetLbl": "<b>rue</b> du patient",
            "cityLbl": "<b>ville</b> du patient",
            "countryLbl": "<b>pays</b> du patient"
          },
          "leaveDialog": {
            "title": "Vous n'avez pas fini de saisir vos données, êtes-vous sûr de vouloir vous déconnecter ?",
            "subtitle": "Vous devrez les ressaisir la prochaine fois que vous vous connecterez",
            "footer": "Si vous avez des questions supplémentaires ou en cas de problème pour remplir la demande, contactez-nous :"
          },
          "contactEmailLbl": "Adresse e-mail supplémentaire pour la réception des messages système",
          "contactEmailDesc": "L'adresse indiquée sera disponible pour l'accueil de la clinique. Si vous souhaitez également recevoir des notifications du système à l'adresse indiquée, marquez les points appropriés :",
          "contactVisitNotification": "Visites - paiement, confirmation",
          "contactEmailPlaceholder": "Adresse e-mail",
          "contactDescriptionPlaceholder": "Description d'adresse",
          "contactReferralNotification": "Références - plan de soins",
          "addAdditionalContactEmail": "Ajouter l'adresse e-mail suivante",
          "documentCountry": "Pays",
          "partner": "Partenaire NSPH"
        },
        "bioMed": {
          "saveSuccess": "Zmiany zostały zapisane",
          "fullInfo": "Afficher toutes les données"
        },
        "bioMedEditDialog": {
          "title": "Édition des données biomédicales des patients",
          "subtitle": "Lors de la modification de la valeur, les données sont automatiquement enregistrées"
        }
      },
      "visitReservation": {
        "title": "Réservation de visite",
        "consultationTitle": "Réservation de consultations",
        "specializationChoice": "Choix de spécialisation",
        "doctorChoice": "Choix du médecin",
        "slotChoice": "Choix de l'emplacement",
        "summaryAndPayment": "Résumé et paiement",
        "noServicesAvailable": "Vous pourrez désigner de nouvelles visites d'épilepsie après la visite que vous avez déjà désignée",
        "goToVisitsList": "Aller à la liste des visites",
        "appointConsultation": "Nommer une consultation / EEG",
        "leadDoctor": "médecin en chef",
        "next": "Prochain",
        "choose": "Choisir",
        "back": "Arrière",
        "doctor": "Docteur",
        "service": "Service",
        "price": "Prix",
        "payment": "Formulaire de paiement",
        "date": "Date",
        "pay": "Payer",
        "noSlotsAvailable": "Aucun créneau disponible",
        "doctors": "Médecins",
        "availableSlots": "Emplacements disponibles",
        "summary": "Résumé",
        "serviceDescription": "La description",
        "eegDescription": "Dans le système Neuroterminal, vous vous inscrivez à un test EEG standard d'une durée de 30 minutes. Le test est effectué au Neurosphera Epilepsy Therapy Center. Si vous souhaitez vous inscrire à un test EEG non standard ou à une modification EEG (VEEG, DEEG, PSG ou autre), prenez rendez-vous par téléphone avec le secrétariat du Neurosphera Epilepsy Therapy Center au +48 609 463 191",
        "serviceTypeSelection": {
          "label": "Type de visite",
          "epilepsy": "Visite épileptique",
          "consultation": "Consultation / EEG"
        },
        "sorting": {
          "label": "Sorte",
          "alpha": "Alphabétiquement",
          "teleconferencePriceAsc": "Tarif téléconférence : ascendant",
          "teleconferencePriceDesc": "Tarif téléconférence : décroissant",
          "stationaryPriceAsc": "Tarif visite stationnaire : ascendant",
          "stationaryPriceDesc": "Tarif visite stationnaire : décroissant",
          "bestMatch": "Meilleure correspondance"
        },
        "serviceDescriptions": {
          "neurosurgeon": {
            "title": "Qu'est-ce qu'un neurochirurgien ?",
            "description": "Un chirurgien spécialisé dans le diagnostic et le traitement chirurgical de certaines maladies du système nerveux (cerveau, moelle épinière, nerfs périphériques) pour lesquelles le traitement pharmacologique n'a pas fonctionné et une intervention chirurgicale doit être pratiquée.",
            "bulletsTitle": "Quand consulter un neurochirurgien ?",
            "bullet1": "Apparition de problèmes neurologiques pour lesquels le traitement pharmacologique n'a pas fonctionné, par ex. discopathie, maux de dos chroniques et blessures, tumeurs cérébrales et lésions néoplasiques de la colonne vertébrale, hémangiomes, etc.",
            "bullet2": "Maux de tête forts et prolongés et vertiges",
            "bullet3": "Engourdissement de cause inconnue ou picotements dans les extrémités",
            "bullet4": "Occurrence de syncope fréquente",
            "bullet5": "Présence d'une déficience de cause inconnue, de la vision, de l'audition",
            "bullet6": "Prévalence de la parésie des membres"
          },
          "neuropsychologist": {
            "title": "Qu'est-ce qu'un neuropsychologue ?",
            "description": "Spécialiste en psychologie clinique, traitant de la relation entre le fonctionnement du cerveau et le comportement humain. Les activités du neuropsychologue s'adressent principalement aux personnes atteintes de lésions cérébrales causées par diverses maladies, blessures ou blessures.",
            "bulletsTitle": "Quand consulter un neuropsychologue ?",
            "bullet1": "Lorsqu'un patient est diagnostiqué avec des problèmes de fonctionnement normal, tels que des traumatismes crâniens, des tumeurs cérébrales, des maladies qui détruisent le tissu nerveux. par exemple. Maladie d'Alzheimer ou de Parkinson",
            "bullet2": "Des troubles de la mémoire se produisent",
            "bullet3": "Problèmes de concentration",
            "bullet4": "Occurrence de troubles de la parole",
            "bullet5": "Difficulté à s'orienter, trouver un chemin",
            "bullet6": "Il y a des troubles visuels malgré des résultats ophtalmiques normaux",
            "bullet7": "Des changements d'humeur, de l'anxiété, des délires surviennent",
            "bullet8": "Dans les états addictifs"
          },
          "neurologopedist": {
            "title": "Qu'est-ce qu'un neurologopédiste ?",
            "description": "Spécialiste du diagnostic et du traitement des troubles de la parole et de la communication d'origine neurologique. Le neurologopédiste peut travailler aussi bien avec des enfants qui ont un retard de développement de la parole ou des troubles de la parole sur divers horizons, qu'avec des patients adultes.",
            "bulletsTitle": "Quand consulter un neurologopédiste ?",
            "bullet1": "Lorsqu'un enfant est diagnostiqué avec un trouble neurologique, un syndrome génétique ou un trouble du spectre autistique",
            "bullet2": "Lorsqu'un parent constate un retard psychomoteur chez son enfant",
            "bullet3": "Lorsqu'un enfant a des difficultés d'élocution - un enfant de 2 ans ou plus ne parle pas du tout, parle peu ou a un trouble de la parole",
            "bullet4": "Lorsqu'un enfant salive excessivement, il a du mal à manger, ne ferme pas la bouche ou ne respire pas par le nez",
            "bullet5": "Quand un adulte a un trouble de la parole après un accident vasculaire cérébral ou un accident",
            "bullet6": "Lorsqu'un adulte présente des troubles de la parole dus à une démence sénile, la maladie de Parkinson, la maladie d'Alzheimer ou la sclérose en plaques"
          },
          "psychiatrist": {
            "title": "Qui est psychiatre ?",
            "description": "Un médecin traitant des problèmes de santé mentale - troubles et maladies mentaux, à la fois en termes de diagnostic et de thérapie ultérieure. Le psychiatre peut conduire un traitement pharmacologique et orienter des consultations supplémentaires vers d'autres spécialités.",
            "bulletsTitle": "Quand consulter un psychiatre ?",
            "bullet1": "Quand ils sont agaçants depuis longtemps : tristesse, souffrance, apathie, manque d'énergie, impuissance, vision pessimiste de l'avenir",
            "bullet2": "Apparition de pensées suicidaires",
            "bullet3": "Dans les problèmes et les troubles du sommeil",
            "bullet4": "Pour les troubles anxieux",
            "bullet5": "L'apparition d'hyperactivité, d'humeur anormalement élevée et de confusion",
            "bullet6": "Apparition de problèmes de concentration et troubles de la mémoire",
            "bullet7": "Éviter les contacts et prendre sa retraite",
            "bullet8": "En cas de délires affectant la perception de la réalité et d'hallucinations"
          },
          "epileptologist": {
            "title": "Qu'est-ce qu'un épileptologue ?",
            "description": "Neurologue spécialisé dans le diagnostic et le traitement de l'épilepsie. Un épileptologue peut se spécialiser dans le diagnostic et le traitement de l'épilepsie chez les enfants ou les adultes. En plus de sélectionner la thérapie, le médecin coordonne le processus thérapeutique, en orientant également le patient vers des médecins d'autres spécialités.",
            "bulletsTitle": "Quand consulter un épileptologue ?",
            "bullet1": "L'apparition d'une crise soudaine, dans laquelle le patient perd connaissance et tombe, et les muscles se contractent. Des convulsions surviennent et le patient peut arrêter de respirer",
            "bullet2": "Une perte soudaine de contact avec l'environnement se produit et le patient se fige sans bouger",
            "bullet3": "Les crampes musculaires surviennent sans perte de conscience",
            "bullet4": "Relaxation musculaire, inconscience, chute du patient"
          },
          "eeg": {
            "title": "Qui réalise le test EEG ?",
            "description": "Le test EEG est réalisé par un technicien spécialisé dans ce domaine à la demande d'un épileptologue, neurologue ou psychiatre. Responsable de la planification et de la réalisation de recherches sur toutes les modalités chez les adultes et les enfants. La recherche est menée dans le sillage-endormissement-sommeil. Elles peuvent également être réalisées au domicile du patient. Le test dure environ 30 minutes.",
            "bulletsTitle": "Quand un test EEG est-il nécessaire ?",
            "bullet1": "Le test EEG est le test de base dans le diagnostic de l'épilepsie",
            "bullet2": "L'EEG est souvent utilisé pour indiquer les causes des troubles du sommeil",
            "bullet3": "Peut être commissionné pour les blessures à la tête",
            "bullet4": "D'autres indications incluent une déficience visuelle, un bégaiement, des maux de tête récurrents, des évanouissements ou une perte de conscience"
          },
          "visit0": {
            "bulletsTitle": "Qu'est-ce que l'enquête 0 ?",
            "bullet1": "<b>Précède et prépare</b> 1 visite chez le médecin spécialiste clinicien en neurosphère",
            "bullet2": "C'est <b>un entretien détaillé de vos antécédents médicaux</b>",
            "bullet3": "Il est animé par un <b> Résident Clinique </b>, que nous sélectionnerons individuellement pour vous",
            "bullet4": "<b>Préparez-vous.</b> Rappelez-vous quels médicaments ont été pris, à quelle fréquence surviennent les crises, comment elles se déroulent et quelle est leur nature",
            "bullet5": "Le médecin compilera une documentation numérique de votre cas sous la forme d'un entretien structuré. Les données numériques ainsi organisées peuvent être analysées par notre robot Ictal pour proposer un diagnostic et une thérapie lors de la Visite 1",
            "bullet6": "Durée <b>jusqu'à 60 minutes</b>",
            "bullet7": "Vous pouvez choisir le type de visite - <b>en ligne ou fixe</b>"
          },
          "visit1": {
            "bulletsTitle": "Qu'est-ce que la Visite 1 ?",
            "bullet1": "C'est une visite chez le spécialiste clinique en neurosphère de votre choix",
            "bullet2": "Le médecin verra les résultats de l'enquête 0, les résultats de l'analyse de notre robot Ictal et les matériaux joints par vous, ce qui lui permettra de confirmer efficacement le diagnostic et la thérapie",
            "bullet3": "Le médecin vous proposera des examens complémentaires si nécessaire",
            "bullet4": "Le médecin vous proposera un plan de soins complet",
            "bullet5": "Durée <b>jusqu'à 30 minutes</b>",
            "bullet6": "Vous pouvez choisir le type de visite - <b> en ligne ou fixe</b>"
          },
          "vns": {
            "title": "Qu'est-ce qu'une visite VNS ?",
            "description": "Lors d'une visite VNS, votre médecin vérifie votre réglage actuel. Sur la base de l'historique des crises enregistrées, il ou elle sélectionne la thérapie VNS appropriée. Le médecin lors de la visite modifie les paramètres de stimulation à l'aide d'un ordinateur, d'un logiciel de configuration et d'une télécommande de programmation. Le médecin peut diminuer ou augmenter le courant et augmenter ou diminuer le temps d'inactivité de l'appareil. La visite dure 15 minutes.",
            "bulletsTitle": "Quand prendre un rendez-vous VNS ?",
            "bullet1": "Lorsque la thérapie VNS implique un changement progressif des paramètres actuels",
            "bullet2": "Lorsqu'un patient constate une augmentation significative du nombre de crises",
            "bullet3": "Lorsqu'un patient constate une détérioration significative de sa santé et de son bien-être",
            "bullet4": "Lorsqu'un patient constate que le stimulateur implanté ne fonctionne plus, c'est-à-dire qu'il n'envoie plus d'impulsions",
            "bullet5": "Avant de subir des tests médicaux susceptibles d'affecter ou d'être affectés par l'appareil VNS, tels que l'imagerie par résonance magnétique (IRM)"
          },
          "dietician": {
            "title": "Qui est diététicien ?",
            "description": "Un spécialiste diplômé de l'enseignement supérieur avec un profil médical ou nutritionnel (agricole). Possède des connaissances adéquates pour mener des régimes alimentaires dans divers groupes de patients et, lors de la formulation de recommandations, prend en compte, entre autres, le sexe, l'âge, le poids et la taille, les maladies, y compris les allergies, et les préférences des patients. Il crée donc un plan d'alimentation individuel pour chaque patient et le supervise. En fonction des résultats du test ou des informations obtenues du patient, il introduit les modifications nécessaires. Par conséquent, une coopération étroite avec le patient et son médecin est extrêmement importante.",
            "bulletsTitle": "Quand consulter une diététicienne ?",
            "bullet1": "Quand le médecin neurosphérique a suggéré un traitement avec un régime cétogène",
            "bullet2": "Lorsque vous pensez que le régime cétogène peut être une option de traitement dans votre cas (REMARQUE - une visite chez le médecin est également nécessaire, si vous êtes un patient Neurospeha, demandez à votre médecin quelle est cette option de traitement, si vous n'êtes pas déjà un patient Neurosphera , prendre rendez-vous pour visiter ZERO)",
            "bullet3": "Lorsque vous suivez un régime cétogène et que vous souhaitez vérifier votre traitement actuel (REMARQUE - une visite chez le médecin est également nécessaire)",
            "bullet4": "Lorsque vous suivez un régime cétogène - une visite de contrôle (la diététicienne vous informera du meilleur moment pour vous inscrire lors de la visite)",
            "bullet5": "Lorsque vous souhaitez vérifier votre alimentation actuelle"
          },
          "neurologist": {
            "title": "Qu'est-ce qu'un neurologue ?",
            "description": "Un spécialiste qui observe les réactions du corps du patient, examine les fonctions du système nerveux et vérifie également les réactions du corps humain aux stimuli. Il s'occupe des maladies du système nerveux périphérique et central. Le neurologue se concentre principalement sur les maladies causées par des processus qui détruisent le système nerveux humain. Il examine les bases des maladies neurologiques émergentes, pose un diagnostic et un traitement adapté. Il s'occupe du traitement de maladies telles que la sciatique, la dégénérescence de la colonne vertébrale, l'épilepsie, la maladie de Parkinson, la maladie d'Alzheimer, la chorée de Huntington, les accidents vasculaires cérébraux et post-AVC, les migraines et autres maux de tête.",
            "bulletsTitle": "Quand consulter un neurologue ?",
            "bullet1": "En cas d'évanouissement et de perte de conscience",
            "bullet2": "Lorsque la mémoire se détériore fortement et qu'il y a des problèmes de concentration",
            "bullet3": "Quand tu as du mal à dormir",
            "bullet4": "Quand il y a des problèmes d'équilibre et de coordination",
            "bullet5": "S'il y a des picotements dans les extrémités avec une sensation perturbée",
            "bullet6": "S'il y a une douleur chronique associée à des étourdissements"
          },
          "geneticist": {
            "title": "Qu'est-ce qu'un généticien ?",
            "description": "Un généticien clinicien est un médecin dont la tâche est de déterminer si une maladie est héréditaire et quel mécanisme a conduit à l'hérédité d'une anomalie. Le généticien détermine également la probabilité des mêmes défauts ou maladies dans les générations suivantes. Son but est également d'identifier les membres de la famille du malade susceptibles de développer une maladie héréditaire. Le rôle de la génétique est également d'informer la famille sur les options de prévention et de traitement de la maladie et de lui fournir un conseil génétique. Un élément important est également la mise en œuvre d'un traitement spécialisé d'une maladie héréditaire.",
            "bulletsTitle": "Quand consulter un généticien ?",
            "bullet1": "Lorsqu'il y a une maladie dans votre famille avec des causes génétiques connues ou suspectées",
            "bullet2": "Quand voulez-vous faire le test WES",
            "bullet3": "Lorsque vous voulez écarter une maladie congénitale pouvant être à l'origine de l'épilepsie",
            "bullet4": "Lorsque vous planifiez une grossesse et que vous souhaitez savoir si votre bébé peut également souffrir d'épilepsie"
          },
          "absent": {
            "title": "Qu'est-ce qu'une consultation absente ?",
            "description": "Une consultation absente est un type de visite médicale qu'un spécialiste de Neurosphera sélectionné effectue sans la présence du patient. La consultation absente vise l'analyse des dossiers médicaux (cartes de sortie, résultats des tests génétiques et autres) ou un examen complémentaire (EEG, IRM et autres). Le spécialiste en neurosphère analysera la documentation, rédigera une note appropriée et des recommandations pour le patient. Le fait qu'elle ait eu lieu et son résultat seront conservés dans les archives de la visite du patient. ATTENTION : la consultation absente dure 30 minutes et est réalisée sans la participation du patient.",
            "bulletsTitle": "Quand dois-je m'inscrire pour une consultation in absentia?",
            "bullet1": "Vous serez invité à vous inscrire pour une consultation absente lors de la visite 1 ou plus tard - si le médecin considère qu'une telle consultation est appropriée. Son objectif est de réaliser une analyse clinique complémentaire dépassant le cadre temporel ou nécessitant la consultation d'un autre médecin (exemple : il s'avère par exemple qu'il est nécessaire de consulter un test métabolique avec un spécialiste choisi ou plusieurs examens IRM sur une période de plusieurs années doit être analysée).",
            "bullet2": "Typiquement, au cours d'une consultation de 30 minutes, le spécialiste clinique a la possibilité de voir deux tests à l'appui avec des descriptions."
          },
          "metabolic": {
            "title": "Qu'est-ce qu'une consultation métabolique ?",
            "description": "La consultation métabolique concerne les maladies rares - anomalies innées du métabolisme, qui sont des maladies génétiques. Le diagnostic des maladies métaboliques comprend principalement de nombreux examens et tests de laboratoire, souvent appuyés par des examens spécialisés.",
            "bulletsTitle": "Quand consulter ?",
            "bullet1": "Il vaut la peine de venir en consultation métabolique s'il y a suspicion de la maladie dans les tests génétiques, ou si les tests : spectrométrie de masse sanguine en tandem (tandem MS, MS/MS) ou profil des acides organiques urinaires (test GC-MS) sont Incorrect.",
            "bullet2": "Certaines maladies associées à l'épilepsie peuvent avoir une base métabolique, par ex. déficit en pyruvate déshydrogénase (PDHD), déficit du transporteur de glucose vers le cerveau (GLUT-1 DS) ou certaines maladies mitochondriales."
          },
          "veegOnStandby": {
            "title": "Qu'est-ce que l'examen VEEG en éveil ?",
            "description": "VEEG est un enregistrement en temps réel de l'EEG et de la vidéo synchronisés. Pour un diagnostic correct, le médecin doit savoir ce qui arrive au patient pendant les crises. Tous les détails ont leur importance : dans quel sens tourne la tête, dans quel sens les globes oculaires « tournent », où se produisent les crises, etc. L'examen vidéométrique permet au médecin de voir le patient à ce moment crucial pour le diagnostic de la maladie. L'examen est effectué dans un laboratoire spécialement préparé. L'examen éveillé est effectué pendant la journée. Le patient, selon les instructions du médecin, décide de la durée de l'examen. Le patient a à sa disposition un fauteuil et un lit dans la salle d'examen. Le patient peut utiliser les toilettes pendant l'examen tout en étant attaché à l'appareil EEG. Après l'examen, le patient reçoit un CD avec un enregistrement enregistré. La description de l'examen est incluse dans le prix.",
            "bulletsTitle": "Comment se préparer à l'examen ?",
            "bullet1": "Le patient doit avoir la tête propre",
            "bullet2": "Aucun gel ou fixatif ne doit être appliqué sur les cheveux avant l'examen",
            "bullet3": "Prenez les médicaments prescrits par votre médecin",
            "bullet4": "N'effectuez pas de privation de sommeil sans accord avec le médecin - le patient arrive en train de dormir"
          },
          "veegDuringSleep": {
            "title": "Qu'est-ce qu'un examen VEEG pendant le sommeil ?",
            "description": "VEEG est un enregistrement en temps réel de l'EEG et de la vidéo synchronisés. Pour un diagnostic correct, le médecin doit savoir ce qui arrive au patient pendant les crises. Tous les détails ont leur importance : dans quel sens tourne la tête, dans quel sens les globes oculaires « tournent », où se produisent les crises, etc. L'examen vidéométrique permet au médecin de voir le patient à ce moment crucial pour le diagnostic de la maladie. L'examen est effectué dans un laboratoire spécialement préparé. L'examen du sommeil est effectué le soir ou la nuit. Le patient décide de la durée de l'examen, selon les instructions du médecin. Le patient a à sa disposition un fauteuil et un lit dans la salle d'examen. Le patient peut utiliser les toilettes pendant l'examen tout en étant attaché à l'appareil EEG. Grands enfants et adultes allongés seuls sur le lit, petits enfants avec un accompagnateur, la salle d'attente est à la disposition de l'accompagnateur. Après l'examen, le patient reçoit un CD avec l'enregistrement enregistré. Une description de l'examen est incluse dans le prix.",
            "bulletsTitle": "Comment se préparer à l'examen ?",
            "bullet1": "Le patient doit avoir la tête propre",
            "bullet2": "Aucun gel ou fixatif ne doit être appliqué sur les cheveux avant l'examen",
            "bullet3": "Prenez les médicaments prescrits par votre médecin",
            "bullet4": "N'effectuez pas de privation de sommeil sans accord avec le médecin - le patient arrive en train de dormir",
            "bullet5": "Cela ne fait pas de mal d'emporter votre oreiller préféré avec vous depuis chez vous"
          },
          "veegNight": {
            "title": "Qu'est-ce qu'un test VEEG ?",
            "description": "VEEG est un enregistrement en temps réel de l'EEG et de la vidéo synchronisés. Pour un diagnostic correct, le médecin doit savoir ce qui arrive au patient pendant les crises. Tous les détails ont leur importance : dans quel sens tourne la tête, dans quel sens les globes oculaires « tournent », où se produisent les crises, etc. L'examen vidéométrique permet au médecin de voir le patient à ce moment crucial pour le diagnostic de la maladie. L'examen est effectué dans un laboratoire spécialement préparé. Le patient a un fauteuil et un lit à sa disposition dans le cabinet. Nous commençons l'examen à partir du moment où le patient s'endort, puis nous passons par les phases individuelles et enfin nous enregistrons le moment du réveil. Le patient a la possibilité d'utiliser les toilettes pendant l'examen tout en restant attaché à l'appareil EEG. Grands enfants et adultes allongés seuls sur le lit, petits enfants avec leur soignant, une salle d'attente est à disposition. Après l'examen, le patient reçoit un CD avec l'enregistrement enregistré. Une description de l'examen est incluse dans le prix.",
            "bulletsTitle": "Comment se préparer à l'examen ?",
            "bullet1": "Le patient doit avoir la tête propre",
            "bullet2": "Aucun gel ou fixatif ne doit être appliqué sur les cheveux avant l'examen",
            "bullet3": "Prenez les médicaments prescrits par votre médecin",
            "bullet4": "N'effectuez pas de privation de sommeil sans accord avec le médecin - le patient arrive en train de dormir",
            "bullet5": "Cela ne fait pas de mal d'emporter votre oreiller préféré avec vous depuis chez vous"
          },
          "veegDay": {
            "title": "Qu'est-ce qu'un test VEEG sur 24 heures ?",
            "description": "Un test VEEG est un enregistrement en temps réel d'une étude EEG et vidéo synchronisée. Pour un diagnostic correct, le médecin doit savoir ce qui arrive au patient pendant les crises. Tous les détails ont leur importance : dans quel sens tourne la tête, dans quel sens les globes oculaires « tournent », où se produisent les crises, etc. L'examen vidéométrique permet au médecin de voir le patient à ce moment crucial pour le diagnostic de la maladie. L'examen est effectué dans un laboratoire spécialement préparé. Le patient a un fauteuil et un lit à sa disposition dans le cabinet. Normalement, l'examen commence par un examen de nuit, puis nous surveillons le patient pendant la journée. Le patient a la possibilité d'utiliser les toilettes pendant l'examen tout en restant attaché à l'appareil EEG. Les repas sont inclus dans le prix de l'examen. Grands enfants et adultes allongés seuls sur un lit, petits enfants accompagnés d'un accompagnateur, une salle d'attente est à disposition. Après l'examen, le patient reçoit un CD avec l'enregistrement enregistré. Une description de l'examen est incluse dans le prix.",
            "bulletsTitle": "Comment se préparer à l'examen ?",
            "bullet1": "Le patient doit avoir la tête propre",
            "bullet2": "Aucun gel ou fixatif ne doit être appliqué sur les cheveux avant l'examen",
            "bullet3": "Prenez les médicaments prescrits par votre médecin",
            "bullet4": "N'effectuez pas de privation de sommeil sans accord avec le médecin - le patient arrive en train de dormir",
            "bullet5": "Cela ne fait pas de mal d'emporter votre oreiller préféré avec vous depuis chez vous",
            "bullet6": "De plus amples détails peuvent être convenus avec le technicien en appelant la réception du cabinet médical au +48 609 463 191"
          },
          "eegConsultation": {
            "title": "Qu'est-ce qu'un examen EEG ?",
            "description": "Un examen EEG standard est effectué pendant que le patient est éveillé. Le technicien sélectionne un capuchon approprié et place les électrodes sur la tête du patient. Il est important pour la qualité de l'examen qu'ils adhèrent le plus possible au cuir chevelu. L'examen lui-même dure environ 20 minutes. Immédiatement après l'examen, le patient peut reprendre une activité normale. Après l'examen, le patient reçoit un CD avec un enregistrement enregistré. La description de l'examen est incluse dans le prix.",
            "bulletsTitle": "Comment se préparer à l'examen ?",
            "bullet1": "Le patient doit avoir la tête propre",
            "bullet2": "Aucun gel ou fixatif ne doit être appliqué sur les cheveux avant l'examen",
            "bullet3": "Prenez les médicaments prescrits par votre médecin",
            "bullet4": "N'effectuez pas de privation de sommeil sans accord avec le médecin - le patient arrive en train de dormir"
          },
          "sleepDisturbance": {
            "title": "Qu'est-ce qu'une consultation sur les troubles du sommeil ?",
            "description": "Les troubles du sommeil peuvent être primaires (résultant d'anomalies du sommeil lui-même) ainsi que le symptôme de maladies d'autres systèmes. Parfois, une consultation psychiatrique est indiquée. Lors de la visite, un entretien détaillé sera réalisé, portant sur le déroulement de la nuit et le programme de la journée. Il vaut la peine de préparer un journal du sommeil pour une telle visite, couvrant au moins 5 jours et enregistrant tous les épisodes perturbateurs survenus la nuit en utilisant des méthodes à domicile. Après la consultation, il sera évalué si le problème est physiologique ou non. Si un trouble du sommeil est diagnostiqué, un traitement vous sera proposé - un traitement ou une référence à un spécialiste qui traite le problème sous-jacent.",
            "bulletsTitle": "Quand consulter ?",
            "bullet1": "Si vous êtes somnolent ou irritable pendant la journée",
            "bullet2": "Si vous souffrez d'insomnie ou de somnolence excessive causée par des facteurs émotionnels",
            "bullet3": "Si vous observez des perturbations du rythme circadien du sommeil et du réveil",
            "bullet4": "Si vous ronflez pendant votre sommeil"
          },
          "regularVisitEegPackage": {
            "title": "Qu'est-ce que le forfait visite régulière + EEG ?",
            "description": "Test EEG – est un test standard pour évaluer l’activité bioélectrique du cerveau, sur la base duquel l’exactitude de son fonctionnement est évaluée. L'examen dure environ 20 minutes. Après l'examen, le patient se rend en visite. Visite stationnaire RÉGULIÈRE - chaque visite ultérieure. Dure jusqu'à 30 minutes. Lors de la visite, le médecin analyse le test EEG effectué, établit le diagnostic final et analyse les données collectées dans le système. Le patient reçoit un CD avec le test EEG enregistré et les recommandations d'une visite régulière.",
            "bulletsTitle": "Quand souscrire au forfait ?",
            "bullet1": "Le test EEG est le test de base dans le diagnostic de l'épilepsie",
            "bullet2": "Un patient souffrant d'épilepsie doit effectuer régulièrement un examen EEG chaque année et consulter régulièrement un épileptologue.",
            "bullet3": "Si vous prévoyez une visite stationnaire chez le médecin, il vaut la peine d'effectuer un EEG pour évaluer l'activité bioélectrique du cerveau.",
            "bullet4": "Vous ne ressentez aucune amélioration ou détérioration de votre état de santé, vous souhaitez discuter avec votre médecin d'une modification de votre traitement"
          },
          "adhdPackage": {
            "title": "Comment diagnostique-t-on le TDAH ?",
            "description": "Le diagnostic du TDAH consiste en 2 rencontres avec un neuropsychologue, d'une durée chacune d'1h30, au cours desquelles sont réalisés un entretien clinique et des tests neuropsychologiques. Après les entretiens, le psychologue donne un avis et recommande vers qui le patient doit s'adresser, que ce soit un neurologue (30 minutes) ou un psychiatre (45 minutes). Lors de la visite appropriée, le patient recevra un diagnostic et des recommandations pour un traitement ultérieur (traitement pharmacologique ou thérapie psychologique).",
            "bulletsTitle": "Quand souscrire au forfait TDAH ?",
            "bullet1": "S'il y a des difficultés avec l'organisation du temps et des tâches. Vous avez du mal à démarrer et à terminer des projets, à planifier et à maintenir l'ordre",
            "bullet2": "S’il y a des difficultés émotionnelles, vous êtes sujet à des sautes d’humeur, à de la frustration et à des difficultés à contrôler vos émotions. Vous ressentez de fortes réactions face à des situations stressantes",
            "bullet3": "Lorsque vous remarquez en vous des difficultés interpersonnelles. Vous êtes agité, impulsif et avez du mal à prendre en compte le point de vue des autres.",
            "bullet4": "Si vous avez du mal à estimer le temps nécessaire pour accomplir une tâche"
          },
          "standardVisit": {
            "title": "Qu'est-ce qu'une visite régulière ?",
            "description": "Visite RÉGULIÈRE – chaque visite ultérieure. Dure jusqu'à 30 minutes. Lors de la visite, le médecin recueille un entretien et analyse le calendrier des événements du patient. Le médecin examine le dossier médical du patient et peut suggérer des tests supplémentaires. Après chaque visite, le patient reçoit des recommandations de la visite, un plan thérapeutique et un plan de soins ultérieurs. Le patient peut choisir le type de visite - en ligne ou stationnaire.",
            "bulletsTitle": "À quoi ressemble une visite régulière ?",
            "bullet1": "Discutez de votre état de santé actuel - rappelez-vous de la date de la dernière crise et de votre bien-être - complétez le calendrier des événements",
            "bullet2": "Connaître les résultats des tests récents - si vous avez récemment effectué des tests, assurez-vous que tous les résultats sont joints au dossier médical",
            "bullet3": "Discussion sur la poursuite du traitement",
            "bullet4": "Établir un plan de soins complet",
            "bullet5": "Délivrance d'une ordonnance, d'une référence, d'un arrêt maladie, d'une attestation"
          },
          "caseConference": {
            "title": "Qu'est-ce qu'une conférence de cas ?",
            "description": "Une conférence médicale entre médecins spécialistes et partenaires médicaux de Neurosphery est une réunion collective pour discuter du cas clinique du patient et déterminer le plan de traitement optimal. Une consultation est organisée dans les situations où une assistance ou des connaissances spécialisées supplémentaires sont nécessaires pour obtenir les meilleurs résultats thérapeutiques. Il s'agit d'une forme de conférence médicale interdisciplinaire au cours de laquelle des connaissances et des opinions sont échangées entre spécialistes."
          },
          "mmpi2Package": {
            "title": "Qu'est-ce que le test de personnalité MMPI-2 ?",
            "description": "Nous permettons de diagnostiquer les troubles de la personnalité à l'aide de l'outil d'évaluation clinique le plus complet et le plus universel disponible en Pologne - le Minnesota Multidimensional Personality Inventory (l'adaptation complète du MMPI-2 a été publiée en 2012). Le test contient dix échelles de contrôle qui peuvent être utilisées pour décrire la fiabilité du profil de résultat obtenu et justifier leur interprétation clinique. Les échelles de contrôle peuvent également informer le diagnosticien sur la motivation du sujet, son niveau d'adaptation et ses éventuels mécanismes de défense.",
            "bulletsTitle": "Qu'est-ce que le test de personnalité MMPI-2 ?",
            "bullet1": "L'inventaire lui-même peut être utilisé dans la description clinique des problèmes de personnalité, mais il peut également être utile pour identifier les personnes inaptes à exercer une fonction professionnelle spécifique. Il peut être utilisé pour établir un diagnostic chez les adultes ayant suivi au moins 6 ans d'études formelles. Le test doit être réalisé sous la supervision d'un psychologue. Vous devez prévoir environ 90 minutes pour effectuer le test et le compléter. Lorsque vous terminez le test, vous devez vous référer à 567 énoncés en sélectionnant VRAI/FAUX. La réalisation du test lui-même doit être précédée d'une réunion, d'environ 50 minutes, pour recueillir les données de l'entretien et présenter le test à la personne testée. Dans un délai d'une semaine, après avoir terminé la visite et terminé le test, la personne examinée recevra un rapport résumant les résultats obtenus."
          },
          "cbtPackage": {
            "title": "Qu’est-ce que la thérapie TCC pour l’insomnie ?",
            "description": "La thérapie cognitivo-comportementale (TCC) pour l'insomnie est considérée comme le traitement de première intention pour les personnes aux prises avec l'insomnie, recommandée par des autorités telles que les National Institutes of Health (NIH), le National Institute for Health and Care Excellence (NICE) et l'American College of Physicians. La TCC-I est une alternative efficace aux somnifères, offrant des solutions durables en modifiant les habitudes de sommeil et de pensée. L'ensemble du processus TCC-I comprend cinq séances réparties sur six semaines, mais une amélioration peut généralement être constatée plus tôt. Les séances peuvent être organisées en présentiel ou en ligne. Environ 75 % des personnes souffrant d'insomnie chronique constatent une amélioration significative de leur sommeil et 90 % réduisent ou arrêtent complètement la prise de somnifères.",
            "bulletsTitle": "La thérapie TCC-I est recommandée aux personnes qui :",
            "bullet1": "Ils souffrent d’insomnie chronique : les problèmes de sommeil surviennent au moins trois fois par semaine pendant au moins trois mois.",
            "bullet2": "Avoir des difficultés à s’endormir : Ils passent plus de 30 minutes à essayer de s’endormir le soir.",
            "bullet3": "Réveils nocturnes : Ils se réveillent régulièrement au milieu de la nuit et ont du mal à se rendormir pendant plus de 30 minutes.",
            "bullet4": "Cela les rend fatigués et irritables pendant la journée : l’insomnie affecte leur bien-être quotidien et leur qualité de vie.",
            "bullet5": "Ils recherchent une alternative aux somnifères : ils veulent éviter la dépendance à la pharmacothérapie et recherchent des solutions naturelles à long terme."
          }
        },
        "visitTypeChoice": "Type de visite",
        "selectPreviousSlots": "Les créneaux disponibles seront présentés après sélection du rendez-vous précédent",
        "allDoctors": "Tous les médecins",
        "paymentLinkSent": "Le lien de paiement a été envoyé au patient",
        "openPaymentLink": "Ouvrir le lien de paiement",
        "linkCopied": "Le lien a été copié dans le presse-papiers",
        "visitPackageDescriptions": {
          "startPackage1": "En tant que nouveau patient Neurosphera ou parent du patient, vous devez avoir un rendez-vous 1 avec un spécialiste clinique. Elle sera précédée d'une enquête 0, qui nous permettra de collecter des informations pour un diagnostic et une thérapie efficaces. Tout cela à un prix abordable.",
          "startPackage2": "Sélectionnez le médecin spécialiste qui verra la visite 1 et si elle sera en ligne ou stationnaire. Faites attention aux différences de prix et à l'emplacement de la visite à l'arrêt.",
          "startPackage3": "L'enquête 0 précède et prépare la visite 1. Elle se déroulera avec un résident clinique en neurosphérique de notre choix. Choisissez le type de visite qui vous convient (en ligne ou fixe) et sa date. N'oubliez pas que l'enquête 0 dure jusqu'à 60 minutes.",
          "startPackage4": "Effectuer un paiement nous permet de prendre rendez-vous avec un médecin donné pour vous. N'oubliez pas que vous pouvez toujours changer la date gratuitement !"
        },
        "slotChoiceDescriptions": {
          "VISIT_1": "La visite 1 a lieu après l'enquête 0. Le spécialiste clinique en neurosphère et le type de visite que vous avez déjà sélectionné à l'étape précédente. Le médecin posera un diagnostic et proposera une thérapie adaptée. Il préparera également un plan de soins individuel. N'oubliez pas que la visite 1 dure jusqu'à 30 minutes."
        },
        "calculateInstallments": "Payer en plusieurs fois",
        "priceFrom": "Prix à partir",
        "payments": {
          "directPayment": "Vous pouvez payer en toute sécurité avec {provider} :",
          "creditCard": "Carte de crédit",
          "blik": "Blik",
          "onlineTransfer": "Transfert en ligne",
          "onlineApplication": "Candidature en ligne",
          "mobilePayments": "Paiements mobiles",
          "payLater": "Payer plus tard"
        },
        "expand": "Développer",
        "approve": "Confirmer",
        "cancel": "Annuler",
        "fold": "Plier",
        "cancelAndChangeDate": "Annuler / modifier la date",
        "visitDuration": "Durée de la visite",
        "durationSelectDesc1": "L'examen peut durer de",
        "durationSelectDesc2": "pour",
        "durationSelectDesc3": "les heures",
        "durationSelectDesc4": "Pour continuer avec un rendez-vous, veuillez sélectionner le nombre d'heures",
        "durationTime": "Heure de l'examen",
        "serviceGroupDescriptions": {
          "eeg_consultation": {
            "groupSelectionTitle": "Examens EEG/VEEG disponibles",
            "title": "Qui réalise le test EEG ?",
            "description": "Le test EEG est réalisé par un technicien spécialisé dans ce domaine à la demande d'un épileptologue, d'un neurologue ou d'un psychiatre. Responsable de la planification et de la réalisation de recherches sur toutes les modalités chez les adultes et les enfants. La recherche est menée dans le sillage-endormissement-sommeil. Elles peuvent également être réalisées au domicile du patient. Le test dure environ 30 minutes.",
            "bulletsTitle": "Quand un test EEG est-il nécessaire ?",
            "bullet1": "Le test EEG est le test de base dans le diagnostic de l'épilepsie",
            "bullet2": "L'EEG est souvent utilisé pour indiquer les causes des troubles du sommeil",
            "bullet3": "Peut être commissionné pour les blessures à la tête",
            "bullet4": "D'autres indications incluent une déficience visuelle, le bégaiement, des maux de tête récurrents, des évanouissements ou une perte de conscience"
          }
        },
        "allWorkplaces": "Tous les emplacements",
        "allTechniciansIntro": "L'examen sera effectué par l'un de nos techniciens expérimentés",
        "markedAsPaid": "La visite a été fixée et marquée comme payée",
        "markAsPaid": "Marquer comme payé",
        "stage": "Scène",
        "sum": "Somme",
        "freeAppointmentPrice": "Gratuit",
        "appointmentSuccess": "Wizyta została umówiona",
        "sameDateAsPrevious": "La visite doit avoir lieu le même jour que la visite forfaitaire précédente",
        "refundRestrictions": "Politique d'annulation de visite",
        "refundRestrictionsAccept": "J'accepte la politique d'annulation",
        "supervisorDoctor": "superviseur de visite",
        "supervisorDoctorAbbr": "Superviseur",
        "doctorPackageDescription": "Le forfait médecins est une visite avec la participation de 2 médecins ou plus. Le médecin qui effectue la visite effectue toutes les activités liées à la visite. Le médecin observateur observe la visite et peut engager une discussion clinique avec le médecin traitant et le patient.",
        "specialist": "Spécialiste en"
      },
      "paymentOutcome": {
        "title": "Confirmation de paiement",
        "loading": "Chargement des données de paiement",
        "status": {
          "title": "Statut",
          "types": {
            "pending": {
              "title": "Traitement des paiements en cours",
              "subtitle": "Le paiement n'est pas encore confirmé",
              "comment": "Veuillez patienter un instant pendant que le paiement se termine"
            },
            "success": {
              "title": "Le service a été payé",
              "subtitle": "Le paiement a été confirmé",
              "comment": "Merci pour le paiement"
            },
            "error": {
              "title": "Le service n'a pas été payé",
              "subtitle": "Le paiement a été annulé",
              "comment": "Veuillez répéter le processus de paiement"
            }
          }
        },
        "details": {
          "title": "Détails",
          "date": "Date",
          "name": "Nom du service",
          "doctor": "Docteur"
        }
      },
      "visitPaymentOutcome": {
        "title": "Réservation de visite",
        "subtitle": "confirmation",
        "details": "détails",
        "loading": "Les données de visite sont en cours de chargement...",
        "card": {
          "successTitle": "Votre visite a été payée",
          "successLabel1": "Merci pour un paiement",
          "successLabel2": "Vous serez prévenu d'une visite 24h avant",
          "failTitle": "Votre visite n'a pas été payée",
          "failLabel1": "Une erreur s'est produite dans un processus de paiement",
          "retryPayment": "Réessayer le paiement",
          "toGoMessageView": "Aller à la vue des messages",
          "paymentPending": "Votre paiement n'a pas encore été confirmé. Cela peut prendre jusqu'à 1 minute",
          "paymentConfirmed": "Le paiement a été confirmé !",
          "successLabel1Package": "Merci! Vous êtes bien soigné !"
        },
        "goHome": "Aller à la page principale",
        "visitScope": "Portée de la visite",
        "scopeBullets": {
          "visit0Bullet1": "Durée env. 60 minutes",
          "visit0Bullet2": "Dirigé par un médecin résident clinicien",
          "visit0Bullet3": "Entretien avec un médecin sur les données médicales, biométriques et personnelles du patient",
          "visit0Bullet4": "Analyse de l'épilepsie et des antécédents de comorbidité",
          "visit0Bullet5": "Analyse des antécédents thérapeutiques",
          "visit0Bullet6": "Sur la base des données collectées, il sera possible dans un premier temps de faire un diagnostic de travail et de déterminer l'ampleur du problème médical du patient",
          "migrationVisitBullet1": "Destiné aux Patients qui étaient ou sont sous nos soins avant 2021",
          "migrationVisitBullet2": "Vérification des données médicales, biométriques et personnelles du patient",
          "migrationVisitBullet3": "Transition vers un nouveau système de soins",
          "visit1Bullet1": "Durée de 30 à 60 minutes",
          "visit1Bullet2": "Analyse ICtal® et reconnaissance informatique initiale",
          "visit1Bullet3": "Diagnostic initial et sélection du médecin traitant",
          "visit1Bullet4": "Élaboration d'un plan de traitement initial avec un plan d'examens et d'activités supplémentaires",
          "visit1Bullet5": "Élaboration du plan d'observation informatique clinique ICtal Move® et du plan initial de thérapie informatique ICtal®",
          "visit1Bullet6": "Discussion des négociations préliminaires",
          "standardVisitBullet1": "Durée de 30 à 45 minutes",
          "standardVisitBullet2": "Déterminer le diagnostic final",
          "standardVisitBullet3": "Analyse constante des données collectées dans le système ICtal®",
          "standardVisitBullet4": "Capacité à intervenir en cas d'absence d'amélioration ou de détérioration - avis d'ICtal® ou de la mairie",
          "consultationBullet1": "Durée de 45 à 60 minutes",
          "consultationBullet2": "Visite avec un médecin d'une autre spécialisation",
          "consultationBullet3": "Antécédents médicaux",
          "consultationBullet4": "Analyse des antécédents d'épilepsie et des comorbidités",
          "consultationBullet5": "Conseil avis médical dans la spécialisation du Médecin Consultant",
          "messageBullet1": "N'oubliez pas qu'un message est une courte question textuelle à laquelle le médecin répondra dans les plus brefs délais pendant ses heures de travail.",
          "messageBullet2": "Dans le cadre des frais, vous pouvez choisir d'envoyer un seul message - une question. Lorsque le médecin répond, vous avez la possibilité de poser une autre question de suivi à laquelle le médecin répondra à nouveau et le fil se terminera automatiquement.",
          "messageBullet3": "Attention! Le médecin a le droit de refuser de vous conseiller dans le cadre du message s'il pense que votre demande se qualifie pour une visite régulière. Le message n'est pas destiné à aider en cas d'urgence ou de situations mettant la vie en danger !",
          "visit0Bullet7": "Vous pouvez choisir le type de visite - <b>en ligne ou fixe</b>"
        }
      },
      "awaitingPaymentNotAvailable": {
        "title": "La réservation de la visite a échoué !",
        "subtitle": "Désolé pour le dérangement",
        "ageDescription": "Le médecin que vous avez choisi n'accepte pas les patients de cet âge",
        "changeDescription": "Sélectionnez un autre médecin puis rendez-vous",
        "action": "Sélectionnez un médecin et une date de rendez-vous"
      },
      "neuroscreen": {
        "main": {
          "title": "La clinique Neurosphera crée une nouvelle qualité dans le traitement des patients !",
          "subtitle1": "Nous combinons efficacement nos connaissances et nos nombreuses années d’expérience avec le besoin de développement et d’amélioration continue de nos méthodes de traitement !",
          "subtitle2": "Nous avons préparé pour vous un sondage qui vous aidera à choisir le bon médecin et le bon plan de soins à la clinique Neurosphera.",
          "subtitle3": "En remplissant le sondage, le médecin le lira avant la visite et saura quel problème vous signalez.",
          "subtitle4": "Remplir l'enquête prend environ 5 minutes.",
          "fillNeuroscreen": "Complétez le sondage",
          "fillNeuroscreenDescription": "Je souhaite compléter l'enquête et saisir le plan de soins du patient",
          "goToReservation": "Sélectionnez un médecin",
          "goToReservationDescription": "Je ne veux pas répondre au sondage, je veux choisir un médecin tout de suite"
        },
        "survey": {
          "title": "Neuroscreen - Enquête auprès des patients de Neurosphera",
          "subtitle1": "Après avoir complété le sondage, nous vous indiquerons la visite et le médecin avec lesquels vous devez prendre rendez-vous.",
          "subtitle2": "Les informations que vous fournissez ici seront partagées avec votre médecin et vous aideront à établir un diagnostic et à vous traiter correctement.",
          "next": "Suivant",
          "prev": "Dos",
          "save": "Enquête complète",
          "form": {
            "section": {
              "HEADACHES": "Maux de tête",
              "MOVEMENT_ISSUES": "Troubles du mouvement",
              "MEMORY_ISSUES": "Troubles de la mémoire",
              "EATING_ISSUES": "Troubles de l'alimentation",
              "CONSCIOUSNESS_ISSUES": "Troubles de la conscience ou de la conscience",
              "PSYCHOMOTOR_DEVELOPMENT_ISSUES": "Troubles du développement psychomoteur",
              "EPILEPSY": "Épilepsie et crises",
              "SPINE_ISSUES": "Problèmes de colonne vertébrale",
              "MUTIPLE_SCLEROSIS": "Sclérose en plaques",
              "DIZZINESS": "Vertiges",
              "AUTISM": "Spectre autistique",
              "OTHER_SYMPTOMS": "Autres symptômes"
            },
            "adult": {
              "field": {
                "headachesGeneral": "Souffrez-vous de maux de tête ?",
                "headaches1": "Avez-vous un mal de tête constant et sourd ?",
                "headaches2": "Ressentez-vous une douleur lancinante, généralement d’un côté de la tête ?",
                "headaches3": "Vos maux de tête s’accompagnent-ils d’une sensibilité à la lumière, au bruit, de nausées ou de vomissements ?",
                "headaches4": "Ressentez-vous des troubles visuels, tels que des éclairs lumineux ou des points devant vos yeux, avant que le mal de tête ne commence ?",
                "headaches5": "Lorsque vous avez un mal de tête, ressentez-vous une rougeur des yeux, un larmoiement ou un gonflement de la paupière du côté de la douleur, ou un nez bouché ?",
                "movementGeneral": "Avez-vous remarqué des tremblements dans vos membres, une raideur musculaire ou des difficultés à bouger ?",
                "movement1": "Ressentez-vous des tremblements dans vos mains ou d’autres parties de votre corps ?",
                "movement2": "Le tremblement s’aggrave-t-il au repos ?",
                "movement3": "Ressentez-vous une raideur dans vos muscles qui rend difficile le démarrage du mouvement ?",
                "movement4": "Avez-vous remarqué un ralentissement dans vos activités quotidiennes ?",
                "movement5": "Avez-vous remarqué des problèmes lors de la marche, comme des petits pas ou des chutes inattendues ?",
                "memoryIssuesGeneral": "Avez-vous des difficultés à mémoriser de nouvelles informations, à trouver vos mots ou à vous orienter dans l’espace ?",
                "memoryIssues1": "Avez-vous des difficultés à vous souvenir des événements récents ?",
                "memoryIssues2": "Vous oubliez parfois des noms, des dates ou avez du mal à trouver les bons mots lorsque vous parlez ?",
                "memoryIssues3": "Avez-vous de plus en plus besoin d’aide dans les activités quotidiennes telles que l’utilisation d’appareils électroménagers, la gestion des finances, la planification ?",
                "memoryIssues4": "Avez-vous des difficultés à vous orienter dans des endroits que vous connaissez déjà ?",
                "memoryIssues5": "Remarquez-vous des changements dans votre comportement, tels qu’un retrait social, de l’apathie, de l’impulsivité ou un manque de tact ?",
                "epilepsyGeneral": "Avez-vous déjà ressenti une perte de connaissance, des convulsions incontrôlables ou de brefs moments de suspension ?",
                "epilepsy1": "Connaissez-vous des pertes de connaissance soudaines et inexpliquées ?",
                "epilepsy2": "Lorsque vous perdez connaissance, vous sentez-vous confus ou avez-vous du mal à reprendre immédiatement vos activités normales ?",
                "epilepsy3": "Les pertes de connaissance sont-elles accompagnées de convulsions ?",
                "epilepsy4": "Ressentez-vous des sentiments ou des signaux spécifiques (par exemple « aura ») avant de perdre connaissance/d’avoir des convulsions ?",
                "epilepsy5": "Y a-t-il des moments où vous cessez soudainement de répondre et vous « figez » pendant un court instant ?",
                "spineIssuesGeneral": "Souffrez-vous de douleurs chroniques ou aiguës au dos ou au cou ?",
                "spineIssues1": "Souffrez-vous de douleurs chroniques au dos ou au cou ?",
                "spineIssues2": "Ressentez-vous des engourdissements dans vos bras ou vos jambes ?",
                "spineIssues3": "Avez-vous remarqué une perte de force dans vos bras ou vos jambes ?",
                "spineIssues4": "Avez-vous des difficultés à contrôler votre vessie ou vos intestins ?",
                "spineIssues5": "La douleur irradie-t-elle de la colonne vertébrale vers d’autres parties du corps ?",
                "mutipleSclerosisGeneral": "Avez-vous remarqué une sensation de picotement, d’engourdissement ou d’autres changements dans les sensations sensorielles qui peuvent survenir dans différentes parties du corps ?",
                "mutipleSclerosis1": "Ressentez-vous des périodes inattendues de faiblesse dans vos membres ou des difficultés à marcher ?",
                "mutipleSclerosis2": "Avez-vous des problèmes d'équilibre ou de coordination ?",
                "mutipleSclerosis3": "Ressentez-vous des épisodes soudains de troubles visuels, tels qu’une vision double ou une vision floue ?",
                "mutipleSclerosis4": "Ressentez-vous une fatigue inhabituelle qui n’est pas liée à l’exercice ?",
                "mutipleSclerosis5": "Ressentez-vous des douleurs musculaires et des crampes ?",
                "dizzinessGeneral": "Souffrez-vous de vertiges ou de problèmes d’équilibre ?",
                "dizziness1": "Les étourdissements apparaissent-ils soudainement et sans avertissement ?",
                "dizziness2": "Ressentez-vous également des nausées ou des vomissements lorsque vous vous sentez étourdi ?",
                "dizziness3": "Les étourdissements deviennent-ils plus graves lorsque vous changez de position corporelle, par exemple en vous levant ou en inclinant la tête ?",
                "dizziness4": "L’apparition de vertiges est-elle liée à des maux d’oreille ou à des changements dans l’audition ?",
                "dizziness5": "Les étourdissements sont-ils accompagnés d’acouphènes ou d’une sensation de plénitude dans les oreilles ?",
                "otherSymptomsGeneral": "Présentez-vous d’autres symptômes qui ne correspondent pas aux catégories ci-dessus ?",
                "otherSymptoms1": "Avez-vous des difficultés à avaler ou des étouffements fréquents lorsque vous mangez ?",
                "otherSymptoms2": "Avez-vous des troubles du sommeil, comme de l’insomnie ou une somnolence diurne excessive ?",
                "otherSymptoms3": "Éprouvez-vous périodiquement des difficultés de concentration ou un « brouillard cérébral » qui vous empêche de vous concentrer sur vos tâches ?",
                "otherSymptoms4": "Avez-vous remarqué que vos ronflements commencent ou s’aggravent pendant votre sommeil ?",
                "otherSymptoms5": "Ressentez-vous parfois une faiblesse soudaine dans une partie de votre corps ou sur un côté de votre corps ?"
              }
            },
            "child": {
              "field": {
                "headachesGeneral": "Votre enfant a des maux de tête ?",
                "headaches1": "Votre enfant souffre-t-il d’un mal de tête constant et sourd ?",
                "headaches2": "S'agit-il d'une douleur lancinante, généralement unilatérale ?",
                "headaches3": "La douleur est-elle accompagnée d’une hypersensibilité à la lumière et au son, de nausées et de vomissements ?",
                "headaches4": "L'enfant ressent-il des troubles visuels, tels que des éclairs lumineux ou des taches devant les yeux, avant le début du mal de tête ?",
                "headaches5": "Lorsque vous avez un mal de tête, ressentez-vous une rougeur des yeux, un larmoiement ou un gonflement de la paupière du côté de la douleur, ou un nez bouché ?",
                "movementGeneral": "Votre enfant présente-t-il des troubles du mouvement : tremblements, raideurs, parésie ?",
                "movement1": "Votre enfant a-t-il des tremblements ?",
                "movement2": "Ce tremblement se produit-il au repos ?",
                "movement3": "Ce tremblement se produit-il pendant le mouvement ?",
                "movement4": "Votre enfant souffre-t-il d’une raideur des membres qui s’aggrave ?",
                "movement5": "Le développement moteur de votre enfant ne progresse pas ou régresse ?",
                "epilepsyGeneral": "Votre enfant a-t-il des crises d’épilepsie ?",
                "epilepsy1": "Les crises sont-elles récurrentes ?",
                "epilepsy2": "Les crises sont-elles différentes ou similaires ?",
                "epilepsy3": "Les crises surviennent-elles à l’état d’éveil ?",
                "epilepsy4": "Les crises surviennent-elles pendant le sommeil (la nuit ou le jour) ?",
                "epilepsy5": "Les crises sont-elles généralisées ?",
                "epilepsy6": "Les crises sont-elles focales, c'est-à-dire qu'elles ne touchent pas tout le corps, par exemple un ou deux membres ou une moitié du corps ?",
                "epilepsy7": "Les crises sont-elles rythmiques ?",
                "consciousnessIssuesGeneral": "L'enfant souffre-t-il de troubles de conscience et/ou de perte de conscience ?",
                "consciousnessIssues1": "L’enfant souffre-t-il d’une altération de la conscience ?",
                "consciousnessIssues2": "L'enfant subit-il une perte de connaissance complète lors d'une chute ?",
                "consciousnessIssues3": "Votre enfant a-t-il des blocages ou des pensées ?",
                "consciousnessIssues4": "Les états de conscience altérée durent-ils quelques secondes ?",
                "consciousnessIssues5": "Les états de conscience altérée durent-ils plus de 30 secondes ?",
                "eatingIssuesGeneral": "Votre enfant souffre-t-il d’un trouble alimentaire ?",
                "eatingIssues1": "Votre enfant souffre-t-il d’un manque d’appétit pathologique ?",
                "eatingIssues2": "Votre enfant souffre-t-il d’un appétit excessif pathologique ?",
                "autismGeneral": "L’enfant présente-t-il des caractéristiques ou un diagnostic de trouble du spectre autistique ?",
                "autism1": "L’enfant souffre-t-il d’un trouble du spectre autistique ?",
                "autism2": "L'enfant suspecte-t-il l'autisme ?",
                "psychomotorDevelopmentIssuesGeneral": "L'enfant présente-t-il des troubles du développement psychomoteur ?",
                "psychomotorDevelopmentIssues1": "Le trouble du développement psychomoteur est-il diagnostiqué et confirmé par un médecin/centre médical ?",
                "psychomotorDevelopmentIssues2": "Le trouble du développement psychomoteur est-il seulement suspecté et non confirmé ?"
              }
            }
          }
        },
        "rating": {
          "title": "Merci d'avoir complété le sondage !",
          "subtitle": "Le système Neuroterminal vous indiquera le bon médecin pour votre maladie",
          "showMore": "Afficher plus",
          "diagnosis": {
            "HEADACHES": "<b>Les maux de tête sont un problème neurologique complexe,</b> qui peut prendre des dizaines de formes et d'intensités différentes. <b>Ces affections peuvent être le symptôme de nombreuses maladies,</b> non seulement dans le domaine de la neurologie, mais aussi dans d'autres domaines de la médecine. Il est essentiel que le diagnostic et le traitement des maux de tête soient effectués par un neurologue, un spécialiste en céphalologie, une science consacrée à cette question. <br/><b>Le Centre Neurosphera aborde le diagnostic et le traitement des maux de tête de manière individuelle et en équipe.</b>",
            "MOVEMENT_ISSUES": "<b>Les troubles du mouvement sont un groupe de troubles neurologiques qui se manifestent par divers problèmes de mouvement.</b>Il peut s'agir d'une faiblesse musculaire et d'une parésie, ainsi que de tremblements, de raideurs ou de spasticité. Cette large gamme de symptômes est le résultat de lésions ou de troubles du fonctionnement du système nerveux. <b>Il est important de consulter un spécialiste si vous ressentez des symptômes inquiétants,</b>car un diagnostic précoce et un traitement approprié sont essentiels pour améliorer la qualité de vie du patient. <br/><b>Neurosphera est en mesure de diagnostiquer précisément la cause des problèmes et de sélectionner les méthodes de traitement appropriées.</b>",
            "MEMORY_ISSUES": "<b>Les troubles de la mémoire sont souvent associés à la démence, dont ils constituent l'un des principaux symptômes.</b> Cependant, les problèmes de mémoire peuvent survenir à tout âge et être le signe de diverses maladies, de légères à graves. C'est pourquoi il est si important de consulter un spécialiste si vous ressentez des symptômes inquiétants,</b> qui évaluera de manière exhaustive le fonctionnement de la mémoire et diagnostiquera les causes potentielles. <br/><b>Au Centre Neurosphera, le patient a la possibilité de choisir un spécialiste en neuropsychologie qui effectuera les tests appropriés et diagnostiquera la cause de la maladie, et un neurologue qui sélectionnera la thérapie appropriée pour le patient.</b>",
            "EATING_ISSUES": "<b>Les troubles alimentaires sont des problèmes de santé complexes qui nécessitent une approche globale.</b> Différents spécialistes, tels que des diététiciens, des neurologues et des psychiatres, participent à leur traitement. Bien que les troubles alimentaires soient souvent le symptôme de problèmes mentaux ou physiques plus profonds, <b>un régime alimentaire et une thérapie nutritionnelle bien choisis peuvent être un élément important du traitement, affectant à la fois la santé physique et mentale du patient. <br/><b>Le Centre Neurosphera offre la possibilité de diagnostiquer le problème des troubles alimentaires avec un neurologue ou un diététicien qui préparera un régime alimentaire bien adapté au patient.</b>",
            "CONSCIOUSNESS_ISSUES": "<b>Les troubles de la conscience et de la perception sont des états qui doivent toujours susciter l'inquiétude et inciter à consulter d'urgence un neurologue.</b> Ils sont souvent le premier signe d'une maladie neurologique grave ou d'un autre trouble de santé grave. Pour déterminer précisément la cause de ces affections, <b>il est nécessaire de procéder à des examens complémentaires, tels que l'EEG (électroencéphalographie) et l'IRM (imagerie par résonance magnétique).</b> Ces méthodes de diagnostic permettent d'évaluer l'activité électrique du cerveau et de visualiser en détail ses structures, ce qui est essentiel pour établir le bon diagnostic et mettre en œuvre le traitement approprié. <br/><b>Le Centre Neurosphera aborde le diagnostic et la thérapie de manière individuelle et en équipe. La clinique offre la possibilité de réaliser des examens EEG.</b>",
            "PSYCHOMOTOR_DEVELOPMENT_ISSUES": "<b>Les troubles du développement psychomoteur chez l'enfant constituent un problème clinique complexe qui nécessite une approche spécialisée et globale.</b> En raison de la diversité des symptômes et des causes potentielles, le diagnostic et le traitement doivent être effectués par une équipe multidisciplinaire de spécialistes. <br/><b>Le Centre Neurosphera propose une prise en charge complète, permettant une large gamme de tests et de consultations avec différents spécialistes.</b> Cela permet de déterminer avec précision les causes des troubles et de mettre en œuvre un programme thérapeutique adapté. Un diagnostic et un traitement précoces sont essentiels pour le développement optimal de l'enfant.",
            "EPILEPSY": "<b>Le traitement de l'épilepsie au Centre Neurosphera est notre spécialité.</b> Nous avons 25 ans d'expérience dans le diagnostic et le traitement de cette maladie, ce qui fait de nous l'un des principaux centres en Pologne. <b>Notre équipe, composée de 20 spécialistes, utilise les outils de diagnostic les plus récents,</b> notamment des systèmes avancés basés sur l'intelligence artificielle, pour fournir aux patients des soins complets. Grâce à cela, nous pouvons diagnostiquer et traiter efficacement même les cas d'épilepsie les plus complexes, offrant à nos patients le plus haut niveau de soins médicaux.",
            "SPINE_ISSUES": "Les douleurs de la colonne vertébrale et du dos sont un problème qui touche de nombreuses personnes et qui peut avoir diverses causes. Il s'agit d'une pathologie qui nécessite une approche globale, combinant les connaissances et l'expérience des neurologues et des neurochirurgiens.</b> <br/><b>Au Centre Neurosphera, nous disposons d'une équipe spécialisée qui s'occupe du diagnostic et du traitement de ce type de pathologies.</b> Grâce aux vastes connaissances et à l'expérience de nos spécialistes et à la disponibilité de méthodes de diagnostic modernes, nous sommes en mesure de déterminer avec précision la cause de la douleur et de proposer un traitement optimal. <b>Notre approche multidisciplinaire permet une évaluation complète du patient et l'élaboration d'un plan thérapeutique individuel.</b>",
            "MUTIPLE_SCLEROSIS": "<b>La sclérose en plaques est une maladie neurologique chronique dont l'évolution est très individuelle et peut varier considérablement d'un patient à l'autre.</b> Le diagnostic de la SEP est souvent difficile en raison de la diversité des symptômes, qui peuvent ressembler à ceux d'autres maladies. <b>Un traitement efficace de la sclérose en plaques nécessite une approche spécialisée et la coopération de nombreux spécialistes.</b> La thérapie doit être adaptée aux besoins individuels de chaque patient et inclure à la fois un traitement pharmacologique et non pharmacologique. <b>Une équipe de neurologues expérimentés est essentielle pour un diagnostic correct et une thérapie complète pour les personnes atteintes de SEP.</b>",
            "DIZZINESS": "<b>Les étourdissements sont un état qui est le plus souvent un symptôme et non une maladie indépendante.</b> Ils peuvent être causés par divers facteurs, à la fois laryngologiques (par exemple des troubles de l'équilibre) et neurologiques (par exemple des lésions du système nerveux central). <br/><b>Les patients souffrant de vertiges consultent souvent un neurologue et nous, au Centre Neurosphera, sommes parfaitement préparés à les accueillir.</b> Nous proposons un diagnostic complet qui permet de déterminer précisément la cause des symptômes. <b>Dans certains cas, il peut être nécessaire de consulter un neurochirurgien, notamment lorsque les vertiges sont liés à des problèmes de colonne vertébrale.</b> Grâce à notre approche multidisciplinaire, nous sommes en mesure d'apporter une aide efficace à nos patients.",
            "AUTISM": "<b>Le trouble du spectre autistique est un trouble neurodéveloppemental complexe qui comprend des difficultés de communication sociale, des interactions sociales et des schémas de comportement restreints et répétitifs.</b> L'autisme peut prendre de nombreuses formes, de légères à graves, et coexiste souvent avec d'autres troubles. <b>L'autisme typique et atypique de l'enfant nécessite un diagnostic spécialisé et une approche thérapeutique globale.</b> <br/><b>Au Centre Neurosphera, nous disposons d'une équipe expérimentée de spécialistes qui s'occupent du diagnostic et du traitement de l'autisme et de ses complications.</b> Nous proposons une large gamme de services, adaptés aux besoins individuels de chaque patient.",
            "OTHER_SYMPTOMS": "<b>Les symptômes neurologiques peuvent être extrêmement divers et souvent ambigus.</b> En plus des symptômes plus caractéristiques, tels que l'engourdissement ou les troubles de l'équilibre, les patients signalent également une faiblesse, des douleurs, des troubles du sommeil, de l'irritabilité et des difficultés de concentration. <b>Cette large gamme de symptômes peut rendre difficile un diagnostic précis, car nombre d'entre eux peuvent indiquer des affections neurologiques diverses, et parfois même des troubles ayant une cause différente.</b> <br/><b>Le Centre Neurosphera dispose d'équipements spécialisés et d'une équipe expérimentée,</b> grâce auxquels nous sommes en mesure d'effectuer des diagnostics précis et d'indiquer la cause des symptômes neurologiques même les plus vagues."
          }
        }
      }
    },
    "error": {
      "LOGIN_CRED_MISSING": "Login et mot de passe sont requis",
      "LOGIN_INVALID_CRED": "Nom d'utilisateur ou mot de passe invalide",
      "PASS_AND_CONF_NOT_EQUAL": "Le mot de passe et la confirmation diffèrent",
      "AUTHORIZATION": "accès non autorisé à la ressource, routage vers la page de connexion",
      "WRONG_PROFILE": "Échec de la connexion en raison d'un mauvais profil sélectionné"
    },
    "validation": {
      "required": "Champ requis",
      "alphanumeric": "Ne doit contenir que des lettres et des chiffres",
      "email": "Adresse e-mail invalide",
      "invalidRange": "Plage non valide",
      "nip": "Numéro NIP invalide",
      "regon": "Numéro REGON invalide",
      "pesel": "Numéro PESEL invalide",
      "max": "Jusqu'à {length} caractères autorisés",
      "min": "Au moins {length} caractères requis",
      "passNonMatching": "Les mots de passe ne correspondent pas",
      "maxFileSize": "Le fichier doit contenir moins de {size} Mo",
      "pwz": "Numéro PWZ invalide",
      "zusCertificate": "L'extension de fichier autorisée est .pfx",
      "password": {
        "lowercase": "Lettre minuscule obligatoire",
        "uppercase": "Lettre majuscule obligatoire",
        "minimumChar": "{length} caractères au minimum",
        "numericChar": "Un numéro requis",
        "specialChar": "Caractère spécial requis, les caractères disponibles sont !@$\\-_.*"
      }
    },
    "enums": Enums.fr,
    "serverErrors": ServerErrors.fr,
    "office": {
      "phone": "+48 609 463 191",
      "phoneHref": "tel:+48609463191",
      "mail": "biuro@neurosphera.pl",
      "mailHref": "mailto:biuro@neurosphera.pl"
    },
    "appointWidget": {
      "appoint": {
        "title": "Planifier une visite",
        "visitTypeLabel": "Type de visite",
        "visitLocationLabel": "Lieu",
        "doctorListDisclaimer": "Le prix final dépend du type de visite",
        "visitTypeListDisclaimer": "Le prix final dépend du type de visite",
        "doctorListHeader": "Choisissez un médecin",
        "visitTypeListHeader": "Choisissez le type de visite",
        "visitLocationListHeader": "Choisissez le lieu de visite",
        "doctorLabel": "Médecin",
        "startingPricePrefix": "à partir de",
        "appointment": {
          "selectHeader": {
            "START_PACKAGE": "Planifier la visite 0 et la visite 1",
            "MIGRATION_VISIT": "Planifier une visite de migration",
            "STANDARD_VISIT": "Planifier une visite régulière",
            "NEUROSURGEON_CONSULTATION": "Planifier une consultation neurochirurgicale",
            "NEUROPSYCHOLOGIST_CONSULTATION": "Planifier une consultation neuropsychologique",
            "NEUROLOGOPEDIST_CONSULTATION": "Planifier une consultation d'orthophoniste (neurologique)",
            "PSYCHIATRIST_CONSULTATION": "Planifier une consultation psychiatrique",
            "GENETICIST_CONSULTATION": "Planifier une consultation de généticien",
            "EPILEPTOLOGIST_CONSULTATION": "Planifier une consultation épileptologue",
            "EEG_CONSULTATION": "Planifier un examen EEG",
            "NEUROLOGIST_CONSULTATION": "Planifier une consultation neurologique",
            "DIETICIAN_CONSULTATION": "Planifier une consultation diététique",
            "VNS_CONSULTATION": "Planifier une visite VNS",
            "ABSENT_CONSULTATION": "Planifier une consultation par contumace",
            "METABOLIC_CONSULTATION": "Planifier une consultation métabolique",
            "SLEEP_DISTURBANCE_CONSULTATION": "Planifier une consultation sur les troubles du sommeil",
            "ADHD_PACKAGE": "Planifier des visites de forfaits TDAH",
            "REGULAR_VISIT_EEG_PACKAGE": "Planifier des visites régulières pour l'épilepsie et un EEG"
          },
          "description": {
            "START_PACKAGE": "La visite 0 est une visite gratuite pour recueillir un entretien détaillé sur les antécédents médicaux du patient, précédant la visite 1. Lors de la visite 1, un diagnostic, une thérapie et un plan de soins complet pour l'année suivante seront établis. La visite 0 est d'env. 1 heure, Visite 1 - 30 minutes ; les deux peuvent avoir lieu sur place ou en ligne.",
            "MIGRATION_VISIT": "Visite migratoire",
            "STANDARD_VISIT": "La visite épileptologique régulière est destinée aux patients actuels de Neurosphera qui ont déjà eu la visite 1 ou la visite de migration.",
            "NEUROSURGEON_CONSULTATION": "La consultation est recommandée en cas de problèmes neurologiques chroniques, tels que :",
            "NEUROPSYCHOLOGIST_CONSULTATION": "Indiqué en cas de :",
            "NEUROLOGOPEDIST_CONSULTATION": "Consultation orthophoniste (neurologique)",
            "PSYCHIATRIST_CONSULTATION": "Une visite pour les personnes aux prises avec des problèmes de santé mentale, comprenant :",
            "GENETICIST_CONSULTATION": "Indiqué en cas de :",
            "EPILEPTOLOGIST_CONSULTATION": "Consultation - épileptologue",
            "EEG_CONSULTATION": "Examen de base dans le diagnostic de l'épilepsie, ainsi que:",
            "NEUROLOGIST_CONSULTATION": "Indiqué en cas de :",
            "DIETICIAN_CONSULTATION": "Visite pour les personnes :",
            "VNS_CONSULTATION": "Visite VNS",
            "ABSENT_CONSULTATION": "Consultation par contumace",
            "METABOLIC_CONSULTATION": "Indiqué dans le cas de défauts innés du métabolisme. Certaines maladies métaboliques peuvent contribuer à l'apparition de l'épilepsie. Il vaut la peine d'évaluer s'il y a une suspicion de la maladie dans les tests génétiques.",
            "SLEEP_DISTURBANCE_CONSULTATION": "Indiqué en cas de :",
            "ADHD_PACKAGE": "Le forfait comprend 2 visites chez un psychologue (chacune d'une durée de 1h30) et une visite chez un neurologue ou une visite chez un psychiatre. Le colis s'adresse aux personnes qui constatent :",
            "REGULAR_VISIT_EEG_PACKAGE": "Le forfait comprend un test EEG et une visite stationnaire régulière. Le package s'adresse aux patients actuels en neurosphère qui ont déjà effectué la visite 1 ou la visite de migration. Inscrivez-vous à un forfait si :",
            "MMPI2_PACKAGE": "Nous permettons de diagnostiquer les troubles de la personnalité en utilisant l'outil d'évaluation clinique le plus complet et le plus universel disponible en Pologne - le Minnesota Multidimensional Personality Inventory (l'adaptation complète du MMPI-2 a été publiée en 2012).",
            "CBT_PACKAGE": "La thérapie cognitivo-comportementale (TCC) pour l'insomnie est considérée comme le traitement de première intention pour les personnes aux prises avec l'insomnie, recommandée par des autorités telles que les National Institutes of Health (NIH), le National Institute for Health and Care Excellence (NICE) et l'American College of Physician."
          },
          "bulletPoints": {
            "DIETICIAN_CONSULTATION": [
              "for whom the ketogenic diet is indicated by physician or is of interest",
              "who are using a ketogenic diet and want to review their current treatment",
              "they want to verify their current diet"
            ],
            "EEG_CONSULTATION": [
              "to indicate the causes of sleep disorders",
              "in head injuries and recurring headaches",
              "fainting or loss of consciousness"
            ],
            "NEUROLOGIST_CONSULTATION": [
              "fainting and loss of consciousness, problems with balance and coordination",
              "chronic headaches combined with dizziness",
              "deterioration of memory and trouble concentrating",
              "tingling in the extremities with a disturbed sensation"
            ],
            "PSYCHIATRIST_CONSULTATION": [
              "depression",
              "anxiety disorders",
              "sleep problems",
              "unnaturally elevated mood and hyperactivity"
            ],
            "GENETICIST_CONSULTATION": [
              "family history of diseases with genetic causes",
              "the exclusion of congenital disease as a cause of epilepsy",
              "assessment of the risk of a child having epilepsy in the event of a planned pregnancy",
              "before performing the WES test"
            ],
            "NEUROSURGEON_CONSULTATION": [
              "chronic pain and injuries of the spine",
              "brain tumors and neoplastic changes in the spine",
              "numbness, tingling and paresis of the limbs",
              "severe headaches and dizziness, visual and hearing disturbances",
              "consultation of magnetic resonance imaging of the head"
            ],
            "NEUROPSYCHOLOGIST_CONSULTATION": [
              "problems in normal functioning that have arisen as a result of, for example, head injuries, brain tumors, diseases that destroy nervous tissue",
              "impairment of memory or concentration",
              "speech, orientation or wayfinding difficulties",
              "visual disturbances despite normal ophthalmic results"
            ],
            "SLEEP_DISTURBANCE_CONSULTATION": [
              "daytime sleepiness or irritability",
              "sleepiness or insomnia caused by emotional factors",
              "disruption of the daily rhythm of sleep and waking up",
              "snoring while sleeping"
            ],
            "ADHD_PACKAGE": [
              "Difficulty Organizing",
              "Underestimating time",
              "interpersonal difficulties",
              "Emotional difficulties"
            ],
            "REGULAR_VISIT_EEG_PACKAGE": [
              "You haven't had an EEG done in the last year",
              "You are planning a regular stationary visit with an Egyptologist",
              "You feel a lack of improvement or deterioration of your health"
            ]
          },
          "detailsHeader": {
            "VISIT_0": "Visite 0",
            "MIGRATION_VISIT": "Visite migratoire",
            "VISIT_1": "Visite 1",
            "START_PACKAGE": "Visite 0 et Visite 1",
            "STANDARD_VISIT": "Visite régulière",
            "NEUROSURGEON_CONSULTATION": "Consultation - neurochirurgien",
            "NEUROPSYCHOLOGIST_CONSULTATION": "Consultation - neuropsychologue",
            "NEUROLOGOPEDIST_CONSULTATION": "Consultation - orthophoniste (neurologue)",
            "PSYCHIATRIST_CONSULTATION": "Consultation - psychiatre",
            "GENETICIST_CONSULTATION": "Consultation - généticien",
            "EPILEPTOLOGIST_CONSULTATION": "Consultation - épileptologue",
            "EEG_CONSULTATION": "Planifier un test EEG",
            "NEUROLOGIST_CONSULTATION": "Consultation - neurologue",
            "DIETICIAN_CONSULTATION": "Consultation - diététiste",
            "VNS_CONSULTATION": "Visite VNS",
            "ABSENT_CONSULTATION": "Consultation par contumace",
            "METABOLIC_CONSULTATION": "Consultation métabolique",
            "SLEEP_DISTURBANCE_CONSULTATION": "Consultation sur les troubles du sommeil",
            "ADHD_PACKAGE": "Forfait TDAH",
            "REGULAR_VISIT_EEG_PACKAGE": "Visite régulière pour épilepsie et EEG"
          },
          "details": {
            "VISIT_0": "La visite 0 est une visite préparatoire au cours de laquelle un entretien approfondi et très détaillé est réalisé. La visite dure env. 1 heure. La visite 0 doit être effectuée avant la visite 1.",
            "MIGRATION_VISIT": "",
            "VISIT_1": "Lors de la visite 1, le diagnostic est confirmé et un plan de soins complet est établi. La visite dure env. 30 minutes.",
            "STANDARD_VISIT": "La durée de la visite est d'environ 30 minutes.",
            "NEUROSURGEON_CONSULTATION": "La durée de la consultation est d'env. 45 minutes.",
            "NEUROPSYCHOLOGIST_CONSULTATION": "La durée de la consultation est d'environ 1 heure.",
            "NEUROLOGOPEDIST_CONSULTATION": "Consultation - orthophoniste (neurologue)",
            "PSYCHIATRIST_CONSULTATION": "La durée de la consultation est d'env. 45 minutes.",
            "GENETICIST_CONSULTATION": "La durée de la consultation est d'env. 45 minutes.",
            "EPILEPTOLOGIST_CONSULTATION": "Consultation - épileptologue",
            "EEG_CONSULTATION": "Planifier une évaluation EEG",
            "NEUROLOGIST_CONSULTATION": "La durée de la consultation est d'env. 30 minutes.",
            "DIETICIAN_CONSULTATION": "La durée de la consultation est d'environ 1 heure.",
            "VNS_CONSULTATION": "Visite VNS",
            "ABSENT_CONSULTATION": "Consultation par contumace",
            "METABOLIC_CONSULTATION": "La durée de la consultation est d'env. 30 minutes.",
            "SLEEP_DISTURBANCE_CONSULTATION": "La durée de la consultation est d'env. 45 minutes."
          }
        },
        "remoteVisitDescription": "Visite à distance via Neuroterminal. Un appareil avec une caméra et un microphone et connecté à Internet est requis",
        "details": "Des détails",
        "visitHeader": {
          "VISIT_0": "Visite 0",
          "MIGRATION_VISIT": "Visite migratoire",
          "VISIT_1": "Visite 1",
          "START_PACKAGE": "Visite 0 et Visite 1",
          "STANDARD_VISIT": "Visite régulière",
          "NEUROSURGEON_CONSULTATION": "Consultation - neurochirurgien",
          "NEUROPSYCHOLOGIST_CONSULTATION": "Consultation - neuropsychologue",
          "NEUROLOGOPEDIST_CONSULTATION": "Consultation - orthophoniste (neurologue)",
          "PSYCHIATRIST_CONSULTATION": "Consultation - psychiatre",
          "GENETICIST_CONSULTATION": "Consultation - généticien",
          "EPILEPTOLOGIST_CONSULTATION": "Consultation - épileptologue",
          "EEG_CONSULTATION": "Planifier un test EEG",
          "NEUROLOGIST_CONSULTATION": "Consultation - neurologue",
          "DIETICIAN_CONSULTATION": "Consultation - diététiste",
          "VNS_CONSULTATION": "Visite VNS",
          "ABSENT_CONSULTATION": "Consultation par contumace",
          "METABOLIC_CONSULTATION": "Consultation métabolique",
          "SLEEP_DISTURBANCE_CONSULTATION": "Consultation sur les troubles du sommeil",
          "ADHD_PACKAGE": "Forfait TDAH",
          "REGULAR_VISIT_EEG_PACKAGE": "Visite régulière pour épilepsie et EEG"
        },
        "instruction": {
          "START_PACKAGE": "Si vous ne voyez pas la date qui vous convient, essayez de choisir un autre médecin, un autre type de visite ou une date antérieure de la visite 0"
        },
        "visitPriceLabel": "Le prix de la visite",
        "showAllSlots": "afficher plus d'heures",
        "hideSlots": "s'effondrer",
        "nextLabel": "Suivant",
        "validationErrorHeader": "Pour accéder au résumé, sélectionnez :",
        "ok": "D'accord",
        "validationError": {
          "slot": "Date",
          "location": "Lieu",
          "doctor": "Médecin",
          "service": "Type de visite"
        },
        "chooseLabel": "Choisir",
        "doctorMissingLabel": "Médecin",
        "locationMissingLabel": "Type de visite",
        "andLabel": "et",
        "unavailableDoctors": "Médecins indisponibles pour la date, le type et/ou le type de visite sélectionnés",
        "ADULT": "Adulte",
        "CHILD": "Enfant",
        "visitNotAvailable": "Nous sommes désolés, mais la date que vous avez sélectionnée <b>{visitName}</b> n'est pas disponible. Revenez en arrière et choisissez une autre date",
        "visitNotSaved": "La visite n'a pas encore été enregistrée. Revenez au résumé pour terminer le processus de prise de rendez-vous.",
        "clearSelection": "Effacer la sélection",
        "visitOrder": "Est-ce votre première visite chez ce spécialiste ?",
        "visitOrderFirst": "Oui",
        "visitOrderNext": "Non"
      },
      "exitPoll": {
        "title": "Pourquoi veux-tu partir ?",
        "description": "Votre réponse est très importante pour nous ! Grâce à lui, nous pourrons améliorer nos services.",
        "SERVICE": "Je n'ai pas trouvé le service qui m'intéresse",
        "DATE": "Aucune date de visite appropriée",
        "PRICE": "Je n'aime pas le prix du service",
        "OTHER": "Autre",
        "sendResponse": "Envoyer une réponse",
        "back": "Retour au site",
        "whatService": "Quel service recherchiez-vous ?",
        "enterMessage": "Veuillez entrer votre message",
        "reasonMissing": "Veuillez fournir une raison pour votre démission"
      },
      "main": {
        "exitPopup": {
          "description": "Êtes-vous sûr de vouloir mettre fin au processus de nomination ? Vos sélections ne seront pas enregistrées.",
          "confirm": "Oui, je veux partir",
          "decline": "Non, revenir à la prise de rendez-vous"
        }
      },
      "summary": {
        "title": "Résumé",
        "grossPrice": "Montant total",
        "availableOnlinePayments": "Nous acceptons les paiements en ligne",
        "transfer": "Virement bancaire",
        "installments": "0% de versements",
        "payment": "Paiement",
        "paymentDescription": "Pour procéder au paiement, merci de nous communiquer votre adresse email. Nous vous enverrons un récapitulatif ainsi qu'un lien de paiement.",
        "descriptionExistingAccount": "Si vous avez un compte Neuroterminal, veuillez fournir l'adresse e-mail que vous utilisez pour vous connecter.",
        "makeReservations": "Prendre rendez-vous",
        "makeReservation": "Prendre rendez-vous",
        "unexpectedError": "Une erreur inattendue s'est produite lors de la tentative de prise de rendez-vous. Veuillez réessayer."
      },
      "thanku": {
        "title": "Merci!",
        "emailHasBeenSent": "Nous avons envoyé un message avec un résumé et un lien de paiement à l'adresse e-mail fournie :",
        "finalizeAppointment": "Accédez à votre boîte mail et <b>terminez le processus de prise de rendez-vous</b> en cliquant sur le lien de paiement envoyé. Vous avez jusqu'au <b>{deadline}</b> pour terminer le processus de prise de rendez-vous.",
        "resendEmail": "Vous n'avez pas reçu l'e-mail ? Vérifiez votre dossier Spam ou",
        "resendEmailLink": "Renvoyer l'e-mail",
        "resendEmailDone": "L'e-mail a été renvoyé",
        "backToNeurosphera": "Retour à neurosphera.pl"
      }
    },
    "server": {
      "episode": {
        "description": "Épisode de crise ajouté lors de la visite"
      },
      "generatedepisode": {
        "description": "Épisode de crise ajouté lors de la visite"
      }
    },
    "dates": {
      "today": "aujourd'hui",
      "tomorrow": "demain"
    }
  },
  "formats": {
    "currency": {
      "style": "devise",
      "currency": "USD"
    }
  }
}