/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ConsultationBasicDto } from './consultation-basic-dto';
import { DoctorBasicDto } from './doctor-basic-dto';
import { DoctorDto } from './doctor-dto';
import { DoctorPricelistItemDto } from './doctor-pricelist-item-dto';
import { DoctorWorkPlaceDto } from './doctor-work-place-dto';
import { PatientDto } from './patient-dto';

/**
 * 
 * @export
 * @interface VisitDto
 */
export interface VisitDto {
    /**
     * 
     * @type {boolean}
     * @memberof VisitDto
     */
    'additional'?: boolean;
    /**
     * 
     * @type {Array<DoctorBasicDto>}
     * @memberof VisitDto
     */
    'additionalDoctors'?: Array<DoctorBasicDto>;
    /**
     * 
     * @type {boolean}
     * @memberof VisitDto
     */
    'availableToChange'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VisitDto
     */
    'cancellationTime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VisitDto
     */
    'cancelled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VisitDto
     */
    'cancelledByDoctor'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VisitDto
     */
    'cancelledByPatient'?: boolean;
    /**
     * 
     * @type {ConsultationBasicDto}
     * @memberof VisitDto
     */
    'consultation'?: ConsultationBasicDto;
    /**
     * 
     * @type {string}
     * @memberof VisitDto
     */
    'description'?: string;
    /**
     * 
     * @type {DoctorDto}
     * @memberof VisitDto
     */
    'doctor'?: DoctorDto;
    /**
     * 
     * @type {number}
     * @memberof VisitDto
     */
    'doctorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof VisitDto
     */
    'doctorPackageId'?: number;
    /**
     * 
     * @type {DoctorPricelistItemDto}
     * @memberof VisitDto
     */
    'doctorPricelistItem'?: DoctorPricelistItemDto;
    /**
     * 
     * @type {boolean}
     * @memberof VisitDto
     */
    'done'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VisitDto
     */
    'doneTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof VisitDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof VisitDto
     */
    'lastVisitTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof VisitDto
     */
    'medicalRequestId'?: number;
    /**
     * 
     * @type {string}
     * @memberof VisitDto
     */
    'medicalService'?: VisitDtoMedicalServiceEnum;
    /**
     * 
     * @type {boolean}
     * @memberof VisitDto
     */
    'paid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VisitDto
     */
    'partner'?: string;
    /**
     * 
     * @type {PatientDto}
     * @memberof VisitDto
     */
    'patient'?: PatientDto;
    /**
     * 
     * @type {number}
     * @memberof VisitDto
     */
    'patientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof VisitDto
     */
    'paymentMarking'?: VisitDtoPaymentMarkingEnum;
    /**
     * 
     * @type {string}
     * @memberof VisitDto
     */
    'publicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitDto
     */
    'timeFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitDto
     */
    'timeTo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VisitDto
     */
    'toMove'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VisitDto
     */
    'visitPackageCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof VisitDto
     */
    'visitPackageId'?: number;
    /**
     * 
     * @type {string}
     * @memberof VisitDto
     */
    'visitType'?: VisitDtoVisitTypeEnum;
    /**
     * 
     * @type {DoctorWorkPlaceDto}
     * @memberof VisitDto
     */
    'workPlace'?: DoctorWorkPlaceDto;
    /**
     * 
     * @type {number}
     * @memberof VisitDto
     */
    'workPlaceId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VisitDtoMedicalServiceEnum {
    Visit0 = 'VISIT_0',
    Visit1 = 'VISIT_1',
    StandardVisit = 'STANDARD_VISIT',
    FollowupVisit = 'FOLLOWUP_VISIT',
    Message = 'MESSAGE',
    Prescription = 'PRESCRIPTION',
    AbsentConsultation = 'ABSENT_CONSULTATION',
    NeurosurgeonConsultation = 'NEUROSURGEON_CONSULTATION',
    NeuropsychologistConsultation = 'NEUROPSYCHOLOGIST_CONSULTATION',
    NeurologopedistConsultation = 'NEUROLOGOPEDIST_CONSULTATION',
    PsychiatristConsultation = 'PSYCHIATRIST_CONSULTATION',
    GeneticistConsultation = 'GENETICIST_CONSULTATION',
    EpileptologistConsultation = 'EPILEPTOLOGIST_CONSULTATION',
    NeurologistConsultation = 'NEUROLOGIST_CONSULTATION',
    DieticianConsultation = 'DIETICIAN_CONSULTATION',
    VnsConsultation = 'VNS_CONSULTATION',
    MetabolicConsultation = 'METABOLIC_CONSULTATION',
    SleepDisturbanceConsultation = 'SLEEP_DISTURBANCE_CONSULTATION',
    CaseConference = 'CASE_CONFERENCE',
    EegConsultation = 'EEG_CONSULTATION',
    VeegNightConsultation = 'VEEG_NIGHT_CONSULTATION',
    VeegDayConsultation = 'VEEG_DAY_CONSULTATION',
    VeegOnStandbyConsultation1H = 'VEEG_ON_STANDBY_CONSULTATION_1H',
    VeegOnStandbyConsultation2H = 'VEEG_ON_STANDBY_CONSULTATION_2H',
    VeegOnStandbyConsultation6H = 'VEEG_ON_STANDBY_CONSULTATION_6H',
    VeegDuringSleepConsultation1H = 'VEEG_DURING_SLEEP_CONSULTATION_1H',
    VeegDuringSleepConsultation2H = 'VEEG_DURING_SLEEP_CONSULTATION_2H',
    VeegDuringSleepConsultation6H = 'VEEG_DURING_SLEEP_CONSULTATION_6H',
    MigrationVisit = 'MIGRATION_VISIT',
    VeegOnStandbyConsultation = 'VEEG_ON_STANDBY_CONSULTATION',
    VeegDuringSleepConsultation = 'VEEG_DURING_SLEEP_CONSULTATION',
    PartnerConsultation = 'PARTNER_CONSULTATION'
}
/**
    * @export
    * @enum {string}
    */
export enum VisitDtoPaymentMarkingEnum {
    WaitingForPayment = 'WAITING_FOR_PAYMENT',
    PaymentReceived = 'PAYMENT_RECEIVED'
}
/**
    * @export
    * @enum {string}
    */
export enum VisitDtoVisitTypeEnum {
    Remote = 'REMOTE',
    Stationary = 'STATIONARY',
    Absent = 'ABSENT'
}


