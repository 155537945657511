/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DoctorAccountPricelistInfoDto } from '../model';
// @ts-ignore
import { DoctorBasicDto } from '../model';
// @ts-ignore
import { DoctorNameDto } from '../model';
// @ts-ignore
import { DoctorWithWorkPlacesDto } from '../model';
// @ts-ignore
import { MedicalServiceSystemItemDto } from '../model';
// @ts-ignore
import { PairDoctorAccountGeneralInfoDtoDoctorAccountPracticeInfoDto } from '../model';
/**
 * AdminDoctorApiControllerApi - axios parameter creator
 * @export
 */
export const AdminDoctorApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate: async (doctorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('activate', 'doctorId', doctorId)
            const localVarPath = `/api/admin/doctor/{doctorId}/activate`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivate: async (doctorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('deactivate', 'doctorId', doctorId)
            const localVarPath = `/api/admin/doctor/{doctorId}/deactivate`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPriceListInfo: async (doctorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('getAccountPriceListInfo', 'doctorId', doctorId)
            const localVarPath = `/api/admin/doctor/pricelist/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBySearchPhrase1: async (phrase?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/doctor/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (phrase !== undefined) {
                localVarQueryParameter['phrase'] = phrase;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorDetails: async (doctorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('getDoctorDetails', 'doctorId', doctorId)
            const localVarPath = `/api/admin/doctor/details/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWithWorkPlaces: async (doctorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('getDoctorWithWorkPlaces', 'doctorId', doctorId)
            const localVarPath = `/api/admin/doctor/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctors: async (all?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/doctor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorsForPrescription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/doctor/prescription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemPriceListInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/doctor/pricelist-system`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [academicTitle] 
         * @param {string} [bio] 
         * @param {any} [certificate] 
         * @param {string} [consultationGroupType] 
         * @param {string} [email] 
         * @param {string} [firstName] 
         * @param {string} [isPractise] 
         * @param {string} [isSpecialization] 
         * @param {string} [lastName] 
         * @param {number} [mainWorkPlaceId] 
         * @param {number} [partnerId] 
         * @param {string} [pesel] 
         * @param {string} [phone] 
         * @param {string} [practiceAddressApartmentNr] 
         * @param {string} [practiceAddressCity] 
         * @param {string} [practiceAddressCountry] 
         * @param {string} [practiceAddressHouseNr] 
         * @param {string} [practiceAddressStreet] 
         * @param {string} [practiceAddressZipcode] 
         * @param {string} [practiceInfoName] 
         * @param {string} [practiceInfoNipNr] 
         * @param {string} [practiceInfoRegonNr] 
         * @param {string} [practiceInfoRpwdlNr] 
         * @param {string} [pwz] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerDoctor: async (academicTitle?: string, bio?: string, certificate?: any, consultationGroupType?: string, email?: string, firstName?: string, isPractise?: string, isSpecialization?: string, lastName?: string, mainWorkPlaceId?: number, partnerId?: number, pesel?: string, phone?: string, practiceAddressApartmentNr?: string, practiceAddressCity?: string, practiceAddressCountry?: string, practiceAddressHouseNr?: string, practiceAddressStreet?: string, practiceAddressZipcode?: string, practiceInfoName?: string, practiceInfoNipNr?: string, practiceInfoRegonNr?: string, practiceInfoRpwdlNr?: string, pwz?: string, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/doctor/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (academicTitle !== undefined) { 
                localVarFormParams.append('academicTitle', academicTitle as any);
            }
    
            if (bio !== undefined) { 
                localVarFormParams.append('bio', bio as any);
            }
    
            if (certificate !== undefined) { 
                localVarFormParams.append('certificate', certificate as any);
            }
    
            if (consultationGroupType !== undefined) { 
                localVarFormParams.append('consultationGroupType', consultationGroupType as any);
            }
    
            if (email !== undefined) { 
                localVarFormParams.append('email', email as any);
            }
    
            if (firstName !== undefined) { 
                localVarFormParams.append('firstName', firstName as any);
            }
    
            if (isPractise !== undefined) { 
                localVarFormParams.append('isPractise', isPractise as any);
            }
    
            if (isSpecialization !== undefined) { 
                localVarFormParams.append('isSpecialization', isSpecialization as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.append('lastName', lastName as any);
            }
    
            if (mainWorkPlaceId !== undefined) { 
                localVarFormParams.append('mainWorkPlaceId', mainWorkPlaceId as any);
            }
    
            if (partnerId !== undefined) { 
                localVarFormParams.append('partnerId', partnerId as any);
            }
    
            if (pesel !== undefined) { 
                localVarFormParams.append('pesel', pesel as any);
            }
    
            if (phone !== undefined) { 
                localVarFormParams.append('phone', phone as any);
            }
    
            if (practiceAddressApartmentNr !== undefined) { 
                localVarFormParams.append('practiceAddressApartmentNr', practiceAddressApartmentNr as any);
            }
    
            if (practiceAddressCity !== undefined) { 
                localVarFormParams.append('practiceAddressCity', practiceAddressCity as any);
            }
    
            if (practiceAddressCountry !== undefined) { 
                localVarFormParams.append('practiceAddressCountry', practiceAddressCountry as any);
            }
    
            if (practiceAddressHouseNr !== undefined) { 
                localVarFormParams.append('practiceAddressHouseNr', practiceAddressHouseNr as any);
            }
    
            if (practiceAddressStreet !== undefined) { 
                localVarFormParams.append('practiceAddressStreet', practiceAddressStreet as any);
            }
    
            if (practiceAddressZipcode !== undefined) { 
                localVarFormParams.append('practiceAddressZipcode', practiceAddressZipcode as any);
            }
    
            if (practiceInfoName !== undefined) { 
                localVarFormParams.append('practiceInfoName', practiceInfoName as any);
            }
    
            if (practiceInfoNipNr !== undefined) { 
                localVarFormParams.append('practiceInfoNipNr', practiceInfoNipNr as any);
            }
    
            if (practiceInfoRegonNr !== undefined) { 
                localVarFormParams.append('practiceInfoRegonNr', practiceInfoRegonNr as any);
            }
    
            if (practiceInfoRpwdlNr !== undefined) { 
                localVarFormParams.append('practiceInfoRpwdlNr', practiceInfoRpwdlNr as any);
            }
    
            if (pwz !== undefined) { 
                localVarFormParams.append('pwz', pwz as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('type', type as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {DoctorAccountPricelistInfoDto} [doctorAccountPricelistInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccountPriceListInfo: async (doctorId: number, doctorAccountPricelistInfoDto?: DoctorAccountPricelistInfoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('saveAccountPriceListInfo', 'doctorId', doctorId)
            const localVarPath = `/api/admin/doctor/pricelist/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorAccountPricelistInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<MedicalServiceSystemItemDto>} medicalServiceSystemItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSystemPriceListInfo: async (medicalServiceSystemItemDto: Array<MedicalServiceSystemItemDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'medicalServiceSystemItemDto' is not null or undefined
            assertParamExists('saveSystemPriceListInfo', 'medicalServiceSystemItemDto', medicalServiceSystemItemDto)
            const localVarPath = `/api/admin/doctor/pricelist-system`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medicalServiceSystemItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminDoctorApiControllerApi - functional programming interface
 * @export
 */
export const AdminDoctorApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminDoctorApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activate(doctorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activate(doctorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivate(doctorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivate(doctorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountPriceListInfo(doctorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorAccountPricelistInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountPriceListInfo(doctorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBySearchPhrase1(phrase?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorBasicDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBySearchPhrase1(phrase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorDetails(doctorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PairDoctorAccountGeneralInfoDtoDoctorAccountPracticeInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorDetails(doctorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorWithWorkPlaces(doctorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorWithWorkPlacesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorWithWorkPlaces(doctorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctors(all?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorWithWorkPlacesDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctors(all, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorsForPrescription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorNameDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorsForPrescription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemPriceListInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<MedicalServiceSystemItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemPriceListInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [academicTitle] 
         * @param {string} [bio] 
         * @param {any} [certificate] 
         * @param {string} [consultationGroupType] 
         * @param {string} [email] 
         * @param {string} [firstName] 
         * @param {string} [isPractise] 
         * @param {string} [isSpecialization] 
         * @param {string} [lastName] 
         * @param {number} [mainWorkPlaceId] 
         * @param {number} [partnerId] 
         * @param {string} [pesel] 
         * @param {string} [phone] 
         * @param {string} [practiceAddressApartmentNr] 
         * @param {string} [practiceAddressCity] 
         * @param {string} [practiceAddressCountry] 
         * @param {string} [practiceAddressHouseNr] 
         * @param {string} [practiceAddressStreet] 
         * @param {string} [practiceAddressZipcode] 
         * @param {string} [practiceInfoName] 
         * @param {string} [practiceInfoNipNr] 
         * @param {string} [practiceInfoRegonNr] 
         * @param {string} [practiceInfoRpwdlNr] 
         * @param {string} [pwz] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerDoctor(academicTitle?: string, bio?: string, certificate?: any, consultationGroupType?: string, email?: string, firstName?: string, isPractise?: string, isSpecialization?: string, lastName?: string, mainWorkPlaceId?: number, partnerId?: number, pesel?: string, phone?: string, practiceAddressApartmentNr?: string, practiceAddressCity?: string, practiceAddressCountry?: string, practiceAddressHouseNr?: string, practiceAddressStreet?: string, practiceAddressZipcode?: string, practiceInfoName?: string, practiceInfoNipNr?: string, practiceInfoRegonNr?: string, practiceInfoRpwdlNr?: string, pwz?: string, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerDoctor(academicTitle, bio, certificate, consultationGroupType, email, firstName, isPractise, isSpecialization, lastName, mainWorkPlaceId, partnerId, pesel, phone, practiceAddressApartmentNr, practiceAddressCity, practiceAddressCountry, practiceAddressHouseNr, practiceAddressStreet, practiceAddressZipcode, practiceInfoName, practiceInfoNipNr, practiceInfoRegonNr, practiceInfoRpwdlNr, pwz, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {DoctorAccountPricelistInfoDto} [doctorAccountPricelistInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAccountPriceListInfo(doctorId: number, doctorAccountPricelistInfoDto?: DoctorAccountPricelistInfoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorAccountPricelistInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAccountPriceListInfo(doctorId, doctorAccountPricelistInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<MedicalServiceSystemItemDto>} medicalServiceSystemItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSystemPriceListInfo(medicalServiceSystemItemDto: Array<MedicalServiceSystemItemDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSystemPriceListInfo(medicalServiceSystemItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminDoctorApiControllerApi - factory interface
 * @export
 */
export const AdminDoctorApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminDoctorApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate(doctorId: number, options?: any): Promise<void> {
            return localVarFp.activate(doctorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivate(doctorId: number, options?: any): Promise<void> {
            return localVarFp.deactivate(doctorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPriceListInfo(doctorId: number, options?: any): Promise<DoctorAccountPricelistInfoDto> {
            return localVarFp.getAccountPriceListInfo(doctorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBySearchPhrase1(phrase?: string, options?: any): Promise<Array<DoctorBasicDto>> {
            return localVarFp.getBySearchPhrase1(phrase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorDetails(doctorId: number, options?: any): Promise<PairDoctorAccountGeneralInfoDtoDoctorAccountPracticeInfoDto> {
            return localVarFp.getDoctorDetails(doctorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWithWorkPlaces(doctorId: number, options?: any): Promise<DoctorWithWorkPlacesDto> {
            return localVarFp.getDoctorWithWorkPlaces(doctorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctors(all?: boolean, options?: any): Promise<Array<DoctorWithWorkPlacesDto>> {
            return localVarFp.getDoctors(all, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorsForPrescription(options?: any): Promise<Array<DoctorNameDto>> {
            return localVarFp.getDoctorsForPrescription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemPriceListInfo(options?: any): Promise<Array<MedicalServiceSystemItemDto>> {
            return localVarFp.getSystemPriceListInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [academicTitle] 
         * @param {string} [bio] 
         * @param {any} [certificate] 
         * @param {string} [consultationGroupType] 
         * @param {string} [email] 
         * @param {string} [firstName] 
         * @param {string} [isPractise] 
         * @param {string} [isSpecialization] 
         * @param {string} [lastName] 
         * @param {number} [mainWorkPlaceId] 
         * @param {number} [partnerId] 
         * @param {string} [pesel] 
         * @param {string} [phone] 
         * @param {string} [practiceAddressApartmentNr] 
         * @param {string} [practiceAddressCity] 
         * @param {string} [practiceAddressCountry] 
         * @param {string} [practiceAddressHouseNr] 
         * @param {string} [practiceAddressStreet] 
         * @param {string} [practiceAddressZipcode] 
         * @param {string} [practiceInfoName] 
         * @param {string} [practiceInfoNipNr] 
         * @param {string} [practiceInfoRegonNr] 
         * @param {string} [practiceInfoRpwdlNr] 
         * @param {string} [pwz] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerDoctor(academicTitle?: string, bio?: string, certificate?: any, consultationGroupType?: string, email?: string, firstName?: string, isPractise?: string, isSpecialization?: string, lastName?: string, mainWorkPlaceId?: number, partnerId?: number, pesel?: string, phone?: string, practiceAddressApartmentNr?: string, practiceAddressCity?: string, practiceAddressCountry?: string, practiceAddressHouseNr?: string, practiceAddressStreet?: string, practiceAddressZipcode?: string, practiceInfoName?: string, practiceInfoNipNr?: string, practiceInfoRegonNr?: string, practiceInfoRpwdlNr?: string, pwz?: string, type?: string, options?: any): Promise<object> {
            return localVarFp.registerDoctor(academicTitle, bio, certificate, consultationGroupType, email, firstName, isPractise, isSpecialization, lastName, mainWorkPlaceId, partnerId, pesel, phone, practiceAddressApartmentNr, practiceAddressCity, practiceAddressCountry, practiceAddressHouseNr, practiceAddressStreet, practiceAddressZipcode, practiceInfoName, practiceInfoNipNr, practiceInfoRegonNr, practiceInfoRpwdlNr, pwz, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {DoctorAccountPricelistInfoDto} [doctorAccountPricelistInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccountPriceListInfo(doctorId: number, doctorAccountPricelistInfoDto?: DoctorAccountPricelistInfoDto, options?: any): Promise<DoctorAccountPricelistInfoDto> {
            return localVarFp.saveAccountPriceListInfo(doctorId, doctorAccountPricelistInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<MedicalServiceSystemItemDto>} medicalServiceSystemItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSystemPriceListInfo(medicalServiceSystemItemDto: Array<MedicalServiceSystemItemDto>, options?: any): Promise<void> {
            return localVarFp.saveSystemPriceListInfo(medicalServiceSystemItemDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminDoctorApiControllerApi - object-oriented interface
 * @export
 * @class AdminDoctorApiControllerApi
 * @extends {BaseAPI}
 */
export class AdminDoctorApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} doctorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDoctorApiControllerApi
     */
    public activate(doctorId: number, options?: AxiosRequestConfig) {
        return AdminDoctorApiControllerApiFp(this.configuration).activate(doctorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDoctorApiControllerApi
     */
    public deactivate(doctorId: number, options?: AxiosRequestConfig) {
        return AdminDoctorApiControllerApiFp(this.configuration).deactivate(doctorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDoctorApiControllerApi
     */
    public getAccountPriceListInfo(doctorId: number, options?: AxiosRequestConfig) {
        return AdminDoctorApiControllerApiFp(this.configuration).getAccountPriceListInfo(doctorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [phrase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDoctorApiControllerApi
     */
    public getBySearchPhrase1(phrase?: string, options?: AxiosRequestConfig) {
        return AdminDoctorApiControllerApiFp(this.configuration).getBySearchPhrase1(phrase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDoctorApiControllerApi
     */
    public getDoctorDetails(doctorId: number, options?: AxiosRequestConfig) {
        return AdminDoctorApiControllerApiFp(this.configuration).getDoctorDetails(doctorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDoctorApiControllerApi
     */
    public getDoctorWithWorkPlaces(doctorId: number, options?: AxiosRequestConfig) {
        return AdminDoctorApiControllerApiFp(this.configuration).getDoctorWithWorkPlaces(doctorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [all] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDoctorApiControllerApi
     */
    public getDoctors(all?: boolean, options?: AxiosRequestConfig) {
        return AdminDoctorApiControllerApiFp(this.configuration).getDoctors(all, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDoctorApiControllerApi
     */
    public getDoctorsForPrescription(options?: AxiosRequestConfig) {
        return AdminDoctorApiControllerApiFp(this.configuration).getDoctorsForPrescription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDoctorApiControllerApi
     */
    public getSystemPriceListInfo(options?: AxiosRequestConfig) {
        return AdminDoctorApiControllerApiFp(this.configuration).getSystemPriceListInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [academicTitle] 
     * @param {string} [bio] 
     * @param {any} [certificate] 
     * @param {string} [consultationGroupType] 
     * @param {string} [email] 
     * @param {string} [firstName] 
     * @param {string} [isPractise] 
     * @param {string} [isSpecialization] 
     * @param {string} [lastName] 
     * @param {number} [mainWorkPlaceId] 
     * @param {number} [partnerId] 
     * @param {string} [pesel] 
     * @param {string} [phone] 
     * @param {string} [practiceAddressApartmentNr] 
     * @param {string} [practiceAddressCity] 
     * @param {string} [practiceAddressCountry] 
     * @param {string} [practiceAddressHouseNr] 
     * @param {string} [practiceAddressStreet] 
     * @param {string} [practiceAddressZipcode] 
     * @param {string} [practiceInfoName] 
     * @param {string} [practiceInfoNipNr] 
     * @param {string} [practiceInfoRegonNr] 
     * @param {string} [practiceInfoRpwdlNr] 
     * @param {string} [pwz] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDoctorApiControllerApi
     */
    public registerDoctor(academicTitle?: string, bio?: string, certificate?: any, consultationGroupType?: string, email?: string, firstName?: string, isPractise?: string, isSpecialization?: string, lastName?: string, mainWorkPlaceId?: number, partnerId?: number, pesel?: string, phone?: string, practiceAddressApartmentNr?: string, practiceAddressCity?: string, practiceAddressCountry?: string, practiceAddressHouseNr?: string, practiceAddressStreet?: string, practiceAddressZipcode?: string, practiceInfoName?: string, practiceInfoNipNr?: string, practiceInfoRegonNr?: string, practiceInfoRpwdlNr?: string, pwz?: string, type?: string, options?: AxiosRequestConfig) {
        return AdminDoctorApiControllerApiFp(this.configuration).registerDoctor(academicTitle, bio, certificate, consultationGroupType, email, firstName, isPractise, isSpecialization, lastName, mainWorkPlaceId, partnerId, pesel, phone, practiceAddressApartmentNr, practiceAddressCity, practiceAddressCountry, practiceAddressHouseNr, practiceAddressStreet, practiceAddressZipcode, practiceInfoName, practiceInfoNipNr, practiceInfoRegonNr, practiceInfoRpwdlNr, pwz, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {DoctorAccountPricelistInfoDto} [doctorAccountPricelistInfoDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDoctorApiControllerApi
     */
    public saveAccountPriceListInfo(doctorId: number, doctorAccountPricelistInfoDto?: DoctorAccountPricelistInfoDto, options?: AxiosRequestConfig) {
        return AdminDoctorApiControllerApiFp(this.configuration).saveAccountPriceListInfo(doctorId, doctorAccountPricelistInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<MedicalServiceSystemItemDto>} medicalServiceSystemItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDoctorApiControllerApi
     */
    public saveSystemPriceListInfo(medicalServiceSystemItemDto: Array<MedicalServiceSystemItemDto>, options?: AxiosRequestConfig) {
        return AdminDoctorApiControllerApiFp(this.configuration).saveSystemPriceListInfo(medicalServiceSystemItemDto, options).then((request) => request(this.axios, this.basePath));
    }
}
