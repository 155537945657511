/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DocplannerVisitDto
 */
export interface DocplannerVisitDto {
    /**
     * 
     * @type {string}
     * @memberof DocplannerVisitDto
     */
    'caseSolved'?: DocplannerVisitDtoCaseSolvedEnum;
    /**
     * 
     * @type {string}
     * @memberof DocplannerVisitDto
     */
    'doctor': string;
    /**
     * 
     * @type {number}
     * @memberof DocplannerVisitDto
     */
    'doctorId': number;
    /**
     * 
     * @type {number}
     * @memberof DocplannerVisitDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DocplannerVisitDto
     */
    'insertionTime': string;
    /**
     * 
     * @type {string}
     * @memberof DocplannerVisitDto
     */
    'medicalService': DocplannerVisitDtoMedicalServiceEnum;
    /**
     * 
     * @type {string}
     * @memberof DocplannerVisitDto
     */
    'oldStatus'?: DocplannerVisitDtoOldStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DocplannerVisitDto
     */
    'patient': string;
    /**
     * 
     * @type {string}
     * @memberof DocplannerVisitDto
     */
    'patientEmail': string;
    /**
     * 
     * @type {number}
     * @memberof DocplannerVisitDto
     */
    'patientId': number;
    /**
     * 
     * @type {boolean}
     * @memberof DocplannerVisitDto
     */
    'patientInitialized': boolean;
    /**
     * 
     * @type {string}
     * @memberof DocplannerVisitDto
     */
    'patientPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocplannerVisitDto
     */
    'paymentMarking'?: DocplannerVisitDtoPaymentMarkingEnum;
    /**
     * 
     * @type {string}
     * @memberof DocplannerVisitDto
     */
    'status': DocplannerVisitDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DocplannerVisitDto
     */
    'timeFrom': string;
}

/**
    * @export
    * @enum {string}
    */
export enum DocplannerVisitDtoCaseSolvedEnum {
    Appointment = 'APPOINTMENT',
    Resignation = 'RESIGNATION',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum DocplannerVisitDtoMedicalServiceEnum {
    Visit0 = 'VISIT_0',
    Visit1 = 'VISIT_1',
    StandardVisit = 'STANDARD_VISIT',
    FollowupVisit = 'FOLLOWUP_VISIT',
    Message = 'MESSAGE',
    Prescription = 'PRESCRIPTION',
    AbsentConsultation = 'ABSENT_CONSULTATION',
    NeurosurgeonConsultation = 'NEUROSURGEON_CONSULTATION',
    NeuropsychologistConsultation = 'NEUROPSYCHOLOGIST_CONSULTATION',
    NeurologopedistConsultation = 'NEUROLOGOPEDIST_CONSULTATION',
    PsychiatristConsultation = 'PSYCHIATRIST_CONSULTATION',
    GeneticistConsultation = 'GENETICIST_CONSULTATION',
    EpileptologistConsultation = 'EPILEPTOLOGIST_CONSULTATION',
    NeurologistConsultation = 'NEUROLOGIST_CONSULTATION',
    DieticianConsultation = 'DIETICIAN_CONSULTATION',
    VnsConsultation = 'VNS_CONSULTATION',
    MetabolicConsultation = 'METABOLIC_CONSULTATION',
    SleepDisturbanceConsultation = 'SLEEP_DISTURBANCE_CONSULTATION',
    CaseConference = 'CASE_CONFERENCE',
    EegConsultation = 'EEG_CONSULTATION',
    VeegNightConsultation = 'VEEG_NIGHT_CONSULTATION',
    VeegDayConsultation = 'VEEG_DAY_CONSULTATION',
    VeegOnStandbyConsultation1H = 'VEEG_ON_STANDBY_CONSULTATION_1H',
    VeegOnStandbyConsultation2H = 'VEEG_ON_STANDBY_CONSULTATION_2H',
    VeegOnStandbyConsultation6H = 'VEEG_ON_STANDBY_CONSULTATION_6H',
    VeegDuringSleepConsultation1H = 'VEEG_DURING_SLEEP_CONSULTATION_1H',
    VeegDuringSleepConsultation2H = 'VEEG_DURING_SLEEP_CONSULTATION_2H',
    VeegDuringSleepConsultation6H = 'VEEG_DURING_SLEEP_CONSULTATION_6H',
    MigrationVisit = 'MIGRATION_VISIT',
    VeegOnStandbyConsultation = 'VEEG_ON_STANDBY_CONSULTATION',
    VeegDuringSleepConsultation = 'VEEG_DURING_SLEEP_CONSULTATION',
    PartnerConsultation = 'PARTNER_CONSULTATION'
}
/**
    * @export
    * @enum {string}
    */
export enum DocplannerVisitDtoOldStatusEnum {
    New = 'NEW',
    CanceledByScheduler = 'CANCELED_BY_SCHEDULER',
    CanceledByPerson = 'CANCELED_BY_PERSON',
    Solved = 'SOLVED',
    PaidNotDone = 'PAID_NOT_DONE',
    Done = 'DONE'
}
/**
    * @export
    * @enum {string}
    */
export enum DocplannerVisitDtoPaymentMarkingEnum {
    WaitingForPayment = 'WAITING_FOR_PAYMENT',
    PaymentReceived = 'PAYMENT_RECEIVED'
}
/**
    * @export
    * @enum {string}
    */
export enum DocplannerVisitDtoStatusEnum {
    New = 'NEW',
    CanceledByScheduler = 'CANCELED_BY_SCHEDULER',
    CanceledByPerson = 'CANCELED_BY_PERSON',
    Solved = 'SOLVED',
    PaidNotDone = 'PAID_NOT_DONE',
    Done = 'DONE'
}


