/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ICD10Dto } from './icd10-dto';
import { ICD9Dto } from './icd9-dto';

/**
 * 
 * @export
 * @interface EReferralIssuanceDto
 */
export interface EReferralIssuanceDto {
    /**
     * 
     * @type {string}
     * @memberof EReferralIssuanceDto
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof EReferralIssuanceDto
     */
    'destinationId': number;
    /**
     * 
     * @type {string}
     * @memberof EReferralIssuanceDto
     */
    'doctorPass': string;
    /**
     * 
     * @type {ICD10Dto}
     * @memberof EReferralIssuanceDto
     */
    'icd10': ICD10Dto;
    /**
     * 
     * @type {number}
     * @memberof EReferralIssuanceDto
     */
    'patientId': number;
    /**
     * 
     * @type {Array<ICD9Dto>}
     * @memberof EReferralIssuanceDto
     */
    'procedures': Array<ICD9Dto>;
    /**
     * 
     * @type {string}
     * @memberof EReferralIssuanceDto
     */
    'type': EReferralIssuanceDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof EReferralIssuanceDto
     */
    'visitId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum EReferralIssuanceDtoTypeEnum {
    Clinic = 'CLINIC',
    Laboratory = 'LABORATORY',
    Hospital = 'HOSPITAL'
}


