import StorageService from "../service/storege.service";
import EventBus from "@/utility/event.bus";

export default {

    setUser(data) {
        this.user = data;
        StorageService.setInLocalStorage('logged-user', JSON.stringify(data));
        EventBus.$emit('user-updated');
    },

    getUser() {

        if (!this.user) {
            this.user = JSON.parse(
                    StorageService.getFromLocalStorage('logged-user')
            );
        }

        return this.user;
    },

    setNotLoggedInFeatures(data) {
        this.notLoggedInFeatures = data;
        StorageService.setInLocalStorage('not-logged-features', JSON.stringify(data));
        EventBus.$emit('features-updated');
    },

    getNotLoggedInFeatures() {

        if (!this.notLoggedInFeatures) {
            this.notLoggedInFeatures = JSON.parse(
                    StorageService.getFromLocalStorage('not-logged-features')
            );
        }

        return this.notLoggedInFeatures || [];
    },

    setUserFeatures(data) {
        this.getUser().features = data;
        EventBus.$emit('features-updated');
        this.updateUser();
    },

    getFeatures() {
        return (this.getUser() || {}).features || this.getNotLoggedInFeatures();
    },

    hasFeature(feature) {
        return this.getFeatures().includes(feature);
    },

    getRoles() {
        return (this.getUser() || {}).roles || [];
    },

    hasRole(role) {
        return this.getRoles().includes(role);
    },

    deleteUser() {
        this.user = null;
        StorageService.removeFromLocalStorage('logged-user');
    },

    updateUser() {
        StorageService.setInLocalStorage('logged-user', JSON.stringify(this.user));
        EventBus.$emit('user-updated');
    },

    isBusinessUserSet() {

        if (!this.user) {
            this.getUser();
        }

        return this.user && this.user.businessUser;
    },

    isUserInitialized() {
        return this.user.businessUser.initialized;
    },

    isMyDataFilled() {
        return this.user.businessUser.myDataFilled;
    },

    isMedicalHistoryFilled() {
        return this.user.businessUser.medicalHistoryFilled;
    },

    setUserInitialized() {
        this.user.businessUser.initialized = true;
        this.updateUser();
    },

    hasEpilepsy() {
        return this.user.businessUser.hasEpilepsy !== 'NO';
    },

    hasAppointment() {
        return this.user.businessUser && this.user.businessUser.hasAppointment;
    },

    isConsultant() {
        const businessUser = this.getUser().businessUser;
        return ['CONSULTANT', 'INTERNATIONAL_CONSULTANT', 'PARTNER_CONSULTANT'].includes(businessUser.type);
    }
}
