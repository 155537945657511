/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DrivingCertificateDto
 */
export interface DrivingCertificateDto {
    /**
     * 
     * @type {string}
     * @memberof DrivingCertificateDto
     */
    'chronicComplications'?: string;
    /**
     * 
     * @type {string}
     * @memberof DrivingCertificateDto
     */
    'coexistingDiseases'?: string;
    /**
     * 
     * @type {string}
     * @memberof DrivingCertificateDto
     */
    'courseOfIllness'?: string;
    /**
     * 
     * @type {string}
     * @memberof DrivingCertificateDto
     */
    'courseOfTreatment'?: string;
    /**
     * 
     * @type {string}
     * @memberof DrivingCertificateDto
     */
    'diagnostics'?: string;
    /**
     * 
     * @type {string}
     * @memberof DrivingCertificateDto
     */
    'driverType': DrivingCertificateDtoDriverTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DrivingCertificateDto
     */
    'firstEpisode'?: string;
    /**
     * 
     * @type {string}
     * @memberof DrivingCertificateDto
     */
    'furtherObservationRequirements'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DrivingCertificateDto
     */
    'hasEpilepsy': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DrivingCertificateDto
     */
    'hasEpilepticSyndrome': boolean;
    /**
     * 
     * @type {string}
     * @memberof DrivingCertificateDto
     */
    'illnessDuration'?: string;
    /**
     * 
     * @type {string}
     * @memberof DrivingCertificateDto
     */
    'otherRemarks'?: string;
    /**
     * 
     * @type {number}
     * @memberof DrivingCertificateDto
     */
    'patientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DrivingCertificateDto
     */
    'periodEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof DrivingCertificateDto
     */
    'periodStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof DrivingCertificateDto
     */
    'seizureTypes'?: string;
    /**
     * 
     * @type {number}
     * @memberof DrivingCertificateDto
     */
    'visitId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum DrivingCertificateDtoDriverTypeEnum {
    Driver = 'DRIVER',
    DriverCandidate = 'DRIVER_CANDIDATE'
}


