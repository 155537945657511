export default {
  "user": {
    "notFound": "Uživatel nenalezen",
    "alreadyExists": "Uživatel s daným přihlášením již existuje",
    "unauthorized": "Tuto akci nemůžete provést, protože nemáte oprávnění",
    "emailAlreadyExists": "Tato e-mailová adresa je již v systému zaregistrována",
    "cannotRemove": {
      "paymentsNotRefunded": "Účet nelze odstranit z důvodu nevrácených plateb"
    }
  },
  "doctor": {
    "notFound": "Doktor nebyl nalezen",
    "priceList": {
      "notFound": "Ceník lékaře nebyl nalezen",
      "wrongGrossPrice": "Je vybrána nesprávná hrubá cena",
      "notAvailable": "Vybraná služba není k dispozici",
      "grossPriceCalculation": "Špatný výpočet ceny návštěvy",
      "durationOutOfSync": "Doba trvání služeb zvoleného typu by měla být stejná"
    },
    "medicalService": {
      "notFound": "Lékařská služba nebyla nalezena"
    },
    "forms": {
      "nfzContract": {
        "numberDuplicated": "Lékař se zadaným číslem smlouvy NFZ již existuje"
      }
    },
    "certificate": {
      "numberInvalid": "Niepoprawny numer certyfikatu lekarza"
    },
    "selection": {
      "request": {
        "alreadyPending": "Žádost o změnu hlavního lékaře již byla odeslána a čeká na odpověď lékaře",
        "incorrectStatus": "Žádost o změnu hlavního lékaře má nesprávný stav",
        "incorrectState": "Žádost o změnu hlavního lékaře je v nesprávném stavu"
      }
    },
    "missingCertificate": "Chybí certifikát ZUS"
  },
  "patient": {
    "notFound": "Pacient nebyl nalezen",
    "mood": {
      "future": "Nemůžete přidat náladu z budoucnosti"
    }
  },
  "position": {
    "notFound": "Pozice nenalezena"
  },
  "visit": {
    "notFound": "Návštěva nebyla nalezena",
    "notDone": "Návštěva nebyla dokončena",
    "notPaid": "Návštěva neplatí",
    "edit": {
      "notAllowed": "Úpravy návštěvy nejsou povoleny"
    },
    "time": {
      "booked": "Vybráno datum z minulosti"
    },
    "appointment": {
      "typeInvalid": "Neplatný typ schůzky"
    },
    "recommendations": {
      "empty": "Doporučení by měla být vyplněna před ukončením návštěvy"
    },
    "processing": {
      "error": "Při zpracování návštěvy došlo k chybě"
    },
    "summary": {
      "pdf": "Při generování dokumentu PDF pro návštěvu došlo k chybě"
    },
    "message": {
      "notInitialized": "Konverzace ještě nebyla zahájena pacientem",
      "limitExceeded": "Limit zpráv pro jednu návštěvu byl vyčerpán",
      "notResponded": "Vaše poslední zpráva ještě neodpověděla"
    },
    "invoice": {
      "pdf": "Při generování pdf faktury pro návštěvu došlo k chybě"
    },
    "afterVisitZeroAvailable": "Funkce není k dispozici před první návštěvou",
    "alreadyReported": "Návštěva již byla nahlášena na P1 a již ji nelze modifikovat",
    "paymentMarkingNotAllowed": "Tuto návštěvu nelze označit, protože cena je příliš vysoká",
    "changeDisabled": "Termín nebo lékař této schůzky již byl změněn. Další změnu systém neumožňuje. Máte-li jakékoli pochybnosti, kontaktujte nás."
  },
  "payment": {
    "receipt": {
      "pdf": "Při generování potvrzení o platbě došlo k chybě"
    },
    "creation": "Při ukládání platební metody došlo k chybě",
    "visit": {
      "alreadyDone": "Návštěva již zaplacena",
      "stillProcessing": "Návštěva se stále zpracovává",
      "unknownState": "Navštivte chybu platby, kontaktujte tým Neuroterminal"
    },
    "genericError": "Při zpracování platby došlo k chybě",
    "alreadyDone": "Platba již dokončena"
  },
  "date": {
    "selected": {
      "fromPast": "Navštivte rezervovaný čas"
    },
    "range": {
      "collision": "Vybraná období jsou ve vzájemném konfliktu"
    }
  },
  "consultation": {
    "notFound": "Konzultace nebyla nalezena"
  },
  "nip": {
    "invalid": "Neplatné NIP"
  },
  "medicalEvent": {
    "notFound": "Událost nebyla nalezena",
    "invalidData": "Neplatná data události"
  },
  "medicalRequest": {
    "notFound": "Žádost nebyla nalezena",
    "invalidData": "Neplatná žádost",
    "alreadyAssigned": "Žádost již byla přidělena"
  },
  "medicalResult": {
    "invalidData": "Neplatný výsledek",
    "notFound": "Výsledek nebyl nalezen",
    "description": {
      "notFound": "Popis výsledku nebyl nalezen"
    },
    "file": {
      "invalidData": "Neplatná data souboru výsledků"
    }
  },
  "medicalDocument": {
    "addError": "Při ukládání dokumentu došlo k chybě",
    "editError": "Při úpravách dokumentu došlo k chybě",
    "downloadError": "Při stahování souboru došlo k chybě",
    "deleteError": "Při mazání dokumentu došlo k chybě",
    "file": {
      "saveError": "Při ukládání souboru došlo k chybě"
    }
  },
  "drugStock": {
    "negativeAmount": "Částka nesmí být menší než 0",
    "notUnique": "Stejný produkt nelze vybrat mnohokrát"
  },
  "product": {
    "notFound": "Produkt nebyl nalezen",
    "notUnique": "Nelze vybrat stejný produkt mnohokrát"
  },
  "patientSeizuresHistory": {
    "futureDateSelected": "Bylo vybráno budoucí datum",
    "periodConflict": "Vybraná období jsou ve vzájemném konfliktu"
  },
  "healthSurvey": {
    "alreadyInitialized": "Vaše anamnéza již byla inicializována",
    "patientHasNotEpilepsy": "K průzkumu 0 mají přístup pouze pacienti s epilepsií"
  },
  "form": {
    "invalidEmail": "Neplatná emailová adresa",
    "invalidPhone": "Neplatné telefonní číslo"
  },
  "passwordReset": {
    "tokenIncorrect": "Odeslaný odkaz je nesprávný",
    "internalError": "Chyba při zpracování požadavku na změnu hesla",
    "unsecure": "Heslo by mělo obsahovat alespoň 10 znaků, velká a malá písmena, číslice a speciální znaky"
  },
  "email": {
    "sendError": "Došlo k chybě při odesílání e-mailu"
  },
  "sms": {
    "noPhone": "Neznámé telefonní číslo pro vybraného uživatele"
  },
  "videochat": {
    "getTokenFailed": "Došlo k chybě připojení",
    "sessionExpired": "Doba návštěvy vypršela. Nelze navázat spojení."
  },
  "doctorNote": {
    "notFound": "Nie odnaleziono notatki"
  },
  "unexpected": "Došlo k neočekávané chybě. Zkuste to později",
  "patientSeizureCharacteristics": {
    "notFound": "Historie záchvatů pacienta nebyla zcela dokončena"
  },
  "referral": {
    "notFound": "Doporučení nenalezeno",
    "cancelFree": "Nelze zrušit doporučení pro bezplatnou návštěvu"
  },
  "harmonogram": {
    "notFound": "Vybraný termín návštěvy je již rezervován"
  },
  "incompleteData": "Údaje jsou neúplné",
  "cered": {
    "prescription": {
      "creation": "Při vydávání receptu došlo k chybě",
      "psychotropicNotUnique": "Předpis nesmí obsahovat kombinaci psychotropních léků s jinými léky",
      "prescriptionTypeNotUnique": "Předpis nesmí obsahovat kombinaci léků RP a RPW",
      "prescriptionFileNotAvailable": "Výtisk předpisu není k dispozici"
    },
    "ewus": {
      "login": "Při přihlašování do EWUŚ došlo k chybě",
      "check": "Při kontrole pojištění pacienta v EWUŚ došlo k chybě"
    },
    "ereferral": {
      "creation": "Při vydávání elektronického doporučení došlo k chybě"
    },
    "zus": {
      "connect": "Při přihlašování do ZUS došlo k chybě",
      "cannotCancel": "Tuto nemocenskou nelze zrušit",
      "cannotFindWorkPlace": "V účtu ZUS nelze najít pracoviště NEUROSPHERA"
    }
  },
  "icd10": {
    "notUnique": "Nelze vybrat stejný icd10 mnohokrát"
  },
  "visitPackage": {
    "dateCollision": "Návštěvy balíčku nelze domluvit na stejný den",
    "invalidService": "Některé z vybraných služeb nejsou v tomto balíčku dostupné",
    "moveDateCollision": "Nová schůzka se překrývá s jinou schůzkou v balíčku",
    "someVisitsDone": "Návštěvy z již zahájeného balíčku nelze zrušit",
    "dateNotEqual": "Některé z návštěv by měly proběhnout ve stejný den",
    "timeCollision": "Vybraná data návštěv jsou ve vzájemném rozporu",
    "wrongDuration": "Neplatná doba trvání návštěvy",
    "notActive": "Návštěvní balíček není k dispozici",
    "differentDoctor": "Některé z návštěv by měly být u stejného lékaře",
    "differentPlace": "Některé z návštěv by měly být na stejném místě (nebo online)"
  },
  "request": {
    "blocked": {
      "reset": "Odkaz na změnu hesla nelze odeslat před uplynutím {n} hodin od poslední žádosti o změnu hesla. Kontaktujte prosím zákaznický servis.",
      "registration": "Odkaz na změnu hesla nelze odeslat před uplynutím {n} hodin od poslední žádosti o změnu hesla. Kontaktujte prosím zákaznický servis.",
      "notFound": "Nelze odemknout reset hesla, protože předchozí požadavek nebyl nalezen.",
      "notActive": "Návštěvní balíček není k dispozici"
    }
  },
  "slot": {
    "stepNotAllowed": "Zdravotní služba nedefinuje možnost volby délky návštěvy",
    "timeNotInRange": "Zvolená délka návštěvy není v možném rozsahu",
    "timeIndivisibleByStep": "Zvolená délka návštěvy není dělitelná definovaným krokem"
  },
  "docplanner": {
    "issue": {
      "alreadyResolved": "Problém již byl označen jako vyřešený",
      "actionNotImplemented": "Pro tento typ problému není připravena žádná automatická akce",
      "notFound": "Problém nenalezen"
    }
  },
  "hashtag": {
    "alreadyExists": "Hashtag s daným jménem již existuje"
  },
  "generic": {
    "alreadyExists": "Objekt s daným identifikátorem již existuje"
  },
  "partner": {
    "notMatch": "Nebyla nalezena odpovídající služba"
  }
}