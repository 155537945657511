<template>

    <div>
        <div v-for="(snackbar, idx) in snackbars" :key="snackbar.id">
            <v-snackbar v-model="snackbar.showing" :color="styles[snackbar.style].color"
                        :style="{'margin-top': `${idx * 75}px`}" right
                        timeout="-1" top>
                <v-row :class="{'clickable': snackbar.action}"
                       align="center" dense @click="runAction(snackbar)">
                    <v-col class="shrink pr-4">
                        <div v-if="snackbar.avatar">
                            <avatar :login="snackbar.avatar"></avatar>
                        </div>
                        <div v-else>
                            <v-icon :color="styles[snackbar.style].icon.color" size="35">
                                {{ snackbar.icon ? snackbar.icon : styles[snackbar.style].icon.name }}
                            </v-icon>
                        </div>
                    </v-col>
                    <v-col :class="styles[snackbar.style].contentClass" class="grow">
                        <span v-html="snackbar.text"></span>
                    </v-col>
                </v-row>
                <template v-slot:action="{ attrs }">
                    <v-btn v-bind="attrs" fab small text @click="closeSnackbar(snackbar)">
                        <v-icon :color="styles[snackbar.style].closeColor">mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </div>

</template>

<script>

import EventBus from "@/utility/event.bus";
import lodash from 'lodash';
import Avatar from "@/component/utility/avatar.component";

export default {
    name: "toast",
    components: {Avatar},
    data: () => ({
        defaultTimeout: 6000,
        styles: {
            info: {
                color: 'white',
                contentClass: 'black--text',
                icon: {
                    color: 'black',
                    name: 'mdi-information'
                },
                closeColor: 'black'
            },
            success: {
                color: 'success',
                contentClass: 'white--text',
                icon: {
                    color: 'white',
                    name: 'mdi-check-circle'
                },
                closeColor: 'white'
            },
            warning: {
                color: 'warning',
                contentClass: 'white--text',
                icon: {
                    color: 'white',
                    name: 'mdi-alert-circle'
                },
                closeColor: 'white'
            },
            error: {
                color: 'error',
                contentClass: 'white--text',
                icon: {
                    color: 'white',
                    name: 'mdi-alert-circle'
                },
                closeColor: 'white'
            }
        },
        snackbars: [],
    }),
    computed: {
        snackbarWidth() {
            return this.$vuetify.breakpoint.mdAndUp ? 'auto' : 'calc(100vw - 30px)';
        }
    },
    methods: {
        closeSnackbar(snackbar) {
            this.snackbars = this.snackbars.filter(item => item.id !== snackbar.id);
        },
        runAction(snackbar) {
            if (snackbar.action) {
                snackbar.action();
                this.closeSnackbar(snackbar);
            }
        },
        showToast(event, style) {
            const snackbar = {
                text: this.$te(event.message) ? this.$t(event.message) : event.message,
                style: style,
                showing: true,
                action: event.action,
                timeout: event.timeout || this.defaultTimeout,
                avatar: event.avatar,
                icon: event.icon,
                id: lodash.uniqueId()
            };
            this.snackbars.push(snackbar);
            setTimeout(() => this.closeSnackbar(snackbar), snackbar.timeout);
        }
    },
    mounted() {
        EventBus.$on('show-success-info', event => this.showToast(event, 'success'));
        EventBus.$on('show-warning-info', event => this.showToast(event, 'warning'));
        EventBus.$on('show-error-info', event => this.showToast(event, 'error'));
        EventBus.$on('show-info', event => this.showToast(event, 'info'));
    },
}
</script>
