/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PagePatientDto } from '../model';
// @ts-ignore
import { PatientAccountInfoDto } from '../model';
// @ts-ignore
import { PatientAccountInfoExtendedDto } from '../model';
// @ts-ignore
import { PatientBasicDataDto } from '../model';
// @ts-ignore
import { PatientDto } from '../model';
/**
 * AdminPatientApiControllerApi - axios parameter creator
 * @export
 */
export const AdminPatientApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelReferral1: async (patientId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('cancelReferral1', 'patientId', patientId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelReferral1', 'id', id)
            const localVarPath = `/api/admin/patient/{patientId}/referral/{id}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePatient1: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('deletePatient1', 'patientId', patientId)
            const localVarPath = `/api/admin/patient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBySearchPhrase: async (phrase?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/patient/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (phrase !== undefined) {
                localVarQueryParameter['phrase'] = phrase;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientById: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientById', 'patientId', patientId)
            const localVarPath = `/api/admin/patient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientMainIcd10: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientMainIcd10', 'patientId', patientId)
            const localVarPath = `/api/admin/patient/mainIcd10/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {string} [email] 
         * @param {string} [pesel] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsFilteredSimple: async (searchString?: string, email?: string, pesel?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/patient/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (pesel !== undefined) {
                localVarQueryParameter['pesel'] = pesel;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatientAccountInfoExtendedDto} patientAccountInfoExtendedDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPatientAccount: async (patientAccountInfoExtendedDto: PatientAccountInfoExtendedDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientAccountInfoExtendedDto' is not null or undefined
            assertParamExists('registerPatientAccount', 'patientAccountInfoExtendedDto', patientAccountInfoExtendedDto)
            const localVarPath = `/api/admin/patient/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientAccountInfoExtendedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {PatientAccountInfoDto} patientAccountInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccountGeneralInfo2: async (patientId: number, patientAccountInfoDto: PatientAccountInfoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('saveAccountGeneralInfo2', 'patientId', patientId)
            // verify required parameter 'patientAccountInfoDto' is not null or undefined
            assertParamExists('saveAccountGeneralInfo2', 'patientAccountInfoDto', patientAccountInfoDto)
            const localVarPath = `/api/admin/patient/update/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientAccountInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPatientApiControllerApi - functional programming interface
 * @export
 */
export const AdminPatientApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPatientApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelReferral1(patientId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelReferral1(patientId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePatient1(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePatient1(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBySearchPhrase(phrase?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<PatientBasicDataDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBySearchPhrase(phrase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientById(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientById(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientMainIcd10(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientMainIcd10(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {string} [email] 
         * @param {string} [pesel] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientsFilteredSimple(searchString?: string, email?: string, pesel?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PagePatientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientsFilteredSimple(searchString, email, pesel, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PatientAccountInfoExtendedDto} patientAccountInfoExtendedDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerPatientAccount(patientAccountInfoExtendedDto: PatientAccountInfoExtendedDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerPatientAccount(patientAccountInfoExtendedDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {PatientAccountInfoDto} patientAccountInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAccountGeneralInfo2(patientId: number, patientAccountInfoDto: PatientAccountInfoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientAccountInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAccountGeneralInfo2(patientId, patientAccountInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPatientApiControllerApi - factory interface
 * @export
 */
export const AdminPatientApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPatientApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelReferral1(patientId: number, id: number, options?: any): Promise<void> {
            return localVarFp.cancelReferral1(patientId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePatient1(patientId: number, options?: any): Promise<void> {
            return localVarFp.deletePatient1(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [phrase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBySearchPhrase(phrase?: string, options?: any): Promise<Array<PatientBasicDataDto>> {
            return localVarFp.getBySearchPhrase(phrase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientById(patientId: number, options?: any): Promise<PatientDto> {
            return localVarFp.getPatientById(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientMainIcd10(patientId: number, options?: any): Promise<string> {
            return localVarFp.getPatientMainIcd10(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {string} [email] 
         * @param {string} [pesel] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsFilteredSimple(searchString?: string, email?: string, pesel?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<PagePatientDto> {
            return localVarFp.getPatientsFilteredSimple(searchString, email, pesel, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PatientAccountInfoExtendedDto} patientAccountInfoExtendedDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerPatientAccount(patientAccountInfoExtendedDto: PatientAccountInfoExtendedDto, options?: any): Promise<number> {
            return localVarFp.registerPatientAccount(patientAccountInfoExtendedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {PatientAccountInfoDto} patientAccountInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccountGeneralInfo2(patientId: number, patientAccountInfoDto: PatientAccountInfoDto, options?: any): Promise<PatientAccountInfoDto> {
            return localVarFp.saveAccountGeneralInfo2(patientId, patientAccountInfoDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPatientApiControllerApi - object-oriented interface
 * @export
 * @class AdminPatientApiControllerApi
 * @extends {BaseAPI}
 */
export class AdminPatientApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} patientId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPatientApiControllerApi
     */
    public cancelReferral1(patientId: number, id: number, options?: AxiosRequestConfig) {
        return AdminPatientApiControllerApiFp(this.configuration).cancelReferral1(patientId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPatientApiControllerApi
     */
    public deletePatient1(patientId: number, options?: AxiosRequestConfig) {
        return AdminPatientApiControllerApiFp(this.configuration).deletePatient1(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [phrase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPatientApiControllerApi
     */
    public getBySearchPhrase(phrase?: string, options?: AxiosRequestConfig) {
        return AdminPatientApiControllerApiFp(this.configuration).getBySearchPhrase(phrase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPatientApiControllerApi
     */
    public getPatientById(patientId: number, options?: AxiosRequestConfig) {
        return AdminPatientApiControllerApiFp(this.configuration).getPatientById(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPatientApiControllerApi
     */
    public getPatientMainIcd10(patientId: number, options?: AxiosRequestConfig) {
        return AdminPatientApiControllerApiFp(this.configuration).getPatientMainIcd10(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchString] 
     * @param {string} [email] 
     * @param {string} [pesel] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPatientApiControllerApi
     */
    public getPatientsFilteredSimple(searchString?: string, email?: string, pesel?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return AdminPatientApiControllerApiFp(this.configuration).getPatientsFilteredSimple(searchString, email, pesel, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PatientAccountInfoExtendedDto} patientAccountInfoExtendedDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPatientApiControllerApi
     */
    public registerPatientAccount(patientAccountInfoExtendedDto: PatientAccountInfoExtendedDto, options?: AxiosRequestConfig) {
        return AdminPatientApiControllerApiFp(this.configuration).registerPatientAccount(patientAccountInfoExtendedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {PatientAccountInfoDto} patientAccountInfoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPatientApiControllerApi
     */
    public saveAccountGeneralInfo2(patientId: number, patientAccountInfoDto: PatientAccountInfoDto, options?: AxiosRequestConfig) {
        return AdminPatientApiControllerApiFp(this.configuration).saveAccountGeneralInfo2(patientId, patientAccountInfoDto, options).then((request) => request(this.axios, this.basePath));
    }
}
