/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ICD10Dto } from './icd10-dto';

/**
 * 
 * @export
 * @interface ComorbidityICD10Dto
 */
export interface ComorbidityICD10Dto {
    /**
     * 
     * @type {ICD10Dto}
     * @memberof ComorbidityICD10Dto
     */
    'icd10'?: ICD10Dto;
    /**
     * 
     * @type {number}
     * @memberof ComorbidityICD10Dto
     */
    'icd10Id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ComorbidityICD10Dto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ComorbidityICD10Dto
     */
    'type'?: ComorbidityICD10DtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ComorbidityICD10DtoTypeEnum {
    Headaches = 'HEADACHES',
    Psychiatric = 'PSYCHIATRIC',
    Neurological = 'NEUROLOGICAL',
    Infectious = 'INFECTIOUS',
    Cancer = 'CANCER',
    Immune = 'IMMUNE',
    Endocrine = 'ENDOCRINE',
    Eye = 'EYE',
    Laryngological = 'LARYNGOLOGICAL',
    Heart = 'HEART',
    Respiratory = 'RESPIRATORY',
    Digestive = 'DIGESTIVE',
    Skin = 'SKIN',
    Bone = 'BONE',
    Urogenital = 'UROGENITAL',
    DevelopmentalDefects = 'DEVELOPMENTAL_DEFECTS',
    InjuriesPoisoning = 'INJURIES_POISONING',
    Somatic = 'SOMATIC'
}


