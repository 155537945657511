/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { NewDoctorNoteContentDto } from '../model';
// @ts-ignore
import { NoteDto } from '../model';
/**
 * DoctorNoteApiControllerApi - axios parameter creator
 * @export
 */
export const DoctorNoteApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {NewDoctorNoteContentDto} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDoctorNote: async (patientId: number, content?: NewDoctorNoteContentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('addDoctorNote', 'patientId', patientId)
            const localVarPath = `/api/doctor-note/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoctorNote: async (doctorNoteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorNoteId' is not null or undefined
            assertParamExists('deleteDoctorNote', 'doctorNoteId', doctorNoteId)
            const localVarPath = `/api/doctor-note/{doctorNoteId}`
                .replace(`{${"doctorNoteId"}}`, encodeURIComponent(String(doctorNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorNotes: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getDoctorNotes', 'patientId', patientId)
            const localVarPath = `/api/doctor-note/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorNotesCount: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getDoctorNotesCount', 'patientId', patientId)
            const localVarPath = `/api/doctor-note/{patientId}/count`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorNoteId 
         * @param {NewDoctorNoteContentDto} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoctorNote: async (doctorNoteId: number, content?: NewDoctorNoteContentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorNoteId' is not null or undefined
            assertParamExists('updateDoctorNote', 'doctorNoteId', doctorNoteId)
            const localVarPath = `/api/doctor-note/{doctorNoteId}`
                .replace(`{${"doctorNoteId"}}`, encodeURIComponent(String(doctorNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DoctorNoteApiControllerApi - functional programming interface
 * @export
 */
export const DoctorNoteApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DoctorNoteApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {NewDoctorNoteContentDto} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDoctorNote(patientId: number, content?: NewDoctorNoteContentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<NoteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDoctorNote(patientId, content, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDoctorNote(doctorNoteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDoctorNote(doctorNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorNotes(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<NoteDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorNotes(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorNotesCount(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorNotesCount(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorNoteId 
         * @param {NewDoctorNoteContentDto} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDoctorNote(doctorNoteId: number, content?: NewDoctorNoteContentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<NoteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDoctorNote(doctorNoteId, content, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DoctorNoteApiControllerApi - factory interface
 * @export
 */
export const DoctorNoteApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DoctorNoteApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {NewDoctorNoteContentDto} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDoctorNote(patientId: number, content?: NewDoctorNoteContentDto, options?: any): Promise<NoteDto> {
            return localVarFp.addDoctorNote(patientId, content, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoctorNote(doctorNoteId: number, options?: any): Promise<void> {
            return localVarFp.deleteDoctorNote(doctorNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorNotes(patientId: number, options?: any): Promise<Array<NoteDto>> {
            return localVarFp.getDoctorNotes(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorNotesCount(patientId: number, options?: any): Promise<number> {
            return localVarFp.getDoctorNotesCount(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorNoteId 
         * @param {NewDoctorNoteContentDto} [content] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoctorNote(doctorNoteId: number, content?: NewDoctorNoteContentDto, options?: any): Promise<NoteDto> {
            return localVarFp.updateDoctorNote(doctorNoteId, content, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DoctorNoteApiControllerApi - object-oriented interface
 * @export
 * @class DoctorNoteApiControllerApi
 * @extends {BaseAPI}
 */
export class DoctorNoteApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} patientId 
     * @param {NewDoctorNoteContentDto} [content] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorNoteApiControllerApi
     */
    public addDoctorNote(patientId: number, content?: NewDoctorNoteContentDto, options?: AxiosRequestConfig) {
        return DoctorNoteApiControllerApiFp(this.configuration).addDoctorNote(patientId, content, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorNoteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorNoteApiControllerApi
     */
    public deleteDoctorNote(doctorNoteId: number, options?: AxiosRequestConfig) {
        return DoctorNoteApiControllerApiFp(this.configuration).deleteDoctorNote(doctorNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorNoteApiControllerApi
     */
    public getDoctorNotes(patientId: number, options?: AxiosRequestConfig) {
        return DoctorNoteApiControllerApiFp(this.configuration).getDoctorNotes(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorNoteApiControllerApi
     */
    public getDoctorNotesCount(patientId: number, options?: AxiosRequestConfig) {
        return DoctorNoteApiControllerApiFp(this.configuration).getDoctorNotesCount(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorNoteId 
     * @param {NewDoctorNoteContentDto} [content] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorNoteApiControllerApi
     */
    public updateDoctorNote(doctorNoteId: number, content?: NewDoctorNoteContentDto, options?: AxiosRequestConfig) {
        return DoctorNoteApiControllerApiFp(this.configuration).updateDoctorNote(doctorNoteId, content, options).then((request) => request(this.axios, this.basePath));
    }
}
