/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AddressDto } from './address-dto';
import { DoctorPricelistItemDto } from './doctor-pricelist-item-dto';
import { DoctorWorkPlaceDto } from './doctor-work-place-dto';
import { MedicalServiceGroupDto } from './medical-service-group-dto';
import { ProfessionDto } from './profession-dto';
import { UserAccountDto } from './user-account-dto';

/**
 * 
 * @export
 * @interface DoctorDto
 */
export interface DoctorDto {
    /**
     * 
     * @type {string}
     * @memberof DoctorDto
     */
    'academicTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorDto
     */
    'achievements'?: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof DoctorDto
     */
    'address'?: AddressDto;
    /**
     * 
     * @type {string}
     * @memberof DoctorDto
     */
    'appointmentType'?: DoctorDtoAppointmentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DoctorDto
     */
    'companyName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorDto
     */
    'contactEmail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorDto
     */
    'contactPhone'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorDto
     */
    'contactQneuro'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorDto
     */
    'contactSms'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorDto
     */
    'describeEeg'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorDto
     */
    'describeNeuroradiology'?: boolean;
    /**
     * 
     * @type {Array<DoctorPricelistItemDto>}
     * @memberof DoctorDto
     */
    'doctorPricelistItems'?: Array<DoctorPricelistItemDto>;
    /**
     * 
     * @type {string}
     * @memberof DoctorDto
     */
    'documentHeader'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorDto
     */
    'entryDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorDto
     */
    'epileptologyAssociationMember'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DoctorDto
     */
    'epileptologyCertNr'?: string;
    /**
     * 
     * @type {number}
     * @memberof DoctorDto
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorDto
     */
    'isWorkInEpileptologyClinic'?: boolean;
    /**
     * 
     * @type {AddressDto}
     * @memberof DoctorDto
     */
    'mailingAddress'?: AddressDto;
    /**
     * 
     * @type {number}
     * @memberof DoctorDto
     */
    'mainProfessionId'?: number;
    /**
     * 
     * @type {DoctorWorkPlaceDto}
     * @memberof DoctorDto
     */
    'mainWorkPlace'?: DoctorWorkPlaceDto;
    /**
     * 
     * @type {MedicalServiceGroupDto}
     * @memberof DoctorDto
     */
    'medicalServiceGroup'?: MedicalServiceGroupDto;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorDto
     */
    'nfzContract'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DoctorDto
     */
    'nip'?: string;
    /**
     * 
     * @type {number}
     * @memberof DoctorDto
     */
    'partnerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DoctorDto
     */
    'patientLimit'?: number;
    /**
     * 
     * @type {Array<ProfessionDto>}
     * @memberof DoctorDto
     */
    'professions'?: Array<ProfessionDto>;
    /**
     * 
     * @type {string}
     * @memberof DoctorDto
     */
    'regon'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorDto
     */
    'scientificResearch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorDto
     */
    'selfEmployed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DoctorDto
     */
    'taxOffice'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorDto
     */
    'type'?: DoctorDtoTypeEnum;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof DoctorDto
     */
    'userAccount'?: UserAccountDto;
    /**
     * 
     * @type {string}
     * @memberof DoctorDto
     */
    'workCareerStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorDto
     */
    'workCertNr'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DoctorDtoAppointmentTypeEnum {
    MedicalRequest = 'MEDICAL_REQUEST',
    Direct = 'DIRECT'
}
/**
    * @export
    * @enum {string}
    */
export enum DoctorDtoTypeEnum {
    Resident = 'RESIDENT',
    Specialist = 'SPECIALIST',
    Expert = 'EXPERT',
    Consultant = 'CONSULTANT',
    Assistant = 'ASSISTANT',
    InternationalConsultant = 'INTERNATIONAL_CONSULTANT',
    PartnerConsultant = 'PARTNER_CONSULTANT'
}


