/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface EegClinicalMeaning
 */
export interface EegClinicalMeaning {
    /**
     * 
     * @type {string}
     * @memberof EegClinicalMeaning
     */
    'main'?: EegClinicalMeaningMainEnum;
    /**
     * 
     * @type {string}
     * @memberof EegClinicalMeaning
     */
    'sub'?: EegClinicalMeaningSubEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EegClinicalMeaningMainEnum {
    CorrectRecord = 'CORRECT_RECORD',
    IncorrectRecord = 'INCORRECT_RECORD',
    UncertainRecord = 'UNCERTAIN_RECORD'
}
/**
    * @export
    * @enum {string}
    */
export enum EegClinicalMeaningSubEnum {
    Epilepsy = 'EPILEPSY',
    Pnes = 'PNES',
    ClinicalEpisode = 'CLINICAL_EPISODE',
    FocusedDysfunction = 'FOCUSED_DYSFUNCTION',
    UnfocusedDysfunction = 'UNFOCUSED_DYSFUNCTION',
    EpilepsyState = 'EPILEPSY_STATE',
    Csws = 'CSWS',
    Eses = 'ESES',
    Coma = 'COMA',
    BrainDeath = 'BRAIN_DEATH'
}


