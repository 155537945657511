/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { VisitPackageStageDoctorDto } from './visit-package-stage-doctor-dto';
import { VisitPackageStageReferralDto } from './visit-package-stage-referral-dto';

/**
 * 
 * @export
 * @interface EditVisitPackageStageDto
 */
export interface EditVisitPackageStageDto {
    /**
     * 
     * @type {boolean}
     * @memberof EditVisitPackageStageDto
     */
    'doctorSelection': boolean;
    /**
     * 
     * @type {Array<VisitPackageStageDoctorDto>}
     * @memberof EditVisitPackageStageDto
     */
    'doctors': Array<VisitPackageStageDoctorDto>;
    /**
     * 
     * @type {number}
     * @memberof EditVisitPackageStageDto
     */
    'duration': number;
    /**
     * 
     * @type {number}
     * @memberof EditVisitPackageStageDto
     */
    'id': number;
    /**
     * 
     * @type {Array<VisitPackageStageReferralDto>}
     * @memberof EditVisitPackageStageDto
     */
    'referrals': Array<VisitPackageStageReferralDto>;
    /**
     * 
     * @type {boolean}
     * @memberof EditVisitPackageStageDto
     */
    'sameDateAsPrevious': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditVisitPackageStageDto
     */
    'sameDoctorAsPrevious': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditVisitPackageStageDto
     */
    'samePlaceAsPrevious': boolean;
    /**
     * 
     * @type {string}
     * @memberof EditVisitPackageStageDto
     */
    'visitType'?: EditVisitPackageStageDtoVisitTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EditVisitPackageStageDtoVisitTypeEnum {
    Remote = 'REMOTE',
    Stationary = 'STATIONARY',
    Absent = 'ABSENT'
}


