/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AddSickLeaveCodesDto } from './add-sick-leave-codes-dto';
import { AddSickLeavePatientDto } from './add-sick-leave-patient-dto';
import { ICD10Dto } from './icd10-dto';
import { ZusPayerDto } from './zus-payer-dto';
import { ZusWorkPlaceDto } from './zus-work-place-dto';

/**
 * 
 * @export
 * @interface AddSickLeaveDto
 */
export interface AddSickLeaveDto {
    /**
     * 
     * @type {AddSickLeaveCodesDto}
     * @memberof AddSickLeaveDto
     */
    'codes': AddSickLeaveCodesDto;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeaveDto
     */
    'doctorPass': string;
    /**
     * 
     * @type {ICD10Dto}
     * @memberof AddSickLeaveDto
     */
    'icd10'?: ICD10Dto;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeaveDto
     */
    'inabilityFrom': string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeaveDto
     */
    'inabilityTo': string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeaveDto
     */
    'indication': AddSickLeaveDtoIndicationEnum;
    /**
     * 
     * @type {AddSickLeavePatientDto}
     * @memberof AddSickLeaveDto
     */
    'insuredPerson': AddSickLeavePatientDto;
    /**
     * 
     * @type {Array<ZusPayerDto>}
     * @memberof AddSickLeaveDto
     */
    'payers': Array<ZusPayerDto>;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeaveDto
     */
    'perName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeaveDto
     */
    'perSurname'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeaveDto
     */
    'reason'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddSickLeaveDto
     */
    'relationshipCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeaveDto
     */
    'type': AddSickLeaveDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AddSickLeaveDto
     */
    'visitId': number;
    /**
     * 
     * @type {ZusWorkPlaceDto}
     * @memberof AddSickLeaveDto
     */
    'workPlace': ZusWorkPlaceDto;
}

/**
    * @export
    * @enum {string}
    */
export enum AddSickLeaveDtoIndicationEnum {
    ShouldLie = 'SHOULD_LIE',
    CanWalk = 'CAN_WALK'
}
/**
    * @export
    * @enum {string}
    */
export enum AddSickLeaveDtoTypeEnum {
    Disease = 'DISEASE',
    Care = 'CARE'
}


