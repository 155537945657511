/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface NeuroscreenAdultDto
 */
export interface NeuroscreenAdultDto {
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'dizziness1'?: NeuroscreenAdultDtoDizziness1Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'dizziness2'?: NeuroscreenAdultDtoDizziness2Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'dizziness3'?: NeuroscreenAdultDtoDizziness3Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'dizziness4'?: NeuroscreenAdultDtoDizziness4Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'dizziness5'?: NeuroscreenAdultDtoDizziness5Enum;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenAdultDto
     */
    'dizzinessGeneral': boolean;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'epilepsy1'?: NeuroscreenAdultDtoEpilepsy1Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'epilepsy2'?: NeuroscreenAdultDtoEpilepsy2Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'epilepsy3'?: NeuroscreenAdultDtoEpilepsy3Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'epilepsy4'?: NeuroscreenAdultDtoEpilepsy4Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'epilepsy5'?: NeuroscreenAdultDtoEpilepsy5Enum;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenAdultDto
     */
    'epilepsyGeneral': boolean;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'headaches1'?: NeuroscreenAdultDtoHeadaches1Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'headaches2'?: NeuroscreenAdultDtoHeadaches2Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'headaches3'?: NeuroscreenAdultDtoHeadaches3Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'headaches4'?: NeuroscreenAdultDtoHeadaches4Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'headaches5'?: NeuroscreenAdultDtoHeadaches5Enum;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenAdultDto
     */
    'headachesGeneral': boolean;
    /**
     * 
     * @type {number}
     * @memberof NeuroscreenAdultDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'memoryIssues1'?: NeuroscreenAdultDtoMemoryIssues1Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'memoryIssues2'?: NeuroscreenAdultDtoMemoryIssues2Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'memoryIssues3'?: NeuroscreenAdultDtoMemoryIssues3Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'memoryIssues4'?: NeuroscreenAdultDtoMemoryIssues4Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'memoryIssues5'?: NeuroscreenAdultDtoMemoryIssues5Enum;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenAdultDto
     */
    'memoryIssuesGeneral': boolean;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'movement1'?: NeuroscreenAdultDtoMovement1Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'movement2'?: NeuroscreenAdultDtoMovement2Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'movement3'?: NeuroscreenAdultDtoMovement3Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'movement4'?: NeuroscreenAdultDtoMovement4Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'movement5'?: NeuroscreenAdultDtoMovement5Enum;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenAdultDto
     */
    'movementGeneral': boolean;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'mutipleSclerosis1'?: NeuroscreenAdultDtoMutipleSclerosis1Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'mutipleSclerosis2'?: NeuroscreenAdultDtoMutipleSclerosis2Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'mutipleSclerosis3'?: NeuroscreenAdultDtoMutipleSclerosis3Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'mutipleSclerosis4'?: NeuroscreenAdultDtoMutipleSclerosis4Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'mutipleSclerosis5'?: NeuroscreenAdultDtoMutipleSclerosis5Enum;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenAdultDto
     */
    'mutipleSclerosisGeneral': boolean;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'otherSymptoms1'?: NeuroscreenAdultDtoOtherSymptoms1Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'otherSymptoms2'?: NeuroscreenAdultDtoOtherSymptoms2Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'otherSymptoms3'?: NeuroscreenAdultDtoOtherSymptoms3Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'otherSymptoms4'?: NeuroscreenAdultDtoOtherSymptoms4Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'otherSymptoms5'?: NeuroscreenAdultDtoOtherSymptoms5Enum;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenAdultDto
     */
    'otherSymptomsGeneral': boolean;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'publicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'spineIssues1'?: NeuroscreenAdultDtoSpineIssues1Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'spineIssues2'?: NeuroscreenAdultDtoSpineIssues2Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'spineIssues3'?: NeuroscreenAdultDtoSpineIssues3Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'spineIssues4'?: NeuroscreenAdultDtoSpineIssues4Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenAdultDto
     */
    'spineIssues5'?: NeuroscreenAdultDtoSpineIssues5Enum;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenAdultDto
     */
    'spineIssuesGeneral': boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoDizziness1Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoDizziness2Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoDizziness3Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoDizziness4Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoDizziness5Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoEpilepsy1Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoEpilepsy2Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoEpilepsy3Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoEpilepsy4Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoEpilepsy5Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoHeadaches1Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoHeadaches2Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoHeadaches3Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoHeadaches4Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoHeadaches5Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoMemoryIssues1Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoMemoryIssues2Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoMemoryIssues3Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoMemoryIssues4Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoMemoryIssues5Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoMovement1Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoMovement2Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoMovement3Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoMovement4Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoMovement5Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoMutipleSclerosis1Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoMutipleSclerosis2Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoMutipleSclerosis3Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoMutipleSclerosis4Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoMutipleSclerosis5Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoOtherSymptoms1Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoOtherSymptoms2Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoOtherSymptoms3Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoOtherSymptoms4Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoOtherSymptoms5Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoSpineIssues1Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoSpineIssues2Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoSpineIssues3Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoSpineIssues4Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenAdultDtoSpineIssues5Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}


