/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { SurveyIcd10 } from './survey-icd10';
import { SurveySeizureDto } from './survey-seizure-dto';

/**
 * 
 * @export
 * @interface VisitSurveyDto
 */
export interface VisitSurveyDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VisitSurveyDto
     */
    'additionalIncidents': Array<VisitSurveyDtoAdditionalIncidentsEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof VisitSurveyDto
     */
    'allSeizureRegistered'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VisitSurveyDto
     */
    'currentTreatment'?: VisitSurveyDtoCurrentTreatmentEnum;
    /**
     * 
     * @type {string}
     * @memberof VisitSurveyDto
     */
    'hospitalizationDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof VisitSurveyDto
     */
    'id'?: number;
    /**
     * 
     * @type {Array<SurveyIcd10>}
     * @memberof VisitSurveyDto
     */
    'injuriesIcd10': Array<SurveyIcd10>;
    /**
     * 
     * @type {Array<SurveyIcd10>}
     * @memberof VisitSurveyDto
     */
    'newDiseasesIcd10': Array<SurveyIcd10>;
    /**
     * 
     * @type {string}
     * @memberof VisitSurveyDto
     */
    'otherIncidentsDescription'?: string;
    /**
     * 
     * @type {Array<SurveySeizureDto>}
     * @memberof VisitSurveyDto
     */
    'seizures': Array<SurveySeizureDto>;
    /**
     * 
     * @type {string}
     * @memberof VisitSurveyDto
     */
    'sideEffects'?: VisitSurveyDtoSideEffectsEnum;
    /**
     * 
     * @type {string}
     * @memberof VisitSurveyDto
     */
    'treatmentEfficiency'?: VisitSurveyDtoTreatmentEfficiencyEnum;
    /**
     * 
     * @type {string}
     * @memberof VisitSurveyDto
     */
    'treatmentSafety'?: VisitSurveyDtoTreatmentSafetyEnum;
    /**
     * 
     * @type {boolean}
     * @memberof VisitSurveyDto
     */
    'updateEpisodes'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VisitSurveyDto
     */
    'visitId': number;
    /**
     * 
     * @type {string}
     * @memberof VisitSurveyDto
     */
    'wellBeing'?: VisitSurveyDtoWellBeingEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum VisitSurveyDtoAdditionalIncidentsEnum {
    NewDiseases = 'NEW_DISEASES',
    Injuries = 'INJURIES',
    Hospitalizations = 'HOSPITALIZATIONS',
    Other = 'OTHER',
    None = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum VisitSurveyDtoCurrentTreatmentEnum {
    Worst = 'WORST',
    VeryBad = 'VERY_BAD',
    Bad = 'BAD',
    NoChange = 'NO_CHANGE',
    BitBetter = 'BIT_BETTER',
    MuchBetter = 'MUCH_BETTER',
    Ideal = 'IDEAL'
}
/**
    * @export
    * @enum {string}
    */
export enum VisitSurveyDtoSideEffectsEnum {
    Worst = 'WORST',
    Bad = 'BAD',
    Bearable = 'BEARABLE',
    Minimal = 'MINIMAL',
    NoTreatment = 'NO_TREATMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum VisitSurveyDtoTreatmentEfficiencyEnum {
    Ideal = 'IDEAL',
    Good = 'GOOD',
    Mediocre = 'MEDIOCRE',
    Bad = 'BAD',
    Worst = 'WORST',
    NoTreatment = 'NO_TREATMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum VisitSurveyDtoTreatmentSafetyEnum {
    Total = 'TOTAL',
    Acceptable = 'ACCEPTABLE',
    Weak = 'WEAK',
    Bad = 'BAD',
    Worst = 'WORST'
}
/**
    * @export
    * @enum {string}
    */
export enum VisitSurveyDtoWellBeingEnum {
    Worst = 'WORST',
    MuchWorse = 'MUCH_WORSE',
    BitWorse = 'BIT_WORSE',
    Same = 'SAME',
    BitBetter = 'BIT_BETTER',
    MuchBetter = 'MUCH_BETTER',
    Ideal = 'IDEAL'
}


