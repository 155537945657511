export default {
  "AD": {
    "code": "AD",
    "name": "Andorre"
  },
  "AE": {
    "code": "AE",
    "name": "Emirats Arabes Unis"
  },
  "AF": {
    "code": "AF",
    "name": "Afghanistan"
  },
  "AG": {
    "code": "AG",
    "name": "Antigua-et-Barbuda"
  },
  "AI": {
    "code": "AI",
    "name": "Anguilla"
  },
  "AL": {
    "code": "AL",
    "name": "Albanie"
  },
  "AM": {
    "code": "AM",
    "name": "Arménie"
  },
  "AO": {
    "code": "AO",
    "name": "Angola"
  },
  "AQ": {
    "code": "AQ",
    "name": "Antarctique"
  },
  "AR": {
    "code": "AR",
    "name": "Argentine"
  },
  "AS": {
    "code": "AS",
    "name": "Samoa américaines"
  },
  "AT": {
    "code": "AT",
    "name": "L'Autriche"
  },
  "AU": {
    "code": "AU",
    "name": "Australie"
  },
  "AW": {
    "code": "AW",
    "name": "Aruba"
  },
  "AX": {
    "code": "AX",
    "name": "? îles terrestres"
  },
  "AZ": {
    "code": "AZ",
    "name": "Azerbaïdjan"
  },
  "BA": {
    "code": "BA",
    "name": "Bosnie Herzégovine"
  },
  "BB": {
    "code": "BB",
    "name": "Barbade"
  },
  "BD": {
    "code": "BD",
    "name": "Bangladesh"
  },
  "BE": {
    "code": "BE",
    "name": "Belgique"
  },
  "BF": {
    "code": "BF",
    "name": "Burkina Faso"
  },
  "BG": {
    "code": "BG",
    "name": "Bulgarie"
  },
  "BH": {
    "code": "BH",
    "name": "Bahreïn"
  },
  "BI": {
    "code": "BI",
    "name": "Burundi"
  },
  "BJ": {
    "code": "BJ",
    "name": "Bénin"
  },
  "BL": {
    "code": "BL",
    "name": "Saint Barthélemy"
  },
  "BM": {
    "code": "BM",
    "name": "Bermudes"
  },
  "BN": {
    "code": "BN",
    "name": "Brunei Darussalam"
  },
  "BO": {
    "code": "BO",
    "name": "Bolivie, État plurinational de"
  },
  "BQ": {
    "code": "BQ",
    "name": "Bonaire, Saint-Eustache et Saba"
  },
  "BR": {
    "code": "BR",
    "name": "Brésil"
  },
  "BS": {
    "code": "BS",
    "name": "Bahamas"
  },
  "BT": {
    "code": "BT",
    "name": "Bhoutan"
  },
  "BV": {
    "code": "BV",
    "name": "Île Bouvet"
  },
  "BW": {
    "code": "BW",
    "name": "Botswana"
  },
  "BY": {
    "code": "BY",
    "name": "Biélorussie"
  },
  "BZ": {
    "code": "BZ",
    "name": "Belize"
  },
  "CA": {
    "code": "CA",
    "name": "Canada"
  },
  "CC": {
    "code": "CC",
    "name": "Îles Cocos (Keeling)"
  },
  "CD": {
    "code": "CD",
    "name": "Congo, République démocratique du"
  },
  "CF": {
    "code": "CF",
    "name": "République centrafricaine"
  },
  "CG": {
    "code": "CG",
    "name": "Congo"
  },
  "CH": {
    "code": "CH",
    "name": "Suisse"
  },
  "CI": {
    "code": "CI",
    "name": "Côte d'Ivoire"
  },
  "CK": {
    "code": "CK",
    "name": "les Îles Cook"
  },
  "CL": {
    "code": "CL",
    "name": "Chili"
  },
  "CM": {
    "code": "CM",
    "name": "Cameroun"
  },
  "CN": {
    "code": "CN",
    "name": "Chine"
  },
  "CO": {
    "code": "CO",
    "name": "Colombie"
  },
  "CR": {
    "code": "CR",
    "name": "Costa Rica"
  },
  "CU": {
    "code": "CU",
    "name": "Cuba"
  },
  "CV": {
    "code": "CV",
    "name": "Cap-Vert"
  },
  "CW": {
    "code": "CW",
    "name": "Curacao"
  },
  "CX": {
    "code": "CX",
    "name": "L'île de noël"
  },
  "CY": {
    "code": "CY",
    "name": "Chypre"
  },
  "CZ": {
    "code": "CZ",
    "name": "République Tchèque"
  },
  "DE": {
    "code": "DE",
    "name": "Allemagne"
  },
  "DJ": {
    "code": "DJ",
    "name": "Djibouti"
  },
  "DK": {
    "code": "DK",
    "name": "Danemark"
  },
  "DM": {
    "code": "DM",
    "name": "Dominique"
  },
  "DO": {
    "code": "DO",
    "name": "République Dominicaine"
  },
  "DZ": {
    "code": "DZ",
    "name": "Algérie"
  },
  "EC": {
    "code": "EC",
    "name": "Equateur"
  },
  "EE": {
    "code": "EE",
    "name": "Estonie"
  },
  "EG": {
    "code": "EG",
    "name": "Egypte"
  },
  "EH": {
    "code": "EH",
    "name": "Sahara occidental"
  },
  "ER": {
    "code": "ER",
    "name": "Érythrée"
  },
  "ES": {
    "code": "ES",
    "name": "Espagne"
  },
  "ET": {
    "code": "ET",
    "name": "Ethiopie"
  },
  "FI": {
    "code": "FI",
    "name": "Finlande"
  },
  "FJ": {
    "code": "FJ",
    "name": "Fidji"
  },
  "FK": {
    "code": "FK",
    "name": "Îles Falkland (Malvinas)"
  },
  "FM": {
    "code": "FM",
    "name": "Micronésie, États fédérés de"
  },
  "FO": {
    "code": "FO",
    "name": "Îles Féroé"
  },
  "FR": {
    "code": "FR",
    "name": "France"
  },
  "GA": {
    "code": "GA",
    "name": "Gabon"
  },
  "GB": {
    "code": "GB",
    "name": "Royaume-Uni"
  },
  "GD": {
    "code": "GD",
    "name": "Grenade"
  },
  "GE": {
    "code": "GE",
    "name": "Géorgie"
  },
  "GF": {
    "code": "GF",
    "name": "Guyane Française"
  },
  "GG": {
    "code": "GG",
    "name": "Guernesey"
  },
  "GH": {
    "code": "GH",
    "name": "Ghana"
  },
  "GI": {
    "code": "GI",
    "name": "Gibraltar"
  },
  "GL": {
    "code": "GL",
    "name": "Groenland"
  },
  "GM": {
    "code": "GM",
    "name": "Gambie"
  },
  "GN": {
    "code": "GN",
    "name": "Guinée"
  },
  "GP": {
    "code": "GP",
    "name": "Guadeloupe"
  },
  "GQ": {
    "code": "GQ",
    "name": "Guinée Équatoriale"
  },
  "GR": {
    "code": "GR",
    "name": "Grèce"
  },
  "GS": {
    "code": "GS",
    "name": "Géorgie du Sud et îles Sandwich du Sud"
  },
  "GT": {
    "code": "GT",
    "name": "Guatemala"
  },
  "GU": {
    "code": "GU",
    "name": "Guam"
  },
  "GW": {
    "code": "GW",
    "name": "Guinée-Bissau"
  },
  "GY": {
    "code": "GY",
    "name": "Guyane"
  },
  "HK": {
    "code": "HK",
    "name": "Hong Kong"
  },
  "HM": {
    "code": "HM",
    "name": "Îles Heard et McDonald"
  },
  "HN": {
    "code": "HN",
    "name": "Honduras"
  },
  "HR": {
    "code": "HR",
    "name": "Croatie"
  },
  "HT": {
    "code": "HT",
    "name": "Haïti"
  },
  "HU": {
    "code": "HU",
    "name": "Hongrie"
  },
  "ID": {
    "code": "ID",
    "name": "Indonésie"
  },
  "IE": {
    "code": "IE",
    "name": "Irlande"
  },
  "IL": {
    "code": "IL",
    "name": "Israël"
  },
  "IM": {
    "code": "IM",
    "name": "île de Man"
  },
  "IN": {
    "code": "IN",
    "name": "Inde"
  },
  "IO": {
    "code": "IO",
    "name": "Territoire britannique de l'océan Indien"
  },
  "IQ": {
    "code": "IQ",
    "name": "Irak"
  },
  "IR": {
    "code": "IR",
    "name": "Iran (République islamique d"
  },
  "IS": {
    "code": "IS",
    "name": "Islande"
  },
  "IT": {
    "code": "IT",
    "name": "Italie"
  },
  "JE": {
    "code": "JE",
    "name": "Jersey"
  },
  "JM": {
    "code": "JM",
    "name": "Jamaïque"
  },
  "JO": {
    "code": "JO",
    "name": "Jordan"
  },
  "JP": {
    "code": "JP",
    "name": "Japon"
  },
  "KE": {
    "code": "KE",
    "name": "Kenya"
  },
  "KG": {
    "code": "KG",
    "name": "Kirghizistan"
  },
  "KH": {
    "code": "KH",
    "name": "Cambodge"
  },
  "KI": {
    "code": "KI",
    "name": "Kiribati"
  },
  "KM": {
    "code": "KM",
    "name": "Comores"
  },
  "KN": {
    "code": "KN",
    "name": "Saint-Christophe-et-Niévès"
  },
  "KP": {
    "code": "KP",
    "name": "République populaire démocratique de Corée"
  },
  "KR": {
    "code": "KR",
    "name": "Corée, République de"
  },
  "KW": {
    "code": "KW",
    "name": "Koweit"
  },
  "KY": {
    "code": "KY",
    "name": "Îles Caïmans"
  },
  "KZ": {
    "code": "KZ",
    "name": "Kazakhstan"
  },
  "LA": {
    "code": "LA",
    "name": "République démocratique populaire lao"
  },
  "LB": {
    "code": "LB",
    "name": "Liban"
  },
  "LC": {
    "code": "LC",
    "name": "Sainte-Lucie"
  },
  "LI": {
    "code": "LI",
    "name": "Liechtenstein"
  },
  "LK": {
    "code": "LK",
    "name": "Sri Lanka"
  },
  "LR": {
    "code": "LR",
    "name": "Libéria"
  },
  "LS": {
    "code": "LS",
    "name": "Lesotho"
  },
  "LT": {
    "code": "LT",
    "name": "Lituanie"
  },
  "LU": {
    "code": "LU",
    "name": "Luxembourg"
  },
  "LV": {
    "code": "LV",
    "name": "Lettonie"
  },
  "LY": {
    "code": "LY",
    "name": "Libye"
  },
  "MA": {
    "code": "MA",
    "name": "Maroc"
  },
  "MC": {
    "code": "MC",
    "name": "Monaco"
  },
  "MD": {
    "code": "MD",
    "name": "Moldova, République de"
  },
  "ME": {
    "code": "ME",
    "name": "Monténégro"
  },
  "MF": {
    "code": "MF",
    "name": "Saint Martin (partie française)"
  },
  "MG": {
    "code": "MG",
    "name": "Madagascar"
  },
  "MH": {
    "code": "MH",
    "name": "Iles Marshall"
  },
  "MK": {
    "code": "MK",
    "name": "Macédoine, ancienne République yougoslave de"
  },
  "ML": {
    "code": "ML",
    "name": "Mali"
  },
  "MM": {
    "code": "MM",
    "name": "Myanmar"
  },
  "MN": {
    "code": "MN",
    "name": "Mongolie"
  },
  "MO": {
    "code": "MO",
    "name": "Macao"
  },
  "MP": {
    "code": "MP",
    "name": "Îles Mariannes du Nord"
  },
  "MQ": {
    "code": "MQ",
    "name": "Martinique"
  },
  "MR": {
    "code": "MR",
    "name": "Mauritanie"
  },
  "MS": {
    "code": "MS",
    "name": "Montserrat"
  },
  "MT": {
    "code": "MT",
    "name": "Malte"
  },
  "MU": {
    "code": "MU",
    "name": "Ile Maurice"
  },
  "MV": {
    "code": "MV",
    "name": "Maldives"
  },
  "MW": {
    "code": "MW",
    "name": "Malawi"
  },
  "MX": {
    "code": "MX",
    "name": "Mexique"
  },
  "MY": {
    "code": "MY",
    "name": "Malaisie"
  },
  "MZ": {
    "code": "MZ",
    "name": "Mozambique"
  },
  "NA": {
    "code": "NA",
    "name": "Namibie"
  },
  "NC": {
    "code": "NC",
    "name": "Nouvelle Calédonie"
  },
  "NE": {
    "code": "NE",
    "name": "Niger"
  },
  "NF": {
    "code": "NF",
    "name": "l'ile de Norfolk"
  },
  "NG": {
    "code": "NG",
    "name": "Nigeria"
  },
  "NI": {
    "code": "NI",
    "name": "Nicaragua"
  },
  "NL": {
    "code": "NL",
    "name": "Pays-Bas"
  },
  "NO": {
    "code": "NO",
    "name": "Norvège"
  },
  "NP": {
    "code": "NP",
    "name": "Népal"
  },
  "NR": {
    "code": "NR",
    "name": "Nauru"
  },
  "NU": {
    "code": "NU",
    "name": "Niue"
  },
  "NZ": {
    "code": "NZ",
    "name": "Nouvelle-Zélande"
  },
  "OM": {
    "code": "OM",
    "name": "Oman"
  },
  "PA": {
    "code": "PA",
    "name": "Panama"
  },
  "PE": {
    "code": "PE",
    "name": "Pérou"
  },
  "PF": {
    "code": "PF",
    "name": "Polynésie française"
  },
  "PG": {
    "code": "PG",
    "name": "Papouasie Nouvelle Guinée"
  },
  "PH": {
    "code": "PH",
    "name": "Philippines"
  },
  "PK": {
    "code": "PK",
    "name": "Pakistan"
  },
  "PL": {
    "code": "PL",
    "name": "Pologne"
  },
  "PM": {
    "code": "PM",
    "name": "Saint-Pierre-et-Miquelon"
  },
  "PN": {
    "code": "PN",
    "name": "Pitcairn"
  },
  "PR": {
    "code": "PR",
    "name": "Porto Rico"
  },
  "PS": {
    "code": "PS",
    "name": "Palestine, État de"
  },
  "PT": {
    "code": "PT",
    "name": "le Portugal"
  },
  "PW": {
    "code": "PW",
    "name": "Palau"
  },
  "PY": {
    "code": "PY",
    "name": "Paraguay"
  },
  "QA": {
    "code": "QA",
    "name": "Qatar"
  },
  "RE": {
    "code": "RE",
    "name": "Réunion"
  },
  "RO": {
    "code": "RO",
    "name": "Roumanie"
  },
  "RS": {
    "code": "RS",
    "name": "Serbie"
  },
  "RU": {
    "code": "RU",
    "name": "Fédération Russe"
  },
  "RW": {
    "code": "RW",
    "name": "Rwanda"
  },
  "SA": {
    "code": "SA",
    "name": "Arabie Saoudite"
  },
  "SB": {
    "code": "SB",
    "name": "îles Salomon"
  },
  "SC": {
    "code": "SC",
    "name": "les Seychelles"
  },
  "SD": {
    "code": "SD",
    "name": "Soudan"
  },
  "SE": {
    "code": "SE",
    "name": "Suède"
  },
  "SG": {
    "code": "SG",
    "name": "Singapour"
  },
  "SH": {
    "code": "SH",
    "name": "Sainte-Hélène, Ascension et Tristan da Cunha"
  },
  "SI": {
    "code": "SI",
    "name": "Slovénie"
  },
  "SJ": {
    "code": "SJ",
    "name": "Svalbard et Jan Mayen"
  },
  "SK": {
    "code": "SK",
    "name": "Slovaquie"
  },
  "SL": {
    "code": "SL",
    "name": "Sierra Leone"
  },
  "SM": {
    "code": "SM",
    "name": "Saint Marin"
  },
  "SN": {
    "code": "SN",
    "name": "Sénégal"
  },
  "SO": {
    "code": "SO",
    "name": "Somalie"
  },
  "SR": {
    "code": "SR",
    "name": "Suriname"
  },
  "SS": {
    "code": "SS",
    "name": "Soudan du sud"
  },
  "ST": {
    "code": "ST",
    "name": "Sao Tomé et Principe"
  },
  "SV": {
    "code": "SV",
    "name": "Le Salvador"
  },
  "SX": {
    "code": "SX",
    "name": "Sint Maarten (partie néerlandaise)"
  },
  "SY": {
    "code": "SY",
    "name": "République arabe syrienne"
  },
  "SZ": {
    "code": "SZ",
    "name": "Swaziland"
  },
  "TC": {
    "code": "TC",
    "name": "îles Turques-et-Caïques"
  },
  "TD": {
    "code": "TD",
    "name": "Tchad"
  },
  "TF": {
    "code": "TF",
    "name": "Terres australes françaises"
  },
  "TG": {
    "code": "TG",
    "name": "Aller"
  },
  "TH": {
    "code": "TH",
    "name": "Thaïlande"
  },
  "TJ": {
    "code": "TJ",
    "name": "Tadjikistan"
  },
  "TK": {
    "code": "TK",
    "name": "Tokelau"
  },
  "TL": {
    "code": "TL",
    "name": "Timor-Leste"
  },
  "TM": {
    "code": "TM",
    "name": "Turkménistan"
  },
  "TN": {
    "code": "TN",
    "name": "Tunisie"
  },
  "TO": {
    "code": "TO",
    "name": "Tonga"
  },
  "TR": {
    "code": "TR",
    "name": "dinde"
  },
  "TT": {
    "code": "TT",
    "name": "Trinité-et-Tobago"
  },
  "TV": {
    "code": "TV",
    "name": "Tuvalu"
  },
  "TW": {
    "code": "TW",
    "name": "Taiwan, Province de Chine"
  },
  "TZ": {
    "code": "TZ",
    "name": "Tanzanie, République-Unie de"
  },
  "UA": {
    "code": "UA",
    "name": "Ukraine"
  },
  "UG": {
    "code": "UG",
    "name": "Ouganda"
  },
  "UM": {
    "code": "UM",
    "name": "Îles mineures éloignées des États-Unis"
  },
  "US": {
    "code": "US",
    "name": "États Unis"
  },
  "UY": {
    "code": "UY",
    "name": "Uruguay"
  },
  "UZ": {
    "code": "UZ",
    "name": "Ouzbékistan"
  },
  "VA": {
    "code": "VA",
    "name": "Saint-Siège (État de la Cité du Vatican)"
  },
  "VC": {
    "code": "VC",
    "name": "Saint-Vincent-et-les-Grenadines"
  },
  "VE": {
    "code": "VE",
    "name": "Venezuela, République bolivarienne du"
  },
  "VG": {
    "code": "VG",
    "name": "Îles Vierges britanniques"
  },
  "VI": {
    "code": "VI",
    "name": "Îles Vierges américaines"
  },
  "VN": {
    "code": "VN",
    "name": "Viet Nam"
  },
  "VU": {
    "code": "VU",
    "name": "Vanuatu"
  },
  "WF": {
    "code": "WF",
    "name": "Wallis et Futuna"
  },
  "WS": {
    "code": "WS",
    "name": "Samoa"
  },
  "YE": {
    "code": "YE",
    "name": "Yémen"
  },
  "YT": {
    "code": "YT",
    "name": "Mayotte"
  },
  "ZA": {
    "code": "ZA",
    "name": "Afrique du Sud"
  },
  "ZM": {
    "code": "ZM",
    "name": "Zambie"
  },
  "ZW": {
    "code": "ZW",
    "name": "Zimbabwe"
  }
}