/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BrainActivityDtoEegInterictalActivityEnum
 */
export interface BrainActivityDtoEegInterictalActivityEnum {
    /**
     * 
     * @type {string}
     * @memberof BrainActivityDtoEegInterictalActivityEnum
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BrainActivityDtoEegInterictalActivityEnum
     */
    'electrodes'?: Array<BrainActivityDtoEegInterictalActivityEnumElectrodesEnum>;
    /**
     * 
     * @type {string}
     * @memberof BrainActivityDtoEegInterictalActivityEnum
     */
    'lateralization'?: BrainActivityDtoEegInterictalActivityEnumLateralizationEnum;
    /**
     * 
     * @type {string}
     * @memberof BrainActivityDtoEegInterictalActivityEnum
     */
    'name': BrainActivityDtoEegInterictalActivityEnumNameEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof BrainActivityDtoEegInterictalActivityEnum
     */
    'regions'?: Array<BrainActivityDtoEegInterictalActivityEnumRegionsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum BrainActivityDtoEegInterictalActivityEnumElectrodesEnum {
    Fp1 = 'FP1',
    F3 = 'F3',
    C3 = 'C3',
    P3 = 'P3',
    F7 = 'F7',
    T7 = 'T7',
    P7 = 'P7',
    O1 = 'O1',
    F9 = 'F9',
    T9 = 'T9',
    P9 = 'P9',
    Fz = 'FZ',
    Cz = 'CZ',
    Pz = 'PZ',
    Fp2 = 'FP2',
    F4 = 'F4',
    C4 = 'C4',
    P4 = 'P4',
    F8 = 'F8',
    T8 = 'T8',
    P8 = 'P8',
    O2 = 'O2',
    F10 = 'F10',
    T10 = 'T10',
    P10 = 'P10'
}
/**
    * @export
    * @enum {string}
    */
export enum BrainActivityDtoEegInterictalActivityEnumLateralizationEnum {
    RightSide = 'RIGHT_SIDE',
    LeftSide = 'LEFT_SIDE',
    BothSide = 'BOTH_SIDE'
}
/**
    * @export
    * @enum {string}
    */
export enum BrainActivityDtoEegInterictalActivityEnumNameEnum {
    None = 'NONE',
    Spire = 'SPIRE',
    SpireAssembly = 'SPIRE_ASSEMBLY',
    MultiSpire = 'MULTI_SPIRE',
    MultiSpireAssembly = 'MULTI_SPIRE_ASSEMBLY',
    SharpWave = 'SHARP_WAVE',
    SharpWaveAssembly = 'SHARP_WAVE_ASSEMBLY',
    Oscillations = 'OSCILLATIONS',
    Hypsarrhythmia = 'HYPSARRHYTHMIA',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum BrainActivityDtoEegInterictalActivityEnumRegionsEnum {
    Frontal = 'FRONTAL',
    Temporal = 'TEMPORAL',
    Central = 'CENTRAL',
    Parietal = 'PARIETAL',
    Occipital = 'OCCIPITAL',
    General = 'GENERAL'
}


