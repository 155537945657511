/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PagePrescriptionRequestSummaryDto } from '../model';
// @ts-ignore
import { Pageable } from '../model';
// @ts-ignore
import { PrescriptionRejectionDto } from '../model';
// @ts-ignore
import { PrescriptionRequestDetailsDto } from '../model';
// @ts-ignore
import { PrescriptionRequestDto } from '../model';
/**
 * MedicalRequestControllerApi - axios parameter creator
 * @export
 */
export const MedicalRequestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PrescriptionRequestDto} prescriptionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewPrescriptionMedicalRequest: async (prescriptionRequestDto: PrescriptionRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prescriptionRequestDto' is not null or undefined
            assertParamExists('addNewPrescriptionMedicalRequest', 'prescriptionRequestDto', prescriptionRequestDto)
            const localVarPath = `/api/medical-request/prescription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(prescriptionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardPrescriptionMedicalRequest: async (id: number, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forwardPrescriptionMedicalRequest', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('forwardPrescriptionMedicalRequest', 'body', body)
            const localVarPath = `/api/medical-request/prescription/forward/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {boolean} [done] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrescriptionMedicalRequests: async (pageable: Pageable, done?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getPrescriptionMedicalRequests', 'pageable', pageable)
            const localVarPath = `/api/medical-request/prescription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (done !== undefined) {
                localVarQueryParameter['done'] = done;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrescriptionMedicalRequestsDetails: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPrescriptionMedicalRequestsDetails', 'id', id)
            const localVarPath = `/api/medical-request/prescription/{id}/details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrescriptionVisitDetails: async (visitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('getPrescriptionVisitDetails', 'visitId', visitId)
            const localVarPath = `/api/medical-request/prescription/visit/{visitId}/details`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PrescriptionRejectionDto} prescriptionRejectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectPrescriptionMedicalRequest: async (id: number, prescriptionRejectionDto: PrescriptionRejectionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rejectPrescriptionMedicalRequest', 'id', id)
            // verify required parameter 'prescriptionRejectionDto' is not null or undefined
            assertParamExists('rejectPrescriptionMedicalRequest', 'prescriptionRejectionDto', prescriptionRejectionDto)
            const localVarPath = `/api/medical-request/prescription/reject/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(prescriptionRejectionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MedicalRequestControllerApi - functional programming interface
 * @export
 */
export const MedicalRequestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MedicalRequestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PrescriptionRequestDto} prescriptionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNewPrescriptionMedicalRequest(prescriptionRequestDto: PrescriptionRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNewPrescriptionMedicalRequest(prescriptionRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forwardPrescriptionMedicalRequest(id: number, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forwardPrescriptionMedicalRequest(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {boolean} [done] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrescriptionMedicalRequests(pageable: Pageable, done?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PagePrescriptionRequestSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrescriptionMedicalRequests(pageable, done, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrescriptionMedicalRequestsDetails(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PrescriptionRequestDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrescriptionMedicalRequestsDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrescriptionVisitDetails(visitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PrescriptionRequestDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrescriptionVisitDetails(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PrescriptionRejectionDto} prescriptionRejectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectPrescriptionMedicalRequest(id: number, prescriptionRejectionDto: PrescriptionRejectionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectPrescriptionMedicalRequest(id, prescriptionRejectionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MedicalRequestControllerApi - factory interface
 * @export
 */
export const MedicalRequestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MedicalRequestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PrescriptionRequestDto} prescriptionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewPrescriptionMedicalRequest(prescriptionRequestDto: PrescriptionRequestDto, options?: any): Promise<string> {
            return localVarFp.addNewPrescriptionMedicalRequest(prescriptionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forwardPrescriptionMedicalRequest(id: number, body: string, options?: any): Promise<void> {
            return localVarFp.forwardPrescriptionMedicalRequest(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {boolean} [done] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrescriptionMedicalRequests(pageable: Pageable, done?: boolean, options?: any): Promise<PagePrescriptionRequestSummaryDto> {
            return localVarFp.getPrescriptionMedicalRequests(pageable, done, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrescriptionMedicalRequestsDetails(id: number, options?: any): Promise<PrescriptionRequestDetailsDto> {
            return localVarFp.getPrescriptionMedicalRequestsDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrescriptionVisitDetails(visitId: number, options?: any): Promise<PrescriptionRequestDetailsDto> {
            return localVarFp.getPrescriptionVisitDetails(visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PrescriptionRejectionDto} prescriptionRejectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectPrescriptionMedicalRequest(id: number, prescriptionRejectionDto: PrescriptionRejectionDto, options?: any): Promise<void> {
            return localVarFp.rejectPrescriptionMedicalRequest(id, prescriptionRejectionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MedicalRequestControllerApi - object-oriented interface
 * @export
 * @class MedicalRequestControllerApi
 * @extends {BaseAPI}
 */
export class MedicalRequestControllerApi extends BaseAPI {
    /**
     * 
     * @param {PrescriptionRequestDto} prescriptionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalRequestControllerApi
     */
    public addNewPrescriptionMedicalRequest(prescriptionRequestDto: PrescriptionRequestDto, options?: AxiosRequestConfig) {
        return MedicalRequestControllerApiFp(this.configuration).addNewPrescriptionMedicalRequest(prescriptionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalRequestControllerApi
     */
    public forwardPrescriptionMedicalRequest(id: number, body: string, options?: AxiosRequestConfig) {
        return MedicalRequestControllerApiFp(this.configuration).forwardPrescriptionMedicalRequest(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {boolean} [done] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalRequestControllerApi
     */
    public getPrescriptionMedicalRequests(pageable: Pageable, done?: boolean, options?: AxiosRequestConfig) {
        return MedicalRequestControllerApiFp(this.configuration).getPrescriptionMedicalRequests(pageable, done, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalRequestControllerApi
     */
    public getPrescriptionMedicalRequestsDetails(id: number, options?: AxiosRequestConfig) {
        return MedicalRequestControllerApiFp(this.configuration).getPrescriptionMedicalRequestsDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalRequestControllerApi
     */
    public getPrescriptionVisitDetails(visitId: number, options?: AxiosRequestConfig) {
        return MedicalRequestControllerApiFp(this.configuration).getPrescriptionVisitDetails(visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PrescriptionRejectionDto} prescriptionRejectionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalRequestControllerApi
     */
    public rejectPrescriptionMedicalRequest(id: number, prescriptionRejectionDto: PrescriptionRejectionDto, options?: AxiosRequestConfig) {
        return MedicalRequestControllerApiFp(this.configuration).rejectPrescriptionMedicalRequest(id, prescriptionRejectionDto, options).then((request) => request(this.axios, this.basePath));
    }
}
