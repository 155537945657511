/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AddressDto } from './address-dto';
import { DrugStockStateDto } from './drug-stock-state-dto';
import { DrugTherapyDto } from './drug-therapy-dto';
import { PatientPrescriptionDto } from './patient-prescription-dto';
import { UserAccountDto } from './user-account-dto';

/**
 * 
 * @export
 * @interface PrescriptionRequestDetailsDto
 */
export interface PrescriptionRequestDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRequestDetailsDto
     */
    'content'?: string;
    /**
     * 
     * @type {Array<DrugStockStateDto>}
     * @memberof PrescriptionRequestDetailsDto
     */
    'drugStocks'?: Array<DrugStockStateDto>;
    /**
     * 
     * @type {Array<DrugTherapyDto>}
     * @memberof PrescriptionRequestDetailsDto
     */
    'drugTherapies'?: Array<DrugTherapyDto>;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRequestDetailsDto
     */
    'dueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRequestDetailsDto
     */
    'entryDate'?: string;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof PrescriptionRequestDetailsDto
     */
    'fromUser'?: UserAccountDto;
    /**
     * 
     * @type {AddressDto}
     * @memberof PrescriptionRequestDetailsDto
     */
    'fromUserAddress'?: AddressDto;
    /**
     * 
     * @type {PatientPrescriptionDto}
     * @memberof PrescriptionRequestDetailsDto
     */
    'lastPrescription'?: PatientPrescriptionDto;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRequestDetailsDto
     */
    'outcomeDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRequestDetailsDto
     */
    'outcomeReason'?: string;
    /**
     * 
     * @type {number}
     * @memberof PrescriptionRequestDetailsDto
     */
    'patientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRequestDetailsDto
     */
    'patientPublicId'?: string;
    /**
     * 
     * @type {number}
     * @memberof PrescriptionRequestDetailsDto
     */
    'requestId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRequestDetailsDto
     */
    'status'?: PrescriptionRequestDetailsDtoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PrescriptionRequestDetailsDto
     */
    'visitId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PrescriptionRequestDetailsDtoStatusEnum {
    New = 'NEW',
    Accepted = 'ACCEPTED',
    Done = 'DONE',
    Rejected = 'REJECTED',
    Cancelled = 'CANCELLED',
    Forwarded = 'FORWARDED'
}


