import Enums from "./enums/enums";
import ServerErrors from "./errors/server-errors";

export default {
    translations: {

        /******** vuetify component translations *********/

        $vuetify: {
            noDataText: 'Brak danych',
            dataFooter: {
                itemsPerPageAll: 'Pokaż wszystkie',
                pageText: 'Nawiguj:'
            },
            dataTable: {
                sortBy: 'Sortuj po',
                ariaLabel: {
                    sortNone: 'sortNone'
                },
                itemsPerPageText: 'Na stronę:',
            },
            timepicker: {
                am: 'am',
                pm: 'pm',
            },
            dataIterator: {
                loadingText: 'Ładuję dane'
            },
            badge: 'Znak',
            datePicker: {
                prevMonthAriaLabel: 'Poprzedni miesiąc',
                nextMonthAriaLabel: 'Następny miesiąc'
            }
        },


        /******** translation-by-component *******/ //TODO - czy moze byc tak?

        view: {
            login: {
                login: {
                    loginTitle: 'Zaloguj się i umów wizytę',
                    registerLinkMsg: 'Zarejestruj się',
                    loginBtnLbl: 'Zaloguj się do Neurosphera',
                    resetPassMsg: 'Zapomniałeś hasła?',
                    passwordFieldLabel: 'Hasło',
                    loginEmailLabel: 'E-mail',
                    registerMsg: 'Nie masz jeszcze konta?',
                },
                reset: {
                    title: 'Zapomniałeś hasła?',
                    subTitle: 'Na podany niżej adres wyślemy e-mail z linkiem do ustawienia nowego hasła',
                    submittedTitle: 'Ustaw nowe hasło!',
                    content1: 'Wysłaliśmy link do zmiany hasła na adres:',
                    content2: 'Sprawdź skrzynkę e-mail i kliknij w link do zmiany hasła',
                    form: {
                        email: 'Adres e-mail',
                        send: 'Zatwierdź'
                    },
                    changePassword: 'Zmień hasło',
                    recoverPassword: 'Odzyskaj hasło',
                    response1: 'Na podany adres email został wysłany link do zmiany hasła.',
                    response2: 'Prosimy o zamknięcie tego okna przeglądarki i kliknięcie w link otrzymany pocztą elektroniczną',
                    sendAgainPrefix: "Nie otrzymałeś maila:",
                    sendAgain: "Wyślij ponownie!",
                    contactReception: "lub skontaktuj się z recepcją:",
                    resendBlocked: "W przypadku nieotrzymania wiadomości z linkiem do zmiany hasła prosimy o kontakt z biurem obsługi klientów.",
                    resendMessage: 'Link do zmiany hasła został wysłany ponownie'
                },
                resetConfirm: {
                    title: 'Ustaw nowe hasło!',
                    form: {
                        password: 'Nowe hasło',
                        passwordConfirm: 'Powtórz hasło',
                        send: 'Zatwierdź'
                    },
                    response: 'Hasło zostało zmienione.',
                    loginBtnLbl: 'Zaloguj się'
                },
                register: {
                    title:"Witaj w Neuroterminalu",
                    subtitle1:"Załóż konto i umów wizytę",
                    firstName: "Imię",
                    lastName: "Nazwisko",
                    email: "Adres e-mail",
                    password: "Hasło",
                    passwordConfirm: "Potwierdź hasło",
                    selectAll: "Zaznacz wszystkie",
                    isApprove1: 'Zapoznałem się z zasadami przetwarzania moich danych osobowych określonymi w',
                    isApprove2: 'Polityce prywatności Neurosphera sp. z o.o.',
                    isApprove3: 'oraz',
                    isApprove4: 'Polityce prywatności Neuroterminal S.A.',
                    maptitle:"Jak działa Neuroterminal?",
                    mapSubtitle:"Zobacz jakie to proste",
                    passwordRequirement: {
                        length: "min. 8 znaków",
                        upperCase: "wielka litera",
                        lowerCase: "mała litera",
                        digit: "cyfra",
                        sign: "znak specjalny ( ! @ $ \\ - _ . * )"
                    },
                    cards:[
                        "Wypełnij formularz rejestracyjny, aby założyć konto w aplikacji Neuroterminal.",
                        "Odbierz maila z linkiem aktywacyjnym, aby potwierdzić zgodność danych.",
                        "Zaloguj się do aplikacji Neuroterminal i uzupełnij informacje w Profilu Pacjenta",
                        "Wybierz lekarza, termin i umów się na swoją pierwszą wizytę (wizyta ZERO).",
                        "Opłacisz ją bezpiecznie dzięki usłudze PayU (przelewem, blikiem lub kartą)",
                        "Dla Twojej wygody możemy przypomnieć Ci o nadchodzącej wizycie wysyłając SMS.",
                        "Każdą kolejną wizytę, bez względu na to, czy online, czy stacjonarną, umówisz bezpośrednio przez Neuroterminal."
                    ],
                    resendMessage: 'Link aktywacyjny został wysłany ponownie'
                },
                confirm: {
                    registerSuccessMsg: 'Rejestracja zakończona sukcesem',
                    passwordChangeSuccessMsg: 'Hasło zostało zmienione',
                    goToLoginMsg: 'Kliknij na przycisk poniżej, aby przejść na stronę logowania do Neuroterminala, portalu pacjenta Neurosphery',
                    outcomeFailureMsg: 'Wystąpił problem podczas próby potwierdzenia rejestracji',
                    outcomeFailureDetMsg: 'Prosimy spróbować jeszcze raz kliknąć w link przesłany w wiadomości email lub skontaktować się z zespołem Neuroterminal',
                    gotTologinBtn: 'Wróć na stronę logowania',
                    passwordChangeFailedMsg: 'Link do ustawienia hasła wygasł'
                }
            },

            admin: {
                settings: {
                    ewusPasswordChanged: 'Hasło EWUŚ zostało zmienione',
                    save: 'Zapisz',
                    ewusPasswordTitle: 'Zmiana hasła EWUŚ',
                    ewusPasswordLabel: 'Nowe hasło EWUŚ',
                    removeAccount: 'Usuwanie konta',
                    registrationDate: 'Data rejestracji konta',
                    registrationSource: 'Konto zarejestrowane przez',
                    registeredBy: {
                        PATIENT: "Pacjenta",
                        DOCPLANNER: "Znany lekarz",
                        APPOINTING_WIDGET: "Widget"
                    },
                    deleteAccount: "Usuń konto",
                    accountNotForDeletion: "To konto nie może zostać usunięte",
                    accountDeletionConfirmation: "Czy na pewno chcesz usunąć konto?",
                    yes: "Tak",
                    no: "Nie",
                    accountRemovedMsg: "Konto pacjenta zostało usunięte"
                },
                notes: {
                    title: 'Notatki',
                    current: 'Bieżące',
                    archive: 'Archiwum',
                    mine: 'Moje',
                    patients: 'Pacjenci'
                },
                noteDetails: {
                    added: "Data dodania",
                    patient: "Pacjent",
                    pesel: "Pesel",
                    address: "Adres",
                    phone: "Nr telefonu",
                    content: "Treść notatki",
                    sharedBy: "Udostępniona przez",
                    moveToArchive: "Przenieś do archiwum",
                    save: "Zapisz",
                    reply: "Odpowiedz",
                    initialNote: "Notatka lekarza",
                    yes: "Tak",
                    no: "Nie",
                    addedFiles: "Dodane pliki"
                },
                reassignment: {
                    noReplacementAvailable: "Brak alternatywnego lekarza w tej samej cenie",
                    backToVisitToMoveTable: "Wróć do listy wizyt wymagających przepisania",
                    visitNotFoundRedirection: "Nie znaleziono wizyty, powrócisz do strony głównej",
                    reassignmentSummary: "Podsumowanie",
                    visitNotToMove: "Wizyta nie może zostać przeniesiona, ponieważ jej stan został już zaktualizowany.",
                    title: "Zmiana lekarza",
                    currentVisitDate: "Wizyta z dnia",
                    currentVisitHeader: "Zmiana dla wizyty",
                    currentVisitFromPackageHeader: "Zmiana dla wizyty z pakietu",
                    additionalInfo: "Dodatkowe informacje",
                    upcomingVisit: "Następna wizyta pacjenta",
                    patient: "Pacjent",
                    doctor: "Lekarz",
                    cannotReassignDueToRelatedVisit: "Wizyta nie może zostać przepisana z powodu zależności od innej wizyty, która już się odbyła"
                },
                patients: {
                    selectPatient: "Wybierz pacjenta",
                    name: "Imię nazwisko",
                    email: "Email",
                    pesel: "Pesel"
                },
                certificates: {
                    issuingDoctor: "Lekarz wystawiający zaświadczenie",
                    changeLbl: "Zmień",
                    selectDoctorLbl: "Wybierz lekarza",
                    hide: "Ukryj"
                },
                doctor: {
                    collectiveWorkPlan: {
                        title: 'Plany pracy lekarzy',
                        none: 'Brak planów pracy',
                        noneForSelectedDay: 'Brak planów pracy na wybrany dzień',
                        header: 'Plany pracy wybranych lekarzy na dany dzień',
                    }
                }
            },
            visits: {
                DoctorVisits: {
                    titleLbl: 'Moje wizyty',
                    returnLbl: 'powrót',
                    noDataLbl: 'Brak wizyt',
                    noResultsLbl: 'Nie znaleziono wizyt',
                    filters: {
                        visitTime: {
                            all: 'Wszystkie',
                            future: 'Przyszłe',
                            past: 'Przeszłe',
                        },
                        searchPatientLbl: 'Szukaj pacjenta',
                        advancedFiltersLbl: 'Wyszukiwanie zaawansowane',
                        advancedFilters: {
                            from: 'Data początkowa',
                            betweenDatesLbl: 'do',
                            till: 'Data końcowa',
                            type: 'Rodzaj wizyty',
                            typeAll: 'Wszystkie',
                            clearBtn: 'wyczyść',
                            searchBtn: 'szukaj',
                        }
                    },
                    table: {
                        headers: {
                            state: 'Stan',
                            lastName: 'Nazwisko',
                            firstName: 'Imię',
                            pesel: 'PESEL',
                            age: 'Wiek',
                            visitType: 'Rodzaj wizyty',
                            visitCategory: 'Kategoria',
                            visitStatus: 'Status',
                            dateFrom: 'Data wizyty',
                            sinceLast: 'Od poprzedniej'
                        },
                        actions: {
                            summary: 'Podsumowanie',
                            start: 'Wizyta'
                        }
                    }
                }
            },
            patients: {
                DoctorPatients: {
                    titleLbl: 'Pacjenci',
                    returnLbl: 'powrót',
                    noDataLbl: 'Brak pacjentów',
                    noResultsLbl: 'Nie znaleziono pacjentów',
                    filters: {
                        tabs: {
                            personal: 'Dane osobowe',
                            address: 'Dane adresowe',
                            biomedic: 'Dane biomedyczne',
                            medic: 'Dane medyczne'
                        },
                        scope: {
                            all: 'Pacjenci Neurosphery',
                            my: 'Moi pacjenci'
                        },
                        search: 'Szukaj',
                        advancedFiltersLbl: 'Wyszukiwanie zaawansowane',
                        from: 'od',
                        birthDate: 'Data urodzenia',
                        till: 'do',
                        clearBtn: 'wyczyść',
                        searchBtn: 'szukaj'
                    },
                    table: {
                        headers: {
                            lastName: 'Nazwisko',
                            firstName: 'Imię',
                            medicalHistory: 'Historia choroby',
                            pesel: 'PESEL',
                            email: 'Email',
                            age: 'Wiek',
                            sex: 'Płeć',
                            leadDoctor: 'Lekarz prowadzący',
                            personalData: 'Dane osobowe',
                            phone: 'Numer telefonu',
                            city: 'Miejscowość',
                            zipCode: 'Kod pocztowy',
                            country: 'Kraj',
                            bloodType: 'Grupa krwi',
                            rh: 'Rh',
                            weight: 'Waga',
                            height: 'Wzrost',
                            hasEpilepsy: 'Padaczka',
                            mainIcd10: 'Rozpoznanie główne',
                            comorbidities: 'Choroby współistniejące',
                            vns: 'Wszczepione VNS',
                            activeSubstance: 'Przyjmowana substancja aktywna',
                            product: 'Przyjmowany lek',
                            therapyType: 'Rodzaj terapii'
                        }
                    }
                }
            },
            leadDoctor: {
                noDoctor: 'Pacjent nie ma przypisanego lekarza opiekuna',
                change: 'Zmień',
                choose: 'Wybierz lekarza',
                dialogTitle: 'Wybierz lekarza opiekuna dla pacjenta',
                genericError: 'Wystąpił błąd podczas ładowania listy lekarzy',
                assign: 'Przypisz lekarza',
                patientsNumber: 'Pacjentów pod opieką',
                assignNoteTitle: "Wybrałeś lekarza opiekuna {name} pacjentowi. Wybrany lekarz dostanie notatkę o treści",
                assignNoteContent: "Lekarz {name} przypisał Ci pacjenta pod opiekę. Zapoznaj się z historią choroby pacjenta.",
                assignNoteComment: "Jeśli chcesz dodaj opcjonalnie dodatkową wiadomość wybranemu lekarzowi. Jeśli dodasz opcjonalną wiadomość, notatka zostanie udostępniona również recepcji",
                assignNoteDialogTitle: "Notatka do wybranego lekarza"
            },
            visit: {
                Visit: {
                    titleLbl: 'Wizyta',
                    returnLbl: 'powrót',
                    printLbl: 'drukuj',
                    survey: 'Ankieta zero',
                    documentation: 'Dokumentacja wizyty',
                    visitDocumentation: 'Dokumentacja z wizyty',
                    calendar: 'Kalendarz zdarzeń',
                    epicrisis: 'Epikryza',
                    eDiagnosisBtn: 'e-diagnoza',
                    eTherapyBtn: 'e-terapia',
                    medHistoryBtn: 'historia choroby',
                    messHistoryBtn: 'Wiadomości',
                    notesBtn: 'notatki',
                    observations: 'Obserwacje',
                    additionalObservations: 'Dodatkowe obserwacje',
                    noteZeroObservations: 'Obserwacje z notatki 0',
                    editNoteZeroObservations: 'Edytuj obserwacje z notatki 0',
                    saveObservationLbl: 'Zapisz obserwacje z notatki 0',
                    notMandatoryLbl: 'pole nieobowiązkowe',
                    observationsPlaceholder: 'Obserwacje uzupełniają się automatycznie podczas wypełniania sekcji wizyty',
                    additionalObservationsPlaceholder: 'Wpisz dodatkowe obserwacje',
                    addObservationLbl: 'Zapisz obserwacje',
                    diagnosis: 'rozpoznanie (choroby współistniejące)',
                    seizureDiagnosis: 'rozpoznanie (napady padaczkowe)',
                    medication: 'leczenie',
                    recommendations: 'zalecenia',
                    prescriptionBtn: 'recepta',
                    certificateBtn: 'zaświadczenie',
                    referralBtn: 'skierowanie',
                    changeTherapyBtn: 'zmień terapie',
                    cancel: 'anuluj',
                    save: 'zapisz',
                    end: 'zakończ',
                    downloadTestRecommendations: 'Pobierz zalecenia próbne',
                    leadDoctorHeader: 'Lekarz opiekun',
                    visitLeaveConfirmMsg: 'Czy na pewno opuścić wizytę?',
                    visitSaveConfirmMsg: 'Czy na pewno zapisać i opuścić wizytę?',
                    visitEndConfirmMsg: 'Czy na pewno zakończyć wizytę? Po jej zakończeniu nie będzie już możliwe wprowadzenie do niej zmian.',
                    dataSavedMsg: 'Zapisano dane wizyty',
                    visitEndedMsg: 'Zakończono wizytę',
                    dataSaveErrorMsg: 'Błąd podczas zapisywania danych wizyty',
                    messageHistoryEmpty: 'Historia wiadomości z tym pacjentem jest pusta',
                    drugsImported: 'Terapie lekowe zostały zainicjalizowane',
                    drugTherapiesInEditionMode: 'Terapie lekowe są w trybie edycji. Nie można zapisać wizyty.',
                    emptyRecommendations: 'Zalecenia nie zostały wprowadzone. Nie można zakończyć wizyty.',
                    absentConsultationInfo: 'Konsultacja zaoczna odbywa się bez udziału pacjenta',
                    mainDoctor: 'Lekarz prowadzący',
                    supervisors: 'Lekarze obserwujący',
                    absentConsultationNote: {
                        header: "Notatka na Konsultację zaoczną",
                        whatYouNeedLbl: "Napisz czego potrzebujesz",
                        timeToEditLbl: "Uwaga! Notatkę można edytować do",
                        save: "Zapisz notatkę",
                        forDoctorLbl: "Do lekarza",
                        validationError: "Uzupełnij pole",
                        notAccessible: "Konsultacja niedostępna",
                        saved: "Notatka została zapisana"
                    },
                    referrals: {
                        title: 'skierowania',
                        removeDialogTitle: 'Czy na pewno chcesz usunąć to skierowanie?',
                        addReferral: 'Skierowanie',
                        addDialogTitle: 'Nowe skierowanie',
                        noAvailableServices: 'Nie znaleziono dostępnych typów konsultacji dla tego pacjenta',
                        consultationService: 'Typ konsultacji',
                        doctor: 'Lekarz',
                        description: 'Opis',
                        cancel: 'Anuluj',
                        empty: 'Podczas wizyty nie wystawiono skierowania',
                        scheduled1Month: '1 miesiąc',
                        scheduled2Months: '2 miesiące',
                        scheduled3Months: '3 miesiące',
                        scheduled6Months: '6 miesięcy',
                        scheduled1Year: '1 rok',
                        scheduled1month: '1 miesiąc',
                        scheduled2month: '2 miesiące',
                        scheduled3month: '3 miesiące',
                        scheduled6month: '6 miesięcy',
                        scheduled12month: '1 rok',
                        scheduled14day: '14 dni',
                        scheduled30day: '30 dni',
                        scheduledDate: 'Planowana data',
                        doctorPackage: 'Pakiet lekarzy',
                        doctorPackageQuestion: 'Skierowanie na pakiet lekarzy?',
                        availableFree: {
                            title: 'Nie wystawiłeś skierowań na darmowe wizyty',
                            subtitle: 'Po tej wizycie pacjentowi przysługuje 1 darmowa wizyta na jedną z usług',
                            subtitle2: 'Wróć do planu opieki i wystaw odpowiednie skierowanie!',
                            back: 'Wróc do wizyty by wystawić skierowanie',
                            endWithoutReferral: 'Zakończ bez skierowania',
                            visitDescription: 'Po tej wizycie pacjentowi przysługuje darmowa wizyta na wybrane usługi',
                            visitAddTitle: 'skierowanie na darmową wizytę'
                        }
                    },
                    prescriptions: {
                        title: 'recepty',
                        addPrescription: 'Recepta',
                        empty: 'Podczas wizyty nie wystawiono recepty'
                    },
                    certificates: {
                        title: 'zaświadczenia',
                        addCertificate: 'Zaświadczenie',
                        empty: 'Podczas wizyty nie wystawiono zaświadczenia'
                    },
                    ended: {
                        titleLbl: 'Wizyta online zakończyła się',
                        questionMsg: 'Czy zakończyć wizytę i powrócić na ekran główny?',
                        confirmationMsg: 'Dziękujemy za udział w wizycie'
                    },
                    saving: {
                        warningMsg: 'Wizyta zapisze się automatycznie za {no} minut',
                        autoSaveMsg: 'Wizyta została automatycznie zapisana. Proszę o niezwłoczne zakończenie wizyty'
                    },
                    icd10: {
                        title: 'rozpoznanie'
                    },
                    leaveDialog: {
                        leave: 'Wyjdź bez zapisania',
                        saveAndLeave: 'Zapisz i wyjdź',
                        endAndLeave: 'Zakończ i wyjdź'
                    },
                    documentationTabs: {
                        visitSurvey: 'Ankieta z wizyty',
                        therapyArrangement: 'Ustalenie terapii',
                        healthCare: 'Plan opieki',
                        recommendations: 'Zalecenia z wizyty',
                        certificates: 'Zaświadczenia',
                        diagnosis: 'Diagnoza',
                        carePlan: {
                            addNext: 'Dodaj kolejne',
                            remove: 'Usuń',
                            loading: 'Wczytywanie danych...',
                            referralListEmpty: 'Lista skierowań jest pusta',
                            appoint: 'umów',
                            skip: 'nie umawiaj',
                            referralsSaved: 'Skierowania zostały zapisane',
                            message: {
                                name: 'Wiadomość do lekarza',
                                title: 'wizyta wiadomość - umawianie',
                                date: 'Data wizyty'
                            },
                            epilepsy: {
                                name: 'Wizyta padaczkowa',
                                title: 'kolejna wizyta padaczkowa - umawianie',
                                date: 'Data wizyty'
                            },
                            eeg: {
                                name: 'Badanie EEG',
                                title: 'badanie EEG - umawianie',
                                date: 'Data badania',
                                service: 'Rodzaj badania'
                            },
                            consultation: {
                                name: 'Konsultacja',
                                title: 'konsultacja NSPH - umawianie',
                                date: 'Data konsultacji',
                                service: 'Typ konsultacji'
                            },
                            other: {
                                name: 'Inne',
                                title: 'Skierowania zewnętrzne',
                                emptyList: 'Lista wystawionych skierowań zewnętrznych na tej wizycie jest pusta',
                                addNextEReferral: 'Dodaj e-skierowanie',
                                isEReferral: 'E-skierowanie',
                                referralCode: 'Kod skierowania',
                                noDataLbl: 'Brak skierowań'
                            },
                            followUp: {
                                name: "Wizyta kontrolna",
                                title: "Wizyta kontrolna - umawianie",
                                date: 'Data wizyty'
                            }
                        }
                    },
                    recommendationTemplate: {
                        title: 'szablon zaleceń',
                        examinationNames: 'Nazwy badań',
                        template: {
                            therapy: 'Leki według schematu w terapii lekowej oraz na koncie pacjenta w systemie Neuroterminal w zakładce terapia',
                            urgentContact: 'W razie konieczności pilnego kontaktu z lekarzem lub wystąpienia nieprzewidzianych okoliczności proszę dzwonić do recepcji +48 609 463 191',
                            noTherapy: 'Bez leczenia farmakologicznego',
                            calendar: 'Proszę prowadzić kalendarz zdarzeń na portalu pacjenta w systemie Neuroterminal',
                            headMr: 'Proszę o wykonanie badania MR głowy',
                            medicalExaminations: 'Proszę o wykonanie dodatkowych badań i załączenie wyników do dokumentacji medycznej w systemie Neuroterminal',
                        }
                    }
                },
                ictal: {
                    diagnosis: {
                        title: 'Szanowna Pani, Szanowny Panie,',
                        subtitle1: 'Pamiętaj, że nasz',
                        subtitle2: 'AI robot – Ictal®',
                        subtitle3: '- jest co prawda innowacyjnym narzędziem, które ci pomaga i stale uczy się pomagać coraz bardziej, jednak',
                        subtitle4: 'nie jest ono wyrobem medycznym i nie może być wykorzystywane do oceny prawdziwych stanów klinicznych Twoich pacjentów.',
                        subtitle5: 'Cała biblioteka ontologii padaczki została opracowana tak, by wiernie rozpoznać typ napadu padaczkowego zgodnie z najnowszą klasyfikacją MLPP, więc narzędzie może być wykorzystywane do oceny hipotetycznych stanów klinicznych w celach edukacyjnych lub treningowych.',
                        suggestedDiagnoseMsg: 'Potencjalne rozpoznanie dla',
                        fillSurveyMsg: 'Nie zdefiniowano jeszcze napadów. Należy wypełnić ankietę zero.',
                        ictalDiagnose: 'Diagnoza ictall',
                        ictalDiagnoseOneMoreTime: 'Diagnozuj ponownie',
                        patientSeizureType: 'Wybrany typ napadu (typy zdefiniował pacjent)'
                    },
                    outcome: {
                        approveLbl: 'Zgadzam się',
                        rejectLbl: 'Nie zgadzam się',
                        saveLbl: 'Zapisz diagnozę',
                        fixCritical: 'Popraw zmiany krytyczne',
                        reasonLbl: 'Powód wybrania tej diagnozy',
                        selectDiagnosisMsg: 'Wybierz diagnozę',
                        calcDiagnosisMsg: 'Ustalanie diagnozy..',
                        otherDiagnosis1Lbl: 'Inna',
                        otherDiagnosis2Lbl: 'diagnoza',
                        updatedByLbl: 'Zgodnie z diagnozą'
                    },
                    therapy: {
                        title: 'Szanowna Pani, Szanowny Panie,',
                        subtitle1: 'Pamiętaj, że nasz',
                        subtitle2: 'AI robot – Ictal®',
                        subtitle3: '- jest co prawda innowacyjnym narzędziem, które ci pomaga i stale uczy się pomagać coraz bardziej, jednak',
                        subtitle4: 'nie jest ono wyrobem medycznym i nie może być wykorzystywane do oceny prawdziwych stanów klinicznych Twoich pacjentów.',
                        subtitle5: 'Cały proces terapii padaczki został opracowany przez zespół ekspertów oraz w oparciu o wytyczne czołowych Medycznych Towarzystw Naukowych zajmujących się padaczką,więc narzędzie może być wykorzystywane do oceny hipotetycznych stanów klinicznych w celach edukacyjnych lub treningowych.',
                        ictalCheck: 'Sprawdź',
                        seizureType: 'Typ napadu',
                        proposal: 'Możliwe opcje terapii',
                        type: {
                            0: 'Propozycja ICTAL',
                            1: 'I Monoterapia',
                            2: 'II Monoterapia',
                            3: 'III Monoterapia',
                            4: 'I Politerapia',
                            5: 'II Politerapia',
                            6: 'III Politerapia',
                            7: 'Politerapia Dalsza'

                        },
                        drug: {
                            description: {
                                lastUsed: 'Ostatnio stosowany:',
                                color: {
                                    undefined: 'Lek nie był wcześniej stosowany.',
                                    orange: 'Lek był wcześniej stosowany: brak efektu/słaby efekt.',
                                    red: 'Lek był wcześniej stosowany: efekt negatywny, stan pacjenta się pogorszył.',
                                    green: 'Lek był wcześniej stosowany: efekt zadowalający.'
                                }
                            }
                        },
                        filter: {
                            availableInPL: 'Lek dostępny w Polsce',
                            refundedInPL: 'Lek refundowany w Polsce',
                            reproductiveAgeOptional: 'Kobieta w wieku rozrodczym'
                        },
                        notFound: 'ICTAL nie zdołał dopasować terapii dla pacjenta.',
                        apply: 'Zastosuj terapię'
                    }
                },
                doctorNotes: {
                    title: 'Notatki',
                    subtitle: 'To miejsce, w którym możesz zarządzać swoimi prywatnymi notatkami na temat pacjenta',
                    addNote: 'Dodaj notatkę',
                    save: 'Zapisz zmiany',
                    saveSuccess: 'Notatka została zapisana',
                    modified: 'Ostatnia modyfikacja',
                    editCancel: 'Odrzuć zmiany',
                    edit: 'Edytuj notatkę',
                    delete: 'Usuń notatkę',
                    askDelete: 'Czy na pewno usunąć tą notatkę?',
                    deleteSuccess: 'Notatka została usunięta',
                    publicVisible: 'Widoczna dla wszystkich lekarzy',
                    limitedShare: 'Widoczna dla wybranych lekarzy',
                    sharedBy: 'Udostępniona przez',
                    doctor: 'Lekarz',
                    forAdmin: 'Widoczna dla admina',
                    limitedShareLbl: 'Udostępiona dla',
                    repliedTo: 'W odpowiedzi na',
                    sharedByPatient: "Udostępniona przez pacjenta do wizyty zaocznej",
                    absentConsultation: "Wizyta zaoczna",
                    addFiles: "Dodaj plik"
                },
                diagnosis: {
                    confirmTitle: 'Zmiana rozponania',
                    confirmText: 'Czy na pewno chcesz zmienić rozpoznanie?',
                    updatedMsg: 'Zauktualizowano diagnoze dla danego typu napadów'
                },
                patientMessageHistory: {
                    title: 'Historia wiadomości pacjenta',
                    emptyList: 'Lista wiadomości pacjenta jest pusta'
                }
            },
            landing: {
                common: {
                    menu: {
                        observe: 'Obserwuj nas',
                        link: {
                            database: 'NT - Baza wiedzy',
                            doctors: 'Dla lekarzy',
                            contact: 'Kontakt',
                        },
                        terms: {
                            title: 'Regulaminy',
                            z1: 'Regulamin organizacyjny',
                            z2: 'Regulamin świadczenia usług drogą elektroniczną',
                            z3: 'Polityka prywatności Neurosphera',
                            z4: 'Polityka prywatności Neuroterminal',
                            payuInstallments: 'Raty PayU - FAQ'
                        }
                    },
                    contact: {
                        dataTitle: 'Dane kontaktowe',
                        formTitle: 'Formularz kontaktowy',
                        form: {
                            email: 'E-mail',
                            phone: 'Telefon',
                            message: 'Wiadomość',
                            send: 'Wyślij wiadomość',
                            approveDataProcessing: 'Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych w celu obsługi zgłoszenia przez Neuroterminal S.A. Szczegółowe informacje o zasadach ochrony danych osobowych i przysługujących prawach znajdują się w',
                            policy: 'Polityce prywatności',
                            feedback: {
                                header: 'Dziękujemy za przesłanie wiadomości!',
                                content: 'Wkrótce skontaktujemy się z Państwem\ntelefonicznie lub przez wiadomość e-mail'
                            }
                        }
                    },
                    help: {
                        title: 'Masz pytania?'
                    }
                },
                main: {
                    title1: 'Nowoczesny system',
                    title2: 'leczenia padaczki',
                    bullet1: 'Wygodne konsultacje telemedyczne\njuż od 99 zł',
                    bullet2: 'Dostęp do najlepszych lekarzy\nepileptologów',
                    bullet3: 'Kompleksowa opieka medyczna\nbez wychodzenia z domu',
                    arrangeConsultation: 'Umów konsultację',
                    features: {
                        consultations: {
                            title: 'Konsultacje\ntelemedyczne',
                            description: 'Prowadź wizyty online\nprzez wideokonferencje'
                        },
                        emergency: {
                            title: 'Nagłe\nwypadki',
                            description: 'Całodobowy dyżur SOS\nprowadzony przez lekarzy'
                        },
                        panel: {
                            title: 'Panel\npacjenta',
                            description: 'Historia choroby,\ndokumenty medyczne i\nzalecenia i jednym\nmiejscu'
                        },
                        edocuments: {
                            title: 'Recepty,\nzwolnienia',
                            description: 'i skierowania w formie\nelektronicznej, bez\nwychodzenia z domu'
                        },
                        doctors: {
                            title: '\nLekarze',
                            description: 'Dostęp do najlepszych\nepileptologów bez kolejek'
                        },
                        quickAccess: {
                            title: 'Szybki\ndostęp',
                            description: 'Konsultacje z lekarzami\nbez kolejek'
                        }
                    },
                    subHeaderBullet1: 'Bądź w stałym kontakcie ze swoim lekarzem,\nktóry korzystając z modułu sztucznej inteligencji\nna bieżaco monitoruje skuteczność Twojego leczenia.',
                    subHeaderBullet2: 'Uzyskaj  dostęp do najlepszych neurologów w Polsce\nbez wychodzenia z domu.',
                    subHeaderBullet3: 'Dołącz bezpłatnie do Neuroterminal\ni zacznij korzystać z innowacyjnych rozwiązań\nw leczeniu padaczki!',
                    howItWorks: 'Jak to działa?',
                    steps: {
                        choose: {
                            title: 'Pacjent wybiera\nlekarza',
                            description: 'z bazy specjalistów'
                        },
                        appoint: {
                            title: 'Umawia się na\nwizytę',
                            description: 'telemedyczną w dogodnym\nterminie'
                        },
                        diagnose: {
                            title: 'Lekarz diagnozuje',
                            description: 'i dobiera terapię. Pomaga mu w\ntym system sztucznej inteligencji\niCtal'
                        },
                        documents: {
                            title: 'Dodaje dokumenty\nmedyczne',
                            description: ''
                        },
                        contact: {
                            title: 'Lekarz ma stały\nkontakt',
                            description: 'z pacjentem,\nna bieżąco monitoruje postępy\nwdrożonego leczenia'
                        }
                    },
                    opinions: 'Opinie użytkowników',
                    register: 'Załóż konto, aby umówić się na wizytę'
                },
                database: {
                    epilepsy: {
                        title: 'PADACZKA (EPILEPSJA) - NAJCZĘSTSZA CHOROBA NEUROLOGICZNA',
                        subheader1: 'Padaczka to najczęstsza choroba neurologiczna przejawiająca się jako nawracające napady padaczkowe u osób w każdym wieku bez różnicy płci, wykształcenia, rejonu geograficznego czy statusu materialnego.\nPadaczka to co najmniej dwa, niesprowokowane napady padaczkowe. Przyczyna padaczki bywa często nieznana.',
                        subheader2: 'Napad padaczkowy jest\nwyrazem napadowej,\nnagłej czynności bioelektrycznej\n(określanej jako wyładowanie)\ngrupy neuronów (komórek nerwowych w mózgu)\npowodującej kliniczny (widoczny jako objawy)\nstan zaburzeń świadomości, ruchowych, czuciowych\ni innych składających się na to określenie.'
                    },
                    seizure: {
                        title: 'Jak wygląda\nnapad padaczkowy?',
                        subheader: 'Napad padaczkowy może przybierać różne formy.\nZnamy kilkadziesiąt różnych typów napadów padaczkowych.',
                        bullet1: 'Niektóre osoby w trakcie napadu padaczkowego tracą na chwilę kontakt z otoczeniem, taki napad może być wręcz niezauważalny',
                        bullet2: 'Typowym, „literackim” napadem padaczkowym, określanym w przeszłości jako „grand mal” jest epizod z upadkiem, drgawkami całego ciała, zasinieniem i utratą kontroli zwieraczy. To skrajna, największa forma napadu padaczkowego',
                        footer: 'Klasyfikacja napadów padaczkowych i padaczek jest opisem medycznym napadów padaczkowych stworzonym przez Międzynarodową Ligę Przeciwpadaczkową. Klasyfikacja taka pozwala na jednolitą diagnostykę i podstawowe kryteria doboru terapii.'
                    },
                    factors: {
                        title: 'CZYNNIKI PROWOKUJĄCE WYSTĄPIENIE NAPADU PADACZKOWEGO',
                        subheader: 'Napad padaczkowy może wystąpić wywołany czynnikiem prowokującym, i to u osoby, które nie choruje na padaczkę. Dopiero powtarzające się, niesprowokowane mnogie napady określamy mianem padaczki.',
                        bullet1: 'Brak snu – to najsilniej prowokujący czynnik wystąpienia napadu padaczkowego',
                        bullet2: 'Odstawienie alkoholu, narkotyków i leków wpływających silnie na układ nerwowy – ten czynnik wywołuje często tzw napady abstynencyjne, które mogą przejść w utrwaloną padaczkę',
                        bullet3: 'Niska glikemia (stężenie glukozy w surowicy) i liczne inne zaburzenia metaboliczne mogą też sprowokować napad padaczkowy',
                        seizureRecognition: 'Powtarzające się samoistnie 2 lub więcej napady padaczkowe to warunek rozpoznania choroby!',
                        seizuresRelatedTo: 'PRZYCZYNY NAPADÓW PADACZKOWYCH',
                        relatedToBullet1: 'Napady padaczkowe pojawiają się często – zwane wówczas objawowymi – w przypadku organicznego (np. pourazowego) uszkodzenia mózgu',
                        relatedToBullet2: 'Napady padaczkowe mają często podłoże genetycznie uwarunkowane co bywa związane z dziedziczeniem (genetycznie uwarunkowana skłonność do padaczki przenosi się z rodzica na potomstwo)',
                        relatedToBullet3: 'U ponad połowy chorych z padaczką nie można ustalić przyczyny choroby'
                    },
                    whoCanSick: {
                        title: 'Kto może zachorować?',
                        subheader: 'Padaczka może wystąpić w każdym wieku, niezależnie od płci.',
                        bullet1: 'Najwięcej nowych przypadków padaczki widzimy\nu dzieci do 6-7 roku życia\noraz u osób starszych po 70 roku życia.',
                        bullet2: 'Padaczka to choroba występująca u 1% populacji,\nco oznacza 380.000 chorych w Polsce.'
                    },
                    treatment: {
                        title: 'JAK MOŻNA LECZYĆ PADACZKĘ?',
                        bullet1: 'Tak, padaczka to choroba, którą można skutecznie leczyć i często wyleczyć. Leki przeciwpadaczkowe doprowadzają do ustąpienia objawów u ok 75% chorych.',
                        bullet1Header: 'Leki',
                        bullet2: 'Pozostała grupa ok 25% chorych choruje na tzw. Lekooporną postać padaczki i wymaga dodatkowych metod terapii. To często zabiegi neurochirurgiczne, czy metody neurostymulacji.',
                        bullet2Header: 'Zabieg',
                        footer: 'Proces leczenia padaczki to zwykle długi i złożony plan składający się z etapów uwzględniających wiek pacjenta, jego płeć, procesy kształcenia, pracę, sytuację rodzinną, nawyki i ogólną sytuację zdrowotną. Celem terapii jest zawsze remisja, tj. pełne ustąpienie objawów (napadów padaczkowych).'
                    },
                    impact: {
                        title: 'JAK PADACZKA WPŁYWA NA ŻYCIE CODZIENNE CHOREGO?',
                        subheader: 'Padaczka to zwykle pewien kompromis dla pacjenta, choć codzienne życie z padaczką nie powoduje zbyt wiele ograniczeń czy zakazów.',
                        bullet1: 'Przy znacząco częstych napadach istnieje zawsze konieczność zachowania bezpieczeństwa – unikania groźnych następstw napadu (np upadku z wysokości, utonięcia czy oparzenia)',
                        bullet2: 'Pewnego przygotowania i omówienia wymagają nieraz relacje chorego z otoczeniem. Dobrym i sprawdzonym rozwiązaniem jest omówienie z lekarzem jak opowiedzieć o swojej padaczce w szkole, pracy czy wśród znajomych. Padaczka nie jest wstydem i piętnem, ale wiele osób się jej boi (co wynika z niewiedzy) i ten strach trzeba umiejętnie usunąć.',
                        bullet3: 'Istnieje niewiele przeciwwskazań do pracy dla osób z padaczką, jednak to ważny temat i wspólna rozmowa z lekarzem pozwoli rozwiać wszelkie wątpliwości.',
                        bullet4: 'Chorzy na padaczkę mogą korzystać z amatorskiego prawa jazdy kategorii B, lecz jednak pod pewnymi warunkami. To zawsze indywidualna decyzja.',
                        bullet5: 'Pacjentka chora na padaczkę może zajść w ciążę i mieć zdrowe dziecko! Ciąża taka jednak musi być przygotowana i zaplanowana. Istotny jest też dobór leku i sprawna współpraca z położnikiem.',
                        footer: 'Chorując na padaczkę można w dzisiejszych czasach prowadzić w miarę normalne i szczęśliwe życie. Trzeba jednak odpowiedzialnie i systematycznie współpracować z lekarzem prowadzącym.'
                    },
                    help: {
                        title: 'Neuroterminal pomoże ci\nleczyć się z padaczki',
                        bullet1Header: 'Komfort',
                        bullet1: 'System Neuroterminal pomoże ci wygodnie i zdalnie wejść pod opiekę specjalistów. Możesz lepiej i sprawniej kontaktować się ze swoim lekarzem.',
                        bullet2Header: 'Innowacyjność',
                        bullet2: 'W systemie cały czas niewidzialnie pracują najnowocześniejsze algorytmy cybernetyczne które analizują twoje zdrowie i doradzają twojemu lekarzowi co zrobić. Nigdy nie byłeś pod tak dobrą opieką!',
                        bullet3Header: 'Bezpieczeństwo',
                        bullet3: 'Monitorujemy twój stan. Możesz 7 dni w tygodniu przez 24 godziny liczyć na naszą pomoc. Specjaliści NSPH są do twojej dyspozycji. Wraz z systemem iCtal®'
                    }
                },
                doctors: {
                    title1: 'Zostań lekarzem',
                    titleIn: 'w',
                    title2: 'NEUROTERMINAL',
                    subtitle: 'Konsultuj pacjentów online\nkorzystając z najnowszych\ntechnologii.',
                    features: {
                        consultations: {
                            title: 'Konsultacje\ntelemedyczne',
                            description: 'Prowadź wizyty online\nprzez wideokonferencje\nlub czat'
                        },
                        digitalOffice: {
                            title: 'Cyfrowy\ngabinet',
                            description: 'Wszystkie wizyty i dane\nmedyczne twoich pacjentów\nw jednym miejscu'
                        },
                        eDocuments: {
                            title: '\nE-dokumenty',
                            description: 'Wystawiaj recepty,\nzwolnienia i skierowania\nonline'
                        },
                        flexibleSchedule: {
                            title: 'Elastyczny\ngrafik',
                            description: 'Planuj konsultacje w\ndogodnych dla siebie\nterminach przez\n7 dni w tygodniu'
                        },
                        system: {
                            title: 'System\nNeuroterminal',
                            description: 'Korzystaj z innowacyjnego\nsystemu sztucznej\ninteligencji w\ndiagnozowaniu pacjentów'
                        },
                        patients: {
                            title: '\nPacjenci',
                            description: 'Prowadź swoich\npacjentów i szybko zyskuj\nnowych'
                        }
                    },
                    steps: {
                        register: {
                            title: 'Zarejestruj się',
                            description: ''
                        },
                        training: {
                            title: 'Przejdź szkolenie',
                            description: 'jak korzystać z platformy\nNeuroterminal i prowadzić\nkonsultacje telemedyczne'
                        },
                        schedule: {
                            title: 'Ustal grafik\nkonsultacji',
                            description: 'dowolnie planując swoją\ndostępność'
                        },
                        pricing: {
                            title: 'Określ stawki',
                            description: 'twojego wynagrodzenia\nza konsultacje'
                        },
                        consultations: {
                            title: 'Prowadź\nkonsultacje',
                            description: 'telemedyczne online przez\nplatformę Neuroterminal za\npośrednictwem wideo lub chatu'
                        },
                        monitor: {
                            title: 'Monitoruj\npacjentów',
                            description: 'i ich postępy w leczeniu'
                        }
                    },
                    firstSteps: 'Pierwsze kroki'
                },
                patients: {
                    rating: {
                        title: 'Dziękujemy za wystawienie oceny',
                        lowScoreContent: 'Chcielibyśmy abyś napisał nam swoją opinię dzięki czemu pozwolisz nam poprawić jakość usług',
                        highScoreContent: 'Chcielibyśmy abyś podzielił się z innymi swoją opinią',
                        redirectionInfo: 'Za chwilę zostaniesz przekierowany na odpowiednią stronę',
                        redirectionCountdown: 'Przekierowanie nastąpi za'
                    }
                },
                removePatient: {
                    title: 'Potwierdź usunięcie konta',
                    subTitle1: 'Konto założone pod adresem email',
                    subTitle2: 'zostanie usunięte',
                    why: 'Dlaczego chcesz usunąć konto',
                    whyPrompt: 'Twoja odpowiedź jest bardzo ważna dla nas. Dzięki niej będziemy mogli udoskonalić nasze usługi.',
                    removeBtn: 'Usuń konto z systemu Neuroterminal',
                    accountRemoved: 'Twoje konto zostało usunięte',
                    noAccount: "Konto nie istnieje lub nie może zostać usunięte",
                    accountRemovedDescription: 'Pamiętaj że w każdym momencie możesz ponownie zarejestrować się w systemie Neuroterminal i umówić wizytę w przychodni Neurosphera'
                }
            },
            ictal: {
                lab: {
                    title: 'IctalLab',
                    subtitle: 'Wprowadź wektor lub wczytaj pacjenta, żeby rozpocząć automatyczną diagnozę.',
                    diagnose: 'Diagnozuj',
                    clear: 'Wyczyść',
                    inputVector: 'Wektor wejściowy',
                    loadPatient: 'Wybierz pacjenta',
                    changePatient: 'Zmień pacjenta',
                    selectedPatient: 'Wybrany pacjent',
                    survey: 'Ankieta',
                    diagnosis: 'Diagnoza',
                    factorsConfiguration: 'Konfiguracja współczynników',
                    sum: 'Suma',
                    therapy: 'Terapia',
                    check: 'Sprawdź',
                    selectTherapy: 'Wybierz rodzaj terapii',
                    selectedTherapy: 'Wybrany rodzaj terapii',
                    selectSeizureType: 'Wybierz rodzaje napadów',
                    selectedSeizureType: 'Wybrane rodzaje napaduów',
                    drugAvailableInPl: 'Lek dostępny w Polsce',
                    drugRefunded: 'Lek refundowany',
                    patientIsPregnant: 'Pacjent w ciąży',
                    patientWithSomaticDiseases: 'Pacjent z chorobami somatycznymi',
                    patientInOlderAge: 'Pacjent w starszym wieku',
                    patientOfChildbearingAge: 'Kobieta w wieku rozrodczym',
                    patientAge: 'Wiek pacjenta',
                    recommendedTherapy: 'Zalecana terapia',
                    errors: {
                        factorsError: 'Suma wartości współczynników powinna wynosić 100',
                        vectorLengthError: 'Wektor wejściowy jest nieprawidłowej długości',
                        vectorInvalidError: 'Wektor wejściowy jest nieprawidłowy',
                    },
                    factors: {
                        nn: 'NN',
                        knn: 'KNN',
                        jacc: 'Jaccard',
                        jaro: 'Jaro',
                        sodi: 'Sørensen–Dice',
                        over: 'Overlap',
                        tver: 'Tversky: α=1, β=1'
                    },
                    surveyHeaders: {
                        name: 'Pytanie',
                        always: 'Zawsze',
                        veryOften: 'Bardzo często',
                        often: 'Często',
                        sometimes: 'Czasem',
                        rarely: 'Rzadko',
                        veryRarely: 'Bardzo rzadko',
                        never: 'Nigdy',

                        veryLong: 'Bardzo długo',
                        long: 'Długo',
                        bitLong: 'Umiarkowanie długo',
                        variable: 'Umiarkowanie',
                        short: 'Krótko',
                        veryShort: 'Umiarkowanie krótko',
                        ultraShort: 'Bardzo krótko',
                    },
                    seizuresTableHeaders: {
                        seizureType: 'Typ napadu',
                        probability: 'Prawdopodobieństwo [ % ]'
                    },
                    selectUserDialog: {
                        title: 'Wybierz pacjenta',
                        noPatientsFound: 'Brak pacjentów z uzupełnioną ankietą napadów'
                    },
                    configuration: {
                        subtitle: 'Konfiguracja',
                        drugCatalog: {
                            title: 'Katalog leków',
                            add: 'Dodaj nowy lek',
                        },
                        factors: {
                            save: 'Zapisz współczynniki',
                            coefModifiedMsg: 'Zapisano współczynniki'
                        }
                    },
                    drugs: {
                        deleteConfirmationDialogText: "Usunięcie leku spowoduje również usunięcie danych treningowych silnika Ictal. Wszystkie dane powiązane z tym lekiem zostaną usunięte. Czy chcesz kontynuować?",
                        updateDrugDialogTitle: "Aktualizacja leku",
                        createDrugDialogTitle: "Dodawanie leku",
                        save: "Zapisz",
                        cancel: "Anuluj",
                        validation: {
                            minAgeRequired: "Wiek od jest wymagany",
                            minAgeRange: "Wiek od musi zawierać się między 0 i 120",
                            weightRequired: "Waga jest wymagana",
                            weightRange: "Waga musi zawierać się miedzy -3 i 3"
                        },
                        legend: {
                            title: "Leki - legenda",
                            column: "Rubryka",
                            value: "Wartość",
                            thrAbove: "Spełniona granica wieku; wiek powyżej ustalonej granicy",
                            thrBelow: "Niespełniona granica wieku; wiek poniżej ustalonej granicy",
                            drugAvailable: "Lek dostępny w PL",
                            drugNotAvailable: "Lek niedostępny w PL",
                            drugRefunded: "Lek refundowany",
                            drugNotRefunded: "Lek nierefundowany",
                            usageForbidden: "Zakazany",
                            usageVeryRare: "Bardzo rzadko stosowany",
                            usageRare: "Rzadko stosowany",
                            usageAmbivalent: "Obojętny",
                            usageSometimes: "Czasami stosowany",
                            usageOften: "Często stosowany",
                            usageRecommended: "Polecany"
                        }
                    },
                    usedDrugs: 'Leki do odfiltrowania (w domyśle - ekstremalne pogorszenie stanu)',
                    drugProposal: 'Proponowany lek',
                    wageSum: 'Suma wag (więcej - lepiej)',
                    patientSex: {
                        label: 'Płeć',
                        type: {
                            MALE: 'Mężczyzna',
                            FEMALE: 'Kobieta',
                        }
                    },
                    experts: {
                        titleLbl: 'Lekarze specjaliści',
                        addExpertMsg: 'Dodaj specjaliste',
                        editExpertMsg: 'Edycja specjalisty',
                        deleteExpertTitleMsg: 'Usunięcie specjalisty',
                        deleteExpertDescMsg: 'Usunięcie specjalisty spowoduje również usunięcie danych treningowych silnika Ictal. Wszystkie dane powiązane z tym specjalistą zostaną usunięte. Czy chcesz kontynuować?',
                        saveMsg: 'Zmodyfikowano dane ekspertów',
                        save: "Zapisz",
                        cancel: "Anuluj",
                        validation: {
                            weightRange: "Waga musi zawierać się miedzy 0 i 1"
                        }
                    },
                    recommendations: {
                        titleLbl: 'Leki rekomendowane przez specjalistów',
                        addRecommendationLbl: 'Dodaj rekomendację',
                        saveMsg: 'Zapisano wektor rekomendacji leków',
                        save: "Zapisz",
                        cancel: "Anuluj",
                        dialog: {
                            titleLbl: 'Rekomendowane leki',
                            expertLbl: 'Specjalista',
                            therapyLbl: 'Terapia'
                        }
                    },
                    epilepsies: {
                        titleLbl: 'Typy epilepsji',
                        addMsg: 'Dodaj typ epilepsji',
                        editMsg: 'Edycja typu epilepsji',
                        deleteTitleMsg: 'Usunięcie typu epilepsji',
                        deleteDescMsg: 'Usunięcie type epilepsji spowoduje również usunięcie danych treningowych silnika Ictal. Wszystkie dane powiązane z tym typem epilepsji zostaną usunięte. Czy chcesz kontynuować?',
                        saveMsg: 'Zmodyfikowano dane typów napadów',
                        save: "Zapisz",
                        cancel: "Anuluj"
                    },
                    ediagnosis: {
                        titleLbl: 'E-Diagnoza',
                        headers: {
                            eventsTrailer: 'Przed napadem długo (godz, dni)',
                            eventsBefore: 'Przed napadem krótko (sekundy)',
                            eventsStart: 'Początek napadu - pierwszy objaw',
                            eventsNext: 'Kolejne objawy napadu',
                            eventsEnd: 'Koniec napadu',
                            eventsAfter: 'Po napadzie',
                            eventsFeature: 'Cechy ogólne napadu'
                        },
                        dialog: {
                            titleLbl: 'Wektor e-diagnozy',
                            epilepsyLbl: 'Typ napadu',
                            save: "Zapisz",
                            cancel: "Anuluj",
                            saveMsg: 'Zmodyfikowano wektor e-diagnozy'
                        },
                        events: {
                            trailerPrecise: 'Przeczucie napadu (zwiastun), długie',
                            trailerUnspecified: '"Inne" samopoczucie nieokreślone',
                            beforeAura: 'Poczucie początku (aura)',
                            beforeSpecificStimuli: 'Napad może być sprowokowany specyficznym bodźcem',
                            beforeNystagmus: 'Oczopląs lub patologiczne ruchy gałek ocznych',
                            beforeImmobility: 'Znieruchomienie lub bezruch',
                            startFaint: 'Przytomność: utrata',
                            startLossConsciousness: 'Świadomość: utrata częściowa lub pełna',
                            startBodyConvulsions: 'Drgawki całego ciała',
                            startBodyPartConvulsions: 'Drgawki ogniskowe (części ciała)',
                            startFaceConvulsions: 'Drgawki mięśni twarzy',
                            startBodyStiffness: 'Wysztywnienie całego ciała',
                            startLimbEjection: 'Wyrzut MYO 4-kończynowy',
                            startEyeMovementDisorders: 'Oczopląs lub patologiczne ruchy gałek ocznych',
                            startEyeTurnPernament: 'Zwrot gałek ocznych w jedną z 4 stron',
                            startBodyTurn: 'Zwrot ciała i/lub głowy w jedną stronę',
                            startGroundFall: 'Upadek',
                            startBitTongue: 'Przygryzienie języka lub elementów jamy ustnej',
                            startShout: 'Krzyk',
                            startSounds: 'Wokalizacje werbalne i niewerbalne',
                            startMotorAutomatisms: 'Automatyzmy ruchowe proste lub złożone',
                            startImmobility: 'Znieruchomienie lub bezruch',
                            startLaugh: 'Śmiech',
                            startChuck: 'Cmokanie i/lub mlaskanie',
                            startCry: 'Płacz',
                            startEpigastricSymptoms: 'objawy epigastryczne napadowe i saliwacja',
                            startDejaVu: 'Urojenia: deja vu, deja vecu, inne ',
                            startVisualHallucinations: 'Zaburzenia widzenia w tym halucynacje',
                            startAuditoryHallucinations: 'Objawy i/lub halucynacje słuchowe',
                            startEyelidsMyoclonus: 'Mioklonie powiek',
                            startMyoclonus: 'Mioklonie uogólnione',
                            nextFaint: 'Przytomność: utrata',
                            nextLossConsciousness: 'Świadomość: utrata',
                            nextBodyConvulsions: 'Drgawki całego ciała',
                            nextBodyPartConvulsions: 'Drgawki ogniskowe (części ciała)',
                            nextFaceConvulsions: 'Drgawki mięśni twarzy',
                            nextBodyStiffness: 'Wysztywnienie całego ciała',
                            nextEyeMovementDisorders: 'Oczopląs lub patologiczne ruchy gałek ocznych',
                            nextEyeTurnPernament: 'Zwrot gałek ocznych w jedną z 4 stron',
                            nextBodyTurn: 'Zwrot ciała i/lub głowy w jedną stronę',
                            nextGroundFall: 'Upadek',
                            nextBitTongue: 'Przygryzienie języka lub elementów jamy ustnej',
                            nextUrinating: 'Oddanie lub popuszczenie moczu',
                            nextDefecation: 'Oddanie kału lub parcie na kał',
                            nextShout: 'Krzyk',
                            nextSounds: 'Wokalizacje werbalne i niewerbalne',
                            nextAggression: 'Agresywne zachowanie',
                            nextMotorAutomatisms: 'Automatyzmy ruchowe proste lub złożone',
                            nextImmobility: 'Znieruchomienie lub bezruch',
                            nextLaugh: 'Śmiech',
                            nextChuck: 'Cmokanie i/lub mlaskanie',
                            nextCry: 'Płacz',
                            nextEpigastricSymptoms: 'Objawy epigastryczne napadowe',
                            nextDejaVu: 'Urojenia: deja vu, deja vecu, inne ',
                            nextVisualHallucinations: 'Zaburzenia widzenia w tym halucynacje',
                            nextAuditoryHallucinations: 'Objawy i/lub halucynacje słuchowe',
                            nextEyelidsMyoclonus: 'Mioklonie powiek',
                            endAnotherSeizure: 'Czy ten napad może przejść w inny napad',
                            endBodyConvulsions: 'Drgawki całego ciała',
                            endBodyPartConvulsions: 'Drgawki ogniskowe (części ciała)',
                            endFaceConvulsions: 'Drgawki mięśni twarzy',
                            endEyeMovementDisorders: 'Oczopląs lub patologiczne ruchy gałek ocznych',
                            endEyeTurnPernament: 'Zwrot gałek ocznych w jedną z 4 stron',
                            endBodyTurn: 'Zwrot ciała i/lub głowy w jedną stronę',
                            endGroundFall: 'Upadek',
                            endUrinating: 'Oddanie lub popuszczenie moczu',
                            endDefecation: 'Oddanie kału lub parcie na kał',
                            endSounds: 'Wokalizacje werbalne i niewerbalne',
                            endAggression: 'Agresywne zachowanie',
                            endMotorAutomatisms: 'Automatyzmy ruchowe proste lub złożone',
                            endImmobility: 'Znieruchomienie lub bezruch',
                            endLaugh: 'Śmiech',
                            endChuck: 'Cmokanie i/lub mlaskanie',
                            endCry: 'Płacz',
                            endVomit: 'Wymioty',
                            afterEyeMovementDisorders: 'Oczopląs lub patologiczne ruchy gałek ocznych',
                            afterEyeTurnPernament: 'Zwrot gałek ocznych w jedną z 4 stron',
                            afterUrinating: 'Oddanie lub popuszczenie moczu',
                            afterDefecation: 'Oddanie kału lub parcie na kał',
                            afterConfusion: 'Splatanie ponapadowe',
                            afterSleep: 'Sen ponapadowy',
                            afterAggression: 'Agresywne zachowanie',
                            afterMotorAutomatisms: 'Automatyzmy ruchowe proste lub złożone',
                            afterVomit: 'Wymioty',
                            afterHighTemperature: 'Podniesiona temperatura ciała',
                            featureMany: 'Występowanie seryjne (gromadne) z przerwą lub bez przerwy',
                            featureDayOccurrence: 'Napady w dzień',
                            featureNightOccurrence: 'Napady nocne',
                            featureAwakeningOccurrence: 'Napady krótko przed/po wybudzeniu',
                            featureMorningOccurrence: 'Napady poranne',
                            featureDuration: 'Czas trwania napadu'
                        }
                    },
                    model: {
                        search: 'Szukaj',
                        manage: 'Zarządzaj',
                        drug: {
                            general: "Dane podstawowe",
                            weights: "Wagi",
                            shortName: "Skrót",
                            name: "Nazwa",
                            minAge: "Wiek od",
                            plAvailability: "Dostępny w PL",
                            plRefunded: "Refundowany w PL",
                            reproductiveAgeWeight: "Kobiety w wieku rozrodczym",
                            pregnantWeight: "Kobiety w ciąży",
                            oldAgeWeight: "Osoby w starszym wieku",
                            somaticDiseaseWeight: "Osoby z chorobami somatycznymi"
                        },
                        expert: {
                            description: "Specjalista",
                            factor: "Waga"
                        },
                        epilepsy: {
                            name: "Nazwa",
                        }
                    },
                    algorithmSteps: {
                        filterName: 'Filtr',
                        order: 'Kolejność',
                        usedFilters: 'Zastosowane filtry',
                        filters: {
                            SeizureDrugFilter: 'Wybranie leków dla zdefiniowanych typów napadów',
                            UsedDrugFilter: 'Usunięcie wcześniej używanych leków',
                            TherapyDrugFilter: 'Ustalenie kolejności leków na podstawie rekomendacji ekspertów',
                            WeightDrugFilter: 'Obliczenie wag leków',
                        },
                        tableModel: {
                            drugName: 'Nazwa',
                            drugShortName: 'Skrót',
                            minAge: 'Wiek od',
                            oldAgeWeight: 'Starszy wiek',
                            pregnantWeight: 'Ciąża',
                            reproductiveAgeWeight: 'Wiek reprodukcyjny',
                            somaticDiseaseWeight: 'Chroby somatyczne',
                            weightSum: 'Suma wag'
                        }
                    },
                    furtherPoliOffset: "Numer Politerapii Dalszej (numerować od 0)"
                }
            },
            duties: {
                Duties: {
                    duty: 'Dyżur zaplanowany',
                    availableDuty: 'Dyżur dostępny',
                    notAvailableDuty: 'Dyżur niedostępny',
                    selectedDuty: 'Wybrany',
                    blockedDuty: 'Niedostępny',
                    cancelledDuty: 'Wybrany do odwołania',
                    dutyToChange: 'Prośba o przejęcie dyżuru',
                    changes: {
                        approve: {
                            text: 'Zapisz zmiany',
                            tooltipInstruction: 'Wybierz dogodne terminy spośród dostępnych, a następnie zatwierdź klikając w ten guzik',
                        },
                        discard: {
                            text: 'odrzuć zmiany',
                        },
                    },
                },
                confirmationDialog: {
                    text: 'Wprowadzone zmiany nie zostały zapisane. Jesteś pewien, że chcesz je odrzucić?'
                },
                youAreNotAllowedDialog: {
                    title: 'Brak uprawnień',
                    text: 'Nie posiadasz uprawnień pozwalających na rejestrację dyżurów. Skontaktuj się z nami, żeby uzyskać więcej informacji.',
                },
                obligatoryDutyDialog: {
                    title: 'Operacja niedozwolona',
                    subtitle: 'Zgodnie z regulaminem wybrany dyżur nie może zostać odwołany.',
                    text: 'Więcej informacji na ten temat znajdziesz w naszym ',
                    termsAndConditions: 'Regulaminie dyżurów',
                    close: 'Zamknij'
                },
                dutiesLimitAchievedDialog: {
                    title: 'Operacja niedozwolona',
                    subtitle: 'Osiągnąłeś limit dyżurów na ten tydzień.',
                    text: 'Więcej informacji na ten temat znajdziesz w naszym ',
                    termsAndConditions: 'Regulaminie dyżurów',
                    close: 'Zamknij'
                },
            },
            medicalHistory: {
                MedicalHistory: {
                    title: 'Historia choroby',
                    return: 'Powrót',
                    show: 'Zobacz',
                    psychomotorDevelopment: 'Rozwój psychoruchowy',
                    comorbidities: 'Choroby współistniejące',
                    notDiagnosedLbl: 'Nie zdiagnozowano',
                    bloodType: 'Grupa  krwi',
                    height: 'Wzrost',
                    weight: 'Waga',
                    bmi: 'BMI',
                    tabs: {
                        psychomotorDevelopment: 'Rozwój psychoruchowy',
                        seizuresHistory: 'Historia napadów',
                        seizuresCharacteristics: 'Charakterystyka napadów',
                        comorbidities: 'Choroby współistniejące',
                        treatment: 'Leczenie'
                    },
                    treatment: {
                        title: 'Leczenie i historia wizyt',
                        currentTherapy: 'Aktualna terapia',
                        biomedicalData: 'Dane biomedyczne',
                        comorbiditiesData: 'Choroby współistniejące',
                        previousTherapies: 'Poprzednie terapie',
                        number: 'Nr',
                        medicine: 'Lek',
                        dosage: 'Dawka',
                        effect: 'Efekt',
                        noCurrentTherapy: 'Brak aktualnej terapii',
                        start: 'Rozpoczęcie',
                        end: 'Zakończenie',
                        noPreviousTherapies: 'Lista zakończonych terapii lekowych jest pusta',
                        treat: 'leczenie',
                        visits: 'wizyty'
                    },
                    survey: {
                        title: 'Ankieta zero'
                    },
                    epicrisis: {
                        title: 'Epikryza'
                    },
                    calendar: {
                        title: 'Kalendarz zdarzeń',
                        visitCalendarLabel: 'Kalendarz wizyt'
                    },
                    visitCalendar: {
                        title: "Opinie lekarza i pacjenta",
                        opinionTypeHeader: "Rodzaje opinii",
                        seizureEvaluation: "Jakość napadów",
                        wellBeing: "Ogólne samopoczucie",
                        treatmentRating: "Ocena leczenia",
                        sideEffects: "Objawy związane z leczeniem",
                        treatmentSafety: "Bezpieczeństwo leczenia",
                        patientOpinion: "Opinia pacjenta",
                        doctorOpinion: "Opinia lekarza",
                        noData: "Brak danych z ankiet",
                        noVisits: "Brak odbytych wizyt"
                    },
                    prescriptions: {
                        title: 'E-recepty'
                    },
                    consultations: {
                        title: 'Wizyty',
                        noConsultations: 'Lista konsultacji jest pusta',
                        observations: 'Obserwacje',
                        recomendation: 'Zalecenia',
                        doctor: 'Lekarz',
                        date: 'Data',
                        type: 'Rodzaj',
                        category: 'Kategoria',
                        none: 'Brak'
                    },
                    icd10: {
                        title: 'Rozpoznanie',
                        current: 'Aktualne rozpoznanie',
                        history: 'Poprzednie',
                        from: 'Od',
                        added: 'Dodano',
                        removed: 'Usunięto',
                        noCurrentIcd10: 'Brak aktualnego rozponania',
                        noIcd10History: 'Historia rozpoznań jest pusta'
                    },
                    bioMedInfo: {
                        edit: "Edytuj"
                    }
                },
                healthSurvey: {
                    title: 'Ankieta zero',
                    readOnlyAccess: 'Widok tylko do odczytu. Ankieta jest wypełniana przez lekarza podczas pierwszej wizyty',
                    noAnswer: 'Brak\nodpowiedzi',
                    allDisabled: 'Ankieta zero nie może być zmieniona po pierwszej wizycie',
                    partCompleted: 'Ta część ankiety została skompletowana',
                    currentTabDisabled: 'Ta część ankiety zero została już wypełniona i nie może być zmieniona po pierwszej wizycie',
                    emptyFilterSet: 'Na liście znajdują się tylko pytania bez udzielonej odpowiedzi.',
                    surveyUpdatedByDoctor: 'Lekarz wprowadził zmiany w ankiecie zdrowia w sekcji \'{sectionName}\'',
                    filters: {
                        show: 'Pokaż',
                        all: 'Wszystkie',
                        empty: 'Nieuzupełnione'
                    },
                    hintPopup: {
                        title: 'Nie wiesz jak odpowiedzieć?',
                        content: 'Nie przejmuj się! Pozostaw trudne pytanie bez odpowiedzi. Lekarz pomoże Ci uzupełnić brakujące części ankiety podczas pierwszej telekonferencji.'
                    },
                    SeizureHistory: {
                        introTitle: 'W tym miejscu chcemy ustalić jak długo i jakie masz napady padaczkowe.',
                        introSubtitle: 'Jest to bardzo ważne, pamiętaj: to historia twojej choroby, którą jedynie tak można ustalić, nie ma innej metody jej opisania, postaraj się zatem o precyzyjne fakty i daty!',
                        next: 'Dalej',
                        edit: 'Edytuj',
                        back: 'Wstecz',
                        historyCanBeModified: 'Historia napadów została już zainicjalizowana. Możesz ją edytować do pierwszej wizyty.',
                        historyCannotBeModified: 'Historia napadów może być edytowana tylko do pierwszej wizyty.',
                        return: 'Powrót',
                        outroTitle: 'Gratulacje, odpowiedziałeś na wszystkie pytania dotyczące historii napadów.',
                        outroSubtitle: 'Dziękujemy!',
                        save: 'Zapisz',
                        generatingInProgress: 'Trwa generowanie historii napadów. Proszę czekać...',
                        saveSuccess: 'Historia napadów została zapisana',
                        generateSuccess: 'Historia napadów została wygenerowana',
                        noHistory: 'Brak leczenia',
                        stages: {
                            firstSeizure: 'Pierwszy napad',
                            quantityAndRegularity: 'Ilość i regularność',
                            frequency: 'Częstotliwość',
                            breaks: 'Przerwy'
                        },
                        seizureTypesForm: {
                            title: 'Określ liczbę typów swoich napadów i nazwij je (maksymalnie 3)',
                            modifyWarning: 'Uwaga! Po modyfikacji typów napadów konieczne będzie powtórne definiowanie ich historii oraz charakterystyki',
                            modifyDisabled: 'Modifikacja typów napadów jest zablokowana, ponieważ zostały już dodane napady tych typów',
                            typeName: 'Typ napadu',
                            addType: 'Dodaj typ napadu'
                        },
                        firstSeizureForm: {
                            title: 'Podaj datę pierwszego napadu',
                            subtitle1: 'Cofnij się pamięcią wstecz i podaj jakikolwiek pierwszy napad.',
                            subtitle2: 'Określ jak najdokładniej możesz datę pierwszego napadu.',
                            subtitle3: 'Nazywamy go napadem {name} (jego typ zdefiniujemy potem wspólnie z lekarzem prowadzącym).',
                            sickSince: 'Ok, chorujesz zatem:'
                        },
                        quantityAndRegularity: {
                            title: 'Napady takie jak pierwszy trwają do dziś, wskaż czy:',
                            knowsAllDates: 'Czy znam daty wszystkich napadów?',
                            quantityYes: 'Od tego pierwszego napadu do dziś pojawiło się ich jeszcze',
                            quantityNo: 'Taki napad już się więcej nie pojawił',
                            quantityUndefined: 'Było ich potem tak dużo, że nie można podać dokładnej liczby',
                            seizuresQuantity: 'Ilość napadów',
                            yes: 'Tak',
                            no: 'Nie',
                            regularOccurrence: 'Czy napady występowały regularnie?'
                        },
                        knowsAllDates: {
                            subtitle: 'Podaj daty kolejnych napadów',
                            date: 'Data',
                            remove: 'Usuń',
                            add: 'Dodaj datę'
                        },
                        frequency: {
                            title: 'Określ częstotliwość - podaj ile napadów występuje zazwyczaj w danym okresie czasu:',
                            in: 'Na'
                        },
                        breaks: {
                            title: 'Czy występowały przerwy w napadach?',
                            subtitle: 'Podaj okresy, w których występowały przerwy',
                            date: 'Data',
                            to: 'do',
                            remove: 'Usuń',
                            add: 'Dodaj okres'
                        }
                    },
                    Intro: {
                        title1: 'Informacje, które tu podasz zostaną udostępnione Twojemu lekarzowi. Pomogą Cię prawidłowo zdiagnozować i leczyć.',
                        fillSurvey: 'Wypełnij ankietę zero',
                        inVisit: {
                            title1: 'Trwa telekonsultacja.',
                            title2: 'To czas by wspólnie z lekarzem uzupełnić ankietę.',
                            title3: 'Lekarz pomoże Ci odpowiedzieć na niejasne pytania.',
                            next: 'Dalej'
                        }
                    },
                    PsychomotorDevelopment: {
                        introTitle: 'Rozwój psychoruchowy',
                        introSubtitle1: 'W tym miejscu chcemy ustalić jaki jest Twój ogólny stan psycho-fizyczny.',
                        introSubtitle2: 'Dzięki Twoim odpowiedziom lekarz dobierze optymalną terapię.',
                        introSubtitle3: 'Pamiętaj, jeśli jakieś pytanie jest niejasne możesz odpowiedzieć na nie wspólnie z lekarzem podczas pierwszej konsultacji.',
                        stages: {
                            psychoPhysicalState: 'Stan fizyczno-psychiczny',
                            senses: 'Zmysły',
                            drugs: 'Używki'
                        },
                        psychoPhysicalState: {
                            overall: 'Określ rozwój psychoruchowy',
                            retardation: 'Określ opóźnienie psychoruchowe (upośledzenie)',
                            iq: 'Określ IQ pacjentki/a',
                            notTested: 'Nie badano',
                            tested: 'Badano',
                            iqValue: 'Podaj wartość IQ',
                            speech: 'Mowa czynna i bierna. (Mówienie i rozumienie mowy)',
                            mentalDisorders: 'Choroby psychiatryczne w wywiadzie',
                            neurologicalSyndrome: 'Stwierdzony zespół neurologiczny',
                            walk: 'Chód',
                            sex: 'Seks'
                        },
                        senses: {
                            nystagmus: 'Czy u pacjenta występuje / pojawia się oczopląs?',
                            vision: 'Wzrok',
                            hearing: 'Słuch'
                        },
                        drugs: {
                            smoking: 'Palenie papierosów',
                            alcohol: 'Alkohol',
                            narcotics: 'Narkotyki'
                        },
                        mentalDisorders: {
                            psychosis: 'Psychoza teraz lub kiedyś',
                            depression: 'Depresja teraz lub kiedyś',
                            autism: 'Autyzm zdiagnozowany',
                            adhd: 'ADHD i pochodne',
                            mentalDisordersNotKnown: 'Brak lub nie wiadomo'
                        },
                        neurologicalSyndrome: {
                            piramidalDeficit: 'Deficyt piramidalny',
                            brainDeficit: 'Móźdżkowy',
                            sensoryDisturbance: 'Zaburzenia czucia',
                            peripharalParesis: 'Niedowład obwodowy',
                            noNeurologicalSyndrome: 'Brak lub nie wiadomo'
                        }
                    },
                    SeizuresCharacteristics: {
                        introTitle: 'Charakterystyka napadów',
                        introSubtitle1: 'Pamiętaj, że opisujesz typowy napad lub napady. Opisując jeden napad możesz podać dokładnie co się zdarzyło. W przypadku mnogich napadów część z nich mogła się nieco różnić od siebie, zatem podajesz ich cechy wypadkowe. Opiszesz tu stany przednapadowe: zwiastuny, bezpośrednie zdarzenia oraz etapy napadu (początek, kontynuację, koniec i zdawisja po napadzie). Podasz też dane ogólne dotyczące napadów / napadu.',
                        introSubtitle2: 'Każda informacja jest ważna i może mieć wpływ na podstawienie diagnozy przez lekarza, postaraj się być jak najbardziej dokładna/y i przecyzyjna/y.',
                        question: 'Pytanie',
                        seizure: 'Napad',
                        emptySeizureTypesInfo: 'Przed wypełnieniem charakterystyki napadów należy zdefiniować typy napadów w zakładce \'Historia napadów\'',
                        stages: {
                            trailers: 'Przepowiednie\nnapadu',
                            before: 'Objawy\nprzednapadowe',
                            start: 'Pierwszy\nobjaw napadu',
                            next: 'Kolejny\nobjaw napadu',
                            end: 'Objawy\nkońca napadu',
                            after: 'Po napadzie',
                            feature: 'Cechy\nnapadu',
                            premonition: 'Zwiastun'
                        },
                        trailers: {
                            trailerPrecise: 'Czy występują (lub wystąpił jeśli był tylko 1 napad) sprecyzowane zwiastuny napadu?',
                            trailerPreciseDescription: 'Zwiastunem (przepowiednią), nazywamy mniej lub bardziej sprecyzowane przeczucie nadejścia napadu. Przez zwiastun sprecyzowany rozumiemy określone uczucie dające się opisać: niepokój, smutek, podniecenie itp. Bywa, że zwiastun jest nieokreślony, trudny do opisania przez pacjenta. Jest długim zjawiskiem, występuje godziny (rzadziej dni) przed napadem, to odróżnia go od aury, która bezpośrednio poprzedza napad. Niektórzy chorzy nabywaja umiejętność przeczucia napadu dopiero po pewnym czasie.',
                            trailerUnspecified: 'Czy występują (lub wystąpił jeśli był tylko 1 napad) niesprecyzowane zwiastuny napadu?',
                            trailerUnspecifiedDescription: ''
                        },
                        before: {
                            beforeAura: 'Poczucie początku (aura)',
                            beforeAuraDescription: 'Przeczucie rozpoczynającego się napadu bezpośrednio przed nim (zwykle sekundy)',
                            beforeNystagmus: 'Oczopląs lub patologiczne ruchy gałek ocznych',
                            beforeNystagmusDescription: 'Czy tuż przed napadem występował oczopląs lub zaburzenia ruchowe gałek?',
                            beforeImmobility: 'Znieruchomienie lub bezruch',
                            beforeImmobilityDescription: 'Czy tuż przed napadem występowało częściowe znieruchomienie (lub osłabienie ruchomości) lub całkowity bezruch?'
                        },
                        start: {
                            startFaint: 'Przytomność: utrata',
                            startFaintDescription: 'Czy pierwszym objawem napadu była utrata przytomności?',
                            startLossConsciousness: 'Świadomość: utrata pełna',
                            startLossConsciousnessDescription: 'Czy pierwszym objawem napadu była pełna utrata świadomości?',
                            startLossConsciousnessPartially: 'Świadomość: utrata częściowa',
                            startLossConsciousnessPartiallyDescription: 'Czy pierwszym objawem napadu była częściowa utrata świadomości? Zaburzony jakikolwiek obszar świadomości ',
                            startBodyConvulsions: 'Drgawki całego ciała',
                            startBodyConvulsionsDescription: 'Czy pierwszym objawem napadu były drgawki całego ciała?',
                            startBodyPartConvulsions: 'Drgawki ogniskowe (części ciała)',
                            startBodyPartConvulsionsDescription: 'Czy pierwszym objawem napadu były drgawki części ciała?',
                            startFaceConvulsions: 'Drgawki mięśni twarzy',
                            startFaceConvulsionsDescription: 'Czy pierwszym objawem napadu były drgawki twarzy (uogólnione lub ogniskowe)?',
                            startRhythmicConvulsions: 'Drgawki rytmiczne',
                            startRhythmicConvulsionsDescription: 'Czy te drgawki jako pierwszy objaw napadu były rytmiczne?',
                            startBodyStiffness: 'Wysztywnienie całego ciała',
                            startBodyStiffnessDescription: 'Czy pierwszym objawem napadu było toniczne wysztywnienie całego ciała?',
                            startLimbEjection: 'Napad zgięciowy lub wyrzutowy typu WEST',
                            startLimbEjectionDescription: 'Czy pierwszym objawem napadu był napad zgięciowy typu Westa?',
                            startHyperkineticSymptoms: 'Duże objawy hyperkinetyczne: pedałowanie, skakanie, rzuty miednicą wielokierunkowe',
                            startHyperkineticSymptomsDescription: 'Czy pierwszym objawem napadu było wystąpienie dużych objawów hyperkinetycznych: pedałowanie, wielokierunkowe rzuty miednicy i podobne?',
                            startEyeMovementDisorders: 'Oczopląs lub patologiczne ruchy gałek ocznych',
                            startEyeMovementDisordersDescription: 'Czy pierwszym objawem napadu był oczopląs lub patologiczne ruchy gałek ocznych?',
                            startEyeTurnPernament: 'Zwrot gałek ocznych w jedną z 4 stron',
                            startEyeTurnPernamentDescription: 'Czy pierwszym objawem napadu był zwrot gałek ocznych w jedną z 4 stron?',
                            startBodyTurn: 'Zwrot ciała i/lub głowy w jedną stronę',
                            startBodyTurnDescription: 'Czy pierwszym objawem napadu był zwrot ciała i/lub głowy w jedną ze stron?',
                            startGroundFall: 'Upadek',
                            startGroundFallDescription: 'Czy pierwszym objawem napadu był upadek na ziemię lub częściowy upadek na ziemię (np. na kolana)?',
                            startAtony: 'Atonia lub napad astatyczny całego ciała lub jego części',
                            startAtonyDescription: 'Czy pierwszym objawem napadu była atonia mięśniowa lub napad astatyczny obejmujący całe ciało lub jego część (np. tylko głowa)? ',
                            startBitTongue: 'Przygryzienie języka lub elementów jamy ustnej',
                            startBitTongueDescription: 'Czy pierwszym objawem napadu było przygryzienie języka lub elementów jamy ustnej?',
                            startShout: 'Krzyk',
                            startShoutDescription: 'Czy pierwszym objawem napadu był krzyk?',
                            startSounds: 'Wokalizacje werbalne i niewerbalne',
                            startSoundsDescription: 'Czy pierwszym objawem napadu były jakiekolwiek wokalizacje werbalne lub niewerbalne?',
                            startSpeechStop: 'Zatrzymanie mowy',
                            startSpeechStopDescription: 'Czy pierwszym objawem napadu było zatrzymanie mowy?',
                            startMotorAutomatisms: 'Automatyzmy oro-facialne: żucie, przełykanie, klikanie, mruganie',
                            startMotorAutomatismsDescription: 'Czy pierwszym objawem napadu było wystąpienie automatyzmów facialnych i oralnych: żucie, przełykanie, klikanie, mruganie? ',
                            startOtherAutomatisms: 'Inne automatyzmy: ruchowe i kk dolnych, perseweracje, wokalne, seksualne, złożone',
                            startOtherAutomatismsDescription: 'Czy pierwszym objawem napadu były inne automatyzmy: całego ciała, kończyn, perseweracje wokalne, automatyzmy seksualne, inne automatyzmy złożone?',
                            startImmobility: 'Znieruchomienie lub bezruch',
                            startImmobilityDescription: 'Czy pierwszym objawem napadu było znaczące obniżenie/zahamowanie ruchomości lub całkowity bezruch?',
                            startLaugh: 'Śmiech',
                            startLaughDescription: 'Czy pierwszym objawem napadu był napadowy śmiech (napad gelastyczny)?',
                            startChuck: 'Cmokanie i/lub mlaskanie',
                            startChuckDescription: 'Czy pierwszym objawem było cmokanie i mlaskanie?',
                            startCry: 'Płacz',
                            startCryDescription: 'Czy pierwszym objawem napadu był napadowy płacz?',
                            startEpigastricSymptoms: 'Objawy epigastryczne napadowe i saliwacja, zmiana smaku',
                            startEpigastricSymptomsDescription: 'Czy pierwszym objawem napadu było wystąpienie objawów epigastrycznych, saliwacja lub zmiana smaku?',
                            startHeartSymptoms: 'Objawy autonomiczne serca: palpitacje, tachy/brady/asystolia',
                            startHeartSymptomsDescription: 'Czy pierwszym objawem napadu były objawy autonomiczne dotyczące serca i krążenia: palpitacje, tachykardia lub bradykardia lub asystolie?',
                            startAutonomicSymptoms: 'Dodatkowe objawy autonomiczne: bladość, zaczerwienienie, zaburzenia oddychania, zaburzenia ruchomości źrenic, parcie na mocz/kał',
                            startAutonomicSymptomsDescription: 'Czy pierwszym objawem napadu były dodatkowe objawy autonomiczne: bladość, zaczerwienienie, zaburzenia oddychania, zaburzenia ruchomości źrenic, parcie na mocz/kał?',
                            startLanguageIssues: 'Dysfazja/Afazja, Dysleksja/Aleksja i nierozumienie mowy/języka',
                            startLanguageIssuesDescription: 'Czy pierwszymi objawami napadu były zaburzenia mowy takie jak: dysfazja/afazja, dysleksja/aleksja i/lub nierozumienie mowy/języka?',
                            startMemoryDisorders: 'Zaburzenia pamięci w czasie napadu (inne zmysły prawidłowe)',
                            startMemoryDisordersDescription: 'Czy pierwszym objawem napadu były zaburzenia pamięci w trakcie tegoż napadu, przy pełnej zachowanej sprawności pozostałych zmysłów?',
                            startDejaVu: 'Urojenia: deja vu, deja vecu, i podobne',
                            startDejaVuDescription: 'Czy pierwszym objawem napadu były urojenia typu deja vu, deja vecu lub podobne?',
                            startVisualHallucinations: 'Omamy wzrokowe: wielobarwne kształty (świetlne koła, błyski, utrata części pola widzenia lub ślepota (amaurosis). I/lub obustronna utrata pola widzenia black-out lub white-out. ',
                            startVisualHallucinationsDescription: 'Czy pierwszym objawem napadu były omamy wzrokowe: wielobarwne kształty (świetlne koła, błyski, utrata części pola widzenia lub ślepota (amaurosis)? I/lub obustronna utrata pola widzenia black-out lub white-out? ',
                            startAuditoryHallucinations: 'Omamy i lub podobne objawy słuchowe',
                            startAuditoryHallucinationsDescription: 'Czy pierwszymi objawami napadu były omamy i/lub podobne objawy słuchowe?',
                            startFeelingFearOrAnxiety: 'Napadowy lęk lub strach',
                            startFeelingFearOrAnxietyDescription: 'Czy pierwszym objawem napadu był napadowy lęk lub strach?',
                            startSomatosensorySymptoms: 'Ogniskowe objawy  somatosensoryczne, najczęściej parestezje z mrowieniem i/lub drętwieniem, kłucie, łaskotanie, pełzanie lub uczucie porażenia prądem.',
                            startSomatosensorySymptomsDescription: 'Czy pierwszymi objawami napadu były ogniskowe objawy  somatosensoryczne, najczęściej parestezje z mrowieniem i/lub drętwieniem, kłucie, łaskotanie, pełzanie lub uczucie porażenia prądem?',
                            startBodyDistortion: 'Zniekształcenia obrazu ciała z uczuciem ruchu (np. unoszenia się) lub zmienionej postawy (np. ruch skręcania) w nieruchomej kończynie',
                            startBodyDistortionDescription: 'Czy pierwszym objawem napadu było zniekształcenia obrazu ciała z uczuciem ruchu (np. unoszenia się) lub zmienionej postawy (np. ruch skręcania) w nieruchomej kończynie?',
                            startEyelidsMyoclonus: 'Mioklonie powiek',
                            startEyelidsMyoclonusDescription: 'Czy pierwszym objawem napadu były mioklonie powiek?',
                            startMyoclonus: 'Mioklonie uogólnione',
                            startMyoclonusDescription: 'Czy pierwszym objawem napadu były uogólnione mioklonie?'
                        },
                        next: {
                            nextFaint: 'Przytomność: utrata',
                            nextFaintDescription: 'Czy kolejnym objawem napadu była utrata przytomności?',
                            nextLossConsciousness: 'Świadomość: utrata pełna',
                            nextLossConsciousnessDescription: 'Czy kolejnym objawem napadu była pełna utrata świadomości?',
                            nextLossConsciousnessPartially: 'Świadomość: utrata częściowa',
                            nextLossConsciousnessPartiallyDescription: 'Czy kolejnym objawem napadu była częściowa utrata świadomości? Zaburzony jakikolwiek obszar świadomości',
                            nextBodyConvulsions: 'Drgawki całego ciała',
                            nextBodyConvulsionsDescription: 'Czy kolejnym objawem napadu były drgawki całego ciała?',
                            nextBodyPartConvulsions: 'Drgawki ogniskowe (części ciała)',
                            nextBodyPartConvulsionsDescription: 'Czy kolejnym objawem napadu były drgawki części ciała?',
                            nextFaceConvulsions: 'Drgawki mięśni twarzy',
                            nextFaceConvulsionsDescription: 'Czy kolejnym objawem napadu były drgawki twarzy (uogólnione lub ogniskowe)?',
                            nextRhythmicConvulsions: 'Drgawki rytmiczne',
                            nextRhythmicConvulsionsDescription: 'Czy te drgawki jako następny objaw napadu były rytmiczne?',
                            nextBodyStiffness: 'Wysztywnienie całego ciała',
                            nextBodyStiffnessDescription: 'Czy kolejnym objawem napadu było toniczne wysztywnienie całego ciała?',
                            nextHyperkineticSymptoms: 'Duże objawy hyperkinetyczne: pedałowanie, skakanie rzuty miednicą wielokierunkowe',
                            nextHyperkineticSymptomsDescription: 'Czy kolejnym objawem napadu było wystąpienie dużych objawów hyperkinetycznych: pedałowanie, wielokierunkowe rzuty miednicy i podobne?',
                            nextEyeMovementDisorders: 'Oczopląs lub patologiczne ruchy gałek ocznych',
                            nextEyeMovementDisordersDescription: 'Czy kolejnym objawem napadu był oczopląs lub patologiczne ruchy gałek ocznych?',
                            nextEyeTurnPernament: 'Zwrot gałek ocznych w jedną z 4 stron',
                            nextEyeTurnPernamentDescription: 'Czy kolejnym objawem napadu był zwrot gałek ocznych w jedną z 4 stron?',
                            nextBodyTurn: 'Zwrot ciała i/lub głowy w jedną stronę',
                            nextBodyTurnDescription: 'Czy kolejnym objawem napadu był zwrot ciała i/lub głowy w jedną ze stron?',
                            nextGroundFall: 'Upadek',
                            nextGroundFallDescription: 'Czy kolejnym objawem napadu był upadek na ziemię lub częściowy upadek na ziemię (np. na kolana)? Jeśli upadek był 1 objawem, to czy chory nadal leżu po upadku?',
                            nextBitTongue: 'Przygryzienie języka lub elementów jamy ustnej',
                            nextBitTongueDescription: 'Czy kolejnym objawem napadu było przygryzienie języka lub elementów jamy ustnej?',
                            nextUrinating: 'Oddanie lub popuszczenie moczu',
                            nextUrinatingDescription: 'Czy kolejnym objawem napadu było popuszczenie lub oddanie moczu?',
                            nextShout: 'Krzyk',
                            nextShoutDescription: 'Czy kolejnym objawem napadu był krzyk?',
                            nextSounds: 'Wokalizacje werbalne i niewerbalne',
                            nextSoundsDescription: 'Czy kolejnym objawem napadu były jakiekolwiek wokalizacje werbalne lub niewerbalne?',
                            nextMotorAutomatisms: 'Automatyzmy oro-facialne: żucie, przełykanie, klikanie, mruganie',
                            nextMotorAutomatismsDescription: 'Czy kolejnym objawem napadu było wystąpienie automatyzmów facialnych i oralnych: żucie, przełykanie, klikanie, mruganie? ',
                            nextOtherAutomatisms: 'Inne automatyzmy: ruchowe i kk dolnych, perseweracje, wokalne, seksualne, złożone',
                            nextOtherAutomatismsDescription: 'Czy kolejnym objawem napadu były inne automatyzmy: całego ciała, kończyn, perseweracje wokalne, automatyzmy seksualne, inne automatyzmy złożone?',
                            nextImmobility: 'Znieruchomienie lub bezruch',
                            nextImmobilityDescription: 'Czy kolejnym objawem napadu było znaczące obniżenie/zahamowanie ruchomości lub całkowity bezruch?',
                            nextChuck: 'Cmokanie i/lub mlaskanie',
                            nextChuckDescription: 'Czy kolejnym objawem było cmokanie i mlaskanie?',
                            nextEpigastricSymptoms: 'Objawy epigastryczne napadowe',
                            nextEpigastricSymptomsDescription: 'Czy kolejnym objawem napadu było wystąpienie objawów epigastrycznych, saliwacja lub zmiana smaku?',
                            nextHeartSymptoms: 'Objawy autonomiczne serca: palpitacje, tachy/brady/asystolia',
                            nextHeartSymptomsDescription: 'Czy kolejnym objawem napadu były objawy autonomiczne dotyczące serca i krążenia: palpitacje, tachykardia lub bradykardia lub asystolie?',
                            nextAutonomicSymptoms: 'Objawy dodatkowe autonomiczne: bladość, zaczerwienienie, zaburzenia oddychania, zaburzenia ruchomości źrenic, parcie na mocz/kał',
                            nextAutonomicSymptomsDescription: 'Czy kolejnym objawem napadu były dodatkowe objawy autonomiczne: bladość, zaczerwienienie, zaburzenia oddychania, zaburzenia ruchomości źrenic, parcie na mocz/kał?',
                            nextLanguageIssues: 'Dysfazja/Afazja, Dysleksja/Aleksja i nierozumienie mowy/języka',
                            nextLanguageIssuesDescription: 'Czy kolejnymi objawami napadu były zaburzenia mowy takie jak: dysfazja/afazja, dysleksja/aleksja i/lub nierozumienie mowy/języka?',
                            nextMemoryDisorders: 'Zaburzenia pamięci w czasie napadu (inne zmysły prawidłowe)',
                            nextMemoryDisordersDescription: 'Czy kolejnym objawem napadu były zaburzenia pamięci w trakcie tegoż napadu, przy pełnej zachowanej sprawności pozostałych zmysłów?',
                            nextDejaVu: 'Urojenia: deja vu, deja vecu, inne',
                            nextDejaVuDescription: 'Czy kolejnym objawem napadu były urojenia typu deja vu, deja vecu lub podobne?',
                            nextVisualHallucinations: 'Halucynacje wzrokowe: wielobarwne kształty, takie jak koła, błyski, utrata części pola widzenia lub ślepota (amaurosis). Obustronna utrata pola widzenia black-out lub white-out.',
                            nextVisualHallucinationsDescription: 'Czy kolejnym objawem napadu były omamy wzrokowe: wielobarwne kształty (świetlne koła, błyski, utrata części pola widzenia lub ślepota (amaurosis)? I/lub obustronna utrata pola widzenia black-out lub white-out?',
                            nextAuditoryHallucinations: 'Objawy i/lub halucynacje słuchowe',
                            nextAuditoryHallucinationsDescription: 'Czy kolejnymi objawami napadu były omamy i/lub podobne objawy słuchowe?',
                            nextSomatosensorySymptoms: 'Ogniskowe objawy  somatosensoryczne, najczęściej parestezje z mrowieniem i/lub drętwieniem, kłucie, łaskotanie, pełzanie lub uczucie porażenia prądem.',
                            nextSomatosensorySymptomsDescription: 'Czy kolejnymi objawami napadu były ogniskowe objawy  somatosensoryczne, najczęściej parestezje z mrowieniem i/lub drętwieniem, kłucie, łaskotanie, pełzanie lub uczucie porażenia prądem?',
                            nextBodyDistortion: 'Zniekształcenia obrazu ciała z uczuciem ruchu (np. unoszenia się) lub zmienionej postawy (np. ruch skręcania) w nieruchomej kończynie',
                            nextBodyDistortionDescription: 'Czy kolejnym objawem napadu było zniekształcenia obrazu ciała z uczuciem ruchu (np. unoszenia się) lub zmienionej postawy (np. ruch skręcania) w nieruchomej kończynie?'
                        },
                        end: {
                            endAnotherSeizure: 'Czy ten napad może przejść w inny napad',
                            endAnotherSeizureDescription: 'Czy ten napad na końcu może przejść w inny napad? (Nie kolejny taki sam, ale innego typu)',
                            endBodyConvulsions: 'Drgawki całego ciała',
                            endBodyConvulsionsDescription: 'Czy objawem kończącym napad były drgawki całego ciała?',
                            endBodyPartConvulsions: 'Drgawki ogniskowe (części ciała)',
                            endBodyPartConvulsionsDescription: 'Czy objawem kończącym napad były drgawki części ciała?',
                            endFaceConvulsions: 'Drgawki mięśni twarzy',
                            endFaceConvulsionsDescription: 'Czy objawem kończącym napad były drgawki twarzy (uogólnione lub ogniskowe)?',
                            endEyeTurnPernament: 'Zwrot gałek ocznych w jedną z 4 stron',
                            endEyeTurnPernamentDescription: 'Czy objawem końca napadu był zwrot gałek ocznych w jedną z 4 stron?',
                            endBodyTurn: 'Zwrot ciała i/lub głowy w jedną stronę',
                            endBodyTurnDescription: 'Czy objawem końca napadu był zwrot ciała i/lub głowy w jedną ze stron?',
                            endGroundFall: 'Upadek',
                            endGroundFallDescription: 'Czy ostatnim objawem napadu był upadek na ziemię lub częściowy upadek na ziemię (np. na kolana)? Lub jeśli chory do tej pory leżał czy leży dalej?',
                            endUrinating: 'Oddanie lub popuszczenie moczu',
                            endUrinatingDescription: 'Czy ostatnim objawem napadu było popuszczenie lub oddanie moczu?',
                            endSounds: 'Wokalizacje werbalne i niewerbalne',
                            endSoundsDescription: 'Czy ostatnim objawem napadu były jakiekolwiek wokalizacje werbalne lub niewerbalne?',
                            endImmobility: 'Znieruchomienie lub bezruch',
                            endImmobilityDescription: 'Czy ostatnim objawem napadu było znaczące obniżenie/zahamowanie ruchomości lub całkowity bezruch?',
                            endChuck: 'Cmokanie i/lub mlaskanie',
                            endChuckDescription: 'Czy ostatnim objawem było cmokanie i mlaskanie?',
                            endVomit: 'Wymioty',
                            endVomitDescription: 'Czy ostatnim objawem napadu były wymioty?'
                        },
                        after: {
                            afterParesisOrParalysis: 'Niedowład ponapadowy (Todda)',
                            afterParesisOrParalysisDescription: 'Czy po napadzie doszło do ponapadowego niedowładu lub porażenia typu Todda?',
                            afterUrinating: 'Oddanie lub popuszczenie moczu',
                            afterUrinatingDescription: 'Czy ostatnim objawem napadu było popuszczenie lub oddanie moczu?',
                            afterConfusion: 'Splatanie ponapadowe i/lub niepamięć wsteczna',
                            afterConfusionDescription: 'Czy po napadzie wystąpiło splątanie ponapadowe i/lub niepamięć wsteczna?',
                            afterSleep: 'Sen ponapadowy',
                            afterSleepDescription: 'Czy po napadzie wystąpił sen ponapadowy?',
                            afterSalivaOrBloodySalivaFoam: 'Piana na ustach/krwawa piana',
                            afterSalivaOrBloodySalivaFoamDescription: 'Czy po napadzie na ustach wystąpiła piana lub krwawa piana?',
                            afterHyperventilation: 'Hyperwentylacja',
                            afterHyperventilationDescription: 'Czy po napadzie doszło do hyperwentylacji?',
                            afterVomit: 'Wymioty',
                            afterVomitDescription: 'Czy po napadzie wystąpiły wymioty?'
                        },
                        feature: {
                            featureDuration: 'Czas trwania napadu',
                            featureDurationDescription: 'Ile czasu trwa przeciętnie taki napad?',
                            featureMorningOccurrence: 'Napady poranne',
                            featureMorningOccurrenceDescription: 'Czy napady występują w godzinach porannych?',
                            featureAwakeningOccurrence: 'Napady krótko przed/po wybudzeniu',
                            featureAwakeningOccurrenceDescription: 'Czy napady występują tuż przed lub tuż po wybudzeniu?',
                            featureNightOccurrence: 'Napady nocne',
                            featureNightOccurrenceDescription: 'Czy napady występują w czasie snu nocnego?',
                            featureDayOccurrence: 'Napady w dzień',
                            featureDayOccurrenceDescription: 'Czy napady występują w ciągu dnia?',
                            featureMany: 'Występowanie seryjne (gromadne) z przerwą lub bez przerwy',
                            featureManyDescription: 'Czy napady występują w seriach? Serie z przerwą lub bez przerwy (napady gromadne lub stan padaczkowy)  ',
                            beforeSpecificStimuli: 'Napad może być sprowokowany specyficznym bodźcem',
                            beforeSpecificStimuliDescription: 'Czy napad może być wywołany specyficznym bodźcem lub stanem (FS, dźwięki, brak snu, emocje, stres itp.) ',
                            formChanges: 'Czy napady taki jest zmienny w formie i czasie trwania?',
                            formChangesDescription: 'Czy ten napad zmienia się w formie i długości trwania? Nie mylić z innymi typami napadów jeśli są jednocześnie!'
                        },
                        fixCriticals: {
                            title: 'Poprawa wektora dla napadu',
                            subtitle: 'Potwierdzony napad',
                            description1: 'Poniżej wyświetlają się odpowiedzi pacjenta, które w dużym stopniu różnią się od wektora wzorcowego wybranego napadu.',
                            description2: 'Dopytaj dokładnie pacjenta o odpowiedź, jeśli nie zgadza się z odpowiedzią z ankiety 0 popraw ją.',
                            oldAnswer: 'Odpowiedź z Ankiety 0',
                            newAnswer: 'Nowa odpowiedź',
                            saveConfirm: 'Czy na pewno chcesz nadpisać tą część ankiety 0?',
                            saveSuccess: 'Zmiany zostały zapisane',
                            vectorChangedBy: 'Wektor zmieniony przez'
                        },
                        premonition: {
                            trailer: 'Zwiastun (przeczucie) napadu',
                            trailerDescription: 'Przeczucie rozpoczynającego się napadu bezpośrednio przed nim (zwykle sekundy)',
                        }
                    },
                    Comorbidities: {
                        introTitle: 'Choroby współistniejące',
                        introSubtitle1: 'W tym miejscu chcemy ustalić czy posiadasz choroby współistniejące.',
                        introSubtitle2: 'Pamiętaj, że choroby moga oddziaływać na przebieg padaczki.',
                        dataSavedMsg: 'Informacje dotyczące chorób współistniejących zostały zapisane',
                        hasHeadaches: 'Bóle głowy',
                        answerNo: 'Brak',
                        answerYes: 'Tak, występują',
                        answerYesInt: 'Tak',
                        disease: 'Choroba',
                        typeDisease: 'Podaj kod lub nazwę choroby',
                        hasPregnancies: 'Ciąże',
                        pregnancies: 'Liczba ciąż',
                        hasChildbirths: 'Porody',
                        childbirths: 'Liczba porodów',
                        hasPsychiatricDiseases: 'Choroby psychiatryczne',
                        hasNeurologicalDiseases: 'Choroby neurologiczne',
                        hasInfectiousDiseases: 'Choroby zapalne i zakaźne',
                        hasCancerDiseases: 'Choroby nowotworowe',
                        hasImmuneDiseases: 'Choroby krwi i immunologiczne',
                        hasEndocrineDiseases: 'Choroby endokrynologiczne i metaboliczne',
                        hasEyeDiseases: 'Choroby oka',
                        hasLaryngologicalDiseases: 'Choroby laryngologiczne',
                        hasHeartDiseases: 'Choroby serca i układu krążenia',
                        hasRespiratoryDiseases: 'Choroby układu oddechowego',
                        hasDigestiveDiseases: 'Choroby układu pokarmowego',
                        hasSkinDiseases: 'Choroby skóry',
                        hasBoneDiseases: 'Choroby kości i tkanki łącznej',
                        hasUrogenitalDiseases: 'Choroby moczowo-płciowe',
                        hasDevelopmentalDefects: 'Wady rozwojowe, wrodzone i abberacje chromosomowe',
                        hasInjuriesOrPoisoning: 'Urazy i zatrucia',
                        somaticDiseases: 'Pacjent posiada choroby wpływające na proces leczenia padaczki',
                        headaches: 'Pacjent ma bóle głowy'
                    },
                    DrugsHistory: {
                        introTitle: 'Leczenie',
                        introSubtitle1: 'To jest historia twojego leczenia do dziś.',
                        introSubtitle2: 'Podaj jakie leki stosowałeś w przeszłości i jaki był tego efekt. Każdy z leków ma nazwę substancji chemicznej w formie pełnej i skróconej. Nie przejmuj się jeśli jej nie pamiętasz.',
                        introSubtitle3: 'Ważne abyś wybrał poprawną nazwę leku.',
                        introSubtitleNotTreated: 'Pacjent nie przyjmował wcześniej leków',
                        drug: 'Lek',
                        drugEffects: 'Efekty zażywania leku',
                        addDrug: 'Dodaj lek',
                        removeDrug: 'Usuń lek',
                        typeDrug: 'Podaj nazwę leku',
                        dataSavedMsg: 'Historia leczenia została zapisana',
                        vnsStimulator: {
                            label: 'Stymulator VNS',
                            add: 'Dodaj stymulator VNS',
                            remove: 'Usuń stymulator VNS',
                            date: 'Data implantacji',
                            name: 'Nazwa implantu',
                            model: 'Model implantu',
                            serial: 'Numer seryjny implantu',
                            removed: 'Implant został usunięty z historii leczenia.'
                        },
                        activeSubstance: {
                            add: 'Dodaj substancję aktywną',
                            remove: 'Usuń substancję aktywną',
                            title: 'Substancja aktywna',
                            effects: 'Efekty zażywania substancji',
                            type: 'Podaj nazwę substancji aktywnej'
                        }
                    }
                },
                visitSurvey: {
                    stages: {
                        patientState: "Stan pacjenta",
                        patientOpinion: "Opinia pacjenta",
                        doctorOpinion: "Opinia lekarza"
                    },
                    patientState: {
                        lastSeizureDate: "Data ostatniego napadu przed tą wizytą",
                        wellBeing: "Ogólne samopoczucie od ostatniej wizyty",
                        allSeizureRegistered: "Od ostatniej wizyty wprowadziłem/nie wprowadziłem w Kalendarzu Zdarzeń moje napady",
                        additionalIncidents: "Od ostatniej wizyty - poza zdarzeniami podanymi w Kalendarzu Zdarzeń - zdarzyły się jeszcze następujące wydarzenia"
                    },
                    patientOpinion: {
                        seizureCountByPatient: "Subiektywnie czy było tyle samo napadów?",
                        seizureEvaluationByPatient: "Subiektywnie jakie były napady",
                        currentTreatment: "Moje leczenie (lub brak leczenia) obecnie jest",
                        sideEffects: "Objawy niepożadane związane z obecnym leczeniem"
                    },
                    doctorOpinion: {
                        seizureCountByDoctor: "Ilość napadów w opinii lekarza",
                        seizureEvaluationByDoctor: "Jakość napadów w opinii lekarza",
                        treatmentEfficiency: "Skuteczność leczenia w opinii lekarza",
                        treatmentSafety: "Bezpieczeństwo leczenia w opinii lekarza"
                    },
                    date: "Data",
                    fromDate: "Od",
                    toDate: "Do",
                    seizuresCountable: "Policzalne wprowadzone w datach",
                    seizuresUncountable: "Niepoliczalne od - do",
                    seizure: "Napad",
                    save: "Zapisz",
                    addSeizure: "Dodaj napad",
                    noEpisodeAdded: "Nie wprowadzono żadnego napadu",
                    episodeTypeLbl: "Napady typu",
                    incidentDescription: "Opis zdarzenia",
                    noSeizure: "Brak napadu",
                    next: "Dalej",
                    howManySeizures: "Ile było napadów od poprzedniej wizyty",
                    countableLbl: "Było ich",
                    unCountableLbl: "Było ich tak dużo, że nie da się podać dokładnej liczby",
                    seizuresMandatoryError: "Podaj daty napadów",
                    add: "Dodaj",
                    close: "Zamknij",
                    seizuresNotAddedSummary: "Napady niewprowadzone od ostatniej wizyty",
                    seizuresDates: "Daty napadów",
                    seizuresSinceLastVisit: "Liczba napadów od ostatniej wizyty",
                    per: "na",
                    addNewSeizureType: "Dodaj nowy typ napadu",
                    addSeizureTypeDialog: {
                        title: "Dodawanie nowego typu napadu",
                        name: "Nazwa napadu",
                        success: "Dodano nowy typ napadu"
                    },
                    seizurePeriod: "Podaj okres, w którym występowały napady",
                    addPeriodicSeizure: "Doday okres",
                    deletePeriodicSeizure: "Usuń okres",
                    until: "Do",
                    seizuresInPeriod: "Liczba napadów w okresie"
                },
                visitCertificates: {
                    create: "Wystaw zaświadczenie Neurosphery",
                    createStateCert: "Wystaw zaświadczenie państwowe",
                    doNotCreate: "Nie wystawiaj zaświadczenia",
                    certificateType: "Typ zaświadczenia",
                    additionalRemarks: "Dodatkowe uwagi",
                    additionalRemarksPlaceholder: "Wprowadź dodatkowe uwagi",
                    generate: "Wystaw",
                    certificateContent: "Treść zaświadczenia:",
                    pickDate: 'Wybierz datę',
                    threeMonths: '3 miesiące',
                    sixMonths: '6 miesiący',
                    oneYear: '1 rok',
                    date: 'Data',
                    enterProcedureName: 'Podaj nazwę zabiegu',
                    enterActivityName: 'Podaj nazwę zajęć',
                    validationError: 'Uzupełnij pole',
                    addNext: 'Dodaj kolejne',
                    otherDescriptionLbl: 'Podaj dalszą treść zaświadczenia',
                    endOfYear: 'Do końca roku',
                    noDoctor: 'Nie wybrano lekarza',
                    addCertificate: 'Dodaj zaświadczenie',
                    noCertificates: 'Lista zaświadczeń jest pusta',
                    stateCerts: {
                        DRIVING_CERT: 'Zaświadczenie do prawa jazdy',
                        ZUS_OL9: 'Zaświadczenie ZUS OL-9',
                        DISABILITY_CERT: 'Zaświadczenie lekarskie o stanie zdrowia'
                    },
                    drivingCert: {
                        chooseSuitable: 'Wybierze odpowiednie',
                        hasEpilepsy: 'Czy pacjent choruje na padaczkę',
                        hasEpilepticSyndrome: 'Zespół padaczkowy',
                        driver: 'Kierowca',
                        driverCandidate: 'Kandydat na kierowcę',
                        firstEpisodeDate: 'Data pierwszego napadu',
                        firstEpisodeDateDisclaimer: 'Data pobrana z Ankiet 0 Jeśli się nie zgadza - zmień datę',
                        seizureTypes: 'Rodzaje napadów',
                        diagnosticsLbl: 'Dane dotyczące przeprowadzonej diagnostyki (wyniki przeprowadzonych badań obrazowych)',
                        illnessDuration: 'Czas trwania choroby ',
                        courseOfIllness: 'Przebieg choroby (w tym data ostatniego napadu) ',
                        courseOfTreatment: 'Przebieg i rodzaj leczenia, a jeżeli odstawiono leczenie – data odstawienia leczenia) ',
                        coexistingDiseases: 'Współistniejące schorzenia mające wpływ na przebieg choroby',
                        chronicComplications: 'Uwagi dotyczące przewlekłych powikłań choroby ',
                        noDrivingPeriod: 'Okres, w którym badany nie może kierować pojazdem',
                        periodStart: 'Od',
                        periodEnd: 'Do',
                        furtherObservationRequirements: 'Wymagania dalszej obserwacji',
                        otherRemarks: 'Inne uwagi (np. inna utrata świadomości lub zaburzenia świadomości lub napadowe zaburzenia ruchowe o symptomatologii padaczkowej, strukturalna zmiana śródmózgowa lub śródczaszkowa itp.)',
                    },
                    healthStateCert: {
                        mainIllness: 'Choroba podstawowa',
                        mainIllnessDisclaimer: 'System automatycznie dodał rozpoznanie główne ICD10 pacjenta. Możesz to usunąć lub dodać opis choroby.',
                        coexistingIllnesses: 'Choroby współistniejące',
                        coexistingIllnessesDisclaimer: 'Jeśli pacjent posiada choroby współistniejące system automatycznie dodał rozpoznanie główne ICD10 pacjenta. Możesz to usunąć lub dodać opis choroby.',
                        illnessDescription: 'Opis przebiegu choroby i dotychczasowego leczenia',
                        illnessDescriptionDisclaimer: 'Uwzględnij, od kiedy (data, okres) i z jakiego powodu prowadzono leczenie, pobyty w szpitalu, sanatorium, ośrodku rehabilitacji (okres, nazwa zakładu), wyniki badań pomocniczych i wnioski z konsultacji specjalistycznych potwierdzające rozpoznanie choroby podstawowej i chorób współistniejących.',
                        prognosis: 'Rokowanie',
                        prognosisDisclaimer: 'Ocena wyników leczenia i rokowanie (należy określić obecny stan zdrowia, prognozę przebiegu choroby, wskazania do dalszego leczenia i rehabilitacji).',
                        insuredData: 'Informacje o osobie ubezpieczonej',
                        insuredLabel: 'Osoba ubezpieczona jest',
                        capable: 'zdolna',
                        inCapable: 'niezdolna do odbycia podróży na badanie przez lekarza orzecznika (konsultanta) ZUS',
                        inCapableReason: 'Powód niezdolności',
                        attaching: 'Załączam',
                        documents: 'dokumentów'
                    },
                    disabilityCert: {
                        pronouncement: 'Orzeczenie',
                        firstTime: 'orzeczenie pierwszorazowe',
                        subsequent: 'orzeczenie powtórne',
                        mainIllness: "Rozpoznanie choroby zasadniczej",
                        courseOfIllness: 'Przebieg schorzenia podstawowego – początek (ustalony na podstawie jakiej dokumentacji); stopień uszkodzenia strukturalnego i funkcjonalnego; stadium zaawansowania choroby',
                        coexistingIllnesses: 'Uszkodzenia innych narządów i układów, choroby współistniejące',
                        coexistingIllnessesDisclaimer1: 'Jeśli pacjent posiada choroby współistniejące system automatycznie dodał rozpoznanie główne ICD10 pacjenta.',
                        coexistingIllnessesDisclaimer2: 'Możesz to usunąć lub dodać opis choroby.',
                        treatment: 'Zastosowane leczenie i rehabilitacja – rodzaje, czas trwania, pobyty w szpitalu, sanatorium',
                        treatmentResult: 'Ocena wyników leczenia, rokowania, (możliwość poprawy), dalsze leczenie i rehabilitacja',
                        rehabilitationEquipment: 'Używane zaopatrzenie ortopedyczne i sprzęt rehabilitacyjny; ewentualne potrzeby w tym zakresie',
                        applies: 'Dotyczy',
                        appliesNot: 'Nie dotyczy',
                        additionalExaminations: 'Wykaz dokonanych badań dodatkowych, potwierdzających rozpoznanie (w załączeniu)',
                        consultations: 'Wykaz istotnych konsultacji specjalistycznych, załączonych do zaświadczenia',
                        treatmentStart: 'Od kiedy pacjent pozostaje pod opieką lekarza wystawiającego zaświadczenie?',
                        treatmentStartDisclaimer: 'Wyświetlona data pobrana jest z pierwszej wizyty pacjent w systemie Neuroterminal - możesz zmienić tą datę',
                        documentationStart: 'Od kiedy pacjent posiada dokumentację medyczną? (data)',
                        documentationAccess: 'Czy lekarz wystawiający zaświadczenie ma pełny wgląd do dokumentacji medycznej pacjenta',
                        requiresCare: 'Pacjent wymaga opieki osoby drugiej ze względu na niemożność samodzielnej egzystencji',
                        requires: 'Wymaga',
                        requiresNot: 'Nie wymaga',
                        incapableOfJourney: 'Pacjent jest trwale niezdolny do odbycia podróży celem udziału w posiedzeniu składu orzekającego w siedzibie zespołu do spraw orzekania o stopniu niepełnosprawności',
                        hasHealthWorsen: 'U pacjenta nastąpiło istotne pogorszenie stanu zdrowia w okresie od ostatniego badania dla celów orzeczniczych',
                        happened: 'Nastąpiło',
                        happenedNot: 'Nie nastąpiło'
                    },
                    yes: 'Tak',
                    no: 'Nie'
                },
                sickLeaves: {
                    title: 'Zwolnienia lekarskie',
                    number: 'Numer',
                    dateFrom: 'Od',
                    dateTo: 'Do',
                    type: 'Rodzaj',
                    status: 'Status',
                    add: 'Dodaj zwolnienie',
                    addPopup: {
                        title: 'Wystawianie nowego zwolnienia lekarskiego',
                        addBtn: 'Wystaw zwolnienie',
                        codes: {
                            a: 'niezdolność do pracy powstała po przerwie nie przekraczającej 60 dni - spowodowana tą samą chorobą, która były przyczyną niezdolności do pracy przed przerwą',
                            b: 'niezdolność do pracy przypadająca w okresie ciąży',
                            c: 'niezdolność do pracy spowodowana nadużyciem alkoholu',
                            d: 'niezdolność do pracy spowodowana gruźlicą',
                            e: 'niezdolność do pracy spowodowana chorobą, która powstała nie później niż w ciągu 3 miesięcy od daty ustania tytułu ubezpieczenia chorobowego - w razie choroby zakaźnej, której okres wylęgania jest dłuższy niż 14 dni, lub innej choroby, której objawy chorobowe ujawniają się po okresie dłuższym niż 14 dni od dnia początku choroby'
                        },
                        insuredPersonTitle: 'Dane osoby ubezpieczonej, dla której wystawiane jest ZUS ZLA',
                        type: 'Powód niezdolności',
                        pesel: 'Pesel',
                        fetchData: 'Pobierz dane',
                        insuredIn: 'Miejsce ubezpieczenia',
                        change: 'Zmień',
                        payersTitle: 'Wybór płatników, dla których zostaną utworzone zaświadczenia',
                        sickLeavesTitle: 'Lista wcześniejszych ZUS ZLA osoby ubezpieczonej',
                        detailsTitle: 'Informacje o niezdolności do pracy',
                        inabilityFrom: 'Niezdolność od',
                        inabilityTo: 'Niezdolność do',
                        daysNumber: 'Liczba dni',
                        reason: 'Uzasadnienie',
                        careForData: 'Dane osoby pod opieką',
                        relationship: 'Pokrewieństwo',
                        relationship1: 'dziecko',
                        relationship2: 'małżonek, rodzice, ojczym, rodzic dziecka, dziadkowie, teściowie, wnuki, macocha',
                        relationship3: 'inna osoba',
                        letterCode: 'Kod literowy',
                        indication: 'Wskazanie lekarskie',
                        addressesTitle: 'Adres pobytu ubezpieczonego w czasie trwania niezdolności do pracy',
                        payerName: 'Nazwa',
                        payerNip: 'NIP',
                        hasPue: 'Czy posiada profil pue?',
                        address: 'Adres',
                        inabilityRange: 'Okres niezdolności',
                        disease: 'Choroba',
                        number: 'Seria i numer',
                        status: 'Status',
                        successToast: 'Zwolnienie lekarskie zostało wystawione',
                        addPayerTitle: 'Dodawanie nowego płatnika',
                        search: 'Szukaj',
                        payerNotFound: 'Nie znaleziono płatnika',
                        addNewPayer: 'Dodaj nowego płatnika',
                        confirmIssueSickLeave: 'Czy na pewno chcesz wystawić to zwolnienie lekarskie?',
                        addNewAddress: 'Dodaj nowy adres',
                        workPlace: 'Miejsce pracy lekarza',
                        icd10Note: 'Uwaga! Do ZUS zostanie przekazany kod główny rozpoznania'
                    },
                    cancelPopup: {
                        title: 'Anulowanie zwolnienia lekarskiego',
                        reason: 'Przyczyna anulowania',
                        confirmBtn: 'Anuluj zwolnienie',
                        confirmCancelSickLeave: 'Czy na pewno chcesz anulować to zwolnienie?',
                        successToast: 'Zwolnienie zostało anulowane'
                    }
                }
            },
            patientVisits: {
                nextVisit: {
                    title: 'Wizyty',
                    nextTitle: 'Kolejne wizyty',
                    changeDate: 'Zmień termin',
                    join: 'Rozpocznij wizytę',
                    forego: 'Odwołaj',
                    payFor: 'Opłać wizytę'
                },
                lastRecommendation: {
                    title: 'Zalecenia z ostatniej wizyty',
                    pdf: 'Pobierz podsumowanie',
                    drug: 'Lek',
                    dosages: 'Dawkowanie',
                    careLbl: 'Dbaj o siebie',
                    from: 'od',
                    to: 'do'
                },
                visitList: {
                    title: 'Archiwum wizyt',
                    subtitle: 'przeszłe wizyty',
                    pdf: 'Pobierz PDF',
                    date: 'Data',
                    type: 'Typ',
                    category: 'Kategoria',
                    doctor: 'Lekarz',
                    summary: 'Zalecenia',
                    invoice: 'Faktura'
                }
            },
            patient: {
                account: {
                    register: {
                        confirm: {
                            outcomeSuccessMsg: 'Rejestracja zakończona sukcesem',
                            outcomeSuccessDetMsg: 'Kliknij przycisk poniżej aby przejśc na stronę logowania',
                            outcomeFailureMsg: 'Wystąpił problem podczas próby potwierdzenia rejestracji',
                            outcomeFailureDetMsg: 'Prosimy spróbować jeszcze raz kliknąć w link przesłany w wiadomości email, lub skontaktować się z zespołem Neuroterminal',
                            saveBtn: 'Zaloguj się'
                        }
                    },
                    settings: {
                        title: 'Ustawienia',
                        saveLbl: 'Zapisz',
                        successMsg: 'Ustawienia zostały zapisane'
                    }
                },
                therapy: {
                    prescriptions: {
                        newLbl: 'nowe recepty',
                        archiveLbl: 'archiwum recept',
                        dateFromLbl: 'Wystawione od',
                        dateToLbl: 'Wystawione do',
                        noDataLbl: 'Brak recept',
                        previewLbl: 'Podgląd',
                        prescriptionLbl: 'Recepta',
                        headers: {
                            dateLbl: 'Data',
                            doctorLbl: 'Lekarz',
                            numberLbl: 'Kod recepty',
                            actionsLbl: 'Recepta',
                        },
                        dialog: {
                            downloadLbl: 'Pobierz',
                            closeLbl: 'Zamknij'
                        }
                    }
                }
            },
            doctor: {
                account: {
                    activation: {
                        title: 'Aktywacja konta w systemie',
                        alreadySent: 'Dokumenty zostały już wysłane',
                        alreadyFinished: 'Aktywacja konta została zakończona',
                        fillInFormMsg: 'Prosimy wypełnić poniższe dane i nacisnąć przycisk \'Wyślij\'',
                        formAgreementScanLbl: 'Skan umowy',
                        formInsuranceScanLbl: 'Skan polisy OC',
                        acceptTerms1: 'Akceptuję',
                        acceptTerms2: 'regulamin',
                        acceptPolicy1: 'Akceptuję',
                        acceptPolicy2: 'politykę prywatności',
                        sendLbl: 'Wyślij',
                        submitMsg1: 'Dziękujemy, weryfikujemy wysłane dane.',
                        submitMsg2: 'Potwierdzenie wyślemy w wiadomości email.',
                    },
                    settings: {
                        title: 'Ustawienia',
                        saveLbl: 'Zapisz',
                        successMsg: 'Ustawienia zostały zapisane'
                    }
                }
            },
            prescriptions: {
                home: {
                    titleLbl: 'E-recepty',
                    text1Lbl: 'Prośba o receptę',
                    text2Lbl: 'Brak próśb o wypisanie recepty'
                },
                list: {
                    currentLbl: 'Obecne',
                    archiveLbl: 'Archiwalne',
                    showLbl: 'Pokaż',
                    sortNewestLbl: 'Od najnowszych',
                    sortDeadlingLbl: 'Od najpilniejszych'
                },
                details: {
                    medHistLbl: 'Historia choroby',
                    pleadLbl: 'prosi o receptę',
                    peselLbl: 'Pesel',
                    copyPeselLbl: 'Kopiuj pesel',
                    checkEwusLbl: 'Sprawdź w EWUŚ',
                    addressLbl: 'Adres zamieszkania',
                    phoneLbl: 'Nr telefonu',
                    issueDateLbl: 'Data wystawienia poprzedniej recepty',
                    viewLbl: 'Podgląd recepty',
                    drugsLbl: 'Zamówione leki',
                    commentLbl: 'Komentarz dla lekarza',
                    openLbl: 'Otwórz kreator e-recepty',
                    denyInfoLbl: 'W przypadku, gdy uznasz prośbę pacjenta za bezzasadną możesz odmówić wystawienia e-recepty',
                    denyLbl: 'Odmów',
                    prescriptionLbl: 'Recepta',
                    confirmInfo1Lbl: 'Odrzucenie prośby o receptę',
                    confirmInfo2Lbl: 'Czy na pewno chcesz odrzucić prośbe o receptę?',
                    confirmYesLl: 'Tak, odrzucam',
                    confirmNoLbl: 'Nie',
                    rejectInfoLbl: 'Odrzucono prośbę o receptę',
                    forwardInfoLbl: 'Informacja została przesłana recepcji',
                    rejectBtn: 'Odrzuć prośbę',
                    rejectDlgTitle: 'Dlaczego chcesz odmówić wystawienia recepty?',
                    rejectReasonPlaceholder: 'Przyczyna odmowy',
                    forwardPrescription: "Jeśli nie możesz wystawić pacjentowi e-recepty i chcesz aby sprawą zajęła się recepcja",
                    forwardBtn: "Odmów e-recepty",
                    forwardDlgTitle: "Wpisz notatkę, która zostanie wysłana do recepcji (admina)",
                    rejectCommentDisclaimer: "Powód odmowy zostanie wysłany do pacjenta",
                    characterCount: "Ilość znaków:",
                    infoForAdmin: "Lekarz odmówił wystawienia e-recepty:"
                },
                creator: {
                    typeLbl: 'Wystawianie e-recepty przez',
                    returnLbl: 'Powrót',
                    peselLbl: 'Pesel',
                    copyPeselLbl: 'Kopiuj pesel',
                    checkEwusLbl: 'Sprawdź w EWUŚ',
                    issueDateLbl: 'Data wystawienia poprzedniej recepty',
                    viewLbl: 'Podgląd recepty',
                    drugsLbl: 'Zamówione leki',
                    commentLbl: 'Komentarz dla lekarza',
                    addProductLbl: 'Dodaj kolejny lek',
                    govText1Lb: 'Przejdź do strony',
                    govText2Lb: 'i wystaw receptę',
                    pinLbl: 'Wpisz 4-cyfrowy pin recepty',
                    fileLbl: 'Plik PDF z receptą (opcjonalnie)',
                    fileChooseLbl: 'Kliknij, aby wybrać plik PDF z receptą',
                    issueLbl: 'Wystaw e-receptę',
                    prescriptionLbl: 'Recepta',
                    notValidMsg: 'Formularz recepty jest niepoprawnie wypełniony',
                    successMsg: 'Recepta została wystawiona',
                    prescriptionList: "Wystawiono recepty o następujących kodach"
                },
                product: {
                    drugLbl: 'Lek',
                    removeLbl: 'Usuń',
                    packageLbl: 'Opakowanie',
                    refundLbl: 'Refundacja',
                    packNumLbl: 'Liczba opakowań',
                    dosageLbl: 'Obecne dawkowanie',
                    refundCauseLbl: 'Płatność',
                    recieptType: 'Typ recepty',
                    permissions: 'Uprawnienie pacjenta',
                    description: 'Opis słowny',
                    dosage: 'Dawka'
                },
                ewus: {
                    checkingLbl: 'Trwa sprawdzanie numeru pesel w EWUŚ',
                    notFoundLbl: 'Nie odnaleziono pacjenta w EWUŚ',
                    insuredLbl: 'Pacjent jest ubezpieczony',
                    notInsuredLbl: 'Pacjent jest nieubezpieczony',
                    closeLbl: 'Zamknij'
                },
                doctorPass: {
                    titleLbl: 'Hasło do certyfikatu ZUS',
                    cancelLbl: 'Anuluj',
                    confirmLbl: 'Zatwierdź'
                }
            },
            eReferrals: {
                creator: {
                    title: 'Wystawianie nowego e-skierowania',
                    type: 'Typ skierowania',
                    destination: 'Poradnia / laboratorium / oddział szpitalny',
                    icd10: 'Rozpoznanie',
                    selectedIcd9: 'Wybrane procedury medyczne',
                    selectIcd9: 'Wybierz przynajmniej jedną procedurę medyczną',
                    icd9: 'Procedura medyczna',
                    comment: 'Komentarz lekarza',
                    issueReferral: 'Wystaw e-skierowanie',
                    icdNoResults: 'Brak wyników wyszukiwania',
                    successMsg: 'E-skierowanie zostało wystawione',
                    issueDate: 'Data wystawienia',
                    noVisitError: 'Nie można utworzyć wizyty dla skierowania'
                }
            },
            header: {
                labelP1: "Portal",
                labelP2: "Pacjenta"
            },
            eegExamination: {
                header: {
                    sex: {
                        MALE: 'Mężczyzna',
                        FEMALE: 'Kobieta',
                    }
                },
                custom: {
                    noSerizure: "Nie zarejestrowano napadu padaczkowego"
                },
                stages: {
                    indicationAndType: "Wskazanie i rodzaj badania",
                    baseActivity: "Czynność podstawowa",
                    artifacts: "Artefakty",
                    variants: "Warianty",
                    sleep: "Sen",
                    interictalChanges: "Zmiany międzynapadowe",
                    seizureChanges: "Zmiany napadowe",
                    semiology: "Semiologia",
                    clinicalMeaning: "Znaczenie kliniczne"
                },
                indicationAndType: {
                    indication: "Wskazanie do badania",
                    indicationValues: {
                        EPILEPSY: "Padaczka",
                        DIFFERENTIAL_DIAGNOSIS: "Diagnostyka różnicowa",
                        PEDIATRIC_RECOMMENDATION: "Specyficzne wskazania pediatryczne",
                        OTHER: "Inne"
                    },
                    type: "Rodzaj badania EEG",
                    state: "Stan",
                    activationMethods: "Metody aktywacyjne"
                },
                baseActivity: {
                    frequency: "Częstotliwość",
                    symmetry: "Symetria częstotliwości",
                    amplitude: "Amplituda",
                    amplitudeSymmetry: "Symetria amplitudy",
                    reactivity: "Reaktywność",
                    rhythmOrganization: "Organizacja rytmu",
                    pdr: "PDR (posterior dominant rhythm)",
                    pdrValues: {
                        ABSENT: "nieobecny",
                        PRESENT: "obecny"
                    },
                    baseActivityMeaning: "Znaczenie"
                },
                artifacts: {
                    artifacts: "Artefakty",
                    artifactsMeaning: "Znaczenie",
                    nonBiologicalArtifacts: "Artefakty nie biologiczne",
                    nonBiologicalArtifactsMeaning: "Znaczenie"
                },
                variants: {
                    physiologicalVariants: "Warianty fizjologiczne",
                    mildVariants: "Warianty łagodne"
                },
                sleep: {
                    sleep: "Sen",
                    sleepMeaning: "Znaczenie"
                },
                interictalChanges: {
                    interictalActivity: "Padaczkowa czynność międzynapadowa",
                    interictalLateralization: "Lateralizacja",
                    interictalRegion: "Okolica",
                    interictalElectrodes: "Elektrody",
                    incorrectInterictalActivity: "Nieprawidłowa rytmiczna czynność międzynapadowa",
                    incorrectInterictalLateralization: "Lateralizacja",
                    incorrectInterictalRegion: "Okolica",
                    incorrectInterictalElectrodes: "Elektrody",
                    specialPattern: "Specjalne wzorce",
                    patternLateralization: "Lateralizacja",
                    patternRegion: "Okolica",
                    patternElectrodes: "Elektrody"
                },
                seizureChanges: {
                    seizuresCount: "Ile typów napadów zarejestrowano?",
                    seizureDescription: "Opis napadu",
                    seizureChanges: "Zmiany napadowe",
                    seizureLateralization: "Lateralizacja",
                    seizureRegion: "Okolica",
                    seizureElectrodes: "Elektrody",
                    episodeCount: "Liczba epizodów",
                    episodeDuration: "Czas trwania napadu",
                    afterPhaseDuration: "Czas trwania fazy ponapadowej"
                },
                semiology: {
                    consciousEpisode: "Świadomość",
                    startSeizure: "Początek",
                    timeCorrelation: "Zależność czasowa kliniczna- EEG",
                    paroxysmal: "Napadowa",
                    paroxysmalValues: {
                        NONE: "brak widocznej manifestacji",
                        SENSORY: "czuciowa"
                    },
                    postSeizure: "Ponapadowo",
                    lateralizingSymptom: "Objawy lateralizacyjne"
                },
                clinicalMeaning: {
                    clinicalMeaning: "Znaczenie kliniczne",
                    clinicalMeaningValues: {
                        CORRECT_RECORD: "zapis prawidłowy",
                        INCORRECT_RECORD: "zapis nieprawidłowy",
                        UNCERTAIN_RECORD: "nieprawidłowe EEG o niepewnym znaczeniu klinicznym"
                    },
                    opinion: "Orzeczenie"
                },
                incompleteFormError: "Uzupełnij brakujące pola",
                documentGenerated: "Dokument został utworzony",
                saveDocumentError: "Wystąpił błąd przy zapisie dokumentu",
                partialSaveError: "Wystąpił błąd przy zapisie formularza",
                selectTemplateHeader: "Wybierz jaki typ formularza opisu badania eeg chcesz wybrać",
                emptyForm: "Formularz nie uzupełniony",
                correctResultTemplat: "Formularz uzupełniony - opis prawidłowy"
            },
            epicrisis: {
                treatmentStart: "Pacjent pod opieką Neurosphery od",
                ictalDiagnosis: "Rozpoznanie ICTAL",
                mainDiagnosis: "Rozpoznanie ICD10",
                additionalDiagnosis: "Rozpoznania dodatkowe ICD10",
                note0Header: "Notatka 0",
                note0Label: "Wstępne obserwacje pacjenta - wypełniane na Ankiecie 0 lub na pierwszej wizycie pacjenta w Neurosphera",
                epicrisisEntryHeader: "Wpisy do epikryzy",
                epicrisisEntryLabel: "Jest to miejsce z ważnymi informacjami na temat choroby pacjenta",
                addEntryLabel: "Dodaj wpis",
                entrySavedMsg: "Wpis został zapisany",
                createdBy: "Utworzona przez",
                addedToObservation: "Wpis został dodany do obserwacji",
                migration: "migracyjna",
                confirmDeletion: "Czy na pewno chcesz usunąć wpis?"
            }
        },

        component: {
            consultation: {
                diagnosisSelection: {
                    searchIcd10: 'Wyszukaj rozpoznanie',
                    searchIcd10NoResult: 'Brak wyników wyszukiwania',
                    empty: 'Lista rozpoznań pacjenta jest pusta',
                    main: 'Główne',
                    markAsMain: 'Oznacz jako główne',
                    icd10: 'ICD 10'
                },
                seizureDiagnosisSelection: {
                    first: 'Pierwszy typ napadów',
                    second: 'Drugi typ napadów',
                    third: 'Trzeci typ napadów',
                },
                drugDosageDialog: {
                    standardTimes: {
                        morning: 'Rano',
                        noon: 'W południe',
                        evening: 'Wieczorem'
                    },
                    standardDose: {
                        morning: 'Dawka rano',
                        noon: 'Dawka w południe',
                        evening: 'Dawka wieczorem'
                    },
                    addCustomDose: 'Dodaj dawkę o ustalonej porze',
                    cancel: 'Anuluj',
                    save: 'Zapisz'
                },
                drugDosageEdition: {
                    drugs: 'Leki',
                    stage: 'Etap',
                    searchDrug: 'Dodaj lek',
                    searchDrugNoResult: 'Brak wyników wyszukiwania',
                    dosage: 'Wybierz dawkowanie',
                    cancelTherapy: 'anuluj',
                    saveTherapy: 'zatwierdź terapię',
                    addTherapy: 'dodaj kolejny etap',
                    previousEndDateError: 'Poprzednia terapia lekowa nie ma daty zakończenia',
                    previousEndDateBeforeCurrentError: 'Należy podać przyszła datę zakończenia terapii',
                    activeSubstanceSearch: 'Wyszukiwanie leków z substancją aktywną',
                    removeManyTitle: 'Czy automatycznie usunąć lek z kolejnych terapii?',
                    addManyTitle: 'Czy automatycznie dodać lek do kolejnych terapii?',
                    yesSameDosage: 'Tak, z tym samym dawkowaniem',
                    yesDifferentDosage: 'Tak, ale z innym dawkowaniem',
                    refreshOrder: 'Posortuj'
                },
                drugTherapySummary: {
                    current: 'Aktualna terapia lekowa',
                    future: 'Przyszła terapia lekowa',
                    past: 'Przeszła terapia lekowa',
                    from: 'Od',
                    to: 'do'
                },
                drugTherapyHistory: {
                    title: 'Edycja historii terapii lekowych',
                    changeHistory: 'Terapia w przeszłości',
                    noAccess: 'Nie masz dostępu do edycji historii terapii tego pacjenta',
                    updateSuccess: 'Historia terapii lekowych została zapisana'
                },
                medicalHistory: {
                    chart: {
                        loadingLbl: 'Ładowanie danych',
                        seizuresLbl: 'Napady',
                        drugsLbl: 'Leki',
                        resultsLbl: 'Badania',
                        visitsLbl: 'Wizyty',
                        eventsLbl: 'Zdarzenia',
                        consultationLbl: 'Konsultacja',
                        seizuresNumberLbl: 'liczba',
                        seizuresDurationLbl: 'Czas trwania',
                        trendBtn: 'Linia trendu',
                        disabledLbl: 'Teraz niedostępne',
                        trendBetterLbl: 'Poprawa trendu',
                        trendWorseLbl: 'Pogorszenie trendu',
                        rangeLbl: 'Zakres danych'
                    }
                },
                patientSummary: {
                    personalInfo:{
                        personalData: 'Dane osobowe'
                    }
                }
            },
            doctor: {
                registration: {
                    form: {
                        firstName: 'Imię',
                        lastName: 'Nazwisko',
                        pesel: 'Pesel',
                        pwz: 'Numer PWZ',
                        email: 'Adres e-mail',
                        password: 'Hasło',
                        passwordConfirm: 'Powtórz hasło',
                        phone: 'Telefon',
                        diploma: 'Dodaj skan dyplomu',
                        isSpecialization: 'Posiadam specjalizację z neurologii',
                        isPractise: 'Prowadzę indywidualną praktykę lekarską',
                        practiceInfoName: 'Nazwa podmiotu',
                        practiceInfoNipNr: 'NIP',
                        practiceInfoRpwdlNr: 'Numer RPWDL',
                        practiceInfoRegonNr: 'REGON',
                        practiceAddressStreet: 'Ulica',
                        practiceAddressHouseAndApartmentNr: 'Nr domu / mieszkania',
                        practiceAddressHouseNr: 'Nr domu',
                        practiceAddressApartmentNr: 'Nr lokalu',
                        practiceAddressZipcode: 'Kod pocztowy',
                        practiceAddressCity: 'Miejscowość',
                        practiceAddressCountry: 'Kraj',
                        isApproveDataProcessing: 'Zgadzam się na przetwarzanie moich danych',
                        send: 'Wyślij zgłoszenie',
                        response1: 'Dziękujemy za zgłoszenie',
                        response2: 'Skontaktujemy się przez wiadomość e-mail',
                        gotAccountMsg1: 'Masz już konto?',
                        gotAccountMsg2: 'Zaloguj się'
                    }
                },
                database: {
                    doctor: {
                        list: {
                            noMedicalHistoryMsg: 'Nie wypełniono jeszcze ankiety zero. Prosimy o wypełnienie jej na tyle, na ile to możliwe przed wyborem lekarza prowadzącego.',
                            gotoMedicalHistoryLbl: 'Przejdź do ankiety',
                            titleLbl: 'Baza lekarzy',
                            titleDescLbl: 'Jeśli chcesz zmienić swojego lekarza opiekuna, kliknij przycisk „Wybierz” w profilu wybranego przez Ciebie lekarza, a następnie wypełnij krótką ankietę.',
                            subtitleLbl: 'Lekarze',
                            patientTypeLbL: 'Prowadzeni pacjenci',
                            sortTypeLbL: 'Sortuj od',
                            noResultsLbL: 'Nie znaleziono lekarzy pasujących do wybranych parametrów',
                            sortTypes: {
                                patientsAsc: 'Najmniejszej liczby pacjentów',
                                patientsDesc: 'Największej liczby pacjentów',
                                alphabeticAsc: 'Nazwisko alfabetycznie rosnąco',
                                alphabeticDesc: 'Nazwisko alfabetycznie malejąco',
                                remotePriceAsc: 'Ceny wizyty zdalnej rosnąco',
                                remotePriceDesc: 'Ceny wizyty zdalnej malejąco',
                                stationaryPriceAsc: 'Ceny wizyty stacjonarnej rosnąco',
                                stationaryPriceDesc: 'Ceny wizyty stacjonarnej malejąco'
                            },
                            content: {
                                juniorExpLbl: 'Młodszy ekspert',
                                standardExpLbl: 'Ekspert',
                                seniorExpLbl: 'Starszy ekspert',
                                leadLbL: 'lekarz prowadzący',
                                bioLbl: 'BIO',
                                pricesLbl: 'CENNIK',
                                servicesLbl: 'ZAKRES USLUG',
                                remoteLbl: 'Wizyta zdalna',
                                stationaryLbl: 'Wizyta stacjonarna',
                                messageLbl: 'Wiadomość',
                                prescriptionLbl: 'Recepta'
                            }
                        },
                        selection: {
                            request: {
                                alert: {
                                    youHaveTimeTo: 'Masz czas do',
                                    toReplyTo: 'na odpowiedź na prośbę o objęcie opieką od pacjenta',
                                    detailsLbl: 'Szczegóły',
                                    closeLbl: 'Zamknij'
                                }
                            }
                        }
                    },
                    lead: {
                        titleLbl: 'Lekarz prowadzący',
                        patientsLbl: 'Pacjenci',
                        visitsLbl: 'Wizyty',
                        firstVisitLbl: 'Pierwsza',
                        subsequentVisitLbl: 'Kolejna',
                        bioLbl: 'Bio',
                        bookVisitLbl: 'Umów wizyte',

                        doctor: {
                            change: {
                                request: {
                                    dialog: {
                                        title1: 'Potwierdź wybór lekarza prowadzącego',
                                        title2: 'Czy na pewno chcesz zmienić swojego lekarza prowadzącego?',
                                        selectionConfirmMsg: 'Czy na pewno chcesz wybrać tego lekarza?',
                                        historyCommentLbl: 'Komentarz dotyczący Twojej historii choroby',
                                        reasonLbl: 'Dlaczego zdecydowałaś/leś się na zmianę?',
                                        reasonCommentLbl: 'Przyczyna zmiany',
                                        evaluationLbl: 'Jak oceniasz swojego obecnego lekarza?',
                                        overallCommentLbl: 'Uwagi dotyczące współpracy z lekarzem',
                                        overallCommentInfo: 'Pozwoli nam to kontrolować jakoś świadczonych usług w podstawowym stopniu i usuwać z systemu lekarzy od których jest duży odpływ po zweryfikowaniu przyczyny.',
                                        confirmLbl: 'Zatwierdź wybór lekarza prowadzącego',
                                        cancelLbl: 'Anuluj',
                                        reasons: {
                                            punctuality: 'Niepunktualność lekarza',
                                            result: 'Brak postępów w leczeniu',
                                            communication: 'Problemy w komunikacji',
                                            other: 'Inne'
                                        },
                                        evaluations: {
                                            vgood: 'bardzo dobrze',
                                            good: 'dobrze',
                                            average: 'umiarkowanie',
                                            bad: 'źle'
                                        },
                                        confirmationLbl: 'Wysłano powiadomienie do lekarza',
                                        closeLbL: 'Zamknij'
                                    }
                                },
                                response: {
                                    dialog: {
                                        title: 'Czy zgadzasz się objąć opieką pacjenta?',
                                        firstSeizureLbl: 'Data pierwszego zarejestrowanego napadu',
                                        epilepsyDescLbl: 'Typ padaczki/napadów',
                                        drugsUsedLbl: 'Dotychczas stosowane leki',
                                        patientCommentLbl: 'Komentarz pacjenta',
                                        acceptLbl: 'Zgadzam się',
                                        rejectLbl: 'Nie zgadzam się',
                                        rejectReasonLbl: 'Przyczyna odmowy',
                                        confirmLbl: 'Zatwierdź',
                                        cancelLbl: 'Anuluj',
                                        rejection: {
                                            reasons: {
                                                full: 'Brak miejsc',
                                                seizure: 'Typ padaczki niezgodny ze specjalizacją',
                                                age: 'Niezgodny profil wiekowy',
                                                other: 'Inne'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            patient: {
                registration: {
                    form: {
                        email: 'Adres e-mail',
                        password: 'Hasło',
                        mandatoryDescription: 'Pola oznaczone * są obowiązkowe',
                        passwordConfirm: 'Powtórz hasło',
                        isAgreeTerms1: 'Przeczytałem i akceptuję',
                        isAgreeTerms2: 'regulamin',
                        isAgreeTerms3: 'korzystania z usług.',
                        isApprove1: 'Zapoznałem się z zasadami przetwarzania moich danych osobowych określonymi w',
                        isApprove2: 'Polityce prywatności Neurosphera sp. z o.o.',
                        isApprove3: 'oraz',
                        isApprove4: 'Polityce prywatności Neuroterminal S.A.',
                        marketing1and2: 'Wyrażam zgodę na kierowanie do mnie komunikacji marketingowej (w tym informacji handlowej), której celem jest promocja usług oraz towarów, w tym komunikacji realizowanej przy wykorzystaniu moich danych osobowych za pomocą urządzeń telekomunikacyjnych w zakresie adresu e-mail (otrzymywanie wiadomości e-mail) oraz w zakresie numer telefonu (otrzymywanie wiadomości sms oraz połączeń przychodzących).',
                        marketing3: 'Chcę otrzymywać na podany adres e-mail newsletter od Neuroterminal S.A. w imieniu Neurosphera sp. z o.o.',
                        send: 'Zarejestruj się',
                        response1: 'Dziękujemy za rejestrację w Neuroterminalu, portalu pacjenta Neurosphery',
                        response2: 'Sprawdź swoją pocztę e-mail, potwierdź rejestrację klikając w otrzymany link aktywacyjny!',
                        gotoLoginLbl: 'Zaloguj się do systemu',
                        gotAccountMsg1: 'Masz już konto?',
                        gotAccountMsg2: 'Zaloguj się',
                        consentsTitle: 'Akceptacja regulaminów i zgód marketingowych',
                        consentsAccept: 'Zaakceptuj zaznaczone'
                    }, confirmation: {
                        header: 'Potwierdź rejestrację!',
                        content1: 'Wysłaliśmy link aktywacyjny na adres:',
                        content2: 'Sprawdź skrzynkę e-mail i kliknij w link aktywacyjny',
                        sendAgainPrefix: "Nie otrzymałeś maila:",
                        sendAgain: "Wyślij ponownie!",
                        contactReception: "lub skontaktuj się z recepcją:",
                        resendBlocked: "W przypadku nieotrzymania wiadomości z linkiem rejestracyjnym prosimy o kontakt z biurem obsługi klientów."
                    }
                },
                documentation: {
                    document: {
                        add: {
                            title: 'Dodaj dokument',
                            nameLbL: 'Nazwa',
                            typeLbl: 'Typ',
                            resultDateLbl: 'Wystawiono',
                            confirmLbl: 'Załącz',
                            filesToLargeLbl: 'Niektóre pliki były zbyt duże aby je dodać',
                            filesEmptyContentLbl: 'Niektóre pliki nie mają treści lub są uszkodzone',
                            wrongFileType: 'Wybrano niepoprawny typ pliku. Dopuszczalne są pliki graficzne, wideo oraz dokumenty pdf',
                            unsavedFormWarningMsg1: 'Formularz zawiera niezapisane dane',
                            unsavedFormWarningMsg2: 'Czy chcesz zapisać dane przed opuszczeniem ekranu?',
                            yesLbl: 'Tak',
                            noLbl: 'Nie',
                            uploadingMsg1: 'Trwa przesyłanie pliku..',
                            uploadingMsg1Templated: 'Trwa przesyłanie pliku {filename}',
                            uploadingMsg2: 'W przypadku większych plików może to chwilę potrwać'
                        },
                        confirm: {
                            title: 'Czy na pewno usunąć dokument?',
                            cancelLbl: 'Anuluj',
                            confirmLbl: 'Zatwierdź',
                            successMsg: 'Usunięto dokument'
                        },
                        edit: {
                            title: 'Edycja dokumentu',
                            nameLbL: 'Nazwa',
                            typeLbl: 'Typ',
                            resultDateLbl: 'Data wystawienia',
                            fileNameLbL: 'Nazwa pliku',
                            cancelLbl: 'Anuluj',
                            confirmLbl: 'Zatwierdź',
                            changedMsg: 'Zapiano zmiany w dokumencie'
                        },
                        viewer: {
                            closeLbl: 'Zamknij',
                            typeNotSupported: 'Ten typ pliku nie jest obsługiwany'
                        },
                        files: {
                            selection: {
                                title1: 'Przeciągnij i upuść',
                                title2: 'tu pliki, które chcesz dodać',
                                title3: 'lub',
                                title4: 'wybierz pliki',
                                title5: 'z komputera'
                            }
                        },
                        list: {
                            title1: 'Dokumentacja medyczna',
                            title2: 'Tutaj możesz przechowywać swoją dokumentację medyczną.',
                            title3: 'Dodając wyniki badań pomagasz lekarzowi postawić właściwą diagnozę i zwiększasz szansę na na wyleczenie.',
                            noDataLbl: 'Brak danych',
                            noResultsLbl: 'Brak danych',
                            loadingLbl: 'Ładowanie dokumentacji',
                            downloadLbl: 'Pobierz',
                            showLbl: 'Pokaż',
                            editLbl: 'Edytuj',
                            removeLbl: 'Usuń',
                            markCompleted: 'Oznacz jako gotowy',
                            markCompletedDescription: 'Jeśli oznaczysz opis jako gotowy, zostanie on wysłany przez recepcje do pacjenta. Jeśli chcesz możesz dodać opcjonalnie tekst dla recepcji',
                            isCompleted: 'Opis gotowy',
                            headers: {
                                type: 'Typ',
                                name: 'Nazwa',
                                fileName: 'Plik',
                                resultDate: 'Wystawiono',
                                additionDate: 'Dodano'
                            },
                            filters: {
                                documentTypeLbl: 'Pokaż',
                                documentTypeAllLbl: 'Pokaż wszystko',
                                resultDateFromLbl: 'Wystawiono od',
                                resultDateToLbl: 'Wystawiono do'
                            },
                            epicrisis: {
                                addingFile: "Załaczany plik",
                                addToEpicrisis: "Dodaj do epikryzy",
                                add: "Dodaj",
                                cancel: "Anuluj",
                                addFileToEpicrisis: "Dodaj plik do epikryzy",
                                description: "Opis"
                            }
                        },
                        disk: {
                            space: {
                                filledLbl: 'Zapełniono {filled} GB z dostępnych {available} GB'
                            }
                        }
                    }
                },
                nearest: {
                    visits: {
                        forthcomingLbl: 'nadchodzące wizyty',
                        forthcomingConsultLbl: '(konsultacje)',
                        noVisitMsg: 'Brak nadchodzących wizyt.',
                        noVisitZeroMsg: 'Brak nadchodzących wizyt. Umów wizytę zero.',
                        noVisitZeroDisclaimerMsg: 'Podczas wizyty zero lekarz przeanalizuje Twoją historię choroby i ustali plan opieki.',
                        sosVisitLbl: 'SOS',
                        bookVisitLbl: 'Umów wizytę',
                        cannotBookVisitMsg: "Nie możesz umówić wizyty ze względu na brak wypełnionych wszystkich pól w sekcji 'Moje dane'",
                        editLbl: 'Edytuj',
                        cancelLbl: 'Usuń',
                        payLbl: 'Opłać',
                        absentConsultation: 'Konsultacja zaoczna - bez udziału pacjenta',
                        dialog: {
                            paymentPending: 'Płatność za wizytę oczekuje na potwierdzenie',
                            paymentError: 'Płatność za wizytę nie powiodła się',
                            payTill1: 'W przypadku nieopłacenia wizyty do',
                            payTill2: 'wizyta zostanie anulowana.',
                            pay: 'Zapłać za wizytę'
                        }
                    }
                },
                treatment: {
                    plan: {
                        titleLbl: 'plan opieki',
                        inaccessibleLbl: 'niedostępny',
                        noVisitZeroDisclaimerMsg: 'Plan opieki wybierzesz podczas wizyty zero.'
                    },
                    prescription: {
                        request: {
                            stockLbl: 'Zapas na {days} dni',
                            otherQuestLbl: 'Potrzebujesz innego leku?',
                            otherDrugLbl: 'Wpisz nazwę leku w komentarzu. Pamiętaj, by podać dokładną nazwę leku.',
                            confirmTitleLbl: 'Potwierdzenie',
                            confirmTextLbl: 'Zostaniesz przeniesiony na strone płatności elektronicznych. Kontynuowac?',
                            cancelBtn: 'Anuluj',
                            confirmBtn: 'Zapłać',
                            chooseDoctor: 'Wybierz lekarza, którego chcesz poprosić o receptę',
                            askForPrescriptionNotAvailable: 'Wysłanie prośby o receptę będzie możliwe po odbyciu wizyty u lekarza wystawiającego recepty',
                            noLeadDoctorAvailable: 'Twój lekarz opiekun aktualnie jest niedostępny. Prośbę o receptę możesz wysłać do lekarza dyżurnego.'
                        }
                    }
                },
                lead: {
                    doctor: {
                        titleLbl: 'lekarz opiekun',
                        noVisitZeroDisclaimerMsg1: 'Lekarza prowadzącego',
                        noVisitZeroDisclaimerMsg2: 'wybierzesz podczas wizyty zero',
                        changeLbl: 'Zmień',
                        prescriptLbl: 'Poproś o receptę',
                        messageLbl: 'Napisz wiadomość',
                        servicesLbl: 'Zakres usług',
                        services: {
                            interview: 'wywiad',
                            therapy: 'ustalenie terapii',
                            commission: 'wydanie zleceń',
                            prescription: 'wystawienie recepty',
                            documents: 'wystawienie skierowania, zaświadczenia, zwolnienia lekarskiego'
                        },
                        scope: {
                            bullet1: 'Pierwszy kontakt medyczny Pacjenta',
                            bullet2: 'Kompleksowy opiekun pacjenta, zlecający dodatkowe badania lub ewentualnie kierujący do specjalistów konsultantów',
                            bullet3: 'Doradca, stanowiący bezpośrednie wsparcie Pacjenta oraz nadzorujący plan terapeutyczny',
                            bullet4: 'Wspierany przez cały zespół, robota iCtal® oraz system nadzoru i automatyzacji działań medycznych'
                        }
                    }
                },
                hashtags: {
                    title: 'Tagi',
                    subtitle: 'Jest to miejsce, w którym możemy oznaczyć pacjenta zdefiniowanymi nazwami',
                    label: 'Wyszukaj hashtag'
                }
            },
            finances: {
                payment: {
                    history: {
                        title: 'Historia rozliczeń',
                        headers: {
                            month: 'Miesiąc',
                            sum: 'Suma'
                        }
                    }
                },
                invoice: {
                    summary: {
                        text1: 'Podsumowanie finansowe',
                        text2: 'Znajdziesz w nim dane niezbędne do wystawienia faktury',
                        text3: 'Masz pytania odnośnie faktur?',
                        text4: 'Skontaktuj się z naszym działem finansowym',
                        btn_lbl: 'Pobierz podsumowanie'
                    }
                },
                general: {
                    summary: {
                        title: 'Finanse',
                        return: 'powrót',
                        table: {
                            headers: {
                                label: 'Typ wizyty',
                                price: 'Stawka',
                                amount: 'Ilość',
                                sum: 'Suma'
                            },
                            summary: 'Razem'
                        }
                    }
                }
            },
            genericDialog: {
                approve: 'Tak',
                cancel: 'Nie',
                close: 'Zamknij',
            },
            videochat: {
                waitingForPatient: 'Trwa oczekiwanie na podłączenie się pacjenta',
                waitingForDoctor: 'Trwa oczekiwanie na podłączenie się lekarza',
                connectionError: 'Wystąpił błąd przy nawiązywaniu połączenia',
                visitEnded: 'Wizyta online została zakończona.',
                deviceLoadError: 'Błąd wczytywania urządeń',
                accessDenied: 'Proszę o udzielenie dostępu do mikrofonu i kamery w przeglądarce i w razie konieczności odświeżenie strony',
                accessDeniedAlert: 'Brak dostępu do mikrofonu i kamery',
                missingAudioInput: 'Nie wykryto mikrofonu',
                missingVideoInput: 'Nie wykryto kamery',
                accessGrantedBut: 'Uzyskano uprawnienia, lecz: ',
                cannotConnectCamera: 'Nie można połączyć się z kamerą',
                cannotConnectMicrophone: 'Nie można połączyć się z mikrofonem',
                deviceAlreadyInUse: 'Proszę upewnić się, że urządzenie nie jest już używane, a następnie spróbować ponownie',
                chooseDeviceDialog: {
                    chooseDevices: 'Wybierz urządzenia',
                    visitStartMsg1: 'Wizyta rozpocznie się o',
                    visitStartMsg2: 'W celu prawidłowego przebiegu wybierz urządzenia audio i video z jakich chcesz korzystać',
                    camera: 'Kamera',
                    microphone: 'Mikrofon',
                    speakers: 'Głośniki',
                },
                dialog: {
                    confirm: 'Zatwierdź'
                },
                tooltip: {
                    disableAudio: 'Wyłącz dzwięk',
                    enableAudio: 'Włącz dzwięk',
                    disableVideo: 'Wyłącz wideo',
                    enableVideo: 'Włącz wideo',
                    terminate: 'Zakończ wizytę'
                },
                confirm: {
                    warningMsg1: 'Wizyta w toku',
                    warningMsg2: 'Czy chcesz zakończyć telekonferencję podczas trwającej wizyty?'
                }
            },
            updateMedicalEventDialog: {
                title: 'Edytowanie zdarzenia',
                changesSaved: 'Zdarzenie zostało zmienione',
            },
            addMedicalEventDialog: {
                title: 'Dodawanie zdarzenia',
                descriptionPlaceholder: 'Podaj krótki opis zdarzenia',
                patientSeizureTypePlaceholder: 'Typ napadu',
                from: 'Od',
                to: 'Do',
                duration: 'Czas trwania',
                cancel: 'Anuluj',
                approve: 'Zatwierdź',
                addNext: 'Dodaj kolejny',
                delete: 'Usuń',
                addSuccessMsg: 'Zdarzenie zostało dodane',
                noSeizureTypes: 'Aby dodać nowy napad musisz zdefiniować typy napadów w ankiecie zdrowia',
                goToHealthSurvey: 'Przejdź do ankiety zero',
                serialSeizures: 'Napady seryjne',
                seizuresCount: 'Liczba napadów',
            },
            user: {
                settings: {
                    sms: {
                        title: 'Powiadomienia SMS'
                    },
                    other: {
                        title: 'Pozostałe'
                    }
                }
            },
            visitChat: {
                title: 'Czat wizyty',
                loading: 'Trwa wczytywanie wiadomości...',
                emptyList: 'Lista wiadomości jest pusta',
                inputLabel: 'Napisz wiadomość'
            },
            payu: {
                banner: {
                    installmentPaymentAvailable: 'Tutaj zapłacisz w ratach',
                    startsFrom: 'już od',
                    decisionIn: 'decyzja nawet',
                    decisionInSeconds: '{val} sekund',
                    checkConditions: 'Sprawdź warunki'
                }
            },
            maintenance: {
                newAddress: '<b>Uwaga!</b> Zmieniamy adres oddziału w Warszawie. Nasz nowy adres <b>ul. Taneczna 30, 02-829 Warszawa (Ursynów)</b>'
            }
        },


        /******** custom ui translations *********/

        ui: {
            help: 'Pomoc',
            policy: 'Polityka prywatności',
            logout: 'Wyloguj',
            navigation: {
                home: 'Strona główna',
                account: {
                    name: 'O mnie',
                    personalData: 'Moje dane',
                    medicalDocuments: 'Dokumentacja medyczna',
                    medicalHistory: 'Ankieta zero',
                },
                messages: 'Wiadomości',
                therapy: 'Terapia',
                visits: {
                    name: 'Wizyty',
                    archive: 'Archiwum wizyt',
                    doctorDatabase: 'Baza lekarzy'
                },
                myVisits: 'Moje wizyty',
                patients: 'Pacjenci',
                ediagnosis: 'IctalLab',
                calendar: 'Kalendarz',
                workplan: 'Plan pracy',
                finances: 'Finanse',
                visitsAndDuties: 'Wizyty i dyżury',
                ehealth: {
                    name: 'E-zdrowie',
                    prescriptions: 'E-recepty',
                    referrals: 'E-skierowania'
                },
                settings: {
                    name: 'Ustawienia',
                    notifications: 'Powiadomienia',
                    regulations: 'Regulamin',
                    payments: 'Płatności'
                },
                documentation: 'Dokumentacja',
                examinations: 'Badania',
                doctors: {
                    workplans: 'Grafiki pracy'
                }
            },
            closeDialog: 'Zamknij',
            visitSummary: 'podsumowanie_wizyty',
            invoice: 'faktura',
            correctionInvoice: 'faktura_korygująca',
            ieAlert: 'Przepraszamy, Internet Explorer nie jest wspieraną przez nas przeglądarką. Prosimy o skorzystanie z innej przeglądarki.'
        },

        components: {
            workPlan: {
                header: {
                    TIMETABLE: 'Plan',
                    TIMETABLE_CHANGES: 'Kalendarz zmian',
                },
                editDialog: {
                    workPosition: 'Edycja pozycji planu pracy',
                    unsavedWorkPosition: 'Nowa pozycja planu pracy',
                    vacation: 'Edycja urlopu',
                    unsavedVacation: 'Nowy urlop',
                    dayOfWeekLabel: 'Dzień tygodnia',
                    officeLabel: 'Gabinet',
                    visitTypeLabel: 'Rodzaj wizyty',
                    startTimeLabel: 'Czas rozpoczęcia',
                    endTimeLabel: 'Czas zakończenia',
                    description: 'Opis',
                    cancel: 'Anuluj',
                    approve: 'Zatwierdź',
                    visitType: 'Typ wizyty',
                    visitPlace: 'Miejsce wizyty',
                    stationaryAndRemote: 'Uwaga! Ten typ wizyty umożliwia zapisywanie się pacjentów zarówno na wizyty stacjonarne jak i wizyty online',
                    workPlaceMissing: 'W celu umożliwienia umawiania wizyt stacjonarnych należy podać miejsce pracy w sekcji \'Moje dane\''
                },
                deleteDialog: {
                    text: 'Na pewno chcesz usunąć tę pozycję?',
                    cancel: 'Anuluj',
                    confirm: 'Usuń',
                },
                visitType: {
                    unavailable: 'Dyżur',
                    any: 'Dowolna',
                    stationaryOrRemore: 'Stacjonarna / online'
                },
                event: {
                    duty: {
                        yourDutyToChange: "Oznaczony jako do zmiany",
                        state: {
                            "AVAILABLE": 'Dyżur dostępny',
                            "NOT_AVAILABLE": 'Dyżur niedostępny',
                            "TO_CHANGE": 'Prośba o przekazanie dyżuru',
                            "PLANNED": 'Twój zaplanowany dyżur'
                        }
                    }
                },
                description: 'zaplanuj swoją dostępność dla wizyt',
                selectedWorkPlan: 'Wybrany plan pracy:',
                newWorkPlan: 'Nowy plan pracy:',
                untilFurtherNotice: 'do odwołania',
                couldNotDeleteActiveWorkPlan: 'Nie można usunąć aktywnego planu pracy',
                validFrom: 'Obowiązuje od',
                validTo: 'Obowiązuje do',
                resetChanges: 'Wyczyść',
                saveChanges: 'Zapisz',
                unsavedChanges: 'Harmonogram zawiera niezapisane zmiany',
                visits: 'Wizyty',
                newPlan: 'Dodaj plan pracy',
                messages: {
                    deleteSuccess: 'Plan pracy został usunięty',
                    addSuccess: 'Plan pracy został dodany',
                    updateSuccess: 'Plan pracy został zaktualizowany'
                }
            },
            doctor: {
                calendar: {
                    todayBtn: 'Dzisiaj'
                },
                messages: {
                    title: 'Wiadomości bieżące',
                    show: 'Pokaż',
                    new: 'Nowe',
                    item: {
                        systemLbl: 'Powiadomienie systemowe',
                        types: {
                            prescribeLbl: 'Prośba o przepisanie recepty od: {sender}',
                            prescribeLblMovedFrom: 'w zastępstwie za lekarza {movedFrom}',
                            selectionLbl: 'Prośba o objęcie opieką od: {sender}',
                            noteLbl: 'Została dodana notatka przy pacjencie: {patient}'
                        }
                    }
                },
                visits: {
                    title: 'Plan dnia',
                    show: 'Pokaż',
                    types: 'Typ wizyty',
                    categories: 'Kategoria wizyty',
                    type: {
                        remote: 'Wizyty online',
                        stationary: 'Stacjonarne'
                    },
                    item: {
                        statusPayed: 'opłacono',
                        statusNotPayed: 'nie opłacono',
                        statusDone: 'wykonano',
                        statusToMove: 'do przepisania',

                        visitBtnLbl: 'wizyta',
                        visitStartBtnLbl: 'rozpocznij wizytę',
                        historyBtnLbl: 'historia choroby',

                        numberLbl: 'Liczba wizyt',
                        causeLbl: 'Powód wizyty',
                        lastVisitLbl: 'Ostatnia wizyta:',
                        diagnosisLbl: 'Rozpoznanie',
                        diagnosisNoEpilepsyLbl: 'Nie choruje na padaczkę',
                        diagnosisNoDataLbl: 'Brak rozpoznania',
                        drugsLbl: 'Aktualne leki',
                        drugsNoneLbl: 'Brak',
                        frequencyLbl: 'Częstotliwość napadów',
                        moodLbl: 'Samopoczucie',
                        summaryLbl: 'Podsumowanie wizyty',
                        visitDuration: 'Czas trwania:'
                    }
                },
                form: {
                    pricelist: {
                        titleInfo: 'Nowa pozycja cennika',
                        cancelBtn: 'Anuluj',
                        saveBtn: 'Zatwierdź'
                    },
                    avatar: {
                        titleInfo: 'Wybierz zdjęcie',
                        addImageBtn: 'Zdjęcie z dysku',
                        cancelBtn: 'Anuluj',
                        saveBtn: 'Zatwierdź'
                    },
                    address: {
                        streetLbl: 'Ulica',
                        homeBuildingLbl: `Numer domu / mieszkania`,
                        apartmentLbl: 'Numer mieszkania',
                        buildingLbl: 'Numer domu',
                        cityLbl: 'Miejscowość',
                        zipcodeLbl: 'Kod pocztowy',
                        countryLbl: 'Kraj'
                    }
                }
            },
            message: {
                history: {
                    list: 'Lista wiadomości',
                    title: 'Wiadomości',
                    newMessage: 'Nowa wiadomość',
                    newMessageOnDuty: 'Nowa wiadomość do lekarza dyżurnego',
                    new: 'Nowa',
                    search: 'Szukaj',
                    visitEnded: 'zakończona',
                    filters: {
                        current: 'Bieżące',
                        archive: 'Archiwum',
                        contact: 'Wyszukaj'
                    },
                    noMessages: 'Brak wiadomości',
                    noTherapies: 'Brak terapii lekowych',
                    born: 'ur.',
                    historyBtnLbl: 'Historia choroby',
                    return: 'Powrót',
                    send: 'Wyślij',
                    sendAndClose: 'Wyślij i zakończ wizytę',
                    closeNoAnswer: 'Wiadomość nie wymaga odpowiedzi',
                    noAnswerContent: 'Dziękuje za wiadomość. Pozdrawiam',
                    confirmNoAnswer: 'Czy na pewno chcesz zakończyć wizytę bez odpowiedzi?',
                    hoursToAnswer: 'godz.',
                    daysToAnswer: 'dni',
                    timeIsUp: 'Czas minął',
                    hoursToAnswerRich: 'Masz {hours} godz. by odpowiedzieć na tą wiadomość',
                    daysToAnswerRich: 'Masz {days} dni by odpowiedzieć na tą wiadomość',
                    timeIsUpRich: 'Deklarowany czas na odpowiedź już minął. Udziel jej jak najszybciej!',
                    summary: 'Podsumowanie',
                    medicalHistory: 'Historia choroby',
                    unidirectional: {
                        send: 'Wyślij wiadomość jednokierunkową',
                        sendTitle: 'Jednokierunkowa wiadomość do pacjenta',
                        sendSubtitle: 'Pacjent nie może odpowiedzieć na tą wiadomość',
                        sendSuccess: 'Wiadomość jednokierunkowa została wysłana do pacjenta',
                        infoForPatient: 'Jest to wiadomość - informacja od lekarza. W tej wiadomości lekarz przekazał Ci dodatkowe informacje na temat Twojej choroby. Na wiadomość nie możesz odpowiedzieć, jeśli chcesz uzyskać dodatkowe informacje napisz nową wiadomość - wizytę do swojego lekarza.'
                    },
                    sort: {
                        label: 'Pokaż',
                        newest: 'Od najnowszych',
                        alpha: 'Alfabetycznie',
                        deadline: 'Od najpilniejszych'
                    },
                    sendAndCloseDialog: {
                        header: 'Czy na pewno chcesz zakończyć wątek?',
                        title: 'Jeśli zakończysz wątek pacjent nie będzie mógł odpisać Ci na wiadomość',
                        subtitle: 'Jeśli chcesz aby pacjent mógł odpisać Ci na  wiadomość kliknij “Wyślij”'
                    },
                    addToEpicrisis: "Dodaj do epikryzy",
                    textAddedToEpicrisis: "Wpis został dodany do epikryzy"
                },
                respondeDeadlineAlert: {
                    youHaveTimeTo: 'Masz czas do',
                    toReplyTo: 'by odpowiedzieć na wiadomość od',
                    reply: 'Odpowiedz'
                },
                newPaidMessageAlert: {
                    content: 'Masz zapłaconą, niezrealizowaną wiadomość do lekarza',
                    reply: 'Napisz wiadomość'
                },
                unidirectionalMessageAlert: {
                    content: 'Otrzymałeś wiadomość - informację od lekarza',
                    reply: 'Przejdź do wiadomości'
                },
                personalDataAcceptanceAlert: {
                    title: 'Potwierdzenie danych osobowych',
                    subtitle: 'Twoje dane osobowe w systemie Neuroterminal to ważna sprawa - upewnij się, że są aktualne! Dzięki temu w pełni korzystasz z naszych usług',
                    changeButton: 'Zmień dane osobowe',
                    acceptButton: 'Potwierdzam dane osobowe'
                },
                patientAlerts: {
                    healthSurveyContent: 'Twoja ankieta zero wymaga uzupełnienia',
                    healthSurvey: 'Uzupełnij ankietę'
                },
                newMessageVisit: {
                    noDoctorTitle: 'Brak lekarza prowadzącego',
                    title: 'Skonsultuj się z lekarzem - wyślij wiadomość',
                    descriptionLbl: 'Opis usługi',
                    description: 'Wizyta - wiadomość to krótkie pytanie wysłane do lekarza. Twój lekarz udzieli na nie odpowiedzi. Jeżeli chcesz skorzystać z tej formy kontaktu, opłać wizytę-wiadomość i kliknij przycisk „Nowa wiadomość”. Jeżeli cokolwiek zaniepokoi Cię w Twoim stanie zdrowia lub potrzebujesz dłuższej konsultacji – prosimy o umówienie wizyty REGULARNEJ ze swoim lekarzem opiekunem.',
                    messageVisitsDisabled: 'Wysłanie wiadomości będzie możliwe po odbyciu wizyty u lekarza, który świadczy tą usługę.',
                    noLeadDoctorAvailable: 'Twój lekarz opiekun aktualnie jest niedostępny. Wiadomość możesz napisać do lekarza dyżurnego.'
                },
                drugStock: {
                    lowMsg: 'Kończą się zapasy poniższych leków:',
                    notInitialized: 'Zaktualizuj zapas leków:',
                    daysLbl: 'dni'
                },
                toast: {
                    text: 'Otrzymałeś wiadomość od '
                }
            },
            utility: {
                multipleSelect: {
                    all: 'Wszystko'
                },
                confirmationDialog: {
                    title: 'Jesteś pewien?',
                    text: 'Czy na pewno chcesz kontynuować?',
                },
                youAreNotAllowedDialog: {
                    title: 'Nie posiadasz uprawnień do wykonania tej akcji',
                    text: 'Niestety, nie możemi Ci na to pozwolić. Skontaktuj się z nami, żeby uzyskać więcej informacji na ten temat.',
                    goBackToMainPage: 'Wróć na stronę główną'
                },
                phoneInput: {
                    phoneNumberLabel: 'Numer telefonu',
                    legalGuardPhoneNumberLabel: 'Telefon rodzica lub przedstawiciela ustawowego'
                }
            },
            health: {
                state: {
                    noData: 'Brak danych',
                    stateNoChange: 'Bez zmian',
                    stateBetter: 'Poprawa',
                    stateWorse: 'Pogorszenie',
                }
            },
            calendar: {
                title: 'Wizyty i dyżury',
                description: 'Twoje wizyty i dyżury na bieżący tydzień. Kliknij na pole w kalendarzu, żeby wyświetlić jego szczegóły.',
                visitCancelled: 'Wizyta została odwołana',
                visitDateChanged: 'Wizyta została przełożona',
                legend: {
                    duty: 'Dyżur SOS',
                    vacation: 'Dzień wolny'
                },
                filters: {
                    showVisits: 'Wyświetl wizyty',
                    showDuties: 'Wyświetl dyżury'
                },
                visitDetailsDialog: {
                    title: 'Szczegóły wizyty',
                    onDay: 'w dniu',
                    fromHour: 'od godziny',
                    toHour: 'do godziny',
                    close: 'Zamknij',
                },
                dutyDetailsDialog: {
                    title: 'Szczegóły dyżuru',
                    fromHour: 'od godziny',
                    toHour: 'do godziny',
                    close: 'Zamknij',
                    signUpForDuty: 'Zapisz się na dyżur',
                    markDutyToChange: 'Poproś o zamianę',
                    toChangeRequestText: 'Prosi o zamianę. Możesz pomóc temu lekarzowi zapisując się za niego na ten dyżur.',
                    cantMarkDutyAsToChange: 'Niestety nie możesz poprosić o zamianę tego dyżuru.',
                    dutyNotAvailable: 'Ten dyżur jest już zajęty. Wybierz inny termin dyżuru.',
                    cancelToChange: 'Cofnij prośbę o przekazanie dyżuru',
                },
                confirmVisitCancellationDialog: {
                    title: 'Odwołanie wizyty',
                    sureToCancelVisit: 'Czy na pewno chcesz odwołać wizytę pacjenta',
                    fromDate: ', która miała się odbyć dnia',
                    fromDatePassed: ', która odbyła się dnia',
                    from: 'i trwać od',
                    fromPassed: 'i trwała od',
                    to: 'do',
                    description: 'Pacjent zostanie poproszony o wybranie innego terminu lub skontaktuje się z nim obsługa.',
                    confirm: 'Odwołaj wizytę',
                    cancel: 'Anuluj',
                    visitAlert: 'Odwołujesz wizytę, która ma się rozpocząć za mniej niż 48 godzin! Nie popieramy takich praktyk. Więcej informacji znajdziesz w naszym',
                    termsAndConditions: 'regulaminie',
                    visitError: 'Ta wizyta już się odbyła. Upewnij się czy na pewno chcesz ją odwołać'
                },
                visits: {
                    title: 'Wizyty lekarzy',
                    description: 'Wizyty wybranych lekarzy na dany dzień. Kliknij na pole w kalendarzu, żeby wyświetlić jego szczegóły.',
                    dateSelectPrefix: 'Wybrany dzień',
                    noVisits: 'Brak wizyt',
                    doctors: 'Lekarze'
                }
            },
            duties: {
                applyDutyDialog: {
                    title: 'Edycja dyżurów',
                    subtitle: 'Przed zapisem upewnij się, że wprowadzane zmiany są poprawne.',
                    addedDuties: 'Dodane dyżury:',
                    cancelledDuties: 'Odwołane dyżury:',
                    inHours: 'w godzinach',
                    back: 'Wróć do edycji',
                    approve: 'Zapisz',
                    acceptTermsAndConditions: 'akceptuję Regulamin Dyżurów',
                },
                alert: {
                    text: 'Masz dzisiaj dyżur w godzinach:'
                },
                description: 'Zapisz się na dyżur SOS. Zaznacz dogodny termin wybierając go kliknięciem w kalendarzu',
            },
            payment: {
                history: {
                    title: 'Historia rozliczeń',
                    filters: {
                        typeAll: 'Wszystkie',
                        advFiltLbl: 'Wyszukiwanie zaawansowane',
                        advanced: {
                            from: 'Data początkowa',
                            till: 'do',
                            to: 'Data końcowa',
                            clearLbl: 'Wyczyść',
                            searchLbl: 'Szukaj'
                        }
                    },
                    noDataLbl: 'Brak danych',
                    noResultsLbl: 'Nie znaleziono wyników wyszukiwania',
                    headers: {
                        date: 'Data',
                        type: 'Typ',
                        amount: 'Kwota',
                        invoice: 'Faktura',
                        service: 'Nazwa usługa'
                    },
                    pdfLbl: 'Pobierz',
                    invoice: 'Faktura',
                    correctionInvoice: 'Faktura korygująca'
                }
            },
            dialog: {
                generic: {
                    attention: 'Uwaga',
                    confirmation: {
                        dialog: {
                            defaultCancelLbl: 'Anuluj',
                            defaultConfirmLbl: 'Zatwierdź'
                        }
                    }
                }
            },
            patient: {
                visits: {
                    next: {
                        visit: {
                            info: {}
                        }
                    },
                    operations: {
                        cancel: {
                            title: 'Czy potwierdzasz anulowanie wizyty?',
                            warning: 'Zgodnie z Regulaminem, anulowanie wizyty na 48 godzin przed wizytą skutkować będzie nałożeniem opłaty manipulacyjnej w wysokości 50% kosztu wizyty. Opłata za konsultację pomniejszona o opłatę manipulacyjną za odwołanie wizyty zostanie zwrócona (sposób do ustalenia)',
                            warningCustom: 'Zgodnie z Regulaminem, anulowanie wizyty na {days} dni przed wizytą skutkować będzie nałożeniem opłaty manipulacyjnej w wysokości {percentage}% kosztu wizyty. Opłata za konsultację pomniejszona o opłatę manipulacyjną za odwołanie wizyty zostanie zwrócona (sposób do ustalenia)',
                            reason: 'Powód odwołania wizyty (opcjonalnie)',
                            confirmLbl: 'Tak',
                            cancelLbl: "Nie"
                        },
                        modify: {
                            title: 'Wybierz nowy termin wizyty',
                            changeWarning: 'Uwaga! System pozwala pacjentowi tylko na jedną zmianę terminu / lekarza dla usługi',
                            confirmMsg: 'Nowy termin wizyty',
                            modifyDisabled: 'Brak możliwości zmiany terminu tej wizyty',
                            dateBefore: 'Poprzedni termin',
                            dateCurrent: 'Aktualny termin',
                            change: 'Zmień termin',
                            cancelLbl: "Anuluj",
                            yesLbl: 'Potwierdź zmiany',
                            noLbl: 'Anuluj'
                        }
                    }
                }
            },
            surveyZeroNote: {
                addButton: 'Notatka 0',
                title: 'Wstępne obserwacje pacjenta',
                addDialog: {
                    visitPurpose: 'Cel wizyty',
                    visitPurposeDescription: 'Należy zapytać pacjenta jaki jest cel zapisania się na wizytę 1. Najlepiej jeśli będzie wpisana wypowiedź pacjenta.',
                    surveyCompleted: 'Czy wypełniono ankietę 0?',
                    surveyCompletedDescription: 'Jesli ankieta 0 nie została wypełniona lub została wypełniona w jakiejść części należy podać powód.',
                    seizures: 'Opisz słownie wprowadzone napady',
                    seizuresEmpty: 'Brak zdefiniowanych typów napadów',
                    chronicDiseases: 'Czy pacjent ma inne choroby przewlekłe - wiodące? (np. autyzm, udar)',
                    chronicDiseasesDescription: 'Choroby przewlekłe',
                    epilepsy: 'Czy w rodzinie pacjenta występowała padaczka lub inne choroby neurologiczne?',
                    epilepsyDescription: 'Opisz krótko u kogo z rodziny wystąpiła padaczka lub inne choroby neurologiczne',
                    eegCompleted: 'Czy pacjent miał wykonane badanie EEG?',
                    mriCompleted: 'Czy pacjent miał wykonane badanie MRI?',
                    ctCompleted: 'Czy pacjent miał wykonane badanie CT?',
                    geneticResearch: 'Czy pacjent miał wykonane badanie genetyczne?',
                    relevantExaminations: 'Czy pacjent miał wykonane inne istotne badania?',
                    otherMedicines: 'Czy pacjent przyjmuje aktualnie leki poza LPP zawartymi w ankiecie 0?',
                    otherMedicinesDescription: 'Opisz słownie jakie leki/substancje pacjent przyjmuje',
                    allergies: 'Czy pacjent ma alergie na leki, w tym antybiotyki?',
                    allergiesDescription: 'Opisz słownie na jakie leki/substancje pacjent jest uczulony',
                    notes: 'Dodatkowe notatki',
                    examinationDescription: 'Opisz wyniki badań jeśli są dołączone',
                    additionalDescription: 'Opis słowny',
                    treatment: 'Dotychczasowe leczenie',
                    treatmentDescription: 'Opis słowny',
                    specialDiet: 'Czy jest prowadzona jakaś specjalna dieta?',
                    specialDietDescription: 'Opis słowny',
                    perinatalInterviewUnimportant: 'Informacje nieistotne',
                    birthDate: 'Data rozwiązania (porodu)',
                    birthType: 'Rodzaj porodu',
                    pregnancyComplications: 'Powikłania w trakcie ciąży',
                    birthWeight: 'Masa przy porodzie',
                    birthLength: 'Długość przy porodzie',
                    apgar: 'Punkty Apgar (0-10)',
                    apgarFirst: '1 min',
                    apgarNext: 'Kolejny pomiar',
                    birthAdditionalProcedures: 'Czy okołoporodowo wykonano jakieś procedury dodatkowe?',
                    birthPrematureAgeCorrected: 'Czy wiek był korygowany dla wcześniaków?',
                    hasSiblings: 'Czy pacjent posiada rodzeństwo?',
                    sibling: 'Osoba',
                    siblingBirthDate: 'Data urodzenia',
                    siblingSameParents: 'Czy ci sami rodzice?',
                    siblingHealthy: 'Czy jest zdrowy?',
                    addSibling: 'Dodaj kolejną osobę',
                    geneticPredisposition: 'Czy występuje obciążenie środowiskowe i genetyczne/dziedziczne oraz wywiad rodzinny?',
                    developmentCorrect: 'Czy dotychczasowy rozwój jest prawidłowy?',
                    retardationDetermined: 'Czy określono na ile w stosunku do wieku jest opóźniony?',
                    retardationExamined: 'Czy było badanie specjalistyczne w tym kierunku?',
                    nystagmus: 'Czy u pacjenta występuje / pojawia się oczopląs?',
                    vision: 'Wzrok',
                    hearing: 'Słuch',
                    smoking: 'Palenie papierosów',
                    alcohol: 'Alkohol',
                    narcotics: 'Narkotyki',
                    leadingDisease: 'Jaka jest wiodąca choroba pacjenta?',
                    leadingDiseaseDominantSymptoms: 'Objawy dominujące choroby',
                    leadingDiseaseLasting: 'Od kiedy trwa choroba?',
                    accompanyingSymptoms: 'Objawy towarzyszące: czy pacjent chciałby coś dodać?',
                    next: 'Dalej',
                    previous: 'Wstecz',
                    saveAndClose: 'Zapisz i zamknij',
                    sections: {
                        surveyZero: 'Ankieta 0',
                        perinatalInterview: 'Wywiad okołoporodowy',
                        development: 'Rozwój',
                        familyInterview: 'Wywiad rodzinny',
                        treatment: 'Leczenie',
                        comorbidities: 'Choroby współistniejące',
                        medicalExams: 'Badania',
                        others: 'Inne'
                    }
                },
                preview: {
                    userCreated: 'Utworzona przez',
                    seizures: 'Opis wprowadzonych napadów',
                    chronicDiseases: 'Czy pacjent ma inne wiodące choroby?',
                    epilepsy: 'Wywiad rodzinny',
                    eegCompleted: 'Badanie EEG',
                    mriCompleted: 'Badanie MRI',
                    ctCompleted: 'Badanie CT',
                    geneticResearch: 'Badanie genetyczne',
                    relevantExaminations: 'Inne badania',
                    otherMedicines: 'Leki poza lekami LPP',
                    allergies: 'Alergie na leki',
                    addFragmentToObservations: 'Dodaj zaznaczony fragment do obserwacji',
                    addWholeToObservations: 'Dodaj całą notatkę 0 do obserwacji'
                }
            },
            medicalHistoryPrintout: {
                download: 'Pobierz historię choroby',
                withObservations: 'Wersja z obserwacjami',
                withoutObservations: 'Wersja bez obserwacji',
                downloadHistory: 'Pokaż historię pobrań',
                history: {
                    title: 'Historia pobrań historii choroby pacjenta',
                    timeDownload: 'Data pobrania',
                    userDownload: 'Osoba pobierająca',
                    observations: 'Zawiera obserwacje'
                },
                parametersPopup: {
                    title: 'Pobieranie historii choroby pacjenta',
                    subtitle: 'Wybierz z jakiego okresu czasu chcesz pobrać historię choroby pacjenta',
                    allHistory: 'cała historia z systemu Neuroterminal',
                    selectedRange: 'historia z określonego czasu',
                    download: 'Pobierz',
                    cancel: 'Anuluj'
                }
            },
            patientExaminations: {
                chooseExaminationLbl: "Wybierz badanie, do którego chcesz wypełnić opis",
                eeg: "Badanie EEG",
                addEegDescription: "Dodaj opis badania EEG",
                eegDescriptionHeader: "Opis badania EEG",
                describingDoctor: "Lekarz opisujący",
                months: "miesięcy",
                removeEegTitle: "Usunięcie opisu badania EEG",
                removeEegContent: "Czy na pewno chcesz usunąć ten opis",
            }
        },

        common: {
            sex: {
                man: 'Mężczyzna',
                woman: 'Kobieta',
                nondefined: 'Nieokreślona'
            },
            role: {
                patientLbl: 'pacjent',
                doctorLbl: 'lekarz'
            },
            years: ' | 1 rok | {n} lata | {n} lat',
            months: ' | 1 miesiąc | {n} miesiące | {n} miesięcy',
            days: ' | 1 dzień | {n} dni | {n} dni',
            visit: {
                paid: 'opłacona',
                notPaid: 'nieopłacona',
                additional: 'dodatkowa',
                toMove: 'do przepisania',
                confirmed: 'potwierdzona',
                done: 'wykonana',
                cancelled: 'anulowana',
                messagePaid: 'w trakcie'
            },
            bornAbbr: 'ur.',
            confirm: "Zatwierdź",
            cancel: "Anuluj",
            select: "Wybierz"
        },

        views: {

            profile: {
                welcome: 'WITAJ !',
                choose: 'Zaloguj się jako:',
                doctor: 'lekarz',
                patient: 'pacjent'
            },

            register: {
                patientLbl: 'Pacjent',
                doctorLbl: 'Lekarz',
                loginFldLbl: 'Login',
                newPassFldLbl: 'Hasło',
                newPassConfFldLbl: 'Potwierdź hasło',
                firstNameFldLbl: 'Imię',
                secNameFldLbl: 'Drugie imię',
                lastNameFldLbl: 'Nazwisko',
                emailFldLbl: 'E-mail',
                workCertNrFldLbl: 'Nr pozwolenia',
                confirmBtnLbl: 'Zatwierdź',
                backToLoginPageLbl: 'Wróć do ekranu logowania'
            },
            reset: {
                loginFldLbl: 'Użytkownik',
                newPasswordLbl: 'Nowe hasło',
                newPasswordConfirmLbl: 'Potwierdź nowe hasło',
                confirmBtnLbl: 'Zatwierdź',
                backToLoginPageLbl: 'Wróć do ekranu logowania'
            },
            dashboard: {
                welcomeMessage: "Zalogowany/a: {userName}",
                enableEditable: 'Zatwierdź widok',
                disableEditable: 'Dostosuj widok'
            },
            visits: {
                titleLbl: 'Moje wizyty',
                noDataLbl: 'Brak wizyt',
                noResultsLbl: 'Nie znaleziono wizyt',
                filters: {
                    visitTime: {
                        all: 'Wszystkie',
                        future: 'Przyszłe',
                        past: 'Przeszłe',
                    },
                    searchPatientLbl: 'Szukaj pacjenta',
                    advancedFiltersLbl: 'Wyszukiwanie zaawansowane',
                    advancedFilters: {
                        from: 'Data początkowa',
                        betweenDatesLbl: 'do',
                        till: 'Data końcowa',
                        type: 'Rodzaj wizyty',
                        category: 'Kategoria wizyty',
                        all: 'Wszystkie',
                        clearBtn: 'wyczyść',
                        searchBtn: 'szukaj',
                    }
                }
            },
            account: {
                common: {
                    requiredMsg: 'Wymagane pola oznaczono gwiazdką',
                    clearBtn: 'Wyczyść',
                    editBtn: 'Edytuj',
                    saveBtn: 'Zapisz',
                    yes: 'Tak',
                    no: 'Nie',
                    woman: 'kobieta',
                    man: 'mężczyzna',
                    nondef: 'nieokreślona',
                },
                home: {
                    myInfoLbl: 'Moje dane',
                    generalInfoLbl: 'Dane ogólne',
                    accountInfoLbl: 'Dane zawodowe',
                    pricelistInfoLbl: 'Dane finansowe',
                    incompleteFormWarningMsg1: 'Nie wypełniłeś wszystkich wymaganych pól',
                    incompleteFormWarningMsg2: 'Czy na pewno chcesz zapisać niekompletny formularz?'
                },
                general: {
                    basicInfoLbl: 'Podstawowe dane',
                    avatarLbl: 'Zdjęcie',
                    nameLbl: 'Imię',
                    lastNameLbl: 'Nazwisko',
                    sexLbl: 'Płeć',
                    emailLbl: 'E-mail',
                    phoneLbl: 'Telefon',
                    pwzLbl: 'Numer PWZ',
                    dataSavedMsg: 'Zapisano dane ogólne'
                },
                practise: {
                    basicInfoLbl: 'Uprawnienia, wykonywane usługi, kariera naukowa',
                    type: 'Poziom',
                    consultationType: 'Typ konsultacji',
                    academicTitle: 'Tytuł zawodowy',
                    specialization: 'Specjalizacja',
                    workPlace: 'Miejsce wizyt stacjonarnych',
                    epileptologyCert: 'Certyfikat z epileptologii',
                    nfzContract: 'Kontrakt z NFZ',
                    testResDescriptions: 'Opisy badań',
                    consultations: 'Konsultacje',
                    scientificWork: 'Praca naukowa',
                    scientificSocieties: 'Towarzystwa naukowe',
                    www: 'Strona WWW',
                    bio: 'Bio',
                    certificate: 'Certyfikat ZUS',
                    chooseFile: 'Wybierz plik',
                    patientsInfoLbl: 'Pacjenci',
                    newPatients: {
                        label: 'Nowi pacjenci',
                        accept: 'Przyjmuje',
                        noaccept: 'Nie przyjmuje'
                    },
                    acceptedPatients: 'Prowadzeni pacjenci',
                    neuroscreenDiagnoses: 'Promowane choroby',
                    leadPractiseInfoLbl: 'Miejsce wiodącej praktyki lekarskiej',
                    workplaceName: 'Nazwa placówki',
                    workplacePosition: 'Stanowisko',
                    communicators: 'Komunikatory',
                    usernameMessanger: 'Nazwa użytkownika Messenger',
                    usernameWhatsup: 'Nazwa użytkownika Whatsapp',
                    usernameSkype: `Nazwa użytkownika Skype`,
                    usernameHangouts: 'Nazwa użytkownika Hangouts',
                    dataSavedMsg: 'Zapisano dane zawodowe',
                    partner: 'Partner NSPH'
                },
                pricelist: {
                    services: 'Usługi',
                    visitsLbl: 'Wizyty',
                    othersLbl: 'Inne',
                    systemItemsLbl: 'Pozycje systemowe',
                    systemItemsDescription: 'Ceny tych usług są ustalane globalnie w zakładce "Cennik - dyżury"',
                    descriptionsLbl: 'Opisy badań',
                    table: {
                        statusLbl: 'Status',
                        activeLbl: 'Aktywna',
                        inactiveLbl: 'Nieaktywna',
                        nameLbl: 'Nazwa usługi',
                        durationLbl: 'Czas trwania',
                        priceLbl: 'Cena',
                        paymentLbl: 'Twoje wynagrodzenie',
                        perHourLbl: 'za godz.',
                        dutyLbl: 'Dyżurna'
                    },
                    bankAccount: 'Rachunek bankowy',
                    widgetVisibility: 'Widoczność w widgecie do umawiania wizyt',
                    practiceName: 'Nazwa praktyki lekarskiej',
                    nip: 'Nip',
                    regon: 'Regon',
                    dataSavedMsg: 'Zapisano dane cennika',
                    visitsDescription: 'Określ stawki za poszczególne wizyty.',
                    othersDescription: 'Określ stawki za inne usługi niezależne od wieku pacjentów jak np. wiadomości lub dokumenty medyczne.',
                    choosePatientTypes: 'Należy wskazać przynajmniej jeden typ pacjentów do prowadzenia, aby móc zdefiniować cennik wizyt'
                }
            },
            patientDashboard: {
                neuroscreen: 'Neuroscreen',
                yourLeadDoctor: 'Twój lekarz opiekun',
                doctorOnDuty: 'Lekarz dyżurny',
                addEvent: 'Dodaj zdarzenie',
                showCalendar: 'Pokaż kalendarz',
                filesAdded: 'Dodano plików',
                addFiles: 'Dodaj pliki',
                showAllFiles: 'Zobacz wszystkie pliki',
                surveyZeroDesc: 'Ankietę wypełnia lekarz podczas wizyty Ankieta 0',
                showSurveyZero: 'Zobacz ankietę',
                aboutMe: 'O mnie',
                myData: 'moje dane',
                myDataEdit: 'Edytuj',
                myDataFill: 'Wypełnij',
                myDataShow: 'Zobacz',
                medicalDocuments: 'dokumentacja medyczna',
                healthQuestionnaire: 'ankieta zero',
                visitsLbl: 'Wizyty',
                eventCalendar: 'Kalendarz zdarzeń',
                addEventLbl: 'Dodaj',
                confirmDeletionTitle: 'Usuwanie zdarzenia',
                confirmDeletionText: 'Chcesz usunąć to zdarzenie?',
                seizure: 'Napad',
                infection: 'Infekcja',
                fever: 'Gorączka',
                insomnia: 'Brak snu',
                menstruation: 'Miesiączka',
                pregnancyStart: 'Rozpoczęcie ciąży lub starania się o dziecko',
                pregnancyEnd: 'Zakończenie ciąży lub starania się o dziecko',
                chooseLeadDoctor: 'Wybierz lekarza opiekuna',
                noLeadDoctor: 'Lekarza prowadzącego wybierzesz podczas wizyty zero',
                notAvailableNow: 'Teraz niedostępny',
                doctorCare: 'Opieka lekarska',
                alert: {
                    yourVisitWithDoctor: 'Twoja wizyta u lekarza',
                    startsAt: 'rozpocznie się o',
                    lastsFrom: 'trwa od',
                    join: 'Dołącz',
                    visitToMove: {
                        first: 'Twoja wizyta z dnia',
                        second: 'została odwołana',
                        makeNewAppointment: 'Umów nowy termin wizyty'
                    },
                    myData: {
                        label: 'Aby korzystać z konta Pacjenta, wypełnij formularz danych osobowych.',
                        action: 'Przejdź do formularza'
                    },
                    prescriptionsToRead: {
                        label: 'Lekarz wystawił receptę. Aby zobaczyć kod PIN przejdź do zakładki Terapia.',
                        action: 'Przejdź do recept'
                    },
                    selectLeadDoctor: {
                        label: 'Wybierz swojego lekarza opiekuna w systemie.',
                        action: 'Wybierz lekarza'
                    },
                    doctorRejectedRequest: "Lekarz {doctorName} nie zgodził się na objęcie opieki. Powód: {reason}",
                    absentConsultation: "Napisz notatkę dla lekarza na <b>konsultacje zaoczną</b>. Masz czas do <nobr><b>{time}</b></nobr>"
                },
                activation: {
                    welcomeLbl: 'Witaj w Neuroterminal!',
                    init1Lbl: 'Cieszymy się, że jesteś z nami.',
                    init2Lbl: 'Przed pierwszą wizytą uzupełnij informacje o swoim zdrowiu.',
                    init3Lbl: 'moje dane',
                    init4Lbl: 'dokumentacja medyczna',
                    init5Lbl: 'A następnie umów wizytę zero.',
                    nextLbl: 'Dalej'
                },
                therapy: 'aktualna terapia',
                noCurrentTherapies: 'Brak aktualnej terapii',
                drugStock: 'zapas leków',
                drugStockDescription: 'Kliknij „Aktualizuj” i wpisz aktualny zapas swoich leków.',
                supplyFor: 'zapas na {days} dni',
                amountUndefined: 'nie określono zapasu',
                supplyEndsIn: 'skończy się za {days} dni',
                runningOutStocks: 'Twój zapas się kończy. Poproś swojego lekarza opiekuna o wystawienie recepty.',
                medicineLbl: 'Lek',
                update: 'Aktualizuj',
                save: 'Zapisz',
                askForPrescription: 'Poproś o receptę',
                updateDrugStockLbl: 'Aktualizacja zapasów leków',
                updateDrugStockLblDesc: 'Policz aktualny zapas przyjmowanych leków i wpisz odpowiednią wartość w okienko poniżej.',
                drugStockUpdateHelpLbl: 'Wprowadź zapasy leków z poczatku dzisiejszego dnia. Będą one aktualizowane każdego dnia w oparciu o dawkowania z twojej aktualnej terapii lekowej',
                drugStockUpdated: 'Zapas leków {products} został zaktualizowany',
                drugStockRecalculationFailed: 'Automatyczna aktualizacja zapasu leków nie powiodła się. Możesz zaktualizować zapasy samodzielnie.',
                referrals: {
                    title: 'Rekomendacja od lekarza',
                    appoint: 'Umów'
                },
                nonepileptic: {
                    infoMsg: 'Wszystkie konultacje są wizytami jednorazowymi realizowanymi przez najlepszych specjalistów. Szczegółowy zakres usług z listą lekarzy jest dostępny w procesie umawiania wizyty.',
                    consultationsLbl: 'konsultacje',
                    specialistsLbl: 'specjaliści',
                    epileptLbl: 'epileptolog',
                    neurosurLbl: 'neurochirurg',
                    neurologLbl: 'neurologopeda',
                    psychLbl: 'psychiatra'
                },
                dataNotFilled: {
                    title: 'Witamy w aplikacji Neuroterminal!',
                    footer: 'Wybierz, kto będzie pacjentem Neurosphery',
                    patient: 'Jestem pacjentem',
                    patientDescription: 'Dla pacjentów, którzy ukończyli 18 rok życia',
                    guardian: 'Pacjentem jest dziecko',
                    guardianDescription: 'Dla pacjentów, którzy nie ukończyli 18 roku życia lub posiadają opiekuna prawnego'
                },
                carePlan: {
                    title: 'Plan opieki',
                    date: 'Termin',
                    incomingVisitsAndReferrals: 'Wizyty nadchodzące i skierowania',
                    incomingVisitsAndReferralsDesc: 'Tu pojawią się twoje umówione nadchodzące wizyty i skierowania od lekarzy',
                    bookFirstVisit: 'Umów pierwszą wizytę',
                    archiveVisits: 'Wizyty archiwalne',
                    archiveVisitsDesc: 'Tu zobaczysz wizyty, które już się odbyły',
                    scheduledDate: 'sugerowany termin',
                    getSummaryPdf: 'pobierz zalecenia z wizyty',
                    cancelReferral: 'usuń skierowanie',
                    changeDate: 'zmień termin',
                    cancelVisit: 'odwołaj wizytę',
                    reassignVisit: 'zmień lekarza',
                    payForVisit: 'opłać wizytę',
                    startingAt: 'Start o',
                    goToVisit: 'Dołącz do wizyty',
                    showMoreArchiveVisits: 'Pokaż więcej wizyt archiwalnych',
                    cancelReferralConfirmation: 'Czy na pewno chcesz usunąć to skierowanie?',
                    cancelReferralSuccess: 'Skierowanie zostało usunięte',
                    chooseDate: 'Wybierz termin',
                    chooseDateFreeReferral: 'Wybierz termin darmowej wizyty',
                    change: 'Zmień plan opieki',
                    suggestedDate: 'sugerowana data',
                    referralRemoved: 'Skierowanie usunięte',
                    downloadSummary: 'Pobierz zalecenia',
                    description: 'Opis',
                    filters: {
                        canceled: 'Pokaż anulowane',
                        visits: 'Pokaż wizyty',
                        messages: 'Pokaż wiadomości',
                        referrals: 'Pokaż skierowania'
                    }
                }
            },
            patientAccount: {
                info: {
                    general: 'Dane pacjenta',
                    generalDesc: 'Potrzebujemy kilku Twoich danych. To wymóg formalny. Pamiętaj, są one chronione prawnie i technicznie!',
                    generalDescLegal: 'Potrzebujemy także danych naszego pacjenta. To wymóg formalny, ale także możliwość poprawnego wystawienia recepty, zaświadczeń lub skierowań.',
                    infoLbl: 'Dane osobowe',
                    avatarLbl: 'Zdjęcie',
                    nameLbl: 'Imię',
                    lastNameLbl: 'Nazwisko',
                    sexLbl: 'Płeć',
                    emailLbl: 'E-mail',
                    phoneLbl: 'Telefon',
                    peselLbl: 'Pesel',
                    nameLblRich: '<b>Imię</b> pacjenta',
                    lastNameLblRich: '<b>Nazwisko</b> pacjenta',
                    peselLblRich: '<b>Pesel</b> pacjenta',
                    otherDocumentLbl: 'Inny dokument',
                    documentTypeLbl: 'Typ dokumentu',
                    documentNumberLbl: 'Numer dokumentu',
                    birthDate: 'Data urodzenia',
                    repLbl: 'Dane przedstawiciela ustawowego',
                    parentLbl: 'Dane rodzica',
                    repLblDesc: 'Potrzebujemy jeszcze kilku informacji o Tobie drogi rodzicu, aby zgodnie z wymogami prawa móc otoczyć Was opieką. Pamiętaj, że Wasze dane są chronione prawnie i technicznie!',
                    repLblShort: 'Przedstawiciel ustawowy',
                    repNameLbl: '<b>Imię</b> rodzica lub przedstawiciela ustawowego',
                    repLastNameLbl: '<b>Nazwisko</b> rodzica lub przedstawiciela ustawowego',
                    repPhoneLbl: '<b>Telefon</b> rodzica lub przedstawiciela ustawowego',
                    repPeselLbl: '<b>PESEL</b> rodzica lub przedstawiciela ustawowego',
                    teleAddrLbl: 'Adres pacjenta',
                    teleAddrLblDesc: 'Poprawne dane teleadresowe to konieczność, aby lekarz mógł wystawić receptę, zaświadczenie lub skierowanie.',
                    bioMedLbl: 'Dane biomedyczne',
                    bioMedLblDesc: 'Poniższe dane nie są obowiązkowe. Możesz je uzupełnić później. Pamiętaj, że podanie danych biometrycznych pomaga lekarzowi poprawnie dobrać dawki leków.',
                    bloodTypeLbl: 'Grupa krwi',
                    rhTypeLbl: 'RH',
                    heightLbl: 'Wzrost',
                    weightLbl: 'Waga',
                    iceLbl: 'ICE (In Case of Emergency - w nagłym przypadku)',
                    iceShortLbl: 'ICE',
                    others: 'Inne',
                    othersDesc: 'Poniższe dane nie są obowiązkowe, jeśli chcesz zostaw nam informację, kogo powiadomić w razie nagłego wypadku.',
                    icePers1Lbl: 'Osoba do powiadomienia 1',
                    icePers1PhoneLbl: 'Telefon osoby 1',
                    icePers2Lbl: 'Osoba do powiadomienia 2',
                    icePers2PhoneLbl: 'Telefon osoby 2',
                    addIceContact: 'Dodaj kolejną osobę',
                    nsphSourceQuestion: 'Skąd dowiedziałeś się o Neurospherze?',
                    nsphSourceDoctorDescription: 'Podaj poszę imię i nazwisko lekarza',
                    clearBtn: 'Wyczyść',
                    saveBtn: 'Zapisz dane',
                    dataSavedMsg: 'Zapisano dane',
                    entitledStatementMsg: 'Pacjent posiada pełną zdolność do czynności prawnych',
                    legalGuardianMsg: 'Oświadczam, że jestem przedstawicielem ustawowym uprawnionym do reprezentacji Pacjenta jako:',
                    invalidPeselWarnMsg: 'Podany PESEL jest nieprawidłowy, płeć i data urodzenia nie zostaną zapisane',
                    fromNsph: 'Czy pacjent leczył się już w Centrum Terapii Padaczki Neurosphera?',
                    hasEpilepsy: 'Czy pacjent choruje na epilepsję?',
                    incompleteFormWarningMsg1: 'Nie wypełniłeś wszystkich wymaganych pól',
                    incompleteFormWarningMsg2: 'Jeśli nie wypełnisz wszystkich wymaganych pól, twoje konto nie zostanie aktywowane i nie będziesz mógł umówić Wizyty 1. To wymóg prawny, który jako podmiot leczniczy jesteśmy zobowiązani spełnić. Poprawne dane pozwalają naszym lekarzom na realizację usług medycznych, w tym np. na wystawienie recept.',
                    incompleteFormConfirmBtn: 'Uzupełnij dane',
                    yesLbl: 'Tak',
                    noLbl: 'Nie',
                    contactEmailLbl: "Dodatkowy adres email, na który będą przychodzić wiadomość z systemu",
                    contactEmailDesc: "Wpisany adres będzie dostępny dla recepcji przychodni. Jeśli dodatkowo chcesz dostawać powiadomienia z systemu na wprowadzony adres, zaznacz odpowiednie punkty:",
                    contactEmailPlaceholder: "Adres e-mail",
                    contactDescriptionPlaceholder: "Opis adresu",
                    contactVisitNotification: "Wizyty - opłata, potwierdzenie",
                    contactReferralNotification: "Skierowania - plan opieki",
                    addAdditionalContactEmail: "Dodaj kolejny adres e-mail",
                    documentCountry: "Kraj",
                    partner: 'Partner NSPH',
                    invoiceBuyer: {
                        title: 'Dane do faktury',
                        checkboxLbl: 'Chcę podać inne dane do faktury',
                        firm: 'Firma',
                        person: 'Osoba prywatna',
                        firmLbl: 'Nazwa firmy',
                        nipLbl: 'Nip',
                        firstNameLbl: 'Imię',
                        lastNameLbl: 'Nazwisko',
                        streetLbl: 'Ulica',
                        buildingLbl: 'Budynek',
                        zipcodeLbl: 'Kod pocztowy',
                        cityLbl: 'Miasto',
                        comment: 'Komentarz'
                    },
                    address: {
                        streetLbl: '<b>Ulica zamieszkania</b> pacjenta',
                        cityLbl: '<b>Miejscowość zamieszkania</b> pacjenta',
                        countryLbl: '<b>Kraj zamieszkania</b> pacjenta',
                    },
                    leaveDialog: {
                        title: 'Nie skończyłeś procesu wprowadzania danych, czy na pewno chcesz się wylogować?',
                        subtitle: 'Będziesz musiał ponownie je wprowadzić przy następnym zalogowaniu',
                        footer: 'Jeśli masz dodatkowe pytania lub w razie problemów związanych z wypełnieniem wniosku - skontaktuj się z nami:'
                    },
                    wrongDataDialog: {
                        title: 'W tej chwili nie możemy zapisać Twoich danych osobowych.',
                        description: 'Skontaktuj się z Recepcją Centrum Terapii Padaczki Neurosphera.'
                    }
                },
                bioMed: {
                    saveSuccess: "Zmiany zostały zapisane",
                    fullInfo: "Zobacz pełne dane"
                },
                bioMedEditDialog:{
                    title: 'Edycja danych biomedycznych pacjenta',
                    subtitle: 'Przy zmianie wartości dane zostaną automatycznie zapisane'
                }
            },
            awaitingPaymentNotAvailable: {
                title: 'Rezerwacja wizyty nie powiodła się!',
                subtitle: 'Przepraszamy',
                ageDescription: 'Wybrany przez Ciebie lekarz nie przyjmuje pacjentów w tym wieku',
                changeDescription: 'Wybierz innego lekarza, a następnie termin wizyty',
                action: 'Wybierz lekarza i termin wizyty'
            },
            visitReservation: {
                title: 'Umawianie wizyty',
                consultationTitle: 'Umawianie konsultacji',
                specializationChoice: 'Wybór specjalizacji',
                visitTypeChoice: 'Typ wizyty',
                doctorChoice: 'Wybór lekarza',
                slotChoice: 'Wybór terminu',
                summaryAndPayment: 'Podsumowanie i płatność',
                noServicesAvailable: 'Umówienie kolejnych wizyt padaczkowych będzie możliwe po odbyciu najbliższej wizyty',
                goToVisitsList: 'Przejdź do listy wizyt',
                appointConsultation: 'Umów konsultację / badanie EEG',
                leadDoctor: 'twój lekarz opiekun',
                next: 'Dalej',
                choose: 'Wybierz',
                expand: 'Rozwiń',
                approve: 'Zatwierdź',
                cancel: 'Anuluj',
                fold: 'Zwiń',
                back: 'Wstecz',
                doctor: 'Lekarz',
                service: 'Rodzaj wizyty',
                price: 'Cena',
                payment: 'Forma zapłaty',
                date: 'Data',
                pay: 'Zapłać',
                cancelAndChangeDate: 'Anuluj / zmień datę',
                calculateInstallments: 'Zapłać w ratach',
                noSlotsAvailable: 'Brak dostępnych terminów',
                doctors: 'Lekarze',
                availableSlots: 'Dostępne terminy',
                selectPreviousSlots: 'Dostępne terminy będą widoczne po wyborze terminu poprzedzającej wizyty',
                summary: 'Podsumowanie',
                serviceDescription: 'Opis usługi',
                priceFrom: 'Cena od',
                visitDuration: 'Czas trwania wizyty',
                durationSelectDesc1: 'Badanie może trwać od',
                durationSelectDesc2: 'do',
                durationSelectDesc3: 'godzin',
                durationSelectDesc4: 'Aby kontynuować umawianie wizyty proszę wybrać ilość godzin',
                durationTime: 'Czas badania',
                eegDescription: 'W systemie Neuroterminal zapiszesz się na badanie EEG standardowe trwające 30 min. Badanie wykonywane jest w Centrum Terapii Padaczki Neurosphera. Jeśli chcesz się zapisać na badanie EEG wykonywane niestandardowo lub na modyfikację badania EEG (VEEG, DEEG, PSG lub inne) należy umówić się telefonicznie z sekretariatem Centrum Terapii Padaczki Neurosphera pod tel. +48 609 463 191',
                allDoctors: 'Wszyscy lekarze',
                allWorkplaces: 'Wszystkie placówki',
                allTechniciansIntro: 'Badanie wykona jeden z doświadczonych techników',
                paymentLinkSent: 'Link do płatności został wysłany do pacjenta',
                markedAsPaid: 'Wizyta została zarezerwowana oraz oznaczona jako opłacona',
                openPaymentLink: 'Otwórz link do płatności',
                linkCopied: 'Link został skopiowany do schowka',
                markAsPaid: 'Oznacz jako opłaconą',
                stage: 'Etap',
                sum: 'Suma',
                freeAppointmentPrice: 'Gratis',
                appointmentSuccess: 'Wizyta została umówiona',
                sameDateAsPrevious: 'Wizyta powinna odbyć się tego samego dnia co poprzednia wizyta z pakietu',
                refundRestrictions: 'Zasady odwoływania wizyty',
                refundRestrictionsAccept: 'Akceptuję zasady odwoływania wizyty',
                supervisorDoctor: 'lekarz obserwujący wizytę',
                supervisorDoctorAbbr: 'Lekarz obserwujący',
                doctorPackageDescription: 'Pakiet Lekarzy to wizyta z udziałem 2 lub więcej lekarzy. Lekarz prowadzący wizytę wykonuje wszelkie czynności związane z wizytą. Lekarz obserwujący obserwuje wizytę i może z lekarzem prowadzącym i pacjentem prowadzić dyskusję kliniczną.',
                specialist: 'Specjalista w dziedzinie',
                serviceTypeSelection: {
                    label: 'Rodzaj wizyty',
                    epilepsy: 'Wizyta padaczkowa',
                    consultation: 'Konsultacja specjalistyczna / EEG'
                },
                sorting: {
                    label: 'Sortuj',
                    alpha: 'Alfabetycznie',
                    teleconferencePriceAsc: 'Cena wizyty online: rosnąco',
                    teleconferencePriceDesc: 'Cena wizyty online: malejąco',
                    stationaryPriceAsc: 'Cena wizyty stacjonarnej: rosnąco',
                    stationaryPriceDesc: 'Cena wizyty stacjonarnej: malejąco',
                    bestMatch: 'Najlepsze dopasowanie'
                },
                serviceGroupDescriptions: {
                    eeg_consultation: {
                        groupSelectionTitle: 'Dostępne badania EEG i VEEG',
                        title: 'Kto przeprowadza badanie?',
                        description: 'Badanie, na zlecenie lekarza epileptologa, neurologa czy psychiatry, przeprowadza technik specjalizujący się w tej dziedzinie. Odpowiada za planowanie i wykonywanie badań wszelkiej modalności u dorosłych i dzieci. Badania prowadzone są w czuwaniu-zasypianiu-śnie. Mogą być również przeprowadzone w domu pacjenta.',
                        bulletsTitle: 'Kiedy konieczne jest badanie?',
                        bullet1: 'Badanie jest podstawowym badaniem w diagnostyce padaczki',
                        bullet2: 'EEG często stosowane jest we wskazaniu przyczyn zaburzeń snu',
                        bullet3: 'Może być zlecone przy urazach głowy',
                        bullet4: 'Wskazaniem są również zaburzenia wzroku, jąkanie się, nawracające bóle głowy, omdlenia czy utraty przytomności'
                    }
                },
                serviceDescriptions: {
                    vns: {
                       title: 'Czym jest wizyta VNS?',
                       description: 'Na wizycie VNS lekarz sprawdza bieżące ustawienie prądu. Na podstawie historii zarejestrowanych  napadów dobiera odpowiednią terapię VNS. Lekarz na wizycie zmienia ustawienia stymulacji za pomocą komputera, oprogramowania konfiguracyjnego i pilota programującego. Lekarz może zmniejszyć lub zwiększyć natężenie prądu oraz wydłużyć lub skrócić czas bezczynności aparatu. Wizyta trwa 15 min.',
                       bulletsTitle: 'Kiedy zapisać się na wizytę VNS?',
                       bullet1: 'Kiedy terapia VNS przewiduje stopniową zmianę parametrów prądu',
                       bullet2: 'Kiedy pacjent zauważa znaczne zwiększenie ilość napadów',
                       bullet3: 'Kiedy pacjent zauważa znaczne pogorszenie stanu zdrowia i samopoczucia',
                       bullet4: 'Gdy pacjent zauważa, że wszczepiony stymulator przestaje działać, czyli nie wysyła impulsów',
                       bullet5: 'Przed poddaniem się jakimkolwiek badaniom medycznym mogącym mieć wpływ na urządzenie VNS lub podlegać jego wpływowi, np. rezonans magnetyczny (MRI)',
                    },
                    dietician: {
                        title: 'Kim jest dietetyk?',
                        description: 'Specjalista, który ukończył studia wyższe o profilu medycznym lub żywieniowym (rolniczym). Ma odpowiednią wiedzę do prowadzenia diet w różnychgrupach pacjentów, a przy układaniu zaleceń bierze pod uwagę m.in. płeć, wiek, masę i wysokość ciała, choroby, w tym alergie oraz upodobania pacjentów. Układa więc indywidualny plan dietetyczny dla każdego pacjenta oraz nadzoruje go. W zależności od wyników badań czy informacji uzyskanych od pacjenta wprowadza potrzebne modyfikacje. Ścisła współpraca z pacjentem i jego lekarzem jest więc niezwykle istotna.',
                        bulletsTitle: 'Kiedy skonsultować się z dietetykiem?',
                        bullet1: 'Kiedy lekarz Neurosphery zasugerował leczenie dietą ketogenną',
                        bullet2: 'Kiedy myślisz, że dieta ketogenna może być opcją leczenia w Twoim przypadku (UWAGA – wizyta u lekarza też jest niezbędna, jeśli jesteś Pacjentem Neurospehy zapytaj lekarza o taką opcję leczenia, jeśli nie jesteś jeszcze Pacjentem Neurosphery umów się na wizytę ZERO)',
                        bullet3: 'Kiedy stosujesz dietę ketogenną i chcesz zweryfikować obecne leczenie (UWAGA – wizyta u lekarza też jest niezbędna)',
                        bullet4: 'Kiedy stosujesz dietę ketogenną – wizyta kontrolna (dietetyk na wizycie poinformuje Ciebie kiedy najlepiej się zapisać)',
                        bullet5: 'Kiedy chcesz zweryfikować swój obecny sposób odżywiania',
                    },
                    neurologist: {
                        title: 'Kim jest neurolog?',
                        description: 'Specjalista, który obserwuje reakcje ciała pacjenta, bada czynności układu nerwowego, jak również sprawdza reakcje ludzkiego organizmu na bodźce. Zajmuje się schorzeniami obwodowego oraz ośrodkowego układu nerwowego. Neurolog skupia się przede wszystkim na schorzeniach, których podłożem są procesy niszczące układ nerwowy człowieka. Bada podłoże powstałych chorób neurologicznych, stawia diagnozę i odpowiednie leczenie. Zajmuje się leczeniem takich schorzeń, jak m.in. rwa kulszowa, zwyrodnienie kręgosłupa, padaczka, choroba Parkinsona, choroba Alzheimera, pląsawica Huntingtona, udary mózgu oraz stany po udarze, migreny i pozostałe bóle głowy.',
                        bulletsTitle: 'Kiedy skonsultować się z neurologiem?',
                        bullet1: 'Kiedy występują omdlenia oraz utraty przytomności',
                        bullet2: 'Kiedy gwałtownie pogarsza się pamięć i występują problemy z koncentracją',
                        bullet3: 'Kiedy występują kłopoty ze snem',
                        bullet4: 'Kiedy występują zaburzenia równowagi oraz koordynacji ruchowej',
                        bullet5: 'Jeśli występuje mrowienie kończyn wraz z zaburzeniem czucia',
                        bullet6: 'Jeśli występuje chroniczne bóle, połączone z zawrotami głowy',
                    },
                    neurosurgeon: {
                        title: 'Kim jest neurochirurg?',
                        description: 'Lekarz chirurg, specjalizujący się w diagnostyce i leczeniu operacyjnym niektórych chorób układu nerwowego (mózgu, rdzenia kręgowego, nerwów obwodowych), w przypadku których leczenie farmakologiczne nie dało efektów i należy wykonać zabieg chirurgiczny.',
                        bulletsTitle: 'Kiedy skonsultować się z neurochirurgiem?',
                        bullet1: 'Wystąpienie problemów neurologicznych, których leczenie farmakologiczne nie dało efektów, np. dyskopatia, przewlekłe bóle i urazy kręgosłupa, guzy mózgu i nowotworowe zmiany w obrębie kręgosłupa, naczyniaki itp.',
                        bullet2: 'Silne i długotrwałe bóle oraz zawroty głowy',
                        bullet3: 'Drętwienie lub mrowienie kończyn o nieznanej przyczynie',
                        bullet4: 'Występowanie częstych omdleń',
                        bullet5: 'Występowanie zaburzeń, widzenia, słuchu o nieznanej przyczynie',
                        bullet6: 'Występowanie niedowładów kończyn'
                    },
                    neuropsychologist: {
                        title: 'Kim jest neuropsycholog?',
                        description: 'Specjalista psychologii klinicznej, zajmujący się związkami między funkcjonowaniem mózgu a zachowaniem człowieka. Działania neuropsychologa kierowane są głównie do osób z uszkodzeniem mózgu, wywołanym różnymi chorobami, uszkodzeniami lub urazami.',
                        bulletsTitle: 'Kiedy skonsultować się z neuropsychologiem?',
                        bullet1: 'Kiedy u chorego stwierdzono problemy w normalnym funkcjonowaniu, jakie pojawiły się na skutek np. urazów głowy, guzów mózgu, chorób niszczących tkankę nerwową. np. choroby Alzhaimera czy Parkinsona',
                        bullet2: 'Wystąpienie osłabienia funkcjonowania pamięci',
                        bullet3: 'Problemy z koncentracją',
                        bullet4: 'Wystąpienie zaburzeń mowy',
                        bullet5: 'Wystąpienie trudności związanych z orientacją, odnajdywaniem drogi',
                        bullet6: 'Występują zaburzenia wzroku mimo prawidłowych wyników okulistycznych',
                        bullet7: 'Pojawiają się zmiany nastroju, stany lękowe, przywidzenia',
                        bullet8: 'W stanach uzależnień'
                    },
                    neurologopedist: {
                        title: 'Kim jest neurologopeda?',
                        description: 'Specjalista, zajmujący się diagnozą oraz terapią zaburzeń mowy oraz komunikacji, pochodzenia neurologicznego. Neurologopeda może pracować zarówno z dziećmi, które mają opóźniony rozwój mowy lub zaburzenia mowy na różnym tle, a także z pacjentami dorosłymi.',
                        bulletsTitle: 'Kiedy skonsultować się z neurologopedą?',
                        bullet1: 'Kiedy dziecko ma zdiagnozowane zaburzenia neurologiczne, zespół genetyczny lub zaburzenia ze spektrum autyzmu',
                        bullet2: 'Kiedy rodzic zaobserwuje u dziecka opóźnienie psychoruchowe',
                        bullet3: 'Kiedy dziecko ma problemy z mówieniem – 2-letnie lub starsze nie mówi wcale, mówi mało, lub ma wadę wymowy',
                        bullet4: 'Kiedy dziecko nadmiernie się ślini, ma problemy z jedzeniem, nie domyka buzi i nie oddycha przez nos',
                        bullet5: 'Kiedy dorosły ma zaburzenia mowy po udarze lub wypadku',
                        bullet6: 'Kiedy dorosły ma zaburzenia mowy w efekcie demencji starczej, choroby Parkinsona, Alzheimera czy stwardnienia rozsianego'
                    },
                    psychiatrist: {
                        title: 'Kim jest psychiatra?',
                        description: 'Lekarz zajmujący się problemami zdrowia psychicznego – zaburzeń oraz chorób psychicznych, zarówno w kwestii diagnostyki schorzeń, jak i dalszej terapii. Psychiatra może prowadzić leczenie farmakologiczne oraz skierować na dodatkowe konsultacje innych specjalności.',
                        bulletsTitle: 'Kiedy skonsultować się z psychiatrą?',
                        bullet1: 'Kiedy przez dłuższy czas dokuczają: smutek, cierpienie, apatia, brak energii, bezradność, pesymistyczne widzenie przyszłości',
                        bullet2: 'Wystąpienie myśli samobójczych',
                        bullet3: 'W problemach i zaburzeniach snu',
                        bullet4: 'Przy zaburzeniach lękowych',
                        bullet5: 'Pojawienie się nadaktywności, nienaturalnie podwyższonego nastroju i natłoku myśli',
                        bullet6: 'Wystąpienie problemów z koncentracją i zaburzeń pamięci',
                        bullet7: 'Unikanie kontaktów i wycofanie się z życia społecznego',
                        bullet8: 'Przy pojawieniu się urojeń, wpływających na postrzeganie rzeczywistości i halucynacji'
                    },
                    epileptologist: {
                        title: 'Kim jest epileptolog?',
                        description: 'Lekarz neurolog, specjalizujący się w diagnozowaniu i terapii padaczki. Epileptolog może specjalizować się w diagnostyce i leczeniu padaczki u dzieci lub dorosłych. Oprócz dobrania terapii lekarz koordynuje proces terapeutyczny, kierując chorego również do lekarzy innych specjalności.',
                        bulletsTitle: 'Kiedy skonsultować się z epileptologiem?',
                        bullet1: 'Wystąpienie nagłego napadu, podczas którego chory traci przytomność i upada, a mięśnie ulegają silnemu napięciu. Następują drgawki a chory może przestać oddychać',
                        bullet2: 'Wystąpienie nagłej utraty kontaktu utraty z otoczeniem, a chory zastyga w bezruchu',
                        bullet3: 'Wystąpienie skurczów mięśni bez utraty przytomności',
                        bullet4: 'Wystąpienie zwiotczenia mięśni, utrata przytomności, upadek chorego'
                    },
                    geneticist: {
                        title: 'Kim jest genetyk?',
                        description: 'Genetyk kliniczny to lekarz, którego zadaniem jest ustalenie, czy choroba jest dziedziczna i jaki mechanizm doprowadził do odziedziczenia występujących nieprawidłowości. Genetyk określa także prawdopodobieństwo wystąpienia tych samych wad albo schorzeń u kolejnych pokoleń. Jego celem jest także zidentyfikowanie członków rodziny osoby chorej, u których może pojawić się choroba dziedziczna. Rolą genetyka jest również poinformowanie rodziny o profilaktyce i możliwościach leczenia choroby oraz objęcie jej poradnictwem genetycznym. Ważnym elementem jest też wdrożenie specjalistycznego leczenia choroby dziedzicznej.',
                        bulletsTitle: 'Kiedy skonsultować się z genetykiem?',
                        bullet1: 'Kiedy w Twojej rodzinie występuje choroba o stwierdzonych lub przypuszczalnych przyczynach genetycznych',
                        bullet2: 'Kiedy chcesz wykonać badanie WES',
                        bullet3: 'Kiedy chcesz wkluczyć chorobę wrodzoną, która może być przyczyną padaczki',
                        bullet4: 'Kiedy planujesz ciąże i chcesz dowiedzieć się czy twoje dziecko może również chorować na padaczkę'
                    },
                    absent: {
                        title: 'Czym jest konsultacja zaoczna?',
                        description: 'Konsultacja zaoczna jest rodzajem wizyty medycznej, którą przeprowadza wybrany specjalista Neurosphera bez obecności pacjenta. Konsultacja zaoczna ma na celu analizę dokumentacji medycznej (karty wypisowe, wyniki badań genetycznych i inne) lub badania dodatkowego (EEG, MRI i inne). Specjalista Neurosphery dokona analizy dokumentacji, sporządzi stosowną notatkę i zalecenia dla pacjenta. Fakt jej odbycia oraz jej wynik trafią do archiwum wizyt pacjenta. UWAGA: konsultacja zaoczna trwa 30 minut i jest wykonywana bez udziału pacjenta.',
                        bulletsTitle: 'Kiedy zapisać się na konsultację zaoczną?',
                        bullet1: 'Skierowanie do zapisania się na konsultację zaoczną otrzymasz podczas wizyty 1 lub kolejnej – w przypadku, gdy lekarz uzna zasadność takiej konsultacji. Jej celem jest wykonanie dodatkowej analizy klinicznej, która wykracza poza ramy czasowe lub wymaga konsultacji z innym lekarzem (przykład: okazuje się np. że zachodzi konieczność konsultacji badania metabolicznego z wybranym specjalistą lub należy przeanalizować kilka badań MRI z okresu kilku lat).',
                        bullet2: 'Zazwyczaj podczas 30 min konsultacji specjalista kliniczny ma możliwość zapoznać się z dwoma badaniami dodatkowymi posiadającymi opisy.'
                    },
                    metabolic: {
                        title: 'Czym jest konsultacja metaboliczna?',
                        description: 'Konsultacja metaboliczna dotyczy chorób rzadkich - wrodzonych wad metabolizmu, które są chorobami genetycznymi. Diagnostyka chorób metabolicznych obejmuje głównie liczne badania oraz testy laboratoryjne, często poparte badaniami specjalistycznymi.',
                        bulletsTitle: 'Kiedy zapisać się na konsultację metaboliczną?',
                        bullet1: 'Na konsultację metaboliczną warto zgłosić się, jeśli jest podejrzenie choroby w badaniach genetycznych, lub jeśli badania: tandemowa spektrometria mas z krwi (tandem MS, MS/MS)  lub profil kwasów organicznych z moczu (badanie GC-MS) wyjdą nieprawidłowo.',
                        bullet2: 'Część chorób przebiegających z padaczką, może mieć podłoże metaboliczne, np. deficyt dehydrogenazy pirogronianu (PDHD), deficyt transportera glukozy do mózgu (GLUT-1 DS) czy wybrane choroby mitochondrialne.'
                    },
                    caseConference: {
                        title: 'Czym jest konsylium medyczne?',
                        description: 'Konsylium medyczne lekarzy specjalistów i partnerów medycznych Neurosphery jest zbiorowym naradzeniem się w celu omówienia przypadku klinicznego pacjenta oraz ustalenia optymalnego planu leczenia. Konsylium jest organizowane w sytuacjach, w których potrzebna jest pomoc lub dodatkowa wiedza specjalistyczna, aby osiągnąć najlepsze rezultaty leczenia. Jest to rodzaj interdyscyplinarnej konferencji medycznej, podczas której odbywa się wymiana wiedzy i opinii pomiędzy specjalistami.'
                    },

                    visit0: {
                        bulletsTitle: 'Czym jest Ankieta 0?',
                        bullet1: '<b>Poprzedza i przygotowuje</b> Wizytę 1 u lekarza Specjalisty Klinicznego Neurosphery',
                        bullet2: 'Jest <b>szczegółowym wywiadem nt. historii Twojej choroby</b>',
                        bullet3: 'Prowadzi ją <b>osoba z przeszkoleniem medycznym</b>, którą wybierzemy indywidualnie dla Ciebie',
                        bullet4: '<b>Przygotuj się do niej.</b> Przypomnij sobie jakie leki były przyjmowane, jak często występują napady, jak przebiegają i jaki mają charakter',
                        bullet5: 'Osoba przeprowadzająca Ankietę 0 sporządzi cyfrową dokumentacją Twojego przypadku w formie ustrukturyzowanego wywiadu. Tak uporządkowane cyfrowe dane będą mogły zostać przeanalizowane przez naszego robota Ictal, aby zasugerować diagnozę i terapię podczas Wizyty 1',
                        bullet6: 'Czas trwania <b>do 60 minut</b>',
                        bullet7: 'Możesz wybrać rodzaj wizyty – <b>online lub stacjonarną</b>'
                    },
                    visit1: {
                        bulletsTitle: 'Czym jest Wizyta 1?',
                        bullet1: 'To wizyta u Specjalisty Klinicznego Neurosphery, którego samodzielnie wybierzesz',
                        bullet2: 'Lekarz zapozna się z wynikami Ankiety 0, wynikami analizy naszego robota Ictal oraz dołączonymi przez Ciebie materiałami, co pozwoli mu skutecznie potwierdzić diagnozę oraz terapię',
                        bullet3: 'Zaproponuje Ci wykonanie dodatkowych badań, o ile będą konieczne',
                        bullet4: 'Zaproponuje Ci kompleksowy plan opieki',
                        bullet5: 'Czas trwania <b>do 30 minut</b>',
                        bullet6: 'Możesz wybrać rodzaj wizyty – <b>online lub stacjonarną</b>'
                    },
                    veegOnStandby: {
                        title: 'Na czym polega badanie VEEG w czuwaniu?',
                        description: 'Badanie VEEG polega na zapisie w czasie rzeczywistym zsynchronizowanego badania EEG oraz Video. Dla prawidłowej diagnozy lekarz musi wiedzieć, co się dzieje z chorym podczas napadów dlatego podczas badania pacjent jest nagrywany. Ważne są wszystkie szczegóły: w którą stronę przekręci głowę, w którą „uciekają” gałki oczne, gdzie występują drgawki napadowe itd. Wideometryczne badanie pozwala lekarzowi zobaczyć pacjenta w tym kluczowym dla rozpoznania choroby momencie. Badanie wykonuje się w warunkach specjalnie przygotowanej pracowni. Badanie w czuwaniu wykonywane jest w ciągu dnia. Pacjent zgodnie z zaleceniami lekarza decyduje ile ma trwać badanie. Pacjent do dyspozycji ma fotel oraz łóżko w gabinecie. Pacjent ma możliwość korzystania z toalety w trakcie badania pozostając przypiętym do aparatu EEG. Po badaniu pacjent dostaje płytę z nagranym zapisem. Opis badania wliczony jest w cenę.',
                        bulletsTitle: 'Jak przygotować się do badania?',
                        bullet1: 'Pacjent powinien mieć czystą głowę',
                        bullet2: 'Przed badaniem nie powinno się nakładać na włosy żelu czy lakieru',
                        bullet3: 'Leki przyjmujemy wg zaleceń lekarza',
                        bullet4: 'Bez ustaleń z lekarzem nie wykonujemy deprywacji snu - pacjent przychodzi wyspany',
                    },
                    veegDuringSleep: {
                        title: 'Na czym polega badanie VEEG we śnie?',
                        description: 'Badanie VEEG polega na zapisie w czasie rzeczywistym zsynchronizowanego badania EEG oraz Video. Dla prawidłowej diagnozy lekarz musi wiedzieć, co się dzieje z chorym podczas napadów dlatego podczas badania pacjent jest nagrywany. Ważne są wszystkie szczegóły: w którą stronę przekręci głowę, w którą „uciekają” gałki oczne, gdzie występują drgawki napadowe itd. Wideometryczne badanie pozwala lekarzowi zobaczyć pacjenta w tym kluczowym dla rozpoznania choroby momencie. Badanie wykonuje się w warunkach specjalnie przygotowanej pracowni. Badanie we śnie wykonywane jest w wieczorem lub w nocy. Pacjent zgodnie z zaleceniami lekarza decyduje ile ma trwać badanie. Pacjent do dyspozycji ma fotel oraz łóżko w gabinecie. Pacjent ma możliwość korzystania z toalety w trakcie badania pozostając przypiętym do aparatu EEG. Duże dzieci i dorośli leżą sami na łóżku małe dzieci z opiekunem, poczekalnia do dyspozycji osoby towarzyszącej. Po badaniu pacjent dostaje płytę z nagranym zapisem. Opis badania wliczony jest w cenę.',
                        bulletsTitle: 'Jak przygotować się do badania?',
                        bullet1: 'Pacjent powinien mieć czystą głowę',
                        bullet2: 'Przed badaniem nie powinno się nakładać na włosy żelu czy lakieru',
                        bullet3: 'Leki przyjmujemy wg zaleceń lekarza',
                        bullet4: 'Bez ustaleń z lekarzem nie wykonujemy deprywacji snu - pacjent przychodzi wyspany',
                        bullet5: 'Nie zaszkodzi zabrania ze sobą ulubionej poduszki z domu',
                    },
                    veegNight: {
                        title: 'Na czym polega badanie VEEG 12 godz.?',
                        description: 'Badanie VEEG polega na zapisie w czasie rzeczywistym zsynchronizowanego badania EEG oraz Video. Dla prawidłowej diagnozy lekarz musi wiedzieć, co się dzieje z chorym podczas napadów dlatego podczas badania pacjent jest nagrywany. Ważne są wszystkie szczegóły: w którą stronę przekręci głowę, w którą „uciekają” gałki oczne, gdzie występują drgawki napadowe itd. Wideometryczne badanie pozwala lekarzowi zobaczyć pacjenta w tym kluczowym dla rozpoznania choroby momencie. Badanie wykonuje się w warunkach specjalnie przygotowanej pracowni. Pacjent do dyspozycji ma fotel oraz łóżko w gabinecie. Standardowo badanie zaczynamy od momentu zasypiania pacjenta, następnie przechodzimy poszczególne fazy oraz na końcu rejestrujemy moment wybudzenia. Pacjent ma możliwość korzystania z toalety w trakcie badania pozostając przypiętym do aparatu EEG. Duże dzieci i dorośli leżą sami na łóżku małe dzieci z opiekunem, poczekalnia do dyspozycji. Po badaniu pacjent dostaje płytę z nagranym zapisem. Opis badania wliczony jest w cenę.',
                        bulletsTitle: 'Jak przygotować się do badania?',
                        bullet1: 'Pacjent powinien mieć czystą głowę',
                        bullet2: 'Przed badaniem nie powinno się nakładać na włosy żelu czy lakieru',
                        bullet3: 'Leki przyjmujemy wg zaleceń lekarza',
                        bullet4: 'Bez ustaleń z lekarzem nie wykonujemy deprywacji snu - pacjent przychodzi wyspany',
                        bullet5: 'Nie zaszkodzi zabrania ze sobą ulubionej poduszki z domu'
                    },
                    veegDay: {
                        title: 'Na czym polega badanie VEEG dobowe?',
                        description: 'Badanie VEEG polega na zapisie w czasie rzeczywistym zsynchronizowanego badania EEG oraz Video. Dla prawidłowej diagnozy lekarza musi wiedzieć, co się dzieje z chorym podczas napadów dlatego podczas badania pacjent jest nagrywany. Ważne są wszystkie szczegóły: w którą stronę przekręci głowę, w którą „uciekają” gałki oczne, gdzie występują drgawki napadowe itd. Wideometryczne badanie pozwala lekarzowi zobaczyć pacjenta w tym kluczowym dla rozpoznania choroby momencie. Badanie wykonuje się w warunkach specjalnie przygotowanej pracowni. Pacjent do dyspozycji ma fotel oraz łóżko w gabinecie. Standardowo badanie zaczynamy od badania nocnego potem monitorujemy pacjenta w ciągu dnia. Pacjent ma możliwość korzystania z toalety w trakcie badania pozostając przypiętym do aparatu EEG. Posiłki wliczone są w cenę badania. Duże dzieci i dorośli leżą sami na łóżku małe dzieci z opiekunem, poczekalnia do dyspozycji. Po badaniu pacjent dostaje płytę z nagranym zapisem. Opis badania wliczony jest w cenę.',
                        bulletsTitle: 'Jak przygotować się do badania?',
                        bullet1: 'Pacjent powinien mieć czystą głowę',
                        bullet2: 'Przed badaniem nie powinno się nakładać na włosy żelu czy lakieru',
                        bullet3: 'Leki przyjmujemy wg zaleceń lekarza',
                        bullet4: 'Bez ustaleń z lekarzem nie wykonujemy deprywacji snu - pacjent przychodzi wyspany',
                        bullet5: 'Nie zaszkodzi zabrania ze sobą ulubionej poduszki z domu',
                        bullet6: 'Więcej szczegółów należy uzgodnić z technikiem, dzwoniąc do recepcji przychodni +48 609 463 191'
                    },
                    eegConsultation: {
                        title: 'Na czym polega badanie EEG?',
                        description: 'Standardowe badanie EEG wykonywane jest w czuwaniu. Technik dobiera odpowiedni czepek i umieszcza na głowie pacjenta elektrody. Dla jakości badania ważne jest, aby maksymalnie dokładnie przylegały do skóry głowy. Samo badanie trwa około 20 minut. Bezpośrednio po nim pacjent może powrócić do normalnej aktywności. Pacjent po badaniu dostaje płytę z nagranym zapisem. Opis badania wliczony jest w cenę.',
                        bulletsTitle: 'Jak przygotować się do badania?',
                        bullet1: 'Pacjent powinien mieć czystą głowę',
                        bullet2: 'Przed badaniem nie powinno się nakładać na włosy żelu czy lakieru',
                        bullet3: 'Leki przyjmujemy wg zaleceń lekarza',
                        bullet4: 'Bez ustaleń z lekarzem nie wykonujemy deprywacji snu - pacjent przychodzi wyspany '
                    },
                    sleepDisturbance: {
                        title: 'Czym jest konsultacja zaburzeń snu?',
                        description: 'Zaburzenia snu mogą być pierwotne (wynikać z nieprawidłowości w przebiegu samego snu) jak również być objawem chorób z innych układów. Czasami wskazana jest konsultacja psychiatryczna. W trakcie wizyty zostanie przeprowadzony szczegółowy wywiad obejmujący przebieg nocy i plan dnia. Warto przygotować na taką wizytę dzienniczek snu obejmujący co najmniej 5 dni oraz nagrać domowymi sposobami ewentualne niepokojące epizody występujące w nocy. Po konsultacji zostanie ocenione czy dany problem jest natury fizjologicznej czy nie. Jeżeli zostanie stwierdzone zaburzenie snu zostanie zaproponowane postępowanie – leczenie albo skierowanie do specjalisty, który zajmuje się problem leżącym u podstawy danego zaburzenia snu.',
                        bulletsTitle: 'Kiedy zapisać się na konsultację zaburzeń snu?',
                        bullet1: 'Kiedy jesteś senny lub rozdrażniony w ciągu dnia',
                        bullet2: 'Kiedy obserwujesz u siebie bezsenność lub nadmierną senność wywołaną czynnikami emocjonalnymi',
                        bullet3: 'Kiedy obserwujesz zaburzenia dobowego rytmu snu i wstawania',
                        bullet4: 'Kiedy podczas snu chrapiesz'
                    },
                    regularVisitEegPackage: {
                        title: 'Czym jest pakiet wizyta regularna + badanie EEG?',
                        description: 'Badanie EEG – jest standardowym badaniem oceny czynności bioelektrycznej mózgu, na podstawie którego ocenia się prawidłowość jego działania. Badanie trwa około 20 minut. Po badaniu pacjent udaje się na wizytę. Wizyta REGULARNA Stacjonarna – to każda kolejna wizyta. Trwa do 30 minut. Podczas wizyty lekarz analizuje wykonane badanie EEG, ustala ostateczne rozpoznanie i analizuje dane zebrane w systemie. Pacjent dostaje płytę z nagranym zapisem badania EEG oraz zalecenia z wizyty regularnej.',
                        bulletsTitle: 'Kiedy zapisać się na pakiet?',
                        bullet1: 'Badanie EEG jest podstawowym badaniem w diagnostyce padaczki',
                        bullet2: 'Pacjent chorujący na padaczkę powinien regularnie co roku wykonywać badanie EEG oraz odbyć wizytę regularną z lekarzem epileptologiem',
                        bullet3: 'Jeśli planujesz wizytę stacjonarną u lekarza, warto wtedy wykonać badanie EEG w celu oceny czynności bioelektrycznej mózgu',
                        bullet4: 'Czujesz brak poprawy lub pogorszenie stanu zdrowia, chcesz porozmawiać z lekarzem o zmianie leczenia'
                    },
                    adhdPackage: {
                        title: 'Jak wygląda diagnostyka ADHD?',
                        description: 'Diagnostyka ADHD składa się z 2 spotkań z neuropsychologiem, każde trwa po 1,5 h, na których przeprowadzony jest wywiad kliniczny oraz testy neuropsychologiczne. Po spotkaniach psycholog wydaję opinię i zaleca do kogo ma się udać pacjent, czy do neurologa (30 min.) czy do psychiatry (45 min). W trakcie odpowiedniej wizyty pacjent otrzyma diagnozę i zalecenia co do dalszego procesu leczenia (leczenie farmakologiczne lub terapia psychologiczna).',
                        bulletsTitle: 'Kiedy zapisać się na pakiet ADHD?',
                        bullet1: 'Jeśli występują trudności z organizacją czasu i zadań. Trudno Ci zaczynać i kończyć projekty, planować i utrzymywać porządek',
                        bullet2: 'Jeśli występują trudności emocjonalne, masz skłonność do zmian nastrojów, frustracji i trudności w kontrolowaniu emocji. Doświadczasz silnych reakcji na stresowe sytuacje',
                        bullet3: 'Kiedy zauważasz u siebie trudności interpersonalne. Jesteś niespokojny, impulsywny i masz trudności z uwzględnianiem perspektywy innych osób',
                        bullet4: 'Jeśli zauważasz u siebie trudności z oszacowaniem czasu potrzebnego na wykonanie zadania'
                    },
                    mmpi2Package: {
                        title: 'Co to jest test osobowości MMPI-2?',
                        description: 'Dajemy możliwość przeprowadzenia diagnozy zaburzeń osobowości za pomocą najobszerniejszego i najbardziej uniwersalnego narzędzia oceny klinicznej, dostępnego w Polsce – Minnesockiego Wielowymiarowego Inwentarza Osobowości (pełna adaptacja MMPI-2 została wydana w 2012). Test zawiera dziesięć skal kontrolnych, dzięki którym można opisać wiarygodność uzyskanego profilu wyników i uzasadnić ich kliniczną interpretację. Skale kontrolne mogą również informować diagnostę o motywacji osoby badanej, jej poziomu przystosowania oraz o ewentualnych mechanizmach obronnych.',
                        bulletsTitle: 'Na czym polega test osobowościowy MMPI-2?',
                        bullet1: 'Sam inwentarz może być stosowany w klinicznym opisie problemów osobowości, ale też może być przydatny w celu identyfikowania osób nieprzystosowanych do pełnienie określonej funkcji zawodowej. Można go używać w diagnozie osób dorosłych, które osiągnęły co najmniej 6 lat edukacji formalnej. Test należy przeprowadzić pod nadzorem psychologa. Na samo przeprowadzenie testu, jego wypełnianie, należy przeznaczyć ok. 90 minut. Wypełniając test, należy odnieść się do 567 stwierdzeń, zaznaczając odpowiedzi PRAWDA/FAŁSZ. Samo wypełnienie testu powinno być poprzedzone spotkaniem, ok. 50 minut, w celu zebrania danych z wywiadu oraz przedstawienia osobie badanej testu. W ciągu tygodnia, po zakończonej wizycie z wypełnieniem testu, osoba badana uzyska raport podsumowujący uzyskane wyniki.'
                    },
                    cbtPackage: {
                        title: 'Czym jest terapia CBT w bezsenności?',
                        description: 'Terapia poznawczo-behawioralna (CBT) w leczeniu bezsenności jest uznawana za leczenie pierwszego wyboru dla osób zmagających się z bezsennością, rekomendowane przez takie autorytety jak National Institutes of Health (NIH), National Institute for Health and Care Excellence (NICE) oraz American College of Physicians. CBT-I stanowi skuteczną alternatywę dla leków nasennych, oferując trwałe rozwiązania poprzez zmianę nawyków i myślenia związanego ze snem. Cały proces CBT-I obejmuje pięć sesji rozłożonych na sześć tygodni, ale poprawę można zauważyć już zwykle wcześniej. Sesje można odbywać stacjonarnie lub online. Około 75% osób z przewlekłą bezsennością doświadcza znaczącej poprawy snu, a 90% redukuje lub całkowicie rezygnuje z leków nasennych.',
                        bulletsTitle: 'Terapia CBT-I jest zalecana dla osób, które:',
                        bullet1: 'Doświadczają przewlekłej bezsenności: Problemy ze snem występują przynajmniej trzy razy w tygodniu przez co najmniej trzy miesiące.',
                        bullet2: 'Mają trudności z zasypianiem: Spędzają więcej niż 30 minut próbując zasnąć wieczorem.',
                        bullet3: 'Budzą się w nocy: Regularnie budzą się w środku nocy i mają trudności z ponownym zaśnięciem, co trwa dłużej niż 30 minut.',
                        bullet4: 'Czują się przez to zmęczone i drażliwe w ciągu dnia: Bezsenność wpływa na codzienne samopoczucie i jakość życia.',
                        bullet5: 'Szukają alternatywy dla leków nasennych: Chcą unikać uzależnienia od farmakoterapii i poszukują długoterminowych, naturalnych rozwiązań.'
                    },
                    standardVisit: {
                        title: 'Czym jest wizyta regularna?',
                        description: 'Wizyta REGULARNA – to każda kolejna wizyta. Trwa do 30 minut. Podczas wizyty lekarz zbiera wywiad i analizuje kalendarz zdarzeń pacjenta. Lekarz zapoznaje się z dokumentację medyczną pacjenta, może zaproponować wykonanie dodatkowych badań. Po każdej wizycie pacjent otrzymuje zalecenia z wizyty, plan terapii oraz plan dalszej opieki. Pacjent może wybrać rodzaj wizyty – online lub stacjonarną.',
                        bulletsTitle: 'Jak wygląda wizyta regularna:',
                        bullet1: 'Omówienie aktualnego stanu zdrowia -  przypomnij sobie datę ostatniego napadu oraz swoje samopoczucie - uzupełnij kalendarz zdarzeń',
                        bullet2: 'Zapoznanie się z wynikami ostatnich badań - jeśli wykonywałeś w ostatnim czasie badania upewnij się czy wszystkie wyniki są dołączone do dokumentacji medycznej',
                        bullet3: 'Omówienie dalszej terapii',
                        bullet4: 'Ustalenie kompleksowego planu opieki',
                        bullet5: 'Wystawienie recepty, skierowania, zwolnienia, zaświadczenia'
                    }
                },
                visitPackageDescriptions: {
                    startPackage1: 'Jako nowy pacjent Neurosphery lub rodzic pacjenta musisz odbyć Wizytę 1 z lekarzem Specjalistą Klinicznym. Będzie ona poprzedzona Ankietą 0, która pozwoli nam zebrać informacje w celu skutecznej diagnozy i terapii. To wszystko w jednej, przystępnej cenie.',
                    startPackage2: 'Wybierz lekarza Specjalistę, u którego odbędziesz Wizytę 1 oraz to, czy odbędzie się ona online czy stacjonarnie. Zwróć uwagę na różnice w cenach oraz lokalizację wizyty stacjonarnej.',
                    startPackage3: 'Ankieta 0 poprzedza i przygotowuje do Wizyty 1. Odbędzie się z wybraną przez nas osobą z przeszkoleniem medycznym. Wybierz dogodny dla Ciebie rodzaj Ankiety 0 (online lub stacjonarna) oraz jej termin. Pamiętaj, że Ankieta 0 trwa do 60 minut.',
                    startPackage4: 'Dokonanie płatności pozwala nam zarezerwować dla Ciebie termin wizyty u danego lekarza. Pamiętaj, zawsze możesz bezpłatnie zmienić jej datę!'
                },
                slotChoiceDescriptions: {
                    VISIT_1: 'Wizyta 1 odbywa się po Ankiecie 0. Specjalista Kliniczny Neurosphery oraz rodzaj wizyty zostały przez Ciebie wybrane już w poprzednim kroku. Lekarz postawi  diagnozę i zaproponuje dopasowaną terapię. Przygotuje także indywidualny plan opieki. Pamiętaj, Wizyta 1 trwa do 30 minut.'
                },
                payments: {
                    directPayment: 'Z {provider} bezpiecznie zapłacisz:',
                    creditCard: 'Kartą',
                    blik: 'Blikiem',
                    onlineTransfer: 'Przelewem online',
                    onlineApplication: 'Wniosek online',
                    mobilePayments: 'Płatności mobilne',
                    payLater: 'Płacę później'
                }
            },
            paymentOutcome: {
                title: 'Potwierdzenie płatności',
                loading: 'Trwa pobieranie danych o płatności',
                status: {
                    title: 'Status',
                    types: {
                        pending: {
                            title: 'Trwa przetwarzanie płatności',
                            subtitle: 'Płatność nie została jeszcze potwierdzona!',
                            comment: 'Prosimy poczekać na przetworzenie płatności'
                        },
                        success: {
                            title: 'Usługa została opłacona',
                            subtitle: 'Płatność została potwierdzona!',
                            comment: 'Dziękujemy za dokonanie płatności'
                        },
                        error: {
                            title: 'Usługa nie została opłacona',
                            subtitle: 'Płatność została anulowana!',
                            comment: 'Prosimy powtórzyć płatność'
                        }
                    }
                },
                details: {
                    title: 'Szczegóły',
                    date: 'Data',
                    name: 'Nazwa usługi',
                    doctor: 'Lekarz'
                }
            },
            visitPaymentOutcome: {
                title: 'Umawianie wizyty',
                subtitle: 'potwierdzenie',
                details: 'szczegóły',
                loading: 'Trwa pobieranie danych wizyty...',
                card: {
                    successTitle: 'Twoja wizyta została opłacona',
                    successLabel1: 'Dziękujemy za dokonanie płatności',
                    successLabel1Package: 'Dziękujemy! Jesteś pod dobrą opieką!',
                    successLabel2: '24h przed wizytą otrzymasz powiadomienie SMS-em',
                    failTitle: 'Twoja wizyta nie została opłacona',
                    failLabel1: 'Wystąpił błąd podczas płatności za wizytę',
                    retryPayment: 'Ponów płatność',
                    toGoMessageView: 'Przejdź do widoku wiadomości',
                    paymentPending: 'Płatność nie została jeszcze potwierdzona, może to potrwać około minuty',
                    paymentConfirmed: 'Płatność została potwierdzona!',
                },
                goHome: 'Powrót do strony głównej',
                visitScope: 'Zakres wizyty',
                scopeBullets: {
                    visit0Bullet1: '<b>Poprzedza i przygotowuje</b> Wizytę 1 u lekarza Specjalisty Klinicznego Neurosphery',
                    visit0Bullet2: 'Jest <b>szczegółowym wywiadem nt. historii Twojej choroby</b>',
                    visit0Bullet3: 'Prowadzi ją <b>osoba z przeszkoleniem medycznym</b>, którą wybierzemy indywidualnie dla Ciebie',
                    visit0Bullet4: '<b>Przygotuj się do niej.</b> Przypomnij sobie jakie leki były przyjmowane, jak często występują napady, jak przebiegają i jaki mają charakter',
                    visit0Bullet5: 'Osoba przeprowadzająca Ankietę 0 sporządzi cyfrową dokumentacją Twojego przypadku w formie ustrukturyzowanego wywiadu. Tak uporządkowane cyfrowe dane będą mogły zostać przeanalizowane przez naszego robota Ictal, aby zasugerować diagnozę i terapię podczas Wizyty 1',
                    visit0Bullet6: 'Czas trwania <b>do 60 minut</b>',
                    visit0Bullet7: 'Możesz wybrać rodzaj wizyty – <b>online lub stacjonarną</b>',
                    migrationVisitBullet1: 'Przeznaczona dla Pacjentów, którzy byli lub są pod naszą opieką przed rokiem 2021',
                    migrationVisitBullet2: 'Weryfikacja danych medycznych, biometrycznych i osobowych Pacjenta',
                    migrationVisitBullet3: 'Przejście w nowy system opieki',
                    visit1Bullet1: 'To wizyta u Specjalisty Klinicznego Neurosphery, którego samodzielnie wybierzesz',
                    visit1Bullet2: 'Lekarz zapozna się z wynikami Ankiety 0, wynikami analizy naszego robota Ictal oraz dołączonymi przez Ciebie materiałami, co pozwoli mu skutecznie potwierdzić diagnozę oraz terapię',
                    visit1Bullet3: 'Zaproponuje Ci wykonanie dodatkowych badań, o ile będą konieczne',
                    visit1Bullet4: 'Zaproponuje Ci kompleksowy plan opieki',
                    visit1Bullet5: 'Czas trwania <b>do 30 minut</b>',
                    visit1Bullet6: 'Możesz wybrać rodzaj wizyty – <b>online lub stacjonarną</b>',
                    standardVisitBullet1: 'Czas trwania od 30 do 45 minut',
                    standardVisitBullet2: 'Ustalenie rozpoznania ostatecznego',
                    standardVisitBullet3: 'Stała analiza danych zebranych w systemie ICtal®',
                    standardVisitBullet4: 'Możliwość interwencji w przypadku braku poprawy lub pogorszenia stanu – porada ICtal® lub konsylium',
                    consultationBullet1: 'Czas trwania od 45 do 60 minut',
                    consultationBullet2: 'Wizyta z lekarzem innej specjalizacji',
                    consultationBullet3: 'Wywiad medyczny',
                    consultationBullet4: 'Analiza historii padaczki i chorób współistniejących',
                    consultationBullet5: 'Porada medyczna w zakresie specjalizacji Lekarza Konsultanta',
                    messageBullet1: 'Pamiętaj, że widomość to krótkie pytanie tekstowe na, które lekarz odpowie najszybciej jak to możliwe, w swoich godzinach pracy.',
                    messageBullet2: 'W ramach opłaty możesz skorzystać z wysłania tylko jednej wiadomości – pytania. Gdy lekarz odpowie, masz możliwość zadania wówczas kolejnego pytania uzupełniającego na które lekarz odpowie ponownie, a wątek zostanie zakończony automatycznie.',
                    messageBullet3: 'Uwaga! Lekarz ma prawo odmówić porady w ramach wiadomości jeśli uzna, że Twoje zapytanie kwalifikuje się na wizytę regularną. Wiadomość nie służy do pomocy w stanach nagłych lub zagrażających życiu!'
                }
            },
            neuroscreen: {
                main: {
                    title: 'Przychodnia Neurosphera tworzy nową jakość w leczeniu pacjentów!',
                    subtitle1: 'Skutecznie łączymy wiedzę, wieloletnie doświadczenie z potrzebą ciągłego rozwoju i doskonalenia naszych metod leczenia!',
                    subtitle2: 'Przygotowaliśmy dla Ciebie ankietę, dzięki któej pomożemy Ci wybrać odpowiedniego lekarza i odpowiedni plan opieki w przychodni Neurosphera.',
                    subtitle3: 'Dzięki wypełnieniu ankiety lekarz przed wizytą zapozna się z nią i będzie wiedział z jakim problemem zgłaszasz się na wizytę.',
                    subtitle4: 'Wypełnienie ankiety trwa około 5 minut.',
                    fillNeuroscreen: 'Wypełnij ankietę',
                    fillNeuroscreenDescription: 'Chcę wypełnić ankietę i wejść w plan opieki pacjenta',
                    goToReservation: 'Wybierz lekarza',
                    goToReservationDescription: 'Nie chcę wypełniać ankiety, chcę od razu wybrać lekarza',
                },
                survey: {
                    title: 'Neuroscreen - ankieta pacjenta Neurosphery',
                    subtitle1: 'Po wypełnieniu ankiety podpowiemy Ci na jaką wizytę i do jakiego lekarza powinieneś się zapisać.',
                    subtitle2: 'Informacje, które tu podasz zostaną udostępnione Twojemu lekarzowi i pomogą Cię prawidłowo zdiagnozować i leczyć.',
                    next: 'Dalej',
                    prev: 'Wstecz',
                    save: 'Zakończ ankietę',
                    form: {
                        section: {
                            HEADACHES: 'Bóle głowy',
                            MOVEMENT_ISSUES: 'Zaburzenia ruchowe',
                            MEMORY_ISSUES: 'Zaburzenia pamięci',
                            EATING_ISSUES: 'Zaburzenia odżywiania',
                            CONSCIOUSNESS_ISSUES: 'Zaburzenia świadomości lub przytomności',
                            PSYCHOMOTOR_DEVELOPMENT_ISSUES: 'Zaburzenia rozwoju psychoruchowego',
                            EPILEPSY: 'Padaczka i napady',
                            SPINE_ISSUES: 'Problemy z kręgosłupem',
                            MUTIPLE_SCLEROSIS: 'Stwardnienie rozsiane',
                            DIZZINESS: 'Zawroty głowy',
                            AUTISM: 'Spektrum autyzmu',
                            OTHER_SYMPTOMS: 'Pozostałe objawy'
                        },
                        adult: {
                            field: {
                                headachesGeneral: 'Czy doświadczasz bólów głowy?',
                                headaches1: 'Czy odczuwasz ciągły, tępy ból głowy?',
                                headaches2: 'Czy doświadczasz pulsującego bólu, zazwyczaj po jednej stronie głowy?',
                                headaches3: 'Czy bólom głowy towarzyszą nadwrażliwość na światło, dźwięk, nudności lub wymioty?',
                                headaches4: 'Czy doświadczasz zaburzeń wzroku, jak błyski światła lub plamki przed oczami, przed rozpoczęciem bólu głowy?',
                                headaches5: 'Czy podczas bólu głowy występuje zaczerwienienie oka, łzawienie lub obrzęk powieki po stronie bólu albo uczucie zatkanego nosa?',
                                movementGeneral: 'Czy zauważyłeś(aś) u siebie drżenie kończyn, sztywność mięśni lub trudności z poruszaniem się?',
                                movement1: 'Czy występuje u Ciebie drżenie rąk lub innych części ciała?',
                                movement2: 'Czy drżenie nasila się w spoczynku?',
                                movement3: 'Czy odczuwasz sztywność w mięśniach, utrudniającą rozpoczęcie ruchu?',
                                movement4: 'Czy zauważyłeś(aś) spowolnienie w wykonywaniu codziennych czynności?',
                                movement5: 'Czy zauważyłeś(aś) problemy z chodzeniem, takie jak chód małymi krokami, nieoczekiwane upadki?',
                                memoryIssuesGeneral: 'Czy masz trudności z zapamiętywaniem nowych informacji, znajdowaniem słów lub orientacją przestrzenną?',
                                memoryIssues1: 'Czy masz trudności z zapamiętywaniem niedawnych wydarzeń?',
                                memoryIssues2: 'Czy zdarza Ci się zapominanie imion, dat, problemy ze znalezieniem odpowiednich słów podczas mówienia?',
                                memoryIssues3: 'Czy coraz częściej potrzebujesz pomocy w wykonywaniu codziennych czynności, takich jak obsługa urządzeń domowych, zarządzanie finansami, planowanie?',
                                memoryIssues4: 'Czy masz trudności z orientacją w znanych Ci wcześniej miejscach?',
                                memoryIssues5: 'Czy zauważasz u siebie zmiany w zachowaniu, takie jak wycofanie społeczne, apatia, impulsywność lub brak taktu?',
                                epilepsyGeneral: 'Czy kiedykolwiek doświadczyłeś(aś) utraty przytomności, niekontrolowanych drgawek lub krótkich momentów zawieszania?',
                                epilepsy1: 'Czy doświadczasz nagłych, niewyjaśnionych utrat przytomności?',
                                epilepsy2: 'Czy po utracie przytomności czujesz się zdezorientowany(a) lub masz trudności z natychmiastowym powrotem do normalnych czynności?',
                                epilepsy3: 'Czy utratom przytomności towarzyszą drgawki?',
                                epilepsy4: 'Czy przed utratą przytomności/drgawkami doświadczasz jakichkolwiek specyficznych uczuć lub sygnałów (np. "aura")?',
                                epilepsy5: 'Czy zdarzają się momenty, gdy nagle przestajesz reagować i "zawieszasz się" na krótki czas?',
                                spineIssuesGeneral: 'Czy doświadczasz przewlekłego lub ostrego bólu pleców, karku?',
                                spineIssues1: 'Czy cierpisz na przewlekły ból pleców lub karku?',
                                spineIssues2: 'Czy doświadczasz drętwienia w rękach lub nogach?',
                                spineIssues3: 'Czy zauważyłeś(aś) utratę siły w rękach lub nogach?',
                                spineIssues4: 'Czy masz trudności z kontrolowaniem pęcherza lub jelit?',
                                spineIssues5: 'Czy ból promieniuje z kręgosłupa do innych części ciała?',
                                mutipleSclerosisGeneral: 'Czy zauważyłeś(aś) u siebie odczucie mrowienia, drętwienia lub innych zmian w wrażeniach czuciowych, które mogą występować w różnych częściach ciała?',
                                mutipleSclerosis1: 'Czy doświadczasz nieoczekiwanych okresów osłabienia w kończynach lub trudności z chodzeniem?',
                                mutipleSclerosis2: 'Czy masz problemy z równowagą lub koordynacją ruchową?',
                                mutipleSclerosis3: 'Czy występują u Ciebie nagłe epizody zaburzeń wzroku, takie jak podwójne widzenie lub zamglenie obrazu?',
                                mutipleSclerosis4: 'Czy doświadczasz nietypowego zmęczenia, które nie jest związane z wysiłkiem fizycznym?',
                                mutipleSclerosis5: 'Czy odczuwasz bóle i skurcze mięśniowe?',
                                dizzinessGeneral: 'Czy doświadczasz zawrotów głowy lub zaburzeń równowagi?',
                                dizziness1: 'Czy zawroty głowy pojawiają się nagle i bez ostrzeżenia?',
                                dizziness2: 'Czy podczas zawrotów głowy odczuwasz dodatkowo nudności lub wymioty?',
                                dizziness3: 'Czy przy zmianie pozycji ciała, np. wstawaniu czy przechylaniu głowy, zawroty głowy stają się silniejsze?',
                                dizziness4: 'Czy pojawienie się zawrotów głowy jest związane z bólami ucha lub zmianami w słyszeniu?',
                                dizziness5: 'Czy zawroty głowy są towarzyszone przez szum w uszach lub uczucie pełności w uszach?',
                                otherSymptomsGeneral: 'Czy masz inne objawy, które nie pasują do powyższych kategorii?',
                                otherSymptoms1: 'Czy doświadczasz trudności z przełykaniem lub częstego krztuszenia się podczas jedzenia?',
                                otherSymptoms2: 'Czy masz problemy ze snem, takie jak bezsenność lub nadmierna senność w ciągu dnia?',
                                otherSymptoms3: 'Czy występuje u Ciebie okresowa trudność z koncentracją lub "mgła mózgowa", uniemożliwiająca skupienie się na zadaniach?',
                                otherSymptoms4: 'Czy zauważyłeś(aś) pojawienie się lub nasilenie się chrapania podczas snu?',
                                otherSymptoms5: 'Czy odczuwasz czasami nagłą słabość w jednej części ciała lub po jednej stronie ciała?'
                            }
                        },
                        child: {
                            field: {
                                headachesGeneral: 'Czy u dziecka występują bóle głowy?',
                                headaches1: 'Czy u dziecka występuje ciągły, tępy ból głowy?',
                                headaches2: 'Czy jest to ból pulsujący, zwykle jednostronny?',
                                headaches3: 'Czy bólowi towarzyszy nadwrażliwość na światło i dźwięk, nudności i wymioty?',
                                headaches4: 'Czy dziecko doświadcza zaburzeń wzroku, jak błyski światła lub plamki przed oczami, przed rozpoczęciem bólu głowy?',
                                headaches5: 'Czy podczas bólu głowy występuje zaczerwienienie oka, łzawienie lub obrzęk powieki po stronie bólu albo uczucie zatkanego nosa?',
                                movementGeneral: 'Czy u dziecka występują zaburzenia ruchowe: drżenia, sztywność, niedowłady?',
                                movement1: 'Czy u dziecka występują drżenia?',
                                movement2: 'Czy to drżenie występuje w spoczynku?',
                                movement3: 'Czy to drżenie występuje podczas ruchu?',
                                movement4: 'Czy u dziecka występuje sztywność kończyn która nasila się?',
                                movement5: 'Czy rozwój ruchowy u dziecka nie postępuje lub się wycofuje?',
                                epilepsyGeneral: 'Czy u dziecka występują drgawki?',
                                epilepsy1: 'Czy drgawki się powtarzają?',
                                epilepsy2: 'Czy napady drgawek są różne czy podobne?',
                                epilepsy3: 'Czy drgawki występują w trakcie czuwania?',
                                epilepsy4: 'Czy drgawki występują podczas snu (nocnego lub dziennego)?',
                                epilepsy5: 'Czy drgawki są uogólnione?',
                                epilepsy6: 'Czy drgawki są ogniskowe czyli nie całego ciała, np. jedno lub dwóch kończyn lub jednej połowy ciała?',
                                epilepsy7: 'Czy drgawki są rytmiczne?',
                                consciousnessIssuesGeneral: 'Czy u dziecka występują zaburzenia świadomości i/lub utraty przytomności?',
                                consciousnessIssues1: 'Czy u dziecka dochodzi do zaburzeń świadomości?',
                                consciousnessIssues2: 'Czy u dziecka dochodzi do pełnej utraty przytomności z upadkiem?',
                                consciousnessIssues3: 'Czy u dziecka występują „zawieszki” lub zamyślenia?',
                                consciousnessIssues4: 'Czy stany zaburzeń świadomości trwają kilka sekund?',
                                consciousnessIssues5: 'Czy stany zaburzeń świadomości trwają więcej niż 30 sekund?',
                                eatingIssuesGeneral: 'Czy u dziecka występują zaburzenia odżywania?',
                                eatingIssues1: 'Czy u dziecka występuje patologiczny brak apetytu i łaknienia?',
                                eatingIssues2: 'Czy u dziecka występuje patologiczny nadmierny apetyt?',
                                autismGeneral: 'Czy dziecko ma cechy lub stwierdzone spektrum autyzmu?',
                                autism1: 'Czy dziecko ma rozpoznane spektrum autyzmu?',
                                autism2: 'Czy dziecko ma podejrzenie autyzmu?',
                                psychomotorDevelopmentIssuesGeneral: 'Czy u dziecka występują zaburzenia rozwoju psychoruchowego?',
                                psychomotorDevelopmentIssues1: 'Czy zaburzenia rozwoju psychoruchowego jest stwierdzone i potwierdzone przez lekarza/ośrodek medyczny?',
                                psychomotorDevelopmentIssues2: 'Czy zaburzenie rozwoju psychoruchowego jest tylko podejrzeniem i nie jest potwierdzone?'
                            }
                        }
                    }
                },
                rating: {
                    title: 'Dziękujęmy za wypełnienie ankiety!',
                    subtitle: 'System Neuroterminal podpowie Ci odpowiedniego lekarza dla Twojej choroby',
                    showMore: 'Zobacz więcej',
                    diagnosis: {
                        HEADACHES: '<b>Bóle głowy to złożony problem neurologiczny,</b> który może przybierać kilkadziesiąt różnych form i intensywności. <b>Dolegliwości te mogą być objawem wielu schorzeń,</b> nie tylko z zakresu neurologii, ale także innych dziedzin medycyny. Kluczowe jest, aby diagnostyką i leczeniem bólów głowy zajmował się neurolog - specjalista w dziedzinie cefalologii, czyli nauki poświęconej temu zagadnieniu. <br/><b>Centrum Neurosphera podchodzi do diagnostyki i terapii bólów głowy indywidualnie i zespołowo.</b>',
                        MOVEMENT_ISSUES: '<b>Zaburzenia ruchowe to grupa schorzeń neurologicznych, które objawiają się różnymi problemami z poruszaniem się.</b> Mogą to być zarówno niedowłady i osłabienia mięśniowe, jak i drżenia, sztywność czy spastyczność. Ten szeroki wachlarz objawów jest wynikiem uszkodzenia lub zaburzeń w funkcjonowaniu układu nerwowego. <b>Ważne jest, aby w przypadku wystąpienia jakichkolwiek niepokojących symptomów zgłosić się do specjalisty,</b> ponieważ wczesna diagnoza i odpowiednie leczenie są kluczowe dla poprawy jakości życia pacjenta. <br/><b>Neurosphera jest w stanie precyzyjnie zdiagnozować przyczynę problemów i dobrać odpowiednie metody leczenia.</b>',
                        MEMORY_ISSUES: '<b>Zaburzenia pamięci są często kojarzone z chorobami otępiennymi, gdzie są jednym z głównych objawów.</b> Jednakże, problemy z pamięcią mogą wystąpić w każdym wieku i być sygnałem różnych schorzeń, od łagodnych po poważne. Dlatego tak istotne jest, aby <b>w przypadku jakichkolwiek niepokojących objawów zgłosić się do specjalisty,</b> który kompleksowo oceni funkcjonowanie pamięci i zdiagnozuje potencjalne przyczyny. <br/><b>W Centrum Neurosphera pacjent ma możliwość wybrania specjalistę neuropsychologii który wykona odpowiednie badania i zdiagnozuje przyczynę choroby oraz lekarza neurologa który dobierze odpowiednią terapię dla pacjenta.</b>',
                        EATING_ISSUES: '<b>Zaburzenia odżywiania to złożone problemy zdrowotne, które wymagają kompleksowego podejścia.</b> W ich leczeniu biorą udział różni specjaliści, tacy jak dietetycy, lekarze neurolodzy oraz psychiatrzy. Chociaż zaburzenia odżywiania często są objawem głębszych problemów psychicznych lub fizycznych, <b>to odpowiednio dobrana dieta i terapia żywieniowa mogą stanowić istotny element leczenia,</b> wpływając zarówno na zdrowie fizyczne, jak i psychiczne pacjenta. <br/><b>Centrum Neurosphera daje możliwość zdiagnozowania problemu zaburzeń odżywiania u lekarza neurologa lub specjalisty dietetyki który dobrze indywidualną dietę dla pacjenta.</b>',
                        CONSCIOUSNESS_ISSUES: '<b>Zaburzenia świadomości i przytomności to stany, które zawsze powinny wzbudzać niepokój i skłaniać do pilnej konsultacji z neurologiem.</b> Są one bowiem często pierwszym sygnałem poważnej choroby neurologicznej lub innym poważnym zaburzeniem zdrowotnym. Aby dokładnie określić przyczynę tych dolegliwości, <b>niezbędne jest przeprowadzenie dodatkowych badań, takich jak EEG (elektroencefalografia) i MRI (rezonans magnetyczny).</b> Te metody diagnostyczne pozwalają na ocenę aktywności elektrycznej mózgu oraz szczegółową wizualizację jego struktur, co jest kluczowe dla postawienia prawidłowej diagnozy i wdrożenia odpowiedniego leczenia. <br/><b>Centrum Neurosphera podchodzi do diagnostyki i terapii indywidualnie i zespołowo. W przychodni jest możliwość wykonania badań EEG.</b>',
                        PSYCHOMOTOR_DEVELOPMENT_ISSUES: '<b>Zaburzenia rozwoju psychoruchowego u dzieci to złożony problem kliniczny, który wymaga specjalistycznego i kompleksowego podejścia.</b> Ze względu na różnorodność objawów i potencjalnych przyczyn, diagnostyka i terapia powinny być prowadzone w ramach multidyscyplinarnego zespołu specjalistów. <br/><b>Centrum Neurosphera oferują kompleksową opiekę, umożliwiając przeprowadzenie szerokiego spektrum badań i konsultacji u różnych specjalistów.</b> Dzięki temu możliwe jest precyzyjne określenie przyczyn zaburzeń oraz wdrożenie odpowiedniego programu terapeutycznego. Wczesna diagnoza i terapia są kluczowe dla optymalnego rozwoju dziecka.',
                        EPILEPSY: '<b>Leczenie padaczki w Centrum Neurosphera to nasza specjalność.</b> Posiadamy 25-letnie doświadczenie w diagnostyce i terapii tej choroby, co czyni nas jednym z wiodących ośrodków w Polsce. <b>Nasz zespół, składający się z 20 specjalistów, wykorzystuje najnowocześniejsze narzędzia diagnostyczne,</b> w tym zaawansowane systemy oparte na sztucznej inteligencji, aby zapewnić pacjentom kompleksową opiekę. Dzięki temu możemy skutecznie diagnozować i leczyć nawet najbardziej złożone przypadki padaczki, oferując naszym pacjentom najwyższy standard opieki medycznej.',
                        SPINE_ISSUES: 'Ból kręgosłupa i pleców to problem, który dotyka wielu osób i może mieć różnorodne przyczyny. <b>Jest to schorzenie, które wymaga kompleksowego podejścia, łączącego wiedzę i doświadczenie neurologów i neurochirurgów.</b> <br/><b>W Centrum Neurosphera dysponujemy specjalistycznym zespołem, który zajmuje się diagnostyką i leczeniem tego rodzaju dolegliwości.</b> Dzięki szerokiej wiedzy i doświadczeniu naszych specjalistów oraz dostępności nowoczesnych metod diagnostycznych, jesteśmy w stanie precyzyjnie określić przyczynę bólu i zaproponować optymalne leczenie. <b>Nasze multidyscyplinarne podejście pozwala na kompleksową ocenę pacjenta i opracowanie indywidualnego planu terapeutycznego.</b>',
                        MUTIPLE_SCLEROSIS: '<b>Stwardnienie rozsiane to przewlekła choroba neurologiczna, której przebieg jest bardzo indywidualny i może znacznie różnić się między pacjentami.</b> Diagnostyka SM często jest wyzwaniem ze względu na różnorodność objawów, które mogą naśladować inne schorzenia. <b>Skuteczne leczenie stwardnienia rozsianego wymaga specjalistycznego podejścia i współpracy wielu specjalistów.</b> Terapia powinna być dostosowana do indywidualnych potrzeb każdego pacjenta i obejmować zarówno leczenie farmakologiczne, jak i niefarmakologiczne. <b>Zespół doświadczonych neurologów jest niezbędny do prawidłowej diagnozy i prowadzenia kompleksowej terapii osób chorych na SM.</b>',
                        DIZZINESS: '<b>Zawroty głowy to dolegliwość, która najczęściej jest objawem, a nie samodzielną chorobą.</b> Mogą być wywołane przez różnorodne czynniki, zarówno laryngologiczne (np. zaburzenia równowagi), jak i neurologiczne (np. uszkodzenia ośrodkowego układu nerwowego). <br/><b>Często pacjenci z zawrotami głowy zgłaszają się do neurologa, a my w Centrum Neurosphera jesteśmy doskonale przygotowani na przyjęcie takich pacjentów.</b> Oferujemy kompleksową diagnostykę, która pozwala na precyzyjne określenie przyczyny dolegliwości. <b>W niektórych przypadkach może okazać się konieczna konsultacja z neurochirurgiem, zwłaszcza gdy zawroty głowy są związane z problemami kręgosłupa.</b> Dzięki multidyscyplinarnemu podejściu jesteśmy w stanie zapewnić naszym pacjentom skuteczną pomoc.',
                        AUTISM: '<b>Spektrum autyzmu to złożone zaburzenie neuro rozwojowe, obejmujące trudności w komunikacji społecznej, interakcjach społecznych oraz ograniczone i powtarzalne wzorce zachowań.</b> Autyzm może przybierać różne formy, od łagodnych do ciężkich, i często współwystępuje z innymi zaburzeniami. <b>Zarówno autyzm dziecięcy typowy, jak i nietypowy wymagają specjalistycznej diagnozy i kompleksowego podejścia terapeutycznego.</b> <br/><b>W Centrum Neurosphera dysponujemy doświadczonym zespołem specjalistów, którzy zajmują się diagnostyką i leczeniem autyzmu oraz jego powikłań.</b> Oferujemy szeroki zakres usług, dostosowanych do indywidualnych potrzeb każdego pacjenta.',
                        OTHER_SYMPTOMS: '<b>Objawy neurologiczne mogą być niezwykle różnorodne i często niejednoznaczne.</b> Oprócz bardziej charakterystycznych symptomów, takich jak drętwienia czy zaburzenia równowagi, pacjenci zgłaszają również osłabienia, bóle, problemy ze snem, rozdrażnienie oraz trudności z koncentracją. <b>Taki szeroki wachlarz objawów sprawia, że precyzyjna diagnoza może być wyzwaniem, ponieważ wiele z nich może wskazywać na różne schorzenia neurologiczne, a czasem nawet na zaburzenia o innej przyczynie.</b> <br/><b>Centrum Neurosphera dysponuje specjalistycznym sprzętem i doświadczonym zespołem,</b> dzięki czemu jesteśmy w stanie przeprowadzić dokładną diagnostykę i wskazać przyczynę nawet najbardziej niejasnych objawów neurologicznych.'
                    }
                }
            }
        },

        error: {
            LOGIN_CRED_MISSING: 'Należy podać login i hasło',
            LOGIN_INVALID_CRED: 'Błędny login lub hasło',
            PASS_AND_CONF_NOT_EQUAL: 'Hasło i potwierdzenie różnią się od siebie',
            AUTHORIZATION: 'Nieuprawniony dostęp do zasobu, nastąpi przekierowanie do strony logowania',
            WRONG_PROFILE: 'Logowanie nieudane z powodu wyboru niepoprawnego profilu'
            //TODO: kody bledow z backendu
            //TODO: ogarnac obsluge defaultowego bledu, np generyczne 500
        },

        validation: {
            required: 'Pole jest wymagane',
            alphanumeric: 'Może zawierać tylko litery i cyfry',
            email: 'Niepoprawny adres e-mail',
            invalidRange: 'Nieprawidłowy zakres',
            nip: 'Nieprawidłowy numer NIP',
            regon: 'Nieprawidłowy numer REGON',
            pesel: 'Nieprawidłowy numer PESEL',
            max: 'Maksymalna liczba znaków: {length}',
            min: 'Minimalna liczba znaków: {length}',
            passNonMatching: 'Hasła nie pasują do siebie',
            maxFileSize: 'Maksymalny rozmiar pliku: {size} MB',
            pwz: 'Nieprawidłowy numer PWZ',
            zusCertificate: 'Wybrano niepoprawny plik. Dozwolone rozszerzenie plioku to .pfx',
            password: {
                lowercase: 'Powinno zawierać małe litery',
                uppercase: 'Powinno zawierać duże litery',
                minimumChar: 'Powinno mieć minimum {length} znaków',
                numericChar: 'Powinno zawierać cyfrę',
                specialChar: 'Powinno zawierać znak specjalny, dozwolone znaki to !@$\\-_.*'
            },
            maxLines: 'Maksymalna liczba linii została przekroczona'
        },
        office: {
            phone: '+48 609 463 191',
            phoneHref: 'tel:+48609463191',
            mail: 'biuro@neurosphera.pl',
            mailHref: 'mailto:biuro@neurosphera.pl'
        },
        appointWidget: {
            appoint: {
                title: "Umów wizytę",
                visitTypeLabel: "Typ wizyty",
                visitLocationLabel: "Rodzaj wizyty",
                doctorListDisclaimer: "Ostateczna cena zależy od rodzaju wizyty",
                visitTypeListDisclaimer: "Ostateczna cena zależy od rodzaju wizyty",
                doctorListHeader: "Wybierz lekarza",
                visitTypeListHeader: "Wybierz typ wizyty",
                visitLocationListHeader: "Wybierz rodzaj wizyty",
                doctorLabel: "Lekarz",
                startingPricePrefix: "od",
                clearSelection: "Wyczyść wybrane",
                visitOrder: "Czy to Twoja pierwsza wizyta u tego specjalisty?",
                visitOrderFirst: "Tak",
                visitOrderNext: "Nie",
                appointment: {
                    selectHeader: {
                        START_PACKAGE: 'Umów Ankietę 0 i Wizytę 1 - padaczka',
                        ADHD_PACKAGE: 'Umów wizyty z pakietu ADHD',
                        REGULAR_VISIT_EEG_PACKAGE: 'Umów wizytę regularną padaczkową i badanie EEG',
                        MIGRATION_VISIT: 'Wizyta migracyjna',
                        STANDARD_VISIT: 'Umów wizytę regularną - padaczka',
                        NEUROSURGEON_CONSULTATION: 'Umów konsultację neurochirurgiczną',
                        NEUROPSYCHOLOGIST_CONSULTATION: 'Umów konsultację neuropsychologiczną',
                        NEUROLOGOPEDIST_CONSULTATION: 'Konsultacja - neurologopeda',
                        PSYCHIATRIST_CONSULTATION: 'Umów konsultację psychiatryczną',
                        GENETICIST_CONSULTATION: 'Umów konsultację ze specjalistą genetykiem',
                        EPILEPTOLOGIST_CONSULTATION: 'Konsultacja - epileptolog',
                        EEG_CONSULTATION: 'Umów badanie EEG',
                        NEUROLOGIST_CONSULTATION: 'Umów konsultację neurologiczną',
                        DIETICIAN_CONSULTATION: 'Umów wizytę u dietetyka',
                        VNS_CONSULTATION: 'Wizyta VNS',
                        ABSENT_CONSULTATION: 'Konsultacja zaoczna',
                        METABOLIC_CONSULTATION: 'Umów konsultację metaboliczną',
                        SLEEP_DISTURBANCE_CONSULTATION: 'Umów konsultację zaburzeń snu',
                        CASE_CONFERENCE: 'Konsylium medyczne'
                    },
                    description: {
                        START_PACKAGE: 'Ankieta 0 to bezpłatna wizyta celem zebrania szczegółowego wywiadu nt. historii choroby pacjenta, poprzedzająca Wizytę 1. Podczas Wizyty 1 postawiona zostanie diagnoza, terapia oraz kompleksowy plan opieki na najbliższy rok. Wizyta 0 twa ok. 1 godz., Wizyta 1 - 30 minut; obie mogą odbywać się w formie stacjonarnej lub online.',
                        ADHD_PACKAGE: 'W skład pakietu wchodzą 2 spotkania z psychologiem (każde trwa po 1,5 godziny) oraz wizyta z neurologiem lub wizyta z psychiatrą. Pakiet jest skierowany do osób które zauważają u siebie:',
                        REGULAR_VISIT_EEG_PACKAGE: 'W skład pakietu wchodzi badanie EEG i wizyta regularna stacjonarna. Pakiet skierowany jest dla obecnych pacjentów Neurosphery, którzy odbyli już Wizytę 1 lub Wizytę Migracyjną. Zapisz się na pakiet jeśli:',
                        MMPI2_PACKAGE: 'Dajemy możliwość przeprowadzenia diagnozy zaburzeń osobowości za pomocą najobszerniejszego i najbardziej uniwersalnego narzędzia oceny klinicznej, dostępnego w Polsce – Minnesockiego Wielowymiarowego Inwentarza Osobowości (pełna adaptacja MMPI-2 została wydana w 2012).',
                        CBT_PACKAGE: 'Terapia poznawczo-behawioralna (CBT) w leczeniu bezsenności jest uznawana za leczenie pierwszego wyboru dla osób zmagających się z bezsennością, rekomendowane przez takie autorytety jak National Institutes of Health (NIH), National Institute for Health and Care Excellence (NICE) oraz American College of Physician.',
                        MIGRATION_VISIT: 'Wizyta migracyjna',
                        STANDARD_VISIT: 'Wizyta regularna epileptologiczna skierowana jest dla obecnych pacjentów Neurosphery, którzy odbyli już Wizytę 1 lub Wizytę Migracyjną.',
                        NEUROSURGEON_CONSULTATION: 'Konsultacja zalecana w przypadku przewlekłych problemów neurologicznych jak np.:',
                        NEUROPSYCHOLOGIST_CONSULTATION: 'Wskazana w przypadku:',
                        NEUROLOGOPEDIST_CONSULTATION: 'Konsultacja - neurologopeda',
                        PSYCHIATRIST_CONSULTATION: 'Wizyta dla osób zmagających się z problemami zdrowia psychicznego, m.in.:',
                        GENETICIST_CONSULTATION: 'Wskazanie do wizyty:',
                        EPILEPTOLOGIST_CONSULTATION: 'Konsultacja - epileptolog',
                        EEG_CONSULTATION: 'Podstawowe badanie w diagnostyce padaczki, a także m.in.:',
                        NEUROLOGIST_CONSULTATION: 'Wskazaniem do wizyty są:',
                        DIETICIAN_CONSULTATION: 'Wizyta dla osób:',
                        VNS_CONSULTATION: 'Wizyta VNS',
                        ABSENT_CONSULTATION: 'Konsultacja zaoczna',
                        METABOLIC_CONSULTATION: 'Wskazana w przypadku wrodzonych wad metabolizmu. Niektóre choroby metaboliczne mogą przyczyniać się do wystąpienia padaczki. Warto zgłosić się, jeśli istnieje podejrzenie choroby w badaniach genetycznych. ',
                        SLEEP_DISTURBANCE_CONSULTATION: 'Wskazana w przypadku:',
                        CASE_CONFERENCE: 'Konsylium medyczne lekarzy specjalistów i partnerów medycznych Neurosphery jest zbiorowym naradzeniem się w celu omówienia przypadku klinicznego pacjenta oraz ustalenia optymalnego planu leczenia.'
                    },
                    bulletPoints: {
                        ADHD_PACKAGE: [
                          "Trudności z organizacją",
                          "Niedocenianie czasu",
                          "Trudności interpersonalne",
                          "Trudności emocjonalne"
                        ],
                        REGULAR_VISIT_EEG_PACKAGE: [
                            "Przez ostatni rok nie miałeś wykonanego badania EEG",
                            "Planujesz wizytę regularną stacjonarną z lekarzem egiptologiem",
                            "Czujesz brak poprawy lub pogorszenie stanu zdrowia"
                        ],
                        DIETICIAN_CONSULTATION: [
                            "u których dieta ketogenna jest wskazaniem lekarza lub są nią zainteresowane",
                            "które stosują dietę ketogenna i chcą zweryfikować obecne leczenie",
                            "chcą zweryfikować swój obecny sposób odżywiania"
                        ],
                        EEG_CONSULTATION: [
                            "dla wskazania przyczyn zaburzeń snu",
                            "przy urazach głowy i nawracających bólach głowy",
                            "omdleniach czy utracie przytomności"
                        ],
                        NEUROLOGIST_CONSULTATION: [
                            "omdlenia oraz utraty przytomności, zaburzenia równowagi i koordynacji ruchowej",
                            "chroniczne bóle głowy połączone z zawrotami",
                            "pogorszenie pamięci i kłopoty z koncentracją",
                            "mrowienie kończyn z zaburzeniem czucia"
                        ],
                        PSYCHIATRIST_CONSULTATION: [
                            "depresją",
                            "zaburzeniami lękowymi",
                            "problemami ze snem",
                            "nienaturalnie podwyższonym nastrojem i nadaktywnością"
                        ],
                        GENETICIST_CONSULTATION: [
                            "występowanie w rodzinie chorób o przyczynach genetycznych",
                            "wykluczenie choroby wrodzonej, jako przyczyny padaczki",
                            "ocena ryzyka zachorowania dziecka na epilepsję, w przypadku planowanej ciąży",
                            "przed wykonaniem badania WES"
                        ],
                        NEUROSURGEON_CONSULTATION: [
                            "przewlekłe bóle i urazy kręgosłupa",
                            "guzy mózgu i nowotworowe zmiany w obrębie kręgosłupa",
                            "drętwienia, mrowienia i niedowłady kończyn",
                            "silne bóle i zawroty głowy, zaburzenia widzenia i słuchu",
                            "konsultacja badania rezonansu magnetycznego głowy"
                        ],
                        NEUROPSYCHOLOGIST_CONSULTATION: [
                            "problemów w normalnym funkcjonowaniu, jakie pojawiły się na skutek np. urazów głowy, guzów mózgu, chorób niszczących tkankę nerwową",
                            "wystąpienia osłabienia pamięci lub koncentracji",
                            "wystąpienia zaburzeń mowy, orientacji lub odnajdywaniem drogi",
                            "występują zaburzenia wzroku mimo prawidłowych wyników okulistycznych"
                        ],
                        SLEEP_DISTURBANCE_CONSULTATION: [
                            "senności lub rozdrażnienia w ciągu dnia",
                            "senności lub bezsenności wywołanej czynnikami emocjonalnymi",
                            "zburzeń dobowego rytmu snu i wstawania",
                            "chrapania podczas snu"
                        ]
                    },
                    detailsHeader: {
                        VISIT_0: 'Ankieta 0 - padaczka',
                        MIGRATION_VISIT: 'Wizyta migracyjna',
                        VISIT_1: 'Wizyta 1 - padaczka',
                        START_PACKAGE: 'Ankieta 0 i Wizyta 1',
                        ADHD_PACKAGE: 'Pakiet ADHD',
                        REGULAR_VISIT_EEG_PACKAGE: 'Wizyta regularna + badanie EEG',
                        STANDARD_VISIT: 'Wizyta regularna - padaczka',
                        NEUROSURGEON_CONSULTATION: 'Konsultacja - neurochirurg',
                        NEUROPSYCHOLOGIST_CONSULTATION: 'Konsultacja – neuropsycholog ',
                        NEUROLOGOPEDIST_CONSULTATION: 'Konsultacja - neurologopeda',
                        PSYCHIATRIST_CONSULTATION: 'Konsultacja – psychiatra',
                        GENETICIST_CONSULTATION: 'Konsultacja – genetyk',
                        EPILEPTOLOGIST_CONSULTATION: 'Konsultacja - epileptolog',
                        EEG_CONSULTATION: 'Umów badanie EEG',
                        NEUROLOGIST_CONSULTATION: 'Konsultacja - neurolog',
                        DIETICIAN_CONSULTATION: 'Konsultacja - dietetyk',
                        VNS_CONSULTATION: 'Wizyta VNS',
                        ABSENT_CONSULTATION: 'Konsultacja zaoczna',
                        METABOLIC_CONSULTATION: 'Konsultacja metaboliczna',
                        SLEEP_DISTURBANCE_CONSULTATION: 'Konsultacja zaburzeń snu',
                        CASE_CONFERENCE: 'Konsylium medyczne'
                    },
                    details: {
                        VISIT_0: 'Ankieta 0 jest wizytą przygotowawczą, podczas której przeprowadzony jest pogłębiony, bardzo szczegółowy wywiad. Wizyta trwa ok. 1 godz. Ankieta 0 musi być przeprowadzona przed Wizytą 1.',
                        MIGRATION_VISIT: '',
                        VISIT_1: 'Na Wizycie 1 następuje potwierdzenie diagnozy oraz ustalenia kompleksowego planu opieki. Wizyta trwa ok. 30 min.',
                        STANDARD_VISIT: 'Czas trwania wizyty ok 30 min.',
                        NEUROSURGEON_CONSULTATION: 'Czas trwania konsultacji od 15 do 45 min.',
                        NEUROPSYCHOLOGIST_CONSULTATION: 'Czas trwania konsultacji ok 1 godz.',
                        NEUROLOGOPEDIST_CONSULTATION: 'Konsultacja - neurologopeda',
                        PSYCHIATRIST_CONSULTATION: 'Czas trwania konsultacji ok. 45 min.',
                        GENETICIST_CONSULTATION: 'Czas trwania konsultacji ok. 45 min.',
                        EPILEPTOLOGIST_CONSULTATION: 'Konsultacja - epileptolog',
                        EEG_CONSULTATION: 'Umów badanie EEG',
                        NEUROLOGIST_CONSULTATION: 'Umów się w przypadku:<ul><li>migren lub bólów głowy</li><li>bólów kręgosłupa, zaburzeń czucia</li><li>utraty przytomności lub świadomości</li><li>udaru mózgu, otępienia, zaburzenia pamięci</li><li>innych przypadłości neurologicznych</li></ul></br>Czas trwania konsultacji ok. 30 min.',
                        DIETICIAN_CONSULTATION: 'Czas trwania konsultacji ok. 1 godz.',
                        VNS_CONSULTATION: 'Wizyta VNS',
                        ABSENT_CONSULTATION: 'Konsultacja zaoczna',
                        METABOLIC_CONSULTATION: 'Czas trwania konsultacji ok. 30 min.',
                        SLEEP_DISTURBANCE_CONSULTATION: 'Czas trwania konsultacji ok. 45 min.',
                        CASE_CONFERENCE: 'Konsylium medyczne lekarzy specjalistów i partnerów medycznych Neurosphery jest zbiorowym naradzeniem się w celu omówienia przypadku klinicznego pacjenta oraz ustalenia optymalnego planu leczenia.'
                    }
                },
                remoteVisitDescription: "Wizyta zdalna przez Neuroterminal. Wymagane urządzenie z kamerą i mikrofonem, podłączone do Internetu",
                details: "Szczegóły",
                visitHeader: {
                    VISIT_0: "Ankiety 0",
                    MIGRATION_VISIT: 'Wizyty migracyjnej',
                    VISIT_1: "Wizyty 1",
                    STANDARD_VISIT: "Wizyty standardowej",
                    NEUROSURGEON_CONSULTATION: "Konsultacji - neurochirurg",
                    NEUROPSYCHOLOGIST_CONSULTATION: "Konsultacji - neuropsycholog",
                    NEUROLOGOPEDIST_CONSULTATION: "Konsultacji - neurologopeda",
                    PSYCHIATRIST_CONSULTATION: "Konsultacji - psychiatra",
                    GENETICIST_CONSULTATION: "Konsultacji - genetyk",
                    EPILEPTOLOGIST_CONSULTATION: "Konsultacji - epileptolog",
                    EEG_CONSULTATION: "Badania EEG",
                    NEUROLOGIST_CONSULTATION: "Konsultacji - neurolog",
                    DIETICIAN_CONSULTATION: "Konsultacji - dietetyk",
                    VNS_CONSULTATION: "Wizyty VNS",
                    ABSENT_CONSULTATION: "Konsultacji zaocznej",
                    METABOLIC_CONSULTATION: "Konsultacji metabolicznej",
                    SLEEP_DISTURBANCE_CONSULTATION: "Konsultacji zaburzeń snu"
                },
                instruction: {
                    START_PACKAGE: "Jeśli nie widzisz pasującego dla Ciebie terminu - spróbuj wybrać innego lekarza, rodzaj wizyty lub wcześniejszy termin Ankiety 0"
                },
                visitPriceLabel: "Cena wizyty",
                showAllSlots: "pokaż więcej godzin",
                hideSlots: "zwiń",
                nextLabel: "Dalej",
                validationErrorHeader: "Żeby przejść do podsumowania wybierz:",
                ok: "Ok",
                validationError: {
                    slot: "Termin",
                    location: "Rodzaj",
                    doctor: "Lekarza",
                    service: "Typ"
                },
                chooseLabel: "Wybierz",
                doctorMissingLabel: "Lekarza",
                locationMissingLabel: "Rodzaj wizyty",
                andLabel: "i",
                unavailableDoctors: "Lekarze niedostępni dla wybranego terminu, typu i/lub rodzaju wizyty",
                ADULT: "Osoba dorosła",
                CHILD: "Dziecko",
                visitNotAvailable: "Przepraszamy, ale wybrany przez Ciebie termin <b>{visitName}</b> nie jest już dostępny. Wróć i wybierz inny termin",
                visitNotSaved: "Wizyta jeszcze nie została zarejestrowana. Wróć do podsumowania by dokończyć proces umawiania wizyty."
            },
            summary: {
                title: 'Podsumowanie',
                grossPrice: 'Do zapłaty',
                availableOnlinePayments: 'Akceptujemy płatności online',
                transfer: 'Przelew',
                installments: 'Raty 0%',
                payment: 'Płatność',
                paymentDescription: 'By przejść do płatności podaj adres email - wyślemy Ci wiadomość z podsumowaniem oraz linkiem do płatności.',
                descriptionExistingAccount: 'Jeśli masz konto w Neuroterminalu - podaj adres email którego używasz do logowania.',
                makeReservations: 'Zarezerwuj terminy',
                makeReservation: 'Zarezerwuj termin',
                unexpectedError: "Wystąpił nieoczekiwany błąd podczas próby umówienia wizyty. Prosimy spróbować ponownie."
            },
            thanku: {
                title: 'Dziękujemy!',
                emailHasBeenSent: 'Wysłaliśmy wiadomość z podsumowaniem oraz linkiem do płatności na podany adres email:',
                finalizeAppointment: 'Przejdź do poczty i <b>dokończ proces umawiania wizyty</b> klikając w podesłany link do płatności. Masz czas do <b>{deadline}</b> by dokończyć proces umawiania wizyty.',
                resendEmail: 'Email nie dotarł? Sprawdź folder Spam lub',
                resendEmailLink: 'Wyślij ponownie',
                resendEmailDone: 'Email został wysłany ponownie',
                backToNeurosphera: 'Wróć do neurosphera.pl'
            },
            exitPoll: {
                title: "Dlaczego chcesz wyjść?",
                description: "Twoja odpowiedź jest bardzo ważna dla nas! Dzięki niej będziemy mogli udoskonalić nasze usługi.",
                SERVICE: "Nie znalazłem/am usługi, która mnie interesuje",
                DATE: "Nie odpowiadają mi terminy",
                PRICE: "Nie odpowiada mi cena usługi",
                OTHER: "Inne",
                sendResponse: "Wyślij odpowiedź",
                back: "Wróć do serwisu",
                whatService: "Jaką usługę szukałeś/aś?",
                enterMessage: "Wpisz wiadomość",
                reasonMissing: "Podaj powód rezygnacji"
            },
            main: {
                exitPopup: {
                    description: "Czy na pewno chcesz przerwać proces umawiania wizyty? Twoje wybory nie zostaną zapisane.",
                    confirm: "Tak, chcę wyjść",
                    decline: "Nie, wróć do umawiania wizyty"
                }
            }
        },
        server: {
            episode: {
                description: "Napad dodany na wizycie"
            },
            generatedepisode: {
                description: "Napad dodany na wizycie"
            }
        },

        dates: {
            today: 'dziś',
            tomorrow: 'jutro'
        },

        enums: Enums.pl,
        serverErrors: ServerErrors.pl,
    },

    formats: {
        currency: {
            style: 'currency', currency: 'PLN', currencyDisplay: 'symbol'
        }
    }
}
