/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DoctorSelectionRequestDto
 */
export interface DoctorSelectionRequestDto {
    /**
     * 
     * @type {number}
     * @memberof DoctorSelectionRequestDto
     */
    'currentDoctorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DoctorSelectionRequestDto
     */
    'evaluation'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorSelectionRequestDto
     */
    'historyComment'?: string;
    /**
     * 
     * @type {number}
     * @memberof DoctorSelectionRequestDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DoctorSelectionRequestDto
     */
    'overallComment'?: string;
    /**
     * 
     * @type {number}
     * @memberof DoctorSelectionRequestDto
     */
    'patientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DoctorSelectionRequestDto
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorSelectionRequestDto
     */
    'reasonComment'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorSelectionRequestDto
     */
    'rejectionReason'?: string;
    /**
     * 
     * @type {number}
     * @memberof DoctorSelectionRequestDto
     */
    'selectedDoctorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DoctorSelectionRequestDto
     */
    'status'?: DoctorSelectionRequestDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DoctorSelectionRequestDto
     */
    'timeAnswered'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorSelectionRequestDto
     */
    'timeCreated'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DoctorSelectionRequestDtoStatusEnum {
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Rejected = 'REJECTED',
    ConfirmAccepted = 'CONFIRM_ACCEPTED',
    ConfirmRejected = 'CONFIRM_REJECTED'
}


