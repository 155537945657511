/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface EegIndication
 */
export interface EegIndication {
    /**
     * 
     * @type {string}
     * @memberof EegIndication
     */
    'main'?: EegIndicationMainEnum;
    /**
     * 
     * @type {string}
     * @memberof EegIndication
     */
    'otherDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegIndication
     */
    'sub'?: EegIndicationSubEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EegIndicationMainEnum {
    Epilepsy = 'EPILEPSY',
    DifferentialDiagnosis = 'DIFFERENTIAL_DIAGNOSIS',
    PediatricRecommendation = 'PEDIATRIC_RECOMMENDATION',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum EegIndicationSubEnum {
    RoutineCheckup = 'ROUTINE_CHECKUP',
    SuspectedEpilepsy = 'SUSPECTED_EPILEPSY',
    EpilepsyVerification = 'EPILEPSY_VERIFICATION',
    EpilepsyClasification = 'EPILEPSY_CLASIFICATION',
    SeizurePatterChange = 'SEIZURE_PATTER_CHANGE',
    NonConvulsionsEpilepsy = 'NON_CONVULSIONS_EPILEPSY',
    EpilepsyMonitoring = 'EPILEPSY_MONITORING',
    SeizureFrequencyMonitoring = 'SEIZURE_FREQUENCY_MONITORING',
    DrugsMonitoring = 'DRUGS_MONITORING',
    LppTherapyStop = 'LPP_THERAPY_STOP',
    PreSurgery = 'PRE_SURGERY',
    PsychogenicSeizure = 'PSYCHOGENIC_SEIZURE',
    ConsciousnessDisturbances = 'CONSCIOUSNESS_DISTURBANCES',
    Encephalopathy = 'ENCEPHALOPATHY',
    Encephalitis = 'ENCEPHALITIS',
    Dementia = 'DEMENTIA',
    Tia = 'TIA',
    BehaviorChange = 'BEHAVIOR_CHANGE',
    Other = 'OTHER',
    GeneticSyndrome = 'GENETIC_SYNDROME',
    MetabolicDisorder = 'METABOLIC_DISORDER',
    Regression = 'REGRESSION'
}


