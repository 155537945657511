export const DOSAGE_MORNING = '08:00:00';
export const DOSAGE_NOON = '12:00:00';
export const DOSAGE_EVENING = '20:00:00';

export const SEIZURES_CHART_MAX_SERIES_LENGTH = 1000;

export const DOCTOR_MESSAGE_RESPONSE_DEADLINE_HOURS = 48;
export const PATIENT_MESSAGE_RESPONSE_DEADLINE_HOURS = 720;

export const VISIT_MODIFICATION_WARNING_HOURS = 48;

export const PATIENT_DASHBOARD_RECALC_VISIT_STATE_INTERVAL = 10000;

export const MINUTES_TO_VISIT_WHEN_CAN_BE_JOINED = 15;

export const MINUTES_TO_VISIT_WHEN_CAN_BE_ENTERED_BY_DOCTOR = 120;

export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_HOUR = 60 * 60;


export const LONG_VISIT_DURATION_THRESHOLD_SECONDS = 60*60;
export const LONG_VISIT_FIRST_TIMEOUT_WARNING_THRESHOLD_SECONDS = 12*60;
export const LONG_VISIT_SECOND_TIMEOUT_WARNING_THRESHOLD_SECONDS = 5*60;

export const SHORT_VISIT_DURATION_THRESHOLD_SECONDS = 60*60;
export const SHORT_VISIT_FIRST_TIMEOUT_WARNING_THRESHOLD_SECONDS = 8*60;
export const SHORT_VISIT_SECOND_TIMEOUT_WARNING_THRESHOLD_SECONDS = 4*60;

export const VISIT_AUTOSAVE_TIMEOUT_MINUTES = 3;


export const DRUG_STOCK_WARNING_THRESHOLD = 14;

export const BYTES_IN_GIGABYTE = 1073741824;

export const CHART_RANGE_3M = {value: 3, unit: 'M', code: '3M'};
export const CHART_RANGE_SINCE_FIRST_SEIZURE = {value: null, unit: null, code: 'SINCE_FIRST_SEIZURE'};
export const CHART_RANGE_SINCE_BIRTH = {value: null, unit: null, code: 'SINCE_BIRTH'};
export const CHART_RANGES = [CHART_RANGE_3M];


export const E_DIAGNOSIS_EVENTS_TRAILER = [
    'trailerPrecise', 'trailerUnspecified'
];

export const E_DIAGNOSIS_EVENTS_BEFORE = [
    'beforeAura', 'beforeSpecificStimuli', 'beforeNystagmus', 'beforeImmobility'
];

export const E_DIAGNOSIS_EVENTS_START = [
    'startFaint', 'startLossConsciousness', 'startBodyConvulsions', 'startBodyPartConvulsions', 'startFaceConvulsions',
    'startBodyStiffness', 'startLimbEjection', 'startEyeMovementDisorders', 'startEyeTurnPernament', 'startBodyTurn',
    'startGroundFall', 'startBitTongue', 'startShout', 'startSounds', 'startMotorAutomatisms', 'startImmobility',
    'startLaugh', 'startChuck', 'startCry', 'startEpigastricSymptoms', 'startDejaVu', 'startVisualHallucinations',
    'startAuditoryHallucinations', 'startEyelidsMyoclonus', 'startMyoclonus'
];

export const E_DIAGNOSIS_EVENTS_NEXT = [
    'nextFaint', 'nextLossConsciousness', 'nextBodyConvulsions', 'nextBodyPartConvulsions', 'nextFaceConvulsions', 'nextBodyStiffness',
    'nextEyeMovementDisorders', 'nextEyeTurnPernament', 'nextBodyTurn', 'nextGroundFall', 'nextBitTongue', 'nextUrinating',
    'nextDefecation', 'nextShout', 'nextSounds', 'nextAggression', 'nextMotorAutomatisms', 'nextImmobility', 'nextLaugh',
    'nextChuck', 'nextCry', 'nextEpigastricSymptoms', 'nextDejaVu', 'nextVisualHallucinations',
    'nextAuditoryHallucinations', 'nextEyelidsMyoclonus'
];

export const E_DIAGNOSIS_EVENTS_END = [
    'endAnotherSeizure', 'endBodyConvulsions', 'endBodyPartConvulsions', 'endFaceConvulsions', 'endEyeMovementDisorders',
    'endEyeTurnPernament', 'endBodyTurn', 'endGroundFall', 'endUrinating', 'endDefecation', 'endSounds', 'endAggression',
    'endMotorAutomatisms', 'endImmobility', 'endLaugh', 'endChuck', 'endCry', 'endVomit'
];

export const E_DIAGNOSIS_EVENTS_AFTER = [
    'afterEyeMovementDisorders', 'afterEyeTurnPernament', 'afterUrinating', 'afterDefecation', 'afterConfusion', 'afterSleep',
    'afterAggression', 'afterMotorAutomatisms', 'afterVomit', 'afterHighTemperature'
];


export const E_DIAGNOSIS_EVENTS_FEATURE = [
    'featureMany', 'featureDayOccurrence', 'featureNightOccurrence', 'featureAwakeningOccurrence',
    'featureMorningOccurrence', 'featureDuration'
];


export const E_DIAGNOSIS_EVENTS =
    E_DIAGNOSIS_EVENTS_TRAILER
        .concat(E_DIAGNOSIS_EVENTS_BEFORE)
        .concat(E_DIAGNOSIS_EVENTS_START)
        .concat(E_DIAGNOSIS_EVENTS_NEXT)
        .concat(E_DIAGNOSIS_EVENTS_END)
        .concat(E_DIAGNOSIS_EVENTS_AFTER)
        .concat(E_DIAGNOSIS_EVENTS_FEATURE);


export const PAYU_INSTALLMENTS_TERMS = 'https://poland.payu.com/wp-content/uploads/sites/14/2021/06/Najczesciej_zadawane_pytania_o_Raty_PayU_3.pdf';

export const PAYU_INSTALLMENTS_MIN_PRICE = 300;