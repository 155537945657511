/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DocplannerIssueDto
 */
export interface DocplannerIssueDto {
    /**
     * 
     * @type {boolean}
     * @memberof DocplannerIssueDto
     */
    'automaticResolve': boolean;
    /**
     * 
     * @type {string}
     * @memberof DocplannerIssueDto
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof DocplannerIssueDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocplannerIssueDto
     */
    'doctor'?: string;
    /**
     * 
     * @type {number}
     * @memberof DocplannerIssueDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DocplannerIssueDto
     */
    'medicalService'?: DocplannerIssueDtoMedicalServiceEnum;
    /**
     * 
     * @type {string}
     * @memberof DocplannerIssueDto
     */
    'metadata'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocplannerIssueDto
     */
    'patientDataFilledIn': boolean;
    /**
     * 
     * @type {number}
     * @memberof DocplannerIssueDto
     */
    'patientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocplannerIssueDto
     */
    'patientLogin'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocplannerIssueDto
     */
    'resolved': boolean;
    /**
     * 
     * @type {string}
     * @memberof DocplannerIssueDto
     */
    'resolvedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocplannerIssueDto
     */
    'resolvedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocplannerIssueDto
     */
    'type': DocplannerIssueDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DocplannerIssueDto
     */
    'visitTime'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DocplannerIssueDtoMedicalServiceEnum {
    Visit0 = 'VISIT_0',
    Visit1 = 'VISIT_1',
    StandardVisit = 'STANDARD_VISIT',
    FollowupVisit = 'FOLLOWUP_VISIT',
    Message = 'MESSAGE',
    Prescription = 'PRESCRIPTION',
    AbsentConsultation = 'ABSENT_CONSULTATION',
    NeurosurgeonConsultation = 'NEUROSURGEON_CONSULTATION',
    NeuropsychologistConsultation = 'NEUROPSYCHOLOGIST_CONSULTATION',
    NeurologopedistConsultation = 'NEUROLOGOPEDIST_CONSULTATION',
    PsychiatristConsultation = 'PSYCHIATRIST_CONSULTATION',
    GeneticistConsultation = 'GENETICIST_CONSULTATION',
    EpileptologistConsultation = 'EPILEPTOLOGIST_CONSULTATION',
    NeurologistConsultation = 'NEUROLOGIST_CONSULTATION',
    DieticianConsultation = 'DIETICIAN_CONSULTATION',
    VnsConsultation = 'VNS_CONSULTATION',
    MetabolicConsultation = 'METABOLIC_CONSULTATION',
    SleepDisturbanceConsultation = 'SLEEP_DISTURBANCE_CONSULTATION',
    CaseConference = 'CASE_CONFERENCE',
    EegConsultation = 'EEG_CONSULTATION',
    VeegNightConsultation = 'VEEG_NIGHT_CONSULTATION',
    VeegDayConsultation = 'VEEG_DAY_CONSULTATION',
    VeegOnStandbyConsultation1H = 'VEEG_ON_STANDBY_CONSULTATION_1H',
    VeegOnStandbyConsultation2H = 'VEEG_ON_STANDBY_CONSULTATION_2H',
    VeegOnStandbyConsultation6H = 'VEEG_ON_STANDBY_CONSULTATION_6H',
    VeegDuringSleepConsultation1H = 'VEEG_DURING_SLEEP_CONSULTATION_1H',
    VeegDuringSleepConsultation2H = 'VEEG_DURING_SLEEP_CONSULTATION_2H',
    VeegDuringSleepConsultation6H = 'VEEG_DURING_SLEEP_CONSULTATION_6H',
    MigrationVisit = 'MIGRATION_VISIT',
    VeegOnStandbyConsultation = 'VEEG_ON_STANDBY_CONSULTATION',
    VeegDuringSleepConsultation = 'VEEG_DURING_SLEEP_CONSULTATION',
    PartnerConsultation = 'PARTNER_CONSULTATION'
}
/**
    * @export
    * @enum {string}
    */
export enum DocplannerIssueDtoTypeEnum {
    DpVisitNotBooked = 'DP_VISIT_NOT_BOOKED',
    DpVisitNotCanceled = 'DP_VISIT_NOT_CANCELED',
    DpSlotNotCanceled = 'DP_SLOT_NOT_CANCELED',
    DpVisitNotMoved = 'DP_VISIT_NOT_MOVED',
    DpNewBookingFailed = 'DP_NEW_BOOKING_FAILED',
    DpMovingBookingFailed = 'DP_MOVING_BOOKING_FAILED',
    DpMoveBookingFailed = 'DP_MOVE_BOOKING_FAILED',
    DpCancelBookingFailed = 'DP_CANCEL_BOOKING_FAILED',
    NotEligibleForRegularVisit = 'NOT_ELIGIBLE_FOR_REGULAR_VISIT',
    NotEligibleForConsultation = 'NOT_ELIGIBLE_FOR_CONSULTATION'
}


