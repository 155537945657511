/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EpilepsyTypeViewModel } from '../model';
/**
 * IctalEpilepsyTypesControllerApi - axios parameter creator
 * @export
 */
export const IctalEpilepsyTypesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIctalEpilepsyTyp: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteIctalEpilepsyTyp', 'id', id)
            const localVarPath = `/api/ictal/epilepsy-type/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIctalEpilepsyTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ictal/epilepsy-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EpilepsyTypeViewModel} epilepsyTypeViewModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveIctalEpilepsyType: async (epilepsyTypeViewModel: EpilepsyTypeViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'epilepsyTypeViewModel' is not null or undefined
            assertParamExists('saveIctalEpilepsyType', 'epilepsyTypeViewModel', epilepsyTypeViewModel)
            const localVarPath = `/api/ictal/epilepsy-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(epilepsyTypeViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IctalEpilepsyTypesControllerApi - functional programming interface
 * @export
 */
export const IctalEpilepsyTypesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IctalEpilepsyTypesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIctalEpilepsyTyp(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIctalEpilepsyTyp(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIctalEpilepsyTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<EpilepsyTypeViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIctalEpilepsyTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EpilepsyTypeViewModel} epilepsyTypeViewModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveIctalEpilepsyType(epilepsyTypeViewModel: EpilepsyTypeViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<EpilepsyTypeViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveIctalEpilepsyType(epilepsyTypeViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IctalEpilepsyTypesControllerApi - factory interface
 * @export
 */
export const IctalEpilepsyTypesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IctalEpilepsyTypesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIctalEpilepsyTyp(id: number, options?: any): Promise<object> {
            return localVarFp.deleteIctalEpilepsyTyp(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIctalEpilepsyTypes(options?: any): Promise<Array<EpilepsyTypeViewModel>> {
            return localVarFp.getIctalEpilepsyTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EpilepsyTypeViewModel} epilepsyTypeViewModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveIctalEpilepsyType(epilepsyTypeViewModel: EpilepsyTypeViewModel, options?: any): Promise<EpilepsyTypeViewModel> {
            return localVarFp.saveIctalEpilepsyType(epilepsyTypeViewModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IctalEpilepsyTypesControllerApi - object-oriented interface
 * @export
 * @class IctalEpilepsyTypesControllerApi
 * @extends {BaseAPI}
 */
export class IctalEpilepsyTypesControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IctalEpilepsyTypesControllerApi
     */
    public deleteIctalEpilepsyTyp(id: number, options?: AxiosRequestConfig) {
        return IctalEpilepsyTypesControllerApiFp(this.configuration).deleteIctalEpilepsyTyp(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IctalEpilepsyTypesControllerApi
     */
    public getIctalEpilepsyTypes(options?: AxiosRequestConfig) {
        return IctalEpilepsyTypesControllerApiFp(this.configuration).getIctalEpilepsyTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EpilepsyTypeViewModel} epilepsyTypeViewModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IctalEpilepsyTypesControllerApi
     */
    public saveIctalEpilepsyType(epilepsyTypeViewModel: EpilepsyTypeViewModel, options?: AxiosRequestConfig) {
        return IctalEpilepsyTypesControllerApiFp(this.configuration).saveIctalEpilepsyType(epilepsyTypeViewModel, options).then((request) => request(this.axios, this.basePath));
    }
}
