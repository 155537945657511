/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ProductDto } from './product-dto';

/**
 * 
 * @export
 * @interface PrescriptionRequestDto
 */
export interface PrescriptionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRequestDto
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof PrescriptionRequestDto
     */
    'doctorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRequestDto
     */
    'paymentProvider'?: PrescriptionRequestDtoPaymentProviderEnum;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof PrescriptionRequestDto
     */
    'products'?: Array<ProductDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum PrescriptionRequestDtoPaymentProviderEnum {
    Payu = 'PAYU',
    Paynow = 'PAYNOW'
}


