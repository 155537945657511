import Vue from "vue";
import Router from "vue-router";
import DataStore from "../utility/data.store";
import SecurityService from "../service/security.service";

Vue.use(Router);


const administrativeRoutes = [
    {
        path: '/landing-page',
        component: function () {
            return import(/* webpackChunkName: "landing-page" */ '../view/landing/LandingPage.vue')
        },
        children: [
            {
                path: '',
                name: 'landing-page',
                redirect: '/',
                component: function () {
                    return import(/* webpackChunkName: "landing-page-main" */ '../view/landing/children/LandingPageMain.vue')
                }
            },
            {
                path: 'nt-database',
                redirect: '/',
                component: function () {
                    return import(/* webpackChunkName: "landing-page-nt" */ '../view/landing/children/LandingPageNtDatabase.vue')
                }
            },
            {
                path: 'doctors',
                redirect: '/',
                component: function () {
                    return import(/* webpackChunkName: "landing-page-doctor" */ '../view/landing/children/LandingPageDoctors.vue')
                }
            },
            {
                path: 'contact',
                redirect: '/',
                component: function () {
                    return import(/* webpackChunkName: "landing-page-contact" */ '../view/landing/children/LandingPageContact.vue')
                }
            },
            {
                // for backwards compatibility, but can be deleted
                path: 'register',
                component: function () {
                    return import(/* webpackChunkName: "landing-page-register" */ '../view/login/Register.vue')
                }
            }
        ]
    },
];


const loginRoutes = [
    {
        path: '*',
        redirect: '/login-profile'
    },
    {
        path: '/login-profile',
        name: 'login',
        component: function () {
            return import("../view/login/Login.vue")
        },
        beforeEnter: async (to, from, next) => {
            if (await isAuthenticated()) {
                resetRouter();
                next('/');
            } else {
                next();
            }
        }
    },
    {
        path: '/register',
        name: 'register',
        component: function () {
            return import(/* webpackChunkName: "reset" */ '../view/login/Register.vue')
        }
    },
    // LoginDoctor
    {
        path: '/reset-confirm/:token',
        name: 'reset-confirm',
        component: function () {
            return import(/* webpackChunkName: "reset-confirm" */ '../view/login/PasswordResetConfirm.vue')
        }
    },
    {
        path: '/reset',
        name: 'reset',
        component: function () {
            return import(/* webpackChunkName: "reset" */ '../view/login/PasswordReset.vue')
        }
    },
    {
        path: '/patient-register-confirm',
        name: 'patient-register-confirm',
        component: function () {
            return import(/* webpackChunkName: "patient-register-confirm" */ '../view/login/RegisterConfirm.vue')
        }
    },
    {
        path: '/appoint',
        name: 'appoint-visit',
        component: function () {
            return import('../appoint-widget/component/widget-main.component')
        }
    },
    {
        path: '/umow-wizyte',
        name: 'umow-wizyte',
        alias: ['/umow'],
        component: function () {
            return import('../appoint-widget/AppointingPage.vue')
        },
        beforeEnter: async (to, from, next) => {
            if (await isAuthenticated()) {
                resetRouter();
                next('/visit-reservation');
            } else {
                next();
            }
        }
    },
    {
        path: '/patient-rating',
        name: 'patient-rating',
        component: function () {
            return import('../view/patient/patient-rating.component.vue')
        }
    },
    {
        path: '/widget-test',
        name: 'widget-test',
        component: function () {
            return import('../appoint-widget/component/widget-test.vue')
        }
    },
    {
        path: '/remove-account',
        name: 'remove-account',
        component: function () {
            return import('../component/patient/remove-patient-account.component.vue')
        }
    }
];


const doctorRoutes = [
    {
        path: '/',
        component: function () {
            return import(/* webpackChunkName: "home" */ '../view/Home.vue')
        },
        beforeEnter: async (to, from, next) => {
            if (await isAuthenticated()) {
                next();
            } else {
                next('/login-profile');
            }
        },
        children: [
            {
                path: '',
                name: 'home',
                component: function () {
                    return import(/* webpackChunkName: "doctor-dashboard" */ '../view/doctor/dashboard/DoctorDashboard.vue')
                }
            },
            {
                path: 'account',
                component: function () {
                    return import(/* webpackChunkName: "doctor-account-home" */ '../view/doctor/account/info/DoctorAccountInfoHome.vue')
                },
                children: [
                    {
                        path: 'general-info',
                        component: function () {
                            return import(/* webpackChunkName: "doctor-general-info" */ '../view/doctor/account/info/DoctorGeneralInfo.vue')
                        }
                    },
                    {
                        path: 'practise-info',
                        component: function () {
                            return import(/* webpackChunkName: "doctor-practise-info" */ '../view/doctor/account/info/DoctorPractiseInfo.vue')
                        }
                    }
                ]
            },
            {
                path: 'visits',
                component: function () {
                    return import(/* webpackChunkName: "doctor-visits" */ '../view/doctor/visits/DoctorVisits.vue')
                }
            },
            {
                path: 'patients',
                component: function () {
                    return import(/* webpackChunkName: "doctor-patients" */ '../view/common/patients/PatientsList.vue')
                }
            },
            {
                name: 'additional-doctor-visit',
                path: 'additional-doctor-visit/:publicId',
                component: function () {
                    return import(/* webpackChunkName: "additional-doctor-visit" */ '../view/common/visit/AdditionalDoctorVisit.vue')
                }
            },
            {
                name: 'doctor-visit',
                path: 'visit/:publicId',
                component: function () {
                    return import(/* webpackChunkName: "doctor-visit" */ '../view/common/visit/Visit.vue')
                }
            },
            {
                path: 'medical-history/:publicId',
                component: function () {
                    return import(/* webpackChunkName: "medical-history" */ '../view/doctor/medical/history/MedicalHistory.vue')
                }
            },
            {
                path: 'messages/:visitPublicId?',
                component: function () {
                    return import(/* webpackChunkName: "message-history" */ '../view/common/message-history/MessageHistory.vue')
                }
            },
            {
                path: 'e-prescriptions/:requestId?',
                component: function () {
                    return import(/* webpackChunkName: "e-prescriptions" */ '../view/doctor/e-health/prescriptions/ePrescriptionsView.vue')
                }
            },
            {
                path: 'work-plan',
                component: function () {
                    return import(/* webpackChunkName: "work-plan" */ '../view/doctor/work-plan/WorkPlan.vue')
                }
            },
            {
                path: 'calendar',
                component: function () {
                    return import(/* webpackChunkName: "calendar" */ '../view/doctor/calendar/Calendar.vue')
                }
            },
            {
                path: 'ictal-lab',
                component: function () {
                    return import(/* webpackChunkName: "ictal-lab" */ '../view/doctor/ictal/lab/IctalLab')
                }
            },
            {
                path: 'settings',
                component: function () {
                    return import(/* webpackChunkName: "ictal-lab" */ '../view/doctor/account/settings/DoctorAccountSettings.vue')
                }
            },
            {
                path: 'eeg-description',
                component: function () {
                    return import(/* webpackChunkName: "ictal-lab" */ '../view/doctor/examinations/eeg-examination.vue')
                }
            }
        ]
    }
];

const forAllPatients = ['patient-dashboard', 'patient-account-info'];
const forPatientsWithoutAppointment = forAllPatients.concat('visit-reservation', 'visit-payment-outcome', 'neuroscreen');

const patientRoutes = [
    {
        path: '/',
        component: function () {
            return import(/* webpackChunkName: "home" */ '../view/Home.vue')
        },
        beforeEnter: async (to, from, next) => {
            if (await isAuthenticated()) {
                if (!DataStore.isUserInitialized() && !forAllPatients.includes(to.name)) {
                    next('');
                } else if (!DataStore.hasAppointment() && !forPatientsWithoutAppointment.includes(to.name)) {
                    next('');
                } else {
                    next();
                }
            } else {
                next('/login-profile');
            }
        },
        children: [
            {
                name: 'patient-dashboard',
                path: '',
                alias: ['/dashboard'],
                component: function () {
                    return import(/* webpackChunkName: "patient-dashboard" */ '../view/patient/dashboard/PatientDashboard.vue')
                }
            },
            {
                name: 'patient-account-info',
                path: 'account/info',
                component: function () {
                    return import(/* webpackChunkName: "account/info" */ '../view/patient/account/form/PatientInfo.vue')
                }
            },
            {
                path: 'account/medical-history',
                component: function () {
                    return import(/* webpackChunkName: "health-survey" */ '../view/patient/health-survey/HealthSurvey.vue')
                }
            },
            {
                path: 'account/medical-documentation',
                component: function () {
                    return import(/* webpackChunkName: "account/medical-documentation" */ '../view/patient/documentation/MedicalDocumentation.vue')
                }
            },


            {
                path: 'messages/:visitPublicId?',
                component: function () {
                    return import(/* webpackChunkName: "message-history" */ '../view/common/message-history/MessageHistory.vue')
                }
            },
            {
                path: 'therapy',
                component: function () {
                    return import(/* webpackChunkName: "patient-therapy" */ '../view/patient/therapy/PatientTherapy.vue')
                }
            },

            // VISITS fixme nie mozna uzyc children bez nadrzednego komponentu (tak jak w przypadku 'account')
            {
                path: 'visits/archive',
                component: function () {
                    return import(/* webpackChunkName: "visits/archive" */ '../view/patient/visits/Visits.vue')
                }
            },

            // CHANGE DOCTOR
            {
                path: 'doctor-database',
                component: function () {
                    return import(/* webpackChunkName: "doctor-database" */ '../view/patient/database/DoctorDatabase.vue')
                }
            },

            {
                name: 'visit',
                path: 'visit/:publicId',
                component: function () {
                    return import(/* webpackChunkName: "patient-visit" */ '../view/patient/visits/PatientVisit.vue')
                }
            },

            // MEDICAL DOCS
            {
                path: 'medical-documentation',
                component: function () {
                    return import(/* webpackChunkName: "payments" */ '../view/patient/documentation/MedicalDocumentation.vue')
                }
            },


            // SETTINGS fixme nie mozna uzyc children bez nadrzednego komponentu (tak jak w przypadku 'account')
            {
                path: 'settings/payments',
                component: function () {
                    return import(/* webpackChunkName: "settings/payments" */ '../view/patient/payment/Payments.vue')
                }
            },
            {
                path: 'settings/regulations',
                component: function () {
                    return import(/* webpackChunkName: "settings/regulations" */ '../view/patient/account/settings/Regulations.vue')
                }
            },
            {
                path: 'settings/notifications',
                component: function () {
                    return import(/* webpackChunkName: "settings/notifications" */ '../view/patient/account/settings/PatientAccountSettings.vue')
                }
            },


            {
                name: 'visit-payment-outcome',
                path: 'payment-outcome/visit/:paymentUid',
                component: function () {
                    return import(/* webpackChunkName: "visit-payment" */ '../view/patient/payment/VisitPaymentOutcome.vue')
                }
            },
            {
                name: 'payment-outcome',
                path: 'payment-outcome/payment/:uid',
                component: function () {
                    return import(/* webpackChunkName: "payment" */ '../view/patient/payment/PaymentOutcome.vue')
                }
            },
            {
                name: 'visit-reservation',
                path: 'visit-reservation',
                component: function () {
                    return import(/* webpackChunkName: "visit-reservation" */ '../view/patient/visits/reservation/PatientVisitReservation.vue')
                }
            },
            {
                name: 'awaiting-payment',
                path: 'awaiting-payment',
                component: function () {
                    return import(/* webpackChunkName: "awaiting-payment" */ '../view/patient/visits/reservation/AwaitingPayment.vue')
                }
            },
            {
                name: 'patient-note',
                path: 'patient-note',
                component: function () {
                    return import(/* webpackChunkName: "patient-note" */ '../view/patient/note/patient-note.component.vue')
                }
            },
            {
                path: 'visit/reassign/:visitPublicId',
                component: function () {
                    return import(/* webpackChunkName: "admin-appoint" */ '../view/common/visit/reassig/VisitReassign.vue')
                }
            },
            {
                path: 'neuroscreen',
                component: function () {
                    return import(/* webpackChunkName: "neuroscreen" */ '../view/patient/neuroscreen/Neuroscreen.vue')
                }
            }
        ]
    }
];

const adminRoutes = [
    {
        path: '/',
        component: function () {
            return import(/* webpackChunkName: "home" */ '../view/Home.vue')
        },
        beforeEnter: async (to, from, next) => {
            if (await isAuthenticated()) {
                next();
            } else {
                next('/login-profile');
            }
        },
        children: [
            {
                path: '',
                name: 'home',
                component: function () {
                    return import(/* webpackChunkName: "admin-dashboard" */ '../view/admin/dashboard/AdminDashboard.vue')
                }
            },
            {
                path: 'doctors/list',
                component: function () {
                    return import(/* webpackChunkName: "doctors-list" */ '../view/admin/doctors/DoctorsList.vue')
                }
            },
            {
                path: 'doctors/pricelist/:doctorId?',
                component: function () {
                    return import(/* webpackChunkName: "doctors-pricelist" */ '../view/admin/doctors/DoctorsPricelist.vue')
                }
            },
            {
                path: 'doctors/pricelist-system',
                component: function () {
                    return import(/* webpackChunkName: "pricelist-system" */ '../view/admin/doctors/DoctorsSystemPricelist.vue')
                }
            },
            {
                path: 'doctors/register',
                component: function () {
                    return import(/* webpackChunkName: "doctors-register" */ '../view/admin/doctors/RegisterDoctor.vue')
                }
            },
            {
                path: 'doctors/work-plan/:doctorId?',
                component: function () {
                    return import(/* webpackChunkName: "doctors-work-plan" */ '../view/admin/doctors/DoctorsWorkPlan.vue')
                }
            },
            {
                path: 'doctors/work-plan-change/:doctorId?',
                component: function () {
                    return import(/* webpackChunkName: "doctor-work-plan-change" */ '../view/admin/doctors/EditDoctorWorkPlanChanges')
                }
            },
            {
                path: 'doctors/calendar/:doctorId?',
                component: function () {
                    return import(/* webpackChunkName: "doctors-calendar" */ '../view/admin/doctors/DoctorsCalendar.vue')
                }
            },
            {
                path: 'doctors/calendar/visits/preview',
                component: function () {
                    return import(/* webpackChunkName: "doctors-visits" */ '../view/admin/doctors/DoctorsVisits.vue')
                }
            },
            {
                path: 'doctors/work-places',
                component: function () {
                    return import(/* webpackChunkName: "doctor-work-places" */ '../view/admin/doctors/work-places/DoctorWorkPlaces')
                }
            },
            {
                path: 'doctors/work-place/:workPlaceId?',
                component: function () {
                    return import(/* webpackChunkName: "doctor-work-place" */ '../view/admin/doctors/work-places/EditDoctorWorkPlace')
                }
            },
            {
                path: 'doctors/medical-service',
                component: function () {
                    return import(/* webpackChunkName: "medical-services" */ '../view/admin/doctors/medical-service/MedicalServices')
                }
            },
            {
                path: 'doctors/visit-package',
                component: function () {
                    return import('../view/admin/doctors/VisitPackageTemplates.vue')
                }
            },
            {
                path: 'doctors/doctor-package',
                component: function () {
                    return import('../view/admin/doctors/DoctorPackages.vue')
                }
            },
            {
                path: 'doctors/partners',
                component: function () {
                    return import('../view/admin/doctors/partners.component')
                }
            },
            {
                path: 'doctors/collective-workplan',
                component: function () {
                    return import('../view/admin/doctors/doctor-collective-workplan.component.vue')
                }
            },
            {
                path: 'patients/list',
                component: function () {
                    return import(/* webpackChunkName: "doctor-patients" */ '../view/common/patients/PatientsList.vue')
                }
            },
            {
                path: 'patients/notes',
                component: function () {
                    return import('../view/admin/patient/notes/admin-notes-list.component')
                }
            },
            {
                path: 'patients/notes/new',
                component: function () {
                    return import('../view/admin/patient/notes/admin-add-note-list.component')
                }
            },
            {
                path: 'patients/certificates',
                component: function () {
                    return import('../view/admin/patient/certificates-list.component.vue')
                }
            },
            {
                path: 'patients/register',
                component: function () {
                    return import(/* webpackChunkName: "patients-registration3" */ '../view/admin/patient/PatientRegistration.vue')
                }
            },
            {
                path: 'patients/edit/:patientId',
                component: function () {
                    return import('../view/admin/patient/PatientEdit.vue')
                }
            },
            {
                path: 'patients/settings/:patientId',
                component: function () {
                    return import('../view/admin/patient/PatientManagement.vue')
                }
            },
            {
                path: 'patients/reassign/:visitPublicId',
                component: function () {
                    return import(/* webpackChunkName: "admin-appoint" */ '../view/common/visit/reassig/VisitReassign.vue')
                }
            },
            {
                path: 'patients/prescriptions',
                component: function () {
                    return import('../view/admin/patient/prescriptions.component.vue')
                }
            },
            {
                path: 'patients/messages',
                component: function () {
                    return import('../view/admin/patient/messages-visits.component')
                }
            },
            {
                path: 'patients/e-referrals',
                component: function () {
                    return import('../view/admin/patient/e-referrals-list.component')
                }
            },
            {
                path: 'patients/docplanner-visits',
                component: function () {
                    return import('../view/admin/patient/docplanner-visits.component')
                }
            },
            {
                path: 'patients/care-plans',
                component: function () {
                    return import('../view/admin/patient/care-plans.component')
                }
            },
            {
                path: 'settings/hashtags',
                component: function () {
                    return import('../view/admin/patient/hashtags.component')
                }
            },
            {
                name: 'admin-patient-appoint',
                path: 'patients/appoint/:patientId',
                component: function () {
                    return import(/* webpackChunkName: "admin-appoint" */ '../view/admin/patient/appoint/AdminVisitReservation.vue')
                }
            },
            {
                name: 'admin-patient-appoint',
                path: 'patients/neuroscreen/:patientId',
                component: function () {
                    return import(/* webpackChunkName: "admin-neuroscreen" */ '../view/admin/patient/neuroscreen/AdminPatientNeuroscreen.vue')
                }
            },
            {
                path: 'ictal-lab',
                component: function () {
                    return import(/* webpackChunkName: "ictal-lab" */ '../view/doctor/ictal/lab/IctalLab')
                }
            },
            {
                path: 'settings',
                component: function () {
                    return import(/* webpackChunkName: "admin-settings" */ '../view/admin/settings/SettingsDashboard')
                }
            },
            {
                path: 'features',
                component: function () {
                    return import(/* webpackChunkName: "admin-features" */ '../view/admin/settings/FeaturesDashboard')
                }
            },
            {
                path: 'tools/s3-migration',
                component: function () {
                    return import(/* webpackChunkName: "admin-s3-migration" */ '../view/admin/tools/MigrateDocumentsToS3Dashboard')
                }
            },
            {
                path: 'test-api',
                component: function () {
                    return import(/* webpackChunkName: "admin-settings" */ '../view/admin/test-api/test-api.component.vue')
                }
            }
        ]
    }
];

const unauthenticatedRoutes = [
    {
        name: 'unauthenticated-payment-outcome',
        path: '/payment-outcome/payment/:uid',
        beforeEnter: async (to, from, next) => {
            if (await isAuthenticated()) {
                resetRouter();
                next({name: 'payment-outcome', params: to.params});
            } else {
                next({name: 'login', params: {redirect: to.fullPath.replace('/','')}});
            }
        }
    },
    {
        name: 'unauthenticated-visit-payment-outcome',
        path: '/payment-outcome/visit/:paymentUid',
        beforeEnter: async (to, from, next) => {
            if (await isAuthenticated()) {
                resetRouter();
                next({name: 'visit-payment-outcome', params: to.params});
            } else {
                next({name: 'login', params: {redirect: to.fullPath.replace('/','')}})
            }
        }
    }
];

const createRouter = (customRoutes) => new Router({
    mode: 'history',
    routes: [].concat(
        administrativeRoutes,
        loginRoutes,
        customRoutes
    )
});

const router = createRouter(getCustomRoutes());

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

async function isAuthenticated() {
    while (router.app.$keycloak.createLoginUrl === null) {
        await sleep(100);
    }
    return router.app.$keycloak.authenticated && isBusinessUserSet();
}

async function isBusinessUserSet() {
    let counter = 0;
    while (!DataStore.isBusinessUserSet() && counter < 10) {
        counter++;
        await sleep(100);
    }
    return DataStore.isBusinessUserSet();
}

function resetRouter() {
    const newRouter = createRouter(getCustomRoutes());
    router.matcher = newRouter.matcher;
}

function getCustomRoutes() {
    let customRoutes = [];
    if (customRoutesEnabled()) {
        if (DataStore.getUser().profile === 'PATIENT') {
            customRoutes = patientRoutes;
        } else if (DataStore.getUser().profile === 'DOCTOR') {
            customRoutes = doctorRoutes;
        } else if (DataStore.getUser().profile === 'ADMIN') {
            customRoutes = adminRoutes;
        }
    } else {
        customRoutes = unauthenticatedRoutes;
    }
    return customRoutes;
}

function customRoutesEnabled() {
    return (SecurityService.isAccessTokenSet() || SecurityService.$keycloak.createLoginUrl === null) && DataStore.isBusinessUserSet();
}

SecurityService.setRouter(router, resetRouter);

export default router;
