/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DrugTherapyProposal } from '../model';
// @ts-ignore
import { FilterResultWithDescription } from '../model';
// @ts-ignore
import { PatientIctalTherapyProposal } from '../model';
// @ts-ignore
import { Therapy } from '../model';
// @ts-ignore
import { WeightedDrug } from '../model';
/**
 * IctalTherapyClassificationControllerApi - axios parameter creator
 * @export
 */
export const IctalTherapyClassificationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [epilepsyTypeId] 
         * @param {number} [therapyTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyCase: async (epilepsyTypeId?: number, therapyTypeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ictal/classification/therapy/case`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (epilepsyTypeId !== undefined) {
                localVarQueryParameter['epilepsyTypeId'] = epilepsyTypeId;
            }

            if (therapyTypeId !== undefined) {
                localVarQueryParameter['therapyTypeId'] = therapyTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} epilepsyTypesIds 
         * @param {number} therapyTypeId 
         * @param {number} age 
         * @param {boolean} pregnant 
         * @param {boolean} somaticDiseases 
         * @param {'MALE' | 'FEMALE'} sex 
         * @param {Array<number>} [usedDrugsIds] 
         * @param {string} [countryCode] 
         * @param {boolean} [shouldBeAvailable] 
         * @param {boolean} [shouldBeRefunded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyCase1: async (epilepsyTypesIds: Array<number>, therapyTypeId: number, age: number, pregnant: boolean, somaticDiseases: boolean, sex: 'MALE' | 'FEMALE', usedDrugsIds?: Array<number>, countryCode?: string, shouldBeAvailable?: boolean, shouldBeRefunded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'epilepsyTypesIds' is not null or undefined
            assertParamExists('classifyCase1', 'epilepsyTypesIds', epilepsyTypesIds)
            // verify required parameter 'therapyTypeId' is not null or undefined
            assertParamExists('classifyCase1', 'therapyTypeId', therapyTypeId)
            // verify required parameter 'age' is not null or undefined
            assertParamExists('classifyCase1', 'age', age)
            // verify required parameter 'pregnant' is not null or undefined
            assertParamExists('classifyCase1', 'pregnant', pregnant)
            // verify required parameter 'somaticDiseases' is not null or undefined
            assertParamExists('classifyCase1', 'somaticDiseases', somaticDiseases)
            // verify required parameter 'sex' is not null or undefined
            assertParamExists('classifyCase1', 'sex', sex)
            const localVarPath = `/api/ictal/classification/therapy/case/specific`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (epilepsyTypesIds) {
                localVarQueryParameter['epilepsyTypesIds'] = epilepsyTypesIds;
            }

            if (therapyTypeId !== undefined) {
                localVarQueryParameter['therapyTypeId'] = therapyTypeId;
            }

            if (age !== undefined) {
                localVarQueryParameter['age'] = age;
            }

            if (pregnant !== undefined) {
                localVarQueryParameter['pregnant'] = pregnant;
            }

            if (somaticDiseases !== undefined) {
                localVarQueryParameter['somaticDiseases'] = somaticDiseases;
            }

            if (sex !== undefined) {
                localVarQueryParameter['sex'] = sex;
            }

            if (usedDrugsIds) {
                localVarQueryParameter['usedDrugsIds'] = usedDrugsIds;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }

            if (shouldBeAvailable !== undefined) {
                localVarQueryParameter['shouldBeAvailable'] = shouldBeAvailable;
            }

            if (shouldBeRefunded !== undefined) {
                localVarQueryParameter['shouldBeRefunded'] = shouldBeRefunded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [epilepsyTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyCase2: async (epilepsyTypeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ictal/classification/therapy/case/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (epilepsyTypeId !== undefined) {
                localVarQueryParameter['epilepsyTypeId'] = epilepsyTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {boolean} available 
         * @param {boolean} refunded 
         * @param {boolean} [reproductiveAgeOptional] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyCaseForPatient: async (patientId: number, available: boolean, refunded: boolean, reproductiveAgeOptional?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('classifyCaseForPatient', 'patientId', patientId)
            // verify required parameter 'available' is not null or undefined
            assertParamExists('classifyCaseForPatient', 'available', available)
            // verify required parameter 'refunded' is not null or undefined
            assertParamExists('classifyCaseForPatient', 'refunded', refunded)
            const localVarPath = `/api/ictal/classification/therapy/case/patient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (available !== undefined) {
                localVarQueryParameter['available'] = available;
            }

            if (refunded !== undefined) {
                localVarQueryParameter['refunded'] = refunded;
            }

            if (reproductiveAgeOptional !== undefined) {
                localVarQueryParameter['reproductiveAgeOptional'] = reproductiveAgeOptional;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} therapyTypeId 
         * @param {boolean} available 
         * @param {boolean} refunded 
         * @param {boolean} [reproductiveAgeOptional] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyCaseForPatientWithSelectedTherapyType: async (patientId: number, therapyTypeId: number, available: boolean, refunded: boolean, reproductiveAgeOptional?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('classifyCaseForPatientWithSelectedTherapyType', 'patientId', patientId)
            // verify required parameter 'therapyTypeId' is not null or undefined
            assertParamExists('classifyCaseForPatientWithSelectedTherapyType', 'therapyTypeId', therapyTypeId)
            // verify required parameter 'available' is not null or undefined
            assertParamExists('classifyCaseForPatientWithSelectedTherapyType', 'available', available)
            // verify required parameter 'refunded' is not null or undefined
            assertParamExists('classifyCaseForPatientWithSelectedTherapyType', 'refunded', refunded)
            const localVarPath = `/api/ictal/classification/therapy/case/specific/{therapyTypeId}/patient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"therapyTypeId"}}`, encodeURIComponent(String(therapyTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (available !== undefined) {
                localVarQueryParameter['available'] = available;
            }

            if (refunded !== undefined) {
                localVarQueryParameter['refunded'] = refunded;
            }

            if (reproductiveAgeOptional !== undefined) {
                localVarQueryParameter['reproductiveAgeOptional'] = reproductiveAgeOptional;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} epilepsyTypesIds 
         * @param {number} therapyTypeId 
         * @param {number} age 
         * @param {boolean} pregnant 
         * @param {boolean} somaticDiseases 
         * @param {'MALE' | 'FEMALE'} sex 
         * @param {Array<number>} [usedDrugsIds] 
         * @param {string} [countryCode] 
         * @param {boolean} [shouldBeAvailable] 
         * @param {boolean} [shouldBeRefunded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyCaseWithDescription: async (epilepsyTypesIds: Array<number>, therapyTypeId: number, age: number, pregnant: boolean, somaticDiseases: boolean, sex: 'MALE' | 'FEMALE', usedDrugsIds?: Array<number>, countryCode?: string, shouldBeAvailable?: boolean, shouldBeRefunded?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'epilepsyTypesIds' is not null or undefined
            assertParamExists('classifyCaseWithDescription', 'epilepsyTypesIds', epilepsyTypesIds)
            // verify required parameter 'therapyTypeId' is not null or undefined
            assertParamExists('classifyCaseWithDescription', 'therapyTypeId', therapyTypeId)
            // verify required parameter 'age' is not null or undefined
            assertParamExists('classifyCaseWithDescription', 'age', age)
            // verify required parameter 'pregnant' is not null or undefined
            assertParamExists('classifyCaseWithDescription', 'pregnant', pregnant)
            // verify required parameter 'somaticDiseases' is not null or undefined
            assertParamExists('classifyCaseWithDescription', 'somaticDiseases', somaticDiseases)
            // verify required parameter 'sex' is not null or undefined
            assertParamExists('classifyCaseWithDescription', 'sex', sex)
            const localVarPath = `/api/ictal/classification/therapy/case/specific/description`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (epilepsyTypesIds) {
                localVarQueryParameter['epilepsyTypesIds'] = epilepsyTypesIds;
            }

            if (therapyTypeId !== undefined) {
                localVarQueryParameter['therapyTypeId'] = therapyTypeId;
            }

            if (age !== undefined) {
                localVarQueryParameter['age'] = age;
            }

            if (pregnant !== undefined) {
                localVarQueryParameter['pregnant'] = pregnant;
            }

            if (somaticDiseases !== undefined) {
                localVarQueryParameter['somaticDiseases'] = somaticDiseases;
            }

            if (sex !== undefined) {
                localVarQueryParameter['sex'] = sex;
            }

            if (usedDrugsIds) {
                localVarQueryParameter['usedDrugsIds'] = usedDrugsIds;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }

            if (shouldBeAvailable !== undefined) {
                localVarQueryParameter['shouldBeAvailable'] = shouldBeAvailable;
            }

            if (shouldBeRefunded !== undefined) {
                localVarQueryParameter['shouldBeRefunded'] = shouldBeRefunded;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IctalTherapyClassificationControllerApi - functional programming interface
 * @export
 */
export const IctalTherapyClassificationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IctalTherapyClassificationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [epilepsyTypeId] 
         * @param {number} [therapyTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async classifyCase(epilepsyTypeId?: number, therapyTypeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DrugTherapyProposal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.classifyCase(epilepsyTypeId, therapyTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} epilepsyTypesIds 
         * @param {number} therapyTypeId 
         * @param {number} age 
         * @param {boolean} pregnant 
         * @param {boolean} somaticDiseases 
         * @param {'MALE' | 'FEMALE'} sex 
         * @param {Array<number>} [usedDrugsIds] 
         * @param {string} [countryCode] 
         * @param {boolean} [shouldBeAvailable] 
         * @param {boolean} [shouldBeRefunded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async classifyCase1(epilepsyTypesIds: Array<number>, therapyTypeId: number, age: number, pregnant: boolean, somaticDiseases: boolean, sex: 'MALE' | 'FEMALE', usedDrugsIds?: Array<number>, countryCode?: string, shouldBeAvailable?: boolean, shouldBeRefunded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<WeightedDrug>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.classifyCase1(epilepsyTypesIds, therapyTypeId, age, pregnant, somaticDiseases, sex, usedDrugsIds, countryCode, shouldBeAvailable, shouldBeRefunded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [epilepsyTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async classifyCase2(epilepsyTypeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<Therapy>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.classifyCase2(epilepsyTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {boolean} available 
         * @param {boolean} refunded 
         * @param {boolean} [reproductiveAgeOptional] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async classifyCaseForPatient(patientId: number, available: boolean, refunded: boolean, reproductiveAgeOptional?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientIctalTherapyProposal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.classifyCaseForPatient(patientId, available, refunded, reproductiveAgeOptional, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} therapyTypeId 
         * @param {boolean} available 
         * @param {boolean} refunded 
         * @param {boolean} [reproductiveAgeOptional] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async classifyCaseForPatientWithSelectedTherapyType(patientId: number, therapyTypeId: number, available: boolean, refunded: boolean, reproductiveAgeOptional?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientIctalTherapyProposal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.classifyCaseForPatientWithSelectedTherapyType(patientId, therapyTypeId, available, refunded, reproductiveAgeOptional, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} epilepsyTypesIds 
         * @param {number} therapyTypeId 
         * @param {number} age 
         * @param {boolean} pregnant 
         * @param {boolean} somaticDiseases 
         * @param {'MALE' | 'FEMALE'} sex 
         * @param {Array<number>} [usedDrugsIds] 
         * @param {string} [countryCode] 
         * @param {boolean} [shouldBeAvailable] 
         * @param {boolean} [shouldBeRefunded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async classifyCaseWithDescription(epilepsyTypesIds: Array<number>, therapyTypeId: number, age: number, pregnant: boolean, somaticDiseases: boolean, sex: 'MALE' | 'FEMALE', usedDrugsIds?: Array<number>, countryCode?: string, shouldBeAvailable?: boolean, shouldBeRefunded?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<FilterResultWithDescription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.classifyCaseWithDescription(epilepsyTypesIds, therapyTypeId, age, pregnant, somaticDiseases, sex, usedDrugsIds, countryCode, shouldBeAvailable, shouldBeRefunded, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IctalTherapyClassificationControllerApi - factory interface
 * @export
 */
export const IctalTherapyClassificationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IctalTherapyClassificationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [epilepsyTypeId] 
         * @param {number} [therapyTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyCase(epilepsyTypeId?: number, therapyTypeId?: number, options?: any): Promise<Array<DrugTherapyProposal>> {
            return localVarFp.classifyCase(epilepsyTypeId, therapyTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} epilepsyTypesIds 
         * @param {number} therapyTypeId 
         * @param {number} age 
         * @param {boolean} pregnant 
         * @param {boolean} somaticDiseases 
         * @param {'MALE' | 'FEMALE'} sex 
         * @param {Array<number>} [usedDrugsIds] 
         * @param {string} [countryCode] 
         * @param {boolean} [shouldBeAvailable] 
         * @param {boolean} [shouldBeRefunded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyCase1(epilepsyTypesIds: Array<number>, therapyTypeId: number, age: number, pregnant: boolean, somaticDiseases: boolean, sex: 'MALE' | 'FEMALE', usedDrugsIds?: Array<number>, countryCode?: string, shouldBeAvailable?: boolean, shouldBeRefunded?: boolean, options?: any): Promise<Array<WeightedDrug>> {
            return localVarFp.classifyCase1(epilepsyTypesIds, therapyTypeId, age, pregnant, somaticDiseases, sex, usedDrugsIds, countryCode, shouldBeAvailable, shouldBeRefunded, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [epilepsyTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyCase2(epilepsyTypeId?: number, options?: any): Promise<Array<Therapy>> {
            return localVarFp.classifyCase2(epilepsyTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {boolean} available 
         * @param {boolean} refunded 
         * @param {boolean} [reproductiveAgeOptional] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyCaseForPatient(patientId: number, available: boolean, refunded: boolean, reproductiveAgeOptional?: boolean, options?: any): Promise<PatientIctalTherapyProposal> {
            return localVarFp.classifyCaseForPatient(patientId, available, refunded, reproductiveAgeOptional, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} therapyTypeId 
         * @param {boolean} available 
         * @param {boolean} refunded 
         * @param {boolean} [reproductiveAgeOptional] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyCaseForPatientWithSelectedTherapyType(patientId: number, therapyTypeId: number, available: boolean, refunded: boolean, reproductiveAgeOptional?: boolean, options?: any): Promise<PatientIctalTherapyProposal> {
            return localVarFp.classifyCaseForPatientWithSelectedTherapyType(patientId, therapyTypeId, available, refunded, reproductiveAgeOptional, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} epilepsyTypesIds 
         * @param {number} therapyTypeId 
         * @param {number} age 
         * @param {boolean} pregnant 
         * @param {boolean} somaticDiseases 
         * @param {'MALE' | 'FEMALE'} sex 
         * @param {Array<number>} [usedDrugsIds] 
         * @param {string} [countryCode] 
         * @param {boolean} [shouldBeAvailable] 
         * @param {boolean} [shouldBeRefunded] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyCaseWithDescription(epilepsyTypesIds: Array<number>, therapyTypeId: number, age: number, pregnant: boolean, somaticDiseases: boolean, sex: 'MALE' | 'FEMALE', usedDrugsIds?: Array<number>, countryCode?: string, shouldBeAvailable?: boolean, shouldBeRefunded?: boolean, options?: any): Promise<FilterResultWithDescription> {
            return localVarFp.classifyCaseWithDescription(epilepsyTypesIds, therapyTypeId, age, pregnant, somaticDiseases, sex, usedDrugsIds, countryCode, shouldBeAvailable, shouldBeRefunded, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IctalTherapyClassificationControllerApi - object-oriented interface
 * @export
 * @class IctalTherapyClassificationControllerApi
 * @extends {BaseAPI}
 */
export class IctalTherapyClassificationControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} [epilepsyTypeId] 
     * @param {number} [therapyTypeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IctalTherapyClassificationControllerApi
     */
    public classifyCase(epilepsyTypeId?: number, therapyTypeId?: number, options?: AxiosRequestConfig) {
        return IctalTherapyClassificationControllerApiFp(this.configuration).classifyCase(epilepsyTypeId, therapyTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} epilepsyTypesIds 
     * @param {number} therapyTypeId 
     * @param {number} age 
     * @param {boolean} pregnant 
     * @param {boolean} somaticDiseases 
     * @param {'MALE' | 'FEMALE'} sex 
     * @param {Array<number>} [usedDrugsIds] 
     * @param {string} [countryCode] 
     * @param {boolean} [shouldBeAvailable] 
     * @param {boolean} [shouldBeRefunded] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IctalTherapyClassificationControllerApi
     */
    public classifyCase1(epilepsyTypesIds: Array<number>, therapyTypeId: number, age: number, pregnant: boolean, somaticDiseases: boolean, sex: 'MALE' | 'FEMALE', usedDrugsIds?: Array<number>, countryCode?: string, shouldBeAvailable?: boolean, shouldBeRefunded?: boolean, options?: AxiosRequestConfig) {
        return IctalTherapyClassificationControllerApiFp(this.configuration).classifyCase1(epilepsyTypesIds, therapyTypeId, age, pregnant, somaticDiseases, sex, usedDrugsIds, countryCode, shouldBeAvailable, shouldBeRefunded, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [epilepsyTypeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IctalTherapyClassificationControllerApi
     */
    public classifyCase2(epilepsyTypeId?: number, options?: AxiosRequestConfig) {
        return IctalTherapyClassificationControllerApiFp(this.configuration).classifyCase2(epilepsyTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {boolean} available 
     * @param {boolean} refunded 
     * @param {boolean} [reproductiveAgeOptional] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IctalTherapyClassificationControllerApi
     */
    public classifyCaseForPatient(patientId: number, available: boolean, refunded: boolean, reproductiveAgeOptional?: boolean, options?: AxiosRequestConfig) {
        return IctalTherapyClassificationControllerApiFp(this.configuration).classifyCaseForPatient(patientId, available, refunded, reproductiveAgeOptional, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {number} therapyTypeId 
     * @param {boolean} available 
     * @param {boolean} refunded 
     * @param {boolean} [reproductiveAgeOptional] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IctalTherapyClassificationControllerApi
     */
    public classifyCaseForPatientWithSelectedTherapyType(patientId: number, therapyTypeId: number, available: boolean, refunded: boolean, reproductiveAgeOptional?: boolean, options?: AxiosRequestConfig) {
        return IctalTherapyClassificationControllerApiFp(this.configuration).classifyCaseForPatientWithSelectedTherapyType(patientId, therapyTypeId, available, refunded, reproductiveAgeOptional, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} epilepsyTypesIds 
     * @param {number} therapyTypeId 
     * @param {number} age 
     * @param {boolean} pregnant 
     * @param {boolean} somaticDiseases 
     * @param {'MALE' | 'FEMALE'} sex 
     * @param {Array<number>} [usedDrugsIds] 
     * @param {string} [countryCode] 
     * @param {boolean} [shouldBeAvailable] 
     * @param {boolean} [shouldBeRefunded] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IctalTherapyClassificationControllerApi
     */
    public classifyCaseWithDescription(epilepsyTypesIds: Array<number>, therapyTypeId: number, age: number, pregnant: boolean, somaticDiseases: boolean, sex: 'MALE' | 'FEMALE', usedDrugsIds?: Array<number>, countryCode?: string, shouldBeAvailable?: boolean, shouldBeRefunded?: boolean, options?: AxiosRequestConfig) {
        return IctalTherapyClassificationControllerApiFp(this.configuration).classifyCaseWithDescription(epilepsyTypesIds, therapyTypeId, age, pregnant, somaticDiseases, sex, usedDrugsIds, countryCode, shouldBeAvailable, shouldBeRefunded, options).then((request) => request(this.axios, this.basePath));
    }
}
