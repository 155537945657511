/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { VisitAwaitingPayment } from './visit-awaiting-payment';

/**
 * 
 * @export
 * @interface VisitAwaitingPaymentsInfo
 */
export interface VisitAwaitingPaymentsInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof VisitAwaitingPaymentsInfo
     */
    'availablePaymentProviders': Array<VisitAwaitingPaymentsInfoAvailablePaymentProvidersEnum>;
    /**
     * 
     * @type {Array<VisitAwaitingPayment>}
     * @memberof VisitAwaitingPaymentsInfo
     */
    'awaitingPayments': Array<VisitAwaitingPayment>;
    /**
     * 
     * @type {boolean}
     * @memberof VisitAwaitingPaymentsInfo
     */
    'canceled': boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum VisitAwaitingPaymentsInfoAvailablePaymentProvidersEnum {
    Payu = 'PAYU',
    Paynow = 'PAYNOW'
}


