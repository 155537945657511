/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EpicrisisDto } from '../model';
// @ts-ignore
import { MedicalDocumentForEpicrisisDto } from '../model';
// @ts-ignore
import { PatientEpicrisisDetails } from '../model';
/**
 * EpicrisisControllerApi - axios parameter creator
 * @export
 */
export const EpicrisisControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {string} content 
         * @param {Array<any>} newFiles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEpicrisis: async (patientId: number, content: string, newFiles: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('addEpicrisis', 'patientId', patientId)
            // verify required parameter 'content' is not null or undefined
            assertParamExists('addEpicrisis', 'content', content)
            // verify required parameter 'newFiles' is not null or undefined
            assertParamExists('addEpicrisis', 'newFiles', newFiles)
            const localVarPath = `/api/epicrisis/new/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (content !== undefined) { 
                localVarFormParams.append('content', content as any);
            }
                if (newFiles) {
                newFiles.forEach((element) => {
                    localVarFormParams.append('newFiles', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedicalDocumentForEpicrisisDto} medicalDocumentForEpicrisisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMedicalDocumentToEpicrisis: async (medicalDocumentForEpicrisisDto: MedicalDocumentForEpicrisisDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'medicalDocumentForEpicrisisDto' is not null or undefined
            assertParamExists('addMedicalDocumentToEpicrisis', 'medicalDocumentForEpicrisisDto', medicalDocumentForEpicrisisDto)
            const localVarPath = `/api/epicrisis/medical-document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medicalDocumentForEpicrisisDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEpicrisis: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEpicrisis', 'id', id)
            const localVarPath = `/api/epicrisis/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadFile', 'id', id)
            const localVarPath = `/api/epicrisis/file/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpicrisisList: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getEpicrisisList', 'patientId', patientId)
            const localVarPath = `/api/epicrisis/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileContent: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFileContent', 'id', id)
            const localVarPath = `/api/epicrisis/file/{id}/content`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientDetails: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientDetails', 'patientId', patientId)
            const localVarPath = `/api/epicrisis/patient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} addedFiles 
         * @param {string} content 
         * @param {Array<any>} newFiles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpicrisis: async (id: number, addedFiles: Array<number>, content: string, newFiles: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEpicrisis', 'id', id)
            // verify required parameter 'addedFiles' is not null or undefined
            assertParamExists('updateEpicrisis', 'addedFiles', addedFiles)
            // verify required parameter 'content' is not null or undefined
            assertParamExists('updateEpicrisis', 'content', content)
            // verify required parameter 'newFiles' is not null or undefined
            assertParamExists('updateEpicrisis', 'newFiles', newFiles)
            const localVarPath = `/api/epicrisis/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (addedFiles) {
                localVarFormParams.append('addedFiles', addedFiles.join(COLLECTION_FORMATS.csv));
            }

    
            if (content !== undefined) { 
                localVarFormParams.append('content', content as any);
            }
                if (newFiles) {
                newFiles.forEach((element) => {
                    localVarFormParams.append('newFiles', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EpicrisisControllerApi - functional programming interface
 * @export
 */
export const EpicrisisControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EpicrisisControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {string} content 
         * @param {Array<any>} newFiles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEpicrisis(patientId: number, content: string, newFiles: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<EpicrisisDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEpicrisis(patientId, content, newFiles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedicalDocumentForEpicrisisDto} medicalDocumentForEpicrisisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMedicalDocumentToEpicrisis(medicalDocumentForEpicrisisDto: MedicalDocumentForEpicrisisDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMedicalDocumentToEpicrisis(medicalDocumentForEpicrisisDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEpicrisis(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEpicrisis(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFile(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEpicrisisList(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<EpicrisisDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEpicrisisList(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileContent(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileContent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientDetails(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientEpicrisisDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientDetails(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} addedFiles 
         * @param {string} content 
         * @param {Array<any>} newFiles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEpicrisis(id: number, addedFiles: Array<number>, content: string, newFiles: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<EpicrisisDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEpicrisis(id, addedFiles, content, newFiles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EpicrisisControllerApi - factory interface
 * @export
 */
export const EpicrisisControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EpicrisisControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {string} content 
         * @param {Array<any>} newFiles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEpicrisis(patientId: number, content: string, newFiles: Array<any>, options?: any): Promise<EpicrisisDto> {
            return localVarFp.addEpicrisis(patientId, content, newFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedicalDocumentForEpicrisisDto} medicalDocumentForEpicrisisDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMedicalDocumentToEpicrisis(medicalDocumentForEpicrisisDto: MedicalDocumentForEpicrisisDto, options?: any): Promise<void> {
            return localVarFp.addMedicalDocumentToEpicrisis(medicalDocumentForEpicrisisDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEpicrisis(id: number, options?: any): Promise<void> {
            return localVarFp.deleteEpicrisis(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile(id: number, options?: any): Promise<any> {
            return localVarFp.downloadFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpicrisisList(patientId: number, options?: any): Promise<Array<EpicrisisDto>> {
            return localVarFp.getEpicrisisList(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileContent(id: number, options?: any): Promise<string> {
            return localVarFp.getFileContent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientDetails(patientId: number, options?: any): Promise<PatientEpicrisisDetails> {
            return localVarFp.getPatientDetails(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} addedFiles 
         * @param {string} content 
         * @param {Array<any>} newFiles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEpicrisis(id: number, addedFiles: Array<number>, content: string, newFiles: Array<any>, options?: any): Promise<EpicrisisDto> {
            return localVarFp.updateEpicrisis(id, addedFiles, content, newFiles, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EpicrisisControllerApi - object-oriented interface
 * @export
 * @class EpicrisisControllerApi
 * @extends {BaseAPI}
 */
export class EpicrisisControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} patientId 
     * @param {string} content 
     * @param {Array<any>} newFiles 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpicrisisControllerApi
     */
    public addEpicrisis(patientId: number, content: string, newFiles: Array<any>, options?: AxiosRequestConfig) {
        return EpicrisisControllerApiFp(this.configuration).addEpicrisis(patientId, content, newFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicalDocumentForEpicrisisDto} medicalDocumentForEpicrisisDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpicrisisControllerApi
     */
    public addMedicalDocumentToEpicrisis(medicalDocumentForEpicrisisDto: MedicalDocumentForEpicrisisDto, options?: AxiosRequestConfig) {
        return EpicrisisControllerApiFp(this.configuration).addMedicalDocumentToEpicrisis(medicalDocumentForEpicrisisDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpicrisisControllerApi
     */
    public deleteEpicrisis(id: number, options?: AxiosRequestConfig) {
        return EpicrisisControllerApiFp(this.configuration).deleteEpicrisis(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpicrisisControllerApi
     */
    public downloadFile(id: number, options?: AxiosRequestConfig) {
        return EpicrisisControllerApiFp(this.configuration).downloadFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpicrisisControllerApi
     */
    public getEpicrisisList(patientId: number, options?: AxiosRequestConfig) {
        return EpicrisisControllerApiFp(this.configuration).getEpicrisisList(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpicrisisControllerApi
     */
    public getFileContent(id: number, options?: AxiosRequestConfig) {
        return EpicrisisControllerApiFp(this.configuration).getFileContent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpicrisisControllerApi
     */
    public getPatientDetails(patientId: number, options?: AxiosRequestConfig) {
        return EpicrisisControllerApiFp(this.configuration).getPatientDetails(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<number>} addedFiles 
     * @param {string} content 
     * @param {Array<any>} newFiles 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EpicrisisControllerApi
     */
    public updateEpicrisis(id: number, addedFiles: Array<number>, content: string, newFiles: Array<any>, options?: AxiosRequestConfig) {
        return EpicrisisControllerApiFp(this.configuration).updateEpicrisis(id, addedFiles, content, newFiles, options).then((request) => request(this.axios, this.basePath));
    }
}
