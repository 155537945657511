/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ActiveSubstanceDto } from './active-substance-dto';

/**
 * 
 * @export
 * @interface ActiveSubstanceHistoryDto
 */
export interface ActiveSubstanceHistoryDto {
    /**
     * 
     * @type {ActiveSubstanceDto}
     * @memberof ActiveSubstanceHistoryDto
     */
    'activeSubstance'?: ActiveSubstanceDto;
    /**
     * 
     * @type {string}
     * @memberof ActiveSubstanceHistoryDto
     */
    'effect'?: ActiveSubstanceHistoryDtoEffectEnum;
    /**
     * 
     * @type {string}
     * @memberof ActiveSubstanceHistoryDto
     */
    'fromDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActiveSubstanceHistoryDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActiveSubstanceHistoryDto
     */
    'time'?: ActiveSubstanceHistoryDtoTimeEnum;
    /**
     * 
     * @type {string}
     * @memberof ActiveSubstanceHistoryDto
     */
    'toDate'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ActiveSubstanceHistoryDtoEffectEnum {
    Remission = 'REMISSION',
    BigImprovement = 'BIG_IMPROVEMENT',
    LittleImprovement = 'LITTLE_IMPROVEMENT',
    NoChange = 'NO_CHANGE',
    LittleDeterioration = 'LITTLE_DETERIORATION',
    BigDeterioration = 'BIG_DETERIORATION',
    ExtremeDeterioration = 'EXTREME_DETERIORATION'
}
/**
    * @export
    * @enum {string}
    */
export enum ActiveSubstanceHistoryDtoTimeEnum {
    Past = 'PAST',
    Currently = 'CURRENTLY'
}


