/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PatientCertificateDocument } from '../model';
// @ts-ignore
import { PatientCertificateDto } from '../model';
/**
 * PatientCertificateApiControllerApi - axios parameter creator
 * @export
 */
export const PatientCertificateApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PatientCertificateDto} patientCertificateDto 
         * @param {boolean} [generateMedicalDoc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add: async (patientCertificateDto: PatientCertificateDto, generateMedicalDoc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientCertificateDto' is not null or undefined
            assertParamExists('add', 'patientCertificateDto', patientCertificateDto)
            const localVarPath = `/api/patient-certificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (generateMedicalDoc !== undefined) {
                localVarQueryParameter['generateMedicalDoc'] = generateMedicalDoc;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientCertificateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteById', 'id', id)
            const localVarPath = `/api/patient-certificate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('download1', 'id', id)
            const localVarPath = `/api/patient-certificate/document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByPatientId: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getByPatientId', 'patientId', patientId)
            const localVarPath = `/api/patient-certificate/patient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByVisitId: async (visitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('getByVisitId', 'visitId', visitId)
            const localVarPath = `/api/patient-certificate/visit/{visitId}`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/patient-certificate/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientCertificateApiControllerApi - functional programming interface
 * @export
 */
export const PatientCertificateApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientCertificateApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PatientCertificateDto} patientCertificateDto 
         * @param {boolean} [generateMedicalDoc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async add(patientCertificateDto: PatientCertificateDto, generateMedicalDoc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.add(patientCertificateDto, generateMedicalDoc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.download1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByPatientId(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<PatientCertificateDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByPatientId(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByVisitId(visitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<PatientCertificateDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByVisitId(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientCertificateApiControllerApi - factory interface
 * @export
 */
export const PatientCertificateApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientCertificateApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PatientCertificateDto} patientCertificateDto 
         * @param {boolean} [generateMedicalDoc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add(patientCertificateDto: PatientCertificateDto, generateMedicalDoc?: boolean, options?: any): Promise<void> {
            return localVarFp.add(patientCertificateDto, generateMedicalDoc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById(id: number, options?: any): Promise<void> {
            return localVarFp.deleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download1(id: number, options?: any): Promise<any> {
            return localVarFp.download1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByPatientId(patientId: number, options?: any): Promise<Array<PatientCertificateDocument>> {
            return localVarFp.getByPatientId(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByVisitId(visitId: number, options?: any): Promise<Array<PatientCertificateDocument>> {
            return localVarFp.getByVisitId(visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTypes(options?: any): Promise<Array<string>> {
            return localVarFp.getTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientCertificateApiControllerApi - object-oriented interface
 * @export
 * @class PatientCertificateApiControllerApi
 * @extends {BaseAPI}
 */
export class PatientCertificateApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {PatientCertificateDto} patientCertificateDto 
     * @param {boolean} [generateMedicalDoc] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientCertificateApiControllerApi
     */
    public add(patientCertificateDto: PatientCertificateDto, generateMedicalDoc?: boolean, options?: AxiosRequestConfig) {
        return PatientCertificateApiControllerApiFp(this.configuration).add(patientCertificateDto, generateMedicalDoc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientCertificateApiControllerApi
     */
    public deleteById(id: number, options?: AxiosRequestConfig) {
        return PatientCertificateApiControllerApiFp(this.configuration).deleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientCertificateApiControllerApi
     */
    public download1(id: number, options?: AxiosRequestConfig) {
        return PatientCertificateApiControllerApiFp(this.configuration).download1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientCertificateApiControllerApi
     */
    public getByPatientId(patientId: number, options?: AxiosRequestConfig) {
        return PatientCertificateApiControllerApiFp(this.configuration).getByPatientId(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientCertificateApiControllerApi
     */
    public getByVisitId(visitId: number, options?: AxiosRequestConfig) {
        return PatientCertificateApiControllerApiFp(this.configuration).getByVisitId(visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientCertificateApiControllerApi
     */
    public getTypes(options?: AxiosRequestConfig) {
        return PatientCertificateApiControllerApiFp(this.configuration).getTypes(options).then((request) => request(this.axios, this.basePath));
    }
}
