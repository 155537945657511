/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DrugTherapyDto } from '../model';
// @ts-ignore
import { DrugUsageDto } from '../model';
// @ts-ignore
import { DrugUsageUpdateDto } from '../model';
/**
 * DrugTherapyApiControllerApi - axios parameter creator
 * @export
 */
export const DrugTherapyApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentDrugTherapy: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/drug-therapy/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTherapyForPatient: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getCurrentTherapyForPatient', 'patientId', patientId)
            const localVarPath = `/api/drug-therapy/current/patient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugTherapiesForVisit: async (visitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('getDrugTherapiesForVisit', 'visitId', visitId)
            const localVarPath = `/api/drug-therapy/visit/{visitId}`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientTherapiesHistory: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientTherapiesHistory', 'patientId', patientId)
            const localVarPath = `/api/drug-therapy/history/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpdatePatientTherapiesHistoryAccess: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getUpdatePatientTherapiesHistoryAccess', 'patientId', patientId)
            const localVarPath = `/api/drug-therapy/history/{patientId}/update`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DrugUsageUpdateDto} drugUsageUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDrugUsage: async (drugUsageUpdateDto: DrugUsageUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'drugUsageUpdateDto' is not null or undefined
            assertParamExists('updateDrugUsage', 'drugUsageUpdateDto', drugUsageUpdateDto)
            const localVarPath = `/api/drug-therapy/usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(drugUsageUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Array<DrugTherapyDto>} drugTherapyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientTherapiesHistory: async (patientId: number, drugTherapyDto: Array<DrugTherapyDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('updatePatientTherapiesHistory', 'patientId', patientId)
            // verify required parameter 'drugTherapyDto' is not null or undefined
            assertParamExists('updatePatientTherapiesHistory', 'drugTherapyDto', drugTherapyDto)
            const localVarPath = `/api/drug-therapy/history/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(drugTherapyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DrugTherapyApiControllerApi - functional programming interface
 * @export
 */
export const DrugTherapyApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DrugTherapyApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentDrugTherapy(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DrugTherapyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentDrugTherapy(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentTherapyForPatient(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DrugTherapyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentTherapyForPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDrugTherapiesForVisit(visitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DrugTherapyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDrugTherapiesForVisit(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientTherapiesHistory(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DrugTherapyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientTherapiesHistory(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUpdatePatientTherapiesHistoryAccess(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUpdatePatientTherapiesHistoryAccess(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DrugUsageUpdateDto} drugUsageUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDrugUsage(drugUsageUpdateDto: DrugUsageUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DrugUsageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDrugUsage(drugUsageUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Array<DrugTherapyDto>} drugTherapyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePatientTherapiesHistory(patientId: number, drugTherapyDto: Array<DrugTherapyDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePatientTherapiesHistory(patientId, drugTherapyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DrugTherapyApiControllerApi - factory interface
 * @export
 */
export const DrugTherapyApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DrugTherapyApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentDrugTherapy(options?: any): Promise<DrugTherapyDto> {
            return localVarFp.getCurrentDrugTherapy(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTherapyForPatient(patientId: number, options?: any): Promise<DrugTherapyDto> {
            return localVarFp.getCurrentTherapyForPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugTherapiesForVisit(visitId: number, options?: any): Promise<Array<DrugTherapyDto>> {
            return localVarFp.getDrugTherapiesForVisit(visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientTherapiesHistory(patientId: number, options?: any): Promise<Array<DrugTherapyDto>> {
            return localVarFp.getPatientTherapiesHistory(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpdatePatientTherapiesHistoryAccess(patientId: number, options?: any): Promise<boolean> {
            return localVarFp.getUpdatePatientTherapiesHistoryAccess(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DrugUsageUpdateDto} drugUsageUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDrugUsage(drugUsageUpdateDto: DrugUsageUpdateDto, options?: any): Promise<DrugUsageDto> {
            return localVarFp.updateDrugUsage(drugUsageUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Array<DrugTherapyDto>} drugTherapyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientTherapiesHistory(patientId: number, drugTherapyDto: Array<DrugTherapyDto>, options?: any): Promise<void> {
            return localVarFp.updatePatientTherapiesHistory(patientId, drugTherapyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DrugTherapyApiControllerApi - object-oriented interface
 * @export
 * @class DrugTherapyApiControllerApi
 * @extends {BaseAPI}
 */
export class DrugTherapyApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrugTherapyApiControllerApi
     */
    public getCurrentDrugTherapy(options?: AxiosRequestConfig) {
        return DrugTherapyApiControllerApiFp(this.configuration).getCurrentDrugTherapy(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrugTherapyApiControllerApi
     */
    public getCurrentTherapyForPatient(patientId: number, options?: AxiosRequestConfig) {
        return DrugTherapyApiControllerApiFp(this.configuration).getCurrentTherapyForPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrugTherapyApiControllerApi
     */
    public getDrugTherapiesForVisit(visitId: number, options?: AxiosRequestConfig) {
        return DrugTherapyApiControllerApiFp(this.configuration).getDrugTherapiesForVisit(visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrugTherapyApiControllerApi
     */
    public getPatientTherapiesHistory(patientId: number, options?: AxiosRequestConfig) {
        return DrugTherapyApiControllerApiFp(this.configuration).getPatientTherapiesHistory(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrugTherapyApiControllerApi
     */
    public getUpdatePatientTherapiesHistoryAccess(patientId: number, options?: AxiosRequestConfig) {
        return DrugTherapyApiControllerApiFp(this.configuration).getUpdatePatientTherapiesHistoryAccess(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DrugUsageUpdateDto} drugUsageUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrugTherapyApiControllerApi
     */
    public updateDrugUsage(drugUsageUpdateDto: DrugUsageUpdateDto, options?: AxiosRequestConfig) {
        return DrugTherapyApiControllerApiFp(this.configuration).updateDrugUsage(drugUsageUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {Array<DrugTherapyDto>} drugTherapyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DrugTherapyApiControllerApi
     */
    public updatePatientTherapiesHistory(patientId: number, drugTherapyDto: Array<DrugTherapyDto>, options?: AxiosRequestConfig) {
        return DrugTherapyApiControllerApiFp(this.configuration).updatePatientTherapiesHistory(patientId, drugTherapyDto, options).then((request) => request(this.axios, this.basePath));
    }
}
