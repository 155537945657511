/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AddressDto } from './address-dto';
import { DoctorDto } from './doctor-dto';
import { HasEpilepsyEnum } from './has-epilepsy-enum';
import { UserAccountDto } from './user-account-dto';

/**
 * 
 * @export
 * @interface PatientDto
 */
export interface PatientDto {
    /**
     * 
     * @type {AddressDto}
     * @memberof PatientDto
     */
    'address'?: AddressDto;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDto
     */
    'afterVisitZero'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'antibodies'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'bloodType'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientDto
     */
    'condition'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'diagnosticPhase'?: PatientDtoDiagnosticPhaseEnum;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'epilepsyDescription'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDto
     */
    'fromNsph'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDto
     */
    'hasAppointment'?: boolean;
    /**
     * 
     * @type {HasEpilepsyEnum}
     * @memberof PatientDto
     */
    'hasEpilepsy'?: HasEpilepsyEnum;
    /**
     * 
     * @type {number}
     * @memberof PatientDto
     */
    'healthPlanId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatientDto
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatientDto
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDto
     */
    'initialized'?: boolean;
    /**
     * 
     * @type {DoctorDto}
     * @memberof PatientDto
     */
    'leadDoctor'?: DoctorDto;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'leadDoctorSince'?: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof PatientDto
     */
    'mailingAddress'?: AddressDto;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDto
     */
    'medicalHistoryFilled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDto
     */
    'myDataFilled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'publicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'rh'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDto
     */
    'treatedBefore'?: boolean;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof PatientDto
     */
    'userAccount'?: UserAccountDto;
    /**
     * 
     * @type {number}
     * @memberof PatientDto
     */
    'weight'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PatientDtoDiagnosticPhaseEnum {
    Diagnostic = 'DIAGNOSTIC',
    Additional = 'ADDITIONAL',
    Drug = 'DRUG',
    Alter = 'ALTER',
    Stable = 'STABLE',
    Dismiss = 'DISMISS'
}


