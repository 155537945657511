/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AvailablePricelistServiceOrderConfigurationDto } from './available-pricelist-service-order-configuration-dto';
import { DoctorVisitsInfoDto } from './doctor-visits-info-dto';
import { PaymentLimitsDto } from './payment-limits-dto';
import { VisitPackageInfoDto } from './visit-package-info-dto';

/**
 * 
 * @export
 * @interface AvailablePricelistDto
 */
export interface AvailablePricelistDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AvailablePricelistDto
     */
    'availablePaymentProviders': Array<AvailablePricelistDtoAvailablePaymentProvidersEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof AvailablePricelistDto
     */
    'neuroscreenDiagnoses'?: Set<AvailablePricelistDtoNeuroscreenDiagnosesEnum>;
    /**
     * 
     * @type {AvailablePricelistServiceOrderConfigurationDto}
     * @memberof AvailablePricelistDto
     */
    'orderConfig'?: AvailablePricelistServiceOrderConfigurationDto;
    /**
     * 
     * @type {Array<VisitPackageInfoDto>}
     * @memberof AvailablePricelistDto
     */
    'packageInfo': Array<VisitPackageInfoDto>;
    /**
     * 
     * @type {PaymentLimitsDto}
     * @memberof AvailablePricelistDto
     */
    'paymentLimits': PaymentLimitsDto;
    /**
     * 
     * @type {Array<DoctorVisitsInfoDto>}
     * @memberof AvailablePricelistDto
     */
    'visitsInfo': Array<DoctorVisitsInfoDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum AvailablePricelistDtoAvailablePaymentProvidersEnum {
    Payu = 'PAYU',
    Paynow = 'PAYNOW'
}
/**
    * @export
    * @enum {string}
    */
export enum AvailablePricelistDtoNeuroscreenDiagnosesEnum {
    Headaches = 'HEADACHES',
    MovementIssues = 'MOVEMENT_ISSUES',
    MemoryIssues = 'MEMORY_ISSUES',
    EatingIssues = 'EATING_ISSUES',
    ConsciousnessIssues = 'CONSCIOUSNESS_ISSUES',
    PsychomotorDevelopmentIssues = 'PSYCHOMOTOR_DEVELOPMENT_ISSUES',
    Epilepsy = 'EPILEPSY',
    SpineIssues = 'SPINE_ISSUES',
    MutipleSclerosis = 'MUTIPLE_SCLEROSIS',
    Dizziness = 'DIZZINESS',
    Autism = 'AUTISM',
    OtherSymptoms = 'OTHER_SYMPTOMS'
}


