import Vue from "vue";
import VueI18n from "vue-i18n";
import translationsPl from "../i18n/translations-pl";
import translationsEn from "../i18n/translations-en";
import translationsFr from "../i18n/translations-fr";
import translationsCs from "../i18n/translations-cs";
import translationsUk from "../i18n/translations-uk";
import translationsHu from "../i18n/translations-hu";
import translationsDe from "../i18n/translations-de";
import am4lang_pl_PL from "@amcharts/amcharts4/lang/pl_PL";
import am4lang_en_US from "@amcharts/amcharts4/lang/en_US";
import am4lang_fr_FR from "@amcharts/amcharts4/lang/fr_FR";
import am4lang_cs_CZ from "@amcharts/amcharts4/lang/cs_CZ";
import am4lang_uk_UA from "@amcharts/amcharts4/lang/uk_UA";
import am4lang_hu_HU from "@amcharts/amcharts4/lang/hu_HU";
import am4lang_de_DE from "@amcharts/amcharts4/lang/de_DE";

VueI18n.prototype.localeConfigs = [
    {
        code: 'pl',
        language: 'pl-PL',
        label: 'Polski',
        countryCode: 'pl',
        charts: am4lang_pl_PL
    },
    {
        code: 'en',
        language: 'en-US',
        label: 'English',
        countryCode: 'gb',
        charts: am4lang_en_US
    },
    {
        code: 'fr',
        language: 'fr-FR',
        label: 'Français',
        countryCode: 'fr',
        charts: am4lang_fr_FR
    },
    {
        code: 'cs',
        language: 'cs-CZ',
        label: 'Čeština',
        countryCode: 'cz',
        charts: am4lang_cs_CZ
    },
    {
        code: 'uk',
        language: 'uk-UA',
        label: 'Український',
        countryCode: 'ua',
        charts: am4lang_uk_UA
    },
    {
        code: 'hu',
        language: 'hu-HU',
        label: 'Magyar',
        countryCode: 'hu',
        charts: am4lang_hu_HU
    },
    {
        code: 'de',
        language: 'de-DE',
        label: 'Deutsch',
        countryCode: 'de',
        charts: am4lang_de_DE
    }
];

Vue.use(VueI18n);

VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {

    if (this.locale !== 'pl') {
        // proceed to the default implementation
    }

    if (!choice) {
        return 0;
    }

    if (choice === 1) {
        return 1;
    }

    if (choice >= 2 && choice <= 4) {
        return 2;
    }

    return 3;
};

export default new VueI18n({
    locale: 'pl',
    fallbackLocale: 'pl',
    messages: {
        pl: translationsPl.translations,
        en: translationsEn.translations,
        fr: translationsFr.translations,
        cs: translationsCs.translations,
        uk: translationsUk.translations,
        hu: translationsHu.translations,
        de: translationsDe.translations
    },
    numberFormats: {
        pl: translationsPl.formats,
        en: translationsEn.formats,
        fr: translationsFr.formats,
        cs: translationsCs.formats,
        uk: translationsUk.formats,
        hu: translationsHu.formats,
        de: translationsDe.formats
    }
})
