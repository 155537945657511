/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AdminData } from './admin-data';

/**
 * 
 * @export
 * @interface PatientCertificateDto
 */
export interface PatientCertificateDto {
    /**
     * 
     * @type {string}
     * @memberof PatientCertificateDto
     */
    'activityName'?: string;
    /**
     * 
     * @type {AdminData}
     * @memberof PatientCertificateDto
     */
    'addedByAdmin'?: AdminData;
    /**
     * 
     * @type {string}
     * @memberof PatientCertificateDto
     */
    'additionalRemarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientCertificateDto
     */
    'dateUntil'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientCertificateDto
     */
    'drugTherapyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatientCertificateDto
     */
    'otherDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientCertificateDto
     */
    'procedureName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientCertificateDto
     */
    'type': PatientCertificateDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PatientCertificateDto
     */
    'visitId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PatientCertificateDtoTypeEnum {
    DrugsIntake = 'DRUGS_INTAKE',
    PostponeVaccination = 'POSTPONE_VACCINATION',
    NoContraindicationForVaccine = 'NO_CONTRAINDICATION_FOR_VACCINE',
    NoContraindicationForProcedure = 'NO_CONTRAINDICATION_FOR_PROCEDURE',
    NoContraindicationForActivities = 'NO_CONTRAINDICATION_FOR_ACTIVITIES',
    NoContraindicationForWork = 'NO_CONTRAINDICATION_FOR_WORK',
    Other = 'OTHER'
}


