/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { FeatureStrategyDto } from './feature-strategy-dto';

/**
 * 
 * @export
 * @interface FeatureDto
 */
export interface FeatureDto {
    /**
     * 
     * @type {boolean}
     * @memberof FeatureDto
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof FeatureDto
     */
    'feature': FeatureDtoFeatureEnum;
    /**
     * 
     * @type {string}
     * @memberof FeatureDto
     */
    'label': string;
    /**
     * 
     * @type {FeatureStrategyDto}
     * @memberof FeatureDto
     */
    'selectedStrategy'?: FeatureStrategyDto;
}

/**
    * @export
    * @enum {string}
    */
export enum FeatureDtoFeatureEnum {
    ActiveDrugTherapiesOnPrintout = 'ACTIVE_DRUG_THERAPIES_ON_PRINTOUT',
    PaymentMarkingMaxPrice = 'PAYMENT_MARKING_MAX_PRICE',
    StandardRefundMaxPrice = 'STANDARD_REFUND_MAX_PRICE',
    NoLimitVisitChange = 'NO_LIMIT_VISIT_CHANGE',
    EmailChangeRequest = 'EMAIL_CHANGE_REQUEST',
    AddressChangedBar = 'ADDRESS_CHANGED_BAR',
    DoctorAccessToPatientManagement = 'DOCTOR_ACCESS_TO_PATIENT_MANAGEMENT',
    AwsS3Storage = 'AWS_S3_STORAGE'
}


