/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EReferralIssuanceDto } from '../model';
// @ts-ignore
import { ExternalReferralDto } from '../model';
// @ts-ignore
import { ListResponseDtoPatientPrescriptionDto } from '../model';
// @ts-ignore
import { PackageDto } from '../model';
// @ts-ignore
import { Pageable } from '../model';
// @ts-ignore
import { PatientPrescriptionDto } from '../model';
// @ts-ignore
import { PrescriptionIssuanceDto } from '../model';
/**
 * EHealthApiControllerApi - axios parameter creator
 * @export
 */
export const EHealthApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} pesel 
         * @param {boolean} [forceCheck] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPeselInEwus: async (pesel: string, forceCheck?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pesel' is not null or undefined
            assertParamExists('checkPeselInEwus', 'pesel', pesel)
            const localVarPath = `/api/ehealth/ewus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pesel !== undefined) {
                localVarQueryParameter['pesel'] = pesel;
            }

            if (forceCheck !== undefined) {
                localVarQueryParameter['forceCheck'] = forceCheck;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EReferralIssuanceDto} eReferralIssuanceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEReferral: async (eReferralIssuanceDto: EReferralIssuanceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eReferralIssuanceDto' is not null or undefined
            assertParamExists('createEReferral', 'eReferralIssuanceDto', eReferralIssuanceDto)
            const localVarPath = `/api/ehealth/e-referral`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eReferralIssuanceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [read] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientPrescriptions: async (patientId: number, pageable: Pageable, dateFrom?: string, dateTo?: string, read?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientPrescriptions', 'patientId', patientId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getPatientPrescriptions', 'pageable', pageable)
            const localVarPath = `/api/ehealth/prescription/patient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }

            if (read !== undefined) {
                localVarQueryParameter['read'] = read;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrescriptionPdf: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPrescriptionPdf', 'id', id)
            const localVarPath = `/api/ehealth/prescription/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductPackages: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProductPackages', 'id', id)
            const localVarPath = `/api/ehealth/product/packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralPdf: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReferralPdf', 'id', id)
            const localVarPath = `/api/ehealth/referral/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PrescriptionIssuanceDto} prescriptionIssuanceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuePrescription: async (prescriptionIssuanceDto: PrescriptionIssuanceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prescriptionIssuanceDto' is not null or undefined
            assertParamExists('issuePrescription', 'prescriptionIssuanceDto', prescriptionIssuanceDto)
            const localVarPath = `/api/ehealth/prescription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(prescriptionIssuanceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} prescriptionId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuePrescription1: async (prescriptionId: number, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prescriptionId' is not null or undefined
            assertParamExists('issuePrescription1', 'prescriptionId', prescriptionId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('issuePrescription1', 'file', file)
            const localVarPath = `/api/ehealth/prescription/{prescriptionId}/file`
                .replace(`{${"prescriptionId"}}`, encodeURIComponent(String(prescriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrescriptionsRead: async (requestBody: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('setPrescriptionsRead', 'requestBody', requestBody)
            const localVarPath = `/api/ehealth/prescription/patient/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EHealthApiControllerApi - functional programming interface
 * @export
 */
export const EHealthApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EHealthApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} pesel 
         * @param {boolean} [forceCheck] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPeselInEwus(pesel: string, forceCheck?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPeselInEwus(pesel, forceCheck, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EReferralIssuanceDto} eReferralIssuanceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEReferral(eReferralIssuanceDto: EReferralIssuanceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<ExternalReferralDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEReferral(eReferralIssuanceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [read] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientPrescriptions(patientId: number, pageable: Pageable, dateFrom?: string, dateTo?: string, read?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<ListResponseDtoPatientPrescriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientPrescriptions(patientId, pageable, dateFrom, dateTo, read, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrescriptionPdf(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrescriptionPdf(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductPackages(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<PackageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductPackages(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralPdf(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralPdf(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PrescriptionIssuanceDto} prescriptionIssuanceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuePrescription(prescriptionIssuanceDto: PrescriptionIssuanceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<PatientPrescriptionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuePrescription(prescriptionIssuanceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} prescriptionId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuePrescription1(prescriptionId: number, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuePrescription1(prescriptionId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPrescriptionsRead(requestBody: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPrescriptionsRead(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EHealthApiControllerApi - factory interface
 * @export
 */
export const EHealthApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EHealthApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} pesel 
         * @param {boolean} [forceCheck] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPeselInEwus(pesel: string, forceCheck?: boolean, options?: any): Promise<string> {
            return localVarFp.checkPeselInEwus(pesel, forceCheck, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EReferralIssuanceDto} eReferralIssuanceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEReferral(eReferralIssuanceDto: EReferralIssuanceDto, options?: any): Promise<ExternalReferralDto> {
            return localVarFp.createEReferral(eReferralIssuanceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [read] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientPrescriptions(patientId: number, pageable: Pageable, dateFrom?: string, dateTo?: string, read?: boolean, options?: any): Promise<ListResponseDtoPatientPrescriptionDto> {
            return localVarFp.getPatientPrescriptions(patientId, pageable, dateFrom, dateTo, read, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrescriptionPdf(id: number, options?: any): Promise<string> {
            return localVarFp.getPrescriptionPdf(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductPackages(id: number, options?: any): Promise<Array<PackageDto>> {
            return localVarFp.getProductPackages(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralPdf(id: number, options?: any): Promise<string> {
            return localVarFp.getReferralPdf(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PrescriptionIssuanceDto} prescriptionIssuanceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuePrescription(prescriptionIssuanceDto: PrescriptionIssuanceDto, options?: any): Promise<Array<PatientPrescriptionDto>> {
            return localVarFp.issuePrescription(prescriptionIssuanceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} prescriptionId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuePrescription1(prescriptionId: number, file: any, options?: any): Promise<void> {
            return localVarFp.issuePrescription1(prescriptionId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrescriptionsRead(requestBody: Array<number>, options?: any): Promise<void> {
            return localVarFp.setPrescriptionsRead(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EHealthApiControllerApi - object-oriented interface
 * @export
 * @class EHealthApiControllerApi
 * @extends {BaseAPI}
 */
export class EHealthApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} pesel 
     * @param {boolean} [forceCheck] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EHealthApiControllerApi
     */
    public checkPeselInEwus(pesel: string, forceCheck?: boolean, options?: AxiosRequestConfig) {
        return EHealthApiControllerApiFp(this.configuration).checkPeselInEwus(pesel, forceCheck, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EReferralIssuanceDto} eReferralIssuanceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EHealthApiControllerApi
     */
    public createEReferral(eReferralIssuanceDto: EReferralIssuanceDto, options?: AxiosRequestConfig) {
        return EHealthApiControllerApiFp(this.configuration).createEReferral(eReferralIssuanceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {Pageable} pageable 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {boolean} [read] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EHealthApiControllerApi
     */
    public getPatientPrescriptions(patientId: number, pageable: Pageable, dateFrom?: string, dateTo?: string, read?: boolean, options?: AxiosRequestConfig) {
        return EHealthApiControllerApiFp(this.configuration).getPatientPrescriptions(patientId, pageable, dateFrom, dateTo, read, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EHealthApiControllerApi
     */
    public getPrescriptionPdf(id: number, options?: AxiosRequestConfig) {
        return EHealthApiControllerApiFp(this.configuration).getPrescriptionPdf(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EHealthApiControllerApi
     */
    public getProductPackages(id: number, options?: AxiosRequestConfig) {
        return EHealthApiControllerApiFp(this.configuration).getProductPackages(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EHealthApiControllerApi
     */
    public getReferralPdf(id: number, options?: AxiosRequestConfig) {
        return EHealthApiControllerApiFp(this.configuration).getReferralPdf(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PrescriptionIssuanceDto} prescriptionIssuanceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EHealthApiControllerApi
     */
    public issuePrescription(prescriptionIssuanceDto: PrescriptionIssuanceDto, options?: AxiosRequestConfig) {
        return EHealthApiControllerApiFp(this.configuration).issuePrescription(prescriptionIssuanceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} prescriptionId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EHealthApiControllerApi
     */
    public issuePrescription1(prescriptionId: number, file: any, options?: AxiosRequestConfig) {
        return EHealthApiControllerApiFp(this.configuration).issuePrescription1(prescriptionId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EHealthApiControllerApi
     */
    public setPrescriptionsRead(requestBody: Array<number>, options?: AxiosRequestConfig) {
        return EHealthApiControllerApiFp(this.configuration).setPrescriptionsRead(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}
