/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PageAdminReferralDto } from '../model';
// @ts-ignore
import { PageExternalReferralDto } from '../model';
/**
 * AdminReferralControllerApi - axios parameter creator
 * @export
 */
export const AdminReferralControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalReferralPdf: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExternalReferralPdf', 'id', id)
            const localVarPath = `/api/admin/referral/external/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [patientId] 
         * @param {number} [doctorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalReferrals1: async (patientId?: number, doctorId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/referral/external`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (patientId !== undefined) {
                localVarQueryParameter['patientId'] = patientId;
            }

            if (doctorId !== undefined) {
                localVarQueryParameter['doctorId'] = doctorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<'NEW' | 'CANCELLED' | 'DONE'>} statuses 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalReferrals: async (statuses: Array<'NEW' | 'CANCELLED' | 'DONE'>, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'statuses' is not null or undefined
            assertParamExists('getInternalReferrals', 'statuses', statuses)
            const localVarPath = `/api/admin/referral`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} referralId 
         * @param {string} newScheduledDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReferralScheduledDate: async (referralId: number, newScheduledDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralId' is not null or undefined
            assertParamExists('updateReferralScheduledDate', 'referralId', referralId)
            // verify required parameter 'newScheduledDate' is not null or undefined
            assertParamExists('updateReferralScheduledDate', 'newScheduledDate', newScheduledDate)
            const localVarPath = `/api/admin/referral/{referralId}`
                .replace(`{${"referralId"}}`, encodeURIComponent(String(referralId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (newScheduledDate !== undefined) {
                localVarQueryParameter['newScheduledDate'] = (newScheduledDate as any instanceof Date) ?
                    (newScheduledDate as any).toISOString().substr(0,10) :
                    newScheduledDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminReferralControllerApi - functional programming interface
 * @export
 */
export const AdminReferralControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminReferralControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalReferralPdf(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalReferralPdf(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [patientId] 
         * @param {number} [doctorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalReferrals1(patientId?: number, doctorId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PageExternalReferralDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalReferrals1(patientId, doctorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<'NEW' | 'CANCELLED' | 'DONE'>} statuses 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInternalReferrals(statuses: Array<'NEW' | 'CANCELLED' | 'DONE'>, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PageAdminReferralDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInternalReferrals(statuses, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} referralId 
         * @param {string} newScheduledDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReferralScheduledDate(referralId: number, newScheduledDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReferralScheduledDate(referralId, newScheduledDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminReferralControllerApi - factory interface
 * @export
 */
export const AdminReferralControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminReferralControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalReferralPdf(id: number, options?: any): Promise<string> {
            return localVarFp.getExternalReferralPdf(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [patientId] 
         * @param {number} [doctorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalReferrals1(patientId?: number, doctorId?: number, options?: any): Promise<PageExternalReferralDto> {
            return localVarFp.getExternalReferrals1(patientId, doctorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<'NEW' | 'CANCELLED' | 'DONE'>} statuses 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalReferrals(statuses: Array<'NEW' | 'CANCELLED' | 'DONE'>, from?: string, to?: string, options?: any): Promise<PageAdminReferralDto> {
            return localVarFp.getInternalReferrals(statuses, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} referralId 
         * @param {string} newScheduledDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReferralScheduledDate(referralId: number, newScheduledDate: string, options?: any): Promise<void> {
            return localVarFp.updateReferralScheduledDate(referralId, newScheduledDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminReferralControllerApi - object-oriented interface
 * @export
 * @class AdminReferralControllerApi
 * @extends {BaseAPI}
 */
export class AdminReferralControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminReferralControllerApi
     */
    public getExternalReferralPdf(id: number, options?: AxiosRequestConfig) {
        return AdminReferralControllerApiFp(this.configuration).getExternalReferralPdf(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [patientId] 
     * @param {number} [doctorId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminReferralControllerApi
     */
    public getExternalReferrals1(patientId?: number, doctorId?: number, options?: AxiosRequestConfig) {
        return AdminReferralControllerApiFp(this.configuration).getExternalReferrals1(patientId, doctorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<'NEW' | 'CANCELLED' | 'DONE'>} statuses 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminReferralControllerApi
     */
    public getInternalReferrals(statuses: Array<'NEW' | 'CANCELLED' | 'DONE'>, from?: string, to?: string, options?: AxiosRequestConfig) {
        return AdminReferralControllerApiFp(this.configuration).getInternalReferrals(statuses, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} referralId 
     * @param {string} newScheduledDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminReferralControllerApi
     */
    public updateReferralScheduledDate(referralId: number, newScheduledDate: string, options?: AxiosRequestConfig) {
        return AdminReferralControllerApiFp(this.configuration).updateReferralScheduledDate(referralId, newScheduledDate, options).then((request) => request(this.axios, this.basePath));
    }
}
