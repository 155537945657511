/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { NeuroscreenAdultDto } from '../model';
// @ts-ignore
import { NeuroscreenChildDto } from '../model';
// @ts-ignore
import { NeuroscreenDiagnosisResultDto } from '../model';
/**
 * NeuroscreenControllerApi - axios parameter creator
 * @export
 */
export const NeuroscreenControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNeuroscreenAdult: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getNeuroscreenAdult', 'patientId', patientId)
            const localVarPath = `/api/neuroscreen/adult/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNeuroscreenChild: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getNeuroscreenChild', 'patientId', patientId)
            const localVarPath = `/api/neuroscreen/child/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NeuroscreenAdultDto} neuroscreenAdultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAnonymousNeuroscreenAdult: async (neuroscreenAdultDto: NeuroscreenAdultDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'neuroscreenAdultDto' is not null or undefined
            assertParamExists('saveAnonymousNeuroscreenAdult', 'neuroscreenAdultDto', neuroscreenAdultDto)
            const localVarPath = `/api/neuroscreen/anonymous/adult`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(neuroscreenAdultDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NeuroscreenChildDto} neuroscreenChildDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAnonymousNeuroscreenChild: async (neuroscreenChildDto: NeuroscreenChildDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'neuroscreenChildDto' is not null or undefined
            assertParamExists('saveAnonymousNeuroscreenChild', 'neuroscreenChildDto', neuroscreenChildDto)
            const localVarPath = `/api/neuroscreen/anonymous/child`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(neuroscreenChildDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {NeuroscreenAdultDto} neuroscreenAdultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveNeuroscreenAdult: async (patientId: number, neuroscreenAdultDto: NeuroscreenAdultDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('saveNeuroscreenAdult', 'patientId', patientId)
            // verify required parameter 'neuroscreenAdultDto' is not null or undefined
            assertParamExists('saveNeuroscreenAdult', 'neuroscreenAdultDto', neuroscreenAdultDto)
            const localVarPath = `/api/neuroscreen/adult/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(neuroscreenAdultDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {NeuroscreenChildDto} neuroscreenChildDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveNeuroscreenChild: async (patientId: number, neuroscreenChildDto: NeuroscreenChildDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('saveNeuroscreenChild', 'patientId', patientId)
            // verify required parameter 'neuroscreenChildDto' is not null or undefined
            assertParamExists('saveNeuroscreenChild', 'neuroscreenChildDto', neuroscreenChildDto)
            const localVarPath = `/api/neuroscreen/child/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(neuroscreenChildDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NeuroscreenControllerApi - functional programming interface
 * @export
 */
export const NeuroscreenControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NeuroscreenControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNeuroscreenAdult(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<NeuroscreenAdultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNeuroscreenAdult(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNeuroscreenChild(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<NeuroscreenChildDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNeuroscreenChild(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NeuroscreenAdultDto} neuroscreenAdultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAnonymousNeuroscreenAdult(neuroscreenAdultDto: NeuroscreenAdultDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAnonymousNeuroscreenAdult(neuroscreenAdultDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NeuroscreenChildDto} neuroscreenChildDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAnonymousNeuroscreenChild(neuroscreenChildDto: NeuroscreenChildDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAnonymousNeuroscreenChild(neuroscreenChildDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {NeuroscreenAdultDto} neuroscreenAdultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveNeuroscreenAdult(patientId: number, neuroscreenAdultDto: NeuroscreenAdultDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<NeuroscreenDiagnosisResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveNeuroscreenAdult(patientId, neuroscreenAdultDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {NeuroscreenChildDto} neuroscreenChildDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveNeuroscreenChild(patientId: number, neuroscreenChildDto: NeuroscreenChildDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<NeuroscreenDiagnosisResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveNeuroscreenChild(patientId, neuroscreenChildDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NeuroscreenControllerApi - factory interface
 * @export
 */
export const NeuroscreenControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NeuroscreenControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNeuroscreenAdult(patientId: number, options?: any): Promise<NeuroscreenAdultDto> {
            return localVarFp.getNeuroscreenAdult(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNeuroscreenChild(patientId: number, options?: any): Promise<NeuroscreenChildDto> {
            return localVarFp.getNeuroscreenChild(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NeuroscreenAdultDto} neuroscreenAdultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAnonymousNeuroscreenAdult(neuroscreenAdultDto: NeuroscreenAdultDto, options?: any): Promise<string> {
            return localVarFp.saveAnonymousNeuroscreenAdult(neuroscreenAdultDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NeuroscreenChildDto} neuroscreenChildDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAnonymousNeuroscreenChild(neuroscreenChildDto: NeuroscreenChildDto, options?: any): Promise<string> {
            return localVarFp.saveAnonymousNeuroscreenChild(neuroscreenChildDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {NeuroscreenAdultDto} neuroscreenAdultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveNeuroscreenAdult(patientId: number, neuroscreenAdultDto: NeuroscreenAdultDto, options?: any): Promise<Array<NeuroscreenDiagnosisResultDto>> {
            return localVarFp.saveNeuroscreenAdult(patientId, neuroscreenAdultDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {NeuroscreenChildDto} neuroscreenChildDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveNeuroscreenChild(patientId: number, neuroscreenChildDto: NeuroscreenChildDto, options?: any): Promise<Array<NeuroscreenDiagnosisResultDto>> {
            return localVarFp.saveNeuroscreenChild(patientId, neuroscreenChildDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NeuroscreenControllerApi - object-oriented interface
 * @export
 * @class NeuroscreenControllerApi
 * @extends {BaseAPI}
 */
export class NeuroscreenControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NeuroscreenControllerApi
     */
    public getNeuroscreenAdult(patientId: number, options?: AxiosRequestConfig) {
        return NeuroscreenControllerApiFp(this.configuration).getNeuroscreenAdult(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NeuroscreenControllerApi
     */
    public getNeuroscreenChild(patientId: number, options?: AxiosRequestConfig) {
        return NeuroscreenControllerApiFp(this.configuration).getNeuroscreenChild(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NeuroscreenAdultDto} neuroscreenAdultDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NeuroscreenControllerApi
     */
    public saveAnonymousNeuroscreenAdult(neuroscreenAdultDto: NeuroscreenAdultDto, options?: AxiosRequestConfig) {
        return NeuroscreenControllerApiFp(this.configuration).saveAnonymousNeuroscreenAdult(neuroscreenAdultDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NeuroscreenChildDto} neuroscreenChildDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NeuroscreenControllerApi
     */
    public saveAnonymousNeuroscreenChild(neuroscreenChildDto: NeuroscreenChildDto, options?: AxiosRequestConfig) {
        return NeuroscreenControllerApiFp(this.configuration).saveAnonymousNeuroscreenChild(neuroscreenChildDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {NeuroscreenAdultDto} neuroscreenAdultDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NeuroscreenControllerApi
     */
    public saveNeuroscreenAdult(patientId: number, neuroscreenAdultDto: NeuroscreenAdultDto, options?: AxiosRequestConfig) {
        return NeuroscreenControllerApiFp(this.configuration).saveNeuroscreenAdult(patientId, neuroscreenAdultDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {NeuroscreenChildDto} neuroscreenChildDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NeuroscreenControllerApi
     */
    public saveNeuroscreenChild(patientId: number, neuroscreenChildDto: NeuroscreenChildDto, options?: AxiosRequestConfig) {
        return NeuroscreenControllerApiFp(this.configuration).saveNeuroscreenChild(patientId, neuroscreenChildDto, options).then((request) => request(this.axios, this.basePath));
    }
}
