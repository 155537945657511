/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DoctorServicePrice
 */
export interface DoctorServicePrice {
    /**
     * 
     * @type {number}
     * @memberof DoctorServicePrice
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DoctorServicePrice
     */
    'patientType': DoctorServicePricePatientTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DoctorServicePrice
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof DoctorServicePrice
     */
    'type': DoctorServicePriceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DoctorServicePrice
     */
    'visitOrder': DoctorServicePriceVisitOrderEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DoctorServicePricePatientTypeEnum {
    Infant = 'INFANT',
    Child = 'CHILD',
    Youth = 'YOUTH',
    Adult = 'ADULT',
    Senior = 'SENIOR'
}
/**
    * @export
    * @enum {string}
    */
export enum DoctorServicePriceTypeEnum {
    Remote = 'REMOTE',
    Stationary = 'STATIONARY',
    Absent = 'ABSENT'
}
/**
    * @export
    * @enum {string}
    */
export enum DoctorServicePriceVisitOrderEnum {
    Each = 'EACH',
    First = 'FIRST',
    Next = 'NEXT'
}


