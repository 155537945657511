import Countries from "../countries/countries";

export default {
  "SeizureTypeEnum": {
    "IA": "Фокальний простий напад",
    "IB": "Фокальний комплексний напад",
    "IC": "Фокальний напад розгортається в генералізований",
    "IIA": "Абсанс",
    "IIB": "Міоклонічний напад",
    "IIC": "Тонічний напад",
    "IID": "Клонічний напад",
    "IIE": "Тоніко-клонічний напад",
    "IIF": "Атонічний напад",
    "GEN": "Сформований напад",
    "UNK": "Невідомий вилучення"
  },
  "SeizureTypeBriefEnum": {
    "IA": "Фокальний простий напад",
    "IB": "Фокальний комплексний напад",
    "IC": "Фокальний напад розгортається в генералізований",
    "IIA": "Відсутність",
    "IIB": "Міоклонічний",
    "IIC": "Тонік",
    "IID": "Клонічна",
    "IIE": "Тоніко-клонічний",
    "IIF": "Атонічний",
    "GEN": "Створено",
    "UNK": "Невідомо"
  },
  "MedicalEventTypeEnum": {
    "SEIZURE": "Напад",
    "MENSTRUATION": "Менструація",
    "INFECTION": "Інфекція",
    "SLEEP_DEPRIVATION": "Позбавлення сну",
    "FEVER": "Лихоманка",
    "OTHER": "Інший",
    "PREGNANCY_OR_PRE_PREGNANCY_PERIOD_START": "Початок вагітності або період до вагітності",
    "PREGNANCY_OR_PRE_PREGNANCY_PERIOD_END": "Закінчення вагітності або період до вагітності"
  },
  "MessageTypeEnum": {
    "EMAIL": "електронною поштою",
    "SMS": "СМС",
    "SYS": "система",
    "CHAT": "чат"
  },
  "MessageStatusEnum": {
    "NEW": "новий",
    "SENT": "надісланий",
    "READ": "читати"
  },
  "MessageMarkerEnum": {
    "MARKED": "позначений",
    "NOT_MARKED": "не позначено"
  },
  "AffinityTypeEnumAdults": {
    "LEGAL_GUARDIAN": "законний опікун",
    "CURATOR": "куратор",
    "SPOUSE": "подружжя"
  },
  "AffinityTypeEnumChildren": {
    "PARENT": "батько",
    "ADOPTER": "обожнювач",
    "GUARDIAN": "призначений опікуном"
  },
  "VisitTypeEnumUndefined": "Інший",
  "DocumentTypeEnum": {
    "REFERRAL_GENERAL_PURPOSE": "Загальне направлення",
    "REFERRAL_CLINIC": "Звернення до спеціалізованої клініки",
    "REFERRAL_TO_HOSPITAL": "Направлення до лікарні",
    "REFERRAL_TO_PSYCHIATRIC_HOSPITAL": "Направлення до психіатричної лікарні",
    "REFERRAL_TO_SPECIALIST": "Направлення до фахівця",
    "REFERRAL_TO_LAB": "Направлення до лабораторії",
    "REFERRAL_TO_DIAGNOSTIC_LABORATORY": "Направлення в діагностичну лабораторію",
    "CERTIFICATE": "Сертифікат",
    "CERTIFICATE_FOR_ZUS": "Сертифікат на ZUS",
    "CERTIFICATE_OF_DISABILITY": "Свідоцтво про інвалідність",
    "HEALTH_CERTIFICATE": "Сертифікат здоров'я",
    "INFORMATION_FOR_POZ_DOCTOR": "Інформація для лікаря POZ",
    "APPLICATION_FOR_REFUND": "Заява про повернення коштів",
    "APPLICATION_FOR_DRUG_REFUND": "Заява про відшкодування ліків",
    "DEMAND_FOR_DRUG_FROM_ABROAD": "Попит на ліки з-за кордону",
    "DEMAND_FOR_FOODSTUFF_FROM_ABROAD": "Попит на продукти харчування з-за кордону",
    "OTHER": "Інші документи"
  },
  "MedicalResultDocumentTypeEnum": {
    "HOSPITAL_INFORMATION_CARD": "Інформаційна картка лікарняного лікування",
    "DISEASE_HISTORY": "Історія хвороби",
    "VIDEO_EVENT": "Відео - подія",
    "LABORATORY_TEST_RESULT": "Результат лабораторного дослідження",
    "VISUAL_RESULT_MRI_FILE": "Файл результатів тесту - магнітний резонанс",
    "VISUAL_RESULT_MRI_DESC": "Опис результату випробування - магнітний резонанс",
    "VISUAL_RESULT_CT_FILE": "Файл результатів тесту - комп’ютерна томографія",
    "VISUAL_RESULT_CT_DESC": "Опис результату тесту - комп’ютерна томографія",
    "VISUAL_RESULT_EEG_FILE": "Файл результатів тесту - ЕЕГ",
    "VISUAL_RESULT_EEG_DESC": "Опис результату тесту - ЕЕГ",
    "VISUAL_RESULT_ADDITIONAL_FILE": "Додатковий файл результатів тесту",
    "VISUAL_RESULT_ADDITIONAL_DESC": "Опис додаткового результату тесту",
    "PATIENT_CERTIFICATE": "Сертифікат Нейросфера",
    "STATE_CERTIFICATE": "Атестат державного зразка",
    "EEG_DESCRIPTION": "Опис ЕЕГ",
    "OTHER": "Інший"
  },
  "DoctorTitle": {
    "DR_MED": "д-р н. мед.",
    "DR_HAB": "доктор хаб.",
    "DR_PROF": "проф. д-р хаб.",
    "LEK_MED": "lek. мед.",
    "TECHN": "техн.",
    "MGR": "mgr",
    "DR": "лікар",
    "STUDENT": "студентка УМ"
  },
  "PricelistItemGroupEnum": {
    "NORMAL_VISIT": "звичайний візит",
    "SOS_VISIT": "sos візит",
    "DIAGNOSTIC": "опис тесту",
    "OTHER": "інший"
  },
  "PricelistItemTypeEnum": {
    "FIRST_TIME": "pierwszorazowa",
    "SUBSEQUENT": "колейна",
    "OTHER": "inne"
  },
  "SexEnum": {
    "FEMALE": "Самка",
    "MALE": "Самець"
  },
  "SeizureFrequencyPeriodEnum": {
    "YEAR": "рік",
    "MONTH": "місяць",
    "DAY": "день"
  },
  "BloodType": {
    "TYPE_A": "A",
    "TYPE_B": "B",
    "TYPE_AB": "AB",
    "TYPE_0": "0"
  },
  "RhType": {
    "RH_PLUS": "RH +",
    "RH_MINUS": "RH-"
  },
  "PsychomotorDevelopmentOverallEnum": {
    "NORMAL": "Звичайний",
    "RETARDED": "Відсталий",
    "VERY_RETARDED": "Дуже відсталий",
    "NOT_APPLICABLE": "Не застосовується"
  },
  "PsychomotorRetardationEnum": {
    "NO": "Ні",
    "LIGHT": "Світло",
    "MODERATE": "Помірний",
    "SIGNIFICANT": "Значний"
  },
  "PsychomotorSpeechEnum": {
    "NORMAL": "Звичайний",
    "DISTURBED": "Порушений",
    "NOT_APPLICABLE": "Не застосовується"
  },
  "PsychomotorWalkEnum": {
    "NORMAL": "Звичайний",
    "INCORRECT": "Невірно",
    "NOT_APPLICABLE": "Не застосовується"
  },
  "PsychomotorSexEnum": {
    "NORMAL": "Звичайний",
    "LIBIDO_DISORDERS": "Порушення лібідо",
    "NOT_APPLICABLE": "Не застосовується"
  },
  "NystagmusEnum": {
    "NO": "Ні",
    "VESTIBULAR": "Вестибулярний",
    "CNS": "CNS"
  },
  "VisionEnum": {
    "NORMAL": "Звичайний",
    "DEFECT_PLUS": "Дефект зору плюс (+)",
    "DEFECT_MINUS": "Дефект зору мінус (-)",
    "DEFECT_OTHER": "Інший дефект зору",
    "BLIND": "Сліпий",
    "NOT_APPLICABLE": "Не застосовується"
  },
  "HearingEnum": {
    "NORMAL": "Звичайний",
    "DISTURBED": "Порушений",
    "NOT_APPLICABLE": "Не застосовується"
  },
  "SmokingEnum": {
    "NO": "Ні",
    "YES_IN_PAST": "Так, у минулому",
    "YES_NOW": "Так, наразі",
    "NOT_APPLICABLE": "Не застосовується"
  },
  "AlcoholEnum": {
    "NO": "Ні",
    "SOMETIMES_LITTLE": "Іноді мало",
    "SOMETIMES_A_LOT": "Іноді багато",
    "OFTEN_LITTLE": "Часто мало",
    "OFTEN_A_LOT": "Часто багато",
    "NOT_APPLICABLE": "Не застосовується"
  },
  "NarcoticsEnum": {
    "NO": "Ні",
    "YES_IN_PAST": "Так, у минулому",
    "YES_NOW_RARELY": "Так, наразі - зрідка",
    "YES_NOW_OFTEN": "Так, obecnie - часто",
    "NOT_APPLICABLE": "Не застосовується"
  },
  "SeizuresCharacteristicsFrequencyEnum": {
    "ALWAYS": "Так (1 напад) / Так, завжди (100% нападів)",
    "VERY_OFTEN": "Так, дуже часто (75 - 99% судом)",
    "OFTEN": "Так, часто (51-74% судом)",
    "SOMETIMES": "Так, іноді (~ 50% судом)",
    "RARELY": "Так, але рідко (25-49% судом)",
    "VERY_RARELY": "Так, але вкрай рідко (1 - 24% судом)",
    "NEVER": "Ні (1 напад) / Ні, ніколи (0% нападів)",
    "NOT_KNOWN": "Невідомо"
  },
  "DrugHistoryTimeEnum": {
    "PAST": "Так, у минулому",
    "CURRENTLY": "Так, наразі"
  },
  "DrugHistoryEffectEnum": {
    "REMISSION": "Ремісія",
    "BIG_IMPROVEMENT": "Поліпшення> 50%",
    "LITTLE_IMPROVEMENT": "Покращення до 50%",
    "NO_CHANGE": "Без змін",
    "LITTLE_DETERIORATION": "Незначне погіршення стану",
    "BIG_DETERIORATION": "Значне погіршення стану",
    "EXTREME_DETERIORATION": "Екстремальні погіршення / побічні ефекти (наприклад, висип)"
  },
  "SeizuresCharacteristicsDurationEnum": {
    "VERY_LONG": "Дуже довго, більше 5 хвилин",
    "LONG": "Тривалий, від 1 до 5 хвилин",
    "BIT_LONG": "Від 30 до 60 секунд",
    "VARIABLE": "Зовсім інші, мінливі",
    "SHORT": "Коротко від декількох до 30 секунд",
    "VERY_SHORT": "Дуже короткий, менше 5 сек",
    "ULTRA_SHORT": "Ультракороткий, частка секунди в секунду",
    "NOT_KNOWN": "я не знаю"
  },
  "UserAccountSettingCodeEnum": {
    "VISIT_CHANGED_BY_PATIENT": "Сповіщає, коли пацієнт змінює час відвідування",
    "VISIT_APPOINTED_REMINDER": "Нагадування про візити, призначені на наступний день",
    "VISIT_PLANNED_REMINDER": "Нагадування про відвідування, заплановані на наступний день",
    "DUTY_PLANNED_REMINDER": "Нагадування про обов'язки, заплановані на наступний день",
    "VISIT_CANCELED_BY_DOCTOR": "Повідомляє, коли лікар скасовує візит",
    "VISIT_CANCELLED_BY_PATIENT": "Повідомляє, коли пацієнт скасовує візит",
    "ADMIT_PATIENTS": "Видно для пацієнтів у базі даних лікаря",
    "MESSAGE_RECEIVED_BY_DOCTOR": "Сповіщення, коли пацієнт надсилає повідомлення",
    "PRESCRIPTION_REQUEST_RECEIVED_BY_DOCTOR": "Сповіщення, коли пацієнт надсилає запит на рецепт"
  },
  "VisitCancellationReason": {
    "INCONVENIENT_DATE": "Незручна дата",
    "DOCTOR_CHANGE": "Зміна лікаря"
  },
  "CountryEnum": Countries.uk,
  "MedicalServiceSystemItemEnum": {
    "SOS_TELECONFERENCE": "Телеконференція SOS",
    "SOS_CHAT": "SOS -чат"
  },
  "MedicalServiceEnum": {
    "VISIT_0": "Відвідайте 0",
    "MIGRATION_VISIT": "Міграційний візит",
    "VISIT_1": "Відвідайте 1",
    "STANDARD_VISIT": "Стандартний візит",
    "MESSAGE": "повідомлення",
    "PRESCRIPTION": "Рецепт",
    "NEUROSURGEON_CONSULTATION": "Консультація - нейрохірург",
    "NEUROPSYCHOLOGIST_CONSULTATION": "Консультація - нейропсихолог",
    "NEUROLOGOPEDIST_CONSULTATION": "Консультація - нейрологопед",
    "PSYCHIATRIST_CONSULTATION": "Консультація - психіатр",
    "EPILEPTOLOGIST_CONSULTATION": "Консультація - епілептолог",
    "EEG_CONSULTATION": "Бадані ЕЕГ",
    "NEUROLOGIST_CONSULTATION": "Консультація - невропатолог",
    "DIETICIAN_CONSULTATION": "Консультація - дієтолог",
    "VNS_CONSULTATION": "Відвідування VNS",
    "GENETICIST_CONSULTATION": "Консультація - генетик",
    "ABSENT_CONSULTATION": "Відсутня консультація",
    "METABOLIC_CONSULTATION": "Метаболічна консультація",
    "VEEG_ON_STANDBY_CONSULTATION": "VEEG-обстеження у стані неспання через 1-6 год",
    "VEEG_DURING_SLEEP_CONSULTATION": "VEEG під час обстеження сну 1 -6 год",
    "VEEG_NIGHT_CONSULTATION": "Нічний огляд VEEG 12 год",
    "VEEG_DAY_CONSULTATION": "Цілодобове обстеження VEEG 24 год",
    "SLEEP_DISTURBANCE_CONSULTATION": "Консультація з питань порушення сну",
    "CASE_CONFERENCE": "Медична конференція",
    "VEEG_ON_STANDBY_CONSULTATION_1H": "VEEG дослідження під час неспання через 1 год",
    "VEEG_ON_STANDBY_CONSULTATION_2H": "VEEG дослідження під час неспання через 2 год",
    "VEEG_ON_STANDBY_CONSULTATION_6H": "VEEG дослідження під час неспання через 6 год",
    "VEEG_DURING_SLEEP_CONSULTATION_1H": "VEEG дослідження під час сну 1 год",
    "VEEG_DURING_SLEEP_CONSULTATION_2H": "VEEG дослідження уві сні 2 год",
    "VEEG_DURING_SLEEP_CONSULTATION_6H": "VEEG дослідження під час сну 6 год"
  },
  "VisitTypeEnum": {
    "STATIONARY": "Стаціонарні",
    "REMOTE": "Дистанційний",
    "ABSENT": "Відсутні"
  },
  "VisitTypeDescriptionEnum": {
    "STATIONARY": "Відвідування проходить в амбулаторії у {place}. Під час візиту лікар безпосередньо зустрічається з пацієнтом. На місці він переглядає поточну документацію пацієнта. Якщо рецепт видається - лікар видає рецепт на місці і надсилає його по смс або роздруковує.",
    "REMOTE": "Інтернет -візит, коли лікар і пацієнт бачать один одного на екрані. З’єднання здійснюється через Neuroterminal і не вимагає встановлення додаткового програмного забезпечення. Документація надсилається пацієнтом в електронному вигляді через Neuroterminal. Якщо потрібен рецепт, лікар видасть електронний рецепт.",
    "ABSENT": "Заочна консультація – це вид медичного візиту, який здійснюється обраним спеціалістом без присутності пацієнта. Заочна консультація спрямована на аналіз медичної документації або додаткове обстеження. Фахівець з нейросфери проаналізує документацію, зробить відповідну записку та рекомендації для пацієнта."
  },
  "MedicalResultTypeEnum": {
    "VIDEO_EVENT": "Відео",
    "LABORATORY_TEST_RESULT": "Лабораторне дослідження",
    "VISUAL_RESULT_MRI_FILE": "Магнітний резонанс",
    "VISUAL_RESULT_MRI_DESC": "Магнітний резонанс",
    "VISUAL_RESULT_CT_FILE": "Комп'ютерна томографія",
    "VISUAL_RESULT_CT_DESC": "Комп'ютерна томографія",
    "VISUAL_RESULT_EEG_FILE": "ЕЕГ",
    "VISUAL_RESULT_EEG_DESC": "ЕЕГ",
    "VISUAL_RESULT_ADDITIONAL_FILE": "Додаткові",
    "VISUAL_RESULT_ADDITIONAL_DESC": "Додаткові"
  },
  "DoctorTypeEnum": {
    "RESIDENT": "Клінічний ординатор",
    "SPECIALIST": "Клінічний спеціаліст",
    "EXPERT": "Медичний партнер",
    "CONSULTANT": "Клінічний консультант",
    "ASSISTANT": "помічник",
    "INTERNATIONAL_CONSULTANT": "Міжнародний клінічний консультант",
    "PARTNER_CONSULTANT": "Партнер-консультант"
  },
  "PatientTypeEnum": {
    "INFANT": "діти до 2 років",
    "CHILD": "діти від 2 до 12 років",
    "YOUTH": "молодь від 12 до 18 років",
    "ADULT": "дорослі від 18 до 65 років",
    "SENIOR": "пенсіонери старше 65 років"
  },
  "HasEpilepsyEnum": {
    "NO": "Ні",
    "YES": "Так",
    "NOT_KNOWN": "Невідомо"
  },
  "HealthSurveyPartEnum": {
    "PSYCHOMOTOR_DEVELOPMENT": "психомоторний розвиток",
    "SEIZURE_TYPES": "типи судом",
    "SEIZURES_HISTORY": "історія судом",
    "SEIZURES_CHARACTERISTICS": "характеристика судом",
    "COMORBIDITIES": "супутні захворювання",
    "DRUGS_HISTORY": "лікування"
  },
  "TimeRangeEnum": {
    "1M": "1 місяць",
    "3M": "3 місяці",
    "6M": "6 місяців",
    "1Y": "1 рік",
    "2Y": "2 роки",
    "3Y": "3 роки",
    "SINCE_BIRTH": "З народження",
    "SINCE_FIRST_SEIZURE": "З моменту першого нападу",
    "1H": "1 година",
    "2H": "2 години",
    "3H": "3 години",
    "4H": "4 години",
    "5H": "5 годин",
    "6H": "6 годин"
  },
  "MedicalRequestStatusEnum": {
    "NEW": "Новий",
    "ACCEPTED": "Прийнято",
    "DONE": "Готово",
    "REJECTED": "Відхилено",
    "CANCELLED": "Скасовано"
  },
  "PersonalDocumentEnum": {
    "PASSPORT": "Паспорт",
    "DRIVING_LICENSE": "Водійські права"
  },
  "NSPHSourceEnum": {
    "INTERNET_SEARCH": "Пошук в Інтернеті",
    "SOCIAL_MEDIA": "Соціальні засоби комунікації",
    "INTERNET_ARTICLE": "Інтернет-стаття",
    "PRESS_ARTICLE": "Стаття в пресі",
    "TV": "телебачення",
    "FAMILY_FRIENDS": "Від родини чи друзів",
    "DOCTOR": "Від лікаря",
    "OTHER": "Інший"
  },
  "NFZBranchEnum": {
    "NFZ_01": "01 - Нижньосілезьке відділення Національного фонду охорони здоров'я у Вроцлаві",
    "NFZ_02": "02 - Куявсько-Поморське відділення Національної каси охорони здоров'я в Бидгощі",
    "NFZ_03": "03 - Люблінське відділення Національної каси здоров'я в Любліні",
    "NFZ_04": "04 - Любуське відділення Національної каси здоров'я в Зеленій Гурі",
    "NFZ_05": "05 - Лодзьське відділення Національної каси здоров'я в Лодзі",
    "NFZ_06": "06 - Малопольське відділення Національного фонду охорони здоров'я в Кракові",
    "NFZ_07": "07 - Мазовецьке відділення Національного фонду охорони здоров'я у Варшаві",
    "NFZ_08": "08 - Опольське відділення Національної каси здоров'я в м. Ополе",
    "NFZ_09": "09 – Підкарпатське відділення Національної каси здоров’я в Жешуві",
    "NFZ_10": "10 - Підляське відділення Національної каси здоров'я в Білостоці",
    "NFZ_11": "11 - Поморське відділення Національної каси охорони здоров'я в Гданську",
    "NFZ_12": "12 - Сілезьке відділення Національного фонду охорони здоров'я в Катовіце",
    "NFZ_13": "13 - Свєнтокшиський відділення Національної каси охорони здоров'я в Кельце",
    "NFZ_14": "14 – Вармінсько-Мазурське відділення Національної каси здоров’я в Ольштині",
    "NFZ_15": "15 - Великопольське відділення Національного фонду охорони здоров'я в Познані",
    "NFZ_16": "16 - Західно-поморське відділення Національної каси охорони здоров'я в Щецині"
  },
  "VisitPackageEnum": {
    "START_PACKAGE": "Опитування 0 + Відвідування 1",
    "ADHD_PACKAGE": "Пакет ADHD",
    "REGULAR_VISIT_EEG_PACKAGE": "Регулярне відвідування + ЕЕГ",
    "MMPI2_PACKAGE": "Тест особистості MMPI-2",
    "CBT_PACKAGE": "КПТ терапія"
  },
  "MedicalServiceGroupEnum": {
    "VISIT_0": "Опитування 0",
    "MIGRATION_VISIT": "Міграційний візит",
    "VISIT_1": "Відвідати 1",
    "STANDARD_VISIT": "Стандартне відвідування",
    "MESSAGE": "повідомлення",
    "PRESCRIPTION": "Рецепт",
    "NEUROSURGEON_CONSULTATION": "Консультація - нейрохірург",
    "NEUROPSYCHOLOGIST_CONSULTATION": "Консультація - нейропсихолог",
    "NEUROLOGOPEDIST_CONSULTATION": "Консультація - невролог",
    "PSYCHIATRIST_CONSULTATION": "Консультація - психіатр",
    "EPILEPTOLOGIST_CONSULTATION": "Консультація - епілептолог",
    "NEUROLOGIST_CONSULTATION": "Консультація - невропатолог",
    "DIETICIAN_CONSULTATION": "Консультація - дієтолог",
    "VNS_CONSULTATION": "Відвідування VNS",
    "GENETICIST_CONSULTATION": "Консультація - генетик",
    "ABSENT_CONSULTATION": "Відсутня консультація",
    "METABOLIC_CONSULTATION": "Метаболічна консультація",
    "EEG_CONSULTATION": "ЕЕГ дослідження",
    "SLEEP_DISTURBANCE_CONSULTATION": "Консультація з питань порушення сну",
    "PARTNER_CONSULTATION": "Партнерська консультація"
  },
  "ShortTimeRangeEnum": {
    "h": "ч",
    "m": "хв"
  },
  "WellBeingEnum": {
    "WORST": "максимум гірше / погано",
    "MUCH_WORSE": "набагато гірше",
    "BIT_WORSE": "трохи гірше",
    "SAME": "те саме",
    "BIT_BETTER": "трохи краще",
    "MUCH_BETTER": "набагато краще",
    "IDEAL": "ідеальне, повне здоров'я"
  },
  "CurrentTreatmentEnum": {
    "WORST": "зовсім погано, це не працює, і я не хочу це продовжувати",
    "VERY_BAD": "не добре, я вважаю за краще змінити це",
    "BAD": "Мені погано, але я можу продовжувати",
    "NO_CHANGE": "Я не відчуваю жодних змін",
    "BIT_BETTER": "це нормально, я почуваюся трохи краще після цього лікування",
    "MUCH_BETTER": "є поліпшення, лікування явно допомагає",
    "IDEAL": "лікування ідеальне, повний успіх"
  },
  "SideEffectsEnum": {
    "WORST": "вони настільки серйозні, що я точно не хочу продовжувати це лікування",
    "BAD": "вони значні і мене турбують, але якщо потрібно, я можу продовжити",
    "BEARABLE": "вони помітні, але не настільки погано, щоб змінити лікування",
    "MINIMAL": "це нормально, побічні ефекти мінімальні, на мене не впливає"
  },
  "TreatmentEfficiencyEnum": {
    "IDEAL": "ідеально",
    "GOOD": "добре",
    "MEDIOCRE": "Посередній",
    "BAD": "погано",
    "WORST": "Найгірше, необхідно змінити лікування"
  },
  "TreatmentSafetyEnum": {
    "TOTAL": "Всього",
    "ACCEPTABLE": "Прийнятно",
    "WEAK": "Слабкий",
    "BAD": "погано",
    "WORST": "Виключає препарат, необхідна зміна"
  },
  "AdditionalIncidentsEnum": {
    "NEW_DISEASES": "Нові хвороби",
    "INJURIES": "Травми",
    "HOSPITALIZATIONS": "Госпіталізація",
    "OTHER": "Інший",
    "NONE": "Жодного"
  },
  "SeizureCountEnum": {
    "MORE": "більше",
    "LESS": "менше",
    "SAME": "Те саме",
    "NONE": "Жодного"
  },
  "SeizureEvaluationEnum": {
    "WORSE": "гірше",
    "BETTER": "Запальничка",
    "NONE": "Жодного"
  },
  "AllSeizureRegisteredEnum": {
    "TRUE": "Я їх усіх зареєстрував",
    "FALSE": "Я їх не реєстрував - зараз зроблю"
  },
  "ExternalReferralTypeEnum": {
    "CLINIC": "до спеціалізованої клініки",
    "LABORATORY": "в діагностичну лабораторію",
    "HOSPITAL": "на стаціонарне лікування"
  },
  "CertificateTypeEnum": {
    "DRUGS_INTAKE": "Довідка з ЛПУ про прийняті ліки",
    "POSTPONE_VACCINATION": "Сертифікат на відстрочку щеплення",
    "NO_CONTRAINDICATION_FOR_VACCINE": "Довідка про відсутність протипоказань до щеплень",
    "NO_CONTRAINDICATION_FOR_PROCEDURE": "Довідка про відсутність протипоказань до процедури",
    "NO_CONTRAINDICATION_FOR_ACTIVITIES": "Довідка про відсутність протипоказань до участі в заняттях",
    "NO_CONTRAINDICATION_FOR_WORK": "Довідка про відсутність протипоказань до виконання фізичної роботи",
    "OTHER": "Інший"
  },
  "CertificateTypeDescriptionEnum": {
    "DRUGS_INTAKE": "Цим підтверджую, що пацієнт знаходиться під наглядом Центру лікування епілепсії «Нейросфера». Прошу виписати ліки за місцем проживання. Пацієнт приймає наступні препарати",
    "POSTPONE_VACCINATION": "Цим підтверджую, що пацієнт знаходиться під наглядом Центру лікування епілепсії «Нейросфера». Прошу перенести щеплення до",
    "NO_CONTRAINDICATION_FOR_VACCINE": "Цим підтверджую, що пацієнт знаходиться під наглядом Центру лікування епілепсії «Нейросфера». Протипоказань для проведення обов'язкових щеплень немає",
    "NO_CONTRAINDICATION_FOR_PROCEDURE": "Цим підтверджую, що пацієнт знаходиться під наглядом Центру лікування епілепсії «Нейросфера». Протипоказань для проведення загального наркозу під час планової процедури немає",
    "NO_CONTRAINDICATION_FOR_ACTIVITIES": "Цим підтверджую, що пацієнт знаходиться під наглядом Центру лікування епілепсії «Нейросфера». Протипоказань для участі в заходах немає",
    "NO_CONTRAINDICATION_FOR_WORK": "Цим підтверджую, що пацієнт знаходиться під наглядом Центру лікування епілепсії «Нейросфера». Протипоказань для фізичної роботи немає.",
    "OTHER": "Цим я підтверджую, що пацієнт перебуває під наглядом Центру лікування епілепсії «Нейросфера»."
  },
  "MessageVisitSummaryStatusEnum": {
    "NEW": "Нереалізований",
    "PENDING": "Очікування відповіді",
    "RESPONDED": "Відповів",
    "DONE": "Готово",
    "UNIDIRECTIONAL": "Односпрямований"
  },
  "SickLeaveTypeEnum": {
    "DISEASE": "Захворювання",
    "CARE": "Догляд за хворими"
  },
  "SickLeaveIndicationEnum": {
    "SHOULD_LIE": "Треба брехати",
    "CAN_WALK": "Може ходити"
  },
  "SickLeaveInsuredInEnum": {
    "ZUS": "ZUS",
    "KRUS": "КРУС",
    "OTHER_PL": "інше в Польщі",
    "OTHER_COUNTRY": "в іншій країні"
  },
  "SickLeaveStatusEnum": {
    "ISSUED": "Виданий",
    "CANCELED": "Скасовано"
  },
  "SurveyCompletedEnum": {
    "YES": "Так",
    "NO": "Ні",
    "PARTIALLY": "Наполовину завершено"
  },
  "EegCompletedEnum": {
    "YES": "Так",
    "NO": "Ні",
    "BEFORE_NEXT": "Зроблю перед візитом 1"
  },
  "DocplannerIssueTypeEnum": {
    "DP_VISIT_NOT_BOOKED": "Візит не замовлений в ZN",
    "DP_VISIT_NOT_CANCELED": "В «ЗН» візит не відмінили",
    "DP_VISIT_NOT_MOVED": "Візит не переїхав у ЗН",
    "NOT_ELIGIBLE_FOR_CONSULTATION": "Новий пацієнт - консультація"
  },
  "DocplannerVisitStatusEnum": {
    "NEW": "новий",
    "CANCELED_BY_SCHEDULER": "Не оплачується",
    "CANCELED_BY_PERSON": "Скасовано",
    "SOLVED": "Вирішено",
    "PAID_NOT_DONE": "Платний",
    "DONE": "Готово"
  },
  "ReferralStatusEnum": {
    "NEW": "новий",
    "CANCELLED": "Скасовано",
    "DONE": "Готово"
  },
  "VisitCaseSolvedEnum": {
    "APPOINTMENT": "Призначення",
    "RESIGNATION": "Відставка",
    "OTHER": "Інший"
  },
  "MedicalServiceGroupConsultantEnum": {
    "NEUROSURGEON_CONSULTATION": "нейрохірург",
    "NEUROPSYCHOLOGIST_CONSULTATION": "нейропсихолог",
    "NEUROLOGOPEDIST_CONSULTATION": "невролог",
    "PSYCHIATRIST_CONSULTATION": "психіатр",
    "EPILEPTOLOGIST_CONSULTATION": "епілептолог",
    "NEUROLOGIST_CONSULTATION": "невролог",
    "DIETICIAN_CONSULTATION": "дієтолог",
    "VNS_CONSULTATION": "VNS консультант",
    "GENETICIST_CONSULTATION": "генетик",
    "METABOLIC_CONSULTATION": "метаболічний консультант",
    "EEG_CONSULTATION": "ЕЕГ/ВЕЕГ консультант",
    "SLEEP_DISTURBANCE_CONSULTATION": "консультант з проблем сну"
  },
  "VisitPaymentMarkingEnum": {
    "WAITING_FOR_PAYMENT": "Позначено для оплати",
    "PAYMENT_RECEIVED": "Позначено як оплачений"
  },
  "PaymentProviderEnum": {
    "PAYU": "PayU",
    "PAYNOW": "Платити зараз"
  },
  "VisitPackageTemplateType": {
    "EPILEPSY": "Епілепсія",
    "CONSULTATION": "Консультація"
  },
  "SexEnumAbbr": {
    "FEMALE": "Ф",
    "MALE": "М"
  },
  "TherapyTypeEnum": {
    "POLI": "Політерапія",
    "MONO": "Монотерапія",
    "NO_ACTIVE": "Не активна терапія"
  },
  "ComorbidityTypeEnum": {
    "HEADACHES": "Головні болі",
    "PSYCHIATRIC": "Психіатричні захворювання",
    "NEUROLOGICAL": "Неврологічні захворювання",
    "INFECTIOUS": "Запальні та інфекційні захворювання",
    "CANCER": "Онкологічні захворювання",
    "IMMUNE": "Захворювання крові та імунітету",
    "ENDOCRINE": "Ендокринні та обмінні захворювання",
    "EYE": "Хвороби очей",
    "LARYNGOLOGICAL": "Ларингологічні захворювання",
    "HEART": "Захворювання серця і системи кровообігу",
    "RESPIRATORY": "Хвороби органів дихання",
    "DIGESTIVE": "Захворювання органів травлення",
    "SKIN": "Захворювання шкіри",
    "BONE": "Хвороби кісток",
    "UROGENITAL": "Сечостатеві захворювання",
    "DEVELOPMENTAL_DEFECTS": "Вади розвитку, вроджені вади та хромосомні аберації",
    "INJURIES_POISONING": "Травми та отруєння",
    "SOMATIC": "Захворювання, що впливають на лікування епілепсії"
  },
  "PatientAccountRemovalReason": {
    "NO_MEDICAL_SERVICE": "Я не знайшов послуги, яка мене цікавить",
    "NO_DATES": "Я не знайшов відповідної дати візиту",
    "PRICE": "Мене не влаштовують ціни на послуги",
    "OTHER": "інше"
  },
  "PrescriptionRejectedReasonEnum": {
    "VISIT_ONLY": "Рецепт на обраний препарат можна виписати лише під час відвідування",
    "INQUIRY_REQUIRED": "Рецепт на обраний препарат може бути виписаний після ознайомлення з поточним станом здоров'я пацієнта",
    "ALREADY_PRESCRIBED": "Раніше виданий рецепт повинен відповідати потребам пацієнта",
    "OTHER": "інше"
  },
  "BirthTypeEnum": {
    "PHYSIOLOGICAL": "Фізіологічні (сили природи)",
    "PLANNED_CESAREAN": "Кесарів розтин (плановий)",
    "UNPLANNED_CESAREAN": "Кесарів розтин (позаплановий)",
    "VACUUM": "Сили / вакуум"
  },
  "PaymentRefundRestrictionsEnum": {
    "REFUND_100": "більше 7 днів до послуги, 100% повернення",
    "REFUND_75": "Повернення 75% за 6-5 днів до послуги",
    "REFUND_50": "Повернення 50% за 4-3 дні до послуги",
    "REFUND_25": "Повернення 25% за 2 дні до послуги",
    "REFUND_0": "за день до послуги та в день послуги без повернення коштів"
  },
  "NeuroscreenDiagnosisEnum": {
    "HEADACHES": "Головні болі",
    "MOVEMENT_ISSUES": "Рухові розлади",
    "MEMORY_ISSUES": "Розлади пам'яті",
    "EATING_ISSUES": "Розлади харчової поведінки",
    "CONSCIOUSNESS_ISSUES": "Порушення свідомості",
    "PSYCHOMOTOR_DEVELOPMENT_ISSUES": "Порушення психомоторного розвитку",
    "EPILEPSY": "Епілепсія і судоми",
    "SPINE_ISSUES": "Проблеми з хребтом",
    "MUTIPLE_SCLEROSIS": "Розсіяний склероз",
    "DIZZINESS": "запаморочення",
    "AUTISM": "Спектр аутизму",
    "OTHER_SYMPTOMS": "Інші неврологічні симптоми"
  },
  "NeuroscreenFrequencyEnum": {
    "ALWAYS": "так",
    "SOMETIMES": "іноді",
    "RARELY": "Рідко",
    "NEVER": "Ніколи"
  }
}