/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AuthSessionRequest } from '../model';
// @ts-ignore
import { PatientMarketingConsentsUpdateDto } from '../model';
// @ts-ignore
import { UserSettingDto } from '../model';
/**
 * UserApiControllerApi - axios parameter creator
 * @export
 */
export const UserApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPatientRegistration: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('confirmPatientRegistration', 'token', token)
            const localVarPath = `/api/user/register/patient/confirm/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPatientUserInitialization: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/initialization/patient/done`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthSessionRequest} authSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangeAutologinToken: async (authSessionRequest: AuthSessionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authSessionRequest' is not null or undefined
            assertParamExists('exchangeAutologinToken', 'authSessionRequest', authSessionRequest)
            const localVarPath = `/api/user/autologin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authSessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/business`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAlerts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/alerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendPasswordRequest: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('resendPasswordRequest', 'email', email)
            const localVarPath = `/api/user/password-reset/resend/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendRegistrationEmail: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('resendRegistrationEmail', 'email', email)
            const localVarPath = `/api/user/register/patient/resend/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordConfirm: async (token: string, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('resetPasswordConfirm', 'token', token)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('resetPasswordConfirm', 'body', body)
            const localVarPath = `/api/user/password-reset/confirm/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordRequest: async (body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('resetPasswordRequest', 'body', body)
            const localVarPath = `/api/user/password-reset/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {boolean} [marketing1and2] 
         * @param {boolean} [marketing3] 
         * @param {string} [password] 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePatientsRegistrationForm: async (email?: string, firstName?: string, lastName?: string, marketing1and2?: boolean, marketing3?: boolean, password?: string, timezone?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/register/patient/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (email !== undefined) { 
                localVarFormParams.append('email', email as any);
            }
    
            if (firstName !== undefined) { 
                localVarFormParams.append('firstName', firstName as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.append('lastName', lastName as any);
            }
    
            if (marketing1and2 !== undefined) { 
                localVarFormParams.append('marketing1and2', marketing1and2 as any);
            }
    
            if (marketing3 !== undefined) { 
                localVarFormParams.append('marketing3', marketing3 as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.append('password', password as any);
            }
    
            if (timezone !== undefined) { 
                localVarFormParams.append('timezone', timezone as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<UserSettingDto>} userSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserSettings: async (userSettingDto: Array<UserSettingDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userSettingDto' is not null or undefined
            assertParamExists('saveUserSettings', 'userSettingDto', userSettingDto)
            const localVarPath = `/api/user/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatientMarketingConsentsUpdateDto} patientMarketingConsentsUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarketingConsents: async (patientMarketingConsentsUpdateDto: PatientMarketingConsentsUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientMarketingConsentsUpdateDto' is not null or undefined
            assertParamExists('updateMarketingConsents', 'patientMarketingConsentsUpdateDto', patientMarketingConsentsUpdateDto)
            const localVarPath = `/api/user/patient/marketing-consents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientMarketingConsentsUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserLocale: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/locale`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserTimezone: async (timezone: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timezone' is not null or undefined
            assertParamExists('updateUserTimezone', 'timezone', timezone)
            const localVarPath = `/api/user/timezone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateResetUserPasswordToken: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('validateResetUserPasswordToken', 'token', token)
            const localVarPath = `/api/user/password-reset/validate/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApiControllerApi - functional programming interface
 * @export
 */
export const UserApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmPatientRegistration(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmPatientRegistration(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmPatientUserInitialization(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmPatientUserInitialization(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthSessionRequest} authSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangeAutologinToken(authSessionRequest: AuthSessionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangeAutologinToken(authSessionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAlerts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAlerts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendPasswordRequest(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendPasswordRequest(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendRegistrationEmail(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendRegistrationEmail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordConfirm(token: string, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordConfirm(token, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordRequest(body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordRequest(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {boolean} [marketing1and2] 
         * @param {boolean} [marketing3] 
         * @param {string} [password] 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePatientsRegistrationForm(email?: string, firstName?: string, lastName?: string, marketing1and2?: boolean, marketing3?: boolean, password?: string, timezone?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePatientsRegistrationForm(email, firstName, lastName, marketing1and2, marketing3, password, timezone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<UserSettingDto>} userSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveUserSettings(userSettingDto: Array<UserSettingDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveUserSettings(userSettingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PatientMarketingConsentsUpdateDto} patientMarketingConsentsUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMarketingConsents(patientMarketingConsentsUpdateDto: PatientMarketingConsentsUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMarketingConsents(patientMarketingConsentsUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserLocale(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserLocale(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserTimezone(timezone: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserTimezone(timezone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateResetUserPasswordToken(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateResetUserPasswordToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApiControllerApi - factory interface
 * @export
 */
export const UserApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPatientRegistration(token: string, options?: any): Promise<void> {
            return localVarFp.confirmPatientRegistration(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPatientUserInitialization(options?: any): Promise<string> {
            return localVarFp.confirmPatientUserInitialization(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthSessionRequest} authSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangeAutologinToken(authSessionRequest: AuthSessionRequest, options?: any): Promise<string> {
            return localVarFp.exchangeAutologinToken(authSessionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessUser(options?: any): Promise<object> {
            return localVarFp.getBusinessUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAlerts(options?: any): Promise<string> {
            return localVarFp.getUserAlerts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSettings(options?: any): Promise<string> {
            return localVarFp.getUserSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendPasswordRequest(email: string, options?: any): Promise<boolean> {
            return localVarFp.resendPasswordRequest(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendRegistrationEmail(email: string, options?: any): Promise<boolean> {
            return localVarFp.resendRegistrationEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordConfirm(token: string, body: string, options?: any): Promise<string> {
            return localVarFp.resetPasswordConfirm(token, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordRequest(body: string, options?: any): Promise<string> {
            return localVarFp.resetPasswordRequest(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {boolean} [marketing1and2] 
         * @param {boolean} [marketing3] 
         * @param {string} [password] 
         * @param {string} [timezone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePatientsRegistrationForm(email?: string, firstName?: string, lastName?: string, marketing1and2?: boolean, marketing3?: boolean, password?: string, timezone?: string, options?: any): Promise<string> {
            return localVarFp.savePatientsRegistrationForm(email, firstName, lastName, marketing1and2, marketing3, password, timezone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<UserSettingDto>} userSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserSettings(userSettingDto: Array<UserSettingDto>, options?: any): Promise<string> {
            return localVarFp.saveUserSettings(userSettingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PatientMarketingConsentsUpdateDto} patientMarketingConsentsUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMarketingConsents(patientMarketingConsentsUpdateDto: PatientMarketingConsentsUpdateDto, options?: any): Promise<string> {
            return localVarFp.updateMarketingConsents(patientMarketingConsentsUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserLocale(options?: any): Promise<string> {
            return localVarFp.updateUserLocale(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserTimezone(timezone: string, options?: any): Promise<string> {
            return localVarFp.updateUserTimezone(timezone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateResetUserPasswordToken(token: string, options?: any): Promise<string> {
            return localVarFp.validateResetUserPasswordToken(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApiControllerApi - object-oriented interface
 * @export
 * @class UserApiControllerApi
 * @extends {BaseAPI}
 */
export class UserApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiControllerApi
     */
    public confirmPatientRegistration(token: string, options?: AxiosRequestConfig) {
        return UserApiControllerApiFp(this.configuration).confirmPatientRegistration(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiControllerApi
     */
    public confirmPatientUserInitialization(options?: AxiosRequestConfig) {
        return UserApiControllerApiFp(this.configuration).confirmPatientUserInitialization(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthSessionRequest} authSessionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiControllerApi
     */
    public exchangeAutologinToken(authSessionRequest: AuthSessionRequest, options?: AxiosRequestConfig) {
        return UserApiControllerApiFp(this.configuration).exchangeAutologinToken(authSessionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiControllerApi
     */
    public getBusinessUser(options?: AxiosRequestConfig) {
        return UserApiControllerApiFp(this.configuration).getBusinessUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiControllerApi
     */
    public getUserAlerts(options?: AxiosRequestConfig) {
        return UserApiControllerApiFp(this.configuration).getUserAlerts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiControllerApi
     */
    public getUserSettings(options?: AxiosRequestConfig) {
        return UserApiControllerApiFp(this.configuration).getUserSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiControllerApi
     */
    public resendPasswordRequest(email: string, options?: AxiosRequestConfig) {
        return UserApiControllerApiFp(this.configuration).resendPasswordRequest(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiControllerApi
     */
    public resendRegistrationEmail(email: string, options?: AxiosRequestConfig) {
        return UserApiControllerApiFp(this.configuration).resendRegistrationEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiControllerApi
     */
    public resetPasswordConfirm(token: string, body: string, options?: AxiosRequestConfig) {
        return UserApiControllerApiFp(this.configuration).resetPasswordConfirm(token, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiControllerApi
     */
    public resetPasswordRequest(body: string, options?: AxiosRequestConfig) {
        return UserApiControllerApiFp(this.configuration).resetPasswordRequest(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {boolean} [marketing1and2] 
     * @param {boolean} [marketing3] 
     * @param {string} [password] 
     * @param {string} [timezone] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiControllerApi
     */
    public savePatientsRegistrationForm(email?: string, firstName?: string, lastName?: string, marketing1and2?: boolean, marketing3?: boolean, password?: string, timezone?: string, options?: AxiosRequestConfig) {
        return UserApiControllerApiFp(this.configuration).savePatientsRegistrationForm(email, firstName, lastName, marketing1and2, marketing3, password, timezone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<UserSettingDto>} userSettingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiControllerApi
     */
    public saveUserSettings(userSettingDto: Array<UserSettingDto>, options?: AxiosRequestConfig) {
        return UserApiControllerApiFp(this.configuration).saveUserSettings(userSettingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PatientMarketingConsentsUpdateDto} patientMarketingConsentsUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiControllerApi
     */
    public updateMarketingConsents(patientMarketingConsentsUpdateDto: PatientMarketingConsentsUpdateDto, options?: AxiosRequestConfig) {
        return UserApiControllerApiFp(this.configuration).updateMarketingConsents(patientMarketingConsentsUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiControllerApi
     */
    public updateUserLocale(options?: AxiosRequestConfig) {
        return UserApiControllerApiFp(this.configuration).updateUserLocale(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} timezone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiControllerApi
     */
    public updateUserTimezone(timezone: string, options?: AxiosRequestConfig) {
        return UserApiControllerApiFp(this.configuration).updateUserTimezone(timezone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiControllerApi
     */
    public validateResetUserPasswordToken(token: string, options?: AxiosRequestConfig) {
        return UserApiControllerApiFp(this.configuration).validateResetUserPasswordToken(token, options).then((request) => request(this.axios, this.basePath));
    }
}
