/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { MedicalResultTypeDto } from './medical-result-type-dto';
import { UserAccountDto } from './user-account-dto';

/**
 * 
 * @export
 * @interface MedicalRequestMedicalResultDto
 */
export interface MedicalRequestMedicalResultDto {
    /**
     * 
     * @type {string}
     * @memberof MedicalRequestMedicalResultDto
     */
    'creationDate'?: string;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof MedicalRequestMedicalResultDto
     */
    'fromUser'?: UserAccountDto;
    /**
     * 
     * @type {number}
     * @memberof MedicalRequestMedicalResultDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalRequestMedicalResultDto
     */
    'medicalRequestDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedicalRequestMedicalResultDto
     */
    'medicalRequestId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalRequestMedicalResultDto
     */
    'medicalRequestStatus'?: MedicalRequestMedicalResultDtoMedicalRequestStatusEnum;
    /**
     * 
     * @type {MedicalResultTypeDto}
     * @memberof MedicalRequestMedicalResultDto
     */
    'medicalRequestType'?: MedicalResultTypeDto;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof MedicalRequestMedicalResultDto
     */
    'regardsUser'?: UserAccountDto;
    /**
     * 
     * @type {string}
     * @memberof MedicalRequestMedicalResultDto
     */
    'resultDate'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MedicalRequestMedicalResultDtoMedicalRequestStatusEnum {
    New = 'NEW',
    Accepted = 'ACCEPTED',
    Done = 'DONE',
    Rejected = 'REJECTED',
    Cancelled = 'CANCELLED',
    Forwarded = 'FORWARDED'
}


