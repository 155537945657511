import moment from "moment";
import {range} from "lodash-es";
import {MINUTES_TO_VISIT_WHEN_CAN_BE_ENTERED_BY_DOCTOR, MINUTES_TO_VISIT_WHEN_CAN_BE_JOINED} from './constants';


export const isoMonthFormat = 'YYYY-MM';
export const isoDateFormat = 'YYYY-MM-DD';
export const isoDateTimeShortFormat = 'YYYY-MM-DD HH:mm';
export const isoDateTimeLongFormat = 'YYYY-MM-DDTHH:mm:ss';
export const isoTimeLongFormat = 'HH:mm:ss';
export const isoTimeShortFormat = 'HH:mm';
export const maxDate = '9999-12-31';
export const minDate = '1000-01-01';

export function enhanceWithOffset(date) {
    var dateObj = asMoment(date).toDate();
    var userTimezoneOffset = (dateObj.getTimezoneOffset() * 60000) + (getServerTimezoneOffset(dateObj) * 60000);
    return asMoment(new Date(dateObj.getTime() - userTimezoneOffset));
}

export function removeOffsetFromDate(date) {
    if (!date) {
        return date;
    } else {
        var dateObj = asMoment(date).toDate();
        var userTimezoneOffset = (dateObj.getTimezoneOffset() * 60000) + (getServerTimezoneOffset(dateObj) * 60000);
        return asMoment(new Date(dateObj.getTime() + userTimezoneOffset));
    }
}

function getServerTimezoneOffset(date) {
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone: 'Europe/Warsaw' }));
    return (tzDate.getTime() - utcDate.getTime()) / 6e4;
}

function asMoment(dateOrMoment) {
    return (dateOrMoment instanceof moment ? dateOrMoment : moment(dateOrMoment));
}

function formatDate(date, format, skipEnhancement) {
    if (date) {
        const enhanced = skipEnhancement || isDateOnly(date) ? moment(date) : enhanceWithOffset(date);
        return !enhanced ? '' : enhanced.format(format);
    } else {
        return date;
    }
}

function isDateOnly(date) {
    if (date) {
        const parsed = asMoment(date);
        if (parsed.isValid()) {
            return [parsed.hours(), parsed.minutes(), parsed.seconds()].every(v => v === 0);
        }
    }
    return false;
}

export default {

    asMoment: asMoment,

    getMillisFromIsoTimestamp(isoTimestamp) {
        return moment(isoTimestamp).valueOf();
    },

    getIsoDate(date) {
        return moment(asMoment(date)).format(isoDateFormat);
    },

    getCurrentIsoDate() {
        return moment().format(isoDateFormat);
    },

    formatDateTime(date, format, skipEnhancement) {
        return formatDate(date, format, skipEnhancement);
    },

    formatTime(time, inputFormat, outputFormat, skipEnhancement) {
        return !time ? '' : (skipEnhancement ? moment(time, inputFormat) : enhanceWithOffset(moment(time, inputFormat))).format(outputFormat || isoTimeShortFormat);
    },

    getWeekday(date) {
        return formatDate(date, 'dddd');
    },

    roundMinutes(date, minutesStep) {
        date = asMoment(date);
        const minutes = date.minutes();
        const diff = Math.round(minutes / minutesStep) * minutesStep - minutes;
        return date.add(diff, 'minutes').startOf('minute');
    },

    getMinutesFromMillis(millis) {
        return millis ? millis / 60000 : millis;
    },

    getMillisFromMinutes(minutes) {
        return minutes ? minutes * 60000 : minutes;
    },

    getTimeSince(date, {unit = 'minutes', since = Date.now(), full = false}, roundingMode = 'floor') {
        if (!date) {
            return null;
        }
        try {
            if (full) {
                return moment(date).from(moment(since), true);
            }

            if (unit) {
                return Math[roundingMode](
                    moment.duration(moment(since).diff(date)).as(unit)
                );
            }
        } catch (error) {
            return date;
        }
    },

    calendar(date) {
        if (!date) {
            return null;
        }
        return enhanceWithOffset(date).calendar(new Date(), {
            lastWeek: 'L',
            sameElse: 'L'
        });
    },

    isPastDate(date) {
        return date != null && asMoment(date).isBefore();
    },

    isFutureDate(date) {
        return date != null && asMoment(date).isAfter();
    },

    isDateBetween(date, beforeDate, afterDate) {
        try {
            const _date = moment(date);
            const _beforeDate = moment(beforeDate);
            const _afterDate = moment(afterDate);

            return _date >= _beforeDate && (!afterDate || _date <= _afterDate);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            return false;
        }
    },

    isDateGreaterEqualThen(date1, date2) {
        try {
            const _date1 = asMoment(date1);
            const _date2 = asMoment(date2);

            return _date1.isSameOrAfter(_date2);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            return false;
        }
    },

    getDaysFrom(date, length) {
        date = asMoment(date);
        return range(length)
            .map(i =>
                date.clone()
                    .add(i, 'days')
                    .startOf('day')
            );
    },

    addTime(date, unit, value) {
        return asMoment(date).add(value, unit).toDate();
    },

    sortAsc() {
        return (t1, t2) => moment(t1).isAfter(moment(t2));
    },

    humanize(duration) {
        if (duration) {
            return moment.duration(duration).humanize();
        } else {
            return duration;
        }
    },

    durationHhMm(duration) {
        if (duration) {
            return moment.utc(duration).format('HH:mm');
        } else {
            return duration;
        }
    },

    //returns number of minutes in a duration (number between 0-59)
    getOnlyMinutes(dateFrom, dateTo){
        var duration = moment.duration(asMoment(dateTo).diff(asMoment(dateFrom)));
        return duration.minutes();
    },

    getOnlyHours(dateFrom, dateTo) {
        var duration = moment.duration(asMoment(dateTo).diff(asMoment(dateFrom)));
        return duration.hours()
    },

    isSameDay(date1, date2){
        return asMoment(date1).isSame(asMoment(date2), 'd')
    },

    canVisitBeJoined(visitTimeFrom) {
        const difference = moment(visitTimeFrom).diff(moment(), 'minutes');
        return difference <= MINUTES_TO_VISIT_WHEN_CAN_BE_JOINED
            && difference >= 0;
        //FIXME: polaczyc to z wymaganiami na mozliwosc dolaczenia do wizyty w trakcie jej trwania. Albo tuta albo w osobnym sprawdzeniu typu 'canVisitBeJoined'€
    },

    canVisitBeStartedByDoctor(visitTimeFrom) {
        return visitTimeFrom ? enhanceWithOffset(visitTimeFrom).diff(moment(), 'minutes') <= MINUTES_TO_VISIT_WHEN_CAN_BE_ENTERED_BY_DOCTOR : false;
    },

    canVisitBeEdited(visitTimeTo) {
        return visitTimeTo ? moment().isBefore(enhanceWithOffset(visitTimeTo).endOf('day')) : false;
    },

    resolveDateName(date) {
        if (!date) {
            return null;
        }
        if (date.isSame(moment(), 'd')) {
            return 'dates.today';
        }
        if (date.isSame(moment().add(1, 'd'), 'd')) {
            return 'dates.tomorrow';
        }
        return this.getWeekday(date);
    },

    getExactAge(dateString) {
        if (!dateString) {
            return null;
        }
        try {
            const birthDate = moment(dateString);
            const currentDate = moment();
            const years = currentDate.diff(birthDate, 'years');
            birthDate.add(years, 'years');
            const months = currentDate.diff(birthDate, 'months');
            birthDate.add(months, 'months');
            const days = currentDate.diff(birthDate, 'days');
            return {
                years: years,
                months: months,
                days: days
            };
        } catch (error) {
            return dateString;
        }
    }
}
