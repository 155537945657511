export default {

    setInSessionStorage(key, value) {
        window.sessionStorage.setItem(key, value);
    },

    getFromSessionStorage(key) {
        return window.sessionStorage.getItem(key);
    },

    removeFromSessionStorage(key) {
        window.sessionStorage.removeItem(key);
    },

    setInLocalStorage(key, value) {
        window.localStorage.setItem(key, value);
    },

    getFromLocalStorage(key) {
        return window.localStorage.getItem(key);
    },

    removeFromLocalStorage(key) {
        window.localStorage.removeItem(key);
    }
}
