/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface NeuroscreenChildDto
 */
export interface NeuroscreenChildDto {
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'autism1'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'autism2'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'autismGeneral': boolean;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'consciousnessIssues1'?: NeuroscreenChildDtoConsciousnessIssues1Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'consciousnessIssues2'?: NeuroscreenChildDtoConsciousnessIssues2Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'consciousnessIssues3'?: NeuroscreenChildDtoConsciousnessIssues3Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'consciousnessIssues4'?: NeuroscreenChildDtoConsciousnessIssues4Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'consciousnessIssues5'?: NeuroscreenChildDtoConsciousnessIssues5Enum;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'consciousnessIssuesGeneral': boolean;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'eatingIssues1'?: NeuroscreenChildDtoEatingIssues1Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'eatingIssues2'?: NeuroscreenChildDtoEatingIssues2Enum;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'eatingIssuesGeneral': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'epilepsy1'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'epilepsy2'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'epilepsy3'?: NeuroscreenChildDtoEpilepsy3Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'epilepsy4'?: NeuroscreenChildDtoEpilepsy4Enum;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'epilepsy5'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'epilepsy6'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'epilepsy7'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'epilepsyGeneral': boolean;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'headaches1'?: NeuroscreenChildDtoHeadaches1Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'headaches2'?: NeuroscreenChildDtoHeadaches2Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'headaches3'?: NeuroscreenChildDtoHeadaches3Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'headaches4'?: NeuroscreenChildDtoHeadaches4Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'headaches5'?: NeuroscreenChildDtoHeadaches5Enum;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'headachesGeneral': boolean;
    /**
     * 
     * @type {number}
     * @memberof NeuroscreenChildDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'movement1'?: NeuroscreenChildDtoMovement1Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'movement2'?: NeuroscreenChildDtoMovement2Enum;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'movement3'?: NeuroscreenChildDtoMovement3Enum;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'movement4'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'movement5'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'movementGeneral': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'psychomotorDevelopmentIssues1'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'psychomotorDevelopmentIssues2'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NeuroscreenChildDto
     */
    'psychomotorDevelopmentIssuesGeneral': boolean;
    /**
     * 
     * @type {string}
     * @memberof NeuroscreenChildDto
     */
    'publicId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenChildDtoConsciousnessIssues1Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenChildDtoConsciousnessIssues2Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenChildDtoConsciousnessIssues3Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenChildDtoConsciousnessIssues4Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenChildDtoConsciousnessIssues5Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenChildDtoEatingIssues1Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenChildDtoEatingIssues2Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenChildDtoEpilepsy3Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenChildDtoEpilepsy4Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenChildDtoHeadaches1Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenChildDtoHeadaches2Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenChildDtoHeadaches3Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenChildDtoHeadaches4Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenChildDtoHeadaches5Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenChildDtoMovement1Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenChildDtoMovement2Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}
/**
    * @export
    * @enum {string}
    */
export enum NeuroscreenChildDtoMovement3Enum {
    Always = 'ALWAYS',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    Never = 'NEVER'
}


