/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DoctorPricelistItemDto } from './doctor-pricelist-item-dto';
import { MedicalResultDto } from './medical-result-dto';
import { UserAccountDto } from './user-account-dto';

/**
 * 
 * @export
 * @interface MedicalRequestDto
 */
export interface MedicalRequestDto {
    /**
     * 
     * @type {DoctorPricelistItemDto}
     * @memberof MedicalRequestDto
     */
    'doctorPricelistItem'?: DoctorPricelistItemDto;
    /**
     * 
     * @type {string}
     * @memberof MedicalRequestDto
     */
    'documentType'?: MedicalRequestDtoDocumentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MedicalRequestDto
     */
    'dueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalRequestDto
     */
    'entryDate'?: string;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof MedicalRequestDto
     */
    'fromUser'?: UserAccountDto;
    /**
     * 
     * @type {number}
     * @memberof MedicalRequestDto
     */
    'fromUserId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicalRequestDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalRequestDto
     */
    'lastModDate'?: string;
    /**
     * 
     * @type {MedicalResultDto}
     * @memberof MedicalRequestDto
     */
    'medicalResult'?: MedicalResultDto;
    /**
     * 
     * @type {string}
     * @memberof MedicalRequestDto
     */
    'outcomeDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalRequestDto
     */
    'outcomeReason'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalRequestDto
     */
    'paid'?: boolean;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof MedicalRequestDto
     */
    'regardsUser'?: UserAccountDto;
    /**
     * 
     * @type {number}
     * @memberof MedicalRequestDto
     */
    'severity'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalRequestDto
     */
    'status'?: MedicalRequestDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MedicalRequestDto
     */
    'text'?: string;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof MedicalRequestDto
     */
    'toUser'?: UserAccountDto;
    /**
     * 
     * @type {number}
     * @memberof MedicalRequestDto
     */
    'toUserId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalRequestDto
     */
    'type'?: MedicalRequestDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MedicalRequestDtoDocumentTypeEnum {
    ReferralGeneralPurpose = 'REFERRAL_GENERAL_PURPOSE',
    ReferralClinic = 'REFERRAL_CLINIC',
    ReferralToHospital = 'REFERRAL_TO_HOSPITAL',
    ReferralToPsychiatricHospital = 'REFERRAL_TO_PSYCHIATRIC_HOSPITAL',
    ReferralToSpecialist = 'REFERRAL_TO_SPECIALIST',
    ReferralToLab = 'REFERRAL_TO_LAB',
    ReferralToDiagnosticLaboratory = 'REFERRAL_TO_DIAGNOSTIC_LABORATORY',
    Certificate = 'CERTIFICATE',
    CertificateForZus = 'CERTIFICATE_FOR_ZUS',
    CertificateOfDisability = 'CERTIFICATE_OF_DISABILITY',
    HealthCertificate = 'HEALTH_CERTIFICATE',
    InformationForPozDoctor = 'INFORMATION_FOR_POZ_DOCTOR',
    ApplicationForRefund = 'APPLICATION_FOR_REFUND',
    ApplicationForDrugRefund = 'APPLICATION_FOR_DRUG_REFUND',
    DemandForDrugFromAbroad = 'DEMAND_FOR_DRUG_FROM_ABROAD',
    DemandForFoodstuffFromAbroad = 'DEMAND_FOR_FOODSTUFF_FROM_ABROAD',
    Prescription = 'PRESCRIPTION',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum MedicalRequestDtoStatusEnum {
    New = 'NEW',
    Accepted = 'ACCEPTED',
    Done = 'DONE',
    Rejected = 'REJECTED',
    Cancelled = 'CANCELLED',
    Forwarded = 'FORWARDED'
}
/**
    * @export
    * @enum {string}
    */
export enum MedicalRequestDtoTypeEnum {
    Prescribe = 'PRESCRIBE',
    Describe = 'DESCRIBE',
    Consult = 'CONSULT',
    Contact = 'CONTACT',
    Appoint = 'APPOINT',
    LeadDoctor = 'LEAD_DOCTOR',
    LeadDoctorReason = 'LEAD_DOCTOR_REASON',
    IssueDocument = 'ISSUE_DOCUMENT',
    IssueCertificate = 'ISSUE_CERTIFICATE',
    IssuePrescription = 'ISSUE_PRESCRIPTION'
}


