/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BrainActivityDtoEegIncorrectInterictalActivityEnum } from './brain-activity-dto-eeg-incorrect-interictal-activity-enum';
import { BrainActivityDtoEegInterictalActivityEnum } from './brain-activity-dto-eeg-interictal-activity-enum';
import { BrainActivityDtoEegSeizureChangesEnum } from './brain-activity-dto-eeg-seizure-changes-enum';
import { BrainActivityDtoEegSpecialPatternEnum } from './brain-activity-dto-eeg-special-pattern-enum';
import { EegClinicalMeaning } from './eeg-clinical-meaning';
import { EegDescriptionAttachment } from './eeg-description-attachment';
import { EegIndication } from './eeg-indication';
import { EegParoxysmal } from './eeg-paroxysmal';
import { EegPdr } from './eeg-pdr';
import { EegSleep } from './eeg-sleep';
import { ExternalExamination } from './external-examination';
import { LateralizingSymptomDescription } from './lateralizing-symptom-description';
import { MildVariantsDescription } from './mild-variants-description';
import { OtherDescription } from './other-description';

/**
 * 
 * @export
 * @interface EegDescriptionDto
 */
export interface EegDescriptionDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof EegDescriptionDto
     */
    'activationMethods'?: Array<EegDescriptionDtoActivationMethodsEnum>;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'afterPhaseDuration1'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'afterPhaseDuration2'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'afterPhaseDuration3'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'amplitude'?: EegDescriptionDtoAmplitudeEnum;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'amplitudeSymmetry'?: EegDescriptionDtoAmplitudeSymmetryEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof EegDescriptionDto
     */
    'artifacts'?: Array<EegDescriptionDtoArtifactsEnum>;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'artifactsMeaning'?: EegDescriptionDtoArtifactsMeaningEnum;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'artifactsMeaningDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'baseActivityMeaning'?: EegDescriptionDtoBaseActivityMeaningEnum;
    /**
     * 
     * @type {EegClinicalMeaning}
     * @memberof EegDescriptionDto
     */
    'clinicalMeaning'?: EegClinicalMeaning;
    /**
     * 
     * @type {boolean}
     * @memberof EegDescriptionDto
     */
    'completed': boolean;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'consciousEpisode1'?: EegDescriptionDtoConsciousEpisode1Enum;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'consciousEpisode2'?: EegDescriptionDtoConsciousEpisode2Enum;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'consciousEpisode3'?: EegDescriptionDtoConsciousEpisode3Enum;
    /**
     * 
     * @type {Array<string>}
     * @memberof EegDescriptionDto
     */
    'drugs'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'episodeCount1'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'episodeCount2'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'episodeCount3'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'episodeDuration1'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'episodeDuration2'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'episodeDuration3'?: string;
    /**
     * 
     * @type {ExternalExamination}
     * @memberof EegDescriptionDto
     */
    'externalExamination'?: ExternalExamination;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'frequency'?: string;
    /**
     * 
     * @type {Array<BrainActivityDtoEegIncorrectInterictalActivityEnum>}
     * @memberof EegDescriptionDto
     */
    'incorrectInterictalActivity'?: Array<BrainActivityDtoEegIncorrectInterictalActivityEnum>;
    /**
     * 
     * @type {EegIndication}
     * @memberof EegDescriptionDto
     */
    'indication'?: EegIndication;
    /**
     * 
     * @type {Array<BrainActivityDtoEegInterictalActivityEnum>}
     * @memberof EegDescriptionDto
     */
    'interictalActivity'?: Array<BrainActivityDtoEegInterictalActivityEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EegDescriptionDto
     */
    'lateralizingSymptom1'?: Array<EegDescriptionDtoLateralizingSymptom1Enum>;
    /**
     * 
     * @type {LateralizingSymptomDescription}
     * @memberof EegDescriptionDto
     */
    'lateralizingSymptom1Descriptions'?: LateralizingSymptomDescription;
    /**
     * 
     * @type {Array<string>}
     * @memberof EegDescriptionDto
     */
    'lateralizingSymptom2'?: Array<EegDescriptionDtoLateralizingSymptom2Enum>;
    /**
     * 
     * @type {LateralizingSymptomDescription}
     * @memberof EegDescriptionDto
     */
    'lateralizingSymptom2Descriptions'?: LateralizingSymptomDescription;
    /**
     * 
     * @type {Array<string>}
     * @memberof EegDescriptionDto
     */
    'lateralizingSymptom3'?: Array<EegDescriptionDtoLateralizingSymptom3Enum>;
    /**
     * 
     * @type {LateralizingSymptomDescription}
     * @memberof EegDescriptionDto
     */
    'lateralizingSymptom3Descriptions'?: LateralizingSymptomDescription;
    /**
     * 
     * @type {Array<string>}
     * @memberof EegDescriptionDto
     */
    'mildVariants'?: Array<EegDescriptionDtoMildVariantsEnum>;
    /**
     * 
     * @type {MildVariantsDescription}
     * @memberof EegDescriptionDto
     */
    'mildVariantsDescriptions'?: MildVariantsDescription;
    /**
     * 
     * @type {Array<any>}
     * @memberof EegDescriptionDto
     */
    'newAttachments': Array<any>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EegDescriptionDto
     */
    'nonBiologicalArtifacts'?: Array<EegDescriptionDtoNonBiologicalArtifactsEnum>;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'nonBiologicalArtifactsMeaning'?: EegDescriptionDtoNonBiologicalArtifactsMeaningEnum;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'nonBiologicalArtifactsMeaningDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'opinion'?: string;
    /**
     * 
     * @type {EegParoxysmal}
     * @memberof EegDescriptionDto
     */
    'paroxysmal1'?: EegParoxysmal;
    /**
     * 
     * @type {EegParoxysmal}
     * @memberof EegDescriptionDto
     */
    'paroxysmal2'?: EegParoxysmal;
    /**
     * 
     * @type {EegParoxysmal}
     * @memberof EegDescriptionDto
     */
    'paroxysmal3'?: EegParoxysmal;
    /**
     * 
     * @type {number}
     * @memberof EegDescriptionDto
     */
    'patientId': number;
    /**
     * 
     * @type {EegPdr}
     * @memberof EegDescriptionDto
     */
    'pdr'?: EegPdr;
    /**
     * 
     * @type {Array<string>}
     * @memberof EegDescriptionDto
     */
    'physiologicalVariants'?: Array<EegDescriptionDtoPhysiologicalVariantsEnum>;
    /**
     * 
     * @type {OtherDescription}
     * @memberof EegDescriptionDto
     */
    'physiologicalVariantsDescriptions'?: OtherDescription;
    /**
     * 
     * @type {Array<string>}
     * @memberof EegDescriptionDto
     */
    'postSeizure1'?: Array<EegDescriptionDtoPostSeizure1Enum>;
    /**
     * 
     * @type {OtherDescription}
     * @memberof EegDescriptionDto
     */
    'postSeizure1Descriptions'?: OtherDescription;
    /**
     * 
     * @type {Array<string>}
     * @memberof EegDescriptionDto
     */
    'postSeizure2'?: Array<EegDescriptionDtoPostSeizure2Enum>;
    /**
     * 
     * @type {OtherDescription}
     * @memberof EegDescriptionDto
     */
    'postSeizure2Descriptions'?: OtherDescription;
    /**
     * 
     * @type {Array<string>}
     * @memberof EegDescriptionDto
     */
    'postSeizure3'?: Array<EegDescriptionDtoPostSeizure3Enum>;
    /**
     * 
     * @type {OtherDescription}
     * @memberof EegDescriptionDto
     */
    'postSeizure3Descriptions'?: OtherDescription;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'reactivity'?: EegDescriptionDtoReactivityEnum;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'rhythmOrganization'?: EegDescriptionDtoRhythmOrganizationEnum;
    /**
     * 
     * @type {Array<BrainActivityDtoEegSeizureChangesEnum>}
     * @memberof EegDescriptionDto
     */
    'seizureChanges1'?: Array<BrainActivityDtoEegSeizureChangesEnum>;
    /**
     * 
     * @type {Array<BrainActivityDtoEegSeizureChangesEnum>}
     * @memberof EegDescriptionDto
     */
    'seizureChanges2'?: Array<BrainActivityDtoEegSeizureChangesEnum>;
    /**
     * 
     * @type {Array<BrainActivityDtoEegSeizureChangesEnum>}
     * @memberof EegDescriptionDto
     */
    'seizureChanges3'?: Array<BrainActivityDtoEegSeizureChangesEnum>;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'seizureDescription1'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'seizureDescription2'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'seizureDescription3'?: string;
    /**
     * 
     * @type {number}
     * @memberof EegDescriptionDto
     */
    'seizuresCount'?: number;
    /**
     * 
     * @type {EegSleep}
     * @memberof EegDescriptionDto
     */
    'sleep'?: EegSleep;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'sleepKcomplexDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'sleepMeaning'?: EegDescriptionDtoSleepMeaningEnum;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'sleepMeaningDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'sleepPostDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'sleepSharpWavesDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'sleepSorempDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'sleepSpindlesDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'sleepWavesDescription'?: string;
    /**
     * 
     * @type {Array<BrainActivityDtoEegSpecialPatternEnum>}
     * @memberof EegDescriptionDto
     */
    'specialPattern'?: Array<BrainActivityDtoEegSpecialPatternEnum>;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'startSeizure1'?: EegDescriptionDtoStartSeizure1Enum;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'startSeizure2'?: EegDescriptionDtoStartSeizure2Enum;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'startSeizure3'?: EegDescriptionDtoStartSeizure3Enum;
    /**
     * 
     * @type {Array<string>}
     * @memberof EegDescriptionDto
     */
    'state'?: Array<EegDescriptionDtoStateEnum>;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'symmetry'?: EegDescriptionDtoSymmetryEnum;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'timeCorrelation1'?: EegDescriptionDtoTimeCorrelation1Enum;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'timeCorrelation2'?: EegDescriptionDtoTimeCorrelation2Enum;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'timeCorrelation3'?: EegDescriptionDtoTimeCorrelation3Enum;
    /**
     * 
     * @type {string}
     * @memberof EegDescriptionDto
     */
    'type'?: EegDescriptionDtoTypeEnum;
    /**
     * 
     * @type {Array<EegDescriptionAttachment>}
     * @memberof EegDescriptionDto
     */
    'uploadedAttachments': Array<EegDescriptionAttachment>;
    /**
     * 
     * @type {number}
     * @memberof EegDescriptionDto
     */
    'visitId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoActivationMethodsEnum {
    None = 'NONE',
    Photostimulation = 'PHOTOSTIMULATION',
    Hyperventilation = 'HYPERVENTILATION',
    Sleep = 'SLEEP',
    SleepDeprivation = 'SLEEP_DEPRIVATION',
    EyeOpening = 'EYE_OPENING',
    Drugs = 'DRUGS',
    NoDrugs = 'NO_DRUGS'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoAmplitudeEnum {
    LowVoltage = 'LOW_VOLTAGE',
    MediumVoltage = 'MEDIUM_VOLTAGE',
    HighVoltage = 'HIGH_VOLTAGE'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoAmplitudeSymmetryEnum {
    Symmetric = 'SYMMETRIC',
    Lp = 'LP',
    Pl = 'PL'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoArtifactsEnum {
    None = 'NONE',
    Muscle = 'MUSCLE',
    Blinking = 'BLINKING',
    Nystagmus = 'NYSTAGMUS',
    EyeMovement = 'EYE_MOVEMENT',
    Motor = 'MOTOR',
    Chew = 'CHEW',
    Glossokinetic = 'GLOSSOKINETIC',
    Rocking = 'ROCKING',
    Pulse = 'PULSE',
    Ekg = 'EKG',
    Sweat = 'SWEAT',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoArtifactsMeaningEnum {
    NotObstructive = 'NOT_OBSTRUCTIVE',
    EvaluationImpossible = 'EVALUATION_IMPOSSIBLE',
    LimitedValue = 'LIMITED_VALUE'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoBaseActivityMeaningEnum {
    CorrectActivity = 'CORRECT_ACTIVITY',
    NoIrregularity = 'NO_IRREGULARITY',
    IncorrectActivity = 'INCORRECT_ACTIVITY'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoConsciousEpisode1Enum {
    Kept = 'KEPT',
    Disturbed = 'DISTURBED',
    NotExamined = 'NOT_EXAMINED'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoConsciousEpisode2Enum {
    Kept = 'KEPT',
    Disturbed = 'DISTURBED',
    NotExamined = 'NOT_EXAMINED'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoConsciousEpisode3Enum {
    Kept = 'KEPT',
    Disturbed = 'DISTURBED',
    NotExamined = 'NOT_EXAMINED'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoLateralizingSymptom1Enum {
    None = 'NONE',
    EarlyDizziness = 'EARLY_DIZZINESS',
    Dystonia = 'DYSTONIA',
    OneSidedAutomatism = 'ONE_SIDED_AUTOMATISM',
    OneSidedBlinking = 'ONE_SIDED_BLINKING',
    Aphasia = 'APHASIA',
    Hemianopsia = 'HEMIANOPSIA',
    Neglect = 'NEGLECT',
    Paresis = 'PARESIS',
    NoseWipe = 'NOSE_WIPE',
    FencerFigure = 'FENCER_FIGURE',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoLateralizingSymptom2Enum {
    None = 'NONE',
    EarlyDizziness = 'EARLY_DIZZINESS',
    Dystonia = 'DYSTONIA',
    OneSidedAutomatism = 'ONE_SIDED_AUTOMATISM',
    OneSidedBlinking = 'ONE_SIDED_BLINKING',
    Aphasia = 'APHASIA',
    Hemianopsia = 'HEMIANOPSIA',
    Neglect = 'NEGLECT',
    Paresis = 'PARESIS',
    NoseWipe = 'NOSE_WIPE',
    FencerFigure = 'FENCER_FIGURE',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoLateralizingSymptom3Enum {
    None = 'NONE',
    EarlyDizziness = 'EARLY_DIZZINESS',
    Dystonia = 'DYSTONIA',
    OneSidedAutomatism = 'ONE_SIDED_AUTOMATISM',
    OneSidedBlinking = 'ONE_SIDED_BLINKING',
    Aphasia = 'APHASIA',
    Hemianopsia = 'HEMIANOPSIA',
    Neglect = 'NEGLECT',
    Paresis = 'PARESIS',
    NoseWipe = 'NOSE_WIPE',
    FencerFigure = 'FENCER_FIGURE',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoMildVariantsEnum {
    None = 'NONE',
    GatedSpires = 'GATED_SPIRES',
    GraphElements = 'GRAPH_ELEMENTS',
    ThetaWaves = 'THETA_WAVES',
    CentralTheta = 'CENTRAL_THETA',
    Spire = 'SPIRE',
    PositiveSpires = 'POSITIVE_SPIRES',
    EegDischarge = 'EEG_DISCHARGE',
    ElderlySlowdown = 'ELDERLY_SLOWDOWN',
    BreachRhythm = 'BREACH_RHYTHM',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoNonBiologicalArtifactsEnum {
    Frequency = 'FREQUENCY',
    ElectrodePop = 'ELECTRODE_POP',
    SaltBridge = 'SALT_BRIDGE',
    Other = 'OTHER',
    None = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoNonBiologicalArtifactsMeaningEnum {
    NotObstructive = 'NOT_OBSTRUCTIVE',
    EvaluationImpossible = 'EVALUATION_IMPOSSIBLE',
    LimitedValue = 'LIMITED_VALUE'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoPhysiologicalVariantsEnum {
    None = 'NONE',
    SlowAlphaRhythm = 'SLOW_ALPHA_RHYTHM',
    FastAlphaRhythm = 'FAST_ALPHA_RHYTHM',
    LambdaWaves = 'LAMBDA_WAVES',
    YouthWaves = 'YOUTH_WAVES',
    Hyperventilation = 'HYPERVENTILATION',
    FsRhythm = 'FS_RHYTHM',
    PhotomyogenicResponse = 'PHOTOMYOGENIC_RESPONSE',
    Microrhythm = 'MICRORHYTHM',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoPostSeizure1Enum {
    None = 'NONE',
    Unconscious = 'UNCONSCIOUS',
    ConsciousnessRegain = 'CONSCIOUSNESS_REGAIN',
    TongueBite = 'TONGUE_BITE',
    Aphasia = 'APHASIA',
    Psychosis = 'PSYCHOSIS',
    Blindness = 'BLINDNESS',
    NoseWipe = 'NOSE_WIPE',
    Headache = 'HEADACHE',
    Amnesia = 'AMNESIA',
    OneSided = 'ONE_SIDED',
    Paresis = 'PARESIS',
    Sleep = 'SLEEP',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoPostSeizure2Enum {
    None = 'NONE',
    Unconscious = 'UNCONSCIOUS',
    ConsciousnessRegain = 'CONSCIOUSNESS_REGAIN',
    TongueBite = 'TONGUE_BITE',
    Aphasia = 'APHASIA',
    Psychosis = 'PSYCHOSIS',
    Blindness = 'BLINDNESS',
    NoseWipe = 'NOSE_WIPE',
    Headache = 'HEADACHE',
    Amnesia = 'AMNESIA',
    OneSided = 'ONE_SIDED',
    Paresis = 'PARESIS',
    Sleep = 'SLEEP',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoPostSeizure3Enum {
    None = 'NONE',
    Unconscious = 'UNCONSCIOUS',
    ConsciousnessRegain = 'CONSCIOUSNESS_REGAIN',
    TongueBite = 'TONGUE_BITE',
    Aphasia = 'APHASIA',
    Psychosis = 'PSYCHOSIS',
    Blindness = 'BLINDNESS',
    NoseWipe = 'NOSE_WIPE',
    Headache = 'HEADACHE',
    Amnesia = 'AMNESIA',
    OneSided = 'ONE_SIDED',
    Paresis = 'PARESIS',
    Sleep = 'SLEEP',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoReactivityEnum {
    Present = 'PRESENT',
    Absent = 'ABSENT'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoRhythmOrganizationEnum {
    Correct = 'CORRECT',
    WeaklyOrganized = 'WEAKLY_ORGANIZED',
    Disorganized = 'DISORGANIZED'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoSleepMeaningEnum {
    Correct = 'CORRECT',
    NoIrregularity = 'NO_IRREGULARITY',
    Wrong = 'WRONG'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoStartSeizure1Enum {
    Wakeful = 'WAKEFUL',
    Sleeping = 'SLEEPING'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoStartSeizure2Enum {
    Wakeful = 'WAKEFUL',
    Sleeping = 'SLEEPING'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoStartSeizure3Enum {
    Wakeful = 'WAKEFUL',
    Sleeping = 'SLEEPING'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoStateEnum {
    Awaken = 'AWAKEN',
    Sleepiness = 'SLEEPINESS',
    Sleep = 'SLEEP',
    Awakening = 'AWAKENING'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoSymmetryEnum {
    Symmetric = 'SYMMETRIC',
    SlowerRight = 'SLOWER_RIGHT',
    SlowerLeft = 'SLOWER_LEFT'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoTimeCorrelation1Enum {
    ClinicalBeginning = 'CLINICAL_BEGINNING',
    EegBeginning = 'EEG_BEGINNING',
    Simultaneous = 'SIMULTANEOUS'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoTimeCorrelation2Enum {
    ClinicalBeginning = 'CLINICAL_BEGINNING',
    EegBeginning = 'EEG_BEGINNING',
    Simultaneous = 'SIMULTANEOUS'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoTimeCorrelation3Enum {
    ClinicalBeginning = 'CLINICAL_BEGINNING',
    EegBeginning = 'EEG_BEGINNING',
    Simultaneous = 'SIMULTANEOUS'
}
/**
    * @export
    * @enum {string}
    */
export enum EegDescriptionDtoTypeEnum {
    Standard = 'STANDARD',
    Short = 'SHORT',
    Long = 'LONG'
}


