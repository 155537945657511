/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface SeizuresCharacteristicsDto
 */
export interface SeizuresCharacteristicsDto {
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'afterAggression'?: SeizuresCharacteristicsDtoAfterAggressionEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'afterConfusion'?: SeizuresCharacteristicsDtoAfterConfusionEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'afterDefecation'?: SeizuresCharacteristicsDtoAfterDefecationEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'afterEyeMovementDisorders'?: SeizuresCharacteristicsDtoAfterEyeMovementDisordersEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'afterEyeTurnPernament'?: SeizuresCharacteristicsDtoAfterEyeTurnPernamentEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'afterHighTemperature'?: SeizuresCharacteristicsDtoAfterHighTemperatureEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'afterHyperventilation'?: SeizuresCharacteristicsDtoAfterHyperventilationEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'afterMotorAutomatisms'?: SeizuresCharacteristicsDtoAfterMotorAutomatismsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'afterParesisOrParalysis'?: SeizuresCharacteristicsDtoAfterParesisOrParalysisEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'afterSalivaOrBloodySalivaFoam'?: SeizuresCharacteristicsDtoAfterSalivaOrBloodySalivaFoamEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'afterSleep'?: SeizuresCharacteristicsDtoAfterSleepEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'afterUrinating'?: SeizuresCharacteristicsDtoAfterUrinatingEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'afterVomit'?: SeizuresCharacteristicsDtoAfterVomitEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'beforeAura'?: SeizuresCharacteristicsDtoBeforeAuraEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'beforeImmobility'?: SeizuresCharacteristicsDtoBeforeImmobilityEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'beforeNystagmus'?: SeizuresCharacteristicsDtoBeforeNystagmusEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'beforeSpecificStimuli'?: SeizuresCharacteristicsDtoBeforeSpecificStimuliEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endAggression'?: SeizuresCharacteristicsDtoEndAggressionEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endAnotherSeizure'?: SeizuresCharacteristicsDtoEndAnotherSeizureEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endBodyConvulsions'?: SeizuresCharacteristicsDtoEndBodyConvulsionsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endBodyPartConvulsions'?: SeizuresCharacteristicsDtoEndBodyPartConvulsionsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endBodyTurn'?: SeizuresCharacteristicsDtoEndBodyTurnEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endChuck'?: SeizuresCharacteristicsDtoEndChuckEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endCry'?: SeizuresCharacteristicsDtoEndCryEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endDefecation'?: SeizuresCharacteristicsDtoEndDefecationEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endEyeMovementDisorders'?: SeizuresCharacteristicsDtoEndEyeMovementDisordersEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endEyeTurnPernament'?: SeizuresCharacteristicsDtoEndEyeTurnPernamentEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endFaceConvulsions'?: SeizuresCharacteristicsDtoEndFaceConvulsionsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endGroundFall'?: SeizuresCharacteristicsDtoEndGroundFallEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endImmobility'?: SeizuresCharacteristicsDtoEndImmobilityEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endLaugh'?: SeizuresCharacteristicsDtoEndLaughEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endMotorAutomatisms'?: SeizuresCharacteristicsDtoEndMotorAutomatismsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endSounds'?: SeizuresCharacteristicsDtoEndSoundsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endUrinating'?: SeizuresCharacteristicsDtoEndUrinatingEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'endVomit'?: SeizuresCharacteristicsDtoEndVomitEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'featureAwakeningOccurrence'?: SeizuresCharacteristicsDtoFeatureAwakeningOccurrenceEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'featureDayOccurrence'?: SeizuresCharacteristicsDtoFeatureDayOccurrenceEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'featureDuration'?: SeizuresCharacteristicsDtoFeatureDurationEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'featureMany'?: SeizuresCharacteristicsDtoFeatureManyEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'featureMorningOccurrence'?: SeizuresCharacteristicsDtoFeatureMorningOccurrenceEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'featureNightOccurrence'?: SeizuresCharacteristicsDtoFeatureNightOccurrenceEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'formChanges'?: SeizuresCharacteristicsDtoFormChangesEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextAggression'?: SeizuresCharacteristicsDtoNextAggressionEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextAuditoryHallucinations'?: SeizuresCharacteristicsDtoNextAuditoryHallucinationsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextAutonomicSymptoms'?: SeizuresCharacteristicsDtoNextAutonomicSymptomsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextBitTongue'?: SeizuresCharacteristicsDtoNextBitTongueEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextBodyConvulsions'?: SeizuresCharacteristicsDtoNextBodyConvulsionsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextBodyDistortion'?: SeizuresCharacteristicsDtoNextBodyDistortionEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextBodyPartConvulsions'?: SeizuresCharacteristicsDtoNextBodyPartConvulsionsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextBodyStiffness'?: SeizuresCharacteristicsDtoNextBodyStiffnessEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextBodyTurn'?: SeizuresCharacteristicsDtoNextBodyTurnEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextChuck'?: SeizuresCharacteristicsDtoNextChuckEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextCry'?: SeizuresCharacteristicsDtoNextCryEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextDefecation'?: SeizuresCharacteristicsDtoNextDefecationEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextDejaVu'?: SeizuresCharacteristicsDtoNextDejaVuEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextEpigastricSymptoms'?: SeizuresCharacteristicsDtoNextEpigastricSymptomsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextEyeMovementDisorders'?: SeizuresCharacteristicsDtoNextEyeMovementDisordersEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextEyeTurnPernament'?: SeizuresCharacteristicsDtoNextEyeTurnPernamentEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextEyelidsMyoclonus'?: SeizuresCharacteristicsDtoNextEyelidsMyoclonusEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextFaceConvulsions'?: SeizuresCharacteristicsDtoNextFaceConvulsionsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextFaint'?: SeizuresCharacteristicsDtoNextFaintEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextGroundFall'?: SeizuresCharacteristicsDtoNextGroundFallEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextHeartSymptoms'?: SeizuresCharacteristicsDtoNextHeartSymptomsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextHyperkineticSymptoms'?: SeizuresCharacteristicsDtoNextHyperkineticSymptomsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextImmobility'?: SeizuresCharacteristicsDtoNextImmobilityEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextLanguageIssues'?: SeizuresCharacteristicsDtoNextLanguageIssuesEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextLaugh'?: SeizuresCharacteristicsDtoNextLaughEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextLossConsciousness'?: SeizuresCharacteristicsDtoNextLossConsciousnessEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextLossConsciousnessPartially'?: SeizuresCharacteristicsDtoNextLossConsciousnessPartiallyEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextMemoryDisorders'?: SeizuresCharacteristicsDtoNextMemoryDisordersEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextMotorAutomatisms'?: SeizuresCharacteristicsDtoNextMotorAutomatismsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextOtherAutomatisms'?: SeizuresCharacteristicsDtoNextOtherAutomatismsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextRhythmicConvulsions'?: SeizuresCharacteristicsDtoNextRhythmicConvulsionsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextShout'?: SeizuresCharacteristicsDtoNextShoutEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextSomatosensorySymptoms'?: SeizuresCharacteristicsDtoNextSomatosensorySymptomsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextSounds'?: SeizuresCharacteristicsDtoNextSoundsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextUrinating'?: SeizuresCharacteristicsDtoNextUrinatingEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'nextVisualHallucinations'?: SeizuresCharacteristicsDtoNextVisualHallucinationsEnum;
    /**
     * 
     * @type {number}
     * @memberof SeizuresCharacteristicsDto
     */
    'patientSeizureTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'patientSeizureTypeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startAtony'?: SeizuresCharacteristicsDtoStartAtonyEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startAuditoryHallucinations'?: SeizuresCharacteristicsDtoStartAuditoryHallucinationsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startAutonomicSymptoms'?: SeizuresCharacteristicsDtoStartAutonomicSymptomsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startBitTongue'?: SeizuresCharacteristicsDtoStartBitTongueEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startBodyConvulsions'?: SeizuresCharacteristicsDtoStartBodyConvulsionsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startBodyDistortion'?: SeizuresCharacteristicsDtoStartBodyDistortionEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startBodyPartConvulsions'?: SeizuresCharacteristicsDtoStartBodyPartConvulsionsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startBodyStiffness'?: SeizuresCharacteristicsDtoStartBodyStiffnessEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startBodyTurn'?: SeizuresCharacteristicsDtoStartBodyTurnEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startChuck'?: SeizuresCharacteristicsDtoStartChuckEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startCry'?: SeizuresCharacteristicsDtoStartCryEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startDejaVu'?: SeizuresCharacteristicsDtoStartDejaVuEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startEpigastricSymptoms'?: SeizuresCharacteristicsDtoStartEpigastricSymptomsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startEyeMovementDisorders'?: SeizuresCharacteristicsDtoStartEyeMovementDisordersEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startEyeTurnPernament'?: SeizuresCharacteristicsDtoStartEyeTurnPernamentEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startEyelidsMyoclonus'?: SeizuresCharacteristicsDtoStartEyelidsMyoclonusEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startFaceConvulsions'?: SeizuresCharacteristicsDtoStartFaceConvulsionsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startFaint'?: SeizuresCharacteristicsDtoStartFaintEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startFeelingFearOrAnxiety'?: SeizuresCharacteristicsDtoStartFeelingFearOrAnxietyEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startGroundFall'?: SeizuresCharacteristicsDtoStartGroundFallEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startHeartSymptoms'?: SeizuresCharacteristicsDtoStartHeartSymptomsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startHyperkineticSymptoms'?: SeizuresCharacteristicsDtoStartHyperkineticSymptomsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startImmobility'?: SeizuresCharacteristicsDtoStartImmobilityEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startLanguageIssues'?: SeizuresCharacteristicsDtoStartLanguageIssuesEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startLaugh'?: SeizuresCharacteristicsDtoStartLaughEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startLimbEjection'?: SeizuresCharacteristicsDtoStartLimbEjectionEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startLossConsciousness'?: SeizuresCharacteristicsDtoStartLossConsciousnessEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startLossConsciousnessPartially'?: SeizuresCharacteristicsDtoStartLossConsciousnessPartiallyEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startMemoryDisorders'?: SeizuresCharacteristicsDtoStartMemoryDisordersEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startMotorAutomatisms'?: SeizuresCharacteristicsDtoStartMotorAutomatismsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startMyoclonus'?: SeizuresCharacteristicsDtoStartMyoclonusEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startOtherAutomatisms'?: SeizuresCharacteristicsDtoStartOtherAutomatismsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startRhythmicConvulsions'?: SeizuresCharacteristicsDtoStartRhythmicConvulsionsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startShout'?: SeizuresCharacteristicsDtoStartShoutEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startSomatosensorySymptoms'?: SeizuresCharacteristicsDtoStartSomatosensorySymptomsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startSounds'?: SeizuresCharacteristicsDtoStartSoundsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startSpeechStop'?: SeizuresCharacteristicsDtoStartSpeechStopEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'startVisualHallucinations'?: SeizuresCharacteristicsDtoStartVisualHallucinationsEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'trailer'?: SeizuresCharacteristicsDtoTrailerEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'trailerPrecise'?: SeizuresCharacteristicsDtoTrailerPreciseEnum;
    /**
     * 
     * @type {string}
     * @memberof SeizuresCharacteristicsDto
     */
    'trailerUnspecified'?: SeizuresCharacteristicsDtoTrailerUnspecifiedEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoAfterAggressionEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoAfterConfusionEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoAfterDefecationEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoAfterEyeMovementDisordersEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoAfterEyeTurnPernamentEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoAfterHighTemperatureEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoAfterHyperventilationEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoAfterMotorAutomatismsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoAfterParesisOrParalysisEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoAfterSalivaOrBloodySalivaFoamEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoAfterSleepEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoAfterUrinatingEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoAfterVomitEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoBeforeAuraEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoBeforeImmobilityEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoBeforeNystagmusEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoBeforeSpecificStimuliEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndAggressionEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndAnotherSeizureEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndBodyConvulsionsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndBodyPartConvulsionsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndBodyTurnEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndChuckEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndCryEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndDefecationEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndEyeMovementDisordersEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndEyeTurnPernamentEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndFaceConvulsionsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndGroundFallEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndImmobilityEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndLaughEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndMotorAutomatismsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndSoundsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndUrinatingEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoEndVomitEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoFeatureAwakeningOccurrenceEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoFeatureDayOccurrenceEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoFeatureDurationEnum {
    VeryLong = 'VERY_LONG',
    Long = 'LONG',
    BitLong = 'BIT_LONG',
    Variable = 'VARIABLE',
    Short = 'SHORT',
    VeryShort = 'VERY_SHORT',
    UltraShort = 'ULTRA_SHORT',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoFeatureManyEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoFeatureMorningOccurrenceEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoFeatureNightOccurrenceEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoFormChangesEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextAggressionEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextAuditoryHallucinationsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextAutonomicSymptomsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextBitTongueEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextBodyConvulsionsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextBodyDistortionEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextBodyPartConvulsionsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextBodyStiffnessEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextBodyTurnEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextChuckEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextCryEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextDefecationEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextDejaVuEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextEpigastricSymptomsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextEyeMovementDisordersEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextEyeTurnPernamentEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextEyelidsMyoclonusEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextFaceConvulsionsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextFaintEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextGroundFallEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextHeartSymptomsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextHyperkineticSymptomsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextImmobilityEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextLanguageIssuesEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextLaughEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextLossConsciousnessEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextLossConsciousnessPartiallyEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextMemoryDisordersEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextMotorAutomatismsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextOtherAutomatismsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextRhythmicConvulsionsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextShoutEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextSomatosensorySymptomsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextSoundsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextUrinatingEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoNextVisualHallucinationsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartAtonyEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartAuditoryHallucinationsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartAutonomicSymptomsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartBitTongueEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartBodyConvulsionsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartBodyDistortionEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartBodyPartConvulsionsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartBodyStiffnessEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartBodyTurnEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartChuckEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartCryEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartDejaVuEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartEpigastricSymptomsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartEyeMovementDisordersEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartEyeTurnPernamentEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartEyelidsMyoclonusEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartFaceConvulsionsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartFaintEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartFeelingFearOrAnxietyEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartGroundFallEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartHeartSymptomsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartHyperkineticSymptomsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartImmobilityEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartLanguageIssuesEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartLaughEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartLimbEjectionEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartLossConsciousnessEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartLossConsciousnessPartiallyEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartMemoryDisordersEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartMotorAutomatismsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartMyoclonusEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartOtherAutomatismsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartRhythmicConvulsionsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartShoutEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartSomatosensorySymptomsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartSoundsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartSpeechStopEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoStartVisualHallucinationsEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoTrailerEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoTrailerPreciseEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum SeizuresCharacteristicsDtoTrailerUnspecifiedEnum {
    Always = 'ALWAYS',
    VeryOften = 'VERY_OFTEN',
    Often = 'OFTEN',
    Sometimes = 'SOMETIMES',
    Rarely = 'RARELY',
    VeryRarely = 'VERY_RARELY',
    Never = 'NEVER',
    NotKnown = 'NOT_KNOWN'
}


