/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddMedicalEventDto } from '../model';
// @ts-ignore
import { MedicalEventBasicDto } from '../model';
// @ts-ignore
import { MedicalEventDto } from '../model';
// @ts-ignore
import { PageMedicalEventBasicDto } from '../model';
// @ts-ignore
import { Pageable } from '../model';
// @ts-ignore
import { UpdateMedicalEventDto } from '../model';
/**
 * MedicalEventApiControllerApi - axios parameter creator
 * @export
 */
export const MedicalEventApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<AddMedicalEventDto>} addMedicalEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMedicalEvents: async (addMedicalEventDto: Array<AddMedicalEventDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addMedicalEventDto' is not null or undefined
            assertParamExists('addMedicalEvents', 'addMedicalEventDto', addMedicalEventDto)
            const localVarPath = `/api/medical-event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addMedicalEventDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Array<AddMedicalEventDto>} addMedicalEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMedicalEventsByDoctor: async (patientId: number, addMedicalEventDto: Array<AddMedicalEventDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('addMedicalEventsByDoctor', 'patientId', patientId)
            // verify required parameter 'addMedicalEventDto' is not null or undefined
            assertParamExists('addMedicalEventsByDoctor', 'addMedicalEventDto', addMedicalEventDto)
            const localVarPath = `/api/medical-event/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addMedicalEventDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedicalEvent: async (patientId: number, eventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('deleteMedicalEvent', 'patientId', patientId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('deleteMedicalEvent', 'eventId', eventId)
            const localVarPath = `/api/medical-event/{patientId}/{eventId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestMedicalEventsAddedByPatient: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getLatestMedicalEventsAddedByPatient', 'patientId', patientId)
            const localVarPath = `/api/medical-event/{patientId}/patient-latest`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalEventsHistory: async (patientId: number, pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getMedicalEventsHistory', 'patientId', patientId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getMedicalEventsHistory', 'pageable', pageable)
            const localVarPath = `/api/medical-event/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} eventId 
         * @param {UpdateMedicalEventDto} updateMedicalEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMedicalEvent: async (patientId: number, eventId: number, updateMedicalEventDto: UpdateMedicalEventDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('updateMedicalEvent', 'patientId', patientId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('updateMedicalEvent', 'eventId', eventId)
            // verify required parameter 'updateMedicalEventDto' is not null or undefined
            assertParamExists('updateMedicalEvent', 'updateMedicalEventDto', updateMedicalEventDto)
            const localVarPath = `/api/medical-event/{patientId}/{eventId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMedicalEventDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MedicalEventApiControllerApi - functional programming interface
 * @export
 */
export const MedicalEventApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MedicalEventApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<AddMedicalEventDto>} addMedicalEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMedicalEvents(addMedicalEventDto: Array<AddMedicalEventDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<MedicalEventDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMedicalEvents(addMedicalEventDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Array<AddMedicalEventDto>} addMedicalEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMedicalEventsByDoctor(patientId: number, addMedicalEventDto: Array<AddMedicalEventDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<MedicalEventDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMedicalEventsByDoctor(patientId, addMedicalEventDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMedicalEvent(patientId: number, eventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMedicalEvent(patientId, eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestMedicalEventsAddedByPatient(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<MedicalEventBasicDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestMedicalEventsAddedByPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMedicalEventsHistory(patientId: number, pageable: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PageMedicalEventBasicDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMedicalEventsHistory(patientId, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} eventId 
         * @param {UpdateMedicalEventDto} updateMedicalEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMedicalEvent(patientId: number, eventId: number, updateMedicalEventDto: UpdateMedicalEventDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<MedicalEventDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMedicalEvent(patientId, eventId, updateMedicalEventDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MedicalEventApiControllerApi - factory interface
 * @export
 */
export const MedicalEventApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MedicalEventApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<AddMedicalEventDto>} addMedicalEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMedicalEvents(addMedicalEventDto: Array<AddMedicalEventDto>, options?: any): Promise<Array<MedicalEventDto>> {
            return localVarFp.addMedicalEvents(addMedicalEventDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Array<AddMedicalEventDto>} addMedicalEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMedicalEventsByDoctor(patientId: number, addMedicalEventDto: Array<AddMedicalEventDto>, options?: any): Promise<Array<MedicalEventDto>> {
            return localVarFp.addMedicalEventsByDoctor(patientId, addMedicalEventDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedicalEvent(patientId: number, eventId: number, options?: any): Promise<void> {
            return localVarFp.deleteMedicalEvent(patientId, eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestMedicalEventsAddedByPatient(patientId: number, options?: any): Promise<Array<MedicalEventBasicDto>> {
            return localVarFp.getLatestMedicalEventsAddedByPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalEventsHistory(patientId: number, pageable: Pageable, options?: any): Promise<PageMedicalEventBasicDto> {
            return localVarFp.getMedicalEventsHistory(patientId, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {number} eventId 
         * @param {UpdateMedicalEventDto} updateMedicalEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMedicalEvent(patientId: number, eventId: number, updateMedicalEventDto: UpdateMedicalEventDto, options?: any): Promise<MedicalEventDto> {
            return localVarFp.updateMedicalEvent(patientId, eventId, updateMedicalEventDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MedicalEventApiControllerApi - object-oriented interface
 * @export
 * @class MedicalEventApiControllerApi
 * @extends {BaseAPI}
 */
export class MedicalEventApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {Array<AddMedicalEventDto>} addMedicalEventDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalEventApiControllerApi
     */
    public addMedicalEvents(addMedicalEventDto: Array<AddMedicalEventDto>, options?: AxiosRequestConfig) {
        return MedicalEventApiControllerApiFp(this.configuration).addMedicalEvents(addMedicalEventDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {Array<AddMedicalEventDto>} addMedicalEventDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalEventApiControllerApi
     */
    public addMedicalEventsByDoctor(patientId: number, addMedicalEventDto: Array<AddMedicalEventDto>, options?: AxiosRequestConfig) {
        return MedicalEventApiControllerApiFp(this.configuration).addMedicalEventsByDoctor(patientId, addMedicalEventDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalEventApiControllerApi
     */
    public deleteMedicalEvent(patientId: number, eventId: number, options?: AxiosRequestConfig) {
        return MedicalEventApiControllerApiFp(this.configuration).deleteMedicalEvent(patientId, eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalEventApiControllerApi
     */
    public getLatestMedicalEventsAddedByPatient(patientId: number, options?: AxiosRequestConfig) {
        return MedicalEventApiControllerApiFp(this.configuration).getLatestMedicalEventsAddedByPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalEventApiControllerApi
     */
    public getMedicalEventsHistory(patientId: number, pageable: Pageable, options?: AxiosRequestConfig) {
        return MedicalEventApiControllerApiFp(this.configuration).getMedicalEventsHistory(patientId, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {number} eventId 
     * @param {UpdateMedicalEventDto} updateMedicalEventDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalEventApiControllerApi
     */
    public updateMedicalEvent(patientId: number, eventId: number, updateMedicalEventDto: UpdateMedicalEventDto, options?: AxiosRequestConfig) {
        return MedicalEventApiControllerApiFp(this.configuration).updateMedicalEvent(patientId, eventId, updateMedicalEventDto, options).then((request) => request(this.axios, this.basePath));
    }
}
