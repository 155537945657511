/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BioMedicalData } from '../model';
// @ts-ignore
import { PatientAccountInfoDto } from '../model';
/**
 * PatientAccountFormsApiControllerApi - axios parameter creator
 * @export
 */
export const PatientAccountFormsApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/patient/form/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInfo1: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getAccountInfo1', 'patientId', patientId)
            const localVarPath = `/api/patient/form/info/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBiomedicalData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/patient/form/info/biomedical`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBiomedicalDataById: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getBiomedicalDataById', 'patientId', patientId)
            const localVarPath = `/api/patient/form/info/{patientId}/biomedical`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatientAccountInfoDto} patientAccountInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccountGeneralInfo: async (patientAccountInfoDto: PatientAccountInfoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientAccountInfoDto' is not null or undefined
            assertParamExists('saveAccountGeneralInfo', 'patientAccountInfoDto', patientAccountInfoDto)
            const localVarPath = `/api/patient/form/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientAccountInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BioMedicalData} bioMedicalData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBiomedicalData: async (bioMedicalData: BioMedicalData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bioMedicalData' is not null or undefined
            assertParamExists('saveBiomedicalData', 'bioMedicalData', bioMedicalData)
            const localVarPath = `/api/patient/form/info/biomedical`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bioMedicalData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {BioMedicalData} bioMedicalData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBiomedicalDataById: async (patientId: number, bioMedicalData: BioMedicalData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('saveBiomedicalDataById', 'patientId', patientId)
            // verify required parameter 'bioMedicalData' is not null or undefined
            assertParamExists('saveBiomedicalDataById', 'bioMedicalData', bioMedicalData)
            const localVarPath = `/api/patient/form/info/{patientId}/biomedical`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bioMedicalData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientAccountFormsApiControllerApi - functional programming interface
 * @export
 */
export const PatientAccountFormsApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientAccountFormsApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientAccountInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountInfo1(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientAccountInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountInfo1(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBiomedicalData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<BioMedicalData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBiomedicalData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBiomedicalDataById(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<BioMedicalData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBiomedicalDataById(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PatientAccountInfoDto} patientAccountInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAccountGeneralInfo(patientAccountInfoDto: PatientAccountInfoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PatientAccountInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAccountGeneralInfo(patientAccountInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BioMedicalData} bioMedicalData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveBiomedicalData(bioMedicalData: BioMedicalData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveBiomedicalData(bioMedicalData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {BioMedicalData} bioMedicalData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveBiomedicalDataById(patientId: number, bioMedicalData: BioMedicalData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveBiomedicalDataById(patientId, bioMedicalData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientAccountFormsApiControllerApi - factory interface
 * @export
 */
export const PatientAccountFormsApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientAccountFormsApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInfo(options?: any): Promise<PatientAccountInfoDto> {
            return localVarFp.getAccountInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInfo1(patientId: number, options?: any): Promise<PatientAccountInfoDto> {
            return localVarFp.getAccountInfo1(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBiomedicalData(options?: any): Promise<BioMedicalData> {
            return localVarFp.getBiomedicalData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBiomedicalDataById(patientId: number, options?: any): Promise<BioMedicalData> {
            return localVarFp.getBiomedicalDataById(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PatientAccountInfoDto} patientAccountInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAccountGeneralInfo(patientAccountInfoDto: PatientAccountInfoDto, options?: any): Promise<PatientAccountInfoDto> {
            return localVarFp.saveAccountGeneralInfo(patientAccountInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BioMedicalData} bioMedicalData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBiomedicalData(bioMedicalData: BioMedicalData, options?: any): Promise<void> {
            return localVarFp.saveBiomedicalData(bioMedicalData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {BioMedicalData} bioMedicalData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBiomedicalDataById(patientId: number, bioMedicalData: BioMedicalData, options?: any): Promise<void> {
            return localVarFp.saveBiomedicalDataById(patientId, bioMedicalData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientAccountFormsApiControllerApi - object-oriented interface
 * @export
 * @class PatientAccountFormsApiControllerApi
 * @extends {BaseAPI}
 */
export class PatientAccountFormsApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAccountFormsApiControllerApi
     */
    public getAccountInfo(options?: AxiosRequestConfig) {
        return PatientAccountFormsApiControllerApiFp(this.configuration).getAccountInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAccountFormsApiControllerApi
     */
    public getAccountInfo1(patientId: number, options?: AxiosRequestConfig) {
        return PatientAccountFormsApiControllerApiFp(this.configuration).getAccountInfo1(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAccountFormsApiControllerApi
     */
    public getBiomedicalData(options?: AxiosRequestConfig) {
        return PatientAccountFormsApiControllerApiFp(this.configuration).getBiomedicalData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAccountFormsApiControllerApi
     */
    public getBiomedicalDataById(patientId: number, options?: AxiosRequestConfig) {
        return PatientAccountFormsApiControllerApiFp(this.configuration).getBiomedicalDataById(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PatientAccountInfoDto} patientAccountInfoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAccountFormsApiControllerApi
     */
    public saveAccountGeneralInfo(patientAccountInfoDto: PatientAccountInfoDto, options?: AxiosRequestConfig) {
        return PatientAccountFormsApiControllerApiFp(this.configuration).saveAccountGeneralInfo(patientAccountInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BioMedicalData} bioMedicalData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAccountFormsApiControllerApi
     */
    public saveBiomedicalData(bioMedicalData: BioMedicalData, options?: AxiosRequestConfig) {
        return PatientAccountFormsApiControllerApiFp(this.configuration).saveBiomedicalData(bioMedicalData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {BioMedicalData} bioMedicalData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientAccountFormsApiControllerApi
     */
    public saveBiomedicalDataById(patientId: number, bioMedicalData: BioMedicalData, options?: AxiosRequestConfig) {
        return PatientAccountFormsApiControllerApiFp(this.configuration).saveBiomedicalDataById(patientId, bioMedicalData, options).then((request) => request(this.axios, this.basePath));
    }
}
