/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BrainActivityDtoEegSpecialPatternEnum
 */
export interface BrainActivityDtoEegSpecialPatternEnum {
    /**
     * 
     * @type {string}
     * @memberof BrainActivityDtoEegSpecialPatternEnum
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BrainActivityDtoEegSpecialPatternEnum
     */
    'electrodes'?: Array<BrainActivityDtoEegSpecialPatternEnumElectrodesEnum>;
    /**
     * 
     * @type {string}
     * @memberof BrainActivityDtoEegSpecialPatternEnum
     */
    'lateralization'?: BrainActivityDtoEegSpecialPatternEnumLateralizationEnum;
    /**
     * 
     * @type {string}
     * @memberof BrainActivityDtoEegSpecialPatternEnum
     */
    'name': BrainActivityDtoEegSpecialPatternEnumNameEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof BrainActivityDtoEegSpecialPatternEnum
     */
    'regions'?: Array<BrainActivityDtoEegSpecialPatternEnumRegionsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum BrainActivityDtoEegSpecialPatternEnumElectrodesEnum {
    Fp1 = 'FP1',
    F3 = 'F3',
    C3 = 'C3',
    P3 = 'P3',
    F7 = 'F7',
    T7 = 'T7',
    P7 = 'P7',
    O1 = 'O1',
    F9 = 'F9',
    T9 = 'T9',
    P9 = 'P9',
    Fz = 'FZ',
    Cz = 'CZ',
    Pz = 'PZ',
    Fp2 = 'FP2',
    F4 = 'F4',
    C4 = 'C4',
    P4 = 'P4',
    F8 = 'F8',
    T8 = 'T8',
    P8 = 'P8',
    O2 = 'O2',
    F10 = 'F10',
    T10 = 'T10',
    P10 = 'P10'
}
/**
    * @export
    * @enum {string}
    */
export enum BrainActivityDtoEegSpecialPatternEnumLateralizationEnum {
    RightSide = 'RIGHT_SIDE',
    LeftSide = 'LEFT_SIDE',
    BothSide = 'BOTH_SIDE'
}
/**
    * @export
    * @enum {string}
    */
export enum BrainActivityDtoEegSpecialPatternEnumNameEnum {
    None = 'NONE',
    PeriodicalDischarge = 'PERIODICAL_DISCHARGE',
    GeneralDischarge = 'GENERAL_DISCHARGE',
    SeizureLikeDischarge = 'SEIZURE_LIKE_DISCHARGE',
    IndependentDischarge = 'INDEPENDENT_DISCHARGE',
    MultifocalDischarge = 'MULTIFOCAL_DISCHARGE',
    ExtremeDelta = 'EXTREME_DELTA',
    BurstSuppression = 'BURST_SUPPRESSION',
    BurstAttenuation = 'BURST_ATTENUATION',
    Other = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum BrainActivityDtoEegSpecialPatternEnumRegionsEnum {
    Frontal = 'FRONTAL',
    Temporal = 'TEMPORAL',
    Central = 'CENTRAL',
    Parietal = 'PARIETAL',
    Occipital = 'OCCIPITAL',
    General = 'GENERAL'
}


