<template>
    <v-avatar :size="getSize" color="#1f93d0">
        <img v-if="avatar" :src="avatar" @error="imageLoadError">
        <v-icon v-else :size="getIconSize" color="white">{{ getIcon }}</v-icon>
    </v-avatar>
</template>

<script>

export default {
    name: 'avatar',

    props: [
        'login',
        'size',
        'userId',
        'defaultIcon'
    ],

    data: () => ({
        avatar: undefined
    }),

    computed: {
        getSize() {
            return this.size || 35;
        },
        getIconSize() {
            return Math.floor((this.getSize) / 1.46);
        },
        getIcon() {
            return this.defaultIcon ? this.defaultIcon : 'mdi-account';
        }
    },

    watch: {
        login: function (val) {
            if (val) {
                this.refresh();
            }
        },
        userId: function () {
            this.refresh();
        }
    },

    methods: {
        refresh() {
            this.avatar = null;
            if (this.login) {
                this.avatar = '/api/avatar/' + this.login;
            }
            if (this.userId) {
                this.avatar = '/api/avatar/id/' + this.userId;
            }
        },
        imageLoadError() {
            this.avatar = undefined;
        }
    },

    mounted() {
        this.refresh();
    }
}
</script>
