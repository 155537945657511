/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ReferralDto } from '../model';
/**
 * ReferralApiControllerApi - axios parameter creator
 * @export
 */
export const ReferralApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelReferral: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelReferral', 'id', id)
            const localVarPath = `/api/referral/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableReferrals: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/referral/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReferralById', 'id', id)
            const localVarPath = `/api/referral/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {Array<ReferralDto>} referralDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReferrals: async (visitId: number, referralDto: Array<ReferralDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('saveReferrals', 'visitId', visitId)
            // verify required parameter 'referralDto' is not null or undefined
            assertParamExists('saveReferrals', 'referralDto', referralDto)
            const localVarPath = `/api/referral/{visitId}`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(referralDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferralApiControllerApi - functional programming interface
 * @export
 */
export const ReferralApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferralApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelReferral(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelReferral(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableReferrals(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<ReferralDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableReferrals(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<ReferralDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {Array<ReferralDto>} referralDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveReferrals(visitId: number, referralDto: Array<ReferralDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<ReferralDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveReferrals(visitId, referralDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReferralApiControllerApi - factory interface
 * @export
 */
export const ReferralApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferralApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelReferral(id: number, options?: any): Promise<void> {
            return localVarFp.cancelReferral(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableReferrals(options?: any): Promise<Array<ReferralDto>> {
            return localVarFp.getAvailableReferrals(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralById(id: number, options?: any): Promise<ReferralDto> {
            return localVarFp.getReferralById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {Array<ReferralDto>} referralDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReferrals(visitId: number, referralDto: Array<ReferralDto>, options?: any): Promise<Array<ReferralDto>> {
            return localVarFp.saveReferrals(visitId, referralDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferralApiControllerApi - object-oriented interface
 * @export
 * @class ReferralApiControllerApi
 * @extends {BaseAPI}
 */
export class ReferralApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApiControllerApi
     */
    public cancelReferral(id: number, options?: AxiosRequestConfig) {
        return ReferralApiControllerApiFp(this.configuration).cancelReferral(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApiControllerApi
     */
    public getAvailableReferrals(options?: AxiosRequestConfig) {
        return ReferralApiControllerApiFp(this.configuration).getAvailableReferrals(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApiControllerApi
     */
    public getReferralById(id: number, options?: AxiosRequestConfig) {
        return ReferralApiControllerApiFp(this.configuration).getReferralById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {Array<ReferralDto>} referralDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApiControllerApi
     */
    public saveReferrals(visitId: number, referralDto: Array<ReferralDto>, options?: AxiosRequestConfig) {
        return ReferralApiControllerApiFp(this.configuration).saveReferrals(visitId, referralDto, options).then((request) => request(this.axios, this.basePath));
    }
}
