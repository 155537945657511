/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddVisitPackageTemplateDto } from '../model';
// @ts-ignore
import { EditVisitPackageStageDto } from '../model';
// @ts-ignore
import { EditVisitPackageTemplateDto } from '../model';
// @ts-ignore
import { VisitPackageStageDto } from '../model';
// @ts-ignore
import { VisitPackageTemplateDto } from '../model';
/**
 * AdminVisitPackageTemplateControllerApi - axios parameter creator
 * @export
 */
export const AdminVisitPackageTemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {VisitPackageStageDto} visitPackageStageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVisitPackageStage: async (id: number, visitPackageStageDto: VisitPackageStageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addVisitPackageStage', 'id', id)
            // verify required parameter 'visitPackageStageDto' is not null or undefined
            assertParamExists('addVisitPackageStage', 'visitPackageStageDto', visitPackageStageDto)
            const localVarPath = `/api/admin/visit-package-template/{id}/stage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visitPackageStageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddVisitPackageTemplateDto} addVisitPackageTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVisitPackageTemplate: async (addVisitPackageTemplateDto: AddVisitPackageTemplateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addVisitPackageTemplateDto' is not null or undefined
            assertParamExists('addVisitPackageTemplate', 'addVisitPackageTemplateDto', addVisitPackageTemplateDto)
            const localVarPath = `/api/admin/visit-package-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addVisitPackageTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVisitPackageStage: async (id: number, stageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteVisitPackageStage', 'id', id)
            // verify required parameter 'stageId' is not null or undefined
            assertParamExists('deleteVisitPackageStage', 'stageId', stageId)
            const localVarPath = `/api/admin/visit-package-template/{id}/stage/{stageId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"stageId"}}`, encodeURIComponent(String(stageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditVisitPackageStageDto} editVisitPackageStageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editVisitPackageStage: async (editVisitPackageStageDto: EditVisitPackageStageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editVisitPackageStageDto' is not null or undefined
            assertParamExists('editVisitPackageStage', 'editVisitPackageStageDto', editVisitPackageStageDto)
            const localVarPath = `/api/admin/visit-package-template/stage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editVisitPackageStageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EditVisitPackageTemplateDto} editVisitPackageTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editVisitPackageTemplate: async (id: number, editVisitPackageTemplateDto: EditVisitPackageTemplateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editVisitPackageTemplate', 'id', id)
            // verify required parameter 'editVisitPackageTemplateDto' is not null or undefined
            assertParamExists('editVisitPackageTemplate', 'editVisitPackageTemplateDto', editVisitPackageTemplateDto)
            const localVarPath = `/api/admin/visit-package-template/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editVisitPackageTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} activeOnly 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitPackageTemplates: async (activeOnly: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activeOnly' is not null or undefined
            assertParamExists('getVisitPackageTemplates', 'activeOnly', activeOnly)
            const localVarPath = `/api/admin/visit-package-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (activeOnly !== undefined) {
                localVarQueryParameter['activeOnly'] = activeOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} firstStageId 
         * @param {number} secondStageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swapOrder: async (firstStageId: number, secondStageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firstStageId' is not null or undefined
            assertParamExists('swapOrder', 'firstStageId', firstStageId)
            // verify required parameter 'secondStageId' is not null or undefined
            assertParamExists('swapOrder', 'secondStageId', secondStageId)
            const localVarPath = `/api/admin/visit-package-template/stage/{firstStageId}/swap/{secondStageId}`
                .replace(`{${"firstStageId"}}`, encodeURIComponent(String(firstStageId)))
                .replace(`{${"secondStageId"}}`, encodeURIComponent(String(secondStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminVisitPackageTemplateControllerApi - functional programming interface
 * @export
 */
export const AdminVisitPackageTemplateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminVisitPackageTemplateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {VisitPackageStageDto} visitPackageStageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addVisitPackageStage(id: number, visitPackageStageDto: VisitPackageStageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<VisitPackageStageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addVisitPackageStage(id, visitPackageStageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddVisitPackageTemplateDto} addVisitPackageTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addVisitPackageTemplate(addVisitPackageTemplateDto: AddVisitPackageTemplateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<VisitPackageTemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addVisitPackageTemplate(addVisitPackageTemplateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVisitPackageStage(id: number, stageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVisitPackageStage(id, stageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditVisitPackageStageDto} editVisitPackageStageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editVisitPackageStage(editVisitPackageStageDto: EditVisitPackageStageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<VisitPackageStageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editVisitPackageStage(editVisitPackageStageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EditVisitPackageTemplateDto} editVisitPackageTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editVisitPackageTemplate(id: number, editVisitPackageTemplateDto: EditVisitPackageTemplateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<VisitPackageTemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editVisitPackageTemplate(id, editVisitPackageTemplateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} activeOnly 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitPackageTemplates(activeOnly: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<VisitPackageTemplateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitPackageTemplates(activeOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} firstStageId 
         * @param {number} secondStageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async swapOrder(firstStageId: number, secondStageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.swapOrder(firstStageId, secondStageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminVisitPackageTemplateControllerApi - factory interface
 * @export
 */
export const AdminVisitPackageTemplateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminVisitPackageTemplateControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {VisitPackageStageDto} visitPackageStageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVisitPackageStage(id: number, visitPackageStageDto: VisitPackageStageDto, options?: any): Promise<VisitPackageStageDto> {
            return localVarFp.addVisitPackageStage(id, visitPackageStageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddVisitPackageTemplateDto} addVisitPackageTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVisitPackageTemplate(addVisitPackageTemplateDto: AddVisitPackageTemplateDto, options?: any): Promise<VisitPackageTemplateDto> {
            return localVarFp.addVisitPackageTemplate(addVisitPackageTemplateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVisitPackageStage(id: number, stageId: number, options?: any): Promise<void> {
            return localVarFp.deleteVisitPackageStage(id, stageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditVisitPackageStageDto} editVisitPackageStageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editVisitPackageStage(editVisitPackageStageDto: EditVisitPackageStageDto, options?: any): Promise<VisitPackageStageDto> {
            return localVarFp.editVisitPackageStage(editVisitPackageStageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EditVisitPackageTemplateDto} editVisitPackageTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editVisitPackageTemplate(id: number, editVisitPackageTemplateDto: EditVisitPackageTemplateDto, options?: any): Promise<VisitPackageTemplateDto> {
            return localVarFp.editVisitPackageTemplate(id, editVisitPackageTemplateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} activeOnly 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitPackageTemplates(activeOnly: boolean, options?: any): Promise<Array<VisitPackageTemplateDto>> {
            return localVarFp.getVisitPackageTemplates(activeOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} firstStageId 
         * @param {number} secondStageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swapOrder(firstStageId: number, secondStageId: number, options?: any): Promise<void> {
            return localVarFp.swapOrder(firstStageId, secondStageId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminVisitPackageTemplateControllerApi - object-oriented interface
 * @export
 * @class AdminVisitPackageTemplateControllerApi
 * @extends {BaseAPI}
 */
export class AdminVisitPackageTemplateControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {VisitPackageStageDto} visitPackageStageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitPackageTemplateControllerApi
     */
    public addVisitPackageStage(id: number, visitPackageStageDto: VisitPackageStageDto, options?: AxiosRequestConfig) {
        return AdminVisitPackageTemplateControllerApiFp(this.configuration).addVisitPackageStage(id, visitPackageStageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddVisitPackageTemplateDto} addVisitPackageTemplateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitPackageTemplateControllerApi
     */
    public addVisitPackageTemplate(addVisitPackageTemplateDto: AddVisitPackageTemplateDto, options?: AxiosRequestConfig) {
        return AdminVisitPackageTemplateControllerApiFp(this.configuration).addVisitPackageTemplate(addVisitPackageTemplateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} stageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitPackageTemplateControllerApi
     */
    public deleteVisitPackageStage(id: number, stageId: number, options?: AxiosRequestConfig) {
        return AdminVisitPackageTemplateControllerApiFp(this.configuration).deleteVisitPackageStage(id, stageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditVisitPackageStageDto} editVisitPackageStageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitPackageTemplateControllerApi
     */
    public editVisitPackageStage(editVisitPackageStageDto: EditVisitPackageStageDto, options?: AxiosRequestConfig) {
        return AdminVisitPackageTemplateControllerApiFp(this.configuration).editVisitPackageStage(editVisitPackageStageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EditVisitPackageTemplateDto} editVisitPackageTemplateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitPackageTemplateControllerApi
     */
    public editVisitPackageTemplate(id: number, editVisitPackageTemplateDto: EditVisitPackageTemplateDto, options?: AxiosRequestConfig) {
        return AdminVisitPackageTemplateControllerApiFp(this.configuration).editVisitPackageTemplate(id, editVisitPackageTemplateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} activeOnly 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitPackageTemplateControllerApi
     */
    public getVisitPackageTemplates(activeOnly: boolean, options?: AxiosRequestConfig) {
        return AdminVisitPackageTemplateControllerApiFp(this.configuration).getVisitPackageTemplates(activeOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} firstStageId 
     * @param {number} secondStageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitPackageTemplateControllerApi
     */
    public swapOrder(firstStageId: number, secondStageId: number, options?: AxiosRequestConfig) {
        return AdminVisitPackageTemplateControllerApiFp(this.configuration).swapOrder(firstStageId, secondStageId, options).then((request) => request(this.axios, this.basePath));
    }
}
