/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PrescriptionRejectionDto
 */
export interface PrescriptionRejectionDto {
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRejectionDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRejectionDto
     */
    'reason': PrescriptionRejectionDtoReasonEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PrescriptionRejectionDtoReasonEnum {
    VisitOnly = 'VISIT_ONLY',
    InquiryRequired = 'INQUIRY_REQUIRED',
    AlreadyPrescribed = 'ALREADY_PRESCRIBED',
    Other = 'OTHER'
}


