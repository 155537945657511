export default {
    AD: {
        code: "AD",
        name: "Andora"
    },
    AE: {
        code: "AE",
        name: "Zjednoczone Emiraty Arabskie"
    },
    AF: {
        code: "AF",
        name: "Afganistan"
    },
    AG: {
        code: "AG",
        name: "Antigua i Barbuda"
    },
    AI: {
        code: "AI",
        name: "Anguilla"
    },
    AL: {
        code: "AL",
        name: "Albania"
    },
    AM: {
        code: "AM",
        name: "Armenia"
    },
    AO: {
        code: "AO",
        name: "Angola"
    },
    AQ: {
        code: "AQ",
        name: "Antarktyka"
    },
    AR: {
        code: "AR",
        name: "Argentyna"
    },
    AS: {
        code: "AS",
        name: "Samoa Amerykańskie"
    },
    AT: {
        code: "AT",
        name: "Austria"
    },
    AU: {
        code: "AU",
        name: "Australia"
    },
    AW: {
        code: "AW",
        name: "Aruba"
    },
    AX: {
        code: "AX",
        name: "Wyspy Alandzkie"
    },
    AZ: {
        code: "AZ",
        name: "Azerbejdżan"
    },
    BA: {
        code: "BA",
        name: "Bośnia i Hercegowina"
    },
    BB: {
        code: "BB",
        name: "Barbados"
    },
    BD: {
        code: "BD",
        name: "Bangladesz"
    },
    BE: {
        code: "BE",
        name: "Belgia"
    },
    BF: {
        code: "BF",
        name: "Burkina Faso"
    },
    BG: {
        code: "BG",
        name: "Bułgaria"
    },
    BH: {
        code: "BH",
        name: "Bahrajn"
    },
    BI: {
        code: "BI",
        name: "Burundi"
    },
    BJ: {
        code: "BJ",
        name: "Benin"
    },
    BL: {
        code: "BL",
        name: "Saint-Barthélemy"
    },
    BM: {
        code: "BM",
        name: "Bermudy"
    },
    BN: {
        code: "BN",
        name: "Brunei"
    },
    BO: {
        code: "BO",
        name: "Boliwia"
    },
    BQ: {
        code: "BQ",
        name: "Bonaire, Sint Eustatius i Saba"
    },
    BR: {
        code: "BR",
        name: "Brazylia"
    },
    BS: {
        code: "BS",
        name: "Bahamy"
    },
    BT: {
        code: "BT",
        name: "Bhutan"
    },
    BV: {
        code: "BV",
        name: "Wyspa Bouveta"
    },
    BW: {
        code: "BW",
        name: "Botswana"
    },
    BY: {
        code: "BY",
        name: "Białoruś"
    },
    BZ: {
        code: "BZ",
        name: "Belize"
    },
    CA: {
        code: "CA",
        name: "Kanada"
    },
    CC: {
        code: "CC",
        name: "Wyspy Kokosowe"
    },
    CD: {
        code: "CD",
        name: "Demokratyczna Republika Konga"
    },
    CF: {
        code: "CF",
        name: "Republika Środkowoafrykańska"
    },
    CG: {
        code: "CG",
        name: "Kongo"
    },
    CH: {
        code: "CH",
        name: "Szwajcaria"
    },
    CI: {
        code: "CI",
        name: "Wybrzeże Kości Słoniowej"
    },
    CK: {
        code: "CK",
        name: "Wyspy Cooka"
    },
    CL: {
        code: "CL",
        name: "Chile"
    },
    CM: {
        code: "CM",
        name: "Kamerun"
    },
    CN: {
        code: "CN",
        name: "Chiny"
    },
    CO: {
        code: "CO",
        name: "Kolumbia"
    },
    CR: {
        code: "CR",
        name: "Kostaryka"
    },
    CU: {
        code: "CU",
        name: "Kuba"
    },
    CV: {
        code: "CV",
        name: "Republika Zielonego Przylądka"
    },
    CW: {
        code: "CW",
        name: "Curaçao"
    },
    CX: {
        code: "CX",
        name: "Wyspa Bożego Narodzenia"
    },
    CY: {
        code: "CY",
        name: "Cypr"
    },
    CZ: {
        code: "CZ",
        name: "Czechy"
    },
    DE: {
        code: "DE",
        name: "Niemcy"
    },
    DJ: {
        code: "DJ",
        name: "Dżibuti"
    },
    DK: {
        code: "DK",
        name: "Dania"
    },
    DM: {
        code: "DM",
        name: "Dominika"
    },
    DO: {
        code: "DO",
        name: "Dominikana"
    },
    DZ: {
        code: "DZ",
        name: "Algieria"
    },
    EC: {
        code: "EC",
        name: "Ekwador"
    },
    EE: {
        code: "EE",
        name: "Estonia"
    },
    EG: {
        code: "EG",
        name: "Egipt"
    },
    EH: {
        code: "EH",
        name: "Sahara Zachodnia"
    },
    ER: {
        code: "ER",
        name: "Erytrea"
    },
    ES: {
        code: "ES",
        name: "Hiszpania"
    },
    ET: {
        code: "ET",
        name: "Etiopia"
    },
    FI: {
        code: "FI",
        name: "Finlandia"
    },
    FJ: {
        code: "FJ",
        name: "Fidżi"
    },
    FK: {
        code: "FK",
        name: "Falklandy"
    },
    FM: {
        code: "FM",
        name: "Mikronezja"
    },
    FO: {
        code: "FO",
        name: "Wyspy Owcze"
    },
    FR: {
        code: "FR",
        name: "Francja"
    },
    GA: {
        code: "GA",
        name: "Gabon"
    },
    GB: {
        code: "GB",
        name: "Wielka Brytania"
    },
    GD: {
        code: "GD",
        name: "Grenada"
    },
    GE: {
        code: "GE",
        name: "Gruzja"
    },
    GF: {
        code: "GF",
        name: "Gujana Francuska"
    },
    GG: {
        code: "GG",
        name: "Guernsey"
    },
    GH: {
        code: "GH",
        name: "Ghana"
    },
    GI: {
        code: "GI",
        name: "Gibraltar"
    },
    GL: {
        code: "GL",
        name: "Grenlandia"
    },
    GM: {
        code: "GM",
        name: "Gambia"
    },
    GN: {
        code: "GN",
        name: "Gwinea"
    },
    GP: {
        code: "GP",
        name: "Gwadelupa"
    },
    GQ: {
        code: "GQ",
        name: "Gwinea Równikowa"
    },
    GR: {
        code: "GR",
        name: "Grecja"
    },
    GS: {
        code: "GS",
        name: "Georgia Południowa i Sandwich Południowy"
    },
    GT: {
        code: "GT",
        name: "Gwatemala"
    },
    GU: {
        code: "GU",
        name: "Guam"
    },
    GW: {
        code: "GW",
        name: "Gwinea Bissau"
    },
    GY: {
        code: "GY",
        name: "Gujana"
    },
    HK: {
        code: "HK",
        name: "Hongkong"
    },
    HM: {
        code: "HM",
        name: "Wyspy Heard i McDonalda"
    },
    HN: {
        code: "HN",
        name: "Honduras"
    },
    HR: {
        code: "HR",
        name: "Chorwacja"
    },
    HT: {
        code: "HT",
        name: "Haiti"
    },
    HU: {
        code: "HU",
        name: "Węgry"
    },
    ID: {
        code: "ID",
        name: "Indonezja"
    },
    IE: {
        code: "IE",
        name: "Irlandia"
    },
    IL: {
        code: "IL",
        name: "Izrael"
    },
    IM: {
        code: "IM",
        name: "Wyspa Man"
    },
    IN: {
        code: "IN",
        name: "Indie"
    },
    IO: {
        code: "IO",
        name: "Brytyjskie Terytorium Oceanu Indyjskiego"
    },
    IQ: {
        code: "IQ",
        name: "Irak"
    },
    IR: {
        code: "IR",
        name: "Iran"
    },
    IS: {
        code: "IS",
        name: "Islandia"
    },
    IT: {
        code: "IT",
        name: "Włochy"
    },
    JE: {
        code: "JE",
        name: "Jersey"
    },
    JM: {
        code: "JM",
        name: "Jamajka"
    },
    JO: {
        code: "JO",
        name: "Jordania"
    },
    JP: {
        code: "JP",
        name: "Japonia"
    },
    KE: {
        code: "KE",
        name: "Kenia"
    },
    KG: {
        code: "KG",
        name: "Kirgistan"
    },
    KH: {
        code: "KH",
        name: "Kambodża"
    },
    KI: {
        code: "KI",
        name: "Kiribati"
    },
    KM: {
        code: "KM",
        name: "Komory"
    },
    KN: {
        code: "KN",
        name: "Saint Kitts i Nevis"
    },
    KP: {
        code: "KP",
        name: "Korea Północna"
    },
    KR: {
        code: "KR",
        name: "Korea Południowa"
    },
    KW: {
        code: "KW",
        name: "Kuwejt"
    },
    KY: {
        code: "KY",
        name: "Kajmany"
    },
    KZ: {
        code: "KZ",
        name: "Kazachstan"
    },
    LA: {
        code: "LA",
        name: "Laos"
    },
    LB: {
        code: "LB",
        name: "Liban"
    },
    LC: {
        code: "LC",
        name: "Saint Lucia"
    },
    LI: {
        code: "LI",
        name: "Liechtenstein"
    },
    LK: {
        code: "LK",
        name: "Sri Lanka"
    },
    LR: {
        code: "LR",
        name: "Liberia"
    },
    LS: {
        code: "LS",
        name: "Lesotho"
    },
    LT: {
        code: "LT",
        name: "Litwa"
    },
    LU: {
        code: "LU",
        name: "Luksemburg"
    },
    LV: {
        code: "LV",
        name: "Łotwa"
    },
    LY: {
        code: "LY",
        name: "Libia"
    },
    MA: {
        code: "MA",
        name: "Maroko"
    },
    MC: {
        code: "MC",
        name: "Monako"
    },
    MD: {
        code: "MD",
        name: "Mołdawia"
    },
    ME: {
        code: "ME",
        name: "Czarnogóra"
    },
    MF: {
        code: "MF",
        name: "Saint-Martin"
    },
    MG: {
        code: "MG",
        name: "Madagaskar"
    },
    MH: {
        code: "MH",
        name: "Wyspy Marshalla"
    },
    MK: {
        code: "MK",
        name: "Macedonia"
    },
    ML: {
        code: "ML",
        name: "Mali"
    },
    MM: {
        code: "MM",
        name: "Mjanma"
    },
    MN: {
        code: "MN",
        name: "Mongolia"
    },
    MO: {
        code: "MO",
        name: "Makau"
    },
    MP: {
        code: "MP",
        name: "Mariany Północne"
    },
    MQ: {
        code: "MQ",
        name: "Martynika"
    },
    MR: {
        code: "MR",
        name: "Mauretania"
    },
    MS: {
        code: "MS",
        name: "Montserrat"
    },
    MT: {
        code: "MT",
        name: "Malta"
    },
    MU: {
        code: "MU",
        name: "Mauritius"
    },
    MV: {
        code: "MV",
        name: "Malediwy"
    },
    MW: {
        code: "MW",
        name: "Malawi"
    },
    MX: {
        code: "MX",
        name: "Meksyk"
    },
    MY: {
        code: "MY",
        name: "Malezja"
    },
    MZ: {
        code: "MZ",
        name: "Mozambik"
    },
    NA: {
        code: "NA",
        name: "Namibia"
    },
    NC: {
        code: "NC",
        name: "Nowa Kaledonia"
    },
    NE: {
        code: "NE",
        name: "Niger"
    },
    NF: {
        code: "NF",
        name: "Norfolk"
    },
    NG: {
        code: "NG",
        name: "Nigeria"
    },
    NI: {
        code: "NI",
        name: "Nikaragua"
    },
    NL: {
        code: "NL",
        name: "Holandia"
    },
    NO: {
        code: "NO",
        name: "Norwegia"
    },
    NP: {
        code: "NP",
        name: "Nepal"
    },
    NR: {
        code: "NR",
        name: "Nauru"
    },
    NU: {
        code: "NU",
        name: "Niue"
    },
    NZ: {
        code: "NZ",
        name: "Nowa Zelandia"
    },
    OM: {
        code: "OM",
        name: "Oman"
    },
    PA: {
        code: "PA",
        name: "Panama"
    },
    PE: {
        code: "PE",
        name: "Peru"
    },
    PF: {
        code: "PF",
        name: "Polinezja Francuska"
    },
    PG: {
        code: "PG",
        name: "Papua-Nowa Gwinea"
    },
    PH: {
        code: "PH",
        name: "Filipiny"
    },
    PK: {
        code: "PK",
        name: "Pakistan"
    },
    PL: {
        code: "PL",
        name: "Polska"
    },
    PM: {
        code: "PM",
        name: "Saint-Pierre i Miquelon"
    },
    PN: {
        code: "PN",
        name: "Pitcairn"
    },
    PR: {
        code: "PR",
        name: "Portoryko"
    },
    PS: {
        code: "PS",
        name: "Palestyna"
    },
    PT: {
        code: "PT",
        name: "Portugalia"
    },
    PW: {
        code: "PW",
        name: "Palau"
    },
    PY: {
        code: "PY",
        name: "Paragwaj"
    },
    QA: {
        code: "QA",
        name: "Katar"
    },
    RE: {
        code: "RE",
        name: "Reunion"
    },
    RO: {
        code: "RO",
        name: "Rumunia"
    },
    RS: {
        code: "RS",
        name: "Serbia"
    },
    RU: {
        code: "RU",
        name: "Rosja"
    },
    RW: {
        code: "RW",
        name: "Rwanda"
    },
    SA: {
        code: "SA",
        name: "Arabia Saudyjska"
    },
    SB: {
        code: "SB",
        name: "Wyspy Salomona"
    },
    SC: {
        code: "SC",
        name: "Seszele"
    },
    SD: {
        code: "SD",
        name: "Sudan"
    },
    SE: {
        code: "SE",
        name: "Szwecja"
    },
    SG: {
        code: "SG",
        name: "Singapur"
    },
    SH: {
        code: "SH",
        name: "Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha"
    },
    SI: {
        code: "SI",
        name: "Słowenia"
    },
    SJ: {
        code: "SJ",
        name: "Svalbard i Jan Mayen"
    },
    SK: {
        code: "SK",
        name: "Słowacja"
    },
    SL: {
        code: "SL",
        name: "Sierra Leone"
    },
    SM: {
        code: "SM",
        name: "San Marino"
    },
    SN: {
        code: "SN",
        name: "Senegal"
    },
    SO: {
        code: "SO",
        name: "Somalia"
    },
    SR: {
        code: "SR",
        name: "Surinam"
    },
    SS: {
        code: "SS",
        name: "Sudan Południowy"
    },
    ST: {
        code: "ST",
        name: "Wyspy Świętego Tomasza i Książęca"
    },
    SV: {
        code: "SV",
        name: "Salwador"
    },
    SX: {
        code: "SX",
        name: "Sint Maarten"
    },
    SY: {
        code: "SY",
        name: "Syria"
    },
    SZ: {
        code: "SZ",
        name: "Suazi"
    },
    TC: {
        code: "TC",
        name: "Turks i Caicos"
    },
    TD: {
        code: "TD",
        name: "Czad"
    },
    TF: {
        code: "TF",
        name: "Francuskie Terytoria Południowe i Antarktyczne"
    },
    TG: {
        code: "TG",
        name: "Togo"
    },
    TH: {
        code: "TH",
        name: "Tajlandia"
    },
    TJ: {
        code: "TJ",
        name: "Tadżykistan"
    },
    TK: {
        code: "TK",
        name: "Tokelau"
    },
    TL: {
        code: "TL",
        name: "Timor Wschodni"
    },
    TM: {
        code: "TM",
        name: "Turkmenistan"
    },
    TN: {
        code: "TN",
        name: "Tunezja"
    },
    TO: {
        code: "TO",
        name: "Tonga"
    },
    TR: {
        code: "TR",
        name: "Turcja"
    },
    TT: {
        code: "TT",
        name: "Trynidad i Tobago"
    },
    TV: {
        code: "TV",
        name: "Tuvalu"
    },
    TW: {
        code: "TW",
        name: "Tajwan"
    },
    TZ: {
        code: "TZ",
        name: "Tanzania"
    },
    UA: {
        code: "UA",
        name: "Ukraina"
    },
    UG: {
        code: "UG",
        name: "Uganda"
    },
    UM: {
        code: "UM",
        name: "Dalekie Wyspy Mniejsze Stanów Zjednoczonych"
    },
    US: {
        code: "US",
        name: "Stany Zjednoczone"
    },
    UY: {
        code: "UY",
        name: "Urugwaj"
    },
    UZ: {
        code: "UZ",
        name: "Uzbekistan"
    },
    VA: {
        code: "VA",
        name: "Watykan"
    },
    VC: {
        code: "VC",
        name: "Saint Vincent i Grenadyny"
    },
    VE: {
        code: "VE",
        name: "Wenezuela"
    },
    VG: {
        code: "VG",
        name: "Brytyjskie Wyspy Dziewicze"
    },
    VI: {
        code: "VI",
        name: "Wyspy Dziewicze Stanów Zjednoczonych"
    },
    VN: {
        code: "VN",
        name: "Wietnam"
    },
    VU: {
        code: "VU",
        name: "Vanuatu"
    },
    WF: {
        code: "WF",
        name: "Wallis i Futuna"
    },
    WS: {
        code: "WS",
        name: "Samoa"
    },
    YE: {
        code: "YE",
        name: "Jemen"
    },
    YT: {
        code: "YT",
        name: "Majotta"
    },
    ZA: {
        code: "ZA",
        name: "Południowa Afryka"
    },
    ZM: {
        code: "ZM",
        name: "Zambia"
    },
    ZW: {
        code: "ZW",
        name: "Zimbabwe"
    }
}
