/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SurveyCalendarDto } from '../model';
// @ts-ignore
import { VisitSurveyDto } from '../model';
/**
 * VisitSurveyApiControllerApi - axios parameter creator
 * @export
 */
export const VisitSurveyApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitSurvey: async (visitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('getVisitSurvey', 'visitId', visitId)
            const localVarPath = `/api/visit-survey/{visitId}`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitSurveySummaries: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getVisitSurveySummaries', 'patientId', patientId)
            const localVarPath = `/api/visit-survey/{patientId}/summary`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VisitSurveyDto} visitSurveyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVisitSurvey: async (visitSurveyDto: VisitSurveyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitSurveyDto' is not null or undefined
            assertParamExists('saveVisitSurvey', 'visitSurveyDto', visitSurveyDto)
            const localVarPath = `/api/visit-survey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visitSurveyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VisitSurveyDto} visitSurveyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVisitSurvey: async (id: number, visitSurveyDto: VisitSurveyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateVisitSurvey', 'id', id)
            // verify required parameter 'visitSurveyDto' is not null or undefined
            assertParamExists('updateVisitSurvey', 'visitSurveyDto', visitSurveyDto)
            const localVarPath = `/api/visit-survey/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visitSurveyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VisitSurveyApiControllerApi - functional programming interface
 * @export
 */
export const VisitSurveyApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VisitSurveyApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitSurvey(visitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<VisitSurveyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitSurvey(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitSurveySummaries(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<SurveyCalendarDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitSurveySummaries(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VisitSurveyDto} visitSurveyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveVisitSurvey(visitSurveyDto: VisitSurveyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveVisitSurvey(visitSurveyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {VisitSurveyDto} visitSurveyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVisitSurvey(id: number, visitSurveyDto: VisitSurveyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVisitSurvey(id, visitSurveyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VisitSurveyApiControllerApi - factory interface
 * @export
 */
export const VisitSurveyApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VisitSurveyApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitSurvey(visitId: number, options?: any): Promise<VisitSurveyDto> {
            return localVarFp.getVisitSurvey(visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitSurveySummaries(patientId: number, options?: any): Promise<Array<SurveyCalendarDto>> {
            return localVarFp.getVisitSurveySummaries(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VisitSurveyDto} visitSurveyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVisitSurvey(visitSurveyDto: VisitSurveyDto, options?: any): Promise<number> {
            return localVarFp.saveVisitSurvey(visitSurveyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VisitSurveyDto} visitSurveyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVisitSurvey(id: number, visitSurveyDto: VisitSurveyDto, options?: any): Promise<void> {
            return localVarFp.updateVisitSurvey(id, visitSurveyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VisitSurveyApiControllerApi - object-oriented interface
 * @export
 * @class VisitSurveyApiControllerApi
 * @extends {BaseAPI}
 */
export class VisitSurveyApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} visitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitSurveyApiControllerApi
     */
    public getVisitSurvey(visitId: number, options?: AxiosRequestConfig) {
        return VisitSurveyApiControllerApiFp(this.configuration).getVisitSurvey(visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitSurveyApiControllerApi
     */
    public getVisitSurveySummaries(patientId: number, options?: AxiosRequestConfig) {
        return VisitSurveyApiControllerApiFp(this.configuration).getVisitSurveySummaries(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VisitSurveyDto} visitSurveyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitSurveyApiControllerApi
     */
    public saveVisitSurvey(visitSurveyDto: VisitSurveyDto, options?: AxiosRequestConfig) {
        return VisitSurveyApiControllerApiFp(this.configuration).saveVisitSurvey(visitSurveyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VisitSurveyDto} visitSurveyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitSurveyApiControllerApi
     */
    public updateVisitSurvey(id: number, visitSurveyDto: VisitSurveyDto, options?: AxiosRequestConfig) {
        return VisitSurveyApiControllerApiFp(this.configuration).updateVisitSurvey(id, visitSurveyDto, options).then((request) => request(this.axios, this.basePath));
    }
}
