/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ComorbiditiesDto } from './comorbidities-dto';
import { PerinatalInterviewDto } from './perinatal-interview-dto';
import { PsychomotorDevelopmentDto } from './psychomotor-development-dto';
import { SurveyZeroNoteSeizureDto } from './survey-zero-note-seizure-dto';
import { SurveyZeroNoteSiblingDto } from './survey-zero-note-sibling-dto';

/**
 * 
 * @export
 * @interface SurveyZeroNoteDto
 */
export interface SurveyZeroNoteDto {
    /**
     * 
     * @type {ComorbiditiesDto}
     * @memberof SurveyZeroNoteDto
     */
    'comorbidities'?: ComorbiditiesDto;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyZeroNoteDto
     */
    'ctCompleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SurveyZeroNoteDto
     */
    'ctCompletedDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyZeroNoteDto
     */
    'eegCompleted'?: SurveyZeroNoteDtoEegCompletedEnum;
    /**
     * 
     * @type {string}
     * @memberof SurveyZeroNoteDto
     */
    'eegCompletedDescription'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyZeroNoteDto
     */
    'geneticPredisposition'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SurveyZeroNoteDto
     */
    'geneticPredispositionDescription'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyZeroNoteDto
     */
    'geneticResearch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SurveyZeroNoteDto
     */
    'geneticResearchDescription'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyZeroNoteDto
     */
    'hasSiblings'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SurveyZeroNoteDto
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyZeroNoteDto
     */
    'mriCompleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SurveyZeroNoteDto
     */
    'mriCompletedDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyZeroNoteDto
     */
    'notes'?: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyZeroNoteDto
     */
    'patientId'?: number;
    /**
     * 
     * @type {PerinatalInterviewDto}
     * @memberof SurveyZeroNoteDto
     */
    'perinatalInterview'?: PerinatalInterviewDto;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyZeroNoteDto
     */
    'perinatalInterviewUnimportant'?: boolean;
    /**
     * 
     * @type {PsychomotorDevelopmentDto}
     * @memberof SurveyZeroNoteDto
     */
    'psychomotorDevelopment'?: PsychomotorDevelopmentDto;
    /**
     * 
     * @type {Array<SurveyZeroNoteSeizureDto>}
     * @memberof SurveyZeroNoteDto
     */
    'seizures': Array<SurveyZeroNoteSeizureDto>;
    /**
     * 
     * @type {Array<SurveyZeroNoteSiblingDto>}
     * @memberof SurveyZeroNoteDto
     */
    'siblings': Array<SurveyZeroNoteSiblingDto>;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyZeroNoteDto
     */
    'specialDiet'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SurveyZeroNoteDto
     */
    'specialDietDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyZeroNoteDto
     */
    'surveyCompleted'?: SurveyZeroNoteDtoSurveyCompletedEnum;
    /**
     * 
     * @type {string}
     * @memberof SurveyZeroNoteDto
     */
    'surveyCompletedDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyZeroNoteDto
     */
    'timeCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyZeroNoteDto
     */
    'timeModified'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyZeroNoteDto
     */
    'treatment'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SurveyZeroNoteDto
     */
    'treatmentDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyZeroNoteDto
     */
    'userCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyZeroNoteDto
     */
    'userModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyZeroNoteDto
     */
    'visitPurpose'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SurveyZeroNoteDtoEegCompletedEnum {
    Yes = 'YES',
    No = 'NO',
    BeforeNext = 'BEFORE_NEXT'
}
/**
    * @export
    * @enum {string}
    */
export enum SurveyZeroNoteDtoSurveyCompletedEnum {
    Yes = 'YES',
    No = 'NO',
    Partially = 'PARTIALLY'
}


