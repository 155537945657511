/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { PatientDto } from './patient-dto';
import { VisitContentDto } from './visit-content-dto';

/**
 * 
 * @export
 * @interface VisitConsultationDto
 */
export interface VisitConsultationDto {
    /**
     * 
     * @type {VisitContentDto}
     * @memberof VisitConsultationDto
     */
    'content'?: VisitContentDto;
    /**
     * 
     * @type {boolean}
     * @memberof VisitConsultationDto
     */
    'done'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VisitConsultationDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof VisitConsultationDto
     */
    'leadDoctorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof VisitConsultationDto
     */
    'medicalService'?: VisitConsultationDtoMedicalServiceEnum;
    /**
     * 
     * @type {PatientDto}
     * @memberof VisitConsultationDto
     */
    'patient'?: PatientDto;
    /**
     * 
     * @type {number}
     * @memberof VisitConsultationDto
     */
    'patientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof VisitConsultationDto
     */
    'patientPublicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitConsultationDto
     */
    'timeFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitConsultationDto
     */
    'timeTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitConsultationDto
     */
    'visitPackageCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitConsultationDto
     */
    'visitType'?: VisitConsultationDtoVisitTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum VisitConsultationDtoMedicalServiceEnum {
    Visit0 = 'VISIT_0',
    Visit1 = 'VISIT_1',
    StandardVisit = 'STANDARD_VISIT',
    FollowupVisit = 'FOLLOWUP_VISIT',
    Message = 'MESSAGE',
    Prescription = 'PRESCRIPTION',
    AbsentConsultation = 'ABSENT_CONSULTATION',
    NeurosurgeonConsultation = 'NEUROSURGEON_CONSULTATION',
    NeuropsychologistConsultation = 'NEUROPSYCHOLOGIST_CONSULTATION',
    NeurologopedistConsultation = 'NEUROLOGOPEDIST_CONSULTATION',
    PsychiatristConsultation = 'PSYCHIATRIST_CONSULTATION',
    GeneticistConsultation = 'GENETICIST_CONSULTATION',
    EpileptologistConsultation = 'EPILEPTOLOGIST_CONSULTATION',
    NeurologistConsultation = 'NEUROLOGIST_CONSULTATION',
    DieticianConsultation = 'DIETICIAN_CONSULTATION',
    VnsConsultation = 'VNS_CONSULTATION',
    MetabolicConsultation = 'METABOLIC_CONSULTATION',
    SleepDisturbanceConsultation = 'SLEEP_DISTURBANCE_CONSULTATION',
    CaseConference = 'CASE_CONFERENCE',
    EegConsultation = 'EEG_CONSULTATION',
    VeegNightConsultation = 'VEEG_NIGHT_CONSULTATION',
    VeegDayConsultation = 'VEEG_DAY_CONSULTATION',
    VeegOnStandbyConsultation1H = 'VEEG_ON_STANDBY_CONSULTATION_1H',
    VeegOnStandbyConsultation2H = 'VEEG_ON_STANDBY_CONSULTATION_2H',
    VeegOnStandbyConsultation6H = 'VEEG_ON_STANDBY_CONSULTATION_6H',
    VeegDuringSleepConsultation1H = 'VEEG_DURING_SLEEP_CONSULTATION_1H',
    VeegDuringSleepConsultation2H = 'VEEG_DURING_SLEEP_CONSULTATION_2H',
    VeegDuringSleepConsultation6H = 'VEEG_DURING_SLEEP_CONSULTATION_6H',
    MigrationVisit = 'MIGRATION_VISIT',
    VeegOnStandbyConsultation = 'VEEG_ON_STANDBY_CONSULTATION',
    VeegDuringSleepConsultation = 'VEEG_DURING_SLEEP_CONSULTATION',
    PartnerConsultation = 'PARTNER_CONSULTATION'
}
/**
    * @export
    * @enum {string}
    */
export enum VisitConsultationDtoVisitTypeEnum {
    Remote = 'REMOTE',
    Stationary = 'STATIONARY',
    Absent = 'ABSENT'
}


