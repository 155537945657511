export default {
  "AD": {
    "code": "AD",
    "name": "Andorra"
  },
  "AE": {
    "code": "AE",
    "name": "Egyesült Arab Emírségek"
  },
  "AF": {
    "code": "AF",
    "name": "Afganisztán"
  },
  "AG": {
    "code": "AG",
    "name": "Antigua és Barbuda"
  },
  "AI": {
    "code": "AI",
    "name": "Anguilla"
  },
  "AL": {
    "code": "AL",
    "name": "Albánia"
  },
  "AM": {
    "code": "AM",
    "name": "Örményország"
  },
  "AO": {
    "code": "AO",
    "name": "Angola"
  },
  "AQ": {
    "code": "AQ",
    "name": "Antarktisz"
  },
  "AR": {
    "code": "AR",
    "name": "Argentína"
  },
  "AS": {
    "code": "AS",
    "name": "Amerikai Szamoa"
  },
  "AT": {
    "code": "AT",
    "name": "Ausztria"
  },
  "AU": {
    "code": "AU",
    "name": "Ausztrália"
  },
  "AW": {
    "code": "AW",
    "name": "Aruba"
  },
  "AX": {
    "code": "AX",
    "name": "szárazföldi szigetek"
  },
  "AZ": {
    "code": "AZ",
    "name": "Azerbajdzsán"
  },
  "BA": {
    "code": "BA",
    "name": "Bosznia és Hercegovina"
  },
  "BB": {
    "code": "BB",
    "name": "Barbados"
  },
  "BD": {
    "code": "BD",
    "name": "Banglades"
  },
  "BE": {
    "code": "BE",
    "name": "Belgium"
  },
  "BF": {
    "code": "BF",
    "name": "Burkina Faso"
  },
  "BG": {
    "code": "BG",
    "name": "Bulgária"
  },
  "BH": {
    "code": "BH",
    "name": "Bahrein"
  },
  "BI": {
    "code": "BI",
    "name": "Burundi"
  },
  "BJ": {
    "code": "BJ",
    "name": "Benin"
  },
  "BL": {
    "code": "BL",
    "name": "Szent Barthélemy"
  },
  "BM": {
    "code": "BM",
    "name": "Bermuda"
  },
  "BN": {
    "code": "BN",
    "name": "Brunei Darussalam"
  },
  "BO": {
    "code": "BO",
    "name": "Bolívia, többnemzetiségű állam"
  },
  "BQ": {
    "code": "BQ",
    "name": "Bonaire, Sint Eustatius és Saba"
  },
  "BR": {
    "code": "BR",
    "name": "Brazília"
  },
  "BS": {
    "code": "BS",
    "name": "Bahamák"
  },
  "BT": {
    "code": "BT",
    "name": "Bhután"
  },
  "BV": {
    "code": "BV",
    "name": "Bouvet-sziget"
  },
  "BW": {
    "code": "BW",
    "name": "Botswana"
  },
  "BY": {
    "code": "BY",
    "name": "Fehéroroszország"
  },
  "BZ": {
    "code": "BZ",
    "name": "Belize"
  },
  "CA": {
    "code": "CA",
    "name": "Kanada"
  },
  "CC": {
    "code": "CC",
    "name": "Kókusz (Keeling)-szigetek"
  },
  "CD": {
    "code": "CD",
    "name": "Kongó, a Demokratikus Köztársaság"
  },
  "CF": {
    "code": "CF",
    "name": "Közép-Afrikai Köztársaság"
  },
  "CG": {
    "code": "CG",
    "name": "Kongó"
  },
  "CH": {
    "code": "CH",
    "name": "Svájc"
  },
  "CI": {
    "code": "CI",
    "name": "Elefántcsontpart"
  },
  "CK": {
    "code": "CK",
    "name": "Cook-szigetek"
  },
  "CL": {
    "code": "CL",
    "name": "Chile"
  },
  "CM": {
    "code": "CM",
    "name": "Kamerun"
  },
  "CN": {
    "code": "CN",
    "name": "Kína"
  },
  "CO": {
    "code": "CO",
    "name": "Colombia"
  },
  "CR": {
    "code": "CR",
    "name": "Costa Rica"
  },
  "CU": {
    "code": "CU",
    "name": "Kuba"
  },
  "CV": {
    "code": "CV",
    "name": "zöld-fok"
  },
  "CW": {
    "code": "CW",
    "name": "Curaçao"
  },
  "CX": {
    "code": "CX",
    "name": "Karácsony-sziget"
  },
  "CY": {
    "code": "CY",
    "name": "Ciprus"
  },
  "CZ": {
    "code": "CZ",
    "name": "Cseh Köztársaság"
  },
  "DE": {
    "code": "DE",
    "name": "Németország"
  },
  "DJ": {
    "code": "DJ",
    "name": "Dzsibuti"
  },
  "DK": {
    "code": "DK",
    "name": "Dánia"
  },
  "DM": {
    "code": "DM",
    "name": "Dominika"
  },
  "DO": {
    "code": "DO",
    "name": "Dominikai Köztársaság"
  },
  "DZ": {
    "code": "DZ",
    "name": "Algéria"
  },
  "EC": {
    "code": "EC",
    "name": "Ecuador"
  },
  "EE": {
    "code": "EE",
    "name": "Észtország"
  },
  "EG": {
    "code": "EG",
    "name": "Egyiptom"
  },
  "EH": {
    "code": "EH",
    "name": "nyugat-Szahara"
  },
  "ER": {
    "code": "ER",
    "name": "Eritrea"
  },
  "ES": {
    "code": "ES",
    "name": "Spanyolország"
  },
  "ET": {
    "code": "ET",
    "name": "Etiópia"
  },
  "FI": {
    "code": "FI",
    "name": "Finnország"
  },
  "FJ": {
    "code": "FJ",
    "name": "Fidzsi-szigetek"
  },
  "FK": {
    "code": "FK",
    "name": "Falkland-szigetek (Malvinas)"
  },
  "FM": {
    "code": "FM",
    "name": "Mikronézia, Szövetségi Államok"
  },
  "FO": {
    "code": "FO",
    "name": "Faroe Szigetek"
  },
  "FR": {
    "code": "FR",
    "name": "Franciaország"
  },
  "GA": {
    "code": "GA",
    "name": "Gabon"
  },
  "GB": {
    "code": "GB",
    "name": "Egyesült Királyság"
  },
  "GD": {
    "code": "GD",
    "name": "Grenada"
  },
  "GE": {
    "code": "GE",
    "name": "Grúzia"
  },
  "GF": {
    "code": "GF",
    "name": "Francia Guyana"
  },
  "GG": {
    "code": "GG",
    "name": "Guernsey"
  },
  "GH": {
    "code": "GH",
    "name": "Ghána"
  },
  "GI": {
    "code": "GI",
    "name": "Gibraltár"
  },
  "GL": {
    "code": "GL",
    "name": "Grönland"
  },
  "GM": {
    "code": "GM",
    "name": "Gambia"
  },
  "GN": {
    "code": "GN",
    "name": "Guinea"
  },
  "GP": {
    "code": "GP",
    "name": "Guadeloupe"
  },
  "GQ": {
    "code": "GQ",
    "name": "Egyenlítői-Guinea"
  },
  "GR": {
    "code": "GR",
    "name": "Görögország"
  },
  "GS": {
    "code": "GS",
    "name": "Dél-Georgia és a Déli Sandwich-szigetek"
  },
  "GT": {
    "code": "GT",
    "name": "Guatemala"
  },
  "GU": {
    "code": "GU",
    "name": "Guam"
  },
  "GW": {
    "code": "GW",
    "name": "Bissau-Guinea"
  },
  "GY": {
    "code": "GY",
    "name": "Guyana"
  },
  "HK": {
    "code": "HK",
    "name": "Hong Kong"
  },
  "HM": {
    "code": "HM",
    "name": "Heard-sziget és McDonald-szigetek"
  },
  "HN": {
    "code": "HN",
    "name": "Honduras"
  },
  "HR": {
    "code": "HR",
    "name": "Horvátország"
  },
  "HT": {
    "code": "HT",
    "name": "Haiti"
  },
  "HU": {
    "code": "HU",
    "name": "Magyarország"
  },
  "ID": {
    "code": "ID",
    "name": "Indonézia"
  },
  "IE": {
    "code": "IE",
    "name": "Írország"
  },
  "IL": {
    "code": "IL",
    "name": "Izrael"
  },
  "IM": {
    "code": "IM",
    "name": "Man-sziget"
  },
  "IN": {
    "code": "IN",
    "name": "India"
  },
  "IO": {
    "code": "IO",
    "name": "Brit Indiai-óceáni Terület"
  },
  "IQ": {
    "code": "IQ",
    "name": "Irak"
  },
  "IR": {
    "code": "IR",
    "name": "Irán, Iszlám Köztársaság"
  },
  "IS": {
    "code": "IS",
    "name": "Izland"
  },
  "IT": {
    "code": "IT",
    "name": "Olaszország"
  },
  "JE": {
    "code": "JE",
    "name": "Jersey"
  },
  "JM": {
    "code": "JM",
    "name": "Jamaica"
  },
  "JO": {
    "code": "JO",
    "name": "Jordánia"
  },
  "JP": {
    "code": "JP",
    "name": "Japán"
  },
  "KE": {
    "code": "KE",
    "name": "Kenya"
  },
  "KG": {
    "code": "KG",
    "name": "Kirgizisztán"
  },
  "KH": {
    "code": "KH",
    "name": "Kambodzsa"
  },
  "KI": {
    "code": "KI",
    "name": "Kiribati"
  },
  "KM": {
    "code": "KM",
    "name": "Comore-szigetek"
  },
  "KN": {
    "code": "KN",
    "name": "Saint Kitts és Nevis"
  },
  "KP": {
    "code": "KP",
    "name": "Korea, Népi Demokratikus Köztársaság"
  },
  "KR": {
    "code": "KR",
    "name": "Koreai Köztársaság"
  },
  "KW": {
    "code": "KW",
    "name": "Kuvait"
  },
  "KY": {
    "code": "KY",
    "name": "Kajmán-szigetek"
  },
  "KZ": {
    "code": "KZ",
    "name": "Kazahsztán"
  },
  "LA": {
    "code": "LA",
    "name": "Laoszi Népi Demokratikus Köztársaság"
  },
  "LB": {
    "code": "LB",
    "name": "Libanon"
  },
  "LC": {
    "code": "LC",
    "name": "Szent Lucia"
  },
  "LI": {
    "code": "LI",
    "name": "Liechtenstein"
  },
  "LK": {
    "code": "LK",
    "name": "Sri Lanka"
  },
  "LR": {
    "code": "LR",
    "name": "Libéria"
  },
  "LS": {
    "code": "LS",
    "name": "Lesotho"
  },
  "LT": {
    "code": "LT",
    "name": "Litvánia"
  },
  "LU": {
    "code": "LU",
    "name": "Luxemburg"
  },
  "LV": {
    "code": "LV",
    "name": "Lettország"
  },
  "LY": {
    "code": "LY",
    "name": "Líbia"
  },
  "MA": {
    "code": "MA",
    "name": "Marokkó"
  },
  "MC": {
    "code": "MC",
    "name": "Monaco"
  },
  "MD": {
    "code": "MD",
    "name": "Moldova, Köztársaság"
  },
  "ME": {
    "code": "ME",
    "name": "Montenegró"
  },
  "MF": {
    "code": "MF",
    "name": "Saint Martin (francia rész)"
  },
  "MG": {
    "code": "MG",
    "name": "Madagaszkár"
  },
  "MH": {
    "code": "MH",
    "name": "Marshall-szigetek"
  },
  "MK": {
    "code": "MK",
    "name": "Macedónia, a Volt Jugoszláv Köztársaság"
  },
  "ML": {
    "code": "ML",
    "name": "Mali"
  },
  "MM": {
    "code": "MM",
    "name": "Mianmar"
  },
  "MN": {
    "code": "MN",
    "name": "Mongólia"
  },
  "MO": {
    "code": "MO",
    "name": "Makaó"
  },
  "MP": {
    "code": "MP",
    "name": "Észak Mariana szigetek"
  },
  "MQ": {
    "code": "MQ",
    "name": "Martinique"
  },
  "MR": {
    "code": "MR",
    "name": "Mauritánia"
  },
  "MS": {
    "code": "MS",
    "name": "Montserrat"
  },
  "MT": {
    "code": "MT",
    "name": "Málta"
  },
  "MU": {
    "code": "MU",
    "name": "Mauritius"
  },
  "MV": {
    "code": "MV",
    "name": "Maldív-szigetek"
  },
  "MW": {
    "code": "MW",
    "name": "Malawi"
  },
  "MX": {
    "code": "MX",
    "name": "Mexikó"
  },
  "MY": {
    "code": "MY",
    "name": "Malaysia"
  },
  "MZ": {
    "code": "MZ",
    "name": "Mozambik"
  },
  "NA": {
    "code": "NA",
    "name": "Namíbia"
  },
  "NC": {
    "code": "NC",
    "name": "Új-Kaledónia"
  },
  "NE": {
    "code": "NE",
    "name": "Niger"
  },
  "NF": {
    "code": "NF",
    "name": "Norfolk-sziget"
  },
  "NG": {
    "code": "NG",
    "name": "Nigéria"
  },
  "NI": {
    "code": "NI",
    "name": "Nicaragua"
  },
  "NL": {
    "code": "NL",
    "name": "Hollandia"
  },
  "NO": {
    "code": "NO",
    "name": "Norvégia"
  },
  "NP": {
    "code": "NP",
    "name": "Nepál"
  },
  "NR": {
    "code": "NR",
    "name": "Nauru"
  },
  "NU": {
    "code": "NU",
    "name": "Niue"
  },
  "NZ": {
    "code": "NZ",
    "name": "Új Zéland"
  },
  "OM": {
    "code": "OM",
    "name": "Omán"
  },
  "PA": {
    "code": "PA",
    "name": "Panama"
  },
  "PE": {
    "code": "PE",
    "name": "Peru"
  },
  "PF": {
    "code": "PF",
    "name": "Francia Polinézia"
  },
  "PG": {
    "code": "PG",
    "name": "Pápua Új-Guinea"
  },
  "PH": {
    "code": "PH",
    "name": "Fülöp-szigetek"
  },
  "PK": {
    "code": "PK",
    "name": "Pakisztán"
  },
  "PL": {
    "code": "PL",
    "name": "Lengyelország"
  },
  "PM": {
    "code": "PM",
    "name": "Saint Pierre és Miquelon"
  },
  "PN": {
    "code": "PN",
    "name": "Pitcairn"
  },
  "PR": {
    "code": "PR",
    "name": "Puerto Rico"
  },
  "PS": {
    "code": "PS",
    "name": "Palesztina, állam"
  },
  "PT": {
    "code": "PT",
    "name": "Portugália"
  },
  "PW": {
    "code": "PW",
    "name": "Palau"
  },
  "PY": {
    "code": "PY",
    "name": "Paraguay"
  },
  "QA": {
    "code": "QA",
    "name": "Katar"
  },
  "RE": {
    "code": "RE",
    "name": "Réunion"
  },
  "RO": {
    "code": "RO",
    "name": "Románia"
  },
  "RS": {
    "code": "RS",
    "name": "Szerbia"
  },
  "RU": {
    "code": "RU",
    "name": "Orosz Föderáció"
  },
  "RW": {
    "code": "RW",
    "name": "Ruanda"
  },
  "SA": {
    "code": "SA",
    "name": "Szaud-Arábia"
  },
  "SB": {
    "code": "SB",
    "name": "Salamon-szigetek"
  },
  "SC": {
    "code": "SC",
    "name": "Seychelle-szigetek"
  },
  "SD": {
    "code": "SD",
    "name": "Szudán"
  },
  "SE": {
    "code": "SE",
    "name": "Svédország"
  },
  "SG": {
    "code": "SG",
    "name": "Szingapúr"
  },
  "SH": {
    "code": "SH",
    "name": "Szent Ilona, Mennybemenetele és Tristan da Cunha"
  },
  "SI": {
    "code": "SI",
    "name": "Szlovénia"
  },
  "SJ": {
    "code": "SJ",
    "name": "Svalbard és Jan Mayen"
  },
  "SK": {
    "code": "SK",
    "name": "Szlovákia"
  },
  "SL": {
    "code": "SL",
    "name": "Sierra Leone"
  },
  "SM": {
    "code": "SM",
    "name": "San Marino"
  },
  "SN": {
    "code": "SN",
    "name": "Szenegál"
  },
  "SO": {
    "code": "SO",
    "name": "Szomália"
  },
  "SR": {
    "code": "SR",
    "name": "Suriname"
  },
  "SS": {
    "code": "SS",
    "name": "Dél Szudán"
  },
  "ST": {
    "code": "ST",
    "name": "Sao Tome és Principe"
  },
  "SV": {
    "code": "SV",
    "name": "El Salvador"
  },
  "SX": {
    "code": "SX",
    "name": "Sint Maarten (holland rész)"
  },
  "SY": {
    "code": "SY",
    "name": "Szíriai Arab Köztársaság"
  },
  "SZ": {
    "code": "SZ",
    "name": "Szváziföld"
  },
  "TC": {
    "code": "TC",
    "name": "Turks-és Caicos-szigetek"
  },
  "TD": {
    "code": "TD",
    "name": "Csád"
  },
  "TF": {
    "code": "TF",
    "name": "Francia déli területek"
  },
  "TG": {
    "code": "TG",
    "name": "Menni"
  },
  "TH": {
    "code": "TH",
    "name": "Thaiföld"
  },
  "TJ": {
    "code": "TJ",
    "name": "Tádzsikisztán"
  },
  "TK": {
    "code": "TK",
    "name": "Tokelau"
  },
  "TL": {
    "code": "TL",
    "name": "Kelet-Timor"
  },
  "TM": {
    "code": "TM",
    "name": "Türkmenisztán"
  },
  "TN": {
    "code": "TN",
    "name": "Tunézia"
  },
  "TO": {
    "code": "TO",
    "name": "Tonga"
  },
  "TR": {
    "code": "TR",
    "name": "pulyka"
  },
  "TT": {
    "code": "TT",
    "name": "Trinidad és Tobago"
  },
  "TV": {
    "code": "TV",
    "name": "Tuvalu"
  },
  "TW": {
    "code": "TW",
    "name": "Tajvan, Kína tartománya"
  },
  "TZ": {
    "code": "TZ",
    "name": "Tanzánia, Egyesült Köztársaság"
  },
  "UA": {
    "code": "UA",
    "name": "Ukrajna"
  },
  "UG": {
    "code": "UG",
    "name": "Uganda"
  },
  "UM": {
    "code": "UM",
    "name": "Az Egyesült Államok külső szigetei"
  },
  "US": {
    "code": "US",
    "name": "Egyesült Államok"
  },
  "UY": {
    "code": "UY",
    "name": "Uruguay"
  },
  "UZ": {
    "code": "UZ",
    "name": "Üzbegisztán"
  },
  "VA": {
    "code": "VA",
    "name": "Szentszék (Vatikánvárosi Állam)"
  },
  "VC": {
    "code": "VC",
    "name": "Saint Vincent és a Grenadine-szigetek"
  },
  "VE": {
    "code": "VE",
    "name": "Venezuela, Bolivári Köztársaság"
  },
  "VG": {
    "code": "VG",
    "name": "Virgin-szigetek, brit"
  },
  "VI": {
    "code": "VI",
    "name": "Virgin-szigetek, Egyesült Államok"
  },
  "VN": {
    "code": "VN",
    "name": "Vietnam"
  },
  "VU": {
    "code": "VU",
    "name": "Vanuatu"
  },
  "WF": {
    "code": "WF",
    "name": "Wallis és Futuna"
  },
  "WS": {
    "code": "WS",
    "name": "Szamoa"
  },
  "YE": {
    "code": "YE",
    "name": "Jemen"
  },
  "YT": {
    "code": "YT",
    "name": "Mayotte"
  },
  "ZA": {
    "code": "ZA",
    "name": "Dél-Afrika"
  },
  "ZM": {
    "code": "ZM",
    "name": "Zambia"
  },
  "ZW": {
    "code": "ZW",
    "name": "Zimbabwe"
  }
}