/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DoctorVacationDto } from '../model';
// @ts-ignore
import { DoctorWorkPlaceDto } from '../model';
// @ts-ignore
import { DoctorWorkPlanChangeDto } from '../model';
// @ts-ignore
import { DoctorWorkPlanDto } from '../model';
// @ts-ignore
import { FreeDayDto } from '../model';
// @ts-ignore
import { HarmonogramDto } from '../model';
/**
 * HarmonogramApiControllerApi - axios parameter creator
 * @export
 */
export const HarmonogramApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DoctorWorkPlanDto} doctorWorkPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDoctorWorkPlan: async (doctorWorkPlanDto: DoctorWorkPlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorWorkPlanDto' is not null or undefined
            assertParamExists('addDoctorWorkPlan', 'doctorWorkPlanDto', doctorWorkPlanDto)
            const localVarPath = `/api/doctor/workplan/base`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorWorkPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FreeDayDto} freeDayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFreeDay: async (freeDayDto: FreeDayDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'freeDayDto' is not null or undefined
            assertParamExists('addFreeDay', 'freeDayDto', freeDayDto)
            const localVarPath = `/api/workplan/freeday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(freeDayDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} workPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoctorWorkPlan: async (workPlanId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workPlanId' is not null or undefined
            assertParamExists('deleteDoctorWorkPlan', 'workPlanId', workPlanId)
            const localVarPath = `/api/doctor/workplan/base/{workPlanId}`
                .replace(`{${"workPlanId"}}`, encodeURIComponent(String(workPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFreeDay: async (date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('deleteFreeDay', 'date', date)
            const localVarPath = `/api/workplan/freeday/{date}`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} workPlanId 
         * @param {DoctorWorkPlanDto} doctorWorkPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDoctorWorkPlan: async (workPlanId: number, doctorWorkPlanDto: DoctorWorkPlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workPlanId' is not null or undefined
            assertParamExists('editDoctorWorkPlan', 'workPlanId', workPlanId)
            // verify required parameter 'doctorWorkPlanDto' is not null or undefined
            assertParamExists('editDoctorWorkPlan', 'doctorWorkPlanDto', doctorWorkPlanDto)
            const localVarPath = `/api/doctor/workplan/base/{workPlanId}`
                .replace(`{${"workPlanId"}}`, encodeURIComponent(String(workPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorWorkPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorVacations: async (dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workplan/vacation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkPlaces: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workplace/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkPlanChanges: async (dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/doctor/workplan/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkPlans: async (dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/doctor/workplan/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreeDays: async (dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workplan/freeday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHarmonograms: async (dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/doctor/harmonogram`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HarmonogramApiControllerApi - functional programming interface
 * @export
 */
export const HarmonogramApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HarmonogramApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DoctorWorkPlanDto} doctorWorkPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDoctorWorkPlan(doctorWorkPlanDto: DoctorWorkPlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorWorkPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDoctorWorkPlan(doctorWorkPlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FreeDayDto} freeDayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFreeDay(freeDayDto: FreeDayDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<FreeDayDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFreeDay(freeDayDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} workPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDoctorWorkPlan(workPlanId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDoctorWorkPlan(workPlanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFreeDay(date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFreeDay(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} workPlanId 
         * @param {DoctorWorkPlanDto} doctorWorkPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editDoctorWorkPlan(workPlanId: number, doctorWorkPlanDto: DoctorWorkPlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorWorkPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editDoctorWorkPlan(workPlanId, doctorWorkPlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorVacations(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorVacationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorVacations(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorWorkPlaces(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorWorkPlaceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorWorkPlaces(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorWorkPlanChanges(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorWorkPlanChangeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorWorkPlanChanges(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorWorkPlans(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorWorkPlanDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorWorkPlans(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFreeDays(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<FreeDayDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFreeDays(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHarmonograms(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<HarmonogramDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHarmonograms(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HarmonogramApiControllerApi - factory interface
 * @export
 */
export const HarmonogramApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HarmonogramApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {DoctorWorkPlanDto} doctorWorkPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDoctorWorkPlan(doctorWorkPlanDto: DoctorWorkPlanDto, options?: any): Promise<DoctorWorkPlanDto> {
            return localVarFp.addDoctorWorkPlan(doctorWorkPlanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FreeDayDto} freeDayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFreeDay(freeDayDto: FreeDayDto, options?: any): Promise<FreeDayDto> {
            return localVarFp.addFreeDay(freeDayDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} workPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDoctorWorkPlan(workPlanId: number, options?: any): Promise<void> {
            return localVarFp.deleteDoctorWorkPlan(workPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFreeDay(date: string, options?: any): Promise<void> {
            return localVarFp.deleteFreeDay(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} workPlanId 
         * @param {DoctorWorkPlanDto} doctorWorkPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDoctorWorkPlan(workPlanId: number, doctorWorkPlanDto: DoctorWorkPlanDto, options?: any): Promise<DoctorWorkPlanDto> {
            return localVarFp.editDoctorWorkPlan(workPlanId, doctorWorkPlanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorVacations(dateFrom?: string, dateTo?: string, options?: any): Promise<Array<DoctorVacationDto>> {
            return localVarFp.getDoctorVacations(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkPlaces(options?: any): Promise<Array<DoctorWorkPlaceDto>> {
            return localVarFp.getDoctorWorkPlaces(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkPlanChanges(dateFrom?: string, dateTo?: string, options?: any): Promise<Array<DoctorWorkPlanChangeDto>> {
            return localVarFp.getDoctorWorkPlanChanges(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkPlans(dateFrom?: string, dateTo?: string, options?: any): Promise<Array<DoctorWorkPlanDto>> {
            return localVarFp.getDoctorWorkPlans(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFreeDays(dateFrom?: string, dateTo?: string, options?: any): Promise<Array<FreeDayDto>> {
            return localVarFp.getFreeDays(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHarmonograms(dateFrom?: string, dateTo?: string, options?: any): Promise<Array<HarmonogramDto>> {
            return localVarFp.getHarmonograms(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HarmonogramApiControllerApi - object-oriented interface
 * @export
 * @class HarmonogramApiControllerApi
 * @extends {BaseAPI}
 */
export class HarmonogramApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {DoctorWorkPlanDto} doctorWorkPlanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonogramApiControllerApi
     */
    public addDoctorWorkPlan(doctorWorkPlanDto: DoctorWorkPlanDto, options?: AxiosRequestConfig) {
        return HarmonogramApiControllerApiFp(this.configuration).addDoctorWorkPlan(doctorWorkPlanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FreeDayDto} freeDayDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonogramApiControllerApi
     */
    public addFreeDay(freeDayDto: FreeDayDto, options?: AxiosRequestConfig) {
        return HarmonogramApiControllerApiFp(this.configuration).addFreeDay(freeDayDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} workPlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonogramApiControllerApi
     */
    public deleteDoctorWorkPlan(workPlanId: number, options?: AxiosRequestConfig) {
        return HarmonogramApiControllerApiFp(this.configuration).deleteDoctorWorkPlan(workPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonogramApiControllerApi
     */
    public deleteFreeDay(date: string, options?: AxiosRequestConfig) {
        return HarmonogramApiControllerApiFp(this.configuration).deleteFreeDay(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} workPlanId 
     * @param {DoctorWorkPlanDto} doctorWorkPlanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonogramApiControllerApi
     */
    public editDoctorWorkPlan(workPlanId: number, doctorWorkPlanDto: DoctorWorkPlanDto, options?: AxiosRequestConfig) {
        return HarmonogramApiControllerApiFp(this.configuration).editDoctorWorkPlan(workPlanId, doctorWorkPlanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonogramApiControllerApi
     */
    public getDoctorVacations(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return HarmonogramApiControllerApiFp(this.configuration).getDoctorVacations(dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonogramApiControllerApi
     */
    public getDoctorWorkPlaces(options?: AxiosRequestConfig) {
        return HarmonogramApiControllerApiFp(this.configuration).getDoctorWorkPlaces(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonogramApiControllerApi
     */
    public getDoctorWorkPlanChanges(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return HarmonogramApiControllerApiFp(this.configuration).getDoctorWorkPlanChanges(dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonogramApiControllerApi
     */
    public getDoctorWorkPlans(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return HarmonogramApiControllerApiFp(this.configuration).getDoctorWorkPlans(dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonogramApiControllerApi
     */
    public getFreeDays(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return HarmonogramApiControllerApiFp(this.configuration).getFreeDays(dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HarmonogramApiControllerApi
     */
    public getHarmonograms(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return HarmonogramApiControllerApiFp(this.configuration).getHarmonograms(dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }
}
