/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PaymentsConfiguration
 */
export interface PaymentsConfiguration {
    /**
     * 
     * @type {string}
     * @memberof PaymentsConfiguration
     */
    'defaultPaymentProvider': PaymentsConfigurationDefaultPaymentProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentsConfiguration
     */
    'defaultPaymentProviderEmail': PaymentsConfigurationDefaultPaymentProviderEmailEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentsConfiguration
     */
    'isValid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentsConfiguration
     */
    'payNowEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentsConfiguration
     */
    'payUEnabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof PaymentsConfiguration
     */
    'paymentMarkingMaxPrice': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentsConfiguration
     */
    'paymentMarkingMaxPriceExceededPaymentDays': number;
    /**
     * 
     * @type {number}
     * @memberof PaymentsConfiguration
     */
    'standardRefundMaxPrice': number;
}

/**
    * @export
    * @enum {string}
    */
export enum PaymentsConfigurationDefaultPaymentProviderEnum {
    Payu = 'PAYU',
    Paynow = 'PAYNOW'
}
/**
    * @export
    * @enum {string}
    */
export enum PaymentsConfigurationDefaultPaymentProviderEmailEnum {
    Payu = 'PAYU',
    Paynow = 'PAYNOW'
}


