/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DoctorDto } from './doctor-dto';
import { MedicalResultFileDto } from './medical-result-file-dto';

/**
 * 
 * @export
 * @interface MedicalResultDescriptionDto
 */
export interface MedicalResultDescriptionDto {
    /**
     * 
     * @type {string}
     * @memberof MedicalResultDescriptionDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalResultDescriptionDto
     */
    'description'?: string;
    /**
     * 
     * @type {DoctorDto}
     * @memberof MedicalResultDescriptionDto
     */
    'doctor'?: DoctorDto;
    /**
     * 
     * @type {number}
     * @memberof MedicalResultDescriptionDto
     */
    'doctorId'?: number;
    /**
     * 
     * @type {Array<MedicalResultFileDto>}
     * @memberof MedicalResultDescriptionDto
     */
    'files'?: Array<MedicalResultFileDto>;
    /**
     * 
     * @type {number}
     * @memberof MedicalResultDescriptionDto
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalResultDescriptionDto
     */
    'isMain'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedicalResultDescriptionDto
     */
    'medicalRequestId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicalResultDescriptionDto
     */
    'medicalResultId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalResultDescriptionDto
     */
    'metadata'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedicalResultDescriptionDto
     */
    'patientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalResultDescriptionDto
     */
    'type'?: MedicalResultDescriptionDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MedicalResultDescriptionDtoTypeEnum {
    Comment = 'COMMENT',
    Description = 'DESCRIPTION'
}


