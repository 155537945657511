/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PageAdminNoteDto } from '../model';
// @ts-ignore
import { PageNoteForAdminDto } from '../model';
// @ts-ignore
import { PagePatientNoteForAdminDto } from '../model';
/**
 * AdminNoteApiControllerApi - axios parameter creator
 * @export
 */
export const AdminNoteApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} content 
         * @param {Array<number>} grantedDoctorsIds 
         * @param {Array<any>} newFiles 
         * @param {number} patientId 
         * @param {boolean} publicVisible 
         * @param {number} [doctorNoteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminNote: async (content: string, grantedDoctorsIds: Array<number>, newFiles: Array<any>, patientId: number, publicVisible: boolean, doctorNoteId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'content' is not null or undefined
            assertParamExists('addAdminNote', 'content', content)
            // verify required parameter 'grantedDoctorsIds' is not null or undefined
            assertParamExists('addAdminNote', 'grantedDoctorsIds', grantedDoctorsIds)
            // verify required parameter 'newFiles' is not null or undefined
            assertParamExists('addAdminNote', 'newFiles', newFiles)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('addAdminNote', 'patientId', patientId)
            // verify required parameter 'publicVisible' is not null or undefined
            assertParamExists('addAdminNote', 'publicVisible', publicVisible)
            const localVarPath = `/api/admin-note/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (content !== undefined) { 
                localVarFormParams.append('content', content as any);
            }
    
            if (doctorNoteId !== undefined) { 
                localVarFormParams.append('doctorNoteId', doctorNoteId as any);
            }
                if (grantedDoctorsIds) {
                localVarFormParams.append('grantedDoctorsIds', grantedDoctorsIds.join(COLLECTION_FORMATS.csv));
            }

                if (newFiles) {
                newFiles.forEach((element) => {
                    localVarFormParams.append('newFiles', element as any);
                })
            }

    
            if (patientId !== undefined) { 
                localVarFormParams.append('patientId', patientId as any);
            }
    
            if (publicVisible !== undefined) { 
                localVarFormParams.append('publicVisible', publicVisible as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveDoctorNote: async (noteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('archiveDoctorNote', 'noteId', noteId)
            const localVarPath = `/api/admin-note/{noteId}/archive`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminNote: async (noteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('deleteAdminNote', 'noteId', noteId)
            const localVarPath = `/api/admin-note/admin/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminNotes: async (search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin-note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [archive] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorNotesForAdmin: async (archive?: boolean, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin-note/doctor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (archive !== undefined) {
                localVarQueryParameter['archive'] = archive;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientNotes: async (search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin-note/patient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} noteId 
         * @param {Array<number>} addedFilesIds 
         * @param {string} content 
         * @param {Array<number>} grantedDoctorsIds 
         * @param {Array<any>} newFiles 
         * @param {boolean} publicVisible 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminNote: async (noteId: number, addedFilesIds: Array<number>, content: string, grantedDoctorsIds: Array<number>, newFiles: Array<any>, publicVisible: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('updateAdminNote', 'noteId', noteId)
            // verify required parameter 'addedFilesIds' is not null or undefined
            assertParamExists('updateAdminNote', 'addedFilesIds', addedFilesIds)
            // verify required parameter 'content' is not null or undefined
            assertParamExists('updateAdminNote', 'content', content)
            // verify required parameter 'grantedDoctorsIds' is not null or undefined
            assertParamExists('updateAdminNote', 'grantedDoctorsIds', grantedDoctorsIds)
            // verify required parameter 'newFiles' is not null or undefined
            assertParamExists('updateAdminNote', 'newFiles', newFiles)
            // verify required parameter 'publicVisible' is not null or undefined
            assertParamExists('updateAdminNote', 'publicVisible', publicVisible)
            const localVarPath = `/api/admin-note/admin/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (addedFilesIds) {
                localVarFormParams.append('addedFilesIds', addedFilesIds.join(COLLECTION_FORMATS.csv));
            }

    
            if (content !== undefined) { 
                localVarFormParams.append('content', content as any);
            }
                if (grantedDoctorsIds) {
                localVarFormParams.append('grantedDoctorsIds', grantedDoctorsIds.join(COLLECTION_FORMATS.csv));
            }

                if (newFiles) {
                newFiles.forEach((element) => {
                    localVarFormParams.append('newFiles', element as any);
                })
            }

    
            if (publicVisible !== undefined) { 
                localVarFormParams.append('publicVisible', publicVisible as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminNoteApiControllerApi - functional programming interface
 * @export
 */
export const AdminNoteApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminNoteApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} content 
         * @param {Array<number>} grantedDoctorsIds 
         * @param {Array<any>} newFiles 
         * @param {number} patientId 
         * @param {boolean} publicVisible 
         * @param {number} [doctorNoteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAdminNote(content: string, grantedDoctorsIds: Array<number>, newFiles: Array<any>, patientId: number, publicVisible: boolean, doctorNoteId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAdminNote(content, grantedDoctorsIds, newFiles, patientId, publicVisible, doctorNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveDoctorNote(noteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveDoctorNote(noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminNote(noteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminNote(noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminNotes(search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PageAdminNoteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminNotes(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [archive] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorNotesForAdmin(archive?: boolean, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PageNoteForAdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorNotesForAdmin(archive, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientNotes(search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PagePatientNoteForAdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientNotes(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} noteId 
         * @param {Array<number>} addedFilesIds 
         * @param {string} content 
         * @param {Array<number>} grantedDoctorsIds 
         * @param {Array<any>} newFiles 
         * @param {boolean} publicVisible 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminNote(noteId: number, addedFilesIds: Array<number>, content: string, grantedDoctorsIds: Array<number>, newFiles: Array<any>, publicVisible: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminNote(noteId, addedFilesIds, content, grantedDoctorsIds, newFiles, publicVisible, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminNoteApiControllerApi - factory interface
 * @export
 */
export const AdminNoteApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminNoteApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} content 
         * @param {Array<number>} grantedDoctorsIds 
         * @param {Array<any>} newFiles 
         * @param {number} patientId 
         * @param {boolean} publicVisible 
         * @param {number} [doctorNoteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAdminNote(content: string, grantedDoctorsIds: Array<number>, newFiles: Array<any>, patientId: number, publicVisible: boolean, doctorNoteId?: number, options?: any): Promise<void> {
            return localVarFp.addAdminNote(content, grantedDoctorsIds, newFiles, patientId, publicVisible, doctorNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveDoctorNote(noteId: number, options?: any): Promise<void> {
            return localVarFp.archiveDoctorNote(noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminNote(noteId: number, options?: any): Promise<void> {
            return localVarFp.deleteAdminNote(noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminNotes(search?: string, options?: any): Promise<PageAdminNoteDto> {
            return localVarFp.getAdminNotes(search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [archive] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorNotesForAdmin(archive?: boolean, search?: string, options?: any): Promise<PageNoteForAdminDto> {
            return localVarFp.getDoctorNotesForAdmin(archive, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientNotes(search?: string, options?: any): Promise<PagePatientNoteForAdminDto> {
            return localVarFp.getPatientNotes(search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} noteId 
         * @param {Array<number>} addedFilesIds 
         * @param {string} content 
         * @param {Array<number>} grantedDoctorsIds 
         * @param {Array<any>} newFiles 
         * @param {boolean} publicVisible 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminNote(noteId: number, addedFilesIds: Array<number>, content: string, grantedDoctorsIds: Array<number>, newFiles: Array<any>, publicVisible: boolean, options?: any): Promise<void> {
            return localVarFp.updateAdminNote(noteId, addedFilesIds, content, grantedDoctorsIds, newFiles, publicVisible, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminNoteApiControllerApi - object-oriented interface
 * @export
 * @class AdminNoteApiControllerApi
 * @extends {BaseAPI}
 */
export class AdminNoteApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} content 
     * @param {Array<number>} grantedDoctorsIds 
     * @param {Array<any>} newFiles 
     * @param {number} patientId 
     * @param {boolean} publicVisible 
     * @param {number} [doctorNoteId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminNoteApiControllerApi
     */
    public addAdminNote(content: string, grantedDoctorsIds: Array<number>, newFiles: Array<any>, patientId: number, publicVisible: boolean, doctorNoteId?: number, options?: AxiosRequestConfig) {
        return AdminNoteApiControllerApiFp(this.configuration).addAdminNote(content, grantedDoctorsIds, newFiles, patientId, publicVisible, doctorNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} noteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminNoteApiControllerApi
     */
    public archiveDoctorNote(noteId: number, options?: AxiosRequestConfig) {
        return AdminNoteApiControllerApiFp(this.configuration).archiveDoctorNote(noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} noteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminNoteApiControllerApi
     */
    public deleteAdminNote(noteId: number, options?: AxiosRequestConfig) {
        return AdminNoteApiControllerApiFp(this.configuration).deleteAdminNote(noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminNoteApiControllerApi
     */
    public getAdminNotes(search?: string, options?: AxiosRequestConfig) {
        return AdminNoteApiControllerApiFp(this.configuration).getAdminNotes(search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [archive] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminNoteApiControllerApi
     */
    public getDoctorNotesForAdmin(archive?: boolean, search?: string, options?: AxiosRequestConfig) {
        return AdminNoteApiControllerApiFp(this.configuration).getDoctorNotesForAdmin(archive, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminNoteApiControllerApi
     */
    public getPatientNotes(search?: string, options?: AxiosRequestConfig) {
        return AdminNoteApiControllerApiFp(this.configuration).getPatientNotes(search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} noteId 
     * @param {Array<number>} addedFilesIds 
     * @param {string} content 
     * @param {Array<number>} grantedDoctorsIds 
     * @param {Array<any>} newFiles 
     * @param {boolean} publicVisible 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminNoteApiControllerApi
     */
    public updateAdminNote(noteId: number, addedFilesIds: Array<number>, content: string, grantedDoctorsIds: Array<number>, newFiles: Array<any>, publicVisible: boolean, options?: AxiosRequestConfig) {
        return AdminNoteApiControllerApiFp(this.configuration).updateAdminNote(noteId, addedFilesIds, content, grantedDoctorsIds, newFiles, publicVisible, options).then((request) => request(this.axios, this.basePath));
    }
}
