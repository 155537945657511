/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PatientDashboardPlannedActivitiesDto
 */
export interface PatientDashboardPlannedActivitiesDto {
    /**
     * 
     * @type {string}
     * @memberof PatientDashboardPlannedActivitiesDto
     */
    'metadata'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDashboardPlannedActivitiesDto
     */
    'type': PatientDashboardPlannedActivitiesDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PatientDashboardPlannedActivitiesDtoTypeEnum {
    Visit = 'VISIT',
    ChooseDoctor = 'CHOOSE_DOCTOR',
    ContactDoctor = 'CONTACT_DOCTOR',
    AskForPrescription = 'ASK_FOR_PRESCRIPTION'
}


