/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PsychomotorDevelopmentDto
 */
export interface PsychomotorDevelopmentDto {
    /**
     * 
     * @type {boolean}
     * @memberof PsychomotorDevelopmentDto
     */
    'adhd'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PsychomotorDevelopmentDto
     */
    'alcohol'?: PsychomotorDevelopmentDtoAlcoholEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PsychomotorDevelopmentDto
     */
    'autism'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PsychomotorDevelopmentDto
     */
    'brainDeficit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PsychomotorDevelopmentDto
     */
    'depression'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PsychomotorDevelopmentDto
     */
    'developmentCorrect'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PsychomotorDevelopmentDto
     */
    'hearing'?: PsychomotorDevelopmentDtoHearingEnum;
    /**
     * 
     * @type {number}
     * @memberof PsychomotorDevelopmentDto
     */
    'iq'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PsychomotorDevelopmentDto
     */
    'mentalDisordersNotKnown'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PsychomotorDevelopmentDto
     */
    'narcotics'?: PsychomotorDevelopmentDtoNarcoticsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PsychomotorDevelopmentDto
     */
    'noNeurologicalSyndrome'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PsychomotorDevelopmentDto
     */
    'nystagmus'?: PsychomotorDevelopmentDtoNystagmusEnum;
    /**
     * 
     * @type {string}
     * @memberof PsychomotorDevelopmentDto
     */
    'overall'?: PsychomotorDevelopmentDtoOverallEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PsychomotorDevelopmentDto
     */
    'peripharalParesis'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PsychomotorDevelopmentDto
     */
    'piramidalDeficit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PsychomotorDevelopmentDto
     */
    'psychosis'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PsychomotorDevelopmentDto
     */
    'retardation'?: PsychomotorDevelopmentDtoRetardationEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PsychomotorDevelopmentDto
     */
    'retardationDetermined'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PsychomotorDevelopmentDto
     */
    'retardationDeterminedDescription'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PsychomotorDevelopmentDto
     */
    'retardationExamined'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PsychomotorDevelopmentDto
     */
    'retardationExaminedDescription'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PsychomotorDevelopmentDto
     */
    'sensoryDisturbance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PsychomotorDevelopmentDto
     */
    'sex'?: PsychomotorDevelopmentDtoSexEnum;
    /**
     * 
     * @type {string}
     * @memberof PsychomotorDevelopmentDto
     */
    'smoking'?: PsychomotorDevelopmentDtoSmokingEnum;
    /**
     * 
     * @type {string}
     * @memberof PsychomotorDevelopmentDto
     */
    'speech'?: PsychomotorDevelopmentDtoSpeechEnum;
    /**
     * 
     * @type {string}
     * @memberof PsychomotorDevelopmentDto
     */
    'vision'?: PsychomotorDevelopmentDtoVisionEnum;
    /**
     * 
     * @type {string}
     * @memberof PsychomotorDevelopmentDto
     */
    'walk'?: PsychomotorDevelopmentDtoWalkEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PsychomotorDevelopmentDtoAlcoholEnum {
    No = 'NO',
    SometimesLittle = 'SOMETIMES_LITTLE',
    SometimesALot = 'SOMETIMES_A_LOT',
    OftenLittle = 'OFTEN_LITTLE',
    OftenALot = 'OFTEN_A_LOT',
    NotApplicable = 'NOT_APPLICABLE'
}
/**
    * @export
    * @enum {string}
    */
export enum PsychomotorDevelopmentDtoHearingEnum {
    Normal = 'NORMAL',
    Disturbed = 'DISTURBED',
    NotApplicable = 'NOT_APPLICABLE'
}
/**
    * @export
    * @enum {string}
    */
export enum PsychomotorDevelopmentDtoNarcoticsEnum {
    No = 'NO',
    YesInPast = 'YES_IN_PAST',
    YesNowRarely = 'YES_NOW_RARELY',
    YesNowOften = 'YES_NOW_OFTEN',
    NotApplicable = 'NOT_APPLICABLE'
}
/**
    * @export
    * @enum {string}
    */
export enum PsychomotorDevelopmentDtoNystagmusEnum {
    No = 'NO',
    Vestibular = 'VESTIBULAR',
    Cns = 'CNS'
}
/**
    * @export
    * @enum {string}
    */
export enum PsychomotorDevelopmentDtoOverallEnum {
    Normal = 'NORMAL',
    Retarded = 'RETARDED',
    VeryRetarded = 'VERY_RETARDED',
    NotApplicable = 'NOT_APPLICABLE'
}
/**
    * @export
    * @enum {string}
    */
export enum PsychomotorDevelopmentDtoRetardationEnum {
    No = 'NO',
    Light = 'LIGHT',
    Moderate = 'MODERATE',
    Significant = 'SIGNIFICANT'
}
/**
    * @export
    * @enum {string}
    */
export enum PsychomotorDevelopmentDtoSexEnum {
    Normal = 'NORMAL',
    LibidoDisorders = 'LIBIDO_DISORDERS',
    NotApplicable = 'NOT_APPLICABLE'
}
/**
    * @export
    * @enum {string}
    */
export enum PsychomotorDevelopmentDtoSmokingEnum {
    No = 'NO',
    YesInPast = 'YES_IN_PAST',
    YesNow = 'YES_NOW',
    NotApplicable = 'NOT_APPLICABLE'
}
/**
    * @export
    * @enum {string}
    */
export enum PsychomotorDevelopmentDtoSpeechEnum {
    Normal = 'NORMAL',
    Disturbed = 'DISTURBED',
    NotApplicable = 'NOT_APPLICABLE'
}
/**
    * @export
    * @enum {string}
    */
export enum PsychomotorDevelopmentDtoVisionEnum {
    Normal = 'NORMAL',
    DefectPlus = 'DEFECT_PLUS',
    DefectMinus = 'DEFECT_MINUS',
    DefectOther = 'DEFECT_OTHER',
    Blind = 'BLIND',
    NotApplicable = 'NOT_APPLICABLE'
}
/**
    * @export
    * @enum {string}
    */
export enum PsychomotorDevelopmentDtoWalkEnum {
    Normal = 'NORMAL',
    Incorrect = 'INCORRECT',
    NotApplicable = 'NOT_APPLICABLE'
}


