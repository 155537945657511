/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface MedicalServiceVisitTypeDto
 */
export interface MedicalServiceVisitTypeDto {
    /**
     * 
     * @type {number}
     * @memberof MedicalServiceVisitTypeDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalServiceVisitTypeDto
     */
    'visitType'?: MedicalServiceVisitTypeDtoVisitTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MedicalServiceVisitTypeDtoVisitTypeEnum {
    Remote = 'REMOTE',
    Stationary = 'STATIONARY',
    Absent = 'ABSENT'
}


