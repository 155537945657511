/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocplannerDictionaryDataEntry } from '../model';
// @ts-ignore
import { DocplannerIssueDto } from '../model';
// @ts-ignore
import { PageDocplannerIssueDto } from '../model';
// @ts-ignore
import { PageDocplannerVisitDto } from '../model';
/**
 * AdminDocplannerControllerApi - axios parameter creator
 * @export
 */
export const AdminDocplannerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'DOCTOR' | 'ADDRESS' | 'SERVICE'} type 
         * @param {string} [discriminator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionaryData: async (type: 'DOCTOR' | 'ADDRESS' | 'SERVICE', discriminator?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getDictionaryData', 'type', type)
            const localVarPath = `/api/admin/docplanner/dictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (discriminator !== undefined) {
                localVarQueryParameter['discriminator'] = discriminator;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} resolved 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocplannerIssues: async (resolved: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resolved' is not null or undefined
            assertParamExists('getDocplannerIssues', 'resolved', resolved)
            const localVarPath = `/api/admin/docplanner/issue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (resolved !== undefined) {
                localVarQueryParameter['resolved'] = resolved;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<'NEW' | 'CANCELED_BY_SCHEDULER' | 'CANCELED_BY_PERSON' | 'SOLVED' | 'PAID_NOT_DONE' | 'DONE'>} statuses 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitsAppointedByDocplanner: async (statuses: Array<'NEW' | 'CANCELED_BY_SCHEDULER' | 'CANCELED_BY_PERSON' | 'SOLVED' | 'PAID_NOT_DONE' | 'DONE'>, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'statuses' is not null or undefined
            assertParamExists('getVisitsAppointedByDocplanner', 'statuses', statuses)
            const localVarPath = `/api/admin/docplanner/visit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} date 
         * @param {boolean} includeSlots 
         * @param {boolean} includeVisits 
         * @param {boolean} includeCanceledVisits 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshSlots: async (doctorId: number, date: string, includeSlots: boolean, includeVisits: boolean, includeCanceledVisits: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('refreshSlots', 'doctorId', doctorId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('refreshSlots', 'date', date)
            // verify required parameter 'includeSlots' is not null or undefined
            assertParamExists('refreshSlots', 'includeSlots', includeSlots)
            // verify required parameter 'includeVisits' is not null or undefined
            assertParamExists('refreshSlots', 'includeVisits', includeVisits)
            // verify required parameter 'includeCanceledVisits' is not null or undefined
            assertParamExists('refreshSlots', 'includeCanceledVisits', includeCanceledVisits)
            const localVarPath = `/api/admin/docplanner/doctor/{doctorId}/refresh-slots/{date}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)))
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeSlots !== undefined) {
                localVarQueryParameter['includeSlots'] = includeSlots;
            }

            if (includeVisits !== undefined) {
                localVarQueryParameter['includeVisits'] = includeVisits;
            }

            if (includeCanceledVisits !== undefined) {
                localVarQueryParameter['includeCanceledVisits'] = includeCanceledVisits;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendVisitEmail: async (visitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('resendVisitEmail', 'visitId', visitId)
            const localVarPath = `/api/admin/docplanner/visit/{visitId}/resend`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runResolveAction: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runResolveAction', 'id', id)
            const localVarPath = `/api/admin/docplanner/issue/{id}/resolve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {'APPOINTMENT' | 'RESIGNATION' | 'OTHER'} caseSolvedStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solveVisitCase: async (visitId: number, caseSolvedStatus: 'APPOINTMENT' | 'RESIGNATION' | 'OTHER', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('solveVisitCase', 'visitId', visitId)
            // verify required parameter 'caseSolvedStatus' is not null or undefined
            assertParamExists('solveVisitCase', 'caseSolvedStatus', caseSolvedStatus)
            const localVarPath = `/api/admin/docplanner/visit/{visitId}/solve`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (caseSolvedStatus !== undefined) {
                localVarQueryParameter['caseSolvedStatus'] = caseSolvedStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} resolved 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssueResolution: async (id: number, resolved: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateIssueResolution', 'id', id)
            // verify required parameter 'resolved' is not null or undefined
            assertParamExists('updateIssueResolution', 'resolved', resolved)
            const localVarPath = `/api/admin/docplanner/issue/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (resolved !== undefined) {
                localVarQueryParameter['resolved'] = resolved;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminDocplannerControllerApi - functional programming interface
 * @export
 */
export const AdminDocplannerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminDocplannerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {'DOCTOR' | 'ADDRESS' | 'SERVICE'} type 
         * @param {string} [discriminator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDictionaryData(type: 'DOCTOR' | 'ADDRESS' | 'SERVICE', discriminator?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DocplannerDictionaryDataEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDictionaryData(type, discriminator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} resolved 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocplannerIssues(resolved: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PageDocplannerIssueDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocplannerIssues(resolved, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<'NEW' | 'CANCELED_BY_SCHEDULER' | 'CANCELED_BY_PERSON' | 'SOLVED' | 'PAID_NOT_DONE' | 'DONE'>} statuses 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitsAppointedByDocplanner(statuses: Array<'NEW' | 'CANCELED_BY_SCHEDULER' | 'CANCELED_BY_PERSON' | 'SOLVED' | 'PAID_NOT_DONE' | 'DONE'>, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PageDocplannerVisitDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitsAppointedByDocplanner(statuses, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} date 
         * @param {boolean} includeSlots 
         * @param {boolean} includeVisits 
         * @param {boolean} includeCanceledVisits 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshSlots(doctorId: number, date: string, includeSlots: boolean, includeVisits: boolean, includeCanceledVisits: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshSlots(doctorId, date, includeSlots, includeVisits, includeCanceledVisits, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendVisitEmail(visitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendVisitEmail(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runResolveAction(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runResolveAction(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {'APPOINTMENT' | 'RESIGNATION' | 'OTHER'} caseSolvedStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async solveVisitCase(visitId: number, caseSolvedStatus: 'APPOINTMENT' | 'RESIGNATION' | 'OTHER', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.solveVisitCase(visitId, caseSolvedStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} resolved 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIssueResolution(id: number, resolved: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DocplannerIssueDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIssueResolution(id, resolved, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminDocplannerControllerApi - factory interface
 * @export
 */
export const AdminDocplannerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminDocplannerControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {'DOCTOR' | 'ADDRESS' | 'SERVICE'} type 
         * @param {string} [discriminator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionaryData(type: 'DOCTOR' | 'ADDRESS' | 'SERVICE', discriminator?: string, options?: any): Promise<Array<DocplannerDictionaryDataEntry>> {
            return localVarFp.getDictionaryData(type, discriminator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} resolved 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocplannerIssues(resolved: boolean, options?: any): Promise<PageDocplannerIssueDto> {
            return localVarFp.getDocplannerIssues(resolved, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<'NEW' | 'CANCELED_BY_SCHEDULER' | 'CANCELED_BY_PERSON' | 'SOLVED' | 'PAID_NOT_DONE' | 'DONE'>} statuses 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitsAppointedByDocplanner(statuses: Array<'NEW' | 'CANCELED_BY_SCHEDULER' | 'CANCELED_BY_PERSON' | 'SOLVED' | 'PAID_NOT_DONE' | 'DONE'>, from?: string, to?: string, options?: any): Promise<PageDocplannerVisitDto> {
            return localVarFp.getVisitsAppointedByDocplanner(statuses, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} date 
         * @param {boolean} includeSlots 
         * @param {boolean} includeVisits 
         * @param {boolean} includeCanceledVisits 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshSlots(doctorId: number, date: string, includeSlots: boolean, includeVisits: boolean, includeCanceledVisits: boolean, options?: any): Promise<void> {
            return localVarFp.refreshSlots(doctorId, date, includeSlots, includeVisits, includeCanceledVisits, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendVisitEmail(visitId: number, options?: any): Promise<void> {
            return localVarFp.resendVisitEmail(visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runResolveAction(id: number, options?: any): Promise<void> {
            return localVarFp.runResolveAction(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {'APPOINTMENT' | 'RESIGNATION' | 'OTHER'} caseSolvedStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solveVisitCase(visitId: number, caseSolvedStatus: 'APPOINTMENT' | 'RESIGNATION' | 'OTHER', options?: any): Promise<void> {
            return localVarFp.solveVisitCase(visitId, caseSolvedStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} resolved 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssueResolution(id: number, resolved: boolean, options?: any): Promise<DocplannerIssueDto> {
            return localVarFp.updateIssueResolution(id, resolved, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminDocplannerControllerApi - object-oriented interface
 * @export
 * @class AdminDocplannerControllerApi
 * @extends {BaseAPI}
 */
export class AdminDocplannerControllerApi extends BaseAPI {
    /**
     * 
     * @param {'DOCTOR' | 'ADDRESS' | 'SERVICE'} type 
     * @param {string} [discriminator] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocplannerControllerApi
     */
    public getDictionaryData(type: 'DOCTOR' | 'ADDRESS' | 'SERVICE', discriminator?: string, options?: AxiosRequestConfig) {
        return AdminDocplannerControllerApiFp(this.configuration).getDictionaryData(type, discriminator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} resolved 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocplannerControllerApi
     */
    public getDocplannerIssues(resolved: boolean, options?: AxiosRequestConfig) {
        return AdminDocplannerControllerApiFp(this.configuration).getDocplannerIssues(resolved, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<'NEW' | 'CANCELED_BY_SCHEDULER' | 'CANCELED_BY_PERSON' | 'SOLVED' | 'PAID_NOT_DONE' | 'DONE'>} statuses 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocplannerControllerApi
     */
    public getVisitsAppointedByDocplanner(statuses: Array<'NEW' | 'CANCELED_BY_SCHEDULER' | 'CANCELED_BY_PERSON' | 'SOLVED' | 'PAID_NOT_DONE' | 'DONE'>, from?: string, to?: string, options?: AxiosRequestConfig) {
        return AdminDocplannerControllerApiFp(this.configuration).getVisitsAppointedByDocplanner(statuses, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {string} date 
     * @param {boolean} includeSlots 
     * @param {boolean} includeVisits 
     * @param {boolean} includeCanceledVisits 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocplannerControllerApi
     */
    public refreshSlots(doctorId: number, date: string, includeSlots: boolean, includeVisits: boolean, includeCanceledVisits: boolean, options?: AxiosRequestConfig) {
        return AdminDocplannerControllerApiFp(this.configuration).refreshSlots(doctorId, date, includeSlots, includeVisits, includeCanceledVisits, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocplannerControllerApi
     */
    public resendVisitEmail(visitId: number, options?: AxiosRequestConfig) {
        return AdminDocplannerControllerApiFp(this.configuration).resendVisitEmail(visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocplannerControllerApi
     */
    public runResolveAction(id: number, options?: AxiosRequestConfig) {
        return AdminDocplannerControllerApiFp(this.configuration).runResolveAction(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {'APPOINTMENT' | 'RESIGNATION' | 'OTHER'} caseSolvedStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocplannerControllerApi
     */
    public solveVisitCase(visitId: number, caseSolvedStatus: 'APPOINTMENT' | 'RESIGNATION' | 'OTHER', options?: AxiosRequestConfig) {
        return AdminDocplannerControllerApiFp(this.configuration).solveVisitCase(visitId, caseSolvedStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} resolved 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDocplannerControllerApi
     */
    public updateIssueResolution(id: number, resolved: boolean, options?: AxiosRequestConfig) {
        return AdminDocplannerControllerApiFp(this.configuration).updateIssueResolution(id, resolved, options).then((request) => request(this.axios, this.basePath));
    }
}
