/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DoctorBasicDto } from './doctor-basic-dto';
import { DoctorWorkPlaceDto } from './doctor-work-place-dto';
import { MedicalServiceBaseDto } from './medical-service-base-dto';
import { MedicalServiceGroupDto } from './medical-service-group-dto';
import { UserAccountDto } from './user-account-dto';

/**
 * 
 * @export
 * @interface DoctorWithWorkPlacesDto
 */
export interface DoctorWithWorkPlacesDto {
    /**
     * 
     * @type {string}
     * @memberof DoctorWithWorkPlacesDto
     */
    'academicTitle'?: string;
    /**
     * 
     * @type {DoctorBasicDto}
     * @memberof DoctorWithWorkPlacesDto
     */
    'doctorDto'?: DoctorBasicDto;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorWithWorkPlacesDto
     */
    'hasDocplannerAccount': boolean;
    /**
     * 
     * @type {number}
     * @memberof DoctorWithWorkPlacesDto
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorWithWorkPlacesDto
     */
    'inactive'?: boolean;
    /**
     * 
     * @type {MedicalServiceGroupDto}
     * @memberof DoctorWithWorkPlacesDto
     */
    'medicalServiceGroup'?: MedicalServiceGroupDto;
    /**
     * 
     * @type {Array<MedicalServiceBaseDto>}
     * @memberof DoctorWithWorkPlacesDto
     */
    'medicalServices': Array<MedicalServiceBaseDto>;
    /**
     * 
     * @type {string}
     * @memberof DoctorWithWorkPlacesDto
     */
    'type'?: DoctorWithWorkPlacesDtoTypeEnum;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof DoctorWithWorkPlacesDto
     */
    'userAccount'?: UserAccountDto;
    /**
     * 
     * @type {Array<DoctorWorkPlaceDto>}
     * @memberof DoctorWithWorkPlacesDto
     */
    'workPlaces': Array<DoctorWorkPlaceDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum DoctorWithWorkPlacesDtoTypeEnum {
    Resident = 'RESIDENT',
    Specialist = 'SPECIALIST',
    Expert = 'EXPERT',
    Consultant = 'CONSULTANT',
    Assistant = 'ASSISTANT',
    InternationalConsultant = 'INTERNATIONAL_CONSULTANT',
    PartnerConsultant = 'PARTNER_CONSULTANT'
}


