/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddSickLeaveDto } from '../model';
// @ts-ignore
import { CancelSickLeaveDto } from '../model';
// @ts-ignore
import { GetDocumentContentDto } from '../model';
// @ts-ignore
import { GetZusCancellationReasonsDataDto } from '../model';
// @ts-ignore
import { GetZusPatientDataDto } from '../model';
// @ts-ignore
import { GetZusPayerDataDto } from '../model';
// @ts-ignore
import { PageSickLeaveDto } from '../model';
// @ts-ignore
import { SickLeaveDto } from '../model';
// @ts-ignore
import { ZusCancellationParametersDto } from '../model';
// @ts-ignore
import { ZusInsuredPersonDetailsDto } from '../model';
// @ts-ignore
import { ZusPayerDto } from '../model';
/**
 * SickLeaveControllerApi - axios parameter creator
 * @export
 */
export const SickLeaveControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CancelSickLeaveDto} cancelSickLeaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSickLeave: async (cancelSickLeaveDto: CancelSickLeaveDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cancelSickLeaveDto' is not null or undefined
            assertParamExists('cancelSickLeave', 'cancelSickLeaveDto', cancelSickLeaveDto)
            const localVarPath = `/api/sick-leave/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelSickLeaveDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetDocumentContentDto} getDocumentContentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadZlaPdf: async (getDocumentContentDto: GetDocumentContentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getDocumentContentDto' is not null or undefined
            assertParamExists('downloadZlaPdf', 'getDocumentContentDto', getDocumentContentDto)
            const localVarPath = `/api/sick-leave/pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getDocumentContentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetZusCancellationReasonsDataDto} getZusCancellationReasonsDataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCancellationReasons: async (getZusCancellationReasonsDataDto: GetZusCancellationReasonsDataDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getZusCancellationReasonsDataDto' is not null or undefined
            assertParamExists('getCancellationReasons', 'getZusCancellationReasonsDataDto', getZusCancellationReasonsDataDto)
            const localVarPath = `/api/sick-leave/cancellation-reasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getZusCancellationReasonsDataDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetZusPatientDataDto} getZusPatientDataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsuredPersonDetailsFromZus: async (getZusPatientDataDto: GetZusPatientDataDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getZusPatientDataDto' is not null or undefined
            assertParamExists('getInsuredPersonDetailsFromZus', 'getZusPatientDataDto', getZusPatientDataDto)
            const localVarPath = `/api/sick-leave/insured-person`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getZusPatientDataDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetZusPayerDataDto} getZusPayerDataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayerInfoByNip: async (getZusPayerDataDto: GetZusPayerDataDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getZusPayerDataDto' is not null or undefined
            assertParamExists('getPayerInfoByNip', 'getZusPayerDataDto', getZusPayerDataDto)
            const localVarPath = `/api/sick-leave/payer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getZusPayerDataDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSickLeavesByPatientId: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getSickLeavesByPatientId', 'patientId', patientId)
            const localVarPath = `/api/sick-leave/patient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddSickLeaveDto} addSickLeaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSickLeave: async (addSickLeaveDto: AddSickLeaveDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addSickLeaveDto' is not null or undefined
            assertParamExists('submitSickLeave', 'addSickLeaveDto', addSickLeaveDto)
            const localVarPath = `/api/sick-leave`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addSickLeaveDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SickLeaveControllerApi - functional programming interface
 * @export
 */
export const SickLeaveControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SickLeaveControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CancelSickLeaveDto} cancelSickLeaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSickLeave(cancelSickLeaveDto: CancelSickLeaveDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<SickLeaveDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSickLeave(cancelSickLeaveDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetDocumentContentDto} getDocumentContentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadZlaPdf(getDocumentContentDto: GetDocumentContentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadZlaPdf(getDocumentContentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetZusCancellationReasonsDataDto} getZusCancellationReasonsDataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCancellationReasons(getZusCancellationReasonsDataDto: GetZusCancellationReasonsDataDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<ZusCancellationParametersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCancellationReasons(getZusCancellationReasonsDataDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetZusPatientDataDto} getZusPatientDataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInsuredPersonDetailsFromZus(getZusPatientDataDto: GetZusPatientDataDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<ZusInsuredPersonDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInsuredPersonDetailsFromZus(getZusPatientDataDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetZusPayerDataDto} getZusPayerDataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayerInfoByNip(getZusPayerDataDto: GetZusPayerDataDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<ZusPayerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayerInfoByNip(getZusPayerDataDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSickLeavesByPatientId(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<PageSickLeaveDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSickLeavesByPatientId(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddSickLeaveDto} addSickLeaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitSickLeave(addSickLeaveDto: AddSickLeaveDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<SickLeaveDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitSickLeave(addSickLeaveDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SickLeaveControllerApi - factory interface
 * @export
 */
export const SickLeaveControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SickLeaveControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CancelSickLeaveDto} cancelSickLeaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSickLeave(cancelSickLeaveDto: CancelSickLeaveDto, options?: any): Promise<SickLeaveDto> {
            return localVarFp.cancelSickLeave(cancelSickLeaveDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetDocumentContentDto} getDocumentContentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadZlaPdf(getDocumentContentDto: GetDocumentContentDto, options?: any): Promise<any> {
            return localVarFp.downloadZlaPdf(getDocumentContentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetZusCancellationReasonsDataDto} getZusCancellationReasonsDataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCancellationReasons(getZusCancellationReasonsDataDto: GetZusCancellationReasonsDataDto, options?: any): Promise<ZusCancellationParametersDto> {
            return localVarFp.getCancellationReasons(getZusCancellationReasonsDataDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetZusPatientDataDto} getZusPatientDataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsuredPersonDetailsFromZus(getZusPatientDataDto: GetZusPatientDataDto, options?: any): Promise<ZusInsuredPersonDetailsDto> {
            return localVarFp.getInsuredPersonDetailsFromZus(getZusPatientDataDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetZusPayerDataDto} getZusPayerDataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayerInfoByNip(getZusPayerDataDto: GetZusPayerDataDto, options?: any): Promise<ZusPayerDto> {
            return localVarFp.getPayerInfoByNip(getZusPayerDataDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSickLeavesByPatientId(patientId: number, options?: any): Promise<PageSickLeaveDto> {
            return localVarFp.getSickLeavesByPatientId(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddSickLeaveDto} addSickLeaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSickLeave(addSickLeaveDto: AddSickLeaveDto, options?: any): Promise<Array<SickLeaveDto>> {
            return localVarFp.submitSickLeave(addSickLeaveDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SickLeaveControllerApi - object-oriented interface
 * @export
 * @class SickLeaveControllerApi
 * @extends {BaseAPI}
 */
export class SickLeaveControllerApi extends BaseAPI {
    /**
     * 
     * @param {CancelSickLeaveDto} cancelSickLeaveDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SickLeaveControllerApi
     */
    public cancelSickLeave(cancelSickLeaveDto: CancelSickLeaveDto, options?: AxiosRequestConfig) {
        return SickLeaveControllerApiFp(this.configuration).cancelSickLeave(cancelSickLeaveDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetDocumentContentDto} getDocumentContentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SickLeaveControllerApi
     */
    public downloadZlaPdf(getDocumentContentDto: GetDocumentContentDto, options?: AxiosRequestConfig) {
        return SickLeaveControllerApiFp(this.configuration).downloadZlaPdf(getDocumentContentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetZusCancellationReasonsDataDto} getZusCancellationReasonsDataDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SickLeaveControllerApi
     */
    public getCancellationReasons(getZusCancellationReasonsDataDto: GetZusCancellationReasonsDataDto, options?: AxiosRequestConfig) {
        return SickLeaveControllerApiFp(this.configuration).getCancellationReasons(getZusCancellationReasonsDataDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetZusPatientDataDto} getZusPatientDataDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SickLeaveControllerApi
     */
    public getInsuredPersonDetailsFromZus(getZusPatientDataDto: GetZusPatientDataDto, options?: AxiosRequestConfig) {
        return SickLeaveControllerApiFp(this.configuration).getInsuredPersonDetailsFromZus(getZusPatientDataDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetZusPayerDataDto} getZusPayerDataDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SickLeaveControllerApi
     */
    public getPayerInfoByNip(getZusPayerDataDto: GetZusPayerDataDto, options?: AxiosRequestConfig) {
        return SickLeaveControllerApiFp(this.configuration).getPayerInfoByNip(getZusPayerDataDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SickLeaveControllerApi
     */
    public getSickLeavesByPatientId(patientId: number, options?: AxiosRequestConfig) {
        return SickLeaveControllerApiFp(this.configuration).getSickLeavesByPatientId(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddSickLeaveDto} addSickLeaveDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SickLeaveControllerApi
     */
    public submitSickLeave(addSickLeaveDto: AddSickLeaveDto, options?: AxiosRequestConfig) {
        return SickLeaveControllerApiFp(this.configuration).submitSickLeave(addSickLeaveDto, options).then((request) => request(this.axios, this.basePath));
    }
}
