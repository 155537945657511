/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface AddSickLeavePatientDto
 */
export interface AddSickLeavePatientDto {
    /**
     * 
     * @type {string}
     * @memberof AddSickLeavePatientDto
     */
    'administrativeGenderCode': string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeavePatientDto
     */
    'birthTime': string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeavePatientDto
     */
    'censusTract': string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeavePatientDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeavePatientDto
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeavePatientDto
     */
    'houseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeavePatientDto
     */
    'insuredIn': AddSickLeavePatientDtoInsuredInEnum;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeavePatientDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeavePatientDto
     */
    'passportNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeavePatientDto
     */
    'pesel'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeavePatientDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeavePatientDto
     */
    'postCode': string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeavePatientDto
     */
    'postCodeCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeavePatientDto
     */
    'streetName': string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeavePatientDto
     */
    'surname': string;
    /**
     * 
     * @type {string}
     * @memberof AddSickLeavePatientDto
     */
    'unitID'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AddSickLeavePatientDtoInsuredInEnum {
    Zus = 'ZUS',
    Krus = 'KRUS',
    OtherPl = 'OTHER_PL',
    OtherCountry = 'OTHER_COUNTRY'
}


