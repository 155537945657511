import PlCountries from "./countries-pl";
import EnCountries from "./countries-en";
import FrCountries from "./countries-fr";
import CsCountries from "./countries-cs";
import UkCountries from "./countries-uk";
import HuCountries from "./countries-hu";
import DeCountries from "./countries-de";

export default {

    pl: PlCountries,

    en: EnCountries,

    fr: FrCountries,

    cs: CsCountries,

    uk: UkCountries,

    hu: HuCountries,

    de: DeCountries

}