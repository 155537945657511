/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PrescriptionMedicineDto
 */
export interface PrescriptionMedicineDto {
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedicineDto
     */
    'authorization'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedicineDto
     */
    'customUnit'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedicineDto
     */
    'dosage'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedicineDto
     */
    'packageId'?: string;
    /**
     * 
     * @type {number}
     * @memberof PrescriptionMedicineDto
     */
    'packages'?: number;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedicineDto
     */
    'payment'?: PrescriptionMedicineDtoPaymentEnum;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedicineDto
     */
    'prescriptionType'?: PrescriptionMedicineDtoPrescriptionTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PrescriptionMedicineDtoPaymentEnum {
    X = 'X',
    B = 'B',
    R = 'R',
    P30 = 'P30',
    P50 = 'P50',
    P100 = 'P100'
}
/**
    * @export
    * @enum {string}
    */
export enum PrescriptionMedicineDtoPrescriptionTypeEnum {
    Rp = 'RP',
    Rpw = 'RPW',
    Rps = 'RPS',
    Lz = 'LZ',
    Rplz = 'RPLZ',
    Lzrp = 'LZRP',
    Xlz = 'XLZ',
    Xrplz = 'XRPLZ',
    Rpwlz = 'RPWLZ',
    Rpz = 'RPZ'
}


