/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface MedicalEventBasicDto
 */
export interface MedicalEventBasicDto {
    /**
     * 
     * @type {string}
     * @memberof MedicalEventBasicDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedicalEventBasicDto
     */
    'durationSeconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalEventBasicDto
     */
    'eventType'?: MedicalEventBasicDtoEventTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MedicalEventBasicDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalEventBasicDto
     */
    'occurrenceTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedicalEventBasicDto
     */
    'patientId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum MedicalEventBasicDtoEventTypeEnum {
    Seizure = 'SEIZURE',
    Menstruation = 'MENSTRUATION',
    Infection = 'INFECTION',
    SleepDeprivation = 'SLEEP_DEPRIVATION',
    Fever = 'FEVER',
    PregnancyOrPrePregnancyPeriodStart = 'PREGNANCY_OR_PRE_PREGNANCY_PERIOD_START',
    PregnancyOrPrePregnancyPeriodEnd = 'PREGNANCY_OR_PRE_PREGNANCY_PERIOD_END',
    VnsStimulatorImplantation = 'VNS_STIMULATOR_IMPLANTATION',
    Other = 'OTHER'
}


