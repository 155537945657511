/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DoctorWorkPlaceDto } from './doctor-work-place-dto';
import { MedicalServiceDto } from './medical-service-dto';

/**
 * 
 * @export
 * @interface PatientVisitDto
 */
export interface PatientVisitDto {
    /**
     * 
     * @type {string}
     * @memberof PatientVisitDto
     */
    'doctorAcademicTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientVisitDto
     */
    'doctorFirstName'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientVisitDto
     */
    'doctorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatientVisitDto
     */
    'doctorLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientVisitDto
     */
    'doctorLogin'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientVisitDto
     */
    'doctorPricelistItemId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PatientVisitDto
     */
    'done'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PatientVisitDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatientVisitDto
     */
    'insertionTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientVisitDto
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {MedicalServiceDto}
     * @memberof PatientVisitDto
     */
    'medicalService'?: MedicalServiceDto;
    /**
     * 
     * @type {boolean}
     * @memberof PatientVisitDto
     */
    'paid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientVisitDto
     */
    'paymentDeadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientVisitDto
     */
    'paymentStatus'?: PatientVisitDtoPaymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PatientVisitDto
     */
    'timeFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientVisitDto
     */
    'timeTo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientVisitDto
     */
    'toMove'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientVisitDto
     */
    'visitType'?: PatientVisitDtoVisitTypeEnum;
    /**
     * 
     * @type {DoctorWorkPlaceDto}
     * @memberof PatientVisitDto
     */
    'workplace'?: DoctorWorkPlaceDto;
}

/**
    * @export
    * @enum {string}
    */
export enum PatientVisitDtoPaymentStatusEnum {
    Pending = 'PENDING',
    Completed = 'COMPLETED',
    Canceled = 'CANCELED'
}
/**
    * @export
    * @enum {string}
    */
export enum PatientVisitDtoVisitTypeEnum {
    Remote = 'REMOTE',
    Stationary = 'STATIONARY',
    Absent = 'ABSENT'
}


