/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DoctorBasicDto } from './doctor-basic-dto';
import { DoctorPackageDto } from './doctor-package-dto';

/**
 * 
 * @export
 * @interface ReferralDto
 */
export interface ReferralDto {
    /**
     * 
     * @type {string}
     * @memberof ReferralDto
     */
    'description'?: string;
    /**
     * 
     * @type {DoctorBasicDto}
     * @memberof ReferralDto
     */
    'doctor'?: DoctorBasicDto;
    /**
     * 
     * @type {DoctorPackageDto}
     * @memberof ReferralDto
     */
    'doctorPackage'?: DoctorPackageDto;
    /**
     * 
     * @type {boolean}
     * @memberof ReferralDto
     */
    'free'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReferralDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReferralDto
     */
    'medicalService'?: ReferralDtoMedicalServiceEnum;
    /**
     * 
     * @type {string}
     * @memberof ReferralDto
     */
    'scheduledDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralDto
     */
    'status'?: ReferralDtoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ReferralDto
     */
    'visitId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ReferralDtoMedicalServiceEnum {
    Visit0 = 'VISIT_0',
    Visit1 = 'VISIT_1',
    StandardVisit = 'STANDARD_VISIT',
    FollowupVisit = 'FOLLOWUP_VISIT',
    Message = 'MESSAGE',
    Prescription = 'PRESCRIPTION',
    AbsentConsultation = 'ABSENT_CONSULTATION',
    NeurosurgeonConsultation = 'NEUROSURGEON_CONSULTATION',
    NeuropsychologistConsultation = 'NEUROPSYCHOLOGIST_CONSULTATION',
    NeurologopedistConsultation = 'NEUROLOGOPEDIST_CONSULTATION',
    PsychiatristConsultation = 'PSYCHIATRIST_CONSULTATION',
    GeneticistConsultation = 'GENETICIST_CONSULTATION',
    EpileptologistConsultation = 'EPILEPTOLOGIST_CONSULTATION',
    NeurologistConsultation = 'NEUROLOGIST_CONSULTATION',
    DieticianConsultation = 'DIETICIAN_CONSULTATION',
    VnsConsultation = 'VNS_CONSULTATION',
    MetabolicConsultation = 'METABOLIC_CONSULTATION',
    SleepDisturbanceConsultation = 'SLEEP_DISTURBANCE_CONSULTATION',
    CaseConference = 'CASE_CONFERENCE',
    EegConsultation = 'EEG_CONSULTATION',
    VeegNightConsultation = 'VEEG_NIGHT_CONSULTATION',
    VeegDayConsultation = 'VEEG_DAY_CONSULTATION',
    VeegOnStandbyConsultation1H = 'VEEG_ON_STANDBY_CONSULTATION_1H',
    VeegOnStandbyConsultation2H = 'VEEG_ON_STANDBY_CONSULTATION_2H',
    VeegOnStandbyConsultation6H = 'VEEG_ON_STANDBY_CONSULTATION_6H',
    VeegDuringSleepConsultation1H = 'VEEG_DURING_SLEEP_CONSULTATION_1H',
    VeegDuringSleepConsultation2H = 'VEEG_DURING_SLEEP_CONSULTATION_2H',
    VeegDuringSleepConsultation6H = 'VEEG_DURING_SLEEP_CONSULTATION_6H',
    MigrationVisit = 'MIGRATION_VISIT',
    VeegOnStandbyConsultation = 'VEEG_ON_STANDBY_CONSULTATION',
    VeegDuringSleepConsultation = 'VEEG_DURING_SLEEP_CONSULTATION',
    PartnerConsultation = 'PARTNER_CONSULTATION'
}
/**
    * @export
    * @enum {string}
    */
export enum ReferralDtoStatusEnum {
    New = 'NEW',
    Cancelled = 'CANCELLED',
    Done = 'DONE'
}


