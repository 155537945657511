/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { SurveySeizureDto } from './survey-seizure-dto';

/**
 * 
 * @export
 * @interface VisitSurveySummaryDto
 */
export interface VisitSurveySummaryDto {
    /**
     * 
     * @type {Array<SurveySeizureDto>}
     * @memberof VisitSurveySummaryDto
     */
    'seizures': Array<SurveySeizureDto>;
    /**
     * 
     * @type {string}
     * @memberof VisitSurveySummaryDto
     */
    'sideEffects'?: VisitSurveySummaryDtoSideEffectsEnum;
    /**
     * 
     * @type {string}
     * @memberof VisitSurveySummaryDto
     */
    'treatmentRatingByDoctor'?: VisitSurveySummaryDtoTreatmentRatingByDoctorEnum;
    /**
     * 
     * @type {string}
     * @memberof VisitSurveySummaryDto
     */
    'treatmentRatingByPatient'?: VisitSurveySummaryDtoTreatmentRatingByPatientEnum;
    /**
     * 
     * @type {string}
     * @memberof VisitSurveySummaryDto
     */
    'treatmentSafety'?: VisitSurveySummaryDtoTreatmentSafetyEnum;
    /**
     * 
     * @type {string}
     * @memberof VisitSurveySummaryDto
     */
    'wellBeing'?: VisitSurveySummaryDtoWellBeingEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum VisitSurveySummaryDtoSideEffectsEnum {
    Worst = 'WORST',
    Bad = 'BAD',
    Bearable = 'BEARABLE',
    Minimal = 'MINIMAL',
    NoTreatment = 'NO_TREATMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum VisitSurveySummaryDtoTreatmentRatingByDoctorEnum {
    Ideal = 'IDEAL',
    Good = 'GOOD',
    Mediocre = 'MEDIOCRE',
    Bad = 'BAD',
    Worst = 'WORST',
    NoTreatment = 'NO_TREATMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum VisitSurveySummaryDtoTreatmentRatingByPatientEnum {
    Worst = 'WORST',
    VeryBad = 'VERY_BAD',
    Bad = 'BAD',
    NoChange = 'NO_CHANGE',
    BitBetter = 'BIT_BETTER',
    MuchBetter = 'MUCH_BETTER',
    Ideal = 'IDEAL'
}
/**
    * @export
    * @enum {string}
    */
export enum VisitSurveySummaryDtoTreatmentSafetyEnum {
    Total = 'TOTAL',
    Acceptable = 'ACCEPTABLE',
    Weak = 'WEAK',
    Bad = 'BAD',
    Worst = 'WORST'
}
/**
    * @export
    * @enum {string}
    */
export enum VisitSurveySummaryDtoWellBeingEnum {
    Worst = 'WORST',
    MuchWorse = 'MUCH_WORSE',
    BitWorse = 'BIT_WORSE',
    Same = 'SAME',
    BitBetter = 'BIT_BETTER',
    MuchBetter = 'MUCH_BETTER',
    Ideal = 'IDEAL'
}


