/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DrugActiveSubstanceEntity } from './drug-active-substance-entity';
import { EpilepsyTypeEntity } from './epilepsy-type-entity';

/**
 * 
 * @export
 * @interface EpilepsyTypePoliSubstanceEntity
 */
export interface EpilepsyTypePoliSubstanceEntity {
    /**
     * 
     * @type {DrugActiveSubstanceEntity}
     * @memberof EpilepsyTypePoliSubstanceEntity
     */
    'drugActiveSubstance'?: DrugActiveSubstanceEntity;
    /**
     * 
     * @type {number}
     * @memberof EpilepsyTypePoliSubstanceEntity
     */
    'drugActiveSubstanceEntityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EpilepsyTypePoliSubstanceEntity
     */
    'drugActiveSubstanceSafetyType': EpilepsyTypePoliSubstanceEntityDrugActiveSubstanceSafetyTypeEnum;
    /**
     * 
     * @type {EpilepsyTypeEntity}
     * @memberof EpilepsyTypePoliSubstanceEntity
     */
    'epilepsyType'?: EpilepsyTypeEntity;
    /**
     * 
     * @type {number}
     * @memberof EpilepsyTypePoliSubstanceEntity
     */
    'epilepsyTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EpilepsyTypePoliSubstanceEntity
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof EpilepsyTypePoliSubstanceEntity
     */
    'substanceWeight': number;
}

/**
    * @export
    * @enum {string}
    */
export enum EpilepsyTypePoliSubstanceEntityDrugActiveSubstanceSafetyTypeEnum {
    Safe = 'SAFE',
    MediumSafe = 'MEDIUM_SAFE',
    NotSafe = 'NOT_SAFE'
}


