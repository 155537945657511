export class TrackingService {

    public trackEvent(eventType: string, payload: any) {
        this.pushToGtm(eventType, payload);
        this.pushToFbq(eventType, payload);
    }

    private pushToGtm(eventType: string, payload: any) {
        const dataLayer = window['dataLayer'];
        if (dataLayer && dataLayer.push) {
            const gtmEvent = payload ? {event: eventType, ...payload} : {event: eventType};
            dataLayer.push(gtmEvent);
        }
    }

    private pushToFbq(eventType: string, payload: any) {
        const fbqTracking = window['fbq'];
        if (fbqTracking) {
            fbqTracking('track', eventType, payload || undefined);
        }
    }
}