export default {
  "AD": {
    "code": "AD",
    "name": "Andorra"
  },
  "AE": {
    "code": "AE",
    "name": "Spojené arabské emiráty"
  },
  "AF": {
    "code": "AF",
    "name": "Afghánistán"
  },
  "AG": {
    "code": "AG",
    "name": "Antigua a Barbuda"
  },
  "AI": {
    "code": "AI",
    "name": "Anguilla"
  },
  "AL": {
    "code": "AL",
    "name": "Albánie"
  },
  "AM": {
    "code": "AM",
    "name": "Arménie"
  },
  "AO": {
    "code": "AO",
    "name": "Angola"
  },
  "AQ": {
    "code": "AQ",
    "name": "Antarktida"
  },
  "AR": {
    "code": "AR",
    "name": "Argentina"
  },
  "AS": {
    "code": "AS",
    "name": "Americká Samoa"
  },
  "AT": {
    "code": "AT",
    "name": "Rakousko"
  },
  "AU": {
    "code": "AU",
    "name": "Austrálie"
  },
  "AW": {
    "code": "AW",
    "name": "Aruba"
  },
  "AX": {
    "code": "AX",
    "name": "? pozemní ostrovy"
  },
  "AZ": {
    "code": "AZ",
    "name": "Ázerbajdžán"
  },
  "BA": {
    "code": "BA",
    "name": "Bosna a Hercegovina"
  },
  "BB": {
    "code": "BB",
    "name": "Barbados"
  },
  "BD": {
    "code": "BD",
    "name": "Bangladéš"
  },
  "BE": {
    "code": "BE",
    "name": "Belgie"
  },
  "BF": {
    "code": "BF",
    "name": "Burkina Faso"
  },
  "BG": {
    "code": "BG",
    "name": "Bulharsko"
  },
  "BH": {
    "code": "BH",
    "name": "Bahrajn"
  },
  "BI": {
    "code": "BI",
    "name": "Burundi"
  },
  "BJ": {
    "code": "BJ",
    "name": "Benin"
  },
  "BL": {
    "code": "BL",
    "name": "Svatý Bartoloměj"
  },
  "BM": {
    "code": "BM",
    "name": "Bermudy"
  },
  "BN": {
    "code": "BN",
    "name": "Brunej Darussalam"
  },
  "BO": {
    "code": "BO",
    "name": "Bolívie, mnohonárodnostní stát"
  },
  "BQ": {
    "code": "BQ",
    "name": "Bonaire, Sint Eustatius a Saba"
  },
  "BR": {
    "code": "BR",
    "name": "Brazílie"
  },
  "BS": {
    "code": "BS",
    "name": "Bahamy"
  },
  "BT": {
    "code": "BT",
    "name": "Bhútán"
  },
  "BV": {
    "code": "BV",
    "name": "Bouvetův ostrov"
  },
  "BW": {
    "code": "BW",
    "name": "Botswana"
  },
  "BY": {
    "code": "BY",
    "name": "Bělorusko"
  },
  "BZ": {
    "code": "BZ",
    "name": "Belize"
  },
  "CA": {
    "code": "CA",
    "name": "Kanada"
  },
  "CC": {
    "code": "CC",
    "name": "Kokosové (Keelingovy) ostrovy"
  },
  "CD": {
    "code": "CD",
    "name": "Konžská demokratická republika"
  },
  "CF": {
    "code": "CF",
    "name": "Středoafrická republika"
  },
  "CG": {
    "code": "CG",
    "name": "Kongo"
  },
  "CH": {
    "code": "CH",
    "name": "Švýcarsko"
  },
  "CI": {
    "code": "CI",
    "name": "Pobřeží slonoviny"
  },
  "CK": {
    "code": "CK",
    "name": "Cookovy ostrovy"
  },
  "CL": {
    "code": "CL",
    "name": "Chile"
  },
  "CM": {
    "code": "CM",
    "name": "Kamerun"
  },
  "CN": {
    "code": "CN",
    "name": "Čína"
  },
  "CO": {
    "code": "CO",
    "name": "Kolumbie"
  },
  "CR": {
    "code": "CR",
    "name": "Kostarika"
  },
  "CU": {
    "code": "CU",
    "name": "Kuba"
  },
  "CV": {
    "code": "CV",
    "name": "Kapverdy"
  },
  "CW": {
    "code": "CW",
    "name": "Curaçao"
  },
  "CX": {
    "code": "CX",
    "name": "Vánoční ostrov"
  },
  "CY": {
    "code": "CY",
    "name": "Kypr"
  },
  "CZ": {
    "code": "CZ",
    "name": "Česká republika"
  },
  "DE": {
    "code": "DE",
    "name": "Německo"
  },
  "DJ": {
    "code": "DJ",
    "name": "Džibuti"
  },
  "DK": {
    "code": "DK",
    "name": "Dánsko"
  },
  "DM": {
    "code": "DM",
    "name": "Dominika"
  },
  "DO": {
    "code": "DO",
    "name": "Dominikánská republika"
  },
  "DZ": {
    "code": "DZ",
    "name": "Alžírsko"
  },
  "EC": {
    "code": "EC",
    "name": "Ekvádor"
  },
  "EE": {
    "code": "EE",
    "name": "Estonsko"
  },
  "EG": {
    "code": "EG",
    "name": "Egypt"
  },
  "EH": {
    "code": "EH",
    "name": "západní Sahara"
  },
  "ER": {
    "code": "ER",
    "name": "Eritrea"
  },
  "ES": {
    "code": "ES",
    "name": "Španělsko"
  },
  "ET": {
    "code": "ET",
    "name": "Etiopie"
  },
  "FI": {
    "code": "FI",
    "name": "Finsko"
  },
  "FJ": {
    "code": "FJ",
    "name": "Fidži"
  },
  "FK": {
    "code": "FK",
    "name": "Falklandské ostrovy (Malvíny)"
  },
  "FM": {
    "code": "FM",
    "name": "Mikronésie, Federativní státy"
  },
  "FO": {
    "code": "FO",
    "name": "Faerské ostrovy"
  },
  "FR": {
    "code": "FR",
    "name": "Francie"
  },
  "GA": {
    "code": "GA",
    "name": "Gabon"
  },
  "GB": {
    "code": "GB",
    "name": "Spojené království"
  },
  "GD": {
    "code": "GD",
    "name": "Grenada"
  },
  "GE": {
    "code": "GE",
    "name": "Gruzie"
  },
  "GF": {
    "code": "GF",
    "name": "Francouzská Guyana"
  },
  "GG": {
    "code": "GG",
    "name": "Guernsey"
  },
  "GH": {
    "code": "GH",
    "name": "Ghana"
  },
  "GI": {
    "code": "GI",
    "name": "Gibraltar"
  },
  "GL": {
    "code": "GL",
    "name": "Grónsko"
  },
  "GM": {
    "code": "GM",
    "name": "Gambie"
  },
  "GN": {
    "code": "GN",
    "name": "Guinea"
  },
  "GP": {
    "code": "GP",
    "name": "Guadeloupe"
  },
  "GQ": {
    "code": "GQ",
    "name": "Rovníková Guinea"
  },
  "GR": {
    "code": "GR",
    "name": "Řecko"
  },
  "GS": {
    "code": "GS",
    "name": "Jižní Georgie a Jižní Sandwichovy ostrovy"
  },
  "GT": {
    "code": "GT",
    "name": "Guatemala"
  },
  "GU": {
    "code": "GU",
    "name": "Guam"
  },
  "GW": {
    "code": "GW",
    "name": "Guinea-Bissau"
  },
  "GY": {
    "code": "GY",
    "name": "Guyana"
  },
  "HK": {
    "code": "HK",
    "name": "Hongkong"
  },
  "HM": {
    "code": "HM",
    "name": "Heardův ostrov a McDonaldovy ostrovy"
  },
  "HN": {
    "code": "HN",
    "name": "Honduras"
  },
  "HR": {
    "code": "HR",
    "name": "Chorvatsko"
  },
  "HT": {
    "code": "HT",
    "name": "Haiti"
  },
  "HU": {
    "code": "HU",
    "name": "Maďarsko"
  },
  "ID": {
    "code": "ID",
    "name": "Indonésie"
  },
  "IE": {
    "code": "IE",
    "name": "Irsko"
  },
  "IL": {
    "code": "IL",
    "name": "Izrael"
  },
  "IM": {
    "code": "IM",
    "name": "Isle of Man"
  },
  "IN": {
    "code": "IN",
    "name": "Indie"
  },
  "IO": {
    "code": "IO",
    "name": "Britské území v Indickém oceánu"
  },
  "IQ": {
    "code": "IQ",
    "name": "Irák"
  },
  "IR": {
    "code": "IR",
    "name": "Írán, Islámská republika"
  },
  "IS": {
    "code": "IS",
    "name": "Island"
  },
  "IT": {
    "code": "IT",
    "name": "Itálie"
  },
  "JE": {
    "code": "JE",
    "name": "Trikot"
  },
  "JM": {
    "code": "JM",
    "name": "Jamaica"
  },
  "JO": {
    "code": "JO",
    "name": "Jordán"
  },
  "JP": {
    "code": "JP",
    "name": "Japonsko"
  },
  "KE": {
    "code": "KE",
    "name": "Keňa"
  },
  "KG": {
    "code": "KG",
    "name": "Kyrgyzstán"
  },
  "KH": {
    "code": "KH",
    "name": "Kambodža"
  },
  "KI": {
    "code": "KI",
    "name": "Kiribati"
  },
  "KM": {
    "code": "KM",
    "name": "Komory"
  },
  "KN": {
    "code": "KN",
    "name": "Svatý Kryštof a Nevis"
  },
  "KP": {
    "code": "KP",
    "name": "Korea, Demokratická lidová republika"
  },
  "KR": {
    "code": "KR",
    "name": "Korejská republika"
  },
  "KW": {
    "code": "KW",
    "name": "Kuvajt"
  },
  "KY": {
    "code": "KY",
    "name": "Kajmanské ostrovy"
  },
  "KZ": {
    "code": "KZ",
    "name": "Kazachstán"
  },
  "LA": {
    "code": "LA",
    "name": "Laoská lidově demokratická republika"
  },
  "LB": {
    "code": "LB",
    "name": "Libanon"
  },
  "LC": {
    "code": "LC",
    "name": "Svatá Lucie"
  },
  "LI": {
    "code": "LI",
    "name": "Lichtenštejnsko"
  },
  "LK": {
    "code": "LK",
    "name": "Srí Lanka"
  },
  "LR": {
    "code": "LR",
    "name": "Libérie"
  },
  "LS": {
    "code": "LS",
    "name": "Lesotho"
  },
  "LT": {
    "code": "LT",
    "name": "Litva"
  },
  "LU": {
    "code": "LU",
    "name": "Lucembursko"
  },
  "LV": {
    "code": "LV",
    "name": "Lotyšsko"
  },
  "LY": {
    "code": "LY",
    "name": "Libye"
  },
  "MA": {
    "code": "MA",
    "name": "Maroko"
  },
  "MC": {
    "code": "MC",
    "name": "Monako"
  },
  "MD": {
    "code": "MD",
    "name": "Moldavská republika"
  },
  "ME": {
    "code": "ME",
    "name": "Černá Hora"
  },
  "MF": {
    "code": "MF",
    "name": "Svatý Martin (francouzská část)"
  },
  "MG": {
    "code": "MG",
    "name": "Madagaskar"
  },
  "MH": {
    "code": "MH",
    "name": "Marshallovy ostrovy"
  },
  "MK": {
    "code": "MK",
    "name": "Makedonie, Bývalá jugoslávská republika"
  },
  "ML": {
    "code": "ML",
    "name": "Mali"
  },
  "MM": {
    "code": "MM",
    "name": "Myanmar"
  },
  "MN": {
    "code": "MN",
    "name": "Mongolsko"
  },
  "MO": {
    "code": "MO",
    "name": "Macao"
  },
  "MP": {
    "code": "MP",
    "name": "Severní Mariany"
  },
  "MQ": {
    "code": "MQ",
    "name": "Martinik"
  },
  "MR": {
    "code": "MR",
    "name": "Mauretánie"
  },
  "MS": {
    "code": "MS",
    "name": "Montserrat"
  },
  "MT": {
    "code": "MT",
    "name": "Malta"
  },
  "MU": {
    "code": "MU",
    "name": "Mauricius"
  },
  "MV": {
    "code": "MV",
    "name": "Maledivy"
  },
  "MW": {
    "code": "MW",
    "name": "Malawi"
  },
  "MX": {
    "code": "MX",
    "name": "Mexiko"
  },
  "MY": {
    "code": "MY",
    "name": "Malajsie"
  },
  "MZ": {
    "code": "MZ",
    "name": "Mosambik"
  },
  "NA": {
    "code": "NA",
    "name": "Namibie"
  },
  "NC": {
    "code": "NC",
    "name": "Nová Kaledonie"
  },
  "NE": {
    "code": "NE",
    "name": "Niger"
  },
  "NF": {
    "code": "NF",
    "name": "Ostrov Norfolk"
  },
  "NG": {
    "code": "NG",
    "name": "Nigérie"
  },
  "NI": {
    "code": "NI",
    "name": "Nikaragua"
  },
  "NL": {
    "code": "NL",
    "name": "Holandsko"
  },
  "NO": {
    "code": "NO",
    "name": "Norsko"
  },
  "NP": {
    "code": "NP",
    "name": "Nepál"
  },
  "NR": {
    "code": "NR",
    "name": "Nauru"
  },
  "NU": {
    "code": "NU",
    "name": "Niue"
  },
  "NZ": {
    "code": "NZ",
    "name": "Nový Zéland"
  },
  "OM": {
    "code": "OM",
    "name": "Omán"
  },
  "PA": {
    "code": "PA",
    "name": "Panama"
  },
  "PE": {
    "code": "PE",
    "name": "Peru"
  },
  "PF": {
    "code": "PF",
    "name": "Francouzská Polynésie"
  },
  "PG": {
    "code": "PG",
    "name": "Papua-Nová Guinea"
  },
  "PH": {
    "code": "PH",
    "name": "Filipíny"
  },
  "PK": {
    "code": "PK",
    "name": "Pákistán"
  },
  "PL": {
    "code": "PL",
    "name": "Polsko"
  },
  "PM": {
    "code": "PM",
    "name": "Svatý Pierre a Miquelon"
  },
  "PN": {
    "code": "PN",
    "name": "Pitcairn"
  },
  "PR": {
    "code": "PR",
    "name": "Portoriko"
  },
  "PS": {
    "code": "PS",
    "name": "Palestina, stát"
  },
  "PT": {
    "code": "PT",
    "name": "Portugalsko"
  },
  "PW": {
    "code": "PW",
    "name": "Palau"
  },
  "PY": {
    "code": "PY",
    "name": "Paraguay"
  },
  "QA": {
    "code": "QA",
    "name": "Katar"
  },
  "RE": {
    "code": "RE",
    "name": "Shledání"
  },
  "RO": {
    "code": "RO",
    "name": "Rumunsko"
  },
  "RS": {
    "code": "RS",
    "name": "Srbsko"
  },
  "RU": {
    "code": "RU",
    "name": "Ruská Federace"
  },
  "RW": {
    "code": "RW",
    "name": "Rwanda"
  },
  "SA": {
    "code": "SA",
    "name": "Saudská arábie"
  },
  "SB": {
    "code": "SB",
    "name": "Solomonovy ostrovy"
  },
  "SC": {
    "code": "SC",
    "name": "Seychely"
  },
  "SD": {
    "code": "SD",
    "name": "Súdán"
  },
  "SE": {
    "code": "SE",
    "name": "Švédsko"
  },
  "SG": {
    "code": "SG",
    "name": "Singapur"
  },
  "SH": {
    "code": "SH",
    "name": "Svatá Helena, Nanebevstoupení a Tristan da Cunha"
  },
  "SI": {
    "code": "SI",
    "name": "Slovinsko"
  },
  "SJ": {
    "code": "SJ",
    "name": "Špicberky a Jan Mayen"
  },
  "SK": {
    "code": "SK",
    "name": "Slovensko"
  },
  "SL": {
    "code": "SL",
    "name": "Sierra Leone"
  },
  "SM": {
    "code": "SM",
    "name": "San Marino"
  },
  "SN": {
    "code": "SN",
    "name": "Senegal"
  },
  "SO": {
    "code": "SO",
    "name": "Somálsko"
  },
  "SR": {
    "code": "SR",
    "name": "Surinam"
  },
  "SS": {
    "code": "SS",
    "name": "jižní Súdán"
  },
  "ST": {
    "code": "ST",
    "name": "Svatý Tomáš a Princův ostrov"
  },
  "SV": {
    "code": "SV",
    "name": "El Salvador"
  },
  "SX": {
    "code": "SX",
    "name": "Sint Maarten (holandská část)"
  },
  "SY": {
    "code": "SY",
    "name": "Syrská Arabská republika"
  },
  "SZ": {
    "code": "SZ",
    "name": "Svazijsko"
  },
  "TC": {
    "code": "TC",
    "name": "ostrovy Turks a Cacois"
  },
  "TD": {
    "code": "TD",
    "name": "Čad"
  },
  "TF": {
    "code": "TF",
    "name": "Francouzská jižní území"
  },
  "TG": {
    "code": "TG",
    "name": "Jít"
  },
  "TH": {
    "code": "TH",
    "name": "Thajsko"
  },
  "TJ": {
    "code": "TJ",
    "name": "Tádžikistán"
  },
  "TK": {
    "code": "TK",
    "name": "Tokelau"
  },
  "TL": {
    "code": "TL",
    "name": "Východní Timor"
  },
  "TM": {
    "code": "TM",
    "name": "Turkmenistán"
  },
  "TN": {
    "code": "TN",
    "name": "Tunisko"
  },
  "TO": {
    "code": "TO",
    "name": "Tonga"
  },
  "TR": {
    "code": "TR",
    "name": "krocan"
  },
  "TT": {
    "code": "TT",
    "name": "Trinidad a Tobago"
  },
  "TV": {
    "code": "TV",
    "name": "Tuvalu"
  },
  "TW": {
    "code": "TW",
    "name": "Tchaj-wan, provincie Číny"
  },
  "TZ": {
    "code": "TZ",
    "name": "Tanzanie, sjednocená republika"
  },
  "UA": {
    "code": "UA",
    "name": "Ukrajina"
  },
  "UG": {
    "code": "UG",
    "name": "Uganda"
  },
  "UM": {
    "code": "UM",
    "name": "Menší odlehlé ostrovy Spojených států"
  },
  "US": {
    "code": "US",
    "name": "Spojené státy"
  },
  "UY": {
    "code": "UY",
    "name": "Uruguay"
  },
  "UZ": {
    "code": "UZ",
    "name": "Uzbekistán"
  },
  "VA": {
    "code": "VA",
    "name": "Svatý stolec (Vatikánský městský stát)"
  },
  "VC": {
    "code": "VC",
    "name": "Svatý Vincenc a Grenadiny"
  },
  "VE": {
    "code": "VE",
    "name": "Venezuela, Bolívarovská republika"
  },
  "VG": {
    "code": "VG",
    "name": "Panenské ostrovy, Britové"
  },
  "VI": {
    "code": "VI",
    "name": "Panenské ostrovy, USA"
  },
  "VN": {
    "code": "VN",
    "name": "Vietnam"
  },
  "VU": {
    "code": "VU",
    "name": "Vanuatu"
  },
  "WF": {
    "code": "WF",
    "name": "Wallis a Futuna"
  },
  "WS": {
    "code": "WS",
    "name": "Samoa"
  },
  "YE": {
    "code": "YE",
    "name": "Jemen"
  },
  "YT": {
    "code": "YT",
    "name": "Mayotte"
  },
  "ZA": {
    "code": "ZA",
    "name": "Jižní Afrika"
  },
  "ZM": {
    "code": "ZM",
    "name": "Zambie"
  },
  "ZW": {
    "code": "ZW",
    "name": "Zimbabwe"
  }
}