import Vue from "vue";
import Vuetify from "vuetify/lib";

import i18n from "./i18n";
import pl from "vuetify/es5/locale/pl";
import en from "vuetify/es5/locale/en";
import {Scroll} from "vuetify/lib/directives";
import InfectionIcon from "../component/icon/infection.icon.component";
import FeverIcon from "../component/icon/fever.icon.component";
import SeizureIcon from "../component/icon/seizure.icon.component";
import PeriodIcon from "../component/icon/period.icon.component";
import InsomniaIcon from "../component/icon/insomnia.icon.component";
import TherapyIcon from "../component/icon/therapy.menu.icon.component";
import VisitsIcon from "../component/icon/visits.menu.icon.component";

Vue.use(Vuetify, {
    directives: {
        Scroll
    }
});

export default new Vuetify({
    lang: {
        t: (key, ...params) => i18n.t(key, params),
        locales: {pl, en},
        current: i18n.locale
    },
    icons: {
        iconfont: 'mdi',
        values: {
            infection: {
                component: InfectionIcon,
            },
            fever: {
                component: FeverIcon,
            },
            seizure: {
                component: SeizureIcon,
            },
            period: {
                component: PeriodIcon,
            },
            insomnia: {
                component: InsomniaIcon,
            },
            therapy: {
                component: TherapyIcon,
            },
            visits: {
                component: VisitsIcon,
            }
        }
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: {
                    base: '#2399D5',
                    darken1: '#397BE7'
                },
                secondary: {
                    base: '#c2e4f9',
                    lighten1: '#DDE4F3',
                    darken1: '#acb0c0',
                    darken2: '#9BA5B7'
                },
                accent: {
                    base: '#CCEEFF',
                    darken4: '#232632'
                },
                success: '#78BD1E',
                warning: '#fb8c00',
                error: '#ff7272'
            }
        }
    }
});
