/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BrainActivityDtoEegSeizureChangesEnum
 */
export interface BrainActivityDtoEegSeizureChangesEnum {
    /**
     * 
     * @type {string}
     * @memberof BrainActivityDtoEegSeizureChangesEnum
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BrainActivityDtoEegSeizureChangesEnum
     */
    'electrodes'?: Array<BrainActivityDtoEegSeizureChangesEnumElectrodesEnum>;
    /**
     * 
     * @type {string}
     * @memberof BrainActivityDtoEegSeizureChangesEnum
     */
    'lateralization'?: BrainActivityDtoEegSeizureChangesEnumLateralizationEnum;
    /**
     * 
     * @type {string}
     * @memberof BrainActivityDtoEegSeizureChangesEnum
     */
    'name': BrainActivityDtoEegSeizureChangesEnumNameEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof BrainActivityDtoEegSeizureChangesEnum
     */
    'regions'?: Array<BrainActivityDtoEegSeizureChangesEnumRegionsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum BrainActivityDtoEegSeizureChangesEnumElectrodesEnum {
    Fp1 = 'FP1',
    F3 = 'F3',
    C3 = 'C3',
    P3 = 'P3',
    F7 = 'F7',
    T7 = 'T7',
    P7 = 'P7',
    O1 = 'O1',
    F9 = 'F9',
    T9 = 'T9',
    P9 = 'P9',
    Fz = 'FZ',
    Cz = 'CZ',
    Pz = 'PZ',
    Fp2 = 'FP2',
    F4 = 'F4',
    C4 = 'C4',
    P4 = 'P4',
    F8 = 'F8',
    T8 = 'T8',
    P8 = 'P8',
    O2 = 'O2',
    F10 = 'F10',
    T10 = 'T10',
    P10 = 'P10'
}
/**
    * @export
    * @enum {string}
    */
export enum BrainActivityDtoEegSeizureChangesEnumLateralizationEnum {
    RightSide = 'RIGHT_SIDE',
    LeftSide = 'LEFT_SIDE',
    BothSide = 'BOTH_SIDE'
}
/**
    * @export
    * @enum {string}
    */
export enum BrainActivityDtoEegSeizureChangesEnumNameEnum {
    None = 'NONE',
    Artifacts = 'ARTIFACTS',
    LowVoltageActivity = 'LOW_VOLTAGE_ACTIVITY',
    RhythmicActivity = 'RHYTHMIC_ACTIVITY',
    SlowWaves = 'SLOW_WAVES',
    IrregularActivity = 'IRREGULAR_ACTIVITY',
    Spires = 'SPIRES',
    MultiSpire = 'MULTI_SPIRE',
    SharpWaves = 'SHARP_WAVES',
    SpireAssembly = 'SPIRE_ASSEMBLY',
    MultiSpireAssembly = 'MULTI_SPIRE_ASSEMBLY',
    BurstSuppression = 'BURST_SUPPRESSION',
    ElectroDecrement = 'ELECTRO_DECREMENT',
    DcShift = 'DC_SHIFT',
    Oscillations = 'OSCILLATIONS',
    ActivityDisappearance = 'ACTIVITY_DISAPPEARANCE',
    OtherPattern = 'OTHER_PATTERN'
}
/**
    * @export
    * @enum {string}
    */
export enum BrainActivityDtoEegSeizureChangesEnumRegionsEnum {
    Frontal = 'FRONTAL',
    Temporal = 'TEMPORAL',
    Central = 'CENTRAL',
    Parietal = 'PARIETAL',
    Occipital = 'OCCIPITAL',
    General = 'GENERAL'
}


