/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AddressDto } from './address-dto';

/**
 * 
 * @export
 * @interface DoctorAccountGeneralInfoDto
 */
export interface DoctorAccountGeneralInfoDto {
    /**
     * 
     * @type {AddressDto}
     * @memberof DoctorAccountGeneralInfoDto
     */
    'address'?: AddressDto;
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountGeneralInfoDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountGeneralInfoDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountGeneralInfoDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountGeneralInfoDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountGeneralInfoDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountGeneralInfoDto
     */
    'sex'?: DoctorAccountGeneralInfoDtoSexEnum;
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountGeneralInfoDto
     */
    'workCertNr'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DoctorAccountGeneralInfoDtoSexEnum {
    Male = 'MALE',
    Female = 'FEMALE'
}


