export default {
  "user": {
    "notFound": "Utilisateur non trouvé",
    "alreadyExists": "L'utilisateur avec une connexion donnée existe déjà",
    "unauthorized": "Vous ne pouvez pas effectuer cette action car vous n’avez pas d’autorisation",
    "emailAlreadyExists": "Cette adresse e-mail est déjà enregistrée dans le système",
    "cannotRemove": {
      "paymentsNotRefunded": "Le compte ne peut pas être supprimé en raison de paiements non retournés"
    }
  },
  "doctor": {
    "notFound": "Médecin introuvable",
    "priceList": {
      "notFound": "Liste de prix du médecin introuvable",
      "wrongGrossPrice": "Mauvais prix brut sélectionné",
      "notAvailable": "Le service sélectionné n'est pas disponible",
      "grossPriceCalculation": "Mauvais calcul du prix de la visite",
      "durationOutOfSync": "La durée des services du type sélectionné doit être la même"
    },
    "medicalService": {
      "notFound": "Service médical introuvable"
    },
    "forms": {
      "nfzContract": {
        "numberDuplicated": "Un médecin avec un numéro de contrat NFZ spécifié existe déjà"
      }
    },
    "certificate": {
      "numberInvalid": "Niepoprawny numer certyfikatu lekarza"
    },
    "selection": {
      "request": {
        "alreadyPending": "Une demande de changement de médecin chef de file a déjà été envoyée et attend la réponse du médecin",
        "incorrectStatus": "Une demande de changement de médecin responsable a un statut incorrect",
        "incorrectState": "Une demande de changement de médecin responsable est dans un état incorrect"
      }
    },
    "missingCertificate": "Le certificat ZUS est manquant"
  },
  "patient": {
    "notFound": "Patient non trouvé",
    "mood": {
      "future": "Vous ne pouvez pas ajouter d'humeur du futur"
    }
  },
  "position": {
    "notFound": "Position introuvable"
  },
  "visit": {
    "notFound": "Visite introuvable",
    "notDone": "Visite non effectuée",
    "notPaid": "Visite non payée",
    "edit": {
      "notAllowed": "Modification de la visite non autorisée"
    },
    "time": {
      "booked": "Date du passé sélectionnée"
    },
    "appointment": {
      "typeInvalid": "Type de rendez-vous non valide"
    },
    "recommendations": {
      "empty": "Les recommandations doivent être remplies avant de terminer la visite"
    },
    "processing": {
      "error": "Une erreur s'est produite lors du traitement de la visite"
    },
    "summary": {
      "pdf": "Une erreur s'est produite lors de la génération du document PDF à visiter"
    },
    "message": {
      "notInitialized": "La conversation n'a pas encore été lancée par le patient",
      "limitExceeded": "La limite de messages pour une visite a été épuisée",
      "notResponded": "Votre dernier message n'a pas encore reçu de réponse"
    },
    "invoice": {
      "pdf": "Une erreur s'est produite lors de la génération du pdf de la facture pour la visite"
    },
    "afterVisitZeroAvailable": "Fonctionnalité non disponible avant la première visite",
    "alreadyReported": "La visite a déjà été signalée à P1 et ne peut plus être modifiée",
    "paymentMarkingNotAllowed": "Cette visite ne peut pas être notée car le prix est trop élevé",
    "changeDisabled": "La date ou le médecin pour ce rendez-vous a déjà été modifié. Le système ne permet pas un autre changement. En cas de doute, contactez-nous."
  },
  "payment": {
    "receipt": {
      "pdf": "Une erreur s'est produite lors de la génération du reçu de paiement"
    },
    "creation": "Une erreur s'est produite lors de l'enregistrement du mode de paiement",
    "visit": {
      "alreadyDone": "Visite déjà payée",
      "stillProcessing": "La visite est toujours en cours de traitement",
      "unknownState": "Visite erreur de paiement, veuillez contacter l'équipe Neuroterminal"
    },
    "genericError": "Une erreur s'est produite lors du traitement du paiement",
    "alreadyDone": "Paiement déjà effectué"
  },
  "date": {
    "selected": {
      "fromPast": "Temps de visite réservé"
    },
    "range": {
      "collision": "Les plages de dates sélectionnées sont en conflit les unes avec les autres"
    }
  },
  "consultation": {
    "notFound": "Consultation introuvable"
  },
  "nip": {
    "invalid": "NIP invalide"
  },
  "medicalEvent": {
    "notFound": "Événement introuvable",
    "invalidData": "Données d'événement non valides"
  },
  "medicalRequest": {
    "notFound": "Demande introuvable",
    "invalidData": "Requête invalide",
    "alreadyAssigned": "Demande déjà attribuée"
  },
  "medicalResult": {
    "invalidData": "Résultat invalide",
    "notFound": "Résultat non trouvé",
    "description": {
      "notFound": "Description du résultat introuvable"
    },
    "file": {
      "invalidData": "Données de fichier de résultats non valides"
    }
  },
  "medicalDocument": {
    "addError": "Une erreur s'est produite lors de l'enregistrement du document",
    "editError": "Une erreur s'est produite lors de l'édition du document",
    "downloadError": "Une erreur s'est produite lors du téléchargement du fichier",
    "deleteError": "Une erreur s'est produite lors de la suppression du document",
    "file": {
      "saveError": "Une erreur s'est produite lors de l'enregistrement du fichier"
    }
  },
  "drugStock": {
    "negativeAmount": "Le montant ne peut pas être inférieur à 0",
    "notUnique": "Vous ne pouvez pas sélectionner le même produit plusieurs fois"
  },
  "product": {
    "notFound": "Produit non trouvé",
    "notUnique": "Vous ne pouvez pas sélectionner le même produit plusieurs fois"
  },
  "patientSeizuresHistory": {
    "futureDateSelected": "La date future a été sélectionnée",
    "periodConflict": "Les périodes sélectionnées sont en conflit les unes avec les autres"
  },
  "healthSurvey": {
    "alreadyInitialized": "Vos antécédents médicaux ont déjà été initialisés",
    "patientHasNotEpilepsy": "Seuls les patients épileptiques ont accès à l'enquête 0"
  },
  "form": {
    "invalidEmail": "Adresse e-mail invalide",
    "invalidPhone": "Numéro de téléphone invalide"
  },
  "passwordReset": {
    "tokenIncorrect": "Le lien envoyé est incorrect lub expiré",
    "internalError": "Erreur lors du traitement de la demande de changement de mot de passe",
    "unsecure": "Le mot de passe doit contenir au moins 10 caractères, des lettres majuscules et minuscules, des chiffres et des caractères spéciaux"
  },
  "email": {
    "sendError": "Une erreur d'envoi d'e-mail s'est produite"
  },
  "sms": {
    "noPhone": "Numéro de téléphone inconnu pour l'utilisateur sélectionné"
  },
  "videochat": {
    "getTokenFailed": "Une erreur de connexion s'est produite",
    "sessionExpired": "Le temps de visite a expiré. La connexion ne peut pas être établie."
  },
  "doctorNote": {
    "notFound": "Nie odnaleziono notatki"
  },
  "unexpected": "Une erreur inattendue s'est produite. Réessayez plus tard",
  "patientSeizureCharacteristics": {
    "notFound": "L'historique des crises du patient n'était pas complètement rempli"
  },
  "referral": {
    "notFound": "Référence introuvable",
    "cancelFree": "Impossible d'annuler le parrainage pour une visite gratuite"
  },
  "harmonogram": {
    "notFound": "La date de visite choisie est déjà réservée"
  },
  "incompleteData": "Les données sont incomplètes",
  "cered": {
    "prescription": {
      "creation": "Une erreur s'est produite lors de la délivrance de l'ordonnance",
      "psychotropicNotUnique": "L'ordonnance ne peut pas contenir une combinaison de médicaments psychotropes avec d'autres médicaments",
      "prescriptionTypeNotUnique": "L'ordonnance ne peut pas contenir une combinaison de médicaments RP i RPW",
      "prescriptionFileNotAvailable": "L'impression d'ordonnance n'est pas disponible"
    },
    "ewus": {
      "login": "Une erreur s'est produite lors de la connexion à EWUŚ",
      "check": "Une erreur s'est produite lors de la vérification de l'assurance des patients dans EWUŚ"
    },
    "ereferral": {
      "creation": "Une erreur s'est produite lors de l'envoi de la recommandation électronique"
    },
    "zus": {
      "connect": "Une erreur s'est produite lors de la connexion à ZUS",
      "cannotCancel": "Ce congé de maladie ne peut être annulé",
      "cannotFindWorkPlace": "Impossible de trouver le lieu de travail NEUROSPHERA dans le compte ZUS"
    }
  },
  "icd10": {
    "notUnique": "Vous ne pouvez pas sélectionner le même icd10 plusieurs fois"
  },
  "visitPackage": {
    "dateCollision": "Les visites à forfait ne peuvent pas être fixées le même jour",
    "invalidService": "Certains des services sélectionnés ne sont pas disponibles dans ce forfait",
    "moveDateCollision": "Le nouveau rendez-vous chevauche un autre rendez-vous dans le package",
    "someVisitsDone": "Les visites d'un forfait déjà commencé ne peuvent pas être annulées",
    "dateNotEqual": "Certaines visites devraient avoir lieu le jour même",
    "timeCollision": "Les dates de visites sélectionnées sont en conflit les unes avec les autres",
    "wrongDuration": "Durée de visite invalide",
    "notActive": "Le forfait de visite n'est pas disponible",
    "differentDoctor": "Certaines visites doivent avoir lieu avec le même médecin",
    "differentPlace": "Certaines visites doivent avoir lieu au même endroit (ou en ligne)"
  },
  "request": {
    "blocked": {
      "reset": "Impossible d'envoyer le lien pour le changement de mot de passe avant que {n} heures se soient écoulées depuis la dernière demande de changement de mot de passe. Veuillez contacter le service client.",
      "registration": "Impossible d'envoyer le lien pour le changement de mot de passe avant que {n} heures se soient écoulées depuis la dernière demande de changement de mot de passe. Veuillez contacter le service client.",
      "notFound": "Impossible de déverrouiller la réinitialisation du mot de passe car la demande précédente est introuvable.",
      "notActive": "Le forfait de visite n'est pas disponible"
    }
  },
  "slot": {
    "stepNotAllowed": "Le service médical ne définit pas la possibilité de choisir la durée de la visite",
    "timeNotInRange": "La durée de visite sélectionnée n'est pas comprise dans la plage possible",
    "timeIndivisibleByStep": "La durée de la visite sélectionnée n'est pas divisible par l'étape définie"
  },
  "docplanner": {
    "issue": {
      "alreadyResolved": "Le problème a déjà été marqué comme résolu",
      "actionNotImplemented": "Aucune action automatique n'est préparée pour ce type de problème",
      "notFound": "Problème introuvable"
    }
  },
  "hashtag": {
    "alreadyExists": "Le hashtag avec le prénom existe déjà"
  },
  "generic": {
    "alreadyExists": "L'objet avec l'identifiant donné existe déjà"
  },
  "partner": {
    "notMatch": "Service correspondant introuvable"
  }
}