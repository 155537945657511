/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DoctorPricelistItemDto } from './doctor-pricelist-item-dto';
import { DoctorToWorkPlaceDto } from './doctor-to-work-place-dto';
import { MedicalServiceSystemItemDto } from './medical-service-system-item-dto';

/**
 * 
 * @export
 * @interface DoctorAccountPricelistInfoDto
 */
export interface DoctorAccountPricelistInfoDto {
    /**
     * 
     * @type {boolean}
     * @memberof DoctorAccountPricelistInfoDto
     */
    'allPatientsAccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountPricelistInfoDto
     */
    'bankAccount'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountPricelistInfoDto
     */
    'docplannerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountPricelistInfoDto
     */
    'docplannerRemoteWorkplaceId'?: string;
    /**
     * 
     * @type {Array<DoctorToWorkPlaceDto>}
     * @memberof DoctorAccountPricelistInfoDto
     */
    'doctorToWorkPlaces'?: Array<DoctorToWorkPlaceDto>;
    /**
     * 
     * @type {Array<DoctorPricelistItemDto>}
     * @memberof DoctorAccountPricelistInfoDto
     */
    'items'?: Array<DoctorPricelistItemDto>;
    /**
     * 
     * @type {Set<string>}
     * @memberof DoctorAccountPricelistInfoDto
     */
    'neuroscreenDiagnoses'?: Set<DoctorAccountPricelistInfoDtoNeuroscreenDiagnosesEnum>;
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountPricelistInfoDto
     */
    'nip'?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof DoctorAccountPricelistInfoDto
     */
    'patientTypes'?: Set<DoctorAccountPricelistInfoDtoPatientTypesEnum>;
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountPricelistInfoDto
     */
    'practiceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountPricelistInfoDto
     */
    'ratingPortalPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountPricelistInfoDto
     */
    'regon'?: string;
    /**
     * 
     * @type {Array<MedicalServiceSystemItemDto>}
     * @memberof DoctorAccountPricelistInfoDto
     */
    'systemItems'?: Array<MedicalServiceSystemItemDto>;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorAccountPricelistInfoDto
     */
    'widgetVisibility'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum DoctorAccountPricelistInfoDtoNeuroscreenDiagnosesEnum {
    Headaches = 'HEADACHES',
    MovementIssues = 'MOVEMENT_ISSUES',
    MemoryIssues = 'MEMORY_ISSUES',
    EatingIssues = 'EATING_ISSUES',
    ConsciousnessIssues = 'CONSCIOUSNESS_ISSUES',
    PsychomotorDevelopmentIssues = 'PSYCHOMOTOR_DEVELOPMENT_ISSUES',
    Epilepsy = 'EPILEPSY',
    SpineIssues = 'SPINE_ISSUES',
    MutipleSclerosis = 'MUTIPLE_SCLEROSIS',
    Dizziness = 'DIZZINESS',
    Autism = 'AUTISM',
    OtherSymptoms = 'OTHER_SYMPTOMS'
}
/**
    * @export
    * @enum {string}
    */
export enum DoctorAccountPricelistInfoDtoPatientTypesEnum {
    Infant = 'INFANT',
    Child = 'CHILD',
    Youth = 'YOUTH',
    Adult = 'ADULT',
    Senior = 'SENIOR'
}


